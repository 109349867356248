export var DOCUMENT = {
  VISIBILITY: {
    PUBLIC: 1,
    PRIVATE: 2,
    SHARED_WITH_ACCESSORS: 3,
    SHARED_WITH_PATIENT: 4,
    HIDDEN: 5, // Deprecated
    SHARED_WITH_INSTITUTION: 6,
    SHARED_WITH_INSTITUTION_AND_PATIENT: 7,
  }
};


export var MEDICAL_INVOICE = {
  STATUS : {
    DRAFT : 0
  }
};

export var PDF = {
  TYPE: {
    PRESCRIPTION: "prescription",
    QUESTIONNAIRE_ANSWERS: "consultation_questionnaire",
    REPORT: "report",
    CREATED_DOCUMENT: "document",
  },
  STATUS: {
    ENABLED: 1,
    ARCHIVED: 2,
  },
  CONTEXTS: {
    GENERATE_DOCUMENT: 'generate_document'
  },
  STRUCTURED_DATA: {
    WYSIWYG: "wysiwyg",
    TEXT: "text",
    DRUGS: "drugs",
    SYNAPSE: "drugs_synapse",
    APPENDICES: "appendices",
    FIELDS: "fields",
    MEDICAL_TEAM: "medical_team",
    SYNAPSE_NON_ALD: "drugs_synapse_not_ald",

  }
};

export var MEDICAL_RESOURCE = {
  OWNER_TYPE: {
    INSTITUTION: 1,
    NURSES_MEDICAL_TEAM: 3,
    MEDICS_MEDICAL_TEAM: 6,
    MEDICAL_TEAM: 5,
    DOCTOR: 4,
  }
};


export var PROFILE = {
  SEX: {
    MALE: 1,
    FEMALE: 2,
  },
  STATUS: {
    ENABLED: 1,
    ARCHIVED: 2,
    DELETED: 3,
    TO_SUPERVISE: 4,
    INVALID: 5,
    INCOMPLETE: 6,
    BLACKLISTED: 7,
    DECEASED: 8,
    TO_REVIEW: 9,
    TO_PAY: 31,
    INVITED: 32,
  }
};

export var QUESTIONNAIRE = {
  TYPE: {
    SELF_EVALUATION: 1,
    DOSSIER_PATIENT: 2,
    CONSULTATIONS: 3
  }
};

export var QUESTION = {
  TYPE: {
    SECTION: "Section",
    SCALE: "Scale",
    TEXTAREA: "TextArea",
    TEXT: "Text",
    CHECKBOX: "Checkbox",
    DATE: "Date",
    BIG_SCALE: "BigScale",
    MULTIPLE_CHOICES: "MultipleChoice",
    NUMBER: 'Number',
    VARIABLE: 'Variable'
  }
};


export var TWO_F_A = {
  EMAIL: "email",
  TOTP: "totp",
  BACKUP_CODE: "backup_code",
  SMS: "sms",
};


export var TEMPLATE_VARIABLE = {
  TYPE: {
    DATETIME: "dateTime",
    MULTIPLE_CHOICES: "multipleChoice",
    TEXT: "text",
    CONSTANT: "constant",
  }
};


export var DEVICES = {
  TYPE: {
    WEB: 'web',
  }
};


export var USER = {
  ROLES: {
    ROLE_PATIENT: "ROLE_PATIENT",
    ROLE_INFIRMIERE: "ROLE_INFIRMIERE",
    ROLE_MEDECIN: "ROLE_MEDECIN",
    ROLE_MEDECIN_PENDING: "ROLE_MEDECIN_PENDING",
    ROLE_INSTITUTION_MANAGER: "ROLE_INSTITUTION_MANAGER",
    ROLE_MEDICAL_COORDINATOR: "ROLE_MEDICAL_COORDINATOR",
    ROLE_ASSISTANT: "ROLE_ASSISTANT",
    ROLE_HOSPITAL_ADMIN: "ROLE_HOSPITAL_ADMIN",
    ROLE_DEVELOPER: "ROLE_DEVELOPER",
    ROLE_SUPPORT: "ROLE_SUPPORT",
    ROLE_RESEARCH: "ROLE_RESEARCH"
  }
};

export var TASK = {
  TYPE: {
    MERGE_REQUEST: 'merge_request',
    LINK_NURSE_TO_MEDIC: 'link_nurse_to_medic',
    LINK_NURSE_TO_NURSE: 'link_nurse_to_nurse',
    LINK_MEDIC_TO_MEDIC: 'link_medic_to_medic',
    PATIENT_SHARED_TO_MEDIC: 'shared_to_medic',
    PATIENT_SHARED_TO_INSTITUTION: 'shared_to_institution',
    PATIENT_SHARED_TO_NURSE: 'shared_to_nurse',
  },
  STATUS: {
    PENDING: 1,
    DONE: 2,
    CANCELED: 3,
    REVERTED: 4,
    DUPLICATE: 5,
    ACKNOWLEDGED: 6,
    HANDLED: 7,
  },

};

export const SharingReason = {
  CONVOCATION: 1,
  ADD_TO_MEDICAL_TEAM: 2,
  SHARED_BY_PATIENT: 3,
  ADMINISTRATIVE: 4,
  FUSION: 5,
};

export var HEALTH_PROFILE = {
  TYPE: {
    OPEN: 1,
    RESTRICTED: 2,
  }
};


export var ALERT = {
  STATUS: {
    PENDING: 1,
    CLEARED: 2,
  }
};


export var PRODUCT_TYPE = {
  PATIENT_BILLING: "patient_billing",
  // @deprecated
  MAIN_SUBSCRIPTION: "core",
  CORE: "core",
};

export var HTTP = {
  STATUS_CODE: {
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    PAYMENT_REQUIRED: 402,
    FORBIDDEN: 403,
    CONFLICT: 409,
    PRECONDITION_REQUIRED: 428,
    NOT_FOUND: 404,
    SERVER_ERROR: 500
  }
};


export const MEDICAL_RECORD_SECTIONS = {
  TYPE: {
    TYPE_FIELD_LIST: "field_list",
    TYPE_GENERAL_DATA: "general_data",
    TYPE_MEDICAL_BACKGROUNDS: "medical_backgrounds",
    TYPE_ALERTS: "alerts",
    TYPE_DOCUMENTS: "documents",
    TYPE_DISEASES_AND_TREATMENTS: "diseases_and_treatments",
    TYPE_TREATMENTS: "treatments",
    TYPE_MEDICAL_TEAM: "medical_team",
    TYPE_ANSWERS_GROUPS: "answers_groups",
    TYPE_PHARMACIES: "pharmacies",
    TYPE_LABORATORIES: "laboratories",
    TYPE_ADDRESS: "address",
    TYPE_NOTES: "notes",
    TYPE_CONSULTATIONS: "consultations",
    TYPE_DISCUSSIONS: "discussions",
    TYPE_EVENTS: "events",
    TYPE_HISTORIC: "historic",
  }
};

export const MEDICAL_RECORD_FIELD = {
  TYPE: {
    TYPE_CHOICE_SINGLE: 'single_choice',
    TYPE_REPEATER: 'repeater',
    TYPE_CHOICE_MULTIPLE: 'multiple_choice',
    TYPE_TEXT: 'text',
    TYPE_VARIABLE: 'variable',
    TYPE_BOOLEAN: 'boolean',
    TYPE_DATE: 'date',
    TYPE_TEXTAREA: 'textarea',
    TYPE_NUMBER: 'number',
  },
  STATUS: {
    ACTIVE: 1,
    ARCHIVED: 2,
    READONLY: 3
  }
};



export const AI_SUGGESTION = {
  STATUS: {
    PENDING: 0,
    ACCEPTED: 1,
    REFUSED: 2,
  }
};

export const TREATMENT = {
  TREATMENT_TYPE : {
    BACKGROUND: 'background',
    SPOT: 'spot',
  }
};


export const EVENT = {
  STATUS : {
    IN_WAITING_ROOM: 1,
    IN_CONSULTATION: 2,
    SEEN: 3,
    ABSENT_EXCUSED: 4,
    ABSENT_UNEXCUSED: 5,
  }
};