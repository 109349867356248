import { ts, viewport } from "../common";

/**
 *
 * Based on :
 * - https://www.smashingmagazine.com/2018/01/drag-drop-file-uploader-vanilla-js/
 * - https://stackoverflow.com/questions/48079867/paste-document-files-in-javascript
 *
 */
export default class DragDroper {


  isFiles = false;

  dropzone;

  /**
     * Used to hide the overlay only when exiting
     */
  timeout;

  /**
     *
     * Callback on file upload
     *
     * @param files
     */
  // eslint-disable-next-line no-unused-vars
  onFileUpload(files) {
  }

  /**
     *
     * @type {string}
     */
  location = 'documents';

  /**
     *
     * @type {boolean}
     */
  followScroll = false;

  /**
     *
     * @param zone
     * @param location
     */
  constructor(zone, location) {

    if (location) {
      this.location = location;
    }

    this.dropzone = zone;

    this.followScroll = this.dropzone.height() > viewport().height;

  }


  get zone() {
    const $zone = $($('#dropzone-content').html());

    $zone.find('h2').html(ts(`dropzone.title.${this.location}`));
    $zone.find('p').html(ts(`dropzone.subtitle.${this.location}`));

    if (this.followScroll) {
      $zone.addClass('follow-scroll');
    }

    return $zone;

  }


  init() {
    this.registerEvents();
  }


  registerEvents() {

    if (this.followScroll) {
      $(window).on('scroll', () => {

        let elem = this.dropzone.find('.dropzone-hover');
        if (elem.length === 0) {
          return;
        }

        this.updatePosition(elem);

      });
    }

    ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
      this.dropzone.on(eventName, (e) => {
        e.preventDefault();
        e.stopPropagation();
      });
    });

    // Handle paste files
    document.addEventListener('paste', (e) => {
      const files = this.getPasteFiles(e);
      this.handleFiles(files);
    });

    ['dragenter', 'dragover'].forEach(eventName => {
      this.dropzone.on(eventName, (e) => {
        this.onEnter(e);
      });
    });

    ['dragleave', 'drop'].forEach(eventName => {
      this.dropzone.on(eventName, (e) => {
        this.onLeave(e);
      });
    });

    this.dropzone.on('drop', (e) => {
      this.onDrop(e);
    });

  }

  /**
     *
     * @param e
     * @return {*}
     */
  getPasteFiles(e) {
    let files = [];
    // IE
    if (window.clipboardData) {
      files = window.clipboardData.files;
    } else {
      files = e.clipboardData.files;
    }

    return files;

  }


  onEnter(e) {

    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    if(e.type === 'dragenter') {
      this.isFiles = e.originalEvent.dataTransfer.types.includes('Files');
    }

    if(!this.isFiles) {
      return;
    }


    if (this.dropzone.find('.dropzone-hover').length === 0) {
      const $zone = this.zone;
      this.dropzone.append($zone);
      this.updatePosition($zone);
    }
  }


  /**
     *
     * @param elem
     */
  updatePosition(elem) {

    if (!this.followScroll) {
      return;
    }

    // This might be an issue later... Let's see
    let windowScroll = $(window).scrollTop();
    elem.css('padding-top', Math.max(windowScroll, 100));
    // elem.css('padding-top',windowScroll - scrollTop + viewport().height/2 - block height/2 or something like this)

  }

  onLeave() {

    this.timeout = setTimeout(() => {
      this.dropzone.find('.dropzone-hover').remove();
    }, 50);
  }

  onDrop(e) {

    let files = this.getFiles(e);

    this.handleFiles(files);
  }

  /**
     *
     * @param e
     * @return {*}
     */
  getFiles(e) {
    let dt = e.originalEvent.dataTransfer;
    return dt.files;
  }


  handleFiles(files) {

    if (files && files.length) {
      this.onFileUpload(files);
    }
  }


}
