export const THIS_VALUE_SHOULD_BE_FALSE = {"id":"This value should be false.","translations":{"validators":{"fr":"Cette valeur doit \u00eatre fausse.","en":"This value should be false."}}};
export const THIS_VALUE_SHOULD_BE_TRUE = {"id":"This value should be true.","translations":{"validators":{"fr":"Cette valeur doit \u00eatre vraie.","en":"This value should be true."}}};
export const THIS_VALUE_SHOULD_BE_OF_TYPE_TYPE = {"id":"This value should be of type {{ type }}.","translations":{"validators":{"fr":"Cette valeur doit \u00eatre de type {{ type }}.","en":"This value should be of type {{ type }}."}}};
export const THIS_VALUE_SHOULD_BE_BLANK = {"id":"This value should be blank.","translations":{"validators":{"fr":"Cette valeur doit \u00eatre vide.","en":"This value should be blank."}}};
export const THE_VALUE_YOU_SELECTED_IS_NOT_A_VALID_CHOICE = {"id":"The value you selected is not a valid choice.","translations":{"validators":{"fr":"Cette valeur doit \u00eatre l'un des choix propos\u00e9s.","en":"The value you selected is not a valid choice."}}};
export const YOU_MUST_SELECT_AT_LEAST_LIMIT_CHOICE_YOU_MUST_SELECT_AT_LEAST_LIMIT_CHOICES = {"id":"You must select at least {{ limit }} choice.|You must select at least {{ limit }} choices.","translations":{"validators":{"fr":"Vous devez s\u00e9lectionner au moins {{ limit }} choix.|Vous devez s\u00e9lectionner au moins {{ limit }} choix.","en":"You must select at least {{ limit }} choice.|You must select at least {{ limit }} choices."}}};
export const YOU_MUST_SELECT_AT_MOST_LIMIT_CHOICE_YOU_MUST_SELECT_AT_MOST_LIMIT_CHOICES = {"id":"You must select at most {{ limit }} choice.|You must select at most {{ limit }} choices.","translations":{"validators":{"fr":"Vous devez s\u00e9lectionner au maximum {{ limit }} choix.|Vous devez s\u00e9lectionner au maximum {{ limit }} choix.","en":"You must select at most {{ limit }} choice.|You must select at most {{ limit }} choices."}}};
export const ONE_OR_MORE_OF_THE_GIVEN_VALUES_IS_INVALID = {"id":"One or more of the given values is invalid.","translations":{"validators":{"fr":"Une ou plusieurs des valeurs soumises sont invalides.","en":"One or more of the given values is invalid."}}};
export const THIS_FIELD_WAS_NOT_EXPECTED = {"id":"This field was not expected.","translations":{"validators":{"fr":"Ce champ n'a pas \u00e9t\u00e9 pr\u00e9vu.","en":"This field was not expected."}}};
export const THIS_FIELD_IS_MISSING = {"id":"This field is missing.","translations":{"validators":{"fr":"Ce champ est manquant.","en":"This field is missing."}}};
export const THIS_VALUE_IS_NOT_A_VALID_DATE = {"id":"This value is not a valid date.","translations":{"validators":{"fr":"Cette valeur n'est pas une date valide.","en":"This value is not a valid date."}}};
export const THIS_VALUE_IS_NOT_A_VALID_DATETIME = {"id":"This value is not a valid datetime.","translations":{"validators":{"fr":"Cette valeur n'est pas une date valide.","en":"This value is not a valid datetime."}}};
export const THIS_VALUE_IS_NOT_A_VALID_EMAIL_ADDRESS = {"id":"This value is not a valid email address.","translations":{"validators":{"fr":"Cette valeur n'est pas une adresse email valide.","en":"This value is not a valid email address."}}};
export const THE_FILE_COULD_NOT_BE_FOUND = {"id":"The file could not be found.","translations":{"validators":{"fr":"Le fichier n'a pas \u00e9t\u00e9 trouv\u00e9.","en":"The file could not be found."}}};
export const THE_FILE_IS_NOT_READABLE = {"id":"The file is not readable.","translations":{"validators":{"fr":"Le fichier n'est pas lisible.","en":"The file is not readable."}}};
export const THE_FILE_IS_TOO_LARGE_SIZE_SUFFIX_ALLOWED_MAXIMUM_SIZE_IS_LIMIT_SUFFIX = {"id":"The file is too large ({{ size }} {{ suffix }}). Allowed maximum size is {{ limit }} {{ suffix }}.","translations":{"validators":{"fr":"Le fichier est trop volumineux ({{ size }} {{ suffix }}). Sa taille ne doit pas d\u00e9passer {{ limit }} {{ suffix }}.","en":"The file is too large ({{ size }} {{ suffix }}). Allowed maximum size is {{ limit }} {{ suffix }}."}}};
export const THE_MIME_TYPE_OF_THE_FILE_IS_INVALID_TYPE_ALLOWED_MIME_TYPES_ARE_TYPES = {"id":"The mime type of the file is invalid ({{ type }}). Allowed mime types are {{ types }}.","translations":{"validators":{"fr":"Le type du fichier est invalide ({{ type }}). Les types autoris\u00e9s sont {{ types }}.","en":"The mime type of the file is invalid ({{ type }}). Allowed mime types are {{ types }}."}}};
export const THIS_VALUE_SHOULD_BE_LIMIT_OR_LESS = {"id":"This value should be {{ limit }} or less.","translations":{"validators":{"fr":"Cette valeur doit \u00eatre inf\u00e9rieure ou \u00e9gale \u00e0 {{ limit }}.","en":"This value should be {{ limit }} or less."}}};
export const THIS_VALUE_IS_TOO_LONG_IT_SHOULD_HAVE_LIMIT_CHARACTER_OR_LESS_THIS_VALUE_IS_TOO_LONG_IT_SHOULD_HAVE_LIMIT_CHARACTERS_OR_LESS = {"id":"This value is too long. It should have {{ limit }} character or less.|This value is too long. It should have {{ limit }} characters or less.","translations":{"validators":{"fr":"Cette cha\u00eene est trop longue. Elle doit avoir au maximum {{ limit }} caract\u00e8re.|Cette cha\u00eene est trop longue. Elle doit avoir au maximum {{ limit }} caract\u00e8res.","en":"This value is too long. It should have {{ limit }} character or less.|This value is too long. It should have {{ limit }} characters or less."}}};
export const THIS_VALUE_SHOULD_BE_LIMIT_OR_MORE = {"id":"This value should be {{ limit }} or more.","translations":{"validators":{"fr":"Cette valeur doit \u00eatre sup\u00e9rieure ou \u00e9gale \u00e0 {{ limit }}.","en":"This value should be {{ limit }} or more."}}};
export const THIS_VALUE_IS_TOO_SHORT_IT_SHOULD_HAVE_LIMIT_CHARACTER_OR_MORE_THIS_VALUE_IS_TOO_SHORT_IT_SHOULD_HAVE_LIMIT_CHARACTERS_OR_MORE = {"id":"This value is too short. It should have {{ limit }} character or more.|This value is too short. It should have {{ limit }} characters or more.","translations":{"validators":{"fr":"Cette cha\u00eene est trop courte. Elle doit avoir au minimum {{ limit }} caract\u00e8re.|Cette cha\u00eene est trop courte. Elle doit avoir au minimum {{ limit }} caract\u00e8res.","en":"This value is too short. It should have {{ limit }} character or more.|This value is too short. It should have {{ limit }} characters or more."}}};
export const THIS_VALUE_SHOULD_NOT_BE_BLANK = {"id":"This value should not be blank.","translations":{"validators":{"fr":"Cette valeur ne doit pas \u00eatre vide.","en":"This value should not be blank."}}};
export const THIS_VALUE_SHOULD_NOT_BE_NULL = {"id":"This value should not be null.","translations":{"validators":{"fr":"Cette valeur ne doit pas \u00eatre nulle.","en":"This value should not be null."}}};
export const THIS_VALUE_SHOULD_BE_NULL = {"id":"This value should be null.","translations":{"validators":{"fr":"Cette valeur doit \u00eatre nulle.","en":"This value should be null."}}};
export const THIS_VALUE_IS_NOT_VALID = {"id":"This value is not valid.","translations":{"validators":{"fr":"Cette valeur n'est pas valide.","en":"This value is not valid."}}};
export const THIS_VALUE_IS_NOT_A_VALID_TIME = {"id":"This value is not a valid time.","translations":{"validators":{"fr":"Cette valeur n'est pas une heure valide.","en":"This value is not a valid time."}}};
export const THIS_VALUE_IS_NOT_A_VALID_URL = {"id":"This value is not a valid URL.","translations":{"validators":{"fr":"Cette valeur n'est pas une URL valide.","en":"This value is not a valid URL."}}};
export const THE_TWO_VALUES_SHOULD_BE_EQUAL = {"id":"The two values should be equal.","translations":{"validators":{"fr":"Les deux valeurs doivent \u00eatre identiques.","en":"The two values should be equal."}}};
export const THE_FILE_IS_TOO_LARGE_ALLOWED_MAXIMUM_SIZE_IS_LIMIT_SUFFIX = {"id":"The file is too large. Allowed maximum size is {{ limit }} {{ suffix }}.","translations":{"validators":{"fr":"Le fichier est trop volumineux. Sa taille ne doit pas d\u00e9passer {{ limit }} {{ suffix }}.","en":"The file is too large. Allowed maximum size is {{ limit }} {{ suffix }}."}}};
export const THE_FILE_IS_TOO_LARGE = {"id":"The file is too large.","translations":{"validators":{"fr":"Le fichier est trop volumineux.","en":"The file is too large."}}};
export const THE_FILE_COULD_NOT_BE_UPLOADED = {"id":"The file could not be uploaded.","translations":{"validators":{"fr":"Le t\u00e9l\u00e9chargement de ce fichier est impossible.","en":"The file could not be uploaded."}}};
export const THIS_VALUE_SHOULD_BE_A_VALID_NUMBER = {"id":"This value should be a valid number.","translations":{"validators":{"fr":"Cette valeur doit \u00eatre un nombre.","en":"This value should be a valid number."}}};
export const THIS_FILE_IS_NOT_A_VALID_IMAGE = {"id":"This file is not a valid image.","translations":{"validators":{"fr":"Ce fichier n'est pas une image valide.","en":"This file is not a valid image."}}};
export const THIS_IS_NOT_A_VALID_IP_ADDRESS = {"id":"This is not a valid IP address.","translations":{"validators":{"fr":"Cette valeur n'est pas une adresse IP valide.","en":"This value is not a valid IP address."}}};
export const THIS_VALUE_IS_NOT_A_VALID_LANGUAGE = {"id":"This value is not a valid language.","translations":{"validators":{"fr":"Cette langue n'est pas valide.","en":"This value is not a valid language."}}};
export const THIS_VALUE_IS_NOT_A_VALID_LOCALE = {"id":"This value is not a valid locale.","translations":{"validators":{"fr":"Ce param\u00e8tre r\u00e9gional n'est pas valide.","en":"This value is not a valid locale."}}};
export const THIS_VALUE_IS_NOT_A_VALID_COUNTRY = {"id":"This value is not a valid country.","translations":{"validators":{"fr":"Ce pays n'est pas valide.","en":"This value is not a valid country."}}};
export const THIS_VALUE_IS_ALREADY_USED = {"id":"This value is already used.","translations":{"validators":{"fr":"Cette valeur est d\u00e9j\u00e0 utilis\u00e9e.","en":"This value is already used."}}};
export const THE_SIZE_OF_THE_IMAGE_COULD_NOT_BE_DETECTED = {"id":"The size of the image could not be detected.","translations":{"validators":{"fr":"La taille de l'image n'a pas pu \u00eatre d\u00e9tect\u00e9e.","en":"The size of the image could not be detected."}}};
export const THE_IMAGE_WIDTH_IS_TOO_BIG_WIDTH_PX_ALLOWED_MAXIMUM_WIDTH_IS_MAX_WIDTH_PX = {"id":"The image width is too big ({{ width }}px). Allowed maximum width is {{ max_width }}px.","translations":{"validators":{"fr":"La largeur de l'image est trop grande ({{ width }}px). La largeur maximale autoris\u00e9e est de {{ max_width }}px.","en":"The image width is too big ({{ width }}px). Allowed maximum width is {{ max_width }}px."}}};
export const THE_IMAGE_WIDTH_IS_TOO_SMALL_WIDTH_PX_MINIMUM_WIDTH_EXPECTED_IS_MIN_WIDTH_PX = {"id":"The image width is too small ({{ width }}px). Minimum width expected is {{ min_width }}px.","translations":{"validators":{"fr":"La largeur de l'image est trop petite ({{ width }}px). La largeur minimale attendue est de {{ min_width }}px.","en":"The image width is too small ({{ width }}px). Minimum width expected is {{ min_width }}px."}}};
export const THE_IMAGE_HEIGHT_IS_TOO_BIG_HEIGHT_PX_ALLOWED_MAXIMUM_HEIGHT_IS_MAX_HEIGHT_PX = {"id":"The image height is too big ({{ height }}px). Allowed maximum height is {{ max_height }}px.","translations":{"validators":{"fr":"La hauteur de l'image est trop grande ({{ height }}px). La hauteur maximale autoris\u00e9e est de {{ max_height }}px.","en":"The image height is too big ({{ height }}px). Allowed maximum height is {{ max_height }}px."}}};
export const THE_IMAGE_HEIGHT_IS_TOO_SMALL_HEIGHT_PX_MINIMUM_HEIGHT_EXPECTED_IS_MIN_HEIGHT_PX = {"id":"The image height is too small ({{ height }}px). Minimum height expected is {{ min_height }}px.","translations":{"validators":{"fr":"La hauteur de l'image est trop petite ({{ height }}px). La hauteur minimale attendue est de {{ min_height }}px.","en":"The image height is too small ({{ height }}px). Minimum height expected is {{ min_height }}px."}}};
export const THIS_VALUE_SHOULD_BE_THE_USER_S_CURRENT_PASSWORD = {"id":"This value should be the user's current password.","translations":{"validators":{"fr":"Cette valeur doit \u00eatre le mot de passe actuel de l'utilisateur.","en":"This value should be the user's current password."}}};
export const THIS_VALUE_SHOULD_HAVE_EXACTLY_LIMIT_CHARACTER_THIS_VALUE_SHOULD_HAVE_EXACTLY_LIMIT_CHARACTERS = {"id":"This value should have exactly {{ limit }} character.|This value should have exactly {{ limit }} characters.","translations":{"validators":{"fr":"Cette cha\u00eene doit avoir exactement {{ limit }} caract\u00e8re.|Cette cha\u00eene doit avoir exactement {{ limit }} caract\u00e8res.","en":"This value should have exactly {{ limit }} character.|This value should have exactly {{ limit }} characters."}}};
export const THE_FILE_WAS_ONLY_PARTIALLY_UPLOADED = {"id":"The file was only partially uploaded.","translations":{"validators":{"fr":"Le fichier a \u00e9t\u00e9 partiellement transf\u00e9r\u00e9.","en":"The file was only partially uploaded."}}};
export const NO_FILE_WAS_UPLOADED = {"id":"No file was uploaded.","translations":{"validators":{"fr":"Aucun fichier n'a \u00e9t\u00e9 transf\u00e9r\u00e9.","en":"No file was uploaded."}}};
export const NO_TEMPORARY_FOLDER_WAS_CONFIGURED_IN_PHP_INI = {"id":"No temporary folder was configured in php.ini.","translations":{"validators":{"fr":"Aucun r\u00e9pertoire temporaire n'a \u00e9t\u00e9 configur\u00e9 dans le php.ini, ou le r\u00e9pertoire configur\u00e9 n'existe pas.","en":"No temporary folder was configured in php.ini, or the configured folder does not exist."}}};
export const CANNOT_WRITE_TEMPORARY_FILE_TO_DISK = {"id":"Cannot write temporary file to disk.","translations":{"validators":{"fr":"Impossible d'\u00e9crire le fichier temporaire sur le disque.","en":"Cannot write temporary file to disk."}}};
export const A_PHP_EXTENSION_CAUSED_THE_UPLOAD_TO_FAIL = {"id":"A PHP extension caused the upload to fail.","translations":{"validators":{"fr":"Une extension PHP a emp\u00each\u00e9 le transfert du fichier.","en":"A PHP extension caused the upload to fail."}}};
export const THIS_COLLECTION_SHOULD_CONTAIN_LIMIT_ELEMENT_OR_MORE_THIS_COLLECTION_SHOULD_CONTAIN_LIMIT_ELEMENTS_OR_MORE = {"id":"This collection should contain {{ limit }} element or more.|This collection should contain {{ limit }} elements or more.","translations":{"validators":{"fr":"Cette collection doit contenir {{ limit }} \u00e9l\u00e9ment ou plus.|Cette collection doit contenir {{ limit }} \u00e9l\u00e9ments ou plus.","en":"This collection should contain {{ limit }} element or more.|This collection should contain {{ limit }} elements or more."}}};
export const THIS_COLLECTION_SHOULD_CONTAIN_LIMIT_ELEMENT_OR_LESS_THIS_COLLECTION_SHOULD_CONTAIN_LIMIT_ELEMENTS_OR_LESS = {"id":"This collection should contain {{ limit }} element or less.|This collection should contain {{ limit }} elements or less.","translations":{"validators":{"fr":"Cette collection doit contenir {{ limit }} \u00e9l\u00e9ment ou moins.|Cette collection doit contenir {{ limit }} \u00e9l\u00e9ments ou moins.","en":"This collection should contain {{ limit }} element or less.|This collection should contain {{ limit }} elements or less."}}};
export const THIS_COLLECTION_SHOULD_CONTAIN_EXACTLY_LIMIT_ELEMENT_THIS_COLLECTION_SHOULD_CONTAIN_EXACTLY_LIMIT_ELEMENTS = {"id":"This collection should contain exactly {{ limit }} element.|This collection should contain exactly {{ limit }} elements.","translations":{"validators":{"fr":"Cette collection doit contenir exactement {{ limit }} \u00e9l\u00e9ment.|Cette collection doit contenir exactement {{ limit }} \u00e9l\u00e9ments.","en":"This collection should contain exactly {{ limit }} element.|This collection should contain exactly {{ limit }} elements."}}};
export const INVALID_CARD_NUMBER = {"id":"Invalid card number.","translations":{"validators":{"fr":"Num\u00e9ro de carte invalide.","en":"Invalid card number."}}};
export const UNSUPPORTED_CARD_TYPE_OR_INVALID_CARD_NUMBER = {"id":"Unsupported card type or invalid card number.","translations":{"validators":{"fr":"Type de carte non support\u00e9 ou num\u00e9ro invalide.","en":"Unsupported card type or invalid card number."}}};
export const THIS_IS_NOT_A_VALID_INTERNATIONAL_BANK_ACCOUNT_NUMBER_IBAN = {"id":"This is not a valid International Bank Account Number (IBAN).","translations":{"validators":{"fr":"Cette valeur n'est pas un Num\u00e9ro de Compte Bancaire International (IBAN) valide.","en":"This value is not a valid International Bank Account Number (IBAN)."}}};
export const THIS_VALUE_IS_NOT_A_VALID_ISBN10 = {"id":"This value is not a valid ISBN-10.","translations":{"validators":{"fr":"Cette valeur n'est pas un code ISBN-10 valide.","en":"This value is not a valid ISBN-10."}}};
export const THIS_VALUE_IS_NOT_A_VALID_ISBN13 = {"id":"This value is not a valid ISBN-13.","translations":{"validators":{"fr":"Cette valeur n'est pas un code ISBN-13 valide.","en":"This value is not a valid ISBN-13."}}};
export const THIS_VALUE_IS_NEITHER_A_VALID_ISBN10_NOR_A_VALID_ISBN13 = {"id":"This value is neither a valid ISBN-10 nor a valid ISBN-13.","translations":{"validators":{"fr":"Cette valeur n'est ni un code ISBN-10, ni un code ISBN-13 valide.","en":"This value is neither a valid ISBN-10 nor a valid ISBN-13."}}};
export const THIS_VALUE_IS_NOT_A_VALID_ISSN = {"id":"This value is not a valid ISSN.","translations":{"validators":{"fr":"Cette valeur n'est pas un code ISSN valide.","en":"This value is not a valid ISSN."}}};
export const THIS_VALUE_IS_NOT_A_VALID_CURRENCY = {"id":"This value is not a valid currency.","translations":{"validators":{"fr":"Cette valeur n'est pas une devise valide.","en":"This value is not a valid currency."}}};
export const THIS_VALUE_SHOULD_BE_EQUAL_TO_COMPARED_VALUE = {"id":"This value should be equal to {{ compared_value }}.","translations":{"validators":{"fr":"Cette valeur doit \u00eatre \u00e9gale \u00e0 {{ compared_value }}.","en":"This value should be equal to {{ compared_value }}."}}};
export const THIS_VALUE_SHOULD_BE_GREATER_THAN_COMPARED_VALUE = {"id":"This value should be greater than {{ compared_value }}.","translations":{"validators":{"fr":"Cette valeur doit \u00eatre sup\u00e9rieure \u00e0 {{ compared_value }}.","en":"This value should be greater than {{ compared_value }}."}}};
export const THIS_VALUE_SHOULD_BE_GREATER_THAN_OR_EQUAL_TO_COMPARED_VALUE = {"id":"This value should be greater than or equal to {{ compared_value }}.","translations":{"validators":{"fr":"Cette valeur doit \u00eatre sup\u00e9rieure ou \u00e9gale \u00e0 {{ compared_value }}.","en":"This value should be greater than or equal to {{ compared_value }}."}}};
export const THIS_VALUE_SHOULD_BE_IDENTICAL_TO_COMPARED_VALUE_TYPE_COMPARED_VALUE = {"id":"This value should be identical to {{ compared_value_type }} {{ compared_value }}.","translations":{"validators":{"fr":"Cette valeur doit \u00eatre identique \u00e0 {{ compared_value_type }} {{ compared_value }}.","en":"This value should be identical to {{ compared_value_type }} {{ compared_value }}."}}};
export const THIS_VALUE_SHOULD_BE_LESS_THAN_COMPARED_VALUE = {"id":"This value should be less than {{ compared_value }}.","translations":{"validators":{"fr":"Cette valeur doit \u00eatre inf\u00e9rieure \u00e0 {{ compared_value }}.","en":"This value should be less than {{ compared_value }}."}}};
export const THIS_VALUE_SHOULD_BE_LESS_THAN_OR_EQUAL_TO_COMPARED_VALUE = {"id":"This value should be less than or equal to {{ compared_value }}.","translations":{"validators":{"fr":"Cette valeur doit \u00eatre inf\u00e9rieure ou \u00e9gale \u00e0 {{ compared_value }}.","en":"This value should be less than or equal to {{ compared_value }}."}}};
export const THIS_VALUE_SHOULD_NOT_BE_EQUAL_TO_COMPARED_VALUE = {"id":"This value should not be equal to {{ compared_value }}.","translations":{"validators":{"fr":"Cette valeur ne doit pas \u00eatre \u00e9gale \u00e0 {{ compared_value }}.","en":"This value should not be equal to {{ compared_value }}."}}};
export const THIS_VALUE_SHOULD_NOT_BE_IDENTICAL_TO_COMPARED_VALUE_TYPE_COMPARED_VALUE = {"id":"This value should not be identical to {{ compared_value_type }} {{ compared_value }}.","translations":{"validators":{"fr":"Cette valeur ne doit pas \u00eatre identique \u00e0 {{ compared_value_type }} {{ compared_value }}.","en":"This value should not be identical to {{ compared_value_type }} {{ compared_value }}."}}};
export const THE_IMAGE_RATIO_IS_TOO_BIG_RATIO_ALLOWED_MAXIMUM_RATIO_IS_MAX_RATIO = {"id":"The image ratio is too big ({{ ratio }}). Allowed maximum ratio is {{ max_ratio }}.","translations":{"validators":{"fr":"Le rapport largeur\/hauteur de l'image est trop grand ({{ ratio }}). Le rapport maximal autoris\u00e9 est {{ max_ratio }}.","en":"The image ratio is too big ({{ ratio }}). Allowed maximum ratio is {{ max_ratio }}."}}};
export const THE_IMAGE_RATIO_IS_TOO_SMALL_RATIO_MINIMUM_RATIO_EXPECTED_IS_MIN_RATIO = {"id":"The image ratio is too small ({{ ratio }}). Minimum ratio expected is {{ min_ratio }}.","translations":{"validators":{"fr":"Le rapport largeur\/hauteur de l'image est trop petit ({{ ratio }}). Le rapport minimal attendu est {{ min_ratio }}.","en":"The image ratio is too small ({{ ratio }}). Minimum ratio expected is {{ min_ratio }}."}}};
export const THE_IMAGE_IS_SQUARE_WIDTH_X_HEIGHT_PX_SQUARE_IMAGES_ARE_NOT_ALLOWED = {"id":"The image is square ({{ width }}x{{ height }}px). Square images are not allowed.","translations":{"validators":{"fr":"L'image est carr\u00e9e ({{ width }}x{{ height }}px). Les images carr\u00e9es ne sont pas autoris\u00e9es.","en":"The image is square ({{ width }}x{{ height }}px). Square images are not allowed."}}};
export const THE_IMAGE_IS_LANDSCAPE_ORIENTED_WIDTH_X_HEIGHT_PX_LANDSCAPE_ORIENTED_IMAGES_ARE_NOT_ALLOWED = {"id":"The image is landscape oriented ({{ width }}x{{ height }}px). Landscape oriented images are not allowed.","translations":{"validators":{"fr":"L'image est au format paysage ({{ width }}x{{ height }}px). Les images au format paysage ne sont pas autoris\u00e9es.","en":"The image is landscape oriented ({{ width }}x{{ height }}px). Landscape oriented images are not allowed."}}};
export const THE_IMAGE_IS_PORTRAIT_ORIENTED_WIDTH_X_HEIGHT_PX_PORTRAIT_ORIENTED_IMAGES_ARE_NOT_ALLOWED = {"id":"The image is portrait oriented ({{ width }}x{{ height }}px). Portrait oriented images are not allowed.","translations":{"validators":{"fr":"L'image est au format portrait ({{ width }}x{{ height }}px). Les images au format portrait ne sont pas autoris\u00e9es.","en":"The image is portrait oriented ({{ width }}x{{ height }}px). Portrait oriented images are not allowed."}}};
export const AN_EMPTY_FILE_IS_NOT_ALLOWED = {"id":"An empty file is not allowed.","translations":{"validators":{"fr":"Un fichier vide n'est pas autoris\u00e9.","en":"An empty file is not allowed."}}};
export const THE_HOST_COULD_NOT_BE_RESOLVED = {"id":"The host could not be resolved.","translations":{"validators":{"fr":"Le nom de domaine n'a pas pu \u00eatre r\u00e9solu.","en":"The host could not be resolved."}}};
export const THIS_VALUE_DOES_NOT_MATCH_THE_EXPECTED_CHARSET_CHARSET = {"id":"This value does not match the expected {{ charset }} charset.","translations":{"validators":{"fr":"Cette valeur ne correspond pas au jeu de caract\u00e8res {{ charset }} attendu.","en":"This value does not match the expected {{ charset }} charset."}}};
export const THIS_IS_NOT_A_VALID_BUSINESS_IDENTIFIER_CODE_BIC = {"id":"This is not a valid Business Identifier Code (BIC).","translations":{"validators":{"fr":"Cette valeur n'est pas un Code Identifiant de Business (BIC) valide.","en":"This value is not a valid Business Identifier Code (BIC)."}}};
export const ERROR = {"id":"Error","translations":{"validators":{"fr":"Erreur","en":"Error"}}};
export const THIS_IS_NOT_A_VALID_UUID = {"id":"This is not a valid UUID.","translations":{"validators":{"fr":"Cette valeur n'est pas un UUID valide.","en":"This value is not a valid UUID."}}};
export const THIS_VALUE_SHOULD_BE_A_MULTIPLE_OF_COMPARED_VALUE = {"id":"This value should be a multiple of {{ compared_value }}.","translations":{"validators":{"fr":"Cette valeur doit \u00eatre un multiple de {{ compared_value }}.","en":"This value should be a multiple of {{ compared_value }}."}}};
export const THIS_BUSINESS_IDENTIFIER_CODE_BIC_IS_NOT_ASSOCIATED_WITH_IBAN_IBAN = {"id":"This Business Identifier Code (BIC) is not associated with IBAN {{ iban }}.","translations":{"validators":{"fr":"Ce code d'identification d'entreprise (BIC) n'est pas associ\u00e9 \u00e0 l'IBAN {{ iban }}.","en":"This Business Identifier Code (BIC) is not associated with IBAN {{ iban }}."}}};
export const THIS_VALUE_SHOULD_BE_VALID_JSON = {"id":"This value should be valid JSON.","translations":{"validators":{"fr":"Cette valeur doit \u00eatre un JSON valide.","en":"This value should be valid JSON."}}};
export const THIS_COLLECTION_SHOULD_CONTAIN_ONLY_UNIQUE_ELEMENTS = {"id":"This collection should contain only unique elements.","translations":{"validators":{"fr":"Cette collection ne doit pas comporter de doublons.","en":"This collection should contain only unique elements."}}};
export const THIS_VALUE_SHOULD_BE_POSITIVE = {"id":"This value should be positive.","translations":{"validators":{"fr":"Cette valeur doit \u00eatre strictement positive.","en":"This value should be positive."}}};
export const THIS_VALUE_SHOULD_BE_EITHER_POSITIVE_OR_ZERO = {"id":"This value should be either positive or zero.","translations":{"validators":{"fr":"Cette valeur doit \u00eatre sup\u00e9rieure ou \u00e9gale \u00e0 z\u00e9ro.","en":"This value should be either positive or zero."}}};
export const THIS_VALUE_SHOULD_BE_NEGATIVE = {"id":"This value should be negative.","translations":{"validators":{"fr":"Cette valeur doit \u00eatre strictement n\u00e9gative.","en":"This value should be negative."}}};
export const THIS_VALUE_SHOULD_BE_EITHER_NEGATIVE_OR_ZERO = {"id":"This value should be either negative or zero.","translations":{"validators":{"fr":"Cette valeur doit \u00eatre inf\u00e9rieure ou \u00e9gale \u00e0 z\u00e9ro.","en":"This value should be either negative or zero."}}};
export const THIS_VALUE_IS_NOT_A_VALID_TIMEZONE = {"id":"This value is not a valid timezone.","translations":{"validators":{"fr":"Cette valeur n'est pas un fuseau horaire valide.","en":"This value is not a valid timezone."}}};
export const THIS_PASSWORD_HAS_BEEN_LEAKED_IN_A_DATA_BREACH_IT_MUST_NOT_BE_USED_PLEASE_USE_ANOTHER_PASSWORD = {"id":"This password has been leaked in a data breach, it must not be used. Please use another password.","translations":{"validators":{"fr":"Ce mot de passe a \u00e9t\u00e9 divulgu\u00e9 lors d'une fuite de donn\u00e9es, il ne doit plus \u00eatre utilis\u00e9. Veuillez utiliser un autre mot de passe.","en":"This password has been leaked in a data breach, it must not be used. Please use another password."}}};
export const THIS_VALUE_SHOULD_BE_BETWEEN_MIN_AND_MAX = {"id":"This value should be between {{ min }} and {{ max }}.","translations":{"validators":{"fr":"Cette valeur doit \u00eatre comprise entre {{ min }} et {{ max }}.","en":"This value should be between {{ min }} and {{ max }}."}}};
export const THIS_VALUE_IS_NOT_A_VALID_HOSTNAME = {"id":"This value is not a valid hostname.","translations":{"validators":{"fr":"Cette valeur n'est pas un nom d'h\u00f4te valide.","en":"This value is not a valid hostname."}}};
export const THE_NUMBER_OF_ELEMENTS_IN_THIS_COLLECTION_SHOULD_BE_A_MULTIPLE_OF_COMPARED_VALUE = {"id":"The number of elements in this collection should be a multiple of {{ compared_value }}.","translations":{"validators":{"fr":"Le nombre d'\u00e9l\u00e9ments de cette collection doit \u00eatre un multiple de {{ compared_value }}.","en":"The number of elements in this collection should be a multiple of {{ compared_value }}."}}};
export const THIS_VALUE_SHOULD_SATISFY_AT_LEAST_ONE_OF_THE_FOLLOWING_CONSTRAINTS = {"id":"This value should satisfy at least one of the following constraints:","translations":{"validators":{"fr":"Cette valeur doit satisfaire \u00e0 au moins une des contraintes suivantes :","en":"This value should satisfy at least one of the following constraints:"}}};
export const EACH_ELEMENT_OF_THIS_COLLECTION_SHOULD_SATISFY_ITS_OWN_SET_OF_CONSTRAINTS = {"id":"Each element of this collection should satisfy its own set of constraints.","translations":{"validators":{"fr":"Chaque \u00e9l\u00e9ment de cette collection doit satisfaire \u00e0 son propre jeu de contraintes.","en":"Each element of this collection should satisfy its own set of constraints."}}};
export const THIS_VALUE_IS_NOT_A_VALID_INTERNATIONAL_SECURITIES_IDENTIFICATION_NUMBER_ISIN = {"id":"This value is not a valid International Securities Identification Number (ISIN).","translations":{"validators":{"fr":"Cette valeur n'est pas un code international de s\u00e9curit\u00e9 valide (ISIN).","en":"This value is not a valid International Securities Identification Number (ISIN)."}}};
export const THIS_VALUE_SHOULD_BE_A_VALID_EXPRESSION = {"id":"This value should be a valid expression.","translations":{"validators":{"fr":"Cette valeur doit \u00eatre une expression valide.","en":"This value should be a valid expression."}}};
export const THIS_VALUE_IS_NOT_A_VALID_CSS_COLOR = {"id":"This value is not a valid CSS color.","translations":{"validators":{"fr":"Cette valeur n'est pas une couleur CSS valide.","en":"This value is not a valid CSS color."}}};
export const THIS_VALUE_IS_NOT_A_VALID_CIDR_NOTATION = {"id":"This value is not a valid CIDR notation.","translations":{"validators":{"fr":"Cette valeur n'est pas une notation CIDR valide.","en":"This value is not a valid CIDR notation."}}};
export const THE_VALUE_OF_THE_NETMASK_SHOULD_BE_BETWEEN_MIN_AND_MAX = {"id":"The value of the netmask should be between {{ min }} and {{ max }}.","translations":{"validators":{"fr":"La valeur du masque de r\u00e9seau doit \u00eatre comprise entre {{ min }} et {{ max }}.","en":"The value of the netmask should be between {{ min }} and {{ max }}."}}};
export const THE_FILENAME_IS_TOO_LONG_IT_SHOULD_HAVE_FILENAME_MAX_LENGTH_CHARACTER_OR_LESS_THE_FILENAME_IS_TOO_LONG_IT_SHOULD_HAVE_FILENAME_MAX_LENGTH_CHARACTERS_OR_LESS = {"id":"The filename is too long. It should have {{ filename_max_length }} character or less.|The filename is too long. It should have {{ filename_max_length }} characters or less.","translations":{"validators":{"fr":"Le nom du fichier est trop long. Il doit contenir au maximum {{ filename_max_length }} caract\u00e8re.|Le nom de fichier est trop long. Il doit contenir au maximum {{ filename_max_length }} caract\u00e8res.","en":"The filename is too long. It should have {{ filename_max_length }} character or less.|The filename is too long. It should have {{ filename_max_length }} characters or less."}}};
export const THE_PASSWORD_STRENGTH_IS_TOO_LOW_PLEASE_USE_A_STRONGER_PASSWORD = {"id":"The password strength is too low. Please use a stronger password.","translations":{"validators":{"fr":"La force du mot de passe est trop faible. Veuillez utiliser un mot de passe plus fort.","en":"The password strength is too low. Please use a stronger password."}}};
export const THIS_VALUE_CONTAINS_CHARACTERS_THAT_ARE_NOT_ALLOWED_BY_THE_CURRENT_RESTRICTION_LEVEL = {"id":"This value contains characters that are not allowed by the current restriction-level.","translations":{"validators":{"fr":"Cette valeur contient des caract\u00e8res qui ne sont pas autoris\u00e9s par le niveau de restriction actuel.","en":"This value contains characters that are not allowed by the current restriction-level."}}};
export const USING_INVISIBLE_CHARACTERS_IS_NOT_ALLOWED = {"id":"Using invisible characters is not allowed.","translations":{"validators":{"fr":"Utiliser des caract\u00e8res invisibles n'est pas autoris\u00e9.","en":"Using invisible characters is not allowed."}}};
export const MIXING_NUMBERS_FROM_DIFFERENT_SCRIPTS_IS_NOT_ALLOWED = {"id":"Mixing numbers from different scripts is not allowed.","translations":{"validators":{"fr":"M\u00e9langer des chiffres provenant de diff\u00e9rents scripts n'est pas autoris\u00e9.","en":"Mixing numbers from different scripts is not allowed."}}};
export const USING_HIDDEN_OVERLAY_CHARACTERS_IS_NOT_ALLOWED = {"id":"Using hidden overlay characters is not allowed.","translations":{"validators":{"fr":"Utiliser des caract\u00e8res de superposition cach\u00e9s n'est pas autoris\u00e9.","en":"Using hidden overlay characters is not allowed."}}};
export const THE_EXTENSION_OF_THE_FILE_IS_INVALID_EXTENSION_ALLOWED_EXTENSIONS_ARE_EXTENSIONS = {"id":"The extension of the file is invalid ({{ extension }}). Allowed extensions are {{ extensions }}.","translations":{"validators":{"fr":"L'extension du fichier est invalide ({{ extension }}). Les extensions autoris\u00e9es sont {{ extensions }}.","en":"The extension of the file is invalid ({{ extension }}). Allowed extensions are {{ extensions }}."}}};
export const THE_DETECTED_CHARACTER_ENCODING_IS_INVALID_DETECTED_ALLOWED_ENCODINGS_ARE_ENCODINGS = {"id":"The detected character encoding is invalid ({{ detected }}). Allowed encodings are {{ encodings }}.","translations":{"validators":{"fr":"L'encodage de caract\u00e8res d\u00e9tect\u00e9 est invalide ({{ detected }}). Les encodages autoris\u00e9s sont {{ encodings }}.","en":"The detected character encoding is invalid ({{ detected }}). Allowed encodings are {{ encodings }}."}}};
export const THIS_VALUE_IS_NOT_A_VALID_MAC_ADDRESS = {"id":"This value is not a valid MAC address.","translations":{"validators":{"fr":"Cette valeur n'est pas une adresse MAC valide.","en":"This value is not a valid MAC address."}}};
export const THIS_URL_IS_MISSING_A_TOP_LEVEL_DOMAIN = {"id":"This URL is missing a top-level domain.","translations":{"validators":{"fr":"Cette URL doit contenir un domaine de premier niveau.","en":"This URL is missing a top-level domain."}}};
export const THIS_VALUE_IS_TOO_SHORT_IT_SHOULD_CONTAIN_AT_LEAST_ONE_WORD_THIS_VALUE_IS_TOO_SHORT_IT_SHOULD_CONTAIN_AT_LEAST_MIN_WORDS = {"id":"This value is too short. It should contain at least one word.|This value is too short. It should contain at least {{ min }} words.","translations":{"validators":{"fr":"Cette valeur est trop courte. Elle doit contenir au moins un mot.|Cette valeur est trop courte. Elle doit contenir au moins {{ min }} mots.","en":"This value is too short. It should contain at least one word.|This value is too short. It should contain at least {{ min }} words."}}};
export const THIS_VALUE_IS_TOO_LONG_IT_SHOULD_CONTAIN_ONE_WORD_THIS_VALUE_IS_TOO_LONG_IT_SHOULD_CONTAIN_MAX_WORDS_OR_LESS = {"id":"This value is too long. It should contain one word.|This value is too long. It should contain {{ max }} words or less.","translations":{"validators":{"fr":"Cette valeur est trop longue. Elle doit contenir au maximum un mot.|Cette valeur est trop longue. Elle doit contenir au maximum {{ max }} mots.","en":"This value is too long. It should contain one word.|This value is too long. It should contain {{ max }} words or less."}}};
export const THIS_VALUE_DOES_NOT_REPRESENT_A_VALID_WEEK_IN_THE_ISO8601_FORMAT = {"id":"This value does not represent a valid week in the ISO 8601 format.","translations":{"validators":{"fr":"Cette valeur ne repr\u00e9sente pas une semaine valide au format ISO 8601.","en":"This value does not represent a valid week in the ISO 8601 format."}}};
export const THIS_VALUE_IS_NOT_A_VALID_WEEK = {"id":"This value is not a valid week.","translations":{"validators":{"fr":"Cette valeur n'est pas une semaine valide.","en":"This value is not a valid week."}}};
export const THIS_VALUE_SHOULD_NOT_BE_BEFORE_WEEK_MIN = {"id":"This value should not be before week \"{{ min }}\".","translations":{"validators":{"fr":"Cette valeur ne doit pas \u00eatre ant\u00e9rieure \u00e0 la semaine \"{{ min }}\".","en":"This value should not be before week \"{{ min }}\"."}}};
export const THIS_VALUE_SHOULD_NOT_BE_AFTER_WEEK_MAX = {"id":"This value should not be after week \"{{ max }}\".","translations":{"validators":{"fr":"Cette valeur ne doit pas \u00eatre post\u00e9rieure \u00e0 la semaine \"{{ max }}\".","en":"This value should not be after week \"{{ max }}\"."}}};
export const THIS_FORM_SHOULD_NOT_CONTAIN_EXTRA_FIELDS = {"id":"This form should not contain extra fields.","translations":{"validators":{"fr":"Ce formulaire ne doit pas contenir de champs suppl\u00e9mentaires.","en":"This form should not contain extra fields."}}};
export const THE_UPLOADED_FILE_WAS_TOO_LARGE_PLEASE_TRY_TO_UPLOAD_A_SMALLER_FILE = {"id":"The uploaded file was too large. Please try to upload a smaller file.","translations":{"validators":{"fr":"Le fichier t\u00e9l\u00e9charg\u00e9 est trop volumineux. Merci d'essayer d'envoyer un fichier plus petit.","en":"The uploaded file was too large. Please try to upload a smaller file."}}};
export const THE_CSRF_TOKEN_IS_INVALID_PLEASE_TRY_TO_RESUBMIT_THE_FORM = {"id":"The CSRF token is invalid. Please try to resubmit the form.","translations":{"validators":{"fr":"Pour des raisons de s\u00e9curit\u00e9, merci de renvoyer le formulaire.","en":"For security reasons, please resend the form."}}};
export const THIS_VALUE_IS_NOT_A_VALID_HTML5_COLOR = {"id":"This value is not a valid HTML5 color.","translations":{"validators":{"fr":"Cette valeur n'est pas une couleur HTML5 valide.","en":"This value is not a valid HTML5 color."}}};
export const PLEASE_ENTER_A_VALID_BIRTHDATE = {"id":"Please enter a valid birthdate.","translations":{"validators":{"fr":"Veuillez entrer une date de naissance valide.","en":"Please enter a valid birthdate."}}};
export const THE_SELECTED_CHOICE_IS_INVALID = {"id":"The selected choice is invalid.","translations":{"validators":{"fr":"Le choix s\u00e9lectionn\u00e9 est invalide.","en":"The selected choice is invalid."}}};
export const THE_COLLECTION_IS_INVALID = {"id":"The collection is invalid.","translations":{"validators":{"fr":"La collection est invalide.","en":"The collection is invalid."}}};
export const PLEASE_SELECT_A_VALID_COLOR = {"id":"Please select a valid color.","translations":{"validators":{"fr":"Veuillez s\u00e9lectionner une couleur valide.","en":"Please select a valid color."}}};
export const PLEASE_SELECT_A_VALID_COUNTRY = {"id":"Please select a valid country.","translations":{"validators":{"fr":"Veuillez s\u00e9lectionner un pays valide.","en":"Please select a valid country."}}};
export const PLEASE_SELECT_A_VALID_CURRENCY = {"id":"Please select a valid currency.","translations":{"validators":{"fr":"Veuillez s\u00e9lectionner une devise valide.","en":"Please select a valid currency."}}};
export const PLEASE_CHOOSE_A_VALID_DATE_INTERVAL = {"id":"Please choose a valid date interval.","translations":{"validators":{"fr":"Veuillez choisir un intervalle de dates valide.","en":"Please choose a valid date interval."}}};
export const PLEASE_ENTER_A_VALID_DATE_AND_TIME = {"id":"Please enter a valid date and time.","translations":{"validators":{"fr":"Veuillez saisir une date et une heure valides.","en":"Please enter a valid date and time."}}};
export const PLEASE_ENTER_A_VALID_DATE = {"id":"Please enter a valid date.","translations":{"validators":{"fr":"Veuillez entrer une date valide.","en":"Please enter a valid date."}}};
export const PLEASE_SELECT_A_VALID_FILE = {"id":"Please select a valid file.","translations":{"validators":{"fr":"Veuillez s\u00e9lectionner un fichier valide.","en":"Please select a valid file."}}};
export const THE_HIDDEN_FIELD_IS_INVALID = {"id":"The hidden field is invalid.","translations":{"validators":{"fr":"Le champ masqu\u00e9 n'est pas valide.","en":"The hidden field is invalid."}}};
export const PLEASE_ENTER_AN_INTEGER = {"id":"Please enter an integer.","translations":{"validators":{"fr":"Veuillez saisir un entier.","en":"Please enter an integer."}}};
export const PLEASE_SELECT_A_VALID_LANGUAGE = {"id":"Please select a valid language.","translations":{"validators":{"fr":"Veuillez s\u00e9lectionner une langue valide.","en":"Please select a valid language."}}};
export const PLEASE_SELECT_A_VALID_LOCALE = {"id":"Please select a valid locale.","translations":{"validators":{"fr":"Veuillez s\u00e9lectionner une langue valide.","en":"Please select a valid locale."}}};
export const PLEASE_ENTER_A_VALID_MONEY_AMOUNT = {"id":"Please enter a valid money amount.","translations":{"validators":{"fr":"Veuillez saisir un montant valide.","en":"Please enter a valid money amount."}}};
export const PLEASE_ENTER_A_NUMBER = {"id":"Please enter a number.","translations":{"validators":{"fr":"Veuillez saisir un nombre.","en":"Please enter a number."}}};
export const THE_PASSWORD_IS_INVALID = {"id":"The password is invalid.","translations":{"validators":{"fr":"Le mot de passe est invalide.","en":"The password is invalid."}}};
export const PLEASE_ENTER_A_PERCENTAGE_VALUE = {"id":"Please enter a percentage value.","translations":{"validators":{"fr":"Veuillez saisir un pourcentage valide.","en":"Please enter a percentage value."}}};
export const THE_VALUES_DO_NOT_MATCH = {"id":"The values do not match.","translations":{"validators":{"fr":"Les valeurs ne correspondent pas.","en":"The values do not match."}}};
export const PLEASE_ENTER_A_VALID_TIME = {"id":"Please enter a valid time.","translations":{"validators":{"fr":"Veuillez saisir une heure valide.","en":"Please enter a valid time."}}};
export const PLEASE_SELECT_A_VALID_TIMEZONE = {"id":"Please select a valid timezone.","translations":{"validators":{"fr":"Veuillez s\u00e9lectionner un fuseau horaire valide.","en":"Please select a valid timezone."}}};
export const PLEASE_ENTER_A_VALID_URL = {"id":"Please enter a valid URL.","translations":{"validators":{"fr":"Veuillez saisir une URL valide.","en":"Please enter a valid URL."}}};
export const PLEASE_ENTER_A_VALID_SEARCH_TERM = {"id":"Please enter a valid search term.","translations":{"validators":{"fr":"Veuillez saisir un terme de recherche valide.","en":"Please enter a valid search term."}}};
export const PLEASE_PROVIDE_A_VALID_PHONE_NUMBER = {"id":"Please provide a valid phone number.","translations":{"validators":{"fr":"Veuillez fournir un num\u00e9ro de t\u00e9l\u00e9phone valide.","en":"Please provide a valid phone number."}}};
export const THE_CHECKBOX_HAS_AN_INVALID_VALUE = {"id":"The checkbox has an invalid value.","translations":{"validators":{"fr":"La case \u00e0 cocher a une valeur non valide.","en":"The checkbox has an invalid value."}}};
export const PLEASE_ENTER_A_VALID_EMAIL_ADDRESS = {"id":"Please enter a valid email address.","translations":{"validators":{"fr":"Veuillez saisir une adresse email valide.","en":"Please enter a valid email address."}}};
export const PLEASE_SELECT_A_VALID_OPTION = {"id":"Please select a valid option.","translations":{"validators":{"fr":"Veuillez s\u00e9lectionner une option valide.","en":"Please select a valid option."}}};
export const PLEASE_SELECT_A_VALID_RANGE = {"id":"Please select a valid range.","translations":{"validators":{"fr":"Veuillez s\u00e9lectionner une plage valide.","en":"Please select a valid range."}}};
export const PLEASE_ENTER_A_VALID_WEEK = {"id":"Please enter a valid week.","translations":{"validators":{"fr":"Veuillez entrer une semaine valide.","en":"Please enter a valid week."}}};
export const THIS_VALUE_IS_NOT_A_VALID_PHONE_NUMBER = {"id":"This value is not a valid phone number.","translations":{"validators":{"fr":"Cette valeur n'est pas un num\u00e9ro de t\u00e9l\u00e9phone valide.","en":"This value is not a valid phone number."}}};
export const THIS_VALUE_IS_NOT_A_VALID_FIXED_LINE_NUMBER = {"id":"This value is not a valid fixed-line number.","translations":{"validators":{"fr":"Cette valeur n'est pas un num\u00e9ro de t\u00e9l\u00e9phone fixe valide.","en":"This value is not a valid fixed-line number."}}};
export const THIS_VALUE_IS_NOT_A_VALID_MOBILE_NUMBER = {"id":"This value is not a valid mobile number.","translations":{"validators":{"fr":"Cette valeur n'est pas un num\u00e9ro de t\u00e9l\u00e9phone mobile valide.","en":"This value is not a valid mobile number."}}};
export const THIS_VALUE_IS_NOT_A_VALID_PAGER_NUMBER = {"id":"This value is not a valid pager number.","translations":{"validators":{"fr":"Cette valeur n'est pas un num\u00e9ro de t\u00e9l\u00e9phone bipeur valide.","en":"This value is not a valid pager number."}}};
export const THIS_VALUE_IS_NOT_A_VALID_PERSONAL_NUMBER = {"id":"This value is not a valid personal number.","translations":{"validators":{"fr":"Cette valeur n'est pas un num\u00e9ro de t\u00e9l\u00e9phone personnel valide.","en":"This value is not a valid personal number."}}};
export const THIS_VALUE_IS_NOT_A_VALID_PREMIUM_RATE_NUMBER = {"id":"This value is not a valid premium-rate number.","translations":{"validators":{"fr":"Cette valeur n'est pas un num\u00e9ro de t\u00e9l\u00e9phone premium valide.","en":"This value is not a valid premium-rate number."}}};
export const THIS_VALUE_IS_NOT_A_VALID_SHARED_COST_NUMBER = {"id":"This value is not a valid shared-cost number.","translations":{"validators":{"fr":"Cette valeur n'est pas un num\u00e9ro \u00e0 co\u00fbt partag\u00e9 valide.","en":"This value is not a valid shared-cost number."}}};
export const THIS_VALUE_IS_NOT_A_VALID_TOLL_FREE_NUMBER = {"id":"This value is not a valid toll-free number.","translations":{"validators":{"fr":"Cette valeur n'est pas un num\u00e9ro vert valide.","en":"This value is not a valid toll-free number."}}};
export const THIS_VALUE_IS_NOT_A_VALID_UAN = {"id":"This value is not a valid UAN.","translations":{"validators":{"fr":"Cette valeur n'est pas un num\u00e9ro de t\u00e9l\u00e9phone UAN valide.","en":"This value is not a valid UAN."}}};
export const THIS_VALUE_IS_NOT_A_VALID_VO_IP_NUMBER = {"id":"This value is not a valid VoIP number.","translations":{"validators":{"fr":"Cette valeur n'est pas un num\u00e9ro de t\u00e9l\u00e9phone VoIP valide.","en":"This value is not a valid VoIP number."}}};
export const THIS_VALUE_IS_NOT_A_VALID_VOICEMAIL_ACCESS_NUMBER = {"id":"This value is not a valid voicemail access number.","translations":{"validators":{"fr":"Cette valeur n'est pas un num\u00e9ro de r\u00e9pondeur valide.","en":"This value is not a valid voicemail access number."}}};
export const AMPLITUDE_EVENT_TYPE_INVALID = {"id":"amplitude.event_type.invalid","translations":{"validators":{"fr":"Le type {{ type }} est invalide seul les types '{{ types }}' sont valide","en":"The type {{ type }} is invalid; only types '{{ types }}' are valid"}}};
export const AMPLITUDE_EVENT_PROPERTIES_MISSING_PARAMETER = {"id":"amplitude.event_properties.missing_parameter","translations":{"validators":{"fr":"Le param\u00e8tre '{{ param }}' est manquant","en":"The parameter '{{ param }}' is missing"}}};
export const AMPLITUDE_USER_PROPERTIES_MISSING_PARAMETER = {"id":"amplitude.user_properties.missing_parameter","translations":{"validators":{"fr":"Le param\u00e8tre '{{ param }}' est manquant","en":"The parameter '{{ param }}' is missing"}}};
export const CONTACT_EMAIL_NOT_BLANK = {"id":"contact.email.not_blank","translations":{"validators":{"fr":"Veuillez saisir une adresse e-mail","en":"Please enter email address"}}};
export const CONTACT_EMAIL_INVALID = {"id":"contact.email.invalid","translations":{"validators":{"fr":"Veuillez saisir une adresse e-mail valide","en":"Please enter a valid email address"}}};
export const CONTACT_FIRST_NAME_NOT_BLANK = {"id":"contact.first_name.not_blank","translations":{"validators":{"fr":"Veuillez saisir un pr\u00e9nom","en":"Please enter first name"}}};
export const CONTACT_LAST_NAME_NOT_BLANK = {"id":"contact.last_name.not_blank","translations":{"validators":{"fr":"Veuillez saisir un nom de famille","en":"Please enter last name"}}};
export const CONTACT_RELATIONSHIP_NOT_BLANK = {"id":"contact.relationship.not_blank","translations":{"validators":{"fr":"Veuillez saisir un lien de parent\u00e9","en":"Please enter relationship"}}};
export const SHORT_LINK_EXPIRED = {"id":"short_link.expired","translations":{"validators":{"fr":"Ce lien a expir\u00e9","en":"This link expired"}}};
export const DOCUMENT_TITLE_NOT_BLANK = {"id":"document.title.not_blank","translations":{"validators":{"fr":"Veuillez entrer un titre de document","en":"Please enter document title"}}};
export const DOCUMENT_NAME_NOT_BLANK = {"id":"document.name.not_blank","translations":{"validators":{"fr":"Veuilez passer un nom de document","en":"Please provide document name"}}};
export const DOCUMENT_MIME_TYPE_NOT_BLANK = {"id":"document.mime_type.not_blank","translations":{"validators":{"fr":"Type de document incorrect","en":"Invalid document type"}}};
export const DOCUMENT_MIME_TYPE_INCORRECT = {"id":"document.mime_type.incorrect","translations":{"validators":{"fr":"Type de document incorrect","en":"Invalid document type"}}};
export const DOCUMENT_FILE_NOT_BLANK = {"id":"document.file.not_blank","translations":{"validators":{"fr":"Veuillez uploader un document","en":"Please upload document"}}};
export const DOCUMENT_FILE_INVALID = {"id":"document.file.invalid","translations":{"validators":{"fr":"Veuillez uploader un document valide","en":"Please upload a valid document"}}};
export const DOCUMENT_FILE_INCORRECT = {"id":"document.file.incorrect","translations":{"validators":{"fr":"Veuillez uploader un document valide","en":"Please upload a valid document"}}};
export const DOCUMENT_FILE_TOO_LARGE = {"id":"document.file.too_large","translations":{"validators":{"fr":"Le fichier ne peut pas d\u00e9passer {{ size }} Mo","en":"The file cannot exceed {{ size }} MB"}}};
export const DOCUMENT_FILE_IMAGE = {"id":"document.file.image","translations":{"validators":{"fr":"Veuillez s\u00e9lectionner un fichier dont le format est pris en charge (image, document...)","en":"Please select supported file format (image, document, etc.)"}}};
export const DOCUMENT_USER_NOT_BLANK = {"id":"document.user.not_blank","translations":{"validators":{"fr":"Veuillez associer le document \u00e0 un patient","en":"Please associate document with a patient"}}};
export const DOCUMENT_USER_INCORRECT = {"id":"document.user.incorrect","translations":{"validators":{"fr":"Vous ne pouvez pas associer de document \u00e0 cet utilisateur","en":"You cannot associate document with this user"}}};
export const DOCUMENT_TYPE_NOT_BLANK = {"id":"document.type.not_blank","translations":{"validators":{"fr":"Veuillez choisir un type de document","en":"Please choose document type"}}};
export const DOCUMENT_PARSE_TYPE_NOT_HANDLED = {"id":"document.parse.type_not_handled","translations":{"validators":{"fr":"Type de document impossible \u00e0 parser","en":"Cannot parse document type"}}};
export const DOCUMENT_BULK_EMPTY = {"id":"document.bulk.empty","translations":{"validators":{"fr":"Veuillez uploader au moins un document","en":"Please upload at least one document"}}};
export const DOCUMENT_PREVIEW_FILE_NOT_FOUND = {"id":"document.preview.file_not_found","translations":{"validators":{"fr":"Fichier introuvable","en":"File not found"}}};
export const DOCUMENT_PREVIEW_URL_EXPIRED = {"id":"document.preview_url.expired","translations":{"validators":{"fr":"Le lien vers le fichier est expir\u00e9","en":"Link to the file has expired"}}};
export const DOCUMENT_CREATED_DATE_FUTURE = {"id":"document.created_date.future","translations":{"validators":{"fr":"Vous ne pouvez pas cr\u00e9er un document dans le futur","en":"You cannot create document in the future"}}};
export const DOCUMENT_MAX_UPLOAD = {"id":"document.max_upload","translations":{"validators":{"fr":"Vous ne pouvez pas uploader plus de {{ max }} documents par patient avec votre abonnement actuel. Si vous souhaitez augmenter cette limite, vous pouvez choisir l'un de nos abonnements","en":"You cannot upload more than {{ max }} documents per patient with your current subscription. If you wish to increase this limit, you can choose one of our subscription plans."}}};
export const DOCUMENT_MD5_HASH_DUPLICATE = {"id":"document.md5_hash.duplicate","translations":{"validators":{"fr":"Ce document est d\u00e9j\u00e0 dans le dossier de ce patient sous le nom '{{ name }}'","en":"This document is already in this patient's medical record with the name '{{ name }}'"}}};
export const COMMENT_CONTENT_NOT_BLANK = {"id":"comment.content.not_blank","translations":{"validators":{"fr":"Veuillez entrer un contenu","en":"Please enter content"}}};
export const DOCUMENT_COMMENT_DOCUMENT_NOT_NULL = {"id":"document_comment.document.not_null","translations":{"validators":{"fr":"Veuillez attacher votre commentaire \u00e0 un document","en":"Please attach your comment to document"}}};
export const EVENT_NAME_NOT_BLANK = {"id":"event.name.not_blank","translations":{"validators":{"fr":"Veuillez saisir un nom d'\u00e9v\u00e9nement","en":"Please enter appointment name"}}};
export const EVENT_TYPE_NOT_BLANK = {"id":"event.type.not_blank","translations":{"validators":{"fr":"Veuillez choisir un type d'\u00e9v\u00e9nement","en":"Please choose appointment type"}}};
export const EVENT_DATE_BEGIN_NOT_BLANK = {"id":"event.date_begin.not_blank","translations":{"validators":{"fr":"Veuillez saisir une date de d\u00e9but","en":"Please enter start date"}}};
export const EVENT_DATE_BEGIN_PAST = {"id":"event.date_begin.past","translations":{"validators":{"fr":"Vous ne pouvez pas modifier d'\u00e9v\u00e9nement dans le pass\u00e9","en":"You cannot modify an appointment in the past"}}};
export const EVENT_DATE_BEGIN_PUBLIC_HOLIDAY = {"id":"event.date_begin.public_holiday","translations":{"validators":{"fr":"Vous ne pouvez pas ajouter d'\u00e9v\u00e9nement un jour f\u00e9ri\u00e9","en":"You cannot add appointment on a public holiday"}}};
export const EVENT_DURATION_NOT_BLANK = {"id":"event.duration.not_blank","translations":{"validators":{"fr":"Veuillez saisir une dur\u00e9e d'\u00e9v\u00e9nement","en":"Please enter appointment duration"}}};
export const EVENT_PARTICIPANTS_NOT_BLANK = {"id":"event.participants.not_blank","translations":{"validators":{"fr":"Veuillez entrer un ou plusieurs utilisateurs","en":"Please enter one or more users"}}};
export const EVENT_PARTICIPANTS_FORBIDDEN = {"id":"event.participants.forbidden","translations":{"validators":{"fr":"Vous ne pouvez pas modifier le participant {{ participant }} de cet \u00e9v\u00e9nement","en":"You cannot modify the participant {{ participant }} of this appointment"}}};
export const EVENT_PARTICIPANTS_MUST_BE_PARTICIPANT = {"id":"event.participants.must_be_participant","translations":{"validators":{"fr":"Vous ne pouvez pas cr\u00e9er un \u00e9v\u00e9nement pour un autre soignant de votre \u00e9tablissement sans que vous soyez \u00e9galement participant","en":"You cannot create appointment for another caregiver at your facility unless you are also a participant"}}};
export const EVENT_PARTICIPANTS_NOT_FOUND = {"id":"event.participants.not_found","translations":{"validators":{"fr":"L'utilisateur ne fait pas partie de l'\u00e9v\u00e9nement","en":"User is not part of appointment"}}};
export const EVENT_PARTICIPANTS_REMOVE_FORBIDDEN = {"id":"event.participants.remove_forbidden","translations":{"validators":{"fr":"Vous ne pouvez pas supprimer cet utilisateur","en":"You cannot remove this user"}}};
export const EVENT_PARTICIPANTS_ADD_FORBIDDEN = {"id":"event.participants.add_forbidden","translations":{"validators":{"fr":"Vous ne pouvez pas ajouter cet utilisateur","en":"You cannot add this user"}}};
export const EVENT_OWNER_INVALID = {"id":"event.owner.invalid","translations":{"validators":{"fr":"Vous ne pouvez pas modifier cet \u00e9v\u00e9nement","en":"You cannot modify this appointment"}}};
export const EVENT_EVENT_TYPE_INVALID = {"id":"event.event_type.invalid","translations":{"validators":{"fr":"Vous ne pouvez pas cr\u00e9er ce type d'\u00e9v\u00e9nement","en":"You cannot create this type of appointment"}}};
export const EVENT_STATUS_PATIENT_WRONG_STATUS = {"id":"event.status.patient_wrong_status","translations":{"validators":{"fr":"Le statut de l'\u00e9v\u00e9nement n'est pas autoris\u00e9 pour un patient","en":"The appointment status is not allowed for patient"}}};
export const EVENT_STATUS_NOT_TODAY = {"id":"event.status.not_today","translations":{"validators":{"fr":"Ce status n'est pas autoris\u00e9 lorsque l'\u00e9v\u00e9nement n'est pas aujourd'hui","en":"This status is not allowed when the appointment is not today"}}};
export const EVENT_RECURRING_INTERVAL_EMPTY = {"id":"event.recurring_interval.empty","translations":{"validators":{"fr":"Pr\u00e9cisez un interval de r\u00e9currence","en":"Specify recurrence interval"}}};
export const EVENT_RECURRING_INTERVAL_NOT_IN_RANGE = {"id":"event.recurring_interval.not_in_range","translations":{"validators":{"fr":"Le nombre de semaine de r\u00e9currence doit \u00eatre compris entre {{ min }} et {{ max }}.","en":"The recurrence week number must be between {{ min }} and {{ max }}."}}};
export const EVENT_RECURRING_DAY_EMPTY = {"id":"event.recurring_day.empty","translations":{"validators":{"fr":"Pr\u00e9cisez au moins un jour de r\u00e9currence","en":"Specify at least one recurrence day"}}};
export const EVENT_RECURRING_DAY_INVALID = {"id":"event.recurring_day.invalid","translations":{"validators":{"fr":"Le jour de r\u00e9currence doit \u00eatre compris entre 1 et 7.","en":"The recurrence day must be between 1 and 7."}}};
export const EVENT_REPEAT_END_COUNT_EMPTY = {"id":"event.repeat_end_count.empty","translations":{"validators":{"fr":"Veuillez pr\u00e9ciser une fr\u00e9quence","en":"Please specify frequency"}}};
export const EVENT_RECURRING_STOP_DATE_EMPTY = {"id":"event.recurring_stop_date.empty","translations":{"validators":{"fr":"Veuillez pr\u00e9ciser une date de fin","en":"Please specify end date"}}};
export const EVENT_RECURRING_NOT_IN_PAST = {"id":"event.recurring_not_in_past","translations":{"validators":{"fr":"Vous ne pouvez pas cr\u00e9er un \u00e9v\u00e9nement r\u00e9current dans le pass\u00e9","en":"You cannot create recurring appointment in the past"}}};
export const DISCUSSION_TITLE_NOT_BLANK = {"id":"discussion.title.not_blank","translations":{"validators":{"fr":"Veuillez saisir un nom de discussion","en":"Please enter conversation name"}}};
export const DISCUSSION_PARTICIPANTS_NOT_BLANK = {"id":"discussion.participants.not_blank","translations":{"validators":{"fr":"Veuillez saisir au moins un participant","en":"Please enter at least one participant"}}};
export const DISCUSSION_PARTICIPANTS_EXISTING = {"id":"discussion.participants.existing","translations":{"validators":{"fr":"L'utilisateur fait d\u00e9j\u00e0 partie de la discussion","en":"The user is already part of the conversation"}}};
export const DISCUSSION_PARTICIPANTS_NOT_FOUND = {"id":"discussion.participants.not_found","translations":{"validators":{"fr":"L'utilisateur ne fait pas partie de la discussion","en":"The user is not part of the conversation"}}};
export const DISCUSSION_PARTICIPANTS_ADD_FORBIDDEN = {"id":"discussion.participants.add_forbidden","translations":{"validators":{"fr":"Vous ne pouvez pas ajouter cet utilisateur","en":"You cannot add this user"}}};
export const DISCUSSION_PARTICIPANTS_REMOVE_FORBIDDEN = {"id":"discussion.participants.remove_forbidden","translations":{"validators":{"fr":"Vous ne pouvez pas supprimer cet utilisateur","en":"You cannot remove this user"}}};
export const DISCUSSION_PARTICIPANTS_REMOVE_OWNER_FORBIDDEN = {"id":"discussion.participants.remove_owner_forbidden","translations":{"validators":{"fr":"Vous ne pouvez pas supprimer le propri\u00e9taire de la discussion","en":"You cannot remove the owner of conversation"}}};
export const DISCUSSION_PARTICIPANTS_REMOVE_MYSELF = {"id":"discussion.participants.remove_myself","translations":{"validators":{"fr":"Vous ne pouvez pas vous retirer d'une conversation","en":"You cannot remove yourself from conversation"}}};
export const DISCUSSION_PATIENT_INVISIBLE = {"id":"discussion.patient.invisible","translations":{"validators":{"fr":"Vous ne pouvez pas cr\u00e9er cette discussion car le patient {{ patient }} n'est pas accessible \u00e0 {{ medic }}. Veuillez lui partager le dossier puis r\u00e9essayer.","en":"You cannot create this conversation because the patient {{ patient }} is not accessible to {{ medic }}. Please share the file with them then try again."}}};
export const DISCUSSION_ARCHIVED_BY_EVERYONE = {"id":"discussion.archived.by_everyone","translations":{"validators":{"fr":"Vous ne pouvez pas envoyer de message sur une conversation archiv\u00e9e par tous les autres membres.","en":"You cannot send message in a conversation archived by all other members."}}};
export const DISCUSSION_ARCHIVED_BY_YOU = {"id":"discussion.archived.by_you","translations":{"validators":{"fr":"Vous ne pouvez pas envoyer de message sur une conversation archiv\u00e9e.","en":"You cannot send message in a conversation you have archived."}}};
export const DISCUSSION_DELETED_BY_EVERYONE = {"id":"discussion.deleted.by_everyone","translations":{"validators":{"fr":"Vous ne pouvez pas envoyer de message car vous \u00eates le seul participant.","en":"You cannot send message because you are the only participant."}}};
export const DISCUSSION_DELETED_BY_YOU = {"id":"discussion.deleted.by_you","translations":{"validators":{"fr":"Vous ne pouvez pas envoyer de message sur une conversation supprim\u00e9e.","en":"You cannot send message in deleted conversation."}}};
export const DISCUSSION_USER_DISABLED = {"id":"discussion.user.disabled","translations":{"validators":{"fr":"L'utilisateur {{ user }} est d\u00e9sactiv\u00e9","en":"The user {{ user }} is deactivated"}}};
export const DISCUSSION_BLOCKED_CONTACT = {"id":"discussion.blocked.contact","translations":{"validators":{"fr":"Votre professionnel de sant\u00e9 a d\u00e9sactiv\u00e9 sa messagerie sur Instamed.","en":"Your healthcare professional has disabled messaging on Instamed."}}};
export const DISCUSSION_BLOCKED_CONTACT_EMAIL = {"id":"discussion.blocked.contact_email","translations":{"validators":{"fr":"Votre professionnel de sant\u00e9 a d\u00e9sactiv\u00e9 sa messagerie sur Instamed. Il a indiqu\u00e9 pr\u00e9f\u00e9r\u00e9 \u00eatre contact\u00e9 par email sur l\u2019adresse suivante {{ email }}","en":"Your healthcare professional has disabled messaging on Instamed. They have indicated a preference to be contacted by email at the following address: {{ email }}"}}};
export const DISCUSSION_BLOCKED_CONTACT_PHONE = {"id":"discussion.blocked.contact_phone","translations":{"validators":{"fr":"Votre professionnel de sant\u00e9 a d\u00e9sactiv\u00e9 sa messagerie sur Instamed. Il a indiqu\u00e9 pr\u00e9f\u00e9r\u00e9 \u00eatre contact\u00e9 par t\u00e9l\u00e9phone au {{ phone }}","en":"Your healthcare professional has disabled messaging on Instamed. They have indicated a preference to be contacted by phone at {{ phone }}"}}};
export const DISCUSSION_BLOCKED_CANT_DISARCHIVE = {"id":"discussion.blocked.cant_disarchive","translations":{"validators":{"fr":"Vous ne pouvez pas archiver cette discussion car vous avez d\u00e9sactiv\u00e9 la messagerie","en":"You cannot unarchive this conversation because you have disabled messaging"}}};
export const DISCUSSION_BLOCKED_CANT_ARCHIVE = {"id":"discussion.blocked.cant_archive","translations":{"validators":{"fr":"Vous ne pouvez pas archiver cette discussion car vous avez d\u00e9sactiv\u00e9 la messagerie","en":"You cannot archive this conversation because you have disabled messaging"}}};
export const DISCUSSION_BLOCKED_CANT_DELETE = {"id":"discussion.blocked.cant_delete","translations":{"validators":{"fr":"Vous ne pouvez pas supprimer cette discussion car vous avez d\u00e9sactiv\u00e9 la messagerie","en":"You cannot delete this conversation because you have disabled messaging"}}};
export const MESSAGE_CONTENT_NOT_BLANK = {"id":"message.content.not_blank","translations":{"validators":{"fr":"Veuillez saisir votre message.","en":"Please enter your message."}}};
export const MESSAGE_DISCUSSION_NOT_BLANK = {"id":"message.discussion.not_blank","translations":{"validators":{"fr":"Veuillez s\u00e9lectionner une discussion","en":"Please select conversation"}}};
export const MESSAGE_DOCUMENTS_MAX = {"id":"message.documents.max","translations":{"validators":{"fr":"Vous ne pouvez pas ajouter plus de {{ max }} documents \u00e0 un message.","en":"You can add no more than {{ max }} documents to message."}}};
export const DISEASE_NOT_FOUND = {"id":"disease.not_found","translations":{"validators":{"fr":"Aucune maladie trouv\u00e9e pour ce code","en":"No disease found for this code"}}};
export const DISEASE_NAME_NOT_BLANK = {"id":"disease.name.not_blank","translations":{"validators":{"fr":"Veuillez saisir un nom de maladie","en":"Please enter disease name"}}};
export const DISEASE_CIM11_CODE_NOT_BLANK = {"id":"disease.cim_11_code.not_blank","translations":{"validators":{"fr":"Veuillez saisir une maladie","en":"Please enter disease"}}};
export const DISEASE_CIM11_CODE_INVALID = {"id":"disease.cim_11_code.invalid","translations":{"validators":{"fr":"Veuillez saisir un code cim-11 valide","en":"Please enter valid CIM-11 code"}}};
export const DISEASE_PATIENT_NOT_NULL = {"id":"disease.patient.not_null","translations":{"validators":{"fr":"Veuillez lier la maladie a un patient","en":"Please link the disease to patient"}}};
export const DISEASE_ENTITY_SCALE_INVALID = {"id":"disease.entity_scale.invalid","translations":{"validators":{"fr":"La valeur {{ value }} est invalide. Elle doit \u00eatre l'un des choix suivant {{ choices }}","en":"The value {{ value }} is invalid. It must be one of the following choices {{ choices }}"}}};
export const INEXACT_DATE_ENTITY_DATE_INVALID = {"id":"inexact_date_entity.date.invalid","translations":{"validators":{"fr":"Veuillez saisir une date valide","en":"Please enter valid date"}}};
export const INEXACT_DATE_ENTITY_DATE_MAX = {"id":"inexact_date_entity.date.max","translations":{"validators":{"fr":"Veuillez saisir une date inf\u00e9rieure ou \u00e9gale \u00e0 aujourd'hui","en":"Please enter date less than or equal to today's date"}}};
export const INEXACT_DATE_ENTITY_START_DATE_INVALID = {"id":"inexact_date_entity.start_date.invalid","translations":{"validators":{"fr":"Veuillez saisir une date valide","en":"Please enter valid date"}}};
export const INEXACT_DATE_ENTITY_START_DATE_MAX = {"id":"inexact_date_entity.start_date.max","translations":{"validators":{"fr":"Veuillez saisir une date inf\u00e9rieure ou \u00e9gale \u00e0 aujourd'hui","en":"Please enter date less than or equal to today's date"}}};
export const INEXACT_DATE_ENTITY_ENTITY_SCALE_INVALID = {"id":"inexact_date_entity.entity_scale.invalid","translations":{"validators":{"fr":"La valeur {{ value }} est invalide. Elle doit \u00eatre l'un des choix suivant {{ choices }}","en":"The value {{ value }} is invalid. It must be one of the following choices {{ choices }}"}}};
export const TREATMENT_NAME_NOT_BLANK = {"id":"treatment.name.not_blank","translations":{"validators":{"fr":"Veuillez saisir un nom de traitement","en":"Please enter treatment name"}}};
export const DOCUMENT_TYPE_DELETE_USED = {"id":"document_type.delete.used","translations":{"validators":{"fr":"Vous ne pouvez pas supprimer un type de document utilis\u00e9 au sein du projet","en":"You cannot delete a document type used within the project"}}};
export const DOCUMENT_TYPE_NOT_SUPPORTED = {"id":"document_type.not_supported","translations":{"validators":{"fr":"Ce type de document n'est pas support\u00e9","en":"This document type is not supported"}}};
export const DOCUMENT_TYPE_NOT_IN_SERVICE = {"id":"document_type.not_in_service","translations":{"validators":{"fr":"Ce type de document n'est pas pr\u00e9sent dans le service","en":"This document type is not in the service"}}};
export const DOCUMENT_TYPE_PRIVATE_VISIBILITY_NOT_NULL = {"id":"document_type.private_visibility.not_null","translations":{"validators":{"fr":"Veuillez saisir une visibilit\u00e9 par d\u00e9faut","en":"Please enter default visibility"}}};
export const DOCUMENT_TYPE_MERGE_DIFFERENT = {"id":"document_type.merge.different","translations":{"validators":{"fr":"Les types de document s\u00e9lectionn\u00e9s doivent \u00eatre diff\u00e9rents","en":"The selected document types must be different"}}};
export const PATIENT_TREATMENT_PATIENT_NOT_BLANK = {"id":"patient_treatment.patient.not_blank","translations":{"validators":{"fr":"Veuillez entrer un patient","en":"Please enter patient"}}};
export const PATIENT_TREATMENT_TREATMENT_NOT_BLANK = {"id":"patient_treatment.treatment.not_blank","translations":{"validators":{"fr":"Veuillez saisir un traitement","en":"Please enter treatment"}}};
export const PATIENT_TREATMENT_TREATMENT_NOT_ALLOWED = {"id":"patient_treatment.treatment.not_allowed","translations":{"validators":{"fr":"Seul un professionnel de sant\u00e9 peut rattacher un traitement \u00e0 un patient"}}};
export const PATIENT_TREATMENT_START_DATE_INVALID = {"id":"patient_treatment.start_date.invalid","translations":{"validators":{"fr":"Veuillez saisir une date de d\u00e9but","en":"Please enter start date"}}};
export const PATIENT_TREATMENT_END_DATE_INVALID = {"id":"patient_treatment.end_date.invalid","translations":{"validators":{"fr":"Veuillez saisir une date de fin apr\u00e8s la date de d\u00e9but","en":"Please enter and end date after the start date"}}};
export const PATIENT_TREATMENT_RECURRENCES_INVALID = {"id":"patient_treatment.recurrences.invalid","translations":{"validators":{"fr":"Veuillez saisir un nombre de traitements valide","en":"Please enter valid number of treatments"}}};
export const PATIENT_TREATMENT_RECURRENCES_MAX = {"id":"patient_treatment.recurrences.max","translations":{"validators":{"fr":"Vous ne pouvez pas cr\u00e9er plus de {{ limit }} traitements","en":"You can create no more than {{ limit }} treatments"}}};
export const PATIENT_TREATMENT_FREQUENCY_INVALID = {"id":"patient_treatment.frequency.invalid","translations":{"validators":{"fr":"Veuillez saisir une fr\u00e9quence de traitements","en":"Please enter treatment frequency"}}};
export const PATIENT_TREATMENT_FREQUENCY_MAX = {"id":"patient_treatment.frequency.max","translations":{"validators":{"fr":"La fr\u00e9quence ne peut pas d\u00e9passer {{ limit }} jours","en":"The frequency cannot exceed {{ limit }} days"}}};
export const ANSWER_VALUE_NOT_BLANK = {"id":"answer.value.not_blank","translations":{"validators":{"fr":"Veuillez saisir une valeur","en":"Please enter value"}}};
export const ANSWER_VALUE_SUPERIOR_TO_MAX = {"id":"answer.value.superior_to_max","translations":{"validators":{"fr":"La valeur doit \u00eatre inf\u00e9rieure \u00e0 {{ max }}","en":"The value must be less than {{ max }}"}}};
export const ANSWER_VALUE_INFERIOR_TO_MIN = {"id":"answer.value.inferior_to_min","translations":{"validators":{"fr":"La valeur doit \u00eatre sup\u00e9rieure \u00e0 {{ min }}","en":"The value must be greater than {{ min }}"}}};
export const ANSWER_VALUE_INVALID = {"id":"answer.value.invalid","translations":{"validators":{"fr":"La valeur doit \u00eatre l'un de ces choix : {{ choices }}","en":"The value must be one of these choices: {{ choices }}"}}};
export const ANSWER_VALUE_NOT_DATE = {"id":"answer.value.not_date","translations":{"validators":{"fr":"La date saisie est incorrecte ou au mauvais format.","en":"The entered date is incorrect or in the wrong format."}}};
export const ANSWER_VALUE_MAX_LENGTH = {"id":"answer.value.max_length","translations":{"validators":{"fr":"La valeur ne peut pas d\u00e9passer 255 caract\u00e8res","en":"The value cannot exceed 255 characters"}}};
export const QUESTION_TITLE_NOT_BLANK = {"id":"question.title.not_blank","translations":{"validators":{"fr":"Veuillez saisir une question","en":"Please enter question"}}};
export const QUESTION_ANSWER_TYPE_NOT_BLANK = {"id":"question.answer_type.not_blank","translations":{"validators":{"fr":"Veuillez saisir un type de question","en":"Please enter question type"}}};
export const QUESTION_ANSWER_TYPE_INVALID = {"id":"question.answer_type.invalid","translations":{"validators":{"fr":"Type de question {{ value }} incorrect, il doit comprendre l'un des choix suivants ({{ choices }})","en":"Question type {{ value }} is incorrect, it must be one of the following choices ({{ choices }})"}}};
export const QUESTION_QUESTIONNAIRE_NOT_BLANK = {"id":"question.questionnaire.not_blank","translations":{"validators":{"fr":"Veuillez rattacher la question \u00e0 un questionnaire","en":"Please link the question to questionnaire"}}};
export const QUESTION_DISPLAY_FOR_INVALID = {"id":"question.display_for.invalid","translations":{"validators":{"fr":"Display for {{ value }} incorrect, il doit comprendre l'un des choix suivants ({{ choices }})","en":"Display for {{ value }} incorrect, it must include one of the following choices ({{ choices }})"}}};
export const QUESTIONNAIRE_NAME_NOT_BLANK = {"id":"questionnaire.name.not_blank","translations":{"validators":{"fr":"Veuillez saisir un nom de questionnaire","en":"Please enter questionnaire name"}}};
export const QUESTIONNAIRE_QUESTIONS_NOT_BLANK = {"id":"questionnaire.questions.not_blank","translations":{"validators":{"fr":"Veuillez entrer au moins une question","en":"Please enter at least one question"}}};
export const QUESTIONNAIRE_TYPE_NOT_BLANK = {"id":"questionnaire.type.not_blank","translations":{"validators":{"fr":"Veuillez choisir un type de questionnaire","en":"Please select questionnaire type"}}};
export const QUESTIONNAIRE_TYPE_INVALID = {"id":"questionnaire.type.invalid","translations":{"validators":{"fr":"Type de questionnaire {{ value }} incorrect, il doit comprendre l'un des choix suivants ({{ choices }})","en":"Questionnaire type {{ value }} incorrect, it must include one of the following choices ({{ choices }})"}}};
export const QUESTIONNAIRE_DELETE_USED = {"id":"questionnaire.delete.used","translations":{"validators":{"fr":"Vous ne pouvez pas supprimer un questionnaire d\u00e9j\u00e0 utilis\u00e9 au sein du projet","en":"You cannot delete a questionnaire already used within the project"}}};
export const QUESTIONNAIRE_DELETE_ERROR = {"id":"questionnaire.delete.error","translations":{"validators":{"fr":"Une erreur est survenue lors de la suppression du questionnaire","en":"An error occurred while deleting the questionnaire"}}};
export const QUESTIONNAIRE_MINE_NOT_FOUND = {"id":"questionnaire.mine.not_found","translations":{"validators":{"fr":"Aucun questionnaire n'a \u00e9t\u00e9 trouv\u00e9. Si vous pensez qu'il s'agit d'une erreur, veuillez vous rapprocher de l'administrateur.","en":"No questionnaire was found. If you think this is an error, please contact the administrator."}}};
export const QUESTIONNAIRE_PATIENT_FILE_NOT_FOUND = {"id":"questionnaire.patient_file.not_found","translations":{"validators":{"fr":"Aucun questionnaire de dossier patient n'a \u00e9t\u00e9 trouv\u00e9. Si vous pensez qu'il s'agit d'une erreur, veuillez vous rapprocher de l'administrateur.","en":"No patient record was found. If you think this is an error, please contact the administrator."}}};
export const QUESTIONNAIRE_STATUS_INVALID = {"id":"questionnaire.status.invalid","translations":{"validators":{"fr":"status {{ value }} incorrect","en":"Status {{ value }} incorrect"}}};
export const PATIENT_QUESTIONNAIRE_ALREADY_ASSIGNED = {"id":"patient_questionnaire.already_assigned","translations":{"validators":{"fr":"Ce questionnaire est d\u00e9j\u00e0 assign\u00e9 \u00e0 ce patient.","en":"This questionnaire is already assigned to this patient."}}};
export const SERVICE_NAME_NOT_BLANK = {"id":"service.name.not_blank","translations":{"validators":{"fr":"Veuillez saisir un nom de service","en":"Please enter service name"}}};
export const TIP_TITLE_NOT_BLANK = {"id":"tip.title.not_blank","translations":{"validators":{"fr":"Veuillez saisir un titre pour votre astuce","en":"Please enter title for your tip"}}};
export const TIP_CONTENT_NOT_BLANK = {"id":"tip.content.not_blank","translations":{"validators":{"fr":"Veuillez entrer un contenu pour votre astuce","en":"Please enter content for your tip"}}};
export const EVENT_TYPE_COLOR_NOT_BLANK = {"id":"event_type.color.not_blank","translations":{"validators":{"fr":"Veuillez entrer une couleur d'\u00e9v\u00e9nement","en":"Please enter event color"}}};
export const EVENT_TYPE_COLOR_INVALID = {"id":"event_type.color.invalid","translations":{"validators":{"fr":"Veuillez entrer une couleur valide","en":"Please enter valid color"}}};
export const EVENT_TYPE_ICON_NOT_BLANK = {"id":"event_type.icon.not_blank","translations":{"validators":{"fr":"Veuillez entrer une icone d'\u00e9v\u00e9nement","en":"Please enter event icon"}}};
export const EVENT_TYPE_MEAN_DURATION_MIN_SIZE = {"id":"event_type.mean_duration.min_size","translations":{"validators":{"fr":"Votre \u00e9v\u00e9nement ne peut pas durer moins de 5 minutes","en":"Your event cannot last less than 5 minutes"}}};
export const EVENT_TYPE_MEAN_DURATION_INVALID_CHOICE = {"id":"event_type.mean_duration.invalid_choice","translations":{"validators":{"fr":"La dur\u00e9e sp\u00e9cifi\u00e9e n'est pas valide. Veuillez s\u00e9lectionner une dur\u00e9e parmi les options pr\u00e9d\u00e9finies","en":"The specified duration is not valid. Please select a duration from the predefined options"}}};
export const EVENT_TYPE_MEAN_DURATION_NOT_BLANK = {"id":"event_type.mean_duration.not_blank","translations":{"validators":{"fr":"Veuillez entrer une dur\u00e9e pour le type d'\u00e9v\u00e9nement","en":"Please enter duration for the event type"}}};
export const EVENT_TYPE_ROLES_NOT_BLANK = {"id":"event_type.roles.not_blank","translations":{"validators":{"fr":"Veuillez saisir un ou plusieurs r\u00f4les","en":"Please enter one or more roles"}}};
export const EVENT_TYPE_DELETE_USED = {"id":"event_type.delete.used","translations":{"validators":{"fr":"Vous ne pouvez pas supprimer un type d'\u00e9v\u00e9nement d\u00e9j\u00e0 utilis\u00e9","en":"You cannot delete event type that has already been used"}}};
export const EVENT_TYPE_DELETE_CHILDREN = {"id":"event_type.delete.children","translations":{"validators":{"fr":"Impossible de supprimer un type d'\u00e9v\u00e9nement ayant d'autres EventType li\u00e9s.","en":"Cannot delete event type having other linked appointment types."}}};
export const EVENT_TYPE_PARENT_NOT_BLANK = {"id":"event_type.parent.not_blank","translations":{"validators":{"fr":"Veuillez lier cet \u00e9v\u00e9nement \u00e0 un type","en":"Please link this event to type"}}};
export const EVENT_TYPE_PARENT_INVALID = {"id":"event_type.parent.invalid","translations":{"validators":{"fr":"Ce type d'\u00e9v\u00e8nement ne peut pas \u00eatre parent","en":"This type of event cannot be parent"}}};
export const EVENT_TYPE_BOOKABLE_EVENT_TYPES_ONLY_DOCTORS = {"id":"event_type.bookable_event_types.only_doctors","translations":{"validators":{"fr":"La prise de rendez-vous n'est possible que pour les m\u00e9decins","en":"Appointment booking is only available for doctors."}}};
export const EVENT_TYPE_NOT_FOUND = {"id":"event_type.not_found","translations":{"validators":{"fr":"Aucun type d'\u00e9v\u00e9nement trouv\u00e9","en":"No event types found"}}};
export const EVENT_TYPE_NOT_BOOKABLE = {"id":"event_type.not_bookable","translations":{"validators":{"fr":"Ce type d'\u00e9v\u00e9nement n'est pas ouvert \u00e0 la prise de rendez-vous.","en":"This event type is not open for appointments."}}};
export const EVENT_TYPE_BULK_UPDATE_INVALID_ARCHIVED_VALUE = {"id":"event_type.bulk_update.invalid_archived_value","translations":{"validators":{"fr":"La valeur de l'\u00e9tat d'archivage est invalide.","en":"Archiving state value is invalid."}}};
export const EVENT_TYPE_BULK_UPDATE_EVENT_TYPE_OPTION_NOT_FOUND = {"id":"event_type.bulk_update.event_type_option_not_found","translations":{"validators":{"fr":"L'option de type d'\u00e9v\u00e9nement sp\u00e9cifi\u00e9e est introuvable. Veuillez v\u00e9rifier l'identifiant et r\u00e9essayer.","en":"The specific event type option could not be found. Please check ID and try again."}}};
export const EVENT_TYPE_BULK_UPDATE_EVENT_TYPE_OPTION_NO_ACCESS = {"id":"event_type.bulk_update.event_type_option_no_access","translations":{"validators":{"fr":"Vous n'avez pas les droits n\u00e9cessaires pour mettre \u00e0 jour cette option de type d'\u00e9v\u00e9nement.","en":"You do not have permission to update this event type option."}}};
export const EVENT_TYPE_OPTIONS_BOOKING_CLOSING_LARGER_THAN_OPENING_DATE = {"id":"event_type_options.booking_closing.larger_than_opening_date","translations":{"validators":{"fr":"La date de fermeture doit \u00eatre ult\u00e9rieure \u00e0 la date d'ouverture","en":"Closing date must be later than opening date"}}};
export const EVENT_TYPE_OPTIONS_EVENT_TYPES_DUPLICATE_OWNER = {"id":"event_type_options.event_types.duplicate_owner","translations":{"validators":{"fr":"Une configuration d'option existe d\u00e9j\u00e0 pour cet utilisateur et ce type d'\u00e9v\u00e9nement; \"{{ value }}\".","en":"An option configuration already exists for this user and this event type; \"{{ value }}\"."}}};
export const EVENT_TYPE_OPTIONS_EVENT_TYPES_NOT_NULL = {"id":"event_type_options.event_types.not_null","translations":{"validators":{"fr":"Au moins un type d'\u00e9v\u00e9nement est obligatoire.","en":"At least one event type is required."}}};
export const BOOKING_SLOT_ONLY_DOCTORS = {"id":"booking_slot.only_doctors","translations":{"validators":{"fr":"La prise de rendez-vous n'est possible que pour les m\u00e9decins","en":"Appointment booking is only available for doctors."}}};
export const BOOKING_SLOT_CANNOT_VIEW_CALENDAR = {"id":"booking_slot.cannot_view_calendar","translations":{"validators":{"fr":"Vous ne pouvez pas voir le calendrier de cet utilisateur","en":"You cannot view this user's calendar"}}};
export const BOOKING_SLOT_EVENT_TYPE_REQUIRED = {"id":"booking_slot.event_type_required","translations":{"validators":{"fr":"Vous devez pr\u00e9ciser un type d'\u00e9v\u00e9nement","en":"You must specify event type"}}};
export const BOOKING_SLOT_INVALID_DATE_RANGE = {"id":"booking_slot.invalid_date_range","translations":{"validators":{"fr":"La date de fin doit \u00eatre sup\u00e9rieure \u00e0 la date de d\u00e9but","en":"End date must be later than the start date"}}};
export const BOOKING_OWNER_NOT_FOUND = {"id":"booking.owner.not_found","translations":{"validators":{"fr":"L'utilisateur n'a pas \u00e9t\u00e9 trouv\u00e9","en":"The user can not be found"}}};
export const BOOKING_OWNER_NOT_DOCTOR = {"id":"booking.owner.not_doctor","translations":{"validators":{"fr":"L'utilisateur n'est pas un m\u00e9decin","en":"User is not doctor"}}};
export const BOOKING_EVENT_TYPE_NOT_FOUND = {"id":"booking.event_type.not_found","translations":{"validators":{"fr":"Le type d'\u00e9v\u00e9nement n'a pas \u00e9t\u00e9 trouv\u00e9","en":"Appointment type was not found"}}};
export const BOOKING_EVENT_TYPE_NOT_OPEN = {"id":"booking.event_type.not_open","translations":{"validators":{"fr":"Le type d'\u00e9v\u00e9nement n'est pas ouvert \u00e0 la prise de rendez-vous par ce m\u00e9decin","en":"The event type is not open for appointments by this doctor"}}};
export const BOOKING_AVAILABILITY_NOT_FOUND = {"id":"booking.availability.not_found","translations":{"validators":{"fr":"Ce cr\u00e9neau de disponibilit\u00e9 n\u2019est plus disponible, veuillez en choisir un autre","en":"This time slot is no longer available. Please select another one."}}};
export const BOOKING_AVAILABILITY_NOT_AVAILABLE = {"id":"booking.availability.not_available","translations":{"validators":{"fr":"Ce cr\u00e9neau de disponibilit\u00e9 n\u2019est plus disponible, veuillez en choisir un autre","en":"This time slot is no longer available. Please select another one."}}};
export const BOOKING_DATE_OUT_OF_RANGE = {"id":"booking.date.out_of_range","translations":{"validators":{"fr":"Ce cr\u00e9neau de disponibilit\u00e9 n\u2019est plus disponible, veuillez en choisir un autre","en":"This time slot is no longer available. Please select another one."}}};
export const BOOKING_DATE_PAST = {"id":"booking.date.past","translations":{"validators":{"fr":"Vous ne pouvez pas prendre de rendez-vous dans le pass\u00e9","en":"You cannot take an appointment at past date"}}};
export const BOOKING_UPDATE_NO_PREVIOUS_DATE = {"id":"booking.update.no_previous_date","translations":{"validators":{"fr":"Il faut fournir la date originale de l'\u00e9v\u00e9nement.","en":"The original event date must be provided."}}};
export const BOOKING_UPDATE_DATE_NULL = {"id":"booking.update.date_null","translations":{"validators":{"fr":"Veuillez saisir une date de rendez-vous","en":"Please enter appointment date"}}};
export const BOOKING_UPDATE_CANCELLATION_DEADLINE_PASSED = {"id":"booking.update.cancellation_deadline_passed","translations":{"validators":{"fr":"Le d\u00e9lai d'annulation de l'\u00e9v\u00e9nement est d\u00e9pass\u00e9.","en":"The event cancellation deadline has passed."}}};
export const BOOKING_CANCEL_NO_REASON_PROVIDED = {"id":"booking.cancel.no_reason_provided","translations":{"validators":{"fr":"Vous devez fournir une raison pour l'annulation du rendez-vous.","en":"You must provide reason for cancelling the appointment."}}};
export const BOOKING_CANCEL_NO_REASON_PROVIDED_FOR_OTHER = {"id":"booking.cancel.no_reason_provided_for_other","translations":{"validators":{"fr":"Vous devez fournir une raison lorsque le motif d'annulation est \"Autre\".","en":"You must provide reason when the reason for cancellation is \"Other\"."}}};
export const HISTORIC_CONTENT_NOT_NULL = {"id":"historic.content.not_null","translations":{"validators":{"fr":"Veuillez entrer un contenu d'historique","en":"Please enter history content"}}};
export const HISTORIC_EVENT_NOT_NULL = {"id":"historic.event.not_null","translations":{"validators":{"fr":"Un historique doit forc\u00e9ment \u00eatre rattach\u00e9 \u00e0 un \u00e9v\u00e9nement","en":"History must be linked to event"}}};
export const HISTORIC_EVENT_UNIQUE = {"id":"historic.event.unique","translations":{"validators":{"fr":"Un \u00e9v\u00e9nement ne peut pas avoir plusieurs historiques","en":"Appointment cannot have multiple histories"}}};
export const OWNED_ENTITY_OWNER_INCORRECT = {"id":"owned_entity.owner.incorrect","translations":{"validators":{"fr":"Vous ne pouvez pas associer cette entit\u00e9e \u00e0 cet utilisateur","en":"You cannot associate this entity with this user"}}};
export const FAQ_QUESTION_NOT_BLANK = {"id":"faq.question.not_blank","translations":{"validators":{"fr":"Veuillez saisir une question","en":"Please enter question"}}};
export const FAQ_DELETE_ERROR = {"id":"faq.delete.error","translations":{"validators":{"fr":"Une erreur est survenue lors de la suppression de la FAQ","en":"An error occurred while deleting the FAQ"}}};
export const BAD_CREDENTIALS = {"id":"Bad credentials.","translations":{"validators":{"fr":"Votre adresse e-mail ou votre mot de passe est incorrect","en":"Your email address or password is incorrect"}}};
export const THE_PRESENTED_PASSWORD_IS_INVALID = {"id":"The presented password is invalid.","translations":{"validators":{"fr":"Votre adresse e-mail ou votre mot de passe est incorrect","en":"Your email address or password is incorrect"}}};
export const REFRESH_TOKEN_CODE_MISSING = {"id":"refresh_token.code.missing","translations":{"validators":{"fr":"Veuillez saisir un code confidentiel","en":"Please enter confidential code"}}};
export const REFRESH_TOKEN_CODE_INVALID = {"id":"refresh_token.code.invalid","translations":{"validators":{"fr":"Code confidentiel invalide","en":"Confidential code invalid"}}};
export const INSTITUTION_NOT_FOUND = {"id":"institution.not_found","translations":{"validators":{"fr":"L'institution {{ domain }} n'existe pas","en":"The facility {{ domain }} does not exist"}}};
export const INSTITUTION_ACCESS_DENIED = {"id":"institution.access_denied","translations":{"validators":{"fr":"Vous n'avez pas acc\u00e8s \u00e0 cette institution","en":"You do not have access to this facility"}}};
export const INSTITUTION_NOT_SET = {"id":"institution.not_set","translations":{"validators":{"fr":"L'institution doit \u00eatre pr\u00e9sente dans le token","en":"The facility must be present in token"}}};
export const INSTITUTION_NAME_NOT_BLANK = {"id":"institution.name.not_blank","translations":{"validators":{"fr":"Veuillez saisir un nom pour votre institution","en":"Please enter name for your institution"}}};
export const INSTITUTION_DOMAIN_NOT_BLANK = {"id":"institution.domain.not_blank","translations":{"validators":{"fr":"Veuilez saisir un domaine pour votre institution","en":"Please enter domain for your institution"}}};
export const INSTITUTION_DOMAIN_UNIQUE = {"id":"institution.domain.unique","translations":{"validators":{"fr":"Le domaine existe d\u00e9j\u00e0","en":"The domain already exists"}}};
export const INSTITUTION_CREATED_INSTITUTION = {"id":"institution.created.institution","translations":{"validators":{"fr":"Seul un super admin peut cr\u00e9er une institution","en":"Only super admin can create an institution"}}};
export const MEDICAL_INVOICE_CONSULTATION_NOT_NULL = {"id":"medical_invoice.consultation.not_null","translations":{"validators":{"fr":"Veuillez rattacher la facture \u00e0 une consultation","en":"Please attach the invoice to consultation"}}};
export const MEDICAL_INVOICE_EXTERNAL_ID_NOT_NULL = {"id":"medical_invoice.external_id.not_null","translations":{"validators":{"fr":"Veuillez saisir un identifiant externe","en":"Please enter external identifier"}}};
export const MEDICAL_INVOICE_ERROR_SYNC = {"id":"medical_invoice.error_sync","translations":{"validators":{"fr":"Une erreur est survenue lors de la synchronisation de votre facture. Veuillez r\u00e9essayer ult\u00e9rieurement."}}};
export const MEDICAL_DOCUMENT_CONSULTATION_NOT_NULL = {"id":"medical_document.consultation.not_null","translations":{"validators":{"fr":"Veuillez rattacher le document \u00e0 une consultation ou bien le lier \u00e0 un patient","en":"Please link the document to consultation or link it to patient"}}};
export const MEDICAL_DOCUMENT_ANSWERS_GROUP_NOT_NULL = {"id":"medical_document.answers_group.not_null","translations":{"validators":{"fr":"Veuillez entrer un groupe de questions pour le type de document {{ type }}","en":"Please enter question group for document type {{ type }}"}}};
export const MEDICAL_DOCUMENT_GENERATE_FORBIDDEN = {"id":"medical_document.generate.forbidden","translations":{"validators":{"fr":"Seul un m\u00e9decin peut g\u00e9n\u00e9rer ce type de document","en":"Only doctor can generate this type of document"}}};
export const MEDICAL_DOCUMENT_GENERATE_PRESCRIPTION_ASSISTANCE_SOFTWARE_REQUIRED = {"id":"medical_document.generate.prescription_assistance_software_required","translations":{"validators":{"fr":"Vous devez \u00eatre \u00e9quip\u00e9 d'un logiciel d'aide \u00e0 la prescription pour g\u00e9n\u00e9rer ce type de document","en":"You must have prescription assistance software to generate this type of document."}}};
export const MEDICAL_DOCUMENT_PREVIOUS_NOT_FOUND = {"id":"medical_document.previous.not_found","translations":{"validators":{"fr":"Aucun document pr\u00e9c\u00e9dent trouv\u00e9","en":"No previous document found"}}};
export const MEDICAL_DOCUMENT_RENEW_NOT_GENERATED = {"id":"medical_document.renew.not_generated","translations":{"validators":{"fr":"Vous ne pouvez pas renouveler un document qui est en brouillon","en":"You cannot renew document that is in draft"}}};
export const MEDICAL_DOCUMENT_PATIENT_NOT_NULL = {"id":"medical_document.patient.not_null","translations":{"validators":{"fr":"Veuillez lier le document \u00e0 un patient ou bien le rattacher \u00e0 une consultation","en":"Please link the document to patient or consultation"}}};
export const MEDICAL_DOCUMENT_QUESTIONNAIRE_CONSULTATION_NOT_NULL = {"id":"medical_document.questionnaire.consultation.not_null","translations":{"validators":{"fr":"Vous ne pouvez pas cr\u00e9er ce type de document en dehors d'une consultation","en":"You cannot create this type of document outside of consultation"}}};
export const MEDICAL_DOCUMENT_PARSE_WITH_IA_PRESCRIPTION_NOT_ALLOWED = {"id":"medical_document.parse_with_ia.prescription_not_allowed","translations":{"validators":{"fr":"Vous ne pouvez pas analyser d'ordonnances","en":"You cannot analyze prescriptions"}}};
export const MEDICAL_DOCUMENT_PARSE_WITH_IA_CERFA_NOT_ALLOWED = {"id":"medical_document.parse_with_ia.cerfa_not_allowed","translations":{"validators":{"fr":"Vous ne pouvez pas analyser de CERFAs","en":"You cannot analyze CERFAs"}}};
export const MEDICAL_DOCUMENT_PARSE_WITH_IA_NOT_GENERATED_NOT_ALLOWED = {"id":"medical_document.parse_with_ia.not_generated_not_allowed","translations":{"validators":{"fr":"Vous ne pouvez pas analyser un brouillon","en":"You cannot analyze draft"}}};
export const MEDICAL_DOCUMENT_PARSE_WITH_IA_ALREADY_PARSED = {"id":"medical_document.parse_with_ia.already_parsed","translations":{"validators":{"fr":"Ce document a d\u00e9j\u00e0 \u00e9t\u00e9 analys\u00e9","en":"This document has already been analysed"}}};
export const MEDICAL_DOCUMENT_CONVERT_TO_ASSISTANT_PRESCRIPTION_FIELD_NOT_FOUND = {"id":"medical_document.convert_to_assistant_prescription.field_not_found","translations":{"validators":{"fr":"Le champ M\u00e9dicament n'a pas \u00e9t\u00e9 trouv\u00e9 et n'a pas pu \u00eatre remplac\u00e9","en":"The Medication field was not found and could not be replaced."}}};
export const PROFILE_STATUS_INVALID = {"id":"profile.status.invalid","translations":{"validators":{"fr":"status {{ value }} incorrect, il doit comprendre l'un des choix suivants ({{ choices }}).","en":"status {{ value }} incorrect, it must include one of the following choices ({{ choices }})."}}};
export const PROFILE_STRIPE_NOT_FOUND = {"id":"profile.stripe.not_found","translations":{"validators":{"fr":"Aucune donn\u00e9e bancaire enregistr\u00e9e pour l'utilisateur","en":"No banking data recorded for the user"}}};
export const PROFILE_STRIPE_MISSING_TOKEN = {"id":"profile.stripe.missing_token","translations":{"validators":{"fr":"Veuillez saisir un token","en":"Please enter token"}}};
export const PROFILE_STRIPE_MISSING_IBAN = {"id":"profile.stripe.missing_iban","translations":{"validators":{"fr":"Veuillez renseigner votre Iban","en":"Please provide your Iban"}}};
export const PROFILE_USER_INVALID = {"id":"profile.user.invalid","translations":{"validators":{"fr":"Veuillez lier votre profil \u00e0 un utilisateur","en":"Please link your profile to user"}}};
export const PROFILE_INSTITUTION_NOT_NULL = {"id":"profile.institution.not_null","translations":{"validators":{"fr":"Veuillez choisir un h\u00f4pital","en":"Please choose hospital"}}};
export const PROFILE_ACTIVITY_FREQUENCY_INVALID = {"id":"profile.activity_frequency.invalid","translations":{"validators":{"fr":"Valeur {{ value }} incorrecte. Elle doit comprendre l'un des choix suivants ({{ choices }}).","en":"Incorrect value {{ value }}. It must include one of the following choices ({{ choices }})."}}};
export const PROFILE_DRINKING_FREQUENCY_INVALID = {"id":"profile.drinking_frequency.invalid","translations":{"validators":{"fr":"Valeur {{ value }} incorrecte. Elle doit comprendre l'un des choix suivants ({{ choices }}).","en":"Incorrect value {{ value }}. It must include one of the following choices ({{ choices }})."}}};
export const PROFILE_SMOKING_FREQUENCY_INVALID = {"id":"profile.smoking_frequency.invalid","translations":{"validators":{"fr":"Valeur {{ value }} incorrecte. Elle doit comprendre l'un des choix suivants ({{ choices }}).","en":"Incorrect value {{ value }}. It must include one of the following choices ({{ choices }})."}}};
export const PROFILE_NOT_FOUND = {"id":"profile.not_found","translations":{"validators":{"fr":"Profil non trouv\u00e9","en":"Profile not found"}}};
export const PROFILE_FAMILY_SITUATION_INVALID = {"id":"profile.family_situation.invalid","translations":{"validators":{"fr":"Valeur {{ value }} incorrecte. Elle doit comprendre l'un des choix suivants ({{ choices }}).","en":"Incorrect value {{ value }}. It must include one of the following choices ({{ choices }})."}}};
export const PROFILE_LATERALITY_INVALID = {"id":"profile.laterality.invalid","translations":{"validators":{"fr":"Valeur {{ value }} incorrecte. Elle doit comprendre l'un des choix suivants ({{ choices }}).","en":"Incorrect value {{ value }}. It must include one of the following choices ({{ choices }})."}}};
export const PROFILE_EYES_COLOR_INVALID = {"id":"profile.eyes_color.invalid","translations":{"validators":{"fr":"Valeur {{ value }} incorrecte. Elle doit comprendre l'un des choix suivants ({{ choices }}).","en":"Incorrect value {{ value }}. It must include one of the following choices ({{ choices }})."}}};
export const PROFILE_HAIR_COLOR_INVALID = {"id":"profile.hair_color.invalid","translations":{"validators":{"fr":"Valeur {{ value }} incorrecte. Elle doit comprendre l'un des choix suivants ({{ choices }}).","en":"Incorrect value {{ value }}. It must include one of the following choices ({{ choices }})."}}};
export const MEDIC_PROFILE_CPS_NOT_NULL = {"id":"medic_profile.cps.not_null","translations":{"validators":{"fr":"Veuillez uploader une carte CPS","en":"Please upload CPS card"}}};
export const MEDIC_PROFILE_USER_ADDRESS_NOT_EMPTY = {"id":"medic_profile.user.address.not_empty","translations":{"validators":{"fr":"Veuillez saisir une addresse","en":"Please enter address"}}};
export const MEDIC_PROFILE_USER_NOT_MEDIC = {"id":"medic_profile.user.not_medic","translations":{"validators":{"fr":"Impossible d'ajouter cet utilisateur \u00e0 vos m\u00e9decins traitants.","en":"Cannot add this user to your primary care physicians."}}};
export const MEDIC_PROFILE_CPS_NUMBER_NOT_NULL = {"id":"medic_profile.cps_number.not_null","translations":{"validators":{"fr":"Veuillez saisir un num\u00e9ro CPS","en":"Please enter a CPS number"}}};
export const MEDIC_PROFILE_CPS_NUMBER_LENGTH = {"id":"medic_profile.cps_number.length","translations":{"validators":{"fr":"Votre num\u00e9ro de carte CPS doit \u00eatre de 10 caract\u00e8res","en":"Your CPS card number must be 10 characters long"}}};
export const MEDIC_PROFILE_RPPS_LENGTH = {"id":"medic_profile.rpps.length","translations":{"validators":{"fr":"Votre num\u00e9ro RPPS doit \u00eatre de 11 caract\u00e8res","en":"Your RPPS number must be 11 characters long"}}};
export const MEDIC_PROFILE_RPPS_NOT_NULL = {"id":"medic_profile.rpps.not_null","translations":{"validators":{"fr":"Veuillez saisir un num\u00e9ro RPPS","en":"Please enter RPPS number"}}};
export const MEDIC_PROFILE_RPPS_IS_INVALID = {"id":"medic_profile.rpps.is_invalid","translations":{"validators":{"fr":"Veuillez entrer un num\u00e9ro RPPS valide","en":"Please enter a valid RPPS number"}}};
export const MEDIC_PROFILE_ADELI_LENGTH = {"id":"medic_profile.adeli.length","translations":{"validators":{"fr":"Votre num\u00e9ro ADELI doit \u00eatre de 9 caract\u00e8res","en":"Your ADELI number must be 9 characters long"}}};
export const MEDIC_PROFILE_FINESS_LENGTH = {"id":"medic_profile.finess.length","translations":{"validators":{"fr":"Votre num\u00e9ro FINESS doit \u00eatre de 9 caract\u00e8res","en":"Your FINESS number must be 9 characters long"}}};
export const MEDIC_PROFILE_SECURE_EMAIL_INVALID = {"id":"medic_profile.secure_email.invalid","translations":{"validators":{"fr":"Veuillez saisir une adresse e-mail valide","en":"Please enter a valid email address"}}};
export const MEDIC_PROFILE_WEBSITE_INVALID = {"id":"medic_profile.website.invalid","translations":{"validators":{"fr":"Veuillez entrer une adresse web valide","en":"Please enter a valid website address"}}};
export const MEDIC_PROFILE_API_CRYPT_INVALID = {"id":"medic_profile.api_crypt.invalid","translations":{"validators":{"fr":"Veuillez saisir une adresse e-mail valide","en":"Please enter a valid email address"}}};
export const MEDIC_PROFILE_SIRET_LENGTH = {"id":"medic_profile.siret.length","translations":{"validators":{"fr":"Votre num\u00e9ro de siret doit \u00eatre de 14 caract\u00e8res","en":"Your SIRET number must be 14 characters long"}}};
export const MEDIC_PROFILE_SIRET_NOT_NULL = {"id":"medic_profile.siret.not_null","translations":{"validators":{"fr":"Veuillez saisir un num\u00e9ro de siret","en":"Please enter SIRET number"}}};
export const MEDIC_PROFILE_SIRET_IS_SIRET = {"id":"medic_profile.siret.is_siret","translations":{"validators":{"fr":"Le num\u00e9ro de siret n'est pas valide","en":"The SIRET number is not valid"}}};
export const MEDIC_PROFILE_SIRET_SERVER_ERROR = {"id":"medic_profile.siret.server_error","translations":{"validators":{"fr":"Une erreur est survenue lors de la validation de votre SIRET, veuillez r\u00e9essayer ult\u00e9rieurement.","en":"An error occurred while validating your SIRET, please try again later."}}};
export const MEDIC_PROFILE_SIGNUP_ALREADY_LOGGED_IN = {"id":"medic_profile.signup.already_logged_in","translations":{"validators":{"fr":"Vous ne pouvez pas cr\u00e9er de compte en \u00e9tant d\u00e9j\u00e0 connect\u00e9.","en":"You cannot create account while already logged in."}}};
export const HEALTH_PROFILE_ID_CARD_NOT_NULL = {"id":"health_profile.idCard.not_null","translations":{"validators":{"fr":"Veuillez uploader une pi\u00e8ce d'identit\u00e9","en":"Please upload identity card"}}};
export const HEALTH_PROFILE_SPECIALTIES_NOT_NULL = {"id":"health_profile.specialties.not_null","translations":{"validators":{"fr":"Veuillez choisir une ou plusieurs sp\u00e9cialit\u00e9s","en":"Please choose one or more specialties"}}};
export const HEALTH_PROFILE_CALENDAR_MAX_HOUR_INVALID = {"id":"health_profile.calendar_max_hour.invalid","translations":{"validators":{"fr":"Veuillez saisir une heure valide","en":"Please enter a valid time"}}};
export const HEALTH_PROFILE_CALENDAR_MIN_HOUR_INVALID = {"id":"health_profile.calendar_min_hour.invalid","translations":{"validators":{"fr":"Veuillez saisir une heure valide","en":"Please enter a valid time"}}};
export const HEALTH_PROFILE_CALENDAR_INTERVAL_INVALID = {"id":"health_profile.calendar_interval.invalid","translations":{"validators":{"fr":"Veuillez saisir un intervalle valide","en":"Please enter a valid interval"}}};
export const HEALTH_PROFILE_CALENDAR_DISPLAY_DATES_INVALID = {"id":"health_profile.calendar_display_dates.invalid","translations":{"validators":{"fr":"Veuillez saisir un nombre de jours valide","en":"Please enter a valid number of days"}}};
export const NOTE_TITLE_NOT_BLANK = {"id":"note.title.not_blank","translations":{"validators":{"fr":"Veuillez saisir un titre","en":"Please enter a title"}}};
export const NOTE_CONTENT_NOT_BLANK = {"id":"note.content.not_blank","translations":{"validators":{"fr":"Veuillez saisir un contenu","en":"Please enter content"}}};
export const PATIENT_NOTE_PATIENT_NOT_NULL = {"id":"patient_note.patient.not_null","translations":{"validators":{"fr":"Veuillez entrer un patient","en":"Please enter patient"}}};
export const PDF_CONTENT_NOT_BLANK = {"id":"pdf.content.not_blank","translations":{"validators":{"fr":"Le contenu de votre document ou de votre mod\u00e8le est vide.","en":"The content of your document or template is empty."}}};
export const PDF_TYPE_INVALID = {"id":"pdf.type.invalid","translations":{"validators":{"fr":"Type {{ value }} incorrect, il doit comprendre l'un des choix suivants ({{ choices }})","en":"Incorrect type {{ value }}, it must include one of the following choices ({{ choices }})."}}};
export const PDF_TYPE_INCORRECT = {"id":"pdf.type.incorrect","translations":{"validators":{"fr":"Type {{ value }} invalid si le document dispose de r\u00e9ponses \u00e0 un questionnaire","en":"Type {{ value }} is invalid if the document has responses to a questionnaire"}}};
export const PDF_TYPE_NOT_NULL = {"id":"pdf.type.not_null","translations":{"validators":{"fr":"Veuillez saisir un type de document","en":"Please enter document type"}}};
export const PDF_TEMPLATE_NAME_NOT_BLANK = {"id":"pdf_template.name.not_blank","translations":{"validators":{"fr":"Veuillez saisir un titre pour votre mod\u00e8le de document","en":"Please enter title for your document template"}}};
export const PDF_TEMPLATE_DELETE_ALREADY_USED = {"id":"pdf_template.delete.already_used","translations":{"validators":{"fr":"Ce mod\u00e8le est utilis\u00e9 dans un ou plusieurs document et ne peut donc pas \u00eatre supprim\u00e9","en":"This template is used in one or more document and therefore cannot be deleted"}}};
export const PDF_TEMPLATE_DELETE_ERROR = {"id":"pdf_template.delete.error","translations":{"validators":{"fr":"Une erreur est survenue lors de la suppression de votre mod\u00e8le","en":"An error occurred while deleting your template"}}};
export const PDF_TEMPLATE_STATUS_INVALID = {"id":"pdf_template.status.invalid","translations":{"validators":{"fr":"status {{ value }} incorrect","en":"incorrect {{ value }} status"}}};
export const PDF_TEMPLATE_CHANGE_TYPE_ALREADY_USED = {"id":"pdf_template.change_type.already_used","translations":{"validators":{"fr":"Ce mod\u00e8le est utilis\u00e9 dans un ou plusieurs document et donc son type ne peut pas \u00eatre modifi\u00e9.","en":"This template is used in one or more document thus its type cannot be changed."}}};
export const PDF_TEMPLATE_LETTER_HEAD_NOT_NULL = {"id":"pdf_template.letter_head.not_null","translations":{"validators":{"fr":"Veuillez choisir un papier \u00e0 en-t\u00eate","en":"Please select letterhead"}}};
export const PDF_TEMPLATE_PREVIEW_UNAVAILABLE = {"id":"pdf_template.preview_unavailable","translations":{"validators":{"fr":"Vous ne pouvez pas pr\u00e9visualiser un mod\u00e8le vide","en":"You cannot preview an empty template"}}};
export const MEDICAL_RESOURCE_OWNER_NOT_DOCTOR = {"id":"medical_resource.owner.not_doctor","translations":{"validators":{"fr":"Veuillez lier cet \u00e9l\u00e9ment \u00e0 un m\u00e9decin","en":"Please link this item to doctor"}}};
export const MEDICAL_RESOURCE_OWNER_TYPE_INVALID = {"id":"medical_resource.owner_type.invalid","translations":{"validators":{"fr":"Vous ne pouvez pas cr\u00e9er ce type d'objet","en":"You cannot create this type of object"}}};
export const MEDICAL_RESOURCE_OWNER_TYPE_INVISIBLE = {"id":"medical_resource.owner_type.invisible","translations":{"validators":{"fr":"En choisissant ce type de propri\u00e9taire vous n'avez pas acc\u00e8s \u00e0 cet objet","en":"By choosing this type of owner you do not have access to this object"}}};
export const PATIENT_PROFILE_COVER_PERCENTAGE_LENGTH = {"id":"patient_profile.cover_percentage.length","translations":{"validators":{"fr":"Veuillez saisir un pourcentage valide (entre 0 et 100)","en":"Please enter a valid percentage (between 0 and 100)"}}};
export const PATIENT_PROFILE_HEIGHT_LENGTH = {"id":"patient_profile.height.length","translations":{"validators":{"fr":"Veuillez saisir une taille valide (en cm)","en":"Please enter a valid height (in cm)"}}};
export const PATIENT_PROFILE_WEIGHT_LENGTH = {"id":"patient_profile.weight.length","translations":{"validators":{"fr":"Veuillez saisir un poids valide (en kg)","en":"Please enter a valid weight (in kg)"}}};
export const PATIENT_PROFILE_BLOOD_TYPE_INVALID = {"id":"patient_profile.blood_type.invalid","translations":{"validators":{"fr":"groupe sanguin {{ value }} incorrect, il doit comprendre l'un des choix suivants ({{ choices }})","en":"Incorrect blood type {{ value }}, it must be one of the following choices ({{ choices }})"}}};
export const PATIENT_PROFILE_SOCIAL_SECURITY_NUMBER_INVALID_LENGTH = {"id":"patient_profile.social_security_number.invalid_length","translations":{"validators":{"fr":"La taille du num\u00e9ro de s\u00e9curit\u00e9 sociale doit \u00eatre de {{ length }} caract\u00e8res","en":"The social security number must be {{ length }} characters long"}}};
export const PATIENT_PROFILE_SOCIAL_SECURITY_NUMBER_INVALID = {"id":"patient_profile.social_security_number.invalid","translations":{"validators":{"fr":"Le num\u00e9ro de s\u00e9curit\u00e9 sociale est invalide","en":"The social security number is invalid"}}};
export const PATIENT_PROFILE_SOCIAL_SECURITY_NUMBER_EXISTING_ACCOUNT = {"id":"patient_profile.social_security_number.existing_account","translations":{"validators":{"fr":"Le num\u00e9ro de s\u00e9curit\u00e9 sociale saisi est d\u00e9j\u00e0 utilis\u00e9 sur un compte pr\u00e9sent dans la base de donn\u00e9es Instamed.","en":"The entered social security number is already used on an existing account in the Instamed database."}}};
export const PATIENT_PROFILE_SOCIAL_SECURITY_NUMBER_EXISTING_ACCOUNT_WITH_ACCESS = {"id":"patient_profile.social_security_number.existing_account_with_access","translations":{"validators":{"fr":"Le num\u00e9ro de s\u00e9curit\u00e9 sociale est d\u00e9j\u00e0 utilis\u00e9.","en":"The social security number is already in use."}}};
export const PATIENT_PROFILE_SOCIAL_SECURITY_NUMBER_EXISTING_DIFFERENT_ACCOUNT_WITH_ACCESS = {"id":"patient_profile.social_security_number.existing_different_account_with_access","translations":{"validators":{"fr":"Le num\u00e9ro de s\u00e9curit\u00e9 sociale saisi est d\u00e9j\u00e0 utilis\u00e9e sur le compte de {{ fullName }} avec des informations diff\u00e9rentes. Veuillez v\u00e9rifier les informations entr\u00e9es, ou bien modifier le num\u00e9ro saisi.","en":"The entered social security number is already used on the account of {{ fullName }} with different information. Please verify the entered information, or modify the entered number."}}};
export const PATIENT_PROFILE_SOCIAL_SECURITY_NUMBER_EXISTING_DIFFERENT_ACCOUNT = {"id":"patient_profile.social_security_number.existing_different_account","translations":{"validators":{"fr":"Le num\u00e9ro de s\u00e9curit\u00e9 sociale saisi est d\u00e9j\u00e0 utilis\u00e9 sur un autre compte avec des informations diff\u00e9rentes. Veuillez v\u00e9rifier les informations entr\u00e9es, ou bien modifier le num\u00e9ro saisi.","en":"The entered social security number is already used on another account with different information. Please verify the entered information, or modify the entered number."}}};
export const PHONE_LABEL_NOT_BLANK = {"id":"phone.label.not_blank","translations":{"validators":{"fr":"Veuillez saisir un label pour le num\u00e9ro de t\u00e9l\u00e9phone","en":"Please enter label for the phone number"}}};
export const PHONE_NUMBER_NOT_BLANK = {"id":"phone.number.not_blank","translations":{"validators":{"fr":"Veuillez saisir un num\u00e9ro de t\u00e9l\u00e9phone","en":"Please enter phone number"}}};
export const PHONE_NUMBER_INVALID = {"id":"phone.number.invalid","translations":{"validators":{"fr":"Veuillez saisir un num\u00e9ro de t\u00e9l\u00e9phone valide","en":"Please enter a valid phone number"}}};
export const PHONE_NUMBER_NOT_MOBILE = {"id":"phone.number.not_mobile","translations":{"validators":{"fr":"Veuillez saisir un num\u00e9ro de t\u00e9l\u00e9phone mobile","en":"Please enter a valid phone number"}}};
export const PHONE_EXTENSION_NOT_BLANK = {"id":"phone.extension.not_blank","translations":{"validators":{"fr":"Veuillez saisir un num\u00e9ro de t\u00e9l\u00e9phone","en":"Please enter phone number"}}};
export const PHONE_CONFIRMATION_DONE = {"id":"phone.confirmation.done","translations":{"validators":{"fr":"Ce num\u00e9ro de t\u00e9l\u00e9phone a d\u00e9j\u00e0 \u00e9t\u00e9 confirm\u00e9","en":"This phone number has already been confirmed"}}};
export const PHONE_CODE_NOT_NULL = {"id":"phone.code.not_null","translations":{"validators":{"fr":"Veuillez entrer un code de confirmation","en":"Please enter confirmation code"}}};
export const PHONE_ENTITY_NOT_NULL = {"id":"phone.entity.not_null","translations":{"validators":{"fr":"Veuillez lier ce num\u00e9ro \u00e0 un utilisateur, une adresse ou une institution","en":"Please link this number to user, address or institution"}}};
export const VARIABLE_TYPE_NOT_BLANK = {"id":"variable.type.not_blank","translations":{"validators":{"fr":"Veuillez saisir un type de variable","en":"Please enter variable type"}}};
export const VARIABLE_VALUE_NOT_BLANK = {"id":"variable.value.not_blank","translations":{"validators":{"fr":"Veuillez entrer une valeur","en":"Please enter value"}}};
export const VARIABLE_DATE_INVALID = {"id":"variable.date.invalid","translations":{"validators":{"fr":"Veuillez entrer une date valide","en":"Please enter valid date"}}};
export const VARIABLE_PATIENT_NOT_BLANK = {"id":"variable.patient.not_blank","translations":{"validators":{"fr":"Veuillez lier la variable \u00e0 un patient","en":"Please link variable to a patient"}}};
export const VARIABLE_TYPE_NAME_NOT_BLANK = {"id":"variable_type.name.not_blank","translations":{"validators":{"fr":"Veuillez saisir un nom","en":"Please enter name"}}};
export const VARIABLE_TYPE_CANONICAL_NOT_BLANK = {"id":"variable_type.canonical.not_blank","translations":{"validators":{"fr":"Veuillez saisir un slug","en":"Please enter slug"}}};
export const VARIABLE_TYPE_UNIT_NOT_BLANK = {"id":"variable_type.unit.not_blank","translations":{"validators":{"fr":"Veuillez saisir une unit\u00e9e","en":"Please enter unit"}}};
export const VARIABLE_TYPE_DELETE_ALREADY_USED = {"id":"variable_type.delete.already_used","translations":{"validators":{"fr":"Vous ne pouvez pas supprimer un type de variable d\u00e9j\u00e0 utilis\u00e9 au sein du projet","en":"You cannot delete variable type already used within the project"}}};
export const PASSWORD_EMAIL_NOT_BLANK = {"id":"password.email.not_blank","translations":{"validators":{"fr":"Veuillez saisir une adresse e-mail valide","en":"Please enter valid email address"}}};
export const PASSWORD_PASSWORD_NOT_BLANK = {"id":"password.password.not_blank","translations":{"validators":{"fr":"Veuillez saisir un mot de passe valide","en":"Please enter valid password"}}};
export const PASSWORD_PASSWORD_SIMILAR = {"id":"password.password.similar","translations":{"validators":{"fr":"Les mots de passe doivent \u00eatre identique","en":"Passwords must match"}}};
export const PASSWORD_TOKEN_NOT_BLANK = {"id":"password.token.not_blank","translations":{"validators":{"fr":"Le lien a expir\u00e9. Veuillez refaire une demande.","en":"Link has expired. Please request again."}}};
export const PASSWORD_TOKEN_GENERATION_ERROR = {"id":"password.token.generation_error","translations":{"validators":{"fr":"Une erreur est survenue lors de la g\u00e9n\u00e9ration du token, veuillez r\u00e9essayer plus tard.","en":"An error occurred while generating token, please try again later."}}};
export const PASSWORD_TOKEN_TOO_MANY_REQUESTS = {"id":"password.token.too_many_requests","translations":{"validators":{"fr":"Vous avez d\u00e9j\u00e0 effectu\u00e9 trop de demande de r\u00e9initialisation de mot de passe. Merci de v\u00e9rifiez vos e-mails.","en":"You have already made too many password reset requests. Please check your emails."}}};
export const ANSWER_GROUP_ANSWERS_NOT_NULL = {"id":"answer_group.answers.not_null","translations":{"validators":{"fr":"Un groupe de r\u00e9ponse doit contenir au moins une r\u00e9ponse","en":"Answer group must contain at least one answer"}}};
export const ANSWER_GROUP_ANSWERS_COUNT = {"id":"answer_group.answers.count","translations":{"validators":{"fr":"Le groupe de r\u00e9ponse doit contenir le m\u00eame nombre de r\u00e9ponses que les questions du questionnaire","en":"Answer group must contain the same number of answers as questionnaire questions"}}};
export const ANSWER_GROUP_QUESTIONNAIRE_NOT_NULL = {"id":"answer_group.questionnaire.not_null","translations":{"validators":{"fr":"Un groupe de question doit \u00eatre rattach\u00e9 \u00e0 un questionnaire","en":"Answer group must be attached to questionnaire"}}};
export const ANSWER_GROUP_QUESTIONNAIRE_CANNOT_REPLY = {"id":"answer_group.questionnaire.cannot_reply","translations":{"validators":{"fr":"Vous avez d\u00e9j\u00e0 r\u00e9pondu \u00e0 ce questionnaire. Vous pourrez y r\u00e9pondre \u00e0 nouveau \u00e0 partir du {{ date }}","en":"You have already answered this questionnaire. You can respond again from {{ date }}"}}};
export const ANSWER_GROUP_PATIENT_NOT_BLANK = {"id":"answer_group.patient.not_blank","translations":{"validators":{"fr":"Veuillez lier le groupe de question \u00e0 un patient","en":"Please link the question group to patient"}}};
export const CALL_NOT_FOUND = {"id":"call.not_found","translations":{"validators":{"fr":"Aucun appel trouv\u00e9. Cet appel n'existe plus ou votre correspondant a lanc\u00e9 un nouvel appel.","en":"No call found. This call no longer exists or your correspondent has launched a new call."}}};
export const CALL_PARTICIPANTS_NOT_BLANK = {"id":"call.participants.not_blank","translations":{"validators":{"fr":"Veuillez entrer au moins un participant \u00e0 l'appel","en":"Please enter at least one participant for call"}}};
export const CALL_TRANSACTION_INCORRECT = {"id":"call.transaction.incorrect","translations":{"validators":{"fr":"Une erreur est survenue sur la transaction li\u00e9e \u00e0 cet appel.","en":"An error occurred on transaction linked to this call."}}};
export const REQUEST_MISSING_PARAMETER = {"id":"request.missing_parameter","translations":{"validators":{"fr":"Param\u00e8tre {{ param }} manquant","en":"Missing parameter {{ param }}"}}};
export const USER_PHONES_NOT_EMPTY = {"id":"user.phones.not_empty","translations":{"validators":{"fr":"Veuillez entrer un num\u00e9ro de t\u00e9l\u00e9phone","en":"Please enter phone number"}}};
export const USER_PHONE_NOT_FOUND = {"id":"user.phone.not_found","translations":{"validators":{"fr":"Aucun num\u00e9ro de t\u00e9l\u00e9phone valide","en":"No valid phone number found"}}};
export const USER_ADDRESSES_NOT_EMPTY = {"id":"user.addresses.not_empty","translations":{"validators":{"fr":"Veuillez entrer une adresse","en":"Please enter address"}}};
export const USER_ID_NOT_NULL = {"id":"user.id.not_null","translations":{"validators":{"fr":"Veuillez sp\u00e9cifier l'id de l'utilisateur lors d'un update","en":"Please specify user ID when updating"}}};
export const USER_ROLE_NOT_NULL = {"id":"user.role.not_null","translations":{"validators":{"fr":"Veuillez saisir un r\u00f4le","en":"Please enter role"}}};
export const USER_ROLE_INVALID = {"id":"user.role.invalid","translations":{"validators":{"fr":"r\u00f4le {{ value }} incorrect, il doit comprendre l'un des choix suivants ({{ choices }})","en":"Incorrect role {{ value }}, it must contain one of the following choices ({{ choices }})"}}};
export const USER_PREVIOUS_PASSWORD_INVALID = {"id":"user.previous_password.invalid","translations":{"validators":{"fr":"Le mot de passe actuel est incorrect","en":"Current password is incorrect"}}};
export const USER_PREVIOUS_CODE_INVALID = {"id":"user.previous_code.invalid","translations":{"validators":{"fr":"Le code actuel est incorrect","en":"Current code is incorrect"}}};
export const USER_PASSWORD_NOT_MATCH = {"id":"user.password.not_match","translations":{"validators":{"fr":"Les deux mots de passe entr\u00e9s sont diff\u00e9rents","en":"The two entered passwords are different"}}};
export const USER_PASSWORD_WEAK = {"id":"user.password.weak","translations":{"validators":{"fr":"Votre mot de passe doit \u00eatre de minimum 8 caract\u00e8res, contenir au moins un chiffre, une minuscule et une majuscule","en":"Your password must be at least 8 characters long, contain at least one digit, one lowercase, and one uppercase letter"}}};
export const USER_PASSWORD_DIFFERENT = {"id":"user.password.different","translations":{"validators":{"fr":"Votre nouveau mot de passe doit \u00eatre diff\u00e9rent de l'ancien","en":"Your new password must be different from the old one"}}};
export const USER_CODE_INVALID = {"id":"user.code.invalid","translations":{"validators":{"fr":"Votre code secret doit contenir entre 4 et 6 chiffres","en":"Your secret code must contain between 4 and 6 digits"}}};
export const USER_CODE_DIFFERENT = {"id":"user.code.different","translations":{"validators":{"fr":"Votre nouveau code doit \u00eatre diff\u00e9rent de l'ancien","en":"Your new code must be different from old one"}}};
export const USER_CODE_NOT_MATCH = {"id":"user.code.not_match","translations":{"validators":{"fr":"Les deux codes entr\u00e9s sont diff\u00e9rents","en":"The two entered codes are different"}}};
export const USER_INSTITUTIONS_NOT_NULL = {"id":"user.institutions.not_null","translations":{"validators":{"fr":"Veuillez saisir au moins une institution pour l'utilisateur","en":"Please enter at least one institution for the user"}}};
export const USER_SERVICES_NOT_NULL = {"id":"user.services.not_null","translations":{"validators":{"fr":"Veuillez saisir au moins un service pour l'utilisateur","en":"Please enter at least one service for the user"}}};
export const USER_ERROR_DELETED = {"id":"user.error.deleted","translations":{"validators":{"fr":"Une erreur est survenue lors de la suppression de l'utilisateur. Veuillez r\u00e9essayer ult\u00e9rieurement","en":"An error occurred while deleting user. Please try again later"}}};
export const USER_EMAIL_EXISTING_ACCOUNT = {"id":"user.email.existing_account","translations":{"validators":{"fr":"L'adresse e-mail saisie est d\u00e9j\u00e0 utilis\u00e9e sur un compte pr\u00e9sent dans la base de donn\u00e9es Instamed.","en":"Entered email address is already being used on an account in Instamed database."}}};
export const USER_EMAIL_EXISTING_ACCOUNT_WITH_ACCESS = {"id":"user.email.existing_account_with_access","translations":{"validators":{"fr":"Cet adresse e-mail est d\u00e9j\u00e0 utilis\u00e9e.","en":"This email address is already in use."}}};
export const USER_EMAIL_EXISTING_DIFFERENT_ACCOUNT_WITH_ACCESS = {"id":"user.email.existing_different_account_with_access","translations":{"validators":{"fr":"L'adresse e-mail saisie est d\u00e9j\u00e0 utilis\u00e9e sur le compte de {{ fullName }} avec des informations diff\u00e9rentes. Veuillez v\u00e9rifier les informations entr\u00e9es, ou bien modifier l'email choisi.","en":"Entered email address is already used on the account of {{ fullName }} with different information. Please check entered information, or edit the chosen email."}}};
export const USER_EMAIL_EXISTING_DIFFERENT_ACCOUNT = {"id":"user.email.existing_different_account","translations":{"validators":{"fr":"L'adresse e-mail saisie est d\u00e9j\u00e0 utilis\u00e9e sur un autre compte avec des informations diff\u00e9rentes. Veuillez v\u00e9rifier les informations entr\u00e9es, ou bien modifier l'email choisi.","en":"Entered email address is already used on another account with different information. Please check entered information, or edit the chosen email."}}};
export const USER_EMAIL_NOT_BLANK = {"id":"user.email.not_blank","translations":{"validators":{"fr":"Veuillez saisir une adresse e-mail","en":"Please enter email address"}}};
export const USER_EMAIL_INVALID = {"id":"user.email.invalid","translations":{"validators":{"fr":"Veuillez saisir une adresse e-mail valide.","en":"Please enter a valid email address."}}};
export const USER_DATE_OF_BIRTH_NOT_NULL = {"id":"user.date_of_birth.not_null","translations":{"validators":{"fr":"Veuillez saisir une date de naissance","en":"Please enter correct date of birth"}}};
export const USER_DATE_OF_BIRTH_TOO_YOUNG = {"id":"user.date_of_birth.too_young","translations":{"validators":{"fr":"Vous devez avoir au moins {{ age }} ans pour vous inscrire","en":"You must be at least be {{ age }} years old to register"}}};
export const USER_LAST_NAME_NOT_BLANK = {"id":"user.last_name.not_blank","translations":{"validators":{"fr":"Veuillez saisir un nom","en":"Please enter last name"}}};
export const USER_FIRST_NAME_NOT_BLANK = {"id":"user.first_name.not_blank","translations":{"validators":{"fr":"Veuillez saisir un pr\u00e9nom","en":"Please enter first name"}}};
export const USER_TOKEN_NOT_FOUND = {"id":"user.token.not_found","translations":{"validators":{"fr":"Aucun utilisateur trouv\u00e9 avec le token {{ token }}","en":"No user found with the token {{ token }}"}}};
export const USER_SOFT_DELETE_FORBIDDEN = {"id":"user.soft_delete.forbidden","translations":{"validators":{"fr":"L'utilisateur de type {{ role }} voit forc\u00e9ment \u00eatre supprim\u00e9 d\u00e9finitivement.","en":"The user of type {{ role }} must be permanently deleted."}}};
export const USER_INVITED_ROLE_DIFFERENT = {"id":"user.invited.role_different","translations":{"validators":{"fr":"Cet utilisateur existe d\u00e9j\u00e0 sous un autre r\u00f4le.","en":"This user already exists under another role."}}};
export const USER_MERGE_BOTH_ACCOUNT_USED = {"id":"user.merge.both_account_used","translations":{"validators":{"fr":"Fusion impossible, Les deux comptes ont d\u00e9j\u00e0 \u00e9t\u00e9 utilis\u00e9s","en":"Merge impossible, both accounts have already been used"}}};
export const USER_MERGE_DIFFERENT_ROLE = {"id":"user.merge.different_role","translations":{"validators":{"fr":"Les deux comptes doivent \u00eatre du m\u00eame r\u00f4le","en":"Both accounts must have the same role"}}};
export const USER_MERGE_ROLE_NOT_HANDLED = {"id":"user.merge.role_not_handled","translations":{"validators":{"fr":"Ces utilisateurs ne peuvent pas \u00eatre fusionn\u00e9s.","en":"These users cannot be merged."}}};
export const USER_MERGE_ERROR = {"id":"user.merge.error","translations":{"validators":{"fr":"Une erreur est survenue lors de la fusion des comptes","en":"An error occurred while merging the accounts"}}};
export const USER_MERGE_SAME_USER = {"id":"user.merge.same_user","translations":{"validators":{"fr":"Vous ne pouvez pas fusionner deux comptes identiques.","en":"You cannot merge two identical accounts."}}};
export const USER_MERGE_DIFFERENT = {"id":"user.merge.different","translations":{"validators":{"fr":"Les comptes s\u00e9lectionn\u00e9s sont diff\u00e9rents et ne peuvent pas \u00eatre fusionn\u00e9s","en":"The selected accounts are different and cannot be merged"}}};
export const USER_MERGE_DATE_OF_BIRTH_INVALID_FORMAT = {"id":"user.merge.date_of_birth.invalid_format","translations":{"validators":{"fr":"Le format de la date de naissance est invalide","en":"The date of birth format is invalid"}}};
export const USER_LOCALE_INVALID = {"id":"user.locale.invalid","translations":{"validators":{"fr":"Veuillez entrer une langue valide","en":"Please enter valid date"}}};
export const USER_CGU_AGREED_NOT_TRUE = {"id":"user.cgu_agreed.not_true","translations":{"validators":{"fr":"Veuillez valider les conditions g\u00e9n\u00e9rales d'utilisation","en":"Please accept Terms of service"}}};
export const USER_NOT_IN_INSTITUTION = {"id":"user.not_in_institution","translations":{"validators":{"fr":"Vous ne faites pas partie de cette institution","en":"You are not part of this institution"}}};
export const USER_TIMEZONE_INVALID = {"id":"user.timezone.invalid","translations":{"validators":{"fr":"Veuillez entrer un fuseau horaire valide","en":"Please enter a valid timezone"}}};
export const USER_NOT_FOUND = {"id":"user.not_found","translations":{"validators":{"fr":"Aucun utilisateur trouv\u00e9","en":"No user found"}}};
export const USER_TITLE_INVALID = {"id":"user.title.invalid","translations":{"validators":{"fr":"Titre invalide, il doit \u00eatre l'un de ces choix : {{ choices }}","en":"Invalid title, it must be one of these choices: {{ choices }}"}}};
export const USER_EXTERNAL_ID_EXISTING = {"id":"user.external_id.existing","translations":{"validators":{"fr":"Un utilisateur existe d\u00e9j\u00e0 avec l'identifiant {{ key }} {{ value }}","en":"A user already exists with the identifier {{ key }} {{ value }}"}}};
export const USER_ENABLE_INVALID_TOKEN = {"id":"user.enable.invalid_token","translations":{"validators":{"fr":"Le token de validation est invalide","en":"Invalid validation token"}}};
export const USER_ENABLE_ALREADY_LOGGED_IN = {"id":"user.enable.already_logged_in","translations":{"validators":{"fr":"Vous \u00eates d\u00e9j\u00e0 connect\u00e9 sur un autre compte.","en":"You are already logged in to another account."}}};
export const USER_ENABLE_ALREADY_ENABLED = {"id":"user.enable.already_enabled","translations":{"validators":{"fr":"Votre compte est d\u00e9j\u00e0 activ\u00e9.","en":"Your account is already active."}}};
export const USER_ENABLE_USER_ALREADY_ENABLED = {"id":"user.enable.user_already_enabled","translations":{"validators":{"fr":"L'utilisateur a d\u00e9j\u00e0 activ\u00e9 son compte.","en":"The user has already activated his account."}}};
export const USER_ENABLE_PENDING_ID_CARD = {"id":"user.enable.pending_id_card","translations":{"validators":{"fr":"Vous ne pouvez pas activer un compte avec un document d'identit\u00e9 en attente de validation.","en":"You cannot activate an account with an ID document waiting for validation."}}};
export const USER_CHECK_EMAIL_ALREADY_EXISTS = {"id":"user.check_email.already_exists","translations":{"validators":{"fr":"Il semble que vous ayez d\u00e9j\u00e0 un compte avec cette adresse email. Veuillez vous connecter ou r\u00e9initialiser votre mot de passe. Si vous n\u2019arrivez toujours pas, veuillez contacter votre \u00e9quipe soignante ou bien le support d\u2019Instamed.","en":"It seems that you already have an account with this email address. Please log in or reset your password. If you still encounter issues, please contact your care team or Instamed's support team."}}};
export const USER_CHECK_EMAIL_INVALID_FORMAT = {"id":"user.check_email.invalid_format","translations":{"validators":{"fr":"Veuillez saisir une adresse e-mail valide","en":"Please enter a valid email address"}}};
export const USER_IMPERSONATING_READONLY = {"id":"user.impersonating.readonly","translations":{"validators":{"fr":"Vous ne pouvez pas modifier un document en mode d'impersonation","en":"You cannot edit document in impersonation mode"}}};
export const USER_COMMUNICATION_BLOCKED = {"id":"user.communication.blocked","translations":{"validators":{"fr":"Vous avez bloqu\u00e9 toute communication vers ce patient, il n'est donc pas possible de lui envoyer d'e-mail ou de SMS d'invitation","en":"you have blocked all communication to this patient, so it is not possible to send invitation mail or SMS"}}};
export const USER_IDENTITY_UPDATE_DENIED = {"id":"user.identity_update_denied","translations":{"validators":{"fr":"Une fois l'identit\u00e9 du patient qualifi\u00e9e, il n'est plus possible de modifier les informations d'identit\u00e9. Si vous pensez qu'il y a une erreur sur ces donn\u00e9es, contactez l'administrateur","en":"Once the patient's identity has been verified, it is no longer possible to modify the identity information. If you believe there is an error in this data, please contact the administrator."}}};
export const MODULE_NAME_NOT_BLANK = {"id":"module.name.not_blank","translations":{"validators":{"fr":"Veuillez saisir un nom de module","en":"Please enter module name"}}};
export const MODULE_CANONICAL_NOT_BLANK = {"id":"module.canonical.not_blank","translations":{"validators":{"fr":"Veuillez saisir un slug de module","en":"Please enter module slug"}}};
export const MODULE_CANONICAL_INVALID = {"id":"module.canonical.invalid","translations":{"validators":{"fr":"Type de module {{ value }} incorrect, il doit comprendre l'un des choix suivants ({{ choices }})","en":"Incorrect module type {{ value }}, it must contain one of the following choices ({{ choices }})"}}};
export const MODULE_NOT_FOUND = {"id":"module.not_found","translations":{"validators":{"fr":"Aucun module trouv\u00e9 pour le slug {{ module }}","en":"No module found for slug {{ module }}"}}};
export const HL7_PARSE_INCORRECT = {"id":"hl7.parse.incorrect","translations":{"validators":{"fr":"Vous ne pouvez pas re-parser un fichier qui n'est pas en erreur","en":"You cannot re-parse file that is not in error"}}};
export const HL7_ACK_NOT_FOUND = {"id":"hl7.ack.not_found","translations":{"validators":{"fr":"Aucun fichier ACK trouv\u00e9","en":"No ACK file found"}}};
export const CARDS_PROFIL_INCORRECT = {"id":"cards.profil.incorrect","translations":{"validators":{"fr":"Type de profil incorrect","en":"Incorrect profile type"}}};
export const CONSULTATION_PATIENT_NOT_NULL = {"id":"consultation.patient.not_null","translations":{"validators":{"fr":"Veuillez lier la consultation \u00e0 un patient","en":"Please link consultation to a patient"}}};
export const CONSULTATION_PDF_GENERATION_ERROR = {"id":"consultation.pdf_generation.error","translations":{"validators":{"fr":"Votre questionnaire a bien \u00e9t\u00e9 enregistr\u00e9 mais une erreur est survenue lors de la cr\u00e9ation du fichier PDF.","en":"Your questionnaire has been saved but an error occurred while creating the PDF file."}}};
export const CONSULTATION_OWNER_INVALID = {"id":"consultation.owner.invalid","translations":{"validators":{"fr":"Vous ne pouvez pas cr\u00e9er cette consultation pour cet utilisateur","en":"You cannot create this consultation for this user"}}};
export const TRANSACTION_REFUND_MAX_AMOUNT = {"id":"transaction.refund.max_amount","translations":{"validators":{"fr":"Vous ne pouvez pas rembourser un montant sup\u00e9rieur \u00e0 la transaction initiale","en":"You cannot refund an amount higher than initial transaction"}}};
export const TRANSACTION_REFUND_ALREADY_DONE = {"id":"transaction.refund.already_done","translations":{"validators":{"fr":"La transaction a d\u00e9j\u00e0 \u00e9t\u00e9 rembours\u00e9e","en":"The transaction has already been refunded"}}};
export const TRANSACTION_REFUND_NOT_CAPTURED = {"id":"transaction.refund.not_captured","translations":{"validators":{"fr":"La transaction n'a pas encore \u00e9t\u00e9 captur\u00e9e","en":"The transaction has not yet been captured"}}};
export const TRANSACTION_REFUND_ONLY_CHARGE = {"id":"transaction.refund.only_charge","translations":{"validators":{"fr":"Seul les transactions de type charge peuvent \u00eatre rembours\u00e9es","en":"Only charge type transactions can be refunded"}}};
export const TRANSACTION_REFUND_NO_AMOUNT = {"id":"transaction.refund.no_amount","translations":{"validators":{"fr":"Veuillez sp\u00e9cifier un montant \u00e0 rembourser","en":"Please specify amount to refund"}}};
export const TRANSACTION_AMOUNT_TOO_SMALL = {"id":"transaction.amount.too_small","translations":{"validators":{"fr":"Vous ne pouvez pas effectuer de paiement inf\u00e9rieur \u00e0 1 euro","en":"You cannot make a payment less than 1 euro"}}};
export const TRANSACTION_NOT_EXISTING = {"id":"transaction.not_existing","translations":{"validators":{"fr":"Le paiement n'a pas pu avoir lieu pour la transaction n\u00b0{{ transaction }}, elle n'a jamais \u00e9t\u00e9 cr\u00e9\u00e9e","en":"The payment could not take place for transaction no. {{ transaction }}, it was never created"}}};
export const PAGE_CANONICAL_NOT_BLANK = {"id":"page.canonical.not_blank","translations":{"validators":{"fr":"Veuillez saisir un nom canonical pour votre page","en":"Please enter canonical name for your page"}}};
export const PAGE_TITLE_NOT_BLANK = {"id":"page.title.not_blank","translations":{"validators":{"fr":"Veuillez saisir un titre pour votre page","en":"Please enter title for your page"}}};
export const PAGE_NOT_FOUND = {"id":"page.not_found","translations":{"validators":{"fr":"Aucune page trouv\u00e9e pour le slug {{ page }}","en":"No page found for slug {{ page }}"}}};
export const PAGE_SECTION_NOT_EMPTY = {"id":"page.section.not_empty","translations":{"validators":{"fr":"Veuillez entrer au moins une section","en":"Please enter at least one section"}}};
export const SECTION_TITLE_NOT_BLANK = {"id":"section.title.not_blank","translations":{"validators":{"fr":"Veuillez saisir un titre pour votre section","en":"Please enter title for your section"}}};
export const SECTION_CONTENT_NOT_BLANK = {"id":"section.content.not_blank","translations":{"validators":{"fr":"Veuillez saisir un contenu pour votre section","en":"Please enter content for your section"}}};
export const PATIENT_SEND_CREDENTIALS_FIRST_LOGIN_NOT_NULL = {"id":"patient.send_credentials.first_login_not_null","translations":{"validators":{"fr":"Vous ne pouvez renvoyer les identifiants d'un patient, seulement s'il ne s'est jamais connect\u00e9","en":"You can only resend patient credentials if they have never logged in"}}};
export const PATIENT_IMPORT_FILE_NOT_FOUND = {"id":"patient.import.file_not_found","translations":{"validators":{"fr":"Veuillez s\u00e9lectionner un fichier \u00e0 parser","en":"Please select file to parse"}}};
export const PATIENT_IMPORT_FIELDS_NOT_FOUND = {"id":"patient.import.fields_not_found","translations":{"validators":{"fr":"Veuillez s\u00e9lectionner une association de champs","en":"Please select field mapping"}}};
export const PATIENT_IMPORT_OPTIONS_NOT_FOUND = {"id":"patient.import.options_not_found","translations":{"validators":{"fr":"Veuillez s\u00e9lectionner des options","en":"Please select options"}}};
export const PATIENT_IMPORT_EMAIL_NOT_FOUND = {"id":"patient.import.email_not_found","translations":{"validators":{"fr":"Votre fichier doit comporter l'adresse e-mail de vos patients","en":"Your file must contain the email address of your patients"}}};
export const PATIENT_IMPORT_LAST_NAME_NOT_FOUND = {"id":"patient.import.lastName_not_found","translations":{"validators":{"fr":"Votre fichier doit comporter le nom de vos patients","en":"Your file must contain the last name of your patients"}}};
export const PATIENT_IMPORT_FIRST_NAME_NOT_FOUND = {"id":"patient.import.firstName_not_found","translations":{"validators":{"fr":"Votre fichier doit comporter le nom de vos patients","en":"Your file must contain the first name of your patients"}}};
export const PATIENT_SHARE_ACCESS_DENIED = {"id":"patient.share.access_denied","translations":{"validators":{"fr":"Vous n'avez pas le droit de partager ce profil.","en":"You do not have permission to share this profile."}}};
export const PATIENT_SHARE_ALREADY_SHARED = {"id":"patient.share.already_shared","translations":{"validators":{"fr":"Ce professionnel a d\u00e9j\u00e0 acc\u00e8s \u00e0 ce profil.","en":"This professional already has access to this profile."}}};
export const PATIENT_SHARE_ALREADY_IN_INSTITUTION = {"id":"patient.share.already_in_institution","translations":{"validators":{"fr":"Ce patient est d\u00e9j\u00e0 pr\u00e9sent dans cet \u00e9tablissement.","en":"This patient is already present in this institution."}}};
export const PATIENT_MERGE_ERROR = {"id":"patient.merge.error","translations":{"validators":{"fr":"Une erreur est survenue lors de la fusion des profils","en":"An error occurred while merging the profiles"}}};
export const PATIENT_MEDIC_NOT_FOUND = {"id":"patient.medic.not_found","translations":{"validators":{"fr":"Ce m\u00e9decin ne fait pas partie de l'\u00e9quipe soignante de ce patient","en":"This doctor is not part of the patient's care team"}}};
export const PATIENT_MEDIC_NOT_EMPTY = {"id":"patient.medic.not_empty","translations":{"validators":{"fr":"Veuillez lier ce patient \u00e0 au moins un m\u00e9decin","en":"Please link this patient to at least one doctor"}}};
export const PATIENT_NURSE_NOT_FOUND = {"id":"patient.nurse.not_found","translations":{"validators":{"fr":"Ce soignant ne fait pas partie de l'\u00e9quipe soignante de ce patient","en":"This nurse is not part of the patient's care team"}}};
export const PATIENT_INS_STATUS_NOT_FOUND = {"id":"patient.ins_status.not_found","translations":{"validators":{"fr":"Le statut INS est introuvable.","en":"INS status not found."}}};
export const PATIENT_INS_STATUS_NOT_ALLOWED = {"id":"patient.ins_status.not_allowed","translations":{"validators":{"fr":"Vous ne pouvez pas modifier le statut INS de ce patient","en":"You are not allowed to update the INS status."}}};
export const PHONE_VALIDATION_PHONE_INVALID = {"id":"phone_validation.phone.invalid","translations":{"validators":{"fr":"Num\u00e9ro de t\u00e9l\u00e9phone incorrect","en":"Invalid phone number"}}};
export const PHONE_VALIDATION_PHONE_NOT_NULL = {"id":"phone_validation.phone.not_null","translations":{"validators":{"fr":"Vous n'avez pas de num\u00e9ro de t\u00e9l\u00e9phone li\u00e9 \u00e0 votre compte","en":"You do not have a phone number linked to your account"}}};
export const TWO_FA_TOKEN_INVALID = {"id":"two_fa.token.invalid","translations":{"validators":{"fr":"Une erreur est survenue sur la validation de votre code.","en":"An error occurred while validating your code."}}};
export const TWO_FA_CODE_EXPIRED = {"id":"two_fa.code.expired","translations":{"validators":{"fr":"Le code est expir\u00e9","en":"The code has expired"}}};
export const TWO_FA_CODE_NOT_FOUND = {"id":"two_fa.code.not_found","translations":{"validators":{"fr":"Le code est expir\u00e9","en":"The code has expired"}}};
export const TWO_FA_CODE_INVALID = {"id":"two_fa.code.invalid","translations":{"validators":{"fr":"Le code est incorrect","en":"The code is incorrect"}}};
export const TWO_FA_CODE_GENERATE_TOO_SOON = {"id":"two_fa.code.generate_too_soon","translations":{"validators":{"fr":"Vous ne pouvez pas reg\u00e9n\u00e9rer de code, merci de r\u00e9essayer ult\u00e9rieurement.","en":"You cannot regenerate a code, please try again later."}}};
export const SUBSCRIPTION_NOT_FOUND = {"id":"subscription.not_found","translations":{"validators":{"fr":"Aucun abonnement trouv\u00e9","en":"No subscription found"}}};
export const SUBSCRIPTION_PRICE_NOT_FOUND = {"id":"subscription.price.not_found","translations":{"validators":{"fr":"Veuillez choisir un plan","en":"Please choose a plan"}}};
export const SUBSCRIPTION_PRICE_NOT_SUPPORTED = {"id":"subscription.price.not_supported","translations":{"validators":{"fr":"Ce prix n'est pas support\u00e9","en":"This price is not supported"}}};
export const SUBSCRIPTION_PRICE_EQUAL = {"id":"subscription.price.equal","translations":{"validators":{"fr":"Les deux produits choisis sont identique","en":"The two chosen products are identical"}}};
export const SUBSCRIPTION_CHANGES_ALREADY_IN_PROGRESS = {"id":"subscription.changes.already_in_progress","translations":{"validators":{"fr":"Vous ne pouvez pas modifier votre abonnement, une modification est d\u00e9j\u00e0 en cours","en":"You cannot modify your subscription, modification is already in progress"}}};
export const SUBSCRIPTION_DELETE_ALREADY_CANCELLED = {"id":"subscription.delete.already_cancelled","translations":{"validators":{"fr":"Votre abonnement est d\u00e9j\u00e0 annul\u00e9","en":"Your subscription is already canceled"}}};
export const SUBSCRIPTION_PAYMENT_METHOD_MISSING = {"id":"subscription.payment_method.missing","translations":{"validators":{"fr":"Vous ne disposez pas de moyen de paiement valide sur votre compte, veuillez en ajouter un","en":"You do not have valid payment method on your account, please add one"}}};
export const SUBSCRIPTION_PROMO_CODE_INVALID = {"id":"subscription.promo_code.invalid","translations":{"validators":{"fr":"Le code promo est invalide ou expir\u00e9","en":"Promo code is invalid or expired"}}};
export const SUBSCRIPTION_INCOMPLETE_STATUS = {"id":"subscription.incomplete_status","translations":{"validators":{"fr":"Veuillez valider le paiement via le lien suivant","en":"Please validate payment using the following link"}}};
export const CSV_FILE_INCORRECT_TYPE = {"id":"csv.file.incorrect_type","translations":{"validators":{"fr":"Veuillez uploader un fichier CSV","en":"Please upload CSV file"}}};
export const JWT_NOT_FOUND = {"id":"jwt.not_found","translations":{"validators":{"fr":"JWT Token not found","en":"JWT Token not found"}}};
export const JWT_EXPIRED = {"id":"jwt.expired","translations":{"validators":{"fr":"Expired JWT Token","en":"Expired JWT Token"}}};
export const ENTITY_CREATED_DATE_INVALID = {"id":"entity.createdDate.invalid","translations":{"validators":{"fr":"La date de cr\u00e9ation entr\u00e9e est invalide","en":"The entered creation date is invalid"}}};
export const ENTITY_UNIQUE_ALREADY_USED = {"id":"entity.unique.already_used","translations":{"validators":{"fr":"Cette valeur est d\u00e9j\u00e0 utilis\u00e9e","en":"This value is already used"}}};
export const ENTITY_BULK_UPDATE_TOO_MANY = {"id":"entity.bulk_update.too_many","translations":{"validators":{"fr":"Vous ne pouvez pas modifier plus de {{ amount }} entit\u00e9s \u00e0 la fois","en":"You cannot modify more than {{ amount }} entities at once"}}};
export const ENTITY_BULK_DELETE_TOO_MANY = {"id":"entity.bulk_delete.too_many","translations":{"validators":{"fr":"Vous ne pouvez pas supprimer plus de {{ amount }} entit\u00e9s \u00e0 la fois","en":"You cannot delete more than {{ amount }} entities at once"}}};
export const DOCTOR_INVITE_PATIENT_NOT_NULL = {"id":"doctor.invite.patient.not_null","translations":{"validators":{"fr":"Veuillez saisir un patient auquel rattacher le m\u00e9decin","en":"Please enter patient to attach the doctor to"}}};
export const UPLOAD_FILE_NOT_NULL = {"id":"upload.file.not_null","translations":{"validators":{"fr":"Veuillez uploader un fichier","en":"Please upload file"}}};
export const ERROR_ACCESS_DENIED = {"id":"error.access_denied","translations":{"validators":{"fr":"L'acc\u00e8s \u00e0 cette page est refus\u00e9.","en":"Access to this page is denied."}}};
export const ERROR_SERVER_ERROR = {"id":"error.server_error","translations":{"validators":{"fr":"Une erreur est survenue, veuillez contacter l'administrateur pour plus d'informations.","en":"An error occurred, please contact administrator for more information."}}};
export const ERROR_INCORRECT_FIELDS = {"id":"error.incorrect_fields","translations":{"validators":{"fr":"Un ou plusieurs champs sont incorrects","en":"One or more fields are incorrect"}}};
export const ERROR_UPLOAD = {"id":"error.upload","translations":{"validators":{"fr":"Une erreur est survenue lors de l'upload du document, veuillez r\u00e9essayer plus tard.","en":"An error occurred while uploading document, please try again later."}}};
export const ERROR_FILE_NOT_FOUND = {"id":"error.file_not_found","translations":{"validators":{"fr":"Le fichier n'a pas \u00e9t\u00e9 trouv\u00e9","en":"The file was not found"}}};
export const ERROR_NO_VALID_DOCUMENT_TO_PRINT = {"id":"error.no_valid_document_to_print","translations":{"validators":{"fr":"Aucun document valide \u00e0 imprimer","en":"No valid document to print"}}};
export const ERROR_OBJECT_NOT_FOUND = {"id":"error.object.not_found","translations":{"validators":{"fr":"Objet non trouv\u00e9.","en":"Object not found."}}};
export const ERROR_USER_NOT_FOUND = {"id":"error.user.not_found","translations":{"validators":{"fr":"Aucun utilisateur trouv\u00e9","en":"No user found"}}};
export const ERROR_USER_ACCOUNT_DELETED = {"id":"error.user.account_deleted","translations":{"validators":{"fr":"Votre compte a \u00e9t\u00e9 supprim\u00e9, vous ne pouvez plus vous connecter","en":"Your account has been deleted, you can no longer log in"}}};
export const ERROR_APPLICATION_OUTDATED = {"id":"error.application_outdated","translations":{"validators":{"fr":"La version de votre application est obsol\u00e8te. Veuillez la mettre \u00e0 jour.","en":"The version of your application is outdated. Please update it."}}};
export const ERROR_PAYMENT_REQUIRED = {"id":"error.payment_required","translations":{"validators":{"fr":"Votre abonnement actuel ne vous permet pas d'acc\u00e9der \u00e0 cette page.","en":"Your current plan does not allow you to access this page."}}};
export const NOT_FOUND = {"id":"Not Found","translations":{"validators":{"fr":"Objet introuvable","en":"Object not found"}}};
export const ACCESS_DENIED = {"id":"Access Denied.","translations":{"validators":{"fr":"L'acc\u00e8s \u00e0 cette page est refus\u00e9.","en":"Access to this page is denied."}}};
export const ACCESS_DENIED_1 = {"id":"Access denied.","translations":{"validators":{"fr":"L'acc\u00e8s \u00e0 cette page est refus\u00e9.","en":"Access to this page is denied."}}};
export const INTERNAL_SERVER_ERROR = {"id":"Internal server error","translations":{"validators":{"fr":"Une erreur est survenue","en":"An error occurred"}}};
export const INTERNAL_SERVER_ERROR_1 = {"id":"Internal Server Error","translations":{"validators":{"fr":"Une erreur est survenue, notre \u00e9quipe technique est au courant et nous sommes en train de le corriger.","en":"An error occurred, our technical team is aware and we are working on fixing it."}}};
export const USER_ACCOUNT_IS_DISABLED = {"id":"User account is disabled.","translations":{"validators":{"fr":"Votre compte est d\u00e9sactiv\u00e9.","en":"Your account is disabled."}}};
export const USER_ACCOUNT_IS_LOCKED = {"id":"User account is locked.","translations":{"validators":{"fr":"Votre compte est bloqu\u00e9","en":"Your account is locked"}}};
export const PRIVATABLE_VISIBILITY_NOT_NULL = {"id":"privatable.visibility.not_null","translations":{"validators":{"fr":"Veuillez choisir une visibilit\u00e9","en":"Please choose visibility"}}};
export const PRIVATABLE_VISIBILITY_INVALID = {"id":"privatable.visibility.invalid","translations":{"validators":{"fr":"La visibilit\u00e9 doit \u00eatre l'un des choix suivants {{ choices }}.","en":"Visibility must be one of the following choices {{ choices }}."}}};
export const API_KEY_INCORRECT = {"id":"api.key.incorrect","translations":{"validators":{"fr":"La cl\u00e9 pass\u00e9e est incorrecte","en":"The provided key is incorrect"}}};
export const MEDIC_INVITE_PARTNER_FORBIDDEN = {"id":"medic.invite_partner.forbidden","translations":{"validators":{"fr":"Vous ne pouvez pas inviter de m\u00e9decin partenaire","en":"You cannot invite partner doctor"}}};
export const MEDIC_RPPS_NOT_FOUND = {"id":"medic.RPPS.not_found","translations":{"validators":{"fr":"Aucun m\u00e9decin instamed n'a \u00e9t\u00e9 trouv\u00e9 avec ce num\u00e9ro RPPS","en":"No Instamed doctor found with this RPPS number"}}};
export const MEDIC_NURSE_NOT_FOUND = {"id":"medic.nurse.not_found","translations":{"validators":{"fr":"Vous n'\u00eates pas li\u00e9 \u00e0 ce param\u00e9dical","en":"You are not associated with this paramedic"}}};
export const MEDIC_MEDIC_NOT_FOUND = {"id":"medic.medic.not_found","translations":{"validators":{"fr":"Vous n'\u00eates pas li\u00e9 \u00e0 ce m\u00e9decin","en":"You are not associated with this doctor"}}};
export const MEDIC_MSSANTE_EMAIL_NOT_CONFIGURED = {"id":"medic.mssante_email.not_configured","translations":{"validators":{"fr":"Vous ne pouvez pas inviter de m\u00e9decin via MSSant\u00e9 car vous n'avez pas encore configur\u00e9 MSSant\u00e9 sur votre profil"}}};
export const MEDIC_INVITE_ACCESS_DENIED = {"id":"medic.invite.access_denied","translations":{"validators":{"fr":"Vous n\u2019\u00eates pas autoris\u00e9 \u00e0 inviter un m\u00e9decin externe \u00e0 acc\u00e9der \u00e0 votre environnement. Merci de contacter l\u2019administrateur de votre \u00e9tablissement afin qu'il ajoute votre correspondant \u00e0 votre \u00e9quipe soignante.","en":"You are not authorized to invite an external doctor to access your environment. Please contact the administrator for further information at contact@instamed.fr."}}};
export const MEDIC_INVITE_ACCESS_DENIED_IN_ESTABLISHMENT = {"id":"medic.invite.access_denied_in_establishment","translations":{"validators":{"fr":"Vous n'\u00eates pas autoris\u00e9 \u00e0 inviter un m\u00e9decin d'h\u00f4pital \u00e0 votre \u00e9quipe soignante. Merci de contacter ce m\u00e9decin pour qu'il fasse les d\u00e9marches n\u00e9cessaires de son c\u00f4t\u00e9.","en":"You are not authorized to invite a hospital doctor to your care team. Please contact this doctor so they can make the necessary arrangements."}}};
export const MEDIC_INVITE_PAYMENT_REQUIRED = {"id":"medic.invite.payment_required","translations":{"validators":{"fr":"Vous ne pouvez pas inviter de m\u00e9decin dans votre \u00e9quipe soignante avec votre abonnement actuel.","en":"You cannot invite a doctor to your care team with your current subscription."}}};
export const INVITATION_OWNER_NOT_FOUND = {"id":"invitation.owner.not_found","translations":{"validators":{"fr":"Aucun m\u00e9decin invitant n'a \u00e9t\u00e9 trouv\u00e9","en":"No inviting doctor found"}}};
export const INVITATION_DOCTOR_NOT_PENDING = {"id":"invitation.doctor.not_pending","translations":{"validators":{"fr":"Vous ne pouvez pas renvoyer un email \u00e0 un m\u00e9decin d\u00e9j\u00e0 valid\u00e9.","en":"You cannot resend an email to an already validated doctor."}}};
export const INVITATION_DOCTOR_LIMIT_REACHED = {"id":"invitation.doctor.limit_reached","translations":{"validators":{"fr":"Vous avez atteint la limite du nombre de dossiers re\u00e7us en mode invit\u00e9, pour d\u00e9bloquer l\u2019acc\u00e8s aux nouveaux dossiers partag\u00e9s, veuillez souscrire \u00e0 l\u2019abonnement \"Partenaire\".","en":"You have reached the limit of the number of medical records received as a Guest, to unblock access to new shared folders please subscribe to our Partner plan."}}};
export const _2FA_AUTH_REQUIRED = {"id":"2fa.auth.required","translations":{"validators":{"fr":"L'authentification a 2 \u00e9tapes est requise","en":"Two-factor authentication is required"}}};
export const _2FA_CODE_INVALID = {"id":"2fa.code.invalid","translations":{"validators":{"fr":"Le code saisi est incorrect","en":"The entered code is incorrect"}}};
export const _2FA_BACKUP_CODES_ALREADY_GENERATED = {"id":"2fa.backup_codes.already_generated","translations":{"validators":{"fr":"Vous avez d\u00e9j\u00e0 g\u00e9n\u00e9r\u00e9 des codes de r\u00e9cup\u00e9ration.","en":"You have already generated recovery codes."}}};
export const _2FA_BACKUP_CODES2FA_REQUIRED = {"id":"2fa.backup_codes.2fa_required","translations":{"validators":{"fr":"Vous devez avoir activ\u00e9 l'authentification \u00e0 deux facteurs pour pouvoir g\u00e9n\u00e9rer les codes de r\u00e9cup\u00e9ration.","en":"You must enable two-factor authentication to generate recovery codes."}}};
export const TEMPLATE_VARIABLE_TYPE_NOT_BLANK = {"id":"template_variable.type.not_blank","translations":{"validators":{"fr":"Le type de variable ne peut pas \u00eatre vide","en":"The variable type cannot be empty"}}};
export const TEMPLATE_VARIABLE_TYPE_INVALID = {"id":"template_variable.type.invalid","translations":{"validators":{"fr":"Le type de variable est invalide.","en":"The variable type is invalid."}}};
export const TEMPLATE_VARIABLE_DELETE_ALREADY_USED = {"id":"template_variable.delete.already_used","translations":{"validators":{"fr":"Vous ne pouvez pas supprimer une variable utilis\u00e9e dans un mod\u00e8le de document","en":"You cannot delete variable used in a document template"}}};
export const TEMPLATE_VARIABLE_NAME_NOT_BLANK = {"id":"template_variable.name.not_blank","translations":{"validators":{"fr":"Le nom de la variable ne peut pas \u00eatre vide","en":"The variable name cannot be empty"}}};
export const EMERGENCY_CONTACT_PATIENT_NOT_NULL = {"id":"emergency_contact.patient.not_null","translations":{"validators":{"fr":"Veuillez lier ce contact \u00e0 un patient","en":"Please link this contact to patient"}}};
export const TASK_STATUS_INVALID = {"id":"task.status.invalid","translations":{"validators":{"fr":"Le statut est invalide","en":"The status is invalid"}}};
export const TASK_TYPE_NOT_BLANK = {"id":"task.type.not_blank","translations":{"validators":{"fr":"Le type de t\u00e2che doit \u00eatre d\u00e9fini","en":"The task type must be defined"}}};
export const TASK_TYPE_INVALID = {"id":"task.type.invalid","translations":{"validators":{"fr":"Le type de t\u00e2che saisit est incorrect","en":"The entered task type is incorrect"}}};
export const TASK_TYPE_INCORRECT_ROLES = {"id":"task.type.incorrect_roles","translations":{"validators":{"fr":"Vous ne pouvez pas cr\u00e9er ce type de t\u00e2che pour ces r\u00f4les","en":"You cannot create this type of task for these roles"}}};
export const TASK_USER_ALREADY_LINKED = {"id":"task.user.already_linked","translations":{"validators":{"fr":"Cet utilisateur fait d\u00e9j\u00e0 partie de votre \u00e9quipe soignante.","en":"This user is already part of your care team."}}};
export const TASK_USER_ALREADY_INVITED = {"id":"task.user.already_invited","translations":{"validators":{"fr":"Vous avez d\u00e9j\u00e0 une invitation en attente pour cet utilisateur","en":"You already have pending invitation for this user"}}};
export const TASK_LINK_MEDIC_TO_MEDIC_SUBSCRIPTION_NULL = {"id":"task.link_medic_to_medic.subscription_null","translations":{"validators":{"fr":"Vous ne pouvez pas rejoindre l'\u00e9quipe soignante de ce m\u00e9decin avec votre abonnement actuel.","en":"You cannot join this doctor's care team with your current subscription."}}};
export const TASK_MERGE_REQUEST_USER_NOT_FOUND = {"id":"task.merge_request.user_not_found","translations":{"validators":{"fr":"Aucun utilisateur trouv\u00e9 pour la fusion.","en":"No user found for the merge."}}};
export const TASK_RESEND_WRONG_STATUS = {"id":"task.resend.wrong_status","translations":{"validators":{"fr":"Vous ne pouvez pas r\u00e9inviter un utilisateur qui a d\u00e9j\u00e0 pris en compte votre partage","en":"You cannot reinvite user who has already acknowledged your share"}}};
export const ADDRESS_ADDRESS_NOT_BLANK = {"id":"address.address.not_blank","translations":{"validators":{"fr":"Veuillez entrer une adresse","en":"Please enter address"}}};
export const ADDRESS_POSTAL_CODE_NOT_BLANK = {"id":"address.postal_code.not_blank","translations":{"validators":{"fr":"Veuillez entrer un code postal","en":"Please enter postal code"}}};
export const ADDRESS_CITY_NOT_BLANK = {"id":"address.city.not_blank","translations":{"validators":{"fr":"Veuillez entrer une ville","en":"Please enter city"}}};
export const ADDRESS_PATIENT_NOT_NULL = {"id":"address.patient.not_null","translations":{"validators":{"fr":"Veuillez entrer un patient","en":"Please enter patient"}}};
export const LETTER_HEAD_NAME_NOT_BLANK = {"id":"letter_head.name.not_blank","translations":{"validators":{"fr":"Veuillez saisir un nom","en":"Please enter name"}}};
export const LETTER_HEAD_DELETE_ALREADY_USED = {"id":"letter_head.delete.already_used","translations":{"validators":{"fr":"Vous ne pouvez pas supprimer un papier \u00e0 en-t\u00eate utilis\u00e9 dans un mod\u00e8le de document","en":"You cannot delete letterhead used in document template"}}};
export const DEVICE_NAME_NOT_BLANK = {"id":"device.name.not_blank","translations":{"validators":{"fr":"Veuillez saisir un nom","en":"Please enter name"}}};
export const DEVICE_DEVICE_ID_NOT_BLANK = {"id":"device.device_id.not_blank","translations":{"validators":{"fr":"Veuillez saisir un identifiant d'appareil","en":"Please enter device ID"}}};
export const DEVICE_PUBLIC_KEY_INVALID = {"id":"device.public_key.invalid","translations":{"validators":{"fr":"Veuillez saisir une cl\u00e9 publique valide","en":"Please enter a valid public key"}}};
export const DEVICE_REFRESH_TOKEN_NOT_FOUND = {"id":"device.refresh_token.not_found","translations":{"validators":{"fr":"Aucun token de raffraichissement trouv\u00e9","en":"No refresh token found"}}};
export const DEVICE_ACCESS_FORBIDDEN = {"id":"device.access.forbidden","translations":{"validators":{"fr":"Vous ne pouvez pas modifier cet appareil","en":"You are not allowed to modify this device"}}};
export const DEVICE_ONE_SIGNAL_ID_NOT_FOUND = {"id":"device.one_signal_id.not_found","translations":{"validators":{"fr":"L'identifiant onesignal pass\u00e9 est incorrect","en":"The provided onesignal ID is incorrect"}}};
export const DEVICE_TOKEN_NOT_NULL = {"id":"device.token.not_null","translations":{"validators":{"fr":"Aucun refresh token trouv\u00e9 pour ce JWT","en":"No refresh token found for this JWT"}}};
export const DEVICE_SIGNATURE_NOT_NULL = {"id":"device.signature.not_null","translations":{"validators":{"fr":"Veuillez saisir une signature pour configurer une nouvelle cl\u00e9 publique","en":"Please enter signature to set up a new public key"}}};
export const DEVICE_TIMESTAMP_NOT_NULL = {"id":"device.timestamp.not_null","translations":{"validators":{"fr":"Veuillez saisir un timestamp pour configurer une nouvelle cl\u00e9 publique","en":"Please enter timestamp to set up a new public key"}}};
export const SERVICE_ENTITY_SERVICE_NOT_EMPTY = {"id":"service_entity.service.not_empty","translations":{"validators":{"fr":"Veuillez saisir un ou plusieurs service","en":"Please enter one or more services"}}};
export const AUTO_LOGIN_LINK_EXPIRED_OR_NOT_FOUND = {"id":"auto_login_link.expired_or_not_found","translations":{"validators":{"fr":"Le lien est inexistant ou expir\u00e9.","en":"The link is non-existent or expired."}}};
export const AUTH_JWT_INVALID = {"id":"auth.jwt_invalid","translations":{"validators":{"fr":"Votre jeton de connexion est invalide.","en":"Your login token is invalid."}}};
export const AUTH_JWT_SIMULTANEOUS_LOGIN = {"id":"auth.jwt_simultaneous_login","translations":{"validators":{"fr":"Votre session a expir\u00e9, suite \u00e0 une connexion depuis un autre appareil.","en":"Your session has expired due to login from another device."}}};
export const AUTH_INVALID_REFRESH_TOKEN = {"id":"auth.invalid_refresh_token","translations":{"validators":{"fr":"Votre jeton de connexion est invalide ou a expir\u00e9.","en":"Your login token is invalid or has expired."}}};
export const AUTH_INVALID_REFRESH_TOKEN_SIGNATURE = {"id":"auth.invalid_refresh_token_signature","translations":{"validators":{"fr":"Votre jeton de connexion est invalide","en":"Your login token is invalid"}}};
export const AUTH_JWT_EXPIRED = {"id":"auth.jwt_expired","translations":{"validators":{"fr":"Votre session a expir\u00e9, veuillez vous reconnecter.","en":"Your session has expired. Please log in again."}}};
export const AUTH_BAD_CREDENTIALS = {"id":"auth.bad_credentials","translations":{"validators":{"fr":"Votre adresse e-mail ou votre mot de passe est incorrect","en":"Your email address or password is incorrect."}}};
export const AUTH_TOO_MUCH_ATTEMPTS = {"id":"auth.too_much_attempts","translations":{"validators":{"fr":"Suite \u00e0 un trop grand nombre d'essai, votre compte a \u00e9t\u00e9 bloqu\u00e9 pendant une heure.","en":"Due to too many attempts, your account has been locked for one hour."}}};
export const AUTH_USER_DISABLED = {"id":"auth.user_disabled","translations":{"validators":{"fr":"Vous ne pouvez pas vous connecter, votre compte est d\u00e9sactiv\u00e9","en":"You cannot log in; your account is disabled"}}};
export const AUTH_INVALID_TIMESTAMP = {"id":"auth.invalid_timestamp","translations":{"validators":{"fr":"Le timestamp pass\u00e9 est invalide ou expir\u00e9","en":"The provided timestamp is invalid or expired"}}};
export const AUTH_TOKEN_MISSING_OR_INVALID = {"id":"auth.token_missing_or_invalid","translations":{"validators":{"fr":"Token incorrect ou manquant","en":"Token incorrect or missing"}}};
export const AUTH_USER_NOT_FOUND = {"id":"auth.user_not_found","translations":{"validators":{"fr":"Utilisateur non trouv\u00e9","en":"User not found"}}};
export const AUTH_SESSION_EXPIRED = {"id":"auth.session_expired","translations":{"validators":{"fr":"Votre session a expir\u00e9, veuillez vous reconnecter.","en":"Your session has expired. Please log in again."}}};
export const AUTH_INVALID_PASSWORD = {"id":"auth.invalid_password","translations":{"validators":{"fr":"Votre mot de passe est incorrect.","en":"Your password is incorrect."}}};
export const AUTH_OAUTH_MISSING_CODE = {"id":"auth.oauth.missing_code","translations":{"validators":{"fr":"Le param\u00e8tre code est manquant","en":"The code parameter is missing"}}};
export const AUTH_OAUTH_INVALID_STATE = {"id":"auth.oauth.invalid_state","translations":{"validators":{"fr":"Invalid state","en":"Invalid status"}}};
export const AUTH_OAUTH_INVALID_CODE = {"id":"auth.oauth.invalid_code","translations":{"validators":{"fr":"Une erreur est survenue lors de la liaison a votre compte Stellair, veuillez r\u00e9essayer ult\u00e9rieurement.","en":"An error occurred while linking your Stellair account. Please try again later."}}};
export const THIS_CUSTOMER_HAS_NO_ATTACHED_PAYMENT_SOURCE_OR_DEFAULT_PAYMENT_METHOD = {"id":"This customer has no attached payment source or default payment method.","translations":{"validators":{"fr":"Vous ne pouvez pas effectuer cette action, aucune carte de paiement valide n'est attach\u00e9e \u00e0 votre compte.","en":"You cannot perform this action; no valid payment card is attached to your account."}}};
export const MEDICAL_BACKGROUND_FAMILY_MEMBER_TYPE_INVALID = {"id":"medical_background.family_member_type.invalid","translations":{"validators":{"fr":"Le champ est incorrect","en":"The field is incorrect"}}};
export const MEDICAL_BACKGROUND_FAMILY_MEMBER_DATE_OF_DEATH_INVALID = {"id":"medical_background.family_member_date_of_death.invalid","translations":{"validators":{"fr":"La date ne peut pas \u00eatre sup\u00e9rieure \u00e0 la date du jour","en":"The date cannot be greater than today's date"}}};
export const MEDICAL_BACKGROUND_TYPE_NOT_NULL = {"id":"medical_background.type.not_null","translations":{"validators":{"fr":"Veuillez saisir un type d'ant\u00e9c\u00e9dent","en":"Please enter type of medical background"}}};
export const PATIENT_BILLING_MEDIC_NOT_CONFIGURED = {"id":"patient_billing.medic_not_configured","translations":{"validators":{"fr":"Ce m\u00e9decin n'a pas encore configur\u00e9 la t\u00e9l\u00e9transmission.","en":"This doctor has not yet set up e-transmission."}}};
export const PATIENT_BILLING_NOT_CONFIGURED = {"id":"patient_billing.not_configured","translations":{"validators":{"fr":"Vous n'avez pas configur\u00e9 la t\u00e9l\u00e9transmission.","en":"You have not set up e-transmission."}}};
export const PATIENT_BILLING_NOT_ENABLED = {"id":"patient_billing.not_enabled","translations":{"validators":{"fr":"La t\u00e9l\u00e9transmission n'est pas activ\u00e9e pour votre compte","en":"E-transmission is not enabled for your account"}}};
export const PATIENT_BILLING_PAYMENT_REQUIRED = {"id":"patient_billing.payment_required","translations":{"validators":{"fr":"Veuillez choisir un abonnement pour votre compte","en":"Please choose subscription for your account"}}};
export const PATIENT_BILLING_CPS_CARD_CANCELED_READING = {"id":"patient_billing.cps_card.canceled_reading","translations":{"validators":{"fr":"Vous avez annul\u00e9 la lecture de votre carte CPS, veuillez r\u00e9essayer.","en":"You canceled the reading of your CPS card, please try again."}}};
export const PATIENT_BILLING_HEALTH_CARD_SSN_NOT_FOUND = {"id":"patient_billing.health_card.ssn_not_found","translations":{"validators":{"fr":"Ce num\u00e9ro de s\u00e9curit\u00e9 sociale n'a pas \u00e9t\u00e9 trouv\u00e9 sur la carte vitale.","en":"This social security number was not found on Carte vitale."}}};
export const PATIENT_BILLING_HEALTH_CARD_PATIENT_NOT_FOUND = {"id":"patient_billing.health_card.patient_not_found","translations":{"validators":{"fr":"Ce patient n'a pas \u00e9t\u00e9 trouv\u00e9 sur la carte vitale. Merci de v\u00e9rifier le nom et pr\u00e9nom entr\u00e9 dans Instamed et r\u00e9essayer.","en":"This patient was not found on Carte vitale. Please check the first and last name entered in Instamed and try again."}}};
export const PATIENT_BILLING_HEALTH_CARD_NOT_FOUND = {"id":"patient_billing.health_card.not_found","translations":{"validators":{"fr":"Veuillez ins\u00e9rer la carte vitale du patient dans votre lecteur puis cliquer sur  le bouton \"Lire la carte vitale\"","en":"Please insert the patient's Carte vitale into your reader and click on \"Read Carte vitale\" button"}}};
export const PATIENT_BILLING_HEALTH_CARD_EXISTING_ACCOUNT = {"id":"patient_billing.health_card.existing_account","translations":{"validators":{"fr":"Nous avons trouv\u00e9 un patient similaire dans vos contacts, veuillez valider s'il s'agit du m\u00eame compte ou non.","en":"We found similar patient in your contacts; please confirm if it is the same account or not."}}};
export const PATIENT_BILLING_HEALTH_CARD_NO_TERMINAL_FOUND = {"id":"patient_billing.health_card.no_terminal_found","translations":{"validators":{"fr":"Vous n'avez pas associ\u00e9 votre terminal \u00e0 votre compte Stellair.","en":"You have not associated your terminal with your Stellair account."}}};
export const PATIENT_BILLING_HEALTH_CARD_TERMINAL_OFFLINE = {"id":"patient_billing.health_card.terminal_offline","translations":{"validators":{"fr":"Veuillez allumer votre lecteur de carte et ins\u00e9rer la carte vitale du patient.","en":"Please turn on your card reader and insert the patient's Carte vitale."}}};
export const PATIENT_BILLING_HEALTH_CARD_AUTHENTICATION_REQUIRED = {"id":"patient_billing.health_card.authentication_required","translations":{"validators":{"fr":"Veuillez vous authentifier sur votre lecteur de carte.","en":"Please authenticate on your card reader."}}};
export const PATIENT_BILLING_HEALTH_CARD_ERROR_READING = {"id":"patient_billing.health_card.error_reading","translations":{"validators":{"fr":"Une erreur est survenue lors de la lecture de la carte vitale, veuillez r\u00e9essayer.","en":"An error occurred while reading the Carte vitale, please try again."}}};
export const PATIENT_BILLING_HEALTH_CARD_INVALID_TOKEN = {"id":"patient_billing.health_card.invalid_token","translations":{"validators":{"fr":"Une erreur est survenue lors de la lecture de votre carte CPS, veuillez r\u00e9essayer.","en":"An error occurred while reading your CPS card, please try again."}}};
export const PATIENT_BILLING_HEALTH_CARD_FORBIDDEN = {"id":"patient_billing.health_card.forbidden","translations":{"validators":{"fr":"Une erreur est survenue lors de la lecture de votre carte CPS, veuillez r\u00e9essayer.","en":"An error occurred while reading your CPS card, please try again."}}};
export const PATIENT_BILLING_SUBSCRIPTION_PRICE_INVALID = {"id":"patient_billing.subscription.price_invalid","translations":{"validators":{"fr":"Ce prix n'est pas li\u00e9 \u00e0 un abonnement de t\u00e9l\u00e9transmission","en":"This price is not associated with a teletransmission subscription"}}};
export const PATIENT_BILLING_SUBSCRIPTION_INVALID_PLAN = {"id":"patient_billing.subscription.invalid_plan","translations":{"validators":{"fr":"Le plan {{ plan }} n'existe pas ou plus","en":"The plan {{ plan }} does not exist or is no longer available"}}};
export const PATIENT_BILLING_SUBSCRIPTION_INVALID_CORE_PLAN = {"id":"patient_billing.subscription.invalid_core_plan","translations":{"validators":{"fr":"La t\u00e9l\u00e9transmission n'est disponible que pour l'abonnement premium","en":"Teletransmission is only available for the premium subscription"}}};
export const PATIENT_BILLING_SUBSCRIPTION_EXISTING = {"id":"patient_billing.subscription.existing","translations":{"validators":{"fr":"Vous disposez d\u00e9j\u00e0 d'un abonnement pour la t\u00e9l\u00e9transmission. Si vous pensez qu'il s'agit d'une erreur, veuillez contacter l'\u00e9quipe \u00e0 l'adresse contact@instamed.fr","en":"You already have a teletransmission subscription. If you think this is a mistake, please contact the team at contact@instamed.fr"}}};
export const PATIENT_BILLING_SUBSCRIPTION_COUNT_INVALID = {"id":"patient_billing.subscription.count_invalid","translations":{"validators":{"fr":"Vous devez s\u00e9lectionner au moins un lecteur.","en":"You must select at least one reader."}}};
export const PATIENT_BILLING_SUBSCRIPTION_PRICE_SETUP_INVALID = {"id":"patient_billing.subscription.price_setup_invalid","translations":{"validators":{"fr":"Vous devez s\u00e9lectionner au moins un prix d'installation","en":"You must select at least one installation price"}}};
export const PATIENT_BILLING_SUBSCRIPTION_PRICE_RECURRING_INVALID = {"id":"patient_billing.subscription.price_recurring_invalid","translations":{"validators":{"fr":"Vous devez s\u00e9lectionner au moins un abonnement","en":"You must select at least one subscription"}}};
export const PATIENT_BILLING_SUBSCRIPTION_PRICE_ONE_TIME_SUBSCRIPTION_INVALID = {"id":"patient_billing.subscription.price_one_time_subscription_invalid","translations":{"validators":{"fr":"Vous devez s\u00e9lectionner l'abonnement li\u00e9 \u00e0 l'achat du lecteur","en":"You must select the subscription related to the purchase of the reader"}}};
export const PATIENT_BILLING_PROFILE_ACCOUNT_EXISTING = {"id":"patient_billing.profile.account_existing","translations":{"validators":{"fr":"Vous ne pouvez pas cr\u00e9er de compte car vous en avez d\u00e9j\u00e0 un.","en":"You cannot create account because you already have one."}}};
export const PATIENT_BILLING_AUTH_PROFILE_NOT_FOUND = {"id":"patient_billing.auth.profile_not_found","translations":{"validators":{"fr":"Vous devez cr\u00e9er un profil avant de pouvoir vous connecter","en":"You need to create profile before you can log in"}}};
export const PATIENT_BILLING_AUTH_UNAUTHORIZED = {"id":"patient_billing.auth.unauthorized","translations":{"validators":{"fr":"Pour des raisons de s\u00e9curit\u00e9, veuillez-vous reconnecter sur Stellair","en":"For security reasons, please reconnect to Stellair"}}};
export const PATIENT_BILLING_INVOICE_NOT_FOUND = {"id":"patient_billing.invoice.not_found","translations":{"validators":{"fr":"Cette facture n'existe pas ou est inaccessible","en":"This invoice does not exist or is not accessible"}}};
export const SUBSCRIPTION_GROUP_ORDER_NOT_NULL = {"id":"subscription_group.order.not_null","translations":{"validators":{"fr":"Veuillez saisir un ordre","en":"Please enter order"}}};
export const SUBSCRIPTION_GROUP_ORDER_NOT_IN_RANGE = {"id":"subscription_group.order.not_in_range","translations":{"validators":{"fr":"La valeur {{ value }} doit \u00eatre comprise entre {{ min }} et {{ max }}","en":"The value {{ value }} must be between {{ min }} and {{ max }}"}}};
export const SUBSCRIPTION_GROUP_CANONICAL_INVALID = {"id":"subscription_group.canonical.invalid","translations":{"validators":{"fr":"Veuillez saisir un type parmi {{ choices }}","en":"Please enter type from among {{ choices }}"}}};
export const SUBSCRIPTION_GROUP_CANONICAL_NOT_BLANK = {"id":"subscription_group.canonical.not_blank","translations":{"validators":{"fr":"Veuillez saisir un type","en":"Please enter type"}}};
export const SUBSCRIPTION_GROUP_URL_NOT_BLANK = {"id":"subscription_group.url.not_blank","translations":{"validators":{"fr":"Veuillez saisir une url","en":"Please enter URL"}}};
export const SUBSCRIPTION_GROUP_AVAILABLE_NOT_NULL = {"id":"subscription_group.available.not_null","translations":{"validators":{"fr":"Veuillez saisir une disponibilit\u00e9","en":"Please enter availability"}}};
export const SUBSCRIPTION_GROUP_NAME_NOT_BLANK = {"id":"subscription_group.name.not_blank","translations":{"validators":{"fr":"Veuillez saisir un nom","en":"Please enter name"}}};
export const SUBSCRIPTION_GROUP_IMAGE_NOT_BLANK = {"id":"subscription_group.image.not_blank","translations":{"validators":{"fr":"Veuillez uploader une image","en":"Please upload image"}}};
export const PATIENT_FAMILY_MEMBER_RELATION_TYPE_INVALID = {"id":"patient_family_member.relation_type.invalid","translations":{"validators":{"fr":"Le type de relation est incorrect","en":"The relation type is incorrect"}}};
export const MEDICAL_RECORD_TAB_POSITION_NOT_IN_RANGE = {"id":"medical_record_tab.position.not_in_range","translations":{"validators":{"fr":"Veuillez saisir une valeur entre 0 et 15","en":"Please enter value between 0 and 15"}}};
export const MEDICAL_RECORD_FIELD_VARIABLE_TYPE_IS_EMPTY = {"id":"medical_record_field.variable_type.is_empty","translations":{"validators":{"fr":"Veuillez choisir un type de variable","en":"Please choose variable type"}}};
export const MEDICAL_RECORD_FIELD_CHOICES_IS_EMPTY = {"id":"medical_record_field.choices.is_empty","translations":{"validators":{"fr":"Veuillez saisir des choix","en":"Please enter choices"}}};
export const MEDICAL_RECORD_FIELD_FIELD_TYPE_NOT_BLANK = {"id":"medical_record_field.field_type.not_blank","translations":{"validators":{"fr":"Le type de champ ne peut pas \u00eatre vide","en":"The field type cannot be empty"}}};
export const MEDICAL_RECORD_FIELD_FIELD_TYPE_INVALID = {"id":"medical_record_field.field_type.invalid","translations":{"validators":{"fr":"Le type de champ est invalide","en":"The field type is invalid"}}};
export const MEDICAL_RECORD_FIELD_FORMULA_IS_EMPTY = {"id":"medical_record_field.formula.is_empty","translations":{"validators":{"fr":"Veuillez saisir une formule pour un champ de type calculated","en":"Please enter formula for field of type calculated"}}};
export const MEDICAL_RECORD_FIELD_FORMULA_WRONG_FIELD_TYPE = {"id":"medical_record_field.formula.wrong_field_type","translations":{"validators":{"fr":"Le champ formule n'est autoris\u00e9e que pour un champ de type \"calculated\"","en":"The formula field is only allowed for field of type \"calculated\""}}};
export const MEDICAL_RECORD_FIELD_FORMULA_WRONG_FIELD_STATUS = {"id":"medical_record_field.formula.wrong_field_status","translations":{"validators":{"fr":"Le status 'enabled' n'est pas autoris\u00e9.","en":"The status 'enabled' is not allowed."}}};
export const MEDICAL_RECORD_FIELD_FORMULA_NO_HISTORICAL = {"id":"medical_record_field.formula.no_historical","translations":{"validators":{"fr":"Un champ de type \"calculated\" n'a pas d'historique.","en":"A \"calculated\" field type does not have history."}}};
export const MEDICAL_RECORD_FIELD_FORMULA_NO_VARIABLE = {"id":"medical_record_field.formula.no_variable","translations":{"validators":{"fr":"Une formule doit contenir au moins une variable. (ex \\#name\\#)","en":"A formula must contain at least one variable. (e.g. \\#name\\#)"}}};
export const MEDICAL_RECORD_FIELD_FORMULA_INVALID = {"id":"medical_record_field.formula.invalid","translations":{"validators":{"fr":"Une erreur est survenue lors du test de votre formule : {{ error }}","en":"An error occurred while testing your formula: {{ error }}"}}};
export const MEDICAL_RECORD_FIELD_FORMULA_INVALID_VARIABLE = {"id":"medical_record_field.formula.invalid_variable","translations":{"validators":{"fr":"Variable invalide. Une variable est un champ canonical entre deux \\#","en":"Invalid variable. A variable is a canonical field between two \\#"}}};
export const MEDICAL_RECORD_FIELD_CONDITIONS_KEY_MISSING_OPERATOR = {"id":"medical_record_field.conditions.key_missing_operator","translations":{"validators":{"fr":"La cl\u00e9 'operator' est manquante.","en":"The 'operator' key is missing."}}};
export const MEDICAL_RECORD_FIELD_CONDITIONS_KEY_MISSING_CONDITIONS = {"id":"medical_record_field.conditions.key_missing_conditions","translations":{"validators":{"fr":"La cl\u00e9 'conditions' est manquante.","en":"The 'conditions' key is missing."}}};
export const MEDICAL_RECORD_FIELD_CONDITIONS_KEY_MISSING_VALUE = {"id":"medical_record_field.conditions.key_missing_value","translations":{"validators":{"fr":"La cl\u00e9 'value' est manquante dans le champ condition.","en":"The 'value' key is missing in the condition field."}}};
export const MEDICAL_RECORD_FIELD_CONDITIONS_KEY_MISSING_COMPARATOR = {"id":"medical_record_field.conditions.key_missing_comparator","translations":{"validators":{"fr":"La cl\u00e9 'comparator' est manquante dans le champ condition.","en":"The 'comparator' key is missing in the condition field."}}};
export const MEDICAL_RECORD_FIELD_CONDITIONS_KEY_MISSING_COMPARED_VALUE = {"id":"medical_record_field.conditions.key_missing_compared_value","translations":{"validators":{"fr":"La cl\u00e9 'compared_value' est manquante dans le champ condition.","en":"The 'compared_value' key is missing in the condition field."}}};
export const MEDICAL_RECORD_FIELD_CONDITIONS_CANONICAL_NOT_FOUND = {"id":"medical_record_field.conditions.canonical_not_found","translations":{"validators":{"fr":"Le champ {{ value }} n'existe pas.","en":"Field {{ value }} does not exist."}}};
export const MEDICAL_RECORD_FIELD_CONDITIONS_OPERATOR_INVALID = {"id":"medical_record_field.conditions.operator_invalid","translations":{"validators":{"fr":"L'op\u00e9rateur {{ value }} est invalide.","en":"Operator {{ value }} is invalid."}}};
export const MEDICAL_RECORD_FIELD_CONDITIONS_WRONG_OPERATOR = {"id":"medical_record_field.conditions.wrong_operator","translations":{"validators":{"fr":"La valeur du champ 'operator' ne peut \u00eatre que OR ou AND.","en":"Value of the 'operator' field can only be OR or AND."}}};
export const MEDICAL_RECORD_FIELD_REPEATER_ADD_MORE_LABEL_IS_EMPTY = {"id":"medical_record_field.repeater.add_more_label_is_empty","translations":{"validators":{"fr":"Pour un champ de type 'repeater', le champ 'addMoreLabel' ne peut pas \u00eatre vide.","en":"For 'repeater' field, the 'addMoreLabel' field cannot be empty."}}};
export const MEDICAL_RECORD_FIELD_REPEATER_CHILDREN_IS_EMPTY = {"id":"medical_record_field.repeater.children_is_empty","translations":{"validators":{"fr":"Pour un champ de type 'repeater', le champ 'repeaterChildren' ne peut pas \u00eatre vide.","en":"For 'repeater' field, the 'repeaterChildren' field cannot be empty."}}};
export const MEDICAL_RECORD_FIELD_SERVICES_IS_EMPTY = {"id":"medical_record_field.services.is_empty","translations":{"validators":{"fr":"Il est obligatoire de sp\u00e9cifier au moins un service.","en":"You must specify at least one service."}}};
export const MEDICAL_RECORD_FIELD_MERGE_DIFFERENT = {"id":"medical_record_field.merge.different","translations":{"validators":{"fr":"Les champs s\u00e9lectionn\u00e9s sont les m\u00eames et ne peuvent donc pas \u00eatre fusionn\u00e9s","en":"Selected fields are the same and cannot be merged"}}};
export const MEDICAL_RECORD_FIELD_MERGE_DIFFERENT_TYPE = {"id":"medical_record_field.merge.different_type","translations":{"validators":{"fr":"Les champs s\u00e9lectionn\u00e9s sont les m\u00eames et ne peuvent donc pas \u00eatre fusionn\u00e9s","en":"Selected fields are the same and cannot be merged"}}};
export const MEDICAL_RECORD_FIELD_MERGE_MAPPING_REQUIRED = {"id":"medical_record_field.merge.mapping_required","translations":{"validators":{"fr":"Veuillez saisir le mapping des choix","en":"Please enter choice mapping"}}};
export const MEDICAL_RECORD_DATA_FIELD_IS_ARCHIVED = {"id":"medical_record_data.field.is_archived","translations":{"validators":{"fr":"Le champ est archiv\u00e9","en":"Field is archived"}}};
export const MEDICAL_RECORD_DATA_FIELD_IS_READ_ONLY = {"id":"medical_record_data.field.is_read_only","translations":{"validators":{"fr":"Le champ ne peut pas \u00eatre modifi\u00e9","en":"Field cannot be modified"}}};
export const MEDICAL_RECORD_DATA_FIELD_NOT_FOUND = {"id":"medical_record_data.field.not_found","translations":{"validators":{"fr":"Le champ n'a pas \u00e9t\u00e9 trouv\u00e9","en":"Field was not found"}}};
export const MEDICAL_RECORD_DATA_VALUE_REQUIRED = {"id":"medical_record_data.value.required","translations":{"validators":{"fr":"Veuillez saisir une valeur","en":"Please enter value"}}};
export const MEDICAL_RECORD_DATA_VALUE_NOT_NULL = {"id":"medical_record_data.value.not_null","translations":{"validators":{"fr":"Veuillez saisir une valeur","en":"Please enter value"}}};
export const MEDICAL_RECORD_DATA_VALUE_IS_NOT_BOOLEAN = {"id":"medical_record_data.value.is_not_boolean","translations":{"validators":{"fr":"La valeur doit \u00eatre de type bool\u00e9en","en":"Value must be of boolean type"}}};
export const MEDICAL_RECORD_DATA_VALUE_IS_NOT_NUMBER = {"id":"medical_record_data.value.is_not_number","translations":{"validators":{"fr":"La valeur doit \u00eatre un nombre","en":"Value must be number"}}};
export const MEDICAL_RECORD_DATA_VALUE_IS_NOT_DATE = {"id":"medical_record_data.value.is_not_date","translations":{"validators":{"fr":"La valeur doit \u00eatre une date","en":"Value must be date"}}};
export const MEDICAL_RECORD_DATA_VALUE_IS_NOT_ALLOWED_CHOICE = {"id":"medical_record_data.value.is_not_allowed_choice","translations":{"validators":{"fr":"La valeur n'est pas un choix autoris\u00e9","en":"Value is not an allowed choice"}}};
export const MEDICAL_RECORD_DATA_VALUE_MIN = {"id":"medical_record_data.value.min","translations":{"validators":{"fr":"La valeur doit \u00eatre sup\u00e9rieure ou \u00e9gale \u00e0 {{ min }}","en":"Value must be greater than or equal to {{ min }}"}}};
export const MEDICAL_RECORD_DATA_VALUE_MAX = {"id":"medical_record_data.value.max","translations":{"validators":{"fr":"La valeur doit \u00eatre inf\u00e9rieure ou \u00e9gale \u00e0 {{ max }}","en":"Value must be less than or equal to {{ max }}"}}};
export const NAMED_ENTITY_NAME_NOT_BLANK = {"id":"named_entity.name.not_blank","translations":{"validators":{"fr":"Veuillez entrer un nom valide","en":"Please enter a valid name"}}};
export const NAMED_ENTITY_CANONICAL_NOT_BLANK = {"id":"named_entity.canonical.not_blank","translations":{"validators":{"fr":"Veuillez entrer un slug valide","en":"Please enter a valid slug"}}};
export const NAMED_ENTITY_CANONICAL_UNIQUE = {"id":"named_entity.canonical.unique","translations":{"validators":{"fr":"Ce slug est d\u00e9j\u00e0 utilis\u00e9","en":"This slug is already in use"}}};
export const MEDICAL_RECORD_SECTION_COLOR_NOT_BLANK = {"id":"medical_record_section.color.not_blank","translations":{"validators":{"fr":"Veuillez saisir une couleur","en":"Please enter color"}}};
export const MEDICAL_RECORD_SECTION_COLOR_INVALID = {"id":"medical_record_section.color.invalid","translations":{"validators":{"fr":"Veuillez saisir une couleur valide","en":"Please enter a valid color"}}};
export const MEDICAL_RECORD_SECTION_ICON_NOT_BLANK = {"id":"medical_record_section.icon.not_blank","translations":{"validators":{"fr":"Veuillez saisir une icone","en":"Please enter icon"}}};
export const MEDICAL_RECORD_SECTION_SECTION_TYPE_NOT_BLANK = {"id":"medical_record_section.section_type.not_blank","translations":{"validators":{"fr":"Veuillez saisir un type de section","en":"Please enter section type"}}};
export const MEDICAL_RECORD_SECTION_SECTION_TYPE_INVALID = {"id":"medical_record_section.section_type.invalid","translations":{"validators":{"fr":"Veuillez saisir un type de section valide","en":"Please enter a valid section type"}}};
export const MEDICAL_RECORD_SECTION_TAB_NOT_BLANK = {"id":"medical_record_section.tab.not_blank","translations":{"validators":{"fr":"Veuillez saisir une tab","en":"Please enter tab"}}};
export const MEDICAL_RECORD_SECTION_SIZE_NOT_IN_RANGE = {"id":"medical_record_section.size.notInRange","translations":{"validators":{"fr":"Veuillez saisir un nombre entre {{ min }} et {{ max }}","en":"Please enter a number between {{ min }} and {{ max }}"}}};
export const MEDICAL_RECORD_SECTION_POSITION_NOT_IN_RANGE = {"id":"medical_record_section.position.notInRange","translations":{"validators":{"fr":"Veuillez saisir un nombre entre {{ min }} et {{ max }}","en":"Please enter a number between {{ min }} and {{ max }}"}}};
export const FORMULA_HANDLER_EVAL_FORMULA_INVALID = {"id":"formula_handler.eval.formula_invalid","translations":{"validators":{"fr":"La formule pass\u00e9e est invalide","en":"The provided formula is invalid"}}};
export const MEDICAL_TEAM_ERROR_NOT_MEDIC_OR_SELF = {"id":"medical_team.error.not_medic_or_self","translations":{"validators":{"fr":"Param\u00e8tre invalide. Non m\u00e9decin ou vous-m\u00eame.","en":"Invalid parameter. Not doctor or yourself."}}};
export const MEDICAL_TEAM_ERROR_NOT_MEMBER = {"id":"medical_team.error.not_member","translations":{"validators":{"fr":"Utilisateur non membre de l'\u00e9quipe medical du m\u00e9decin.","en":"User is not member of the doctor's care team."}}};
export const UPLOADABLE_NOT_OWNER = {"id":"uploadable.not_owner","translations":{"validators":{"fr":"Ce fichier ne vous appartient pas.","en":"This file does not belong to you."}}};
export const OPENAI_PROMPT_MISSING_TEXT = {"id":"openai.prompt.missing_text","translations":{"validators":{"fr":"Votre prompt doit contenir un {text}","en":"Your prompt must contain a {text}"}}};
export const OPENAI_TEXT_MISSING = {"id":"openai.text.missing","translations":{"validators":{"fr":"Veuillez saisir un texte \u00e0 parser ou uploader une vid\u00e9o","en":"Please enter text to parse or upload video"}}};
export const IP_ADDRESS_LOGIN_TOO_MANY_ATTEMPTS = {"id":"ip_address.login.too_many_attempts","translations":{"validators":{"fr":"Vous avez atteint le nombre maximum de tentatives de connexion. Veuillez r\u00e9essayer dans {{ time }} minutes.","en":"You have reached the maximum number of login attempts. Please try again in {{ time }} minutes."}}};
export const CERFA_TEMPLATE_CERFA_NOT_NULL = {"id":"cerfa_template.cerfa.not_null","translations":{"validators":{"fr":"Veuillez saisir un Cerfa","en":"Please enter Cerfa"}}};
export const CERFA_TEMPLATE_NAME_NOT_BLANK = {"id":"cerfa_template.name.not_blank","translations":{"validators":{"fr":"Veuillez saisir un nom","en":"Please enter name"}}};
export const CERFA_TEMPLATE_DELETE_ALREADY_USED = {"id":"cerfa_template.delete.already_used","translations":{"validators":{"fr":"Ce mod\u00e8le est utilis\u00e9 dans un ou plusieurs document et ne peut donc pas \u00eatre supprim\u00e9","en":"This template is used in one or more documents and cannot be deleted"}}};
export const PSC_PROFILE_ACCOUNT_ALREADY_EXISTS = {"id":"psc.profile.account_already_exists","translations":{"validators":{"fr":"Vous disposez d\u00e9j\u00e0 d\u2019un compte sur Instamed, veuillez vous connecter.","en":"You already have account on Instamed, please log in."}}};
export const PSC_PROFILE_ONLY_DOCTORS_ALLOWED = {"id":"psc.profile.only_doctors_allowed","translations":{"validators":{"fr":"Seuls les m\u00e9decins sont autoris\u00e9s \u00e0 acc\u00e9der \u00e0 cette application via Pro Sant\u00e9 Connect.","en":"Only doctors are authorized to access this application by Pro Sant\u00e9 Connect."}}};
export const PSC_PROFILE_NOT_FOUND = {"id":"psc.profile.not_found","translations":{"validators":{"fr":"Une erreur est survenue lors de la r\u00e9cup\u00e9ration de vos informations sur Pro Sant\u00e9 Connect. Veuillez r\u00e9essayer","en":"An error occurred while retrieving your information from Pro Sant\u00e9 Connect. Please try again"}}};
export const PSC_PROFILE_LINK_ACCOUNT_REQUIRED = {"id":"psc.profile.link_account_required","translations":{"validators":{"fr":"Veuillez lier votre compte Instamed avec votre compte Pro Sant\u00e9 Connect.","en":"Please link your Instamed account with your Pro Sant\u00e9 Connect account."}}};
export const PSC_PROFILE_USER_NOT_LINKED = {"id":"psc.profile.user_not_linked","translations":{"validators":{"fr":"Votre compte n'est pas li\u00e9 \u00e0 Pro Sant\u00e9 Connect.","en":"Your account is not linked to Pro Sant\u00e9 Connect."}}};
export const PSC_PROFILE_CONTEXT_INVALID = {"id":"psc.profile.context_invalid","translations":{"validators":{"fr":"Le contexte pass\u00e9 est invalide.","en":"The provided context is invalid."}}};
export const PSC_USER_RPPS_NOT_FOUND = {"id":"psc.user.rpps_not_found","translations":{"validators":{"fr":"Aucun compte n'a \u00e9t\u00e9 trouv\u00e9 li\u00e9 \u00e0 cet identifiant. Veuillez cr\u00e9er un compte.","en":"No account found linked to this ID. Please create an account."}}};
export const PSC_ERROR_REFRESH_TOKEN_EXPIRED = {"id":"psc.error.refresh_token_expired","translations":{"validators":{"fr":"La connexion avec Pro Sant\u00e9 Connect a expir\u00e9, veuillez vous reconnecter","en":"Connection with Pro Sant\u00e9 Connect expired, please reconnect"}}};
export const PSC_ERROR_WRONG_CALLBACK_ACTION = {"id":"psc.error.wrong_callback_action","translations":{"validators":{"fr":"Action inconnue action","en":"Unknown action"},"pro_sante_connect":{"fr":"Action inconnue action","en":"Unknown action"}}};
export const PSC_ERROR_NO_ACCESS_TOKEN = {"id":"psc.error.no_access_token","translations":{"validators":{"fr":"Erreur lors de l'obtention de l' access_token via Pro Sant\u00e9 Connect.","en":"Error obtaining access_token from Pro Sant\u00e9 Connect."},"pro_sante_connect":{"fr":"Erreur lors de l'obtention de l' access_token via Pro Sant\u00e9 Connect.","en":"Error obtaining access_token by Pro Sant\u00e9 Connect."}}};
export const PSC_ERROR_RETRIEVE_RPPS_ERROR = {"id":"psc.error.retrieve_rpps_error","translations":{"validators":{"fr":"Impossible de r\u00e9cup\u00e9rer le num\u00e9ro RPPS \u00e0 partir des informations de Pro Sant\u00e9 Connect.","en":"Unable to retrieve RPPS number from Pro Sant\u00e9 Connect information."},"pro_sante_connect":{"fr":"Impossible de r\u00e9cup\u00e9rer le num\u00e9ro RPPS \u00e0 partir des informations de Pro Sant\u00e9 Connect.","en":"Unable to retrieve the RPPS number from Pro Sant\u00e9 Connect information."}}};
export const PSC_ERROR_LINK_ACCOUNT_REQUIRED = {"id":"psc.error.link_account_required","translations":{"validators":{"fr":"Veuillez lier votre compte Instamed avec votre compte Pro Sant\u00e9 Connect.","en":"Please link your Instamed account with your Pro Sant\u00e9 Connect account."}}};
export const PSC_ERROR_USER_NOT_LINKED = {"id":"psc.error.user_not_linked","translations":{"validators":{"fr":"Le compte de l'utilisateur n'est pas li\u00e9 \u00e0 Pro Sant\u00e9 Connect.","en":"The user's account is not linked to Pro Sant\u00e9 Connect."}}};
export const PSC_ERROR_MISSING_PSC_ID_TOKEN = {"id":"psc.error.missing_psc_id_token","translations":{"validators":{"fr":"L'utilisateur ne poss\u00e8de pas de jeton d'identification Pro Sant\u00e9 Connect.","en":"The user does not have a Pro Sant\u00e9 Connect identification token."},"pro_sante_connect":{"fr":"L'utilisateur ne poss\u00e8de pas de jeton d'identification Pro Sant\u00e9 Connect.","en":"The user does not have a Pro Sant\u00e9 Connect identification token."}}};
export const PSC_ERROR_WRONG_USER = {"id":"psc.error.wrong_user","translations":{"validators":{"fr":"Ce profil ne vous appartient pas.","en":"This profile does not belong to you."},"pro_sante_connect":{"fr":"Ce profil ne vous appartient pas.","en":"This profile does not belong to you."}}};
export const PSC_ERROR_STATE_PARAMETER_MISSING = {"id":"psc.error.state_parameter_missing","translations":{"validators":{"fr":"Le param\u00e8tre state est manquant.","en":"The state parameter is missing."},"pro_sante_connect":{"fr":"Le param\u00e8tre state est manquant.","en":"The state parameter is missing."}}};
export const PSC_ERROR_CODE_PARAMETER_MISSING = {"id":"psc.error.code_parameter_missing","translations":{"validators":{"fr":"Le param\u00e8tre code est manquant.","en":"The code parameter is missing."},"pro_sante_connect":{"fr":"Le param\u00e8tre code est manquant.","en":"The code parameter is missing."}}};
export const PATIENT_SYNOPSIS_CONTENT_NOT_BLANK = {"id":"patient_synopsis.content.not_blank","translations":{"validators":{"fr":"Veuillez saisir un contenu","en":"Please enter content"}}};
export const PATIENT_SYNOPSIS_PATIENT_NOT_NULL = {"id":"patient_synopsis.patient.not_null","translations":{"validators":{"fr":"Veuillez lier ce synopsis a un m\u00e9decin","en":"Please link this synopsis to doctor"}}};
export const EXPORT_VARIABLES_NOT_EMPTY = {"id":"export.variables.not_empty","translations":{"validators":{"fr":"Veuillez s\u00e9lectionner au moins une variable a exporter","en":"Please select at least one variable to export"}}};
export const ARCHIVABLE_INTERFACE_STATUS_INVALID = {"id":"archivable_interface.status.invalid","translations":{"validators":{"fr":"Ce status n'est pas autoris\u00e9 pour cette entit\u00e9.","en":"This status is not allowed for this entity."}}};
export const PUBLIC_PROFILE_NOT_MEDIC = {"id":"public_profile.not_medic","translations":{"validators":{"fr":"Vous devez \u00eatre un m\u00e9decin pour cr\u00e9er un profil public.","en":"You must be doctor to create a public profile."}}};
export const PUBLIC_PROFILE_ALREADY_HAS_PUBLIC_PROFILE = {"id":"public_profile.already_has_public_profile","translations":{"validators":{"fr":"Vous avez d\u00e9j\u00e0 un profil public.","en":"You already have a public profile."}}};
export const PUBLIC_PROFILE_ALREADY_DISABLED = {"id":"public_profile.already_disabled","translations":{"validators":{"fr":"Une demande de d\u00e9sactivation est d\u00e9j\u00e0 en cours pour votre profil","en":"You've already asked to disable your profile"}}};
export const PUBLIC_PROFILE_REFUSES_INDEX = {"id":"public_profile.refuses_index","translations":{"validators":{"fr":"Cet utilisateur ne souhaite pas \u00eatre index\u00e9 sur Instamed","en":"This doctor does not want to be on Instamed"}}};
export const PUBLIC_PROFILE_CONVENTION_SECTOR_INVALID = {"id":"public_profile.convention_sector_invalid","translations":{"validators":{"fr":"Le secteur de convention sp\u00e9cifi\u00e9 n'est pas valide","en":"Specified convention sector is not valid"}}};
export const PUBLIC_PROFILE_DOCTOLIB_INVALID_URL = {"id":"public_profile.doctolib.invalid_url","translations":{"validators":{"fr":"Veuillez saisir une url valide","en":"Please enter valid URL"}}};
export const PUBLIC_PROFILE_DOCTOLIB_NOT_SUPPORTED_URL = {"id":"public_profile.doctolib.not_supported_url","translations":{"validators":{"fr":"Le profil public que vous avez saisit n'est pas encore support\u00e9 pour l'import automatique","en":"The public profile you entered is not yet supported for automatic import"}}};
export const PUBLIC_PROFILE_CANONICAL_UNIQUE = {"id":"public_profile.canonical.unique","translations":{"validators":{"fr":"Cette url est d\u00e9j\u00e0 utilis\u00e9e par un autre utilisateur","en":"This URL is already in use by another user"}}};
export const PUBLIC_PROFILE_SKILLS_MAX = {"id":"public_profile.skills.max","translations":{"validators":{"fr":"Vous ne pouvez pas d\u00e9finir plus de {{ limit }} expertises sur votre profil","en":"You cannot set more than {{ limit }} specialities on your profile"}}};
export const ACHIEVEMENT_YEAR_WRONG_FORMAT = {"id":"achievement.year.wrong_format","translations":{"validators":{"fr":"L'ann\u00e9e doit \u00eatre au format 4 digits.","en":"Year must be in 4 digits format."}}};
export const ACHIEVEMENT_YEAR_WRONG_RANGE = {"id":"achievement.year.wrong_range","translations":{"validators":{"fr":"L'ann\u00e9e '{{ value }}' n'est pas dans la plage valide ({{ min }} - {{ max }}).","en":"Year '{{ value }}' is not within the valid range ({{ min }} - {{ max }})."}}};
export const MEDIC_RATE_MIN_AMOUNT_GREATER_THAN_OR_EQUAL = {"id":"medic_rate.min_amount.greater_than_or_equal","translations":{"validators":{"fr":"Ce montant doit \u00eatre sup\u00e9rieur ou \u00e9gal \u00e0 0","en":"This amount must be greater than or equal to 0."}}};
export const MEDIC_RATE_MAX_AMOUNT_GREATER_THAN = {"id":"medic_rate.max_amount.greater_than","translations":{"validators":{"fr":"Ce montant doit \u00eatre sup\u00e9rieur \u00e0 0","en":"This amount must be greater than 0."}}};
export const INVALID_BRACKETS_TOO_MUCH_CLOSE = {"id":"invalid_brackets.too_much_close","translations":{"validators":{"fr":"Les crochets ne sont pas correctement ouverts, v\u00e9rifiez que vous ayez le m\u00eame nombre de crochets ouvert '[[' que de crochets ferm\u00e9s ']]'","en":"Brackets are not correctly opened. Make sure you have the same number of opening brackets '[[' as closing brackets ']]'."}}};
export const INVALID_BRACKETS_TOO_MUCH_OPEN = {"id":"invalid_brackets.too_much_open","translations":{"validators":{"fr":"Les crochets ne sont pas correctement ferm\u00e9s, v\u00e9rifiez que vous ayez le m\u00eame nombre de crochets ouvert '[[' que de crochets ferm\u00e9s ']]'","en":"Brackets are not correctly closed. Make sure you have the same number of opening brackets '[[' as closing brackets ']]'."}}};
export const CONSULTATION_LOCATION_NOT_FOUND = {"id":"consultation_location.not_found","translations":{"validators":{"fr":"Le lieu de consultation n'a pas \u00e9t\u00e9 trouv\u00e9","en":"The location of the consultation was not found"}}};
export const CONSULTATION_LOCATION_OWNER_EMPTY = {"id":"consultation_location.owner_empty","translations":{"validators":{"fr":"Vous devez sp\u00e9cifier un m\u00e9decin comme propri\u00e9taire de ce lieu","en":"You must specify a doctor as the owner of this location."}}};
export const CONSULTATION_LOCATION_WRONG_OWNER = {"id":"consultation_location.wrong_owner","translations":{"validators":{"fr":"Le lieu de consultation n'appartient pas au propri\u00e9taire","en":"The consultation location does not belong to the owner."}}};
export const CONSULTATION_LOCATION_OWNER_TYPE_EMPTY = {"id":"consultation_location.owner_type.empty","translations":{"validators":{"fr":"Vous devez sp\u00e9cifier un type de propri\u00e9taire pour ce lieu","en":"You must specify an owner type for this location."}}};
export const CONSULTATION_LOCATION_ADDRESS_NOT_NULL = {"id":"consultation_location.address.not_null","translations":{"validators":{"fr":"Veuillez saisir une adresse","en":"Please enter address"}}};
export const FEATURE_FLAG_ERROR_CANONICAL_NOT_FOUND = {"id":"feature_flag.error.canonical_not_found","translations":{"validators":{"fr":"La fonctionnalit\u00e9 sp\u00e9cifi\u00e9e n'a pas \u00e9t\u00e9 trouv\u00e9e","en":"Specified feature was not found."}}};
export const FEATURE_FLAG_ERROR_FEATURE_NOT_ENABLED = {"id":"feature_flag.error.feature_not_enabled","translations":{"validators":{"fr":"La fonctionnalit\u00e9 n'est pas activ\u00e9e","en":"Feature is not enabled."}}};
export const FEATURE_FLAG_ERROR_ENABLE_NOT_BOOLEAN = {"id":"feature_flag.error.enable_not_boolean","translations":{"validators":{"fr":"enable n'est pas un boolean","en":"Enable is not boolean."}}};
export const FEATURE_FLAG_ERROR_FEATURE_UNMANAGEABLE = {"id":"feature_flag.error.feature_unmanageable","translations":{"validators":{"fr":"La fonctionnalit\u00e9 n'est pas modifiable par un utilisateur","en":"The feature is not manageable by user."}}};
export const FEATURE_FLAG_ERROR_NO_RULES_OR_WRONG_ENVIRONMENT = {"id":"feature_flag.error.no_rules_or_wrong_environment","translations":{"validators":{"fr":"Aucune cl\u00e9 rules d\u00e9finie ou mauvais environnement sp\u00e9cifi\u00e9","en":"No rules key defined or wrong environment specified."}}};
export const AVAILABILITY_DURATION_GREATER_THAN = {"id":"availability.duration.greater_than","translations":{"validators":{"fr":"Veuillez saisir une dur\u00e9e sup\u00e9rieure \u00e0 0 minutes.","en":"Please enter a duration greater than 0 minutes."}}};
export const AVAILABILITY_CONSULTATION_LOCATION_NOT_NULL = {"id":"availability.consultation_location.not_null","translations":{"validators":{"fr":"Veuillez saisir un lieu de consultation","en":"Please enter location of consultation"}}};
export const MSSANTE_EMAIL_SUBJECT_NOT_BLANK = {"id":"mssante_email.subject.not_blank","translations":{"validators":{"fr":"Veuillez saisir un objet","en":"Please enter subject."}}};
export const MSSANTE_EMAIL_TO_NOT_BLANK = {"id":"mssante_email.to.not_blank","translations":{"validators":{"fr":"Veuillez saisir un destinataire","en":"Please enter recipient."}}};
export const MSSANTE_EMAIL_TO_INVALID_EMAIL = {"id":"mssante_email.to.invalid_email","translations":{"validators":{"fr":"Veuillez saisir un email valide","en":"Please enter a valid email address."}}};
export const MSSANTE_EMAIL_BODY_NOT_BLANK = {"id":"mssante_email.body.not_blank","translations":{"validators":{"fr":"Veuillez saisir un message","en":"Please enter message."}}};
export const MSSANTE_EMAIL_ATTACHMENT_FILE_NOT_FOUND = {"id":"mssante_email.attachment.file_not_found","translations":{"validators":{"fr":"Le fichier n'existe pas ou plus","en":"File does not exist or no longer exists."}}};
export const MSSANTE_EMAIL_ACCESS_DENIED = {"id":"mssante_email.access_denied","translations":{"validators":{"fr":"Vous ne pouvez pas lire cet e-mail","en":"You cannot read this email."}}};
export const MSSANTE_EMAIL_NOT_FOUND = {"id":"mssante_email.not_found","translations":{"validators":{"fr":"L'e-mail n'a pas \u00e9t\u00e9 trouv\u00e9"}}};
export const MSSANTE_EMAIL_DELETE_ERROR = {"id":"mssante_email.delete_error","translations":{"validators":{"fr":"Vous ne pouvez pas supprimer un e-mail d\u00e9j\u00e0 envoy\u00e9"}}};
export const MSSANTE_EMAIL_ALREADY_SENT = {"id":"mssante_email.already_sent","translations":{"validators":{"fr":"Cet e-mail a d\u00e9j\u00e0 \u00e9t\u00e9 envoy\u00e9"}}};
export const MSSANTE_EMAIL_ERROR_REFRESH = {"id":"mssante_email.error_refresh","translations":{"validators":{"fr":"Une erreur est survenue lors de la r\u00e9cup\u00e9ration de vos derniers e-mails, veuillez r\u00e9essayer ult\u00e9rieurement.","en":"An error occurred while retrieving your latest emails, please try again later."}}};
export const MSSANTE_EMAIL_SEND_ERROR = {"id":"mssante_email.send_error","translations":{"validators":{"fr":"Une erreur est survenue lors de l'envoi de votre e-mail, veuillez r\u00e9essayer ult\u00e9rieurement","en":"An error occurred while sending your email, please try again later."}}};
export const MSSANTE_EMAIL_JWT_EXPIRED = {"id":"mssante_email.jwt_expired","translations":{"validators":{"fr":"Votre session a expir\u00e9e, veuillez vous reconnecter","en":"Your session has expired. Please log in again."}}};
export const MSSANTE_EMAIL_TOO_MANY_RECONNECT = {"id":"mssante_email.too_many_reconnect","translations":{"validators":{"fr":"L'envoi de l'e-mail via MSSant\u00e9 \u00e0 \u00e9chou\u00e9 plusieurs fois, veuillez r\u00e9essayer ult\u00e9rieurement.","en":"Sending the email via MSSant\u00e9 failed several times, please try again later."}}};
export const MSSANTE_EMAIL_LOGIN_ERROR = {"id":"mssante_email.login_error","translations":{"validators":{"fr":"La connexion \u00e0 MSSant\u00e9 n'a pas pu \u00eatre \u00e9tablie, veuillez v\u00e9rifier votre adresse et r\u00e9essayer. Si l'adresse est correcte, le probl\u00e8me provient de votre op\u00e9rateur MSSant\u00e9, veuillez r\u00e9essayer ult\u00e9rieurement ou bien le contacter pour plus d'informations."}}};
export const MSSANTE_EMAIL_ACCESS_DENIED_EMAIL = {"id":"mssante_email.access_denied_email","translations":{"validators":{"fr":"Vous n'avez pas acc\u00e8s \u00e0 cette adresse e-mail MSSant\u00e9","en":"You have no access to this MSSante's email address"}}};
export const MSSANTE_EMAIL_DOMAIN_NOT_CONFIGURED = {"id":"mssante_email.domain_not_configured","translations":{"validators":{"fr":"Votre \u00e9diteur de boite aux lettres MSSant\u00e9 n'autorise pas encore la connexion \u00e0 des services ext\u00e9rieurs. Si vous le souhaitez vous pouvez contacter leur support et leur demander de se mettre \u00e0 jour avec la r\u00e9glementation et l'API LPS MSSant\u00e9, requise pour tout logiciel S\u00e9gur.","en":"Your MSSante mailbox editor does not yet support connection to external services. If desired, you can contact their support team and request that they update to comply with the regulations and the MSSante LPS API, required for all Segur-compliant software."}}};
export const MSSANTE_DOCUMENT_PATIENT_NOT_NULL = {"id":"mssante_document.patient.not_null","translations":{"validators":{"fr":"Veuillez choisir un patient","en":"Please select patient."}}};
export const MSSANTE_DOCUMENT_MESSAGE_NOT_NULL = {"id":"mssante_document.message.not_null","translations":{"validators":{"fr":"Veuillez s\u00e9lectionner le message","en":"Please select message."}}};
export const MSSANTE_DOCUMENT_ATTACHMENT_NOT_NULL = {"id":"mssante_document.attachment.not_null","translations":{"validators":{"fr":"Veuillez s\u00e9lectionner la pi\u00e8ce-jointe","en":"Please select attachment."}}};
export const MSSANTE_DOCUMENT_ATTACHMENT_NOT_FOUND = {"id":"mssante_document.attachment.not_found","translations":{"validators":{"fr":"La pi\u00e8ce-jointe n'existe pas ou plus","en":"Attachment does not exist or no longer exists."}}};
export const MSSANTE_DOCUMENT_TYPE_NOT_NULL = {"id":"mssante_document.type.not_null","translations":{"validators":{"fr":"Le type de document ne peut pas \u00eatre vide.","en":"Document type cannot be empty."}}};
export const MSSANTE_DOCUMENT_TITLE_NOT_NULL = {"id":"mssante_document.title.not_null","translations":{"validators":{"fr":"Le type de document ne peut pas \u00eatre vide.","en":"Document title cannot be empty."}}};
export const MSSANTE_DOCUMENT_VISIBILITY_NOT_NULL = {"id":"mssante_document.visibility.not_null","translations":{"validators":{"fr":"La visibilit\u00e9 du document ne peut pas \u00eatre vide.","en":"Document visibility cannot be empty."}}};
export const MS_SANTE_MODULE_NOT_ENABLED = {"id":"ms_sante.module.not_enabled","translations":{"validators":{"fr":"Le module MSSant\u00e9 n'est pas encore activ\u00e9 pour votre compte.","en":"The MSSant\u00e9 module is not yet enabled for your account."}}};
export const MS_SANTE_MODULE_ALREADY_ENABLED = {"id":"ms_sante.module.already_enabled","translations":{"validators":{"fr":"Votre compte MSSant\u00e9 a d\u00e9j\u00e0 \u00e9t\u00e9 cr\u00e9\u00e9","en":"Your MSSant\u00e9 account has already been created."}}};
export const MS_SANTE_SENT_MESSAGE_IMPOSSIBLE_TO_SORT = {"id":"ms_sante.sent_message.impossible_to_sort","translations":{"validators":{"fr":"Vous ne pouvez pas classer les documents que vous avez envoy\u00e9","en":"You cannot sort documents you have sent."}}};
export const MS_SANTE_DOCUMENT_ALREADY_ATTACHED = {"id":"ms_sante.document.already_attached","translations":{"validators":{"fr":"Cette pi\u00e8ce-jointe est d\u00e9j\u00e0 li\u00e9e \u00e0 un document","en":"This attachment is already linked to a document."}}};
export const MOBILE_APP_VERSION_EXISTING_VERSION = {"id":"mobile_app_version.existing_version","translations":{"validators":{"fr":"La version que vous souhaitez cr\u00e9er existe d\u00e9j\u00e0","en":"Version you wish to create already exists."}}};
export const PRESCRIPTION_ASSISTANCE_SOFTWARE_SUBSCRIPTION_INVALID_CORE_PLAN = {"id":"prescription_assistance_software.subscription.invalid_core_plan","translations":{"validators":{"fr":"L'aide \u00e0 la prescription est uniquement disponible avec l'abonnement premium.","en":"Prescription assistance is only available with premium subscription."}}};
export const PRESCRIPTION_ASSISTANCE_SOFTWARE_SUBSCRIPTION_EXISTING = {"id":"prescription_assistance_software.subscription.existing","translations":{"validators":{"fr":"Vous disposez d\u00e9j\u00e0 d'un abonnement pour l'aide \u00e0 la prescription. Si vous pensez qu'il s'agit d'une erreur, veuillez contacter l'\u00e9quipe \u00e0 l'adresse contact@instamed.fr","en":"You already have subscription for prescription assistance. If you believe this is an error, please contact our team at contact@instamed.fr."}}};
export const PRESCRIPTION_ASSISTANCE_SOFTWARE_SUBSCRIPTION_PRICE_INVALID = {"id":"prescription_assistance_software.subscription.price_invalid","translations":{"validators":{"fr":"Ce prix n'est pas li\u00e9 \u00e0 un abonnement de l'aide \u00e0 la prescription","en":"This price is not linked to prescription assistance subscription."}}};
export const PRESCRIPTION_ASSISTANCE_SOFTWARE_SUBSCRIPTION_NOT_ENABLED = {"id":"prescription_assistance_software.subscription.not_enabled","translations":{"validators":{"fr":"Vous devez souscrire \u00e0 l'aide \u00e0 la prescription pour pouvoir utiliser ce mod\u00e8le","en":"You must subscribe to prescription assistance in order to use this template."}}};
export const PRESCRIPTION_ASSISTANCE_SOFTWARE_SUBSCRIPTION_NOT_FOUND = {"id":"prescription_assistance_software.subscription.not_found","translations":{"validators":{"fr":"L'abonnement demand\u00e9 pour l'aide \u00e0 la prescription est introuvable. Veuillez v\u00e9rifier les informations saisies ou contacter l'\u00e9quipe \u00e0 l'adresse contact@instamed.fr.","en":"The subscription requested for prescription assistance cannot be found. Please check information entered or contact team at contact@instamed.fr."}}};
export const PRESCRIPTION_ASSISTANCE_SOFTWARE_UNSUBSCRIPTION_NOT_ENABLED = {"id":"prescription_assistance_software.unsubscription.not_enabled","translations":{"validators":{"fr":"L'aide \u00e0 la prescription n'est pas activ\u00e9e pour votre compte.","en":"Prescription assistance is not activated for your account."}}};
export const SPECIALTY_OWNER_NOT_SPECIALIST = {"id":"specialty.owner.not_specialist","translations":{"validators":{"fr":"Cette sp\u00e9cialit\u00e9 '{{ specialty }}' n'appartient pas \u00e0 l'utilisateur.","en":"This specialty '{{ specialty }}' does not belong to the user."}}};
export const IDENTITY_DOCUMENT_DELIVERY_DATE_NOT_IN_FUTURE = {"id":"identity_document.delivery_date.not_in_future","translations":{"validators":{"fr":"La date de d\u00e9livrance ne peut pas \u00eatre dans le futur.","en":"Delivery date cannot be in the future."}}};
export const IDENTITY_DOCUMENT_DELIVERY_DATE_NOT_BLANK = {"id":"identity_document.delivery_date.not_blank","translations":{"validators":{"fr":"Veuillez saisir une date de d\u00e9livrance","en":"Please enter delivery date."}}};
export const IDENTITY_DOCUMENT_EXPIRATION_DATE_NOT_IN_PAST = {"id":"identity_document.expiration_date.not_in_past","translations":{"validators":{"fr":"La date d'expiration ne peut pas \u00eatre dans le pass\u00e9.","en":"The expiration date cannot be in the past."}}};
export const IDENTITY_DOCUMENT_EXPIRATION_DATE_AFTER_DELIVERY_DATE = {"id":"identity_document.expiration_date.after_delivery_date","translations":{"validators":{"fr":"La date d'expiration doit \u00eatre post\u00e9rieure \u00e0 la date de d\u00e9livrance.","en":"The expiration date must be after the delivery date."}}};
export const IDENTITY_DOCUMENT_EXPIRATION_DATE_NOT_BLANK = {"id":"identity_document.expiration_date.not_blank","translations":{"validators":{"fr":"Veuillez saisir une date d'expiration","en":"Please enter expiration date."}}};
export const IDENTITY_DOCUMENT_COUNTRY_NOT_ISO_CODE = {"id":"identity_document.country.not_iso_code","translations":{"validators":{"fr":"Veuillez saisir un pays valide","en":"Please enter valid country."}}};
export const IDENTITY_DOCUMENT_FRONT_NOT_BLANK = {"id":"identity_document.front.not_blank","translations":{"validators":{"fr":"Veuillez uploader le recto de votre document d'identit\u00e9.","en":"Please upload the front of your identity document."}}};
export const IDENTITY_DOCUMENT_BACK_NOT_BLANK = {"id":"identity_document.back.not_blank","translations":{"validators":{"fr":"Veuillez uploader le verso de votre document d'identit\u00e9.","en":"Please upload the back of your identity document."}}};
export const ONLINE_APPOINTMENTS_SUBSCRIPTION_PAYMENT_REQUIRED = {"id":"online_appointments.subscription.payment_required","translations":{"validators":{"fr":"Veuillez choisir un abonnement pour la prise de rendez-vous","en":"Please select subscription for the appointment bookings"}}};
export const ONLINE_APPOINTMENTS_SUBSCRIPTION_INVALID_CORE_PLAN = {"id":"online_appointments.subscription.invalid_core_plan","translations":{"validators":{"fr":"La prise de rendez-vous est uniquement disponible avec l'abonnement premium.","en":"Online appointments are only available with the premium subscription."}}};
export const ONLINE_APPOINTMENTS_SUBSCRIPTION_EXISTING = {"id":"online_appointments.subscription.existing","translations":{"validators":{"fr":"Vous disposez d\u00e9j\u00e0 d'un abonnement pour la prise de rendez-vous. Si vous pensez qu'il s'agit d'une erreur, veuillez contacter l'\u00e9quipe \u00e0 l'adresse contact@instamed.fr","en":"You already have subscription for online appointments. If you believe this is an error, please contact our team at contact@instamed.fr."}}};
export const ONLINE_APPOINTMENTS_SUBSCRIPTION_PRICE_INVALID = {"id":"online_appointments.subscription.price_invalid","translations":{"validators":{"fr":"Ce prix n'est pas li\u00e9 \u00e0 un abonnement de la prise de rendez-vous","en":"This price is not linked to an online appointment subscription."}}};
export const LIA_SUBSCRIPTION_INVALID_CORE_PLAN = {"id":"lia.subscription.invalid_core_plan","translations":{"validators":{"fr":"Lia est uniquement disponible avec l'abonnement premium.","en":"Lia is only available with our Premium plan."}}};
export const LIA_SUBSCRIPTION_PRICE_INVALID = {"id":"lia.subscription.price_invalid","translations":{"validators":{"fr":"Ce prix n'est pas li\u00e9 \u00e0 une offre Lia.","en":"This price is not linked to one of our Lia offers."}}};
export const LIA_SUBSCRIPTION_INVALID_CREDIT_COUNT = {"id":"lia.subscription.invalid_credit_count","translations":{"validators":{"fr":"Le nombre de cr\u00e9dits est invalide.","en":"Number of credits is invalid."}}};
export const LIA_SUBSCRIPTION_STRIPE_ERROR = {"id":"lia.subscription.stripe_error","translations":{"validators":{"fr":"Une erreur est survenue lors de votre paiement. Vous n'avez pas \u00e9t\u00e9 d\u00e9bit\u00e9 pour la transaction, veuillez contacter l'administrateur pour plus d'informations.","en":"An error occurred during your payment. You have not been charged for the transaction. Please contact administrator for more information."}}};
export const CREDIT_NOT_ENOUGH = {"id":"credit.not_enough","translations":{"validators":{"fr":"Veuillez racheter des cr\u00e9dits pour effectuer cette action","en":"You must buy more credits to complete this action."}}};
export const SETTINGS_HOLIDAYS_MODE_ENABLED_REQUIRED = {"id":"settings.holidays_mode.enabled_required","translations":{"validators":{"fr":"Veuillez activer ou d\u00e9sactiver le mode vacances","en":"Please enable or disable holiday mode"}}};
export const SETTINGS_HOLIDAYS_MODE_START_REQUIRED = {"id":"settings.holidays_mode.start_required","translations":{"validators":{"fr":"Veuillez saisir une date de d\u00e9but","en":"Please enter start date"}}};
export const SETTINGS_HOLIDAYS_MODE_END_REQUIRED = {"id":"settings.holidays_mode.end_required","translations":{"validators":{"fr":"Veuillez saisir une date de fin","en":"Please enter end date"}}};
export const SETTINGS_HOLIDAYS_MODE_MESSAGE_REQUIRED = {"id":"settings.holidays_mode.message_required","translations":{"validators":{"fr":"Veuillez saisir un message","en":"Type your message"}}};
export const SETTINGS_DOCUMENT_DISPLAY_MODE_INVALID = {"id":"settings.document.display_mode_invalid","translations":{"validators":{"fr":"La valeur \"{{ value }}\" saisie est incorrecte, les valeurs accept\u00e9es sont {{ correct_values }}","en":"Value \"{{ value }}\" is incorrect, accepted values are {{ correct_values }}"}}};
export const SETTINGS_DOCUMENT_INVALID_BOOLEAN = {"id":"settings.document.invalid_boolean","translations":{"validators":{"fr":"La valeur saisie est incorrecte","en":"Entered value was incorrect"}}};
export const SETTINGS_PATIENT_BLOCK_ALL_COMMUNICATION = {"id":"settings.patient.block_all_communication","translations":{"validators":{"fr":"Si l'option 'bloquer la communication' est activ\u00e9e, les autres options doivent \u00eatre d\u00e9sactiv\u00e9s.","en":"If the 'block communication' option is enabled, the other options must be disabled."}}};
export const ONLINE_APPOINTMENT_NOT_ENABLED = {"id":"online_appointment.not_enabled","translations":{"validators":{"fr":"Ce soignant n\u2019a pas activ\u00e9 pas la prise de rendez-vous en ligne.","en":"This caregiver did not activate appointment bookings."}}};
export const ONLINE_APPOINTMENT_PAYMENT_REQUIRED = {"id":"online_appointment.payment_required","translations":{"validators":{"fr":"Pour utiliser cette fonction, veuillez souscrire \u00e0 l'option 'Prise de rendez-vous'","en":"To use this feature, please subscribe to appointment booking plan"}}};
export const SEARCH_ROLE_NOT_SUPPORTED = {"id":"search.role_not_supported","translations":{"validators":{"fr":"Le r\u00f4le sp\u00e9cifi\u00e9 est invalide.","en":"The specified role is invalid."}}};
export const MEDICAL_TEAM_INVITATION_RPPS_ONLY_NEW = {"id":"medical_team_invitation.rpps.only_new","translations":{"validators":{"fr":"Ce m\u00e9decin dispose d\u00e9j\u00e0 d'un compte Instamed","en":"This doctor already has an Instamed account"}}};
export const AN_AUTHENTICATION_EXCEPTION_OCCURRED = {"id":"An authentication exception occurred.","translations":{"security":{"fr":"Une exception d'authentification s'est produite.","en":"An authentication exception occurred."}}};
export const AUTHENTICATION_CREDENTIALS_COULD_NOT_BE_FOUND = {"id":"Authentication credentials could not be found.","translations":{"security":{"fr":"Les identifiants d'authentification n'ont pas pu \u00eatre trouv\u00e9s.","en":"Authentication credentials could not be found."}}};
export const AUTHENTICATION_REQUEST_COULD_NOT_BE_PROCESSED_DUE_TO_A_SYSTEM_PROBLEM = {"id":"Authentication request could not be processed due to a system problem.","translations":{"security":{"fr":"La requ\u00eate d'authentification n'a pas pu \u00eatre execut\u00e9e \u00e0 cause d'un probl\u00e8me syst\u00e8me.","en":"Authentication request could not be processed due to a system problem."}}};
export const INVALID_CREDENTIALS = {"id":"Invalid credentials.","translations":{"security":{"fr":"Identifiants invalides.","en":"Invalid credentials."}}};
export const COOKIE_HAS_ALREADY_BEEN_USED_BY_SOMEONE_ELSE = {"id":"Cookie has already been used by someone else.","translations":{"security":{"fr":"Le cookie a d\u00e9j\u00e0 \u00e9t\u00e9 utilis\u00e9 par quelqu'un d'autre.","en":"Cookie has already been used by someone else."}}};
export const NOT_PRIVILEGED_TO_REQUEST_THE_RESOURCE = {"id":"Not privileged to request the resource.","translations":{"security":{"fr":"Privil\u00e8ges insuffisants pour acc\u00e9der \u00e0 la ressource.","en":"Not privileged to request the resource."}}};
export const INVALID_CSRF_TOKEN = {"id":"Invalid CSRF token.","translations":{"security":{"fr":"Jeton CSRF invalide.","en":"Invalid CSRF token."}}};
export const NO_AUTHENTICATION_PROVIDER_FOUND_TO_SUPPORT_THE_AUTHENTICATION_TOKEN = {"id":"No authentication provider found to support the authentication token.","translations":{"security":{"fr":"Aucun fournisseur d'authentification n'a \u00e9t\u00e9 trouv\u00e9 pour supporter le jeton d'authentification.","en":"No authentication provider found to support the authentication token."}}};
export const NO_SESSION_AVAILABLE_IT_EITHER_TIMED_OUT_OR_COOKIES_ARE_NOT_ENABLED = {"id":"No session available, it either timed out or cookies are not enabled.","translations":{"security":{"fr":"Aucune session disponible, celle-ci a expir\u00e9 ou les cookies ne sont pas activ\u00e9s.","en":"No session available, it either timed out or cookies are not enabled."}}};
export const NO_TOKEN_COULD_BE_FOUND = {"id":"No token could be found.","translations":{"security":{"fr":"Aucun jeton n'a pu \u00eatre trouv\u00e9.","en":"No token could be found."}}};
export const USERNAME_COULD_NOT_BE_FOUND = {"id":"Username could not be found.","translations":{"security":{"fr":"Le nom d'utilisateur n'a pas pu \u00eatre trouv\u00e9.","en":"Username could not be found."}}};
export const ACCOUNT_HAS_EXPIRED = {"id":"Account has expired.","translations":{"security":{"fr":"Le compte a expir\u00e9.","en":"Account has expired."}}};
export const CREDENTIALS_HAVE_EXPIRED = {"id":"Credentials have expired.","translations":{"security":{"fr":"Les identifiants ont expir\u00e9.","en":"Credentials have expired."}}};
export const ACCOUNT_IS_DISABLED = {"id":"Account is disabled.","translations":{"security":{"fr":"Le compte est d\u00e9sactiv\u00e9.","en":"Account is disabled."}}};
export const ACCOUNT_IS_LOCKED = {"id":"Account is locked.","translations":{"security":{"fr":"Le compte est bloqu\u00e9.","en":"Account is locked."}}};
export const TOO_MANY_FAILED_LOGIN_ATTEMPTS_PLEASE_TRY_AGAIN_LATER = {"id":"Too many failed login attempts, please try again later.","translations":{"security":{"fr":"Plusieurs tentatives de connexion ont \u00e9chou\u00e9, veuillez r\u00e9essayer plus tard.","en":"Too many failed login attempts, please try again later."}}};
export const INVALID_OR_EXPIRED_LOGIN_LINK = {"id":"Invalid or expired login link.","translations":{"security":{"fr":"Lien de connexion invalide ou expir\u00e9.","en":"Invalid or expired login link."}}};
export const TOO_MANY_FAILED_LOGIN_ATTEMPTS_PLEASE_TRY_AGAIN_IN_MINUTES_MINUTE = {"id":"Too many failed login attempts, please try again in %minutes% minute.","translations":{"security":{"fr":"Plusieurs tentatives de connexion ont \u00e9chou\u00e9, veuillez r\u00e9essayer dans %minutes% minute.","en":"Too many failed login attempts, please try again in %minutes% minute."}}};
export const TOO_MANY_FAILED_LOGIN_ATTEMPTS_PLEASE_TRY_AGAIN_IN_MINUTES_MINUTES = {"id":"Too many failed login attempts, please try again in %minutes% minutes.","translations":{"security":{"fr":"Trop de tentatives de connexion \u00e9chou\u00e9es, veuillez r\u00e9essayer dans %minutes% minutes.","en":"Too many failed login attempts, please try again in %minutes% minutes."}}};
export const LABEL_PREVIOUS = {"id":"label_previous","translations":{"KnpPaginatorBundle":{"fr":"Pr\u00e9c\u00e9dent","en":"Previous"}}};
export const LABEL_NEXT = {"id":"label_next","translations":{"KnpPaginatorBundle":{"fr":"Suivant","en":"Next"}}};
export const FILTER_SEARCHWORD = {"id":"filter_searchword","translations":{"KnpPaginatorBundle":{"fr":"Recherche...","en":"Searchword..."}}};
export const COUNT_YEAR_COUNT_YEARS = {"id":"%count% year|%count% years","translations":{"ResetPasswordBundle":{"fr":"%count% an|%count% ans","en":"%count% year|%count% years"}}};
export const COUNT_MONTH_COUNT_MONTHS = {"id":"%count% month|%count% months","translations":{"ResetPasswordBundle":{"fr":"%count% mois|%count% mois","en":"%count% month|%count% months"}}};
export const COUNT_DAY_COUNT_DAYS = {"id":"%count% day|%count% days","translations":{"ResetPasswordBundle":{"fr":"%count% jour|%count% jours","en":"%count% day|%count% days"}}};
export const COUNT_HOUR_COUNT_HOURS = {"id":"%count% hour|%count% hours","translations":{"ResetPasswordBundle":{"fr":"%count% heure|%count% heures","en":"%count% hour|%count% hours"}}};
export const COUNT_MINUTE_COUNT_MINUTES = {"id":"%count% minute|%count% minutes","translations":{"ResetPasswordBundle":{"fr":"%count% minute|%count% minutes","en":"%count% minute|%count% minutes"}}};
export const THERE_WAS_A_PROBLEM_VALIDATING_YOUR_PASSWORD_RESET_REQUEST = {"id":"There was a problem validating your password reset request","translations":{"ResetPasswordBundle":{"fr":"Un probl\u00e8me est survenu lors de la validation de votre demande de r\u00e9initialisation de mot de passe","en":"There was a problem validating your password reset request"}}};
export const THERE_WAS_A_PROBLEM_HANDLING_YOUR_PASSWORD_RESET_REQUEST = {"id":"There was a problem handling your password reset request","translations":{"ResetPasswordBundle":{"fr":"Un probl\u00e8me est survenu lors du traitement de votre demande de r\u00e9initialisation de mot de passe","en":"There was a problem handling your password reset request"}}};
export const THE_LINK_IN_YOUR_EMAIL_IS_EXPIRED_PLEASE_TRY_TO_RESET_YOUR_PASSWORD_AGAIN = {"id":"The link in your email is expired. Please try to reset your password again.","translations":{"ResetPasswordBundle":{"fr":"Le lien dans votre e-mail est expir\u00e9. Veuillez r\u00e9essayer de r\u00e9initialiser votre mot de passe.","en":"The link in your email is expired. Please try to reset your password again."}}};
export const PLEASE_UPDATE_THE_REQUEST_PASSWORD_REPOSITORY_CONFIGURATION_IN_CONFIG_PACKAGES_RESET_PASSWORD_YAML_TO_POINT_TO_YOUR_REQUEST_PASSWORD_REPOSITORY_SERVICE = {"id":"Please update the request_password_repository configuration in config\/packages\/reset_password.yaml to point to your \"request password repository\" service.","translations":{"ResetPasswordBundle":{"fr":"Veuillez mettre \u00e0 jour la configuration de request_password_repository dans config\/packages\/reset_password.yaml pour pointer vers votre service \"request password repository\"","en":"Please update the request_password_repository configuration in config\/packages\/reset_password.yaml to point to your \"request password repository\" service."}}};
export const THE_RESET_PASSWORD_LINK_IS_INVALID_PLEASE_TRY_TO_RESET_YOUR_PASSWORD_AGAIN = {"id":"The reset password link is invalid. Please try to reset your password again.","translations":{"ResetPasswordBundle":{"fr":"Le lien de r\u00e9initialisation du mot de passe n'est pas valide. Veuillez r\u00e9essayer de r\u00e9initialiser votre mot de passe","en":"The reset password link is invalid. Please try to reset your password again."}}};
export const YOU_HAVE_ALREADY_REQUESTED_A_RESET_PASSWORD_EMAIL_PLEASE_CHECK_YOUR_EMAIL_OR_TRY_AGAIN_SOON = {"id":"You have already requested a reset password email. Please check your email or try again soon.","translations":{"ResetPasswordBundle":{"fr":"Vous avez d\u00e9j\u00e0 demand\u00e9 un e-mail de r\u00e9initialisation du mot de passe. Veuillez v\u00e9rifier votre e-mail ou r\u00e9essayer bient\u00f4t.","en":"You have already requested a reset password email. Please check your email or try again soon."}}};
export const AUTH_CODE = {"id":"auth_code","translations":{"SchebTwoFactorBundle":{"fr":"Code d'authentification","en":"Authentication Code"}}};
export const CHOOSE_PROVIDER = {"id":"choose_provider","translations":{"SchebTwoFactorBundle":{"fr":"Changer de m\u00e9thode d'authentification","en":"Switch authentication method"}}};
export const LOGIN = {"id":"login","translations":{"SchebTwoFactorBundle":{"fr":"Connexion","en":"Login"}}};
export const CODE_INVALID = {"id":"code_invalid","translations":{"SchebTwoFactorBundle":{"fr":"Le code de v\u00e9rification n'est pas valide.","en":"The verification code is not valid."}}};
export const TRUSTED = {"id":"trusted","translations":{"SchebTwoFactorBundle":{"fr":"Je suis sur un appareil de confiance","en":"I'm on a trusted device"}}};
export const CANCEL = {"id":"cancel","translations":{"SchebTwoFactorBundle":{"fr":"Annuler","en":"Cancel"}}};
export const COUNTRY_AF = {"id":"country.AF","translations":{"address":{"fr":"Afghanistan","en":"Afghanistan"}}};
export const COUNTRY_AX = {"id":"country.AX","translations":{"address":{"fr":"\u00celes \u00c5land","en":"\u00c5land Islands"}}};
export const COUNTRY_AL = {"id":"country.AL","translations":{"address":{"fr":"Albanie","en":"Albania"}}};
export const COUNTRY_DZ = {"id":"country.DZ","translations":{"address":{"fr":"Alg\u00e9rie","en":"Algeria"}}};
export const COUNTRY_AS = {"id":"country.AS","translations":{"address":{"fr":"Samoa am\u00e9ricaines","en":"American Samoa"}}};
export const COUNTRY_AD = {"id":"country.AD","translations":{"address":{"fr":"Andorre","en":"Andorra"}}};
export const COUNTRY_AO = {"id":"country.AO","translations":{"address":{"fr":"Angola","en":"Angola"}}};
export const COUNTRY_AI = {"id":"country.AI","translations":{"address":{"fr":"Anguilla","en":"Anguilla"}}};
export const COUNTRY_AQ = {"id":"country.AQ","translations":{"address":{"fr":"Antarctique","en":"Antarctica"}}};
export const COUNTRY_AG = {"id":"country.AG","translations":{"address":{"fr":"Antigua-et-Barbuda","en":"Antigua and Barbuda"}}};
export const COUNTRY_AR = {"id":"country.AR","translations":{"address":{"fr":"Argentine","en":"Argentina"}}};
export const COUNTRY_AM = {"id":"country.AM","translations":{"address":{"fr":"Arm\u00e9nie","en":"Armenia"}}};
export const COUNTRY_AW = {"id":"country.AW","translations":{"address":{"fr":"Aruba","en":"Aruba"}}};
export const COUNTRY_AU = {"id":"country.AU","translations":{"address":{"fr":"Australie","en":"Australia"}}};
export const COUNTRY_AT = {"id":"country.AT","translations":{"address":{"fr":"Autriche","en":"Austria"}}};
export const COUNTRY_AZ = {"id":"country.AZ","translations":{"address":{"fr":"Azerba\u00efdjan","en":"Azerbaijan"}}};
export const COUNTRY_BS = {"id":"country.BS","translations":{"address":{"fr":"Bahamas","en":"Bahamas"}}};
export const COUNTRY_BH = {"id":"country.BH","translations":{"address":{"fr":"Bahre\u00efn","en":"Bahrain"}}};
export const COUNTRY_BD = {"id":"country.BD","translations":{"address":{"fr":"Bangladesh","en":"Bangladesh"}}};
export const COUNTRY_BB = {"id":"country.BB","translations":{"address":{"fr":"Barbade","en":"Barbados"}}};
export const COUNTRY_BY = {"id":"country.BY","translations":{"address":{"fr":"Bi\u00e9lorussie","en":"Belarus"}}};
export const COUNTRY_BE = {"id":"country.BE","translations":{"address":{"fr":"Belgique","en":"Belgium"}}};
export const COUNTRY_BZ = {"id":"country.BZ","translations":{"address":{"fr":"Belize","en":"Belize"}}};
export const COUNTRY_BJ = {"id":"country.BJ","translations":{"address":{"fr":"B\u00e9nin","en":"Benin"}}};
export const COUNTRY_BM = {"id":"country.BM","translations":{"address":{"fr":"Bermudes","en":"Bermuda"}}};
export const COUNTRY_BT = {"id":"country.BT","translations":{"address":{"fr":"Bhoutan","en":"Bhutan"}}};
export const COUNTRY_BO = {"id":"country.BO","translations":{"address":{"fr":"Bolivie","en":"Bolivia"}}};
export const COUNTRY_BA = {"id":"country.BA","translations":{"address":{"fr":"Bosnie-Herz\u00e9govine","en":"Bosnia and Herzegovina"}}};
export const COUNTRY_BW = {"id":"country.BW","translations":{"address":{"fr":"Botswana","en":"Botswana"}}};
export const COUNTRY_BV = {"id":"country.BV","translations":{"address":{"fr":"\u00cele Bouvet","en":"Bouvet Island"}}};
export const COUNTRY_BR = {"id":"country.BR","translations":{"address":{"fr":"Br\u00e9sil","en":"Brazil"}}};
export const COUNTRY_VG = {"id":"country.VG","translations":{"address":{"fr":"British Virgin Islands","en":"British Virgin Islands"}}};
export const COUNTRY_IO = {"id":"country.IO","translations":{"address":{"fr":"Territoire britannique de l\u2019Oc\u00e9an Indien","en":"British Indian Ocean Territory"}}};
export const COUNTRY_BN = {"id":"country.BN","translations":{"address":{"fr":"Brunei Darussalam","en":"Brunei Darussalam"}}};
export const COUNTRY_BG = {"id":"country.BG","translations":{"address":{"fr":"Bulgarie","en":"Bulgaria"}}};
export const COUNTRY_BF = {"id":"country.BF","translations":{"address":{"fr":"Burkina Faso","en":"Burkina Faso"}}};
export const COUNTRY_BI = {"id":"country.BI","translations":{"address":{"fr":"Burundi","en":"Burundi"}}};
export const COUNTRY_KH = {"id":"country.KH","translations":{"address":{"fr":"Cambodge","en":"Cambodia"}}};
export const COUNTRY_CM = {"id":"country.CM","translations":{"address":{"fr":"Cameroun","en":"Cameroon"}}};
export const COUNTRY_CA = {"id":"country.CA","translations":{"address":{"fr":"Canada","en":"Canada"}}};
export const COUNTRY_CV = {"id":"country.CV","translations":{"address":{"fr":"Cap-Vert","en":"Cape Verde"}}};
export const COUNTRY_KY = {"id":"country.KY","translations":{"address":{"fr":"Iles Cayman","en":"Cayman Islands"}}};
export const COUNTRY_CF = {"id":"country.CF","translations":{"address":{"fr":"R\u00e9publique centrafricaine","en":"Central African Republic"}}};
export const COUNTRY_TD = {"id":"country.TD","translations":{"address":{"fr":"Tchad","en":"Chad"}}};
export const COUNTRY_CL = {"id":"country.CL","translations":{"address":{"fr":"Chili","en":"Chile"}}};
export const COUNTRY_CN = {"id":"country.CN","translations":{"address":{"fr":"Chine","en":"China"}}};
export const COUNTRY_HK = {"id":"country.HK","translations":{"address":{"fr":"Hong Kong","en":"Hong Kong"}}};
export const COUNTRY_MO = {"id":"country.MO","translations":{"address":{"fr":"Macao","en":"Macao"}}};
export const COUNTRY_CX = {"id":"country.CX","translations":{"address":{"fr":"\u00cele Christmas","en":"Christmas Island"}}};
export const COUNTRY_CC = {"id":"country.CC","translations":{"address":{"fr":"\u00celes Cocos","en":"Cocos Islands"}}};
export const COUNTRY_CO = {"id":"country.CO","translations":{"address":{"fr":"Colombie","en":"Colombia"}}};
export const COUNTRY_KM = {"id":"country.KM","translations":{"address":{"fr":"Comores","en":"Comoros"}}};
export const COUNTRY_CG = {"id":"country.CG","translations":{"address":{"fr":"R\u00e9publique du Congo","en":"Republic of the Congo"}}};
export const COUNTRY_CD = {"id":"country.CD","translations":{"address":{"fr":"R\u00e9publique d\u00e9mocratique du Congo","en":"Democratic Republic of the Congo"}}};
export const COUNTRY_CK = {"id":"country.CK","translations":{"address":{"fr":"\u00celes Cook","en":"Cook Islands"}}};
export const COUNTRY_CR = {"id":"country.CR","translations":{"address":{"fr":"Costa Rica","en":"Costa Rica"}}};
export const COUNTRY_CI = {"id":"country.CI","translations":{"address":{"fr":"C\u00f4te d\u2019Ivoire","en":"Ivory Coast"}}};
export const COUNTRY_HR = {"id":"country.HR","translations":{"address":{"fr":"Croatie","en":"Croatia"}}};
export const COUNTRY_CU = {"id":"country.CU","translations":{"address":{"fr":"Cuba","en":"Cuba"}}};
export const COUNTRY_CY = {"id":"country.CY","translations":{"address":{"fr":"Chypre","en":"Cyprus"}}};
export const COUNTRY_CZ = {"id":"country.CZ","translations":{"address":{"fr":"R\u00e9publique tch\u00e8que","en":"Czech Republic"}}};
export const COUNTRY_DK = {"id":"country.DK","translations":{"address":{"fr":"Danemark","en":"Denmark"}}};
export const COUNTRY_DJ = {"id":"country.DJ","translations":{"address":{"fr":"Djibouti","en":"Djibouti"}}};
export const COUNTRY_DM = {"id":"country.DM","translations":{"address":{"fr":"Dominique","en":"Dominica"}}};
export const COUNTRY_DO = {"id":"country.DO","translations":{"address":{"fr":"R\u00e9publique dominicaine","en":"Dominican Republic"}}};
export const COUNTRY_EC = {"id":"country.EC","translations":{"address":{"fr":"\u00c9quateur","en":"Ecuador"}}};
export const COUNTRY_EG = {"id":"country.EG","translations":{"address":{"fr":"\u00c9gypte","en":"Egypt"}}};
export const COUNTRY_SV = {"id":"country.SV","translations":{"address":{"fr":"Salvador","en":"El Salvador"}}};
export const COUNTRY_GQ = {"id":"country.GQ","translations":{"address":{"fr":"Guin\u00e9e \u00e9quatoriale","en":"Equatorial Guinea"}}};
export const COUNTRY_ER = {"id":"country.ER","translations":{"address":{"fr":"\u00c9rythr\u00e9e","en":"Eritrea"}}};
export const COUNTRY_EE = {"id":"country.EE","translations":{"address":{"fr":"Estonie","en":"Estonia"}}};
export const COUNTRY_ET = {"id":"country.ET","translations":{"address":{"fr":"\u00c9thiopie","en":"Ethiopia"}}};
export const COUNTRY_FK = {"id":"country.FK","translations":{"address":{"fr":"\u00celes Falkland","en":"Falkland Islands"}}};
export const COUNTRY_FO = {"id":"country.FO","translations":{"address":{"fr":"\u00celes F\u00e9ro\u00e9","en":"Faroe Islands"}}};
export const COUNTRY_FJ = {"id":"country.FJ","translations":{"address":{"fr":"Fidji","en":"Fiji"}}};
export const COUNTRY_FI = {"id":"country.FI","translations":{"address":{"fr":"Finlande","en":"Finland"}}};
export const COUNTRY_FR = {"id":"country.FR","translations":{"address":{"fr":"France","en":"France"}}};
export const COUNTRY_GF = {"id":"country.GF","translations":{"address":{"fr":"Guyane fran\u00e7aise","en":"French Guiana"}}};
export const COUNTRY_PF = {"id":"country.PF","translations":{"address":{"fr":"Polyn\u00e9sie fran\u00e7aise","en":"French Polynesia"}}};
export const COUNTRY_TF = {"id":"country.TF","translations":{"address":{"fr":"Terres australes et antarctiques fran\u00e7aises","en":"French Southern and Antarctic Lands"}}};
export const COUNTRY_GA = {"id":"country.GA","translations":{"address":{"fr":"Gabon","en":"Gabon"}}};
export const COUNTRY_GM = {"id":"country.GM","translations":{"address":{"fr":"Gambie","en":"Gambia"}}};
export const COUNTRY_GE = {"id":"country.GE","translations":{"address":{"fr":"G\u00e9orgie","en":"Georgia"}}};
export const COUNTRY_DE = {"id":"country.DE","translations":{"address":{"fr":"Allemagne","en":"Germany"}}};
export const COUNTRY_GH = {"id":"country.GH","translations":{"address":{"fr":"Ghana","en":"Ghana"}}};
export const COUNTRY_GI = {"id":"country.GI","translations":{"address":{"fr":"Gibraltar","en":"Gibraltar"}}};
export const COUNTRY_GR = {"id":"country.GR","translations":{"address":{"fr":"Gr\u00e8ce","en":"Greece"}}};
export const COUNTRY_GL = {"id":"country.GL","translations":{"address":{"fr":"Groenland","en":"Greenland"}}};
export const COUNTRY_GD = {"id":"country.GD","translations":{"address":{"fr":"Grenade","en":"Grenada"}}};
export const COUNTRY_GP = {"id":"country.GP","translations":{"address":{"fr":"Guadeloupe","en":"Guadeloupe"}}};
export const COUNTRY_GU = {"id":"country.GU","translations":{"address":{"fr":"Guam","en":"Guam"}}};
export const COUNTRY_GT = {"id":"country.GT","translations":{"address":{"fr":"Guatemala","en":"Guatemala"}}};
export const COUNTRY_GG = {"id":"country.GG","translations":{"address":{"fr":"Guernesey","en":"Guernsey"}}};
export const COUNTRY_GN = {"id":"country.GN","translations":{"address":{"fr":"Guin\u00e9e","en":"Guinea"}}};
export const COUNTRY_GW = {"id":"country.GW","translations":{"address":{"fr":"Guin\u00e9e-Bissau","en":"Guinea-Bissau"}}};
export const COUNTRY_GY = {"id":"country.GY","translations":{"address":{"fr":"Guyane","en":"Guyana"}}};
export const COUNTRY_HT = {"id":"country.HT","translations":{"address":{"fr":"Ha\u00efti","en":"Haiti"}}};
export const COUNTRY_HM = {"id":"country.HM","translations":{"address":{"fr":"\u00celes Heard-et-MacDonald","en":"Heard Island and McDonald Islands"}}};
export const COUNTRY_VA = {"id":"country.VA","translations":{"address":{"fr":"Saint-Si\u00e8ge (Vatican)","en":"Holy See (Vatican)"}}};
export const COUNTRY_HN = {"id":"country.HN","translations":{"address":{"fr":"Honduras","en":"Honduras"}}};
export const COUNTRY_HU = {"id":"country.HU","translations":{"address":{"fr":"Hongrie","en":"Hungary"}}};
export const COUNTRY_IS = {"id":"country.IS","translations":{"address":{"fr":"Islande","en":"Iceland"}}};
export const COUNTRY_IN = {"id":"country.IN","translations":{"address":{"fr":"Inde","en":"India"}}};
export const COUNTRY_ID = {"id":"country.ID","translations":{"address":{"fr":"Indon\u00e9sie","en":"Indonesia"}}};
export const COUNTRY_IR = {"id":"country.IR","translations":{"address":{"fr":"Iran","en":"Iran"}}};
export const COUNTRY_IQ = {"id":"country.IQ","translations":{"address":{"fr":"Irak","en":"Iraq"}}};
export const COUNTRY_IE = {"id":"country.IE","translations":{"address":{"fr":"Irlande","en":"Ireland"}}};
export const COUNTRY_IM = {"id":"country.IM","translations":{"address":{"fr":"Ile de Man","en":"Isle of Man"}}};
export const COUNTRY_IL = {"id":"country.IL","translations":{"address":{"fr":"Isra\u00ebl","en":"Israel"}}};
export const COUNTRY_IT = {"id":"country.IT","translations":{"address":{"fr":"Italie","en":"Italy"}}};
export const COUNTRY_JM = {"id":"country.JM","translations":{"address":{"fr":"Jama\u00efque","en":"Jamaica"}}};
export const COUNTRY_JP = {"id":"country.JP","translations":{"address":{"fr":"Japon","en":"Japan"}}};
export const COUNTRY_JE = {"id":"country.JE","translations":{"address":{"fr":"Jersey","en":"Jersey"}}};
export const COUNTRY_JO = {"id":"country.JO","translations":{"address":{"fr":"Jordanie","en":"Jordan"}}};
export const COUNTRY_KZ = {"id":"country.KZ","translations":{"address":{"fr":"Kazakhstan","en":"Kazakhstan"}}};
export const COUNTRY_KE = {"id":"country.KE","translations":{"address":{"fr":"Kenya","en":"Kenya"}}};
export const COUNTRY_KI = {"id":"country.KI","translations":{"address":{"fr":"Kiribati","en":"Kiribati"}}};
export const COUNTRY_KP = {"id":"country.KP","translations":{"address":{"fr":"Cor\u00e9e du Nord","en":"North Korea"}}};
export const COUNTRY_KR = {"id":"country.KR","translations":{"address":{"fr":"Cor\u00e9e du Sud","en":"South Korea"}}};
export const COUNTRY_KW = {"id":"country.KW","translations":{"address":{"fr":"Kowe\u00eft","en":"Kuwait"}}};
export const COUNTRY_KG = {"id":"country.KG","translations":{"address":{"fr":"Kirghizistan","en":"Kyrgyzstan"}}};
export const COUNTRY_LA = {"id":"country.LA","translations":{"address":{"fr":"Laos","en":"Laos"}}};
export const COUNTRY_LV = {"id":"country.LV","translations":{"address":{"fr":"Lettonie","en":"Latvia"}}};
export const COUNTRY_LB = {"id":"country.LB","translations":{"address":{"fr":"Liban","en":"Lebanon"}}};
export const COUNTRY_LS = {"id":"country.LS","translations":{"address":{"fr":"Lesotho","en":"Lesotho"}}};
export const COUNTRY_LR = {"id":"country.LR","translations":{"address":{"fr":"Lib\u00e9ria","en":"Liberia"}}};
export const COUNTRY_LY = {"id":"country.LY","translations":{"address":{"fr":"Libye","en":"Libya"}}};
export const COUNTRY_LI = {"id":"country.LI","translations":{"address":{"fr":"Liechtenstein","en":"Liechtenstein"}}};
export const COUNTRY_LT = {"id":"country.LT","translations":{"address":{"fr":"Lituanie","en":"Lithuania"}}};
export const COUNTRY_LU = {"id":"country.LU","translations":{"address":{"fr":"Luxembourg","en":"Luxembourg"}}};
export const COUNTRY_MK = {"id":"country.MK","translations":{"address":{"fr":"Mac\u00e9doine","en":"Macedonia"}}};
export const COUNTRY_MG = {"id":"country.MG","translations":{"address":{"fr":"Madagascar","en":"Madagascar"}}};
export const COUNTRY_MW = {"id":"country.MW","translations":{"address":{"fr":"Malawi","en":"Malawi"}}};
export const COUNTRY_MY = {"id":"country.MY","translations":{"address":{"fr":"Malaisie","en":"Malaysia"}}};
export const COUNTRY_MV = {"id":"country.MV","translations":{"address":{"fr":"Maldives","en":"Maldives"}}};
export const COUNTRY_ML = {"id":"country.ML","translations":{"address":{"fr":"Mali","en":"Mali"}}};
export const COUNTRY_MT = {"id":"country.MT","translations":{"address":{"fr":"Malte","en":"Malta"}}};
export const COUNTRY_MH = {"id":"country.MH","translations":{"address":{"fr":"\u00celes Marshall","en":"Marshall Islands"}}};
export const COUNTRY_MQ = {"id":"country.MQ","translations":{"address":{"fr":"Martinique","en":"Martinique"}}};
export const COUNTRY_MR = {"id":"country.MR","translations":{"address":{"fr":"Mauritanie","en":"Mauritania"}}};
export const COUNTRY_MU = {"id":"country.MU","translations":{"address":{"fr":"Maurice","en":"Mauritius"}}};
export const COUNTRY_YT = {"id":"country.YT","translations":{"address":{"fr":"Mayotte","en":"Mayotte"}}};
export const COUNTRY_MX = {"id":"country.MX","translations":{"address":{"fr":"Mexique","en":"Mexico"}}};
export const COUNTRY_FM = {"id":"country.FM","translations":{"address":{"fr":"Micron\u00e9sie","en":"Micronesia"}}};
export const COUNTRY_MD = {"id":"country.MD","translations":{"address":{"fr":"Moldavie","en":"Moldova"}}};
export const COUNTRY_MC = {"id":"country.MC","translations":{"address":{"fr":"Monaco","en":"Monaco"}}};
export const COUNTRY_MN = {"id":"country.MN","translations":{"address":{"fr":"Mongolie","en":"Mongolia"}}};
export const COUNTRY_ME = {"id":"country.ME","translations":{"address":{"fr":"Mont\u00e9n\u00e9gro","en":"Montenegro"}}};
export const COUNTRY_MS = {"id":"country.MS","translations":{"address":{"fr":"Montserrat","en":"Montserrat"}}};
export const COUNTRY_MA = {"id":"country.MA","translations":{"address":{"fr":"Maroc","en":"Morocco"}}};
export const COUNTRY_MZ = {"id":"country.MZ","translations":{"address":{"fr":"Mozambique","en":"Mozambique"}}};
export const COUNTRY_MM = {"id":"country.MM","translations":{"address":{"fr":"Myanmar","en":"Myanmar"}}};
export const COUNTRY_NA = {"id":"country.NA","translations":{"address":{"fr":"Namibie","en":"Namibia"}}};
export const COUNTRY_NR = {"id":"country.NR","translations":{"address":{"fr":"Nauru","en":"Nauru"}}};
export const COUNTRY_NP = {"id":"country.NP","translations":{"address":{"fr":"N\u00e9pal","en":"Nepal"}}};
export const COUNTRY_NL = {"id":"country.NL","translations":{"address":{"fr":"Pays-Bas","en":"Netherlands"}}};
export const COUNTRY_NC = {"id":"country.NC","translations":{"address":{"fr":"Nouvelle-Cal\u00e9donie","en":"New Caledonia"}}};
export const COUNTRY_NZ = {"id":"country.NZ","translations":{"address":{"fr":"Nouvelle-Z\u00e9lande","en":"New Zealand"}}};
export const COUNTRY_NI = {"id":"country.NI","translations":{"address":{"fr":"Nicaragua","en":"Nicaragua"}}};
export const COUNTRY_NE = {"id":"country.NE","translations":{"address":{"fr":"Niger","en":"Niger"}}};
export const COUNTRY_NG = {"id":"country.NG","translations":{"address":{"fr":"Nigeria","en":"Nigeria"}}};
export const COUNTRY_NU = {"id":"country.NU","translations":{"address":{"fr":"Niue","en":"Niue"}}};
export const COUNTRY_NF = {"id":"country.NF","translations":{"address":{"fr":"\u00cele Norfolk","en":"Norfolk Island"}}};
export const COUNTRY_MP = {"id":"country.MP","translations":{"address":{"fr":"\u00celes Mariannes du Nord","en":"Northern Mariana Islands"}}};
export const COUNTRY_NO = {"id":"country.NO","translations":{"address":{"fr":"Norv\u00e8ge","en":"Norway"}}};
export const COUNTRY_OM = {"id":"country.OM","translations":{"address":{"fr":"Oman","en":"Oman"}}};
export const COUNTRY_PK = {"id":"country.PK","translations":{"address":{"fr":"Pakistan","en":"Pakistan"}}};
export const COUNTRY_PW = {"id":"country.PW","translations":{"address":{"fr":"Palau","en":"Palau"}}};
export const COUNTRY_PS = {"id":"country.PS","translations":{"address":{"fr":"Palestine","en":"Palestine"}}};
export const COUNTRY_PA = {"id":"country.PA","translations":{"address":{"fr":"Panama","en":"Panama"}}};
export const COUNTRY_PG = {"id":"country.PG","translations":{"address":{"fr":"Papouasie-Nouvelle-Guin\u00e9e","en":"Papua New Guinea"}}};
export const COUNTRY_PY = {"id":"country.PY","translations":{"address":{"fr":"Paraguay","en":"Paraguay"}}};
export const COUNTRY_PE = {"id":"country.PE","translations":{"address":{"fr":"P\u00e9rou","en":"Peru"}}};
export const COUNTRY_PH = {"id":"country.PH","translations":{"address":{"fr":"Philippines","en":"Philippines"}}};
export const COUNTRY_PN = {"id":"country.PN","translations":{"address":{"fr":"Pitcairn","en":"Pitcairn"}}};
export const COUNTRY_PL = {"id":"country.PL","translations":{"address":{"fr":"Pologne","en":"Poland"}}};
export const COUNTRY_PT = {"id":"country.PT","translations":{"address":{"fr":"Portugal","en":"Portugal"}}};
export const COUNTRY_PR = {"id":"country.PR","translations":{"address":{"fr":"Puerto Rico","en":"Puerto Rico"}}};
export const COUNTRY_QA = {"id":"country.QA","translations":{"address":{"fr":"Qatar","en":"Qatar"}}};
export const COUNTRY_RE = {"id":"country.RE","translations":{"address":{"fr":"R\u00e9union","en":"Reunion island"}}};
export const COUNTRY_RO = {"id":"country.RO","translations":{"address":{"fr":"Roumanie","en":"Romania"}}};
export const COUNTRY_RU = {"id":"country.RU","translations":{"address":{"fr":"Russie","en":"Russia"}}};
export const COUNTRY_RW = {"id":"country.RW","translations":{"address":{"fr":"Rwanda","en":"Rwanda"}}};
export const COUNTRY_BL = {"id":"country.BL","translations":{"address":{"fr":"Saint-Barth\u00e9lemy","en":"Saint Barth\u00e9lemy"}}};
export const COUNTRY_SH = {"id":"country.SH","translations":{"address":{"fr":"Sainte-H\u00e9l\u00e8ne","en":"Saint Helena"}}};
export const COUNTRY_KN = {"id":"country.KN","translations":{"address":{"fr":"Saint-Kitts-et-Nevis","en":"Saint Kitts and Nevis"}}};
export const COUNTRY_LC = {"id":"country.LC","translations":{"address":{"fr":"Sainte-Lucie","en":"Saint Lucia"}}};
export const COUNTRY_MF = {"id":"country.MF","translations":{"address":{"fr":"Saint-Martin (partie fran\u00e7aise)","en":"Saint Martin (French part)"}}};
export const COUNTRY_SX = {"id":"country.SX","translations":{"address":{"fr":"Saint-Martin (partie n\u00e9erlandaise)","en":"Saint Martin (Dutch part)"}}};
export const COUNTRY_PM = {"id":"country.PM","translations":{"address":{"fr":"Saint-Pierre-et-Miquelon","en":"Saint Pierre and Miquelon"}}};
export const COUNTRY_VC = {"id":"country.VC","translations":{"address":{"fr":"Saint-Vincent-et-les Grenadines","en":"Saint Vincent and the Grenadines"}}};
export const COUNTRY_WS = {"id":"country.WS","translations":{"address":{"fr":"Samoa","en":"Samoa"}}};
export const COUNTRY_SM = {"id":"country.SM","translations":{"address":{"fr":"Saint-Marin","en":"San Marino"}}};
export const COUNTRY_ST = {"id":"country.ST","translations":{"address":{"fr":"Sao Tom\u00e9-et-Principe","en":"S\u00e3o Tom\u00e9 and Pr\u00edncipe"}}};
export const COUNTRY_SA = {"id":"country.SA","translations":{"address":{"fr":"Arabie Saoudite","en":"Saudi Arabia"}}};
export const COUNTRY_SN = {"id":"country.SN","translations":{"address":{"fr":"S\u00e9n\u00e9gal","en":"Senegal"}}};
export const COUNTRY_RS = {"id":"country.RS","translations":{"address":{"fr":"Serbie","en":"Serbia"}}};
export const COUNTRY_SC = {"id":"country.SC","translations":{"address":{"fr":"Seychelles","en":"Seychelles"}}};
export const COUNTRY_SL = {"id":"country.SL","translations":{"address":{"fr":"Sierra Leone","en":"Sierra Leone"}}};
export const COUNTRY_SG = {"id":"country.SG","translations":{"address":{"fr":"Singapour","en":"Singapore"}}};
export const COUNTRY_SK = {"id":"country.SK","translations":{"address":{"fr":"Slovaquie","en":"Slovakia"}}};
export const COUNTRY_SI = {"id":"country.SI","translations":{"address":{"fr":"Slov\u00e9nie","en":"Slovenia"}}};
export const COUNTRY_SB = {"id":"country.SB","translations":{"address":{"fr":"\u00celes Salomon","en":"Solomon Islands"}}};
export const COUNTRY_SO = {"id":"country.SO","translations":{"address":{"fr":"Somalie","en":"Somalia"}}};
export const COUNTRY_ZA = {"id":"country.ZA","translations":{"address":{"fr":"Afrique du Sud","en":"South Africa"}}};
export const COUNTRY_GS = {"id":"country.GS","translations":{"address":{"fr":"G\u00e9orgie du Sud et les \u00eeles Sandwich du Sud","en":"South Georgia and South Sandwich Islands"}}};
export const COUNTRY_SS = {"id":"country.SS","translations":{"address":{"fr":"Sud-Soudan","en":"South Sudan"}}};
export const COUNTRY_ES = {"id":"country.ES","translations":{"address":{"fr":"Espagne","en":"Spain"}}};
export const COUNTRY_LK = {"id":"country.LK","translations":{"address":{"fr":"Sri Lanka","en":"Sri Lanka"}}};
export const COUNTRY_SD = {"id":"country.SD","translations":{"address":{"fr":"Soudan","en":"Sudan"}}};
export const COUNTRY_SR = {"id":"country.SR","translations":{"address":{"fr":"Suriname","en":"Suriname"}}};
export const COUNTRY_SJ = {"id":"country.SJ","translations":{"address":{"fr":"Svalbard et Jan Mayen","en":"Svalbard and Jan Mayen"}}};
export const COUNTRY_SZ = {"id":"country.SZ","translations":{"address":{"fr":"Eswatini","en":"Eswatini"}}};
export const COUNTRY_SE = {"id":"country.SE","translations":{"address":{"fr":"Su\u00e8de","en":"Sweden"}}};
export const COUNTRY_CH = {"id":"country.CH","translations":{"address":{"fr":"Suisse","en":"Switzerland"}}};
export const COUNTRY_SY = {"id":"country.SY","translations":{"address":{"fr":"Syrie","en":"Syria"}}};
export const COUNTRY_TW = {"id":"country.TW","translations":{"address":{"fr":"Taiwan","en":"Taiwan"}}};
export const COUNTRY_TJ = {"id":"country.TJ","translations":{"address":{"fr":"Tadjikistan","en":"Tajikistan"}}};
export const COUNTRY_TZ = {"id":"country.TZ","translations":{"address":{"fr":"Tanzanie","en":"Tanzania"}}};
export const COUNTRY_TH = {"id":"country.TH","translations":{"address":{"fr":"Tha\u00eflande","en":"Thailand"}}};
export const COUNTRY_TL = {"id":"country.TL","translations":{"address":{"fr":"Timor-Leste","en":"Timor-Leste"}}};
export const COUNTRY_TG = {"id":"country.TG","translations":{"address":{"fr":"Togo","en":"Togo"}}};
export const COUNTRY_TK = {"id":"country.TK","translations":{"address":{"fr":"Tokelau","en":"Tokelau (Tokelau Islands)"}}};
export const COUNTRY_TO = {"id":"country.TO","translations":{"address":{"fr":"Tonga","en":"Tonga"}}};
export const COUNTRY_TT = {"id":"country.TT","translations":{"address":{"fr":"Trinit\u00e9-et-Tobago","en":"Trinidad and Tobago"}}};
export const COUNTRY_TN = {"id":"country.TN","translations":{"address":{"fr":"Tunisie","en":"Tunisia"}}};
export const COUNTRY_TR = {"id":"country.TR","translations":{"address":{"fr":"Turquie","en":"Turkey"}}};
export const COUNTRY_TM = {"id":"country.TM","translations":{"address":{"fr":"Turkm\u00e9nistan","en":"Turkmenistan"}}};
export const COUNTRY_TC = {"id":"country.TC","translations":{"address":{"fr":"\u00celes Turques-et-Ca\u00efques","en":"Turks and Caicos Islands"}}};
export const COUNTRY_TV = {"id":"country.TV","translations":{"address":{"fr":"Tuvalu","en":"Tuvalu"}}};
export const COUNTRY_UG = {"id":"country.UG","translations":{"address":{"fr":"Ouganda","en":"Uganda"}}};
export const COUNTRY_UA = {"id":"country.UA","translations":{"address":{"fr":"Ukraine","en":"Ukraine"}}};
export const COUNTRY_AE = {"id":"country.AE","translations":{"address":{"fr":"\u00c9mirats Arabes Unis","en":"United Arab Emirates"}}};
export const COUNTRY_GB = {"id":"country.GB","translations":{"address":{"fr":"Royaume-Uni","en":"United Kingdom"}}};
export const COUNTRY_US = {"id":"country.US","translations":{"address":{"fr":"\u00c9tats-Unis","en":"United States"}}};
export const COUNTRY_UM = {"id":"country.UM","translations":{"address":{"fr":"\u00celes mineures \u00e9loign\u00e9es des \u00c9tats-Unis","en":"United States Minor Outlying Islands"}}};
export const COUNTRY_UY = {"id":"country.UY","translations":{"address":{"fr":"Uruguay","en":"Uruguay"}}};
export const COUNTRY_UZ = {"id":"country.UZ","translations":{"address":{"fr":"Ouzb\u00e9kistan","en":"Uzbekistan"}}};
export const COUNTRY_VU = {"id":"country.VU","translations":{"address":{"fr":"Vanuatu","en":"Vanuatu"}}};
export const COUNTRY_VE = {"id":"country.VE","translations":{"address":{"fr":"Venezuela","en":"Venezuela"}}};
export const COUNTRY_VN = {"id":"country.VN","translations":{"address":{"fr":"Vi\u00eat Nam","en":"Vietnam"}}};
export const COUNTRY_VI = {"id":"country.VI","translations":{"address":{"fr":"\u00celes Vierges am\u00e9ricaines","en":"United States Virgin Islands"}}};
export const COUNTRY_WF = {"id":"country.WF","translations":{"address":{"fr":"Wallis-et-Futuna","en":"Wallis and Futuna"}}};
export const COUNTRY_EH = {"id":"country.EH","translations":{"address":{"fr":"Sahara occidental","en":"Western Sahara"}}};
export const COUNTRY_YE = {"id":"country.YE","translations":{"address":{"fr":"Y\u00e9men","en":"Yemen"}}};
export const COUNTRY_ZM = {"id":"country.ZM","translations":{"address":{"fr":"Zambie","en":"Zambia"}}};
export const COUNTRY_ZW = {"id":"country.ZW","translations":{"address":{"fr":"Zimbabwe","en":"Zimbabwe"}}};
export const DISPLAY_VALUE_PATIENT_TREATMENT = {"id":"displayValue.patientTreatment","translations":{"ai_suggestion":{"fr":"{{ name }},{{ recurrences is defined and recurrences ? \" d\u00e9livr\u00e9 \" ~ recurrences ~ \" fois,\" : \"\" }}{{ frequency is defined and frequency ? \" tous les \" ~ frequency ~ \" jours\" }}{{ frequency is defined and frequency > 1 ? \" \u00e0 partir du\" : end_date_formatted is defined and end_date_formatted ? \" du\" : \" le\" }} {{ date_formatted }} {{ end_date_formatted is defined and end_date_formatted ? \" au \"  ~ end_date_formatted : \"\" }}","en":"{{ name }},{{ recurrences is defined and recurrences ? \" delivered \" ~ recurrences ~ \" times,\" : \"\" }}{{ frequency is defined and frequency ? \" every \" ~ frequency ~ \" days\" }}{{ frequency is defined and frequency > 1 ? \" starting from\" : \" on\" }} {{ date_formatted }}"}}};
export const REJECTION_REASON_TYPE_DESCRIPTION = {"id":"rejection_reason_type.description","translations":{"ai_suggestion":{"fr":"Type de rejection","en":"Rejection type"}}};
export const REJECTION_REASON_TYPE_LABEL = {"id":"rejection_reason_type.label","translations":{"ai_suggestion":{"fr":"Pourquoi avez-vous rejet\u00e9 cette suggestion ?","en":"Why did you reject this suggestion?"}}};
export const REJECTION_REASON_TYPE_PLACEHOLDER = {"id":"rejection_reason_type.placeholder","translations":{"ai_suggestion":{"fr":"S\u00e9lectionnez une raison","en":"Select reason"}}};
export const REJECTION_REASON_TYPE_VALUES1 = {"id":"rejection_reason_type.values.1","translations":{"ai_suggestion":{"fr":"La suggestion propos\u00e9e est incorrecte","en":"The proposed value is incorrect"}}};
export const REJECTION_REASON_TYPE_VALUES2 = {"id":"rejection_reason_type.values.2","translations":{"ai_suggestion":{"fr":"La suggestion propos\u00e9e n'est pas compatible avec le sexe du patient","en":"The proposed value is not compatible with patient's gender"}}};
export const REJECTION_REASON_TYPE_VALUES3 = {"id":"rejection_reason_type.values.3","translations":{"ai_suggestion":{"fr":"La suggestion propos\u00e9e ne figure pas dans le document","en":"The proposed value is not in the document"}}};
export const REJECTION_REASON_TYPE_VALUES5 = {"id":"rejection_reason_type.values.5","translations":{"ai_suggestion":{"fr":"La date propos\u00e9e est incorrecte","en":"The proposed date is incorrect"}}};
export const REJECTION_REASON_TYPE_VALUES6 = {"id":"rejection_reason_type.values.6","translations":{"ai_suggestion":{"fr":"La valeur propos\u00e9e est en doublon","en":"The proposed value is a duplicate"}}};
export const REJECTION_REASON_TYPE_VALUES4 = {"id":"rejection_reason_type.values.4","translations":{"ai_suggestion":{"fr":"Autre","en":"Other"}}};
export const STATUS_DESCRIPTION = {"id":"status.description","translations":{"ai_suggestion":{"fr":"Etat","en":"Status"},"alert":{"fr":"Le status de l'alerte, si cette derni\u00e8re a \u00e9t\u00e9 trait\u00e9e par un m\u00e9decin ou non.","en":"The status of the alert, whether it has been handled by a doctor or not."},"cerfa_template":{"fr":"Etat du mod\u00e8le","en":"Model status"},"consultation_location":{"fr":"Etat du lieu de consultation","en":"Status of the consultation location"},"event":{"fr":"Le status de l'\u00e9v\u00e9nement","en":"The status of appointment"},"event_type":{"fr":"Le status du motif d'\u00e9v\u00e9nement","en":"The appointment type status"},"identity_document":{"fr":"Statut du document","en":"Document status"},"medical_record_field":{"fr":"Le status du champ","en":"The field status"},"mobile_app_version":{"fr":"L'\u00e9tat de la version","en":"Version status"},"patient+intl-icu":{"fr":"L'\u00e9tat du compte du patient","en":"Patient's account status"},"task":{"fr":"Statut de la Task","en":"Task Status"}}};
export const STATUS_LABEL = {"id":"status.label","translations":{"ai_suggestion":{"fr":"Etat de la suggestion","en":"Suggestion status"},"alert":{"fr":"Status","en":"Status"},"cerfa_template":{"fr":"Choisissez un \u00e9tat","en":"Select status"},"consultation_location":{"fr":"Choisissez un \u00e9tat","en":"Choose status"},"event":{"fr":"Status","en":"Status"},"event_type":{"fr":"Etat","en":"Status"},"identity_document":{"fr":"S\u00e9lectionnez un statut pour le document","en":"Select status for document"},"medical_record_field":{"fr":"Status du champ","en":"Field status"},"mobile_app_version":{"fr":"Etat","en":"Status"},"patient+intl-icu":{"fr":"Etat du compte","en":"Account status"},"task":{"fr":"Choisissez un statut","en":"Choose status"}}};
export const STATUS_PLACEHOLDER = {"id":"status.placeholder","translations":{"ai_suggestion":{"fr":"S\u00e9lectionnez un \u00eatat","en":"Select status"},"cerfa_template":{"fr":"Choisissez un \u00e9tat","en":"Select status"},"consultation_location":{"fr":"Choisissez un \u00e9tat","en":"Choose status"},"event":{"fr":"S\u00e9lectionnez un status","en":"Select status"},"event_type":{"fr":"S\u00e9lectionnez un \u00e9tat","en":"Select status"},"identity_document":{"fr":"S\u00e9lectionnez un statut","en":"Select status"},"medical_record_field":{"fr":"Choisissez une valeur","en":"Choose a value"},"mobile_app_version":{"fr":"S\u00e9lectionnez un \u00e9tat","en":"Select status"},"patient+intl-icu":{"fr":"S\u00e9lectionnez un \u00e9tat","en":"Select status"},"task":{"fr":"Choisissez un statut","en":"Choose status"}}};
export const STATUS_VALUES0 = {"id":"status.values.0","translations":{"ai_suggestion":{"fr":"En attente","en":"Pending"},"identity_document":{"fr":"En attente","en":"Pending"}}};
export const STATUS_VALUES1 = {"id":"status.values.1","translations":{"ai_suggestion":{"fr":"Valid\u00e9e","en":"Approved"},"cerfa_template":{"fr":"Actif","en":"Active"},"consultation_location":{"fr":"Actif","en":"Active"},"event":{"fr":"En salle d\u2019attente","en":"In waiting room"},"identity_document":{"fr":"Accept\u00e9","en":"Accepted"},"medical_record_field":{"fr":"Actif","en":"Active"}}};
export const STATUS_VALUES2 = {"id":"status.values.2","translations":{"ai_suggestion":{"fr":"Refus\u00e9e","en":"Rejected"},"cerfa_template":{"fr":"Archiv\u00e9","en":"Archived"},"consultation_location":{"fr":"Archiv\u00e9","en":"Archived"},"event":{"fr":"En consultation","en":"In consultation"},"identity_document":{"fr":"Refus\u00e9","en":"Refused"},"medical_record_field":{"fr":"Archiv\u00e9","en":"Archived"}}};
export const AI_SUGGESTION_REJECTION_TYPE = {"id":"ai_suggestion.rejection_type","translations":{"ai_suggestion":{"fr":"Type de refus","en":"Type of refusal"}}};
export const AI_SUGGESTION_FILL_REJECTION_TYPE = {"id":"ai_suggestion.fill_rejection_type","translations":{"ai_suggestion":{"fr":"Entrez la raison du refus","en":"Enter reason for rejection"}}};
export const STATUS1 = {"id":"status.1","translations":{"alert":{"fr":"En cours","en":"In Progress"},"event_type":{"fr":"Actif","en":"Active"},"log":{"fr":"En cours","en":"In progress"},"patient+intl-icu":{"fr":"Activ\u00e9","en":"Enabled"},"profile":{"fr":"Activ\u00e9","en":"Activated"},"task":{"fr":"En attente","en":"Pending"}}};
export const STATUS2 = {"id":"status.2","translations":{"alert":{"fr":"Lev\u00e9e","en":"Resolved"},"event_type":{"fr":"Archiv\u00e9","en":"Archived"},"log":{"fr":"Effectu\u00e9","en":"Completed"},"profile":{"fr":"Archiv\u00e9","en":"Archived"},"task":{"fr":"Re\u00e7u","en":"Received"}}};
export const ALERT_LIST_TITLE = {"id":"alert.list.title","translations":{"alert":{"fr":"Liste des alertes","en":"List of alerts"}}};
export const ALERT_LIST_HISTORIC = {"id":"alert.list.historic","translations":{"alert":{"fr":"Historique des alertes","en":"Alerts history"}}};
export const ALERT_LIST_HISTORIC_OF = {"id":"alert.list.historic_of","translations":{"alert":{"fr":"Historique des alertes de $profile","en":"Alerts history of $profile"}}};
export const ALERT_LIST_BACK_RECORD = {"id":"alert.list.back_record","translations":{"alert":{"fr":"Retour au dossier","en":"Back to record"}}};
export const ALERT_LIST_CRITICALITY = {"id":"alert.list.criticality","translations":{"alert":{"fr":"Criticit\u00e9","en":"Criticality"}}};
export const ALERT_LIST_NOT_FOUND = {"id":"alert.list.not_found","translations":{"alert":{"fr":"Aucune alerte trouv\u00e9e","en":"No alerts found"}}};
export const PRIORITY_DESCRIPTION = {"id":"priority.description","translations":{"alert_type":{"fr":"La priorit\u00e9 de l'alerte qui sera lev\u00e9e","en":"The priority of the alert that will be raised"}}};
export const PRIORITY_LABEL = {"id":"priority.label","translations":{"alert_type":{"fr":"Priorit\u00e9 de l'alerte","en":"Alert priority"}}};
export const PRIORITY_PLACEHOLDER = {"id":"priority.placeholder","translations":{"alert_type":{"fr":"S\u00e9lectionnez une priorit\u00e9","en":"Select a priority"}}};
export const PRIORITY1 = {"id":"priority.1","translations":{"alert_type":{"fr":"Basse","en":"Low"}}};
export const PRIORITY2 = {"id":"priority.2","translations":{"alert_type":{"fr":"Moyenne","en":"Medium"}}};
export const PRIORITY3 = {"id":"priority.3","translations":{"alert_type":{"fr":"\u00c9lev\u00e9e","en":"High"}}};
export const PRIORITY4 = {"id":"priority.4","translations":{"alert_type":{"fr":"Critique","en":"Critical"}}};
export const COMPARATED_VALUE_DESCRIPTION = {"id":"comparated_value.description","translations":{"alert_type":{"fr":"Si l'on compare la condition avec la valeur actuelle ou la valeur pr\u00e9c\u00e9dente","en":"If comparing the condition with the current value or the previous value"}}};
export const COMPARATED_VALUE_LABEL = {"id":"comparated_value.label","translations":{"alert_type":{"fr":"Valeur compar\u00e9e","en":"Compared value"}}};
export const COMPARATED_VALUE_PLACEHOLDER = {"id":"comparated_value.placeholder","translations":{"alert_type":{"fr":"S\u00e9lectionnez une valeur","en":"Select value"}}};
export const COMPARATED_VALUE_CURRENT = {"id":"comparated_value.current","translations":{"alert_type":{"fr":"Valeur actuelle","en":"Current value"}}};
export const COMPARATED_VALUE_PREVIOUS = {"id":"comparated_value.previous","translations":{"alert_type":{"fr":"Pr\u00e9c\u00e9dente valeur","en":"Previous value"}}};
export const PHARMACEUTICAL_INGREDIENT = {"id":"pharmaceutical-ingredient","translations":{"allergens":{"fr":"Ingr\u00e9dient pharmaceutique","en":"Pharmaceutical ingredient"}}};
export const EXCIPIENT = {"id":"excipient","translations":{"allergens":{"fr":"Excipient","en":"Excipient"}}};
export const THERAPEUTIC_CLASS = {"id":"therapeutic-class","translations":{"allergens":{"fr":"Classe th\u00e9rapeutique","en":"Therapeutic class"}}};
export const FILTERS_ACTION_SOURCE_LABEL = {"id":"filters.actionSource.label","translations":{"audit_trail":{"fr":"Source de l'action","en":"Action source"}}};
export const FILTERS_ACTION_SOURCE_VALUES_WEB = {"id":"filters.actionSource.values.web","translations":{"audit_trail":{"fr":"Application web","en":"Web application"}}};
export const FILTERS_ACTION_SOURCE_VALUES_MOBILE = {"id":"filters.actionSource.values.mobile","translations":{"audit_trail":{"fr":"Application mobile","en":"Mobile application"}}};
export const FILTERS_ACTION_SOURCE_VALUES_WORKER = {"id":"filters.actionSource.values.worker","translations":{"audit_trail":{"fr":"Application worker","en":"Worker application"}}};
export const FILTERS_KIND_LABEL = {"id":"filters.kind.label","translations":{"audit_trail":{"fr":"Type d'\u00e9v\u00e9nement","en":"Appointment type"}}};
export const FILTERS_KIND_VALUES_MEDICAL_RECORD_CONSULTED = {"id":"filters.kind.values.medical_record_consulted","translations":{"audit_trail":{"fr":"Dossier m\u00e9dical consult\u00e9","en":"Medical record consulted"}}};
export const FILTERS_KIND_VALUES_MEDICAL_RECORD_CREATED = {"id":"filters.kind.values.medical_record_created","translations":{"audit_trail":{"fr":"Dossier m\u00e9dical cr\u00e9\u00e9","en":"Medical record created"}}};
export const FILTERS_KIND_VALUES_MEDICAL_RECORD_SHARED_INITIATED = {"id":"filters.kind.values.medical_record_shared_initiated","translations":{"audit_trail":{"fr":"Dossier m\u00e9dical en cours de partage","en":"Medical record sharing initiated"}}};
export const FILTERS_KIND_VALUES_MEDICAL_RECORD_SHARED_COMPLETED = {"id":"filters.kind.values.medical_record_shared_completed","translations":{"audit_trail":{"fr":"Dossier m\u00e9dical partag\u00e9","en":"Medical record shared"}}};
export const FILTERS_KIND_VALUES_MEDICAL_RECORD_PATIENT_MARKED_AS_DECEASED = {"id":"filters.kind.values.medical_record_patient_marked_as_deceased","translations":{"audit_trail":{"fr":"Patient d\u00e9c\u00e9d\u00e9","en":"Patient marked as deceased"}}};
export const FILTERS_KIND_VALUES_MEDICAL_RECORD_PATIENT_UN_MARKED_AS_DECEASED = {"id":"filters.kind.values.medical_record_patient_un_marked_as_deceased","translations":{"audit_trail":{"fr":"Patient marqu\u00e9 comme non d\u00e9c\u00e9d\u00e9","en":"Patient unmarked as deceased"}}};
export const FILTERS_KIND_VALUES_MEDICAL_RECORD_SOCIAL_SECURITY_NUMBER_UPDATED = {"id":"filters.kind.values.medical_record_social_security_number_updated","translations":{"audit_trail":{"fr":"N\u00b0 de s\u00e9curit\u00e9 sociale mis \u00e0 jour","en":"Social security number updated"}}};
export const FILTERS_KIND_VALUES_MEDICAL_RECORD_PATIENT_MEDICAL_TEAM_MEMBER_ADDED = {"id":"filters.kind.values.medical_record_patient_medical_team_member_added","translations":{"audit_trail":{"fr":"Membre ajout\u00e9 dans l'\u00e9quipe soignante","en":"Member added to care team"}}};
export const FILTERS_KIND_VALUES_MEDICAL_RECORD_PATIENT_MEDICAL_TEAM_MEMBER_REMOVED = {"id":"filters.kind.values.medical_record_patient_medical_team_member_removed","translations":{"audit_trail":{"fr":"Membre retir\u00e9 de l'\u00e9quipe soignante","en":"Member removed from care team"}}};
export const FILTERS_KIND_VALUES_MEDICAL_RECORD_PATIENT_ADDRESS_ADDED = {"id":"filters.kind.values.medical_record_patient_address_added","translations":{"audit_trail":{"fr":"Adresse ajout\u00e9e","en":"Address added"}}};
export const FILTERS_KIND_VALUES_MEDICAL_RECORD_PATIENT_ADDRESS_UPDATED = {"id":"filters.kind.values.medical_record_patient_address_updated","translations":{"audit_trail":{"fr":"Adresse modifi\u00e9e","en":"Address updated"}}};
export const FILTERS_KIND_VALUES_MEDICAL_RECORD_PATIENT_NOTE_CREATED = {"id":"filters.kind.values.medical_record_patient_note_created","translations":{"audit_trail":{"fr":"Note cr\u00e9\u00e9e","en":"Note created"}}};
export const FILTERS_KIND_VALUES_MEDICAL_RECORD_PATIENT_NOTE_UPDATED = {"id":"filters.kind.values.medical_record_patient_note_updated","translations":{"audit_trail":{"fr":"Note modifi\u00e9e","en":"Note updated"}}};
export const FILTERS_KIND_VALUES_MEDICAL_RECORD_PATIENT_NOTE_DELETED = {"id":"filters.kind.values.medical_record_patient_note_deleted","translations":{"audit_trail":{"fr":"Note supprim\u00e9e","en":"Note deleted"}}};
export const FILTERS_KIND_VALUES_MEDICAL_RECORD_PATIENT_DISEASE_CREATED = {"id":"filters.kind.values.medical_record_patient_disease_created","translations":{"audit_trail":{"fr":"Maladie ajout\u00e9e","en":"Disease added"}}};
export const FILTERS_KIND_VALUES_MEDICAL_RECORD_PATIENT_DISEASE_FINISHED = {"id":"filters.kind.values.medical_record_patient_disease_finished","translations":{"audit_trail":{"fr":"Maladie termin\u00e9e","en":"Disease finished"}}};
export const FILTERS_KIND_VALUES_MEDICAL_RECORD_PATIENT_MEDICAL_BACKGROUND_CREATED = {"id":"filters.kind.values.medical_record_patient_medical_background_created","translations":{"audit_trail":{"fr":"Ant\u00e9c\u00e9dent cr\u00e9\u00e9","en":"Medical background created"}}};
export const FILTERS_KIND_VALUES_USER_CREATED = {"id":"filters.kind.values.user_created","translations":{"audit_trail":{"fr":"Utilisateur cr\u00e9\u00e9","en":"User created"}}};
export const FILTERS_KIND_VALUES_USER_ARCHIVED = {"id":"filters.kind.values.user_archived","translations":{"audit_trail":{"fr":"Utilisateur archiv\u00e9","en":"User archived"}}};
export const FILTERS_KIND_VALUES_USER_UN_ARCHIVED = {"id":"filters.kind.values.user_un_archived","translations":{"audit_trail":{"fr":"Utilisateur d\u00e9sarchiv\u00e9","en":"User unarchived"}}};
export const FILTERS_KIND_VALUES_USER_SOFT_DELETED = {"id":"filters.kind.values.user_soft_deleted","translations":{"audit_trail":{"fr":"Utilisateur marqu\u00e9 comme supprim\u00e9","en":"User marked as deleted"}}};
export const FILTERS_KIND_VALUES_USER_DELETED = {"id":"filters.kind.values.user_deleted","translations":{"audit_trail":{"fr":"Utilisateur supprim\u00e9 d\u00e9finitivement","en":"User permanently deleted"}}};
export const FILTERS_KIND_VALUES_USER_MERGED = {"id":"filters.kind.values.user_merged","translations":{"audit_trail":{"fr":"Utilisateur fusionn\u00e9","en":"User merged"}}};
export const FILTERS_KIND_VALUES_USER_EMAIL_UPDATED = {"id":"filters.kind.values.user_email_updated","translations":{"audit_trail":{"fr":"Email mis \u00e0 jour","en":"Email updated"}}};
export const FILTERS_KIND_VALUES_DOCUMENT_CREATED = {"id":"filters.kind.values.document_created","translations":{"audit_trail":{"fr":"Document cr\u00e9\u00e9","en":"Document created"}}};
export const FILTERS_KIND_VALUES_DOCUMENT_VISIBILITY_UPDATED = {"id":"filters.kind.values.document_visibility_updated","translations":{"audit_trail":{"fr":"Visibilit\u00e9 du document mise \u00e0 jour","en":"Document visibility updated"}}};
export const FILTERS_KIND_VALUES_DOCUMENT_TYPE_UPDATED = {"id":"filters.kind.values.document_type_updated","translations":{"audit_trail":{"fr":"Type du document mis \u00e0 jour","en":"Document type updated"}}};
export const FILTERS_KIND_VALUES_DOCUMENT_DELETED = {"id":"filters.kind.values.document_deleted","translations":{"audit_trail":{"fr":"Document supprim\u00e9","en":"Document deleted"}}};
export const FILTERS_KIND_VALUES_DOCUMENT_TEMPLATE_CREATED = {"id":"filters.kind.values.document_template_created","translations":{"audit_trail":{"fr":"Mod\u00e8le de document cr\u00e9\u00e9","en":"Document template created"}}};
export const FILTERS_KIND_VALUES_DOCUMENT_TEMPLATE_UPDATED = {"id":"filters.kind.values.document_template_updated","translations":{"audit_trail":{"fr":"Mod\u00e8le de document modifi\u00e9","en":"Document template updated"}}};
export const FILTERS_KIND_VALUES_DOCUMENT_TEMPLATE_ARCHIVED = {"id":"filters.kind.values.document_template_archived","translations":{"audit_trail":{"fr":"Mod\u00e8le de document archiv\u00e9","en":"Document template archived"}}};
export const FILTERS_KIND_VALUES_DOCUMENT_TEMPLATE_UN_ARCHIVED = {"id":"filters.kind.values.document_template_un_archived","translations":{"audit_trail":{"fr":"Mod\u00e8le de document d\u00e9sarchiv\u00e9","en":"Document template unarchived"}}};
export const FILTERS_KIND_VALUES_DOCUMENT_TEMPLATE_DELETED = {"id":"filters.kind.values.document_template_deleted","translations":{"audit_trail":{"fr":"Mod\u00e8le de document supprim\u00e9","en":"Document template deleted"}}};
export const FILTERS_KIND_VALUES_DOCUMENT_MEDICAL_DOCUMENT_CREATED = {"id":"filters.kind.values.document_medical_document_created","translations":{"audit_trail":{"fr":"Document m\u00e9dical cr\u00e9\u00e9","en":"Medical document created"}}};
export const FILTERS_KIND_VALUES_DOCUMENT_MEDICAL_DOCUMENT_UPDATED = {"id":"filters.kind.values.document_medical_document_updated","translations":{"audit_trail":{"fr":"Document m\u00e9dical modifi\u00e9","en":"Medical document updated"}}};
export const FILTERS_KIND_VALUES_DOCUMENT_MEDICAL_DOCUMENT_RENEWED = {"id":"filters.kind.values.document_medical_document_renewed","translations":{"audit_trail":{"fr":"Document m\u00e9dical renouvel\u00e9","en":"Medical document renewed"}}};
export const FILTERS_KIND_VALUES_DOCUMENT_MEDICAL_DOCUMENT_DELETED = {"id":"filters.kind.values.document_medical_document_deleted","translations":{"audit_trail":{"fr":"Document m\u00e9dical supprim\u00e9","en":"Medical document deleted"}}};
export const FILTERS_KIND_VALUES_SELF_EVALUATION_QUESTIONNAIRE_ANSWERED = {"id":"filters.kind.values.self_evaluation_questionnaire_answered","translations":{"audit_trail":{"fr":"Auto-\u00e9valuation compl\u00e9t\u00e9e","en":"patient reported outcome completed"}}};
export const FILTERS_KIND_VALUES_SELF_EVALUATION_QUESTIONNAIRE_REVIEWED = {"id":"filters.kind.values.self_evaluation_questionnaire_reviewed","translations":{"audit_trail":{"fr":"Auto-\u00e9valuation r\u00e9vis\u00e9e","en":"patient reported outcome reviewed"}}};
export const FILTERS_KIND_VALUES_DISCUSSION_CREATED = {"id":"filters.kind.values.discussion_created","translations":{"audit_trail":{"fr":"Discussion cr\u00e9\u00e9e","en":"Conversation created"}}};
export const FILTERS_KIND_VALUES_DISCUSSION_ARCHIVED = {"id":"filters.kind.values.discussion_archived","translations":{"audit_trail":{"fr":"Discussion archiv\u00e9e","en":"Conversation archived"}}};
export const FILTERS_KIND_VALUES_DISCUSSION_UN_ARCHIVED = {"id":"filters.kind.values.discussion_un_archived","translations":{"audit_trail":{"fr":"Discussion d\u00e9sarchiv\u00e9e","en":"Conversation unarchived"}}};
export const FILTERS_KIND_VALUES_DISCUSSION_MESSAGE_SENT = {"id":"filters.kind.values.discussion_message_sent","translations":{"audit_trail":{"fr":"Message envoy\u00e9","en":"Message sent"}}};
export const FILTERS_KIND_VALUES_DISCUSSION_MESSAGE_DELETED = {"id":"filters.kind.values.discussion_message_deleted","translations":{"audit_trail":{"fr":"Message supprim\u00e9","en":"Message deleted"}}};
export const FILTERS_KIND_VALUES_DISCUSSION_MESSAGE_PATIENT_TAGGED = {"id":"filters.kind.values.discussion_message_patient_tagged","translations":{"audit_trail":{"fr":"Message avec tag patient envoy\u00e9","en":"Message with patient tag sent"}}};
export const FILTERS_KIND_VALUES_DISCUSSION_DELETED = {"id":"filters.kind.values.discussion_deleted","translations":{"audit_trail":{"fr":"Discussion supprim\u00e9e","en":"Conversation deleted"}}};
export const FILTERS_KIND_VALUES_DISCUSSION_PARTICIPANT_ADDED = {"id":"filters.kind.values.discussion_participant_added","translations":{"audit_trail":{"fr":"Participant ajout\u00e9 \u00e0 la discussion","en":"Participant added to the conversation"}}};
export const FILTERS_KIND_VALUES_DISCUSSION_PARTICIPANT_REMOVED = {"id":"filters.kind.values.discussion_participant_removed","translations":{"audit_trail":{"fr":"Participant supprim\u00e9 de la discussion","en":"Participant removed from the conversation"}}};
export const FILTERS_KIND_VALUES_EVENT_CREATED = {"id":"filters.kind.values.event_created","translations":{"audit_trail":{"fr":"Ev\u00e9nement cr\u00e9\u00e9","en":"Appointment created"}}};
export const FILTERS_KIND_VALUES_EVENT_DATE_UPDATED = {"id":"filters.kind.values.event_date_updated","translations":{"audit_trail":{"fr":"Date de l'\u00e9v\u00e9nement modifi\u00e9e","en":"Appointment date modified"}}};
export const FILTERS_KIND_VALUES_EVENT_DELETED = {"id":"filters.kind.values.event_deleted","translations":{"audit_trail":{"fr":"Ev\u00e9nement supprim\u00e9","en":"Appointment deleted"}}};
export const FILTERS_KIND_VALUES_CONSULTATION_CREATED = {"id":"filters.kind.values.consultation_created","translations":{"audit_trail":{"fr":"Consultation cr\u00e9\u00e9e","en":"Consultation created"}}};
export const FILTERS_KIND_VALUES_CONSULTATION_QUESTIONNAIRE_ANSWERED = {"id":"filters.kind.values.consultation_questionnaire_answered","translations":{"audit_trail":{"fr":"Questionnaire de consultation compl\u00e9t\u00e9","en":"Consultation questionnaire completed"}}};
export const FILTERS_KIND_VALUES_CONSULTATION_NOTE_CREATED = {"id":"filters.kind.values.consultation_note_created","translations":{"audit_trail":{"fr":"Note de consultation cr\u00e9\u00e9e","en":"Consultation note created"}}};
export const FILTERS_KIND_VALUES_CONSULTATION_NOTE_UPDATED = {"id":"filters.kind.values.consultation_note_updated","translations":{"audit_trail":{"fr":"Note de consultation mise \u00e0 jour","en":"Consultation note updated"}}};
export const FILTERS_KIND_VALUES_CONSULTATION_NOTE_DELETED = {"id":"filters.kind.values.consultation_note_deleted","translations":{"audit_trail":{"fr":"Note de consultation supprim\u00e9e","en":"Consultation note deleted"}}};
export const FILTERS_PERFORMER_ROLE_LABEL = {"id":"filters.performerRole.label","translations":{"audit_trail":{"fr":"R\u00f4le du performer","en":"Performer role"}}};
export const FILTERS_ROLES_VALUES_ADMIN = {"id":"filters.roles.values.admin","translations":{"audit_trail":{"fr":"Administrateur","en":"Administrator"}}};
export const FILTERS_ROLES_VALUES_ASSISTANT = {"id":"filters.roles.values.assistant","translations":{"audit_trail":{"fr":"Secr\u00e9taire","en":"Secretary"}}};
export const FILTERS_ROLES_VALUES_COORDINATOR = {"id":"filters.roles.values.coordinator","translations":{"audit_trail":{"fr":"Coordinateur m\u00e9dical","en":"Medical coordinator"}}};
export const FILTERS_ROLES_VALUES_DEVELOPER = {"id":"filters.roles.values.developer","translations":{"audit_trail":{"fr":"\u00c9quipe technique Instamed","en":"Instamed technical team"}}};
export const FILTERS_ROLES_VALUES_DOCTOR = {"id":"filters.roles.values.doctor","translations":{"audit_trail":{"fr":"Docteur","en":"Doctor"}}};
export const FILTERS_ROLES_VALUES_HOSPITAL_ADMIN = {"id":"filters.roles.values.hospital_admin","translations":{"audit_trail":{"fr":"Administrateur H\u00f4pital","en":"Hospital administrator"}}};
export const FILTERS_ROLES_VALUES_INSTITUTION_MANAGER = {"id":"filters.roles.values.institution_manager","translations":{"audit_trail":{"fr":"Gestionnaire d'institution","en":"Facility manager"}}};
export const FILTERS_ROLES_VALUES_NURSE = {"id":"filters.roles.values.nurse","translations":{"audit_trail":{"fr":"Param\u00e9dical","en":"Paramedic"}}};
export const FILTERS_ROLES_VALUES_PATIENT = {"id":"filters.roles.values.patient","translations":{"audit_trail":{"fr":"Patient","en":"Patient"}}};
export const FILTERS_TARGET_ROLE_LABEL = {"id":"filters.targetRole.label","translations":{"audit_trail":{"fr":"R\u00f4le de la cible","en":"Target role"}}};
export const VALUE_UNKNOWN = {"id":"value.unknown","translations":{"audit_trail":{"fr":"Inconnu","en":"Unknown"}}};
export const VALUE_EMPTY = {"id":"value.empty","translations":{"audit_trail":{"fr":"Vide","en":"Empty"}}};
export const VALUE_YES = {"id":"value.yes","translations":{"audit_trail":{"fr":"Oui","en":"Yes"}}};
export const VALUE_NO = {"id":"value.no","translations":{"audit_trail":{"fr":"Non","en":"No"}}};
export const RECURRING_END_TYPE_DESCRIPTION = {"id":"recurring_end_type.description","translations":{"availability":{"fr":"Le type de fin de r\u00e9currence de la plage de disponibilit\u00e9","en":"Recurring end type of availability slot"},"event":{"fr":"Le type de fin de r\u00e9currence de l'\u00e9v\u00e9nement","en":"The type of appointment recurrence end"}}};
export const RECURRING_END_TYPE_LABEL = {"id":"recurring_end_type.label","translations":{"availability":{"fr":"Type de fin de r\u00e9currence","en":"Recurring end type"},"event":{"fr":"Type de fin de r\u00e9currence","en":"Recurrence end type"}}};
export const RECURRING_END_TYPE_PLACEHOLDER = {"id":"recurring_end_type.placeholder","translations":{"availability":{"fr":"S\u00e9lectionnez un type de fin de r\u00e9currence","en":"Select recurring end type"},"event":{"fr":"S\u00e9lectionnez un type de fin de r\u00e9currence","en":"Select recurrence end type"}}};
export const RECURRING_END_TYPE_END_DATE = {"id":"recurring_end_type.endDate","translations":{"availability":{"fr":"Date de fin","en":"End date"}}};
export const RECURRING_END_TYPE_AFTER = {"id":"recurring_end_type.after","translations":{"availability":{"fr":"Apr\u00e8s","en":"After"}}};
export const RECURRING_END_TYPE_NEVER = {"id":"recurring_end_type.never","translations":{"availability":{"fr":"Jamais","en":"Never"}}};
export const RECURRING_DAYS_DESCRIPTION = {"id":"recurring_days.description","translations":{"availability":{"fr":"Les jours de la semaine de la plage de disponibilit\u00e9","en":"The week days of availability range"},"event":{"fr":"Le(s) jour(s) de r\u00e9currence de l'\u00e9v\u00e9nement","en":"The days of appointment recurrence"}}};
export const RECURRING_DAYS_LABEL = {"id":"recurring_days.label","translations":{"availability":{"fr":"Jours de r\u00e9currence","en":"Recurrence days"},"event":{"fr":"Jour(s) de r\u00e9currence","en":"Days of recurrence"}}};
export const RECURRING_DAYS_PLACEHOLDER = {"id":"recurring_days.placeholder","translations":{"availability":{"fr":"S\u00e9lectionnez un jour de r\u00e9currence","en":"Select recurrence day"},"event":{"fr":"S\u00e9lectionnez le(s) jour(s) de r\u00e9currence","en":"Select days of recurrence"}}};
export const RECURRING_DAYS_VALUES1 = {"id":"recurring_days.values.1","translations":{"availability":{"fr":"Lundi","en":"Monday"},"event":{"fr":"Lundi","en":"Monday"}}};
export const RECURRING_DAYS_VALUES2 = {"id":"recurring_days.values.2","translations":{"availability":{"fr":"Mardi","en":"Tuesday"},"event":{"fr":"Mardi","en":"Tuesday"}}};
export const RECURRING_DAYS_VALUES3 = {"id":"recurring_days.values.3","translations":{"availability":{"fr":"Mercredi","en":"Wednesday"},"event":{"fr":"Mercredi","en":"Wednesday"}}};
export const RECURRING_DAYS_VALUES4 = {"id":"recurring_days.values.4","translations":{"availability":{"fr":"Jeudi","en":"Thursday"},"event":{"fr":"Jeudi","en":"Thursday"}}};
export const RECURRING_DAYS_VALUES5 = {"id":"recurring_days.values.5","translations":{"availability":{"fr":"Vendredi","en":"Friday"},"event":{"fr":"Vendredi","en":"Friday"}}};
export const RECURRING_DAYS_VALUES6 = {"id":"recurring_days.values.6","translations":{"availability":{"fr":"Samedi","en":"Saturday"},"event":{"fr":"Samedi","en":"Saturday"}}};
export const RECURRING_DAYS_VALUES7 = {"id":"recurring_days.values.7","translations":{"availability":{"fr":"Dimanche","en":"Sunday"},"event":{"fr":"Dimanche","en":"Sunday"}}};
export const COLOR_DESCRIPTION = {"id":"color.description","translations":{"availability":{"fr":"La couleur de la plage de disponibilit\u00e9","en":"Availability slot color"},"event_type":{"fr":"La couleur du motif d'\u00e9v\u00e9nement","en":"The appointment type color"},"medical_record_section":{"fr":"La couleur de l'icon de la section","en":"The color of section icon"}}};
export const COLOR_LABEL = {"id":"color.label","translations":{"availability":{"fr":"Couleur","en":"Color"},"event_type":{"fr":"Couleur","en":"Color"},"medical_record_section":{"fr":"Couleur","en":"Color"}}};
export const COLOR_PLACEHOLDER = {"id":"color.placeholder","translations":{"availability":{"fr":"S\u00e9lectionnez une couleur","en":"Select color"},"event_type":{"fr":"S\u00e9lectionnez une couleur","en":"Select color"},"medical_record_section":{"fr":"Choisissez une couleur...","en":"Select color..."}}};
export const COLOR_FFFFFF = {"id":"color.#FFFFFF","translations":{"availability":{"fr":"Blanc","en":"White"}}};
export const COLOR_F85656 = {"id":"color.#F85656","translations":{"availability":{"fr":"Rouge","en":"Red"}}};
export const COLOR_F9_A05_F = {"id":"color.#F9A05F","translations":{"availability":{"fr":"Orange","en":"Orange"}}};
export const COLOR_E29_AD2 = {"id":"color.#E29AD2","translations":{"availability":{"fr":"Rose","en":"Pink"}}};
export const COLOR_F3_A7_AB = {"id":"color.#F3A7AB","translations":{"availability":{"fr":"Rose saumon","en":"Salmon pink"}}};
export const COLOR_FFD54_C = {"id":"color.#FFD54C","translations":{"availability":{"fr":"Jaune","en":"Yellow"}}};
export const COLOR89_C23_C = {"id":"color.#89C23C","translations":{"availability":{"fr":"Vert","en":"Green"}}};
export const COLOR63_CC67 = {"id":"color.#63CC67","translations":{"availability":{"fr":"Vert menthe","en":"Mint green"}}};
export const COLOR69_E1_C1 = {"id":"color.#69E1C1","translations":{"availability":{"fr":"Turquoise","en":"Turquoise"}}};
export const COLOR82_DAED = {"id":"color.#82DAED","translations":{"availability":{"fr":"Bleu ciel","en":"Sky blue"}}};
export const COLOR7_CBDFA = {"id":"color.#7CBDFA","translations":{"availability":{"fr":"Bleu","en":"Blue"}}};
export const COLOR172_DC2 = {"id":"color.#172DC2","translations":{"availability":{"fr":"Bleu indigo","en":"Indigo blue"}}};
export const COLOR_B988_F2 = {"id":"color.#B988F2","translations":{"availability":{"fr":"Lavande","en":"Lavender"}}};
export const COLOR_AD2_AB9 = {"id":"color.#AD2AB9","translations":{"availability":{"fr":"Violet","en":"Purple"}}};
export const COLOR6_D3_DE1 = {"id":"color.#6D3DE1","translations":{"availability":{"fr":"Violet fonc\u00e9","en":"Dark violet"}}};
export const SCALE_LATERALITY = {"id":"scale.laterality","translations":{"cmi11":{"fr":"Lat\u00e9ralit\u00e9","en":"Laterality"}}};
export const SCALE_SPECIFIC_ANATOMY = {"id":"scale.specificAnatomy","translations":{"cmi11":{"fr":"Anatomie sp\u00e9cifique","en":"Specific anatomy"}}};
export const SCALE_HISTOPATHOLOGY = {"id":"scale.histopathology","translations":{"cmi11":{"fr":"Histo-pathologie","en":"Histopathology"}}};
export const SCALE_HAS_MANIFESTATION = {"id":"scale.hasManifestation","translations":{"cmi11":{"fr":"A une manifestation","en":"Has a manifestation"}}};
export const SCALE_HAS_SEVERITY = {"id":"scale.hasSeverity","translations":{"cmi11":{"fr":"Stade","en":"Stage"}}};
export const SCALE_HAS_ALTERNATIVE_SEVERITY1 = {"id":"scale.hasAlternativeSeverity1","translations":{"cmi11":{"fr":"Gravit\u00e9","en":"Severity"}}};
export const SCALE_HAS_ALTERNATIVE_SEVERITY2 = {"id":"scale.hasAlternativeSeverity2","translations":{"cmi11":{"fr":"\u00c9tat","en":"Status"}}};
export const SCALE_INFECTIOUS_AGENT = {"id":"scale.infectiousAgent","translations":{"cmi11":{"fr":"Agent infectieux","en":"Infectious agent"}}};
export const SCALE_ASSOCIATED_WITH = {"id":"scale.associatedWith","translations":{"cmi11":{"fr":"Associ\u00e9 \u00e0","en":"Associated to"}}};
export const COMMON_ACTIONS_TITLE = {"id":"common.actions.title","translations":{"common":{"fr":"Actions","en":"Actions"}}};
export const COMMON_ACTIONS_CANCEL = {"id":"common.actions.cancel","translations":{"common":{"fr":"Annuler","en":"Cancel"}}};
export const COMMON_ACTIONS_ADD_TO_QUEUE = {"id":"common.actions.add_to_queue","translations":{"common":{"fr":"Ajouter \u00e0 la file d'attente","en":"Add to queue"}}};
export const COMMON_ACTIONS_VALIDATE = {"id":"common.actions.validate","translations":{"common":{"fr":"Valider","en":"Validate"}}};
export const COMMON_ACTIONS_INVITE = {"id":"common.actions.invite","translations":{"common":{"fr":"Inviter","en":"Invite"}}};
export const COMMON_ACTIONS_DECLINE = {"id":"common.actions.decline","translations":{"common":{"fr":"D\u00e9cliner","en":"Decline"}}};
export const COMMON_ACTIONS_ACCEPT = {"id":"common.actions.accept","translations":{"common":{"fr":"Accepter","en":"Accept"}}};
export const COMMON_ACTIONS_SHARE = {"id":"common.actions.share","translations":{"common":{"fr":"Partager","en":"Share"}}};
export const COMMON_ACTIONS_CONTINUE = {"id":"common.actions.continue","translations":{"common":{"fr":"Continuer","en":"Continue"}}};
export const COMMON_ACTIONS_PASS = {"id":"common.actions.pass","translations":{"common":{"fr":"Passer","en":"Skip"}}};
export const COMMON_ACTIONS_SEND = {"id":"common.actions.send","translations":{"common":{"fr":"Envoyer","en":"Send"}}};
export const COMMON_ACTIONS_LOAD_MORE = {"id":"common.actions.load_more","translations":{"common":{"fr":"Charger plus","en":"Load more"}}};
export const COMMON_ACTIONS_FILTER = {"id":"common.actions.filter","translations":{"common":{"fr":"Filtrer","en":"Filter"}}};
export const COMMON_ACTIONS_SEE = {"id":"common.actions.see","translations":{"common":{"fr":"Voir","en":"See"}}};
export const COMMON_ACTIONS_PRINT = {"id":"common.actions.print","translations":{"common":{"fr":"Imprimer","en":"Print"}}};
export const COMMON_ACTIONS_DOWNLOAD = {"id":"common.actions.download","translations":{"common":{"fr":"T\u00e9l\u00e9charger","en":"Download"}}};
export const COMMON_ACTIONS_EDIT = {"id":"common.actions.edit","translations":{"common":{"fr":"Modifier","en":"Edit"}}};
export const COMMON_ACTIONS_DELETE = {"id":"common.actions.delete","translations":{"common":{"fr":"Supprimer","en":"Delete"}}};
export const COMMON_ACTIONS_SEARCH = {"id":"common.actions.search","translations":{"common":{"fr":"Rechercher","en":"Search"}}};
export const COMMON_ACTIONS_FUSION = {"id":"common.actions.fusion","translations":{"common":{"fr":"Fusionner","en":"Merge"}}};
export const COMMON_ACTIONS_ENABLE = {"id":"common.actions.enable","translations":{"common":{"fr":"Activer","en":"Enable"}}};
export const COMMON_ACTIONS_DISABLED = {"id":"common.actions.disabled","translations":{"common":{"fr":"D\u00e9sactiver","en":"Disable"}}};
export const COMMON_ACTIONS_REFRESH = {"id":"common.actions.refresh","translations":{"common":{"fr":"Recharger la page","en":"Reload page"}}};
export const COMMON_ACTIONS_UPDATE = {"id":"common.actions.update","translations":{"common":{"fr":"Mettre \u00e0 jour","en":"Update"}}};
export const COMMON_ACTIONS_ADD = {"id":"common.actions.add","translations":{"common":{"fr":"Ajouter","en":"Add"}}};
export const COMMON_ACTIONS_CLOSE = {"id":"common.actions.close","translations":{"common":{"fr":"Fermer","en":"Close"}}};
export const COMMON_ACTIONS_SHOW = {"id":"common.actions.show","translations":{"common":{"fr":"Afficher","en":"View"}}};
export const COMMON_ACTIONS_ANSWER = {"id":"common.actions.answer","translations":{"common":{"fr":"R\u00e9pondre","en":"Answer"}}};
export const COMMON_ACTIONS_UNLOCK = {"id":"common.actions.unlock","translations":{"common":{"fr":"D\u00e9verrouiller","en":"Unlock"}}};
export const COMMON_ACTIONS_SEE_PROFILE = {"id":"common.actions.see_profile","translations":{"common":{"fr":"Voir le profil","en":"View profile"}}};
export const COMMON_ACTIONS_REFUSE = {"id":"common.actions.refuse","translations":{"common":{"fr":"Refuser","en":"Refuse"}}};
export const COMMON_ACTIONS_USE = {"id":"common.actions.use","translations":{"common":{"fr":"Utiliser","en":"Use"}}};
export const COMMON_ACTIONS_SAVE = {"id":"common.actions.save","translations":{"common":{"fr":"Enregistrer","en":"Save"}}};
export const COMMON_ACTIONS_START = {"id":"common.actions.start","translations":{"common":{"fr":"Commencer","en":"Start"}}};
export const COMMON_ACTIONS_LOG_IN = {"id":"common.actions.log_in","translations":{"common":{"fr":"Se connecter","en":"Log in"}}};
export const COMMON_ACTIONS_LOG_OFF = {"id":"common.actions.log_off","translations":{"common":{"fr":"Se d\u00e9connecter","en":"Logout"}}};
export const COMMON_ACTIONS_SUBSCRIBE = {"id":"common.actions.subscribe","translations":{"common":{"fr":"S'abonner","en":"Subscribe"}}};
export const COMMON_ACTIONS_CHARGE = {"id":"common.actions.charge","translations":{"common":{"fr":"Charger","en":"Load"}}};
export const COMMON_ACTIONS_DUPLICATE = {"id":"common.actions.duplicate","translations":{"common":{"fr":"Dupliquer","en":"Duplicate"}}};
export const COMMON_ACTIONS_ARCHIVE = {"id":"common.actions.archive","translations":{"common":{"fr":"Archiver","en":"Archive"}}};
export const COMMON_ACTIONS_DISARCHIVE = {"id":"common.actions.disarchive","translations":{"common":{"fr":"D\u00e9sarchiver","en":"Unarchive"}}};
export const COMMON_ACTIONS_SUBMIT = {"id":"common.actions.submit","translations":{"common":{"fr":"Soumettre","en":"Submit"}}};
export const COMMON_ACTIONS_SYNC = {"id":"common.actions.sync","translations":{"common":{"fr":"Synchroniser","en":"Synchronize"}}};
export const COMMON_ACTIONS_ERASE = {"id":"common.actions.erase","translations":{"common":{"fr":"Effacer","en":"Erase"}}};
export const COMMON_ACTIONS_RESET = {"id":"common.actions.reset","translations":{"common":{"fr":"R\u00e9initialiser","en":"Reset"}}};
export const COMMON_ACTIONS_IGNORE = {"id":"common.actions.ignore","translations":{"common":{"fr":"Ignorer","en":"Ignore"}}};
export const COMMON_ACTIONS_SEND_MESSAGE = {"id":"common.actions.send_message","translations":{"common":{"fr":"Envoyer un message","en":"Send message"}}};
export const COMMON_ACTIONS_MODIFY_RIGHTS = {"id":"common.actions.modify_rights","translations":{"common":{"fr":"Modifier les droits","en":"Modify rights"}}};
export const COMMON_ACTIONS_BACK_TO_LIST = {"id":"common.actions.back_to_list","translations":{"common":{"fr":"Retour \u00e0 la liste","en":"Back to list"}}};
export const COMMON_ACTIONS_DELETE_IMAGE = {"id":"common.actions.delete_image","translations":{"common":{"fr":"Supprimer l'image","en":"Delete image"}}};
export const COMMON_ACTIONS_SEE_DETAILS = {"id":"common.actions.see_details","translations":{"common":{"fr":"Voir le d\u00e9tail","en":"View details"}}};
export const COMMON_ACTIONS_HIDE_DETAILS = {"id":"common.actions.hide_details","translations":{"common":{"fr":"Masquer le d\u00e9tail","en":"Hide details"}}};
export const COMMON_ACTIONS_ACTIVATE = {"id":"common.actions.activate","translations":{"common":{"fr":"Activer","en":"Activate"}}};
export const COMMON_ACTIONS_VIEW = {"id":"common.actions.view","translations":{"common":{"fr":"View","en":"View"}}};
export const COMMON_ACTIONS_DISABLE = {"id":"common.actions.disable","translations":{"common":{"fr":"D\u00e9sactiver","en":"Disable"}}};
export const COMMON_ACTIONS_SORT_BY = {"id":"common.actions.sort_by","translations":{"common":{"fr":"Trier par","en":"Sort by"}}};
export const COMMON_ACTIONS_SEARCH_BY_NAME = {"id":"common.actions.search_by_name","translations":{"common":{"fr":"Rechercher par nom ou pr\u00e9nom","en":"Search by name or first name"}}};
export const COMMON_ACTIONS_COPY_LINK = {"id":"common.actions.copy_link","translations":{"common":{"fr":"Copier le lien","en":"Copy link"}}};
export const COMMON_ACTIONS_SEE_FILE = {"id":"common.actions.see_file","translations":{"common":{"fr":"Voir le fichier","en":"View file"}}};
export const COMMON_ACTIONS_SELECT_CHOICE = {"id":"common.actions.select_choice","translations":{"common":{"fr":"S\u00e9lectionnez un choix dans la liste","en":"Select a choice from the list"}}};
export const COMMON_ACTIONS_SELECT_SEVERAL_CHOICES = {"id":"common.actions.select_several_choices","translations":{"common":{"fr":"S\u00e9lectionnez un ou plusieurs choix dans la liste","en":"Select one or more choices from the list"}}};
export const COMMON_ACTIONS_GENERATE_LINK = {"id":"common.actions.generate_link","translations":{"common":{"fr":"G\u00e9n\u00e9rer le lien","en":"Generate link"}}};
export const COMMON_ACTIONS_SELECT_ANSWER = {"id":"common.actions.select_answer","translations":{"common":{"fr":"Choisir une r\u00e9ponse","en":"Choose answer"}}};
export const COMMON_ACTIONS_SELECT_VALUE = {"id":"common.actions.select_value","translations":{"common":{"fr":"Choisissez une valeur","en":"Choose value"}}};
export const COMMON_ACTIONS_ACCEPT_ALL = {"id":"common.actions.accept_all","translations":{"common":{"fr":"Tout accepter","en":"Accept all"}}};
export const COMMON_ACTIONS_CLOSE_WITHOUT_EDIT = {"id":"common.actions.close_without_edit","translations":{"common":{"fr":"Fermer sans modifier","en":"Close without editing"}}};
export const COMMON_ACTIONS_ADD_NOTE = {"id":"common.actions.add_note","translations":{"common":{"fr":"Ajouter une note","en":"Add note"}}};
export const COMMON_ACTIONS_SHARE_NOW = {"id":"common.actions.share_now","translations":{"common":{"fr":"Partager maintenant","en":"Share now"}}};
export const COMMON_TITLES_WARNING = {"id":"common.titles.warning","translations":{"common":{"fr":"Attention!","en":"Warning!"}}};
export const COMMON_TITLES_SEARCH = {"id":"common.titles.search","translations":{"common":{"fr":"Recherche","en":"Search"}}};
export const COMMON_MESSAGE_IMPOSSIBLE_ACTION = {"id":"common.message.impossible_action","translations":{"common":{"fr":"Action impossible","en":"Impossible action"}}};
export const COMMON_MESSAGE_UPDATE_DONE = {"id":"common.message.update_done","translations":{"common":{"fr":"La modification a bien \u00e9t\u00e9 effectu\u00e9e","en":"The modification has been completed"}}};
export const COMMON_MESSAGE_ERROR_WITH_MESSAGE = {"id":"common.message.error_with_message","translations":{"common":{"fr":"Erreur: $message","en":"Error: $message"}}};
export const COMMON_MESSAGE_EXPIRED_LINK = {"id":"common.message.expired_link","translations":{"common":{"fr":"Votre lien a expir\u00e9","en":"Your link has expired"}}};
export const COMMON_MESSAGE_FILE_NOT_FOUND = {"id":"common.message.file_not_found","translations":{"common":{"fr":"Fichier introuvable.","en":"File not found."}}};
export const COMMON_MESSAGE_ACCESS_REFUSED = {"id":"common.message.access_refused","translations":{"common":{"fr":"L'acc\u00e8s \u00e0 cette page est refus\u00e9.","en":"Access to this page is denied."}}};
export const COMMON_MESSAGE_PAYMENT_ERROR = {"id":"common.message.payment_error","translations":{"common":{"fr":"Une erreur est survenue lors de votre paiement, veuillez r\u00e9essayer \u00e0 nouveau.","en":"An error occurred during your payment, please try again."}}};
export const COMMON_MESSAGE_INCORRECT_LINK = {"id":"common.message.incorrect_link","translations":{"common":{"fr":"Ce lien est incorrect, veuillez retourner sur votre page d'accueil.","en":"This link is incorrect, please return to your homepage."}}};
export const COMMON_MESSAGE_COMPUTER_OF = {"id":"common.message.computer_of","translations":{"common":{"fr":"Ordinateur de ","en":"Computer of "}}};
export const COMMON_MESSAGE_NO_RESULTS = {"id":"common.message.no_results","translations":{"common":{"fr":"Aucun r\u00e9sultat trouv\u00e9","en":"No results found"}}};
export const COMMON_MESSAGE_UNKNOW_ERROR = {"id":"common.message.unknow_error","translations":{"common":{"fr":"Erreur inconnue. Veuillez contacter un administrateur avec cet identifiant : $id ","en":"Unknown error. Please contact an administrator with this ID: $id "}}};
export const COMMON_MESSAGE_MISSING_INFO = {"id":"common.message.missing_info","translations":{"common":{"fr":"Informations manquantes","en":"Missing information"}}};
export const COMMON_WORDS_INSTAMED = {"id":"common.words.instamed","translations":{"common":{"fr":"Instamed","en":"Instamed"}}};
export const COMMON_WORDS_ACTION = {"id":"common.words.action","translations":{"common":{"fr":"Action","en":"Action"}}};
export const COMMON_WORDS_WITH = {"id":"common.words.with","translations":{"common":{"fr":"avec","en":"with"}}};
export const COMMON_WORDS_SPACED_WITH = {"id":"common.words.spaced_with","translations":{"common":{"fr":" avec ","en":" with "}}};
export const COMMON_WORDS_DURATION = {"id":"common.words.duration","translations":{"common":{"fr":"Dur\u00e9e","en":"Duration"}}};
export const COMMON_WORDS_MINUTES = {"id":"common.words.minutes","translations":{"common":{"fr":"minutes","en":"minutes"}}};
export const COMMON_WORDS_YEARS = {"id":"common.words.years","translations":{"common":{"fr":"ans","en":"years"}}};
export const COMMON_WORDS_NUMBER_YEARS = {"id":"common.words.number_years","translations":{"common":{"fr":"$number years","en":"$number years"}}};
export const COMMON_WORDS_DATE = {"id":"common.words.date","translations":{"common":{"fr":"Date","en":"Date"}}};
export const COMMON_WORDS_DATES = {"id":"common.words.dates","translations":{"common":{"fr":"Dates","en":"Dates"}}};
export const COMMON_WORDS_LOADING = {"id":"common.words.loading","translations":{"common":{"fr":"Chargement...","en":"Loading..."}}};
export const COMMON_WORDS_NEXT = {"id":"common.words.next","translations":{"common":{"fr":"Suivant","en":"Next"}}};
export const COMMON_WORDS_PREV = {"id":"common.words.prev","translations":{"common":{"fr":"Pr\u00e9c\u00e9dent","en":"Previous"}}};
export const COMMON_WORDS_NEXT_SHORT = {"id":"common.words.next_short","translations":{"common":{"fr":"Suiv>","en":"Next>"}}};
export const COMMON_WORDS_PREV_SHORT = {"id":"common.words.prev_short","translations":{"common":{"fr":"<Pr\u00e9c","en":"<Prev"}}};
export const COMMON_WORDS_NAME = {"id":"common.words.name","translations":{"common":{"fr":"Nom","en":"Name"}}};
export const COMMON_WORDS_MSSANTE = {"id":"common.words.mssante","translations":{"common":{"fr":"MSSant\u00e9","en":"MSSante"}}};
export const COMMON_WORDS_ALL = {"id":"common.words.all","translations":{"common":{"fr":"Tous","en":"All"}}};
export const COMMON_WORDS_ACTIVE = {"id":"common.words.active","translations":{"common":{"fr":"Actif","en":"Active"}}};
export const COMMON_WORDS_ACTIVES = {"id":"common.words.actives","translations":{"common":{"fr":"Actifs","en":"Actives"}}};
export const COMMON_WORDS_ACTIVATED = {"id":"common.words.activated","translations":{"common":{"fr":"Activ\u00e9","en":"Activated"}}};
export const COMMON_WORDS_DISABLED = {"id":"common.words.disabled","translations":{"common":{"fr":"D\u00e9sactiv\u00e9","en":"Disabled"}}};
export const COMMON_WORDS_PARENT = {"id":"common.words.parent","translations":{"common":{"fr":"Parent","en":"Parent"}}};
export const COMMON_WORDS_CANONICAL = {"id":"common.words.canonical","translations":{"common":{"fr":"Canonical","en":"Canonical"}}};
export const COMMON_WORDS_INSTITUTIONS = {"id":"common.words.institutions","translations":{"common":{"fr":"Institutions","en":"Facilities"}}};
export const COMMON_WORDS_MASTER = {"id":"common.words.master","translations":{"common":{"fr":"Ma\u00eetre","en":"Master"}}};
export const COMMON_WORDS_SERVICES = {"id":"common.words.services","translations":{"common":{"fr":"Services","en":"Departments"}}};
export const COMMON_WORDS_STATUS = {"id":"common.words.status","translations":{"common":{"fr":"Status","en":"Status"}}};
export const COMMON_WORDS_ERROR = {"id":"common.words.error","translations":{"common":{"fr":"Erreur","en":"Error"}}};
export const COMMON_WORDS_OK = {"id":"common.words.ok","translations":{"common":{"fr":"Ok","en":"Ok"}}};
export const COMMON_WORDS_QUESTION = {"id":"common.words.question","translations":{"common":{"fr":"Question","en":"Question"}}};
export const COMMON_WORDS_ANSWER = {"id":"common.words.answer","translations":{"common":{"fr":"R\u00e9ponse","en":"Answer"}}};
export const COMMON_WORDS_ANSWERS = {"id":"common.words.answers","translations":{"common":{"fr":"R\u00e9ponses","en":"Answers"}}};
export const COMMON_WORDS_ALERT = {"id":"common.words.alert","translations":{"common":{"fr":"Alerte","en":"Alert"}}};
export const COMMON_WORDS_ALERTS = {"id":"common.words.alerts","translations":{"common":{"fr":"Alertes","en":"Alerts"}}};
export const COMMON_WORDS_USER = {"id":"common.words.user","translations":{"common":{"fr":"Utilisateur","en":"User"}}};
export const COMMON_WORDS_OBJECT = {"id":"common.words.object","translations":{"common":{"fr":"Objet","en":"Object"}}};
export const COMMON_WORDS_PENDING = {"id":"common.words.pending","translations":{"common":{"fr":"En attente","en":"Pending"}}};
export const COMMON_WORDS_ONGOING = {"id":"common.words.ongoing","translations":{"common":{"fr":"En cours","en":"Ongoing"}}};
export const COMMON_WORDS_ACCEPTED = {"id":"common.words.accepted","translations":{"common":{"fr":"Accept\u00e9","en":"Accepted"}}};
export const COMMON_WORDS_REFUSED = {"id":"common.words.refused","translations":{"common":{"fr":"Refus\u00e9","en":"Refused"}}};
export const COMMON_WORDS_EXPIRED = {"id":"common.words.expired","translations":{"common":{"fr":"Expir\u00e9","en":"Expired"}}};
export const COMMON_WORDS_ID = {"id":"common.words.id","translations":{"common":{"fr":"Id","en":"ID"}}};
export const COMMON_WORDS_DOCTOR = {"id":"common.words.doctor","translations":{"common":{"fr":"M\u00e9decin","en":"Doctor"}}};
export const COMMON_WORDS_PATIENT = {"id":"common.words.patient","translations":{"common":{"fr":"Patient","en":"Patient"}}};
export const COMMON_WORDS_PATIENTS = {"id":"common.words.patients","translations":{"common":{"fr":"Patients","en":"Patients"}}};
export const COMMON_WORDS_CARER = {"id":"common.words.carer","translations":{"common":{"fr":"Soignant","en":"Carer"}}};
export const COMMON_WORDS_PARAMEDICAL = {"id":"common.words.paramedical","translations":{"common":{"fr":"Param\u00e9dical","en":"Paramedic"}}};
export const COMMON_WORDS_MEDIC_COORDINATOR = {"id":"common.words.medic_coordinator","translations":{"common":{"fr":"Coordinateur m\u00e9dical","en":"Medical coordinator"}}};
export const COMMON_WORDS_SECRETARY = {"id":"common.words.secretary","translations":{"common":{"fr":"Secr\u00e9taire","en":"Secretary"}}};
export const COMMON_WORDS_AMOUNT = {"id":"common.words.amount","translations":{"common":{"fr":"Montant","en":"Amount"}}};
export const COMMON_WORDS_DESCRIPTION = {"id":"common.words.description","translations":{"common":{"fr":"Description","en":"Description"}}};
export const COMMON_WORDS_ROLE = {"id":"common.words.role","translations":{"common":{"fr":"R\u00f4le","en":"Role"}}};
export const COMMON_WORDS_SURVEY = {"id":"common.words.survey","translations":{"common":{"fr":"Questionnaire","en":"Survey"}}};
export const COMMON_WORDS_COMMENT = {"id":"common.words.comment","translations":{"common":{"fr":"Commentaire","en":"Comment"}}};
export const COMMON_WORDS_DASHBOARD = {"id":"common.words.dashboard","translations":{"common":{"fr":"Tableau de bord","en":"Dashboard"}}};
export const COMMON_WORDS_SYNOPSIS = {"id":"common.words.synopsis","translations":{"common":{"fr":"Synopsis","en":"Synopsis"}}};
export const COMMON_WORDS_OR = {"id":"common.words.or","translations":{"common":{"fr":"ou","en":"or"}}};
export const COMMON_WORDS_CAP_OR = {"id":"common.words.cap_or","translations":{"common":{"fr":"OU","en":"OR"}}};
export const COMMON_WORDS_CAP_AND = {"id":"common.words.cap_and","translations":{"common":{"fr":"ET","en":"AND"}}};
export const COMMON_WORDS_ACTIONS = {"id":"common.words.actions","translations":{"common":{"fr":"Actions","en":"Actions"}}};
export const COMMON_WORDS_YES = {"id":"common.words.yes","translations":{"common":{"fr":"Oui","en":"Yes"}}};
export const COMMON_WORDS_NO = {"id":"common.words.no","translations":{"common":{"fr":"Non","en":"No"}}};
export const COMMON_WORDS_DONT_KNOW = {"id":"common.words.dont_know","translations":{"common":{"fr":"Ne sait pas","en":"Don't know"}}};
export const COMMON_WORDS_REQUIRED_VALUE = {"id":"common.words.required_value","translations":{"common":{"fr":"Valeur requise","en":"Required value"}}};
export const COMMON_WORDS_TITLE = {"id":"common.words.title","translations":{"common":{"fr":"Titre","en":"Title"}}};
export const COMMON_WORDS_CONTENT = {"id":"common.words.content","translations":{"common":{"fr":"Contenu","en":"Content"}}};
export const COMMON_WORDS_CONNEXION = {"id":"common.words.connexion","translations":{"common":{"fr":"Connexion","en":"Connection"}}};
export const COMMON_WORDS_BACK_HOME = {"id":"common.words.back_home","translations":{"common":{"fr":"Retour \u00e0 l'accueil","en":"Back to homepage"}}};
export const COMMON_WORDS_SELECT = {"id":"common.words.select","translations":{"common":{"fr":"Selectionner","en":"Select"}}};
export const COMMON_WORDS_ARCHIVED = {"id":"common.words.archived","translations":{"common":{"fr":"Archiv\u00e9","en":"Archived"}}};
export const COMMON_WORDS_NONE = {"id":"common.words.none","translations":{"common":{"fr":"Aucun","en":"None"}}};
export const COMMON_WORDS_TYPE = {"id":"common.words.type","translations":{"common":{"fr":"Type","en":"Type"}}};
export const COMMON_WORDS_DRUGS = {"id":"common.words.drugs","translations":{"common":{"fr":"M\u00e9dicaments","en":"Drugs"}}};
export const COMMON_WORDS_ADVICES = {"id":"common.words.advices","translations":{"common":{"fr":"Conseils","en":"Advice"}}};
export const COMMON_WORDS_REASON = {"id":"common.words.reason","translations":{"common":{"fr":"Motif","en":"Reason"}}};
export const COMMON_WORDS_ACTUAL_SITUATION = {"id":"common.words.actual_situation","translations":{"common":{"fr":"Situation actuelle","en":"Current situation"}}};
export const COMMON_WORDS_CONCLUSION = {"id":"common.words.conclusion","translations":{"common":{"fr":"Conclusion","en":"Conclusion"}}};
export const COMMON_WORDS_FAVORITE = {"id":"common.words.favorite","translations":{"common":{"fr":"Favoris","en":"Favorites"}}};
export const COMMON_WORDS_DAY = {"id":"common.words.day","translations":{"common":{"fr":"Jour","en":"Day"}}};
export const COMMON_WORDS_MONTH = {"id":"common.words.month","translations":{"common":{"fr":"Mois","en":"Month"}}};
export const COMMON_WORDS_YEAR = {"id":"common.words.year","translations":{"common":{"fr":"Ann\u00e9e","en":"Year"}}};
export const COMMON_WORDS_NUMBER = {"id":"common.words.number","translations":{"common":{"fr":"Num\u00e9ro","en":"Number"}}};
export const COMMON_WORDS_ONSET_DATE = {"id":"common.words.onset_date","translations":{"common":{"fr":"Date de survenue","en":"Onset date"}}};
export const COMMON_WORDS_IF_OTHER = {"id":"common.words.if_other","translations":{"common":{"fr":"Si autre, pr\u00e9cisez","en":"If other, specify"}}};
export const COMMON_WORDS_UNFILLED = {"id":"common.words.unfilled","translations":{"common":{"fr":"Non renseign\u00e9","en":"Not filled"}}};
export const COMMON_WORDS_FROM_TO = {"id":"common.words.from_to","translations":{"common":{"fr":"du $from au $to","en":"from $from to $to"}}};
export const COMMON_WORDS_UNTIL = {"id":"common.words.until","translations":{"common":{"fr":"jusqu'au $date","en":"until $date"}}};
export const COMMON_WORDS_STARTING_FROM = {"id":"common.words.starting_from","translations":{"common":{"fr":"\u00e0 partir du $date","en":"starting from $date"}}};
export const COMMON_WORDS_HELP = {"id":"common.words.help","translations":{"common":{"fr":"Aide","en":"Help"}}};
export const COMMON_WORDS_ERROR_OCCURED = {"id":"common.words.error_occured","translations":{"common":{"fr":"Une erreur est survenue","en":"An error occurred"}}};
export const COMMON_WORDS_INFORMATIONS_UPDATED = {"id":"common.words.informations_updated","translations":{"common":{"fr":"Les informations ont \u00e9t\u00e9 mises \u00e0 jour","en":"Information has been updated"}}};
export const COMMON_WORDS_LOW = {"id":"common.words.low","translations":{"common":{"fr":"Basse","en":"Low"}}};
export const COMMON_WORDS_MODERATE = {"id":"common.words.moderate","translations":{"common":{"fr":"Moyenne","en":"Moderate"}}};
export const COMMON_WORDS_HIGH = {"id":"common.words.high","translations":{"common":{"fr":"\u00c9lev\u00e9e","en":"High"}}};
export const COMMON_WORDS_CRITICAL = {"id":"common.words.critical","translations":{"common":{"fr":"Critique","en":"Critical"}}};
export const COMMON_WORDS_OTHER = {"id":"common.words.other","translations":{"common":{"fr":"Autre","en":"Other"}}};
export const COMMON_WORDS_CUSTOM = {"id":"common.words.custom","translations":{"common":{"fr":"Personnalis\u00e9es","en":"Custom"}}};
export const COMMON_WORDS_YOU = {"id":"common.words.you","translations":{"common":{"fr":"Vous","en":"You"}}};
export const COMMON_WORDS_TEXT = {"id":"common.words.text","translations":{"common":{"fr":"Texte","en":"Text"}}};
export const COMMON_WORDS_TYPE_NUMBER = {"id":"common.words.type_number","translations":{"common":{"fr":"Nombre","en":"Number"}}};
export const COMMON_WORDS_BOOLEAN = {"id":"common.words.boolean","translations":{"common":{"fr":"Yes\/No","en":"Yes\/No"}}};
export const COMMON_WORDS_SIMPLE_CHOICE = {"id":"common.words.simple_choice","translations":{"common":{"fr":"Choix simple","en":"Single choice"}}};
export const COMMON_WORDS_MULTIPLE_CHOICE = {"id":"common.words.multiple_choice","translations":{"common":{"fr":"Choix multiple","en":"Multiple choice"}}};
export const COMMON_WORDS_CONSTANT = {"id":"common.words.constant","translations":{"common":{"fr":"Valeur fixe","en":"Fixed value"}}};
export const COMMON_WORDS_WEAK = {"id":"common.words.weak","translations":{"common":{"fr":"Faible","en":"Weak"}}};
export const COMMON_WORDS_MEDIUM = {"id":"common.words.medium","translations":{"common":{"fr":"Medium","en":"Medium"}}};
export const COMMON_WORDS_STRONG = {"id":"common.words.strong","translations":{"common":{"fr":"Solide","en":"Strong"}}};
export const COMMON_WORDS_NONE_F = {"id":"common.words.none_f","translations":{"common":{"fr":"Aucune","en":"None"}}};
export const COMMON_WORDS_START = {"id":"common.words.start","translations":{"common":{"fr":"D\u00e9but","en":"Start"}}};
export const COMMON_WORDS_STOP = {"id":"common.words.stop","translations":{"common":{"fr":"Arr\u00eat","en":"Stop"}}};
export const COMMON_WORDS_END = {"id":"common.words.end","translations":{"common":{"fr":"Fin","en":"End"}}};
export const COMMON_WORDS_IMPORTED_LIA = {"id":"common.words.imported_lia","translations":{"common":{"fr":"Import\u00e9 par Lia","en":"Imported by Lia"}}};
export const COMMON_WORDS_IMPORTED_BY = {"id":"common.words.imported_by","translations":{"common":{"fr":"Import\u00e9 par $provider","en":"Imported by $provider"}}};
export const COMMON_WORDS_DEFAULT = {"id":"common.words.default","translations":{"common":{"fr":"D\u00e9faut","en":"Default"}}};
export const COMMON_WORDS_FORCE2FA = {"id":"common.words.force_2fa","translations":{"common":{"fr":"Force 2FA","en":"Force 2FA"}}};
export const COMMON_PLACEHOLDER_SEARCH = {"id":"common.placeholder.search","translations":{"common":{"fr":"Recherchez...","en":"Search..."}}};
export const COMMON_PLACEHOLDER_YOUR_CONTENT = {"id":"common.placeholder.your_content","translations":{"common":{"fr":"Votre contenu...","en":"Your content..."}}};
export const COMMON_TABS_INSTAMED_MESSAGES = {"id":"common.tabs.instamed_messages","translations":{"common":{"fr":"Messagerie Instamed","en":"Instamed messenger"}}};
export const COMMON_TABS_NOTIFICATIONS = {"id":"common.tabs.notifications","translations":{"common":{"fr":"Notifications","en":"Notifications"}}};
export const COMMON_TIME_D = {"id":"common.time.d","translations":{"common":{"fr":"J","en":"D"}}};
export const COMMON_TIME_M = {"id":"common.time.m","translations":{"common":{"fr":"M","en":"M"}}};
export const COMMON_TIME_Y = {"id":"common.time.y","translations":{"common":{"fr":"A","en":"Y"}}};
export const COMMON_TIME_TODAY = {"id":"common.time.today","translations":{"common":{"fr":"Aujourd'hui","en":"Today"}}};
export const COMMON_TIME_TODAY_AT = {"id":"common.time.today_at","translations":{"common":{"fr":"Aujourd'hui \u00e0 $hour","en":"Today at $hour"}}};
export const COMMON_TIME_WEEK_SHORT = {"id":"common.time.week_short","translations":{"common":{"fr":"Sem.","en":"Week"}}};
export const COMMON_TIME_YEAR = {"id":"common.time.year","translations":{"common":{"fr":"an","en":"year"}}};
export const COMMON_TIME_MONTH = {"id":"common.time.month","translations":{"common":{"fr":"mois","en":"month"}}};
export const COMMON_TIME_SHOW_OTHER_YEAR = {"id":"common.time.show_other_year","translations":{"common":{"fr":"Voir une autre ann\u00e9e","en":"View another year"}}};
export const COMMON_TIME_SELECT_DATE = {"id":"common.time.select_date","translations":{"common":{"fr":"Choisir la date","en":"Select date"}}};
export const COMMON_TIME_FORMAT = {"id":"common.time.format","translations":{"common":{"fr":"dddd DD MMMM \u00e0 HH[h]mm","en":"dddd DD MMMM [at] HH[:]mm"}}};
export const COMMON_TIME_FORMAT_FULL = {"id":"common.time.format_full","translations":{"common":{"fr":"Do MMMM YYYY \u00e0 HH[h]mm","en":"Do MMMM YYYY [at] HH[:]mm"}}};
export const COMMON_TIME_SHORT_DAY = {"id":"common.time.short_day","translations":{"common":{"fr":"j","en":"d"}}};
export const COMMON_TIME_UNIT_WEEKS = {"id":"common.time.unit.weeks","translations":{"common":{"fr":"","en":""}}};
export const COMMON_TIME_UNIT_DAYS = {"id":"common.time.unit.days","translations":{"common":{"fr":"","en":""}}};
export const COMMON_TIME_JS_YEARS = {"id":"common.time.js.years","translations":{"common":{"fr":"{{ years }} ans","en":"{{ years }} years"}}};
export const COMMON_TIME_JS_YEARS_MONTH = {"id":"common.time.js.years_month","translations":{"common":{"fr":"{{ years }} ans et {{ month }} mois","en":"{{ years }} years and {{ month }} months"}}};
export const COMMON_TIME_JS_MONTH = {"id":"common.time.js.month","translations":{"common":{"fr":"{{ month }} mois","en":"{{ month }} months"}}};
export const COMMON_TIME_JS_DAYS = {"id":"common.time.js.days","translations":{"common":{"fr":"{{ days }} jours","en":"{{ days }} days"}}};
export const COMMON_TIME_JS_FIRST_WEEK_DAY = {"id":"common.time.js.first_week_day","translations":{"common":{"fr":"Utiliser DD comme premier jour de la semaine","en":"Use DD as the first day of the week"}}};
export const COMMON_TIME_JS_SELECT = {"id":"common.time.js.select","translations":{"common":{"fr":"Choisir le DD, MM d","en":"Choose DD, MM of"}}};
export const COMMON_TIME_JS_BORN_ON1 = {"id":"common.time.js.born_on.1","translations":{"common":{"fr":"{{ age }} (N\u00e9 le {{ date }})","en":"{{ age }} (Born on {{ date }})"}}};
export const COMMON_TIME_JS_BORN_ON2 = {"id":"common.time.js.born_on.2","translations":{"common":{"fr":"{{ age }} (N\u00e9(e) le {{ date }})","en":"{{ age }} (Born on {{ date }})"}}};
export const COMMON_TIME_JS_BORN_ON3 = {"id":"common.time.js.born_on.3","translations":{"common":{"fr":"{{ age }} (N\u00e9e le {{ date }})","en":"{{ age }} (Born on {{ date }})"}}};
export const COMMON_TIME_MONTHS_SHOW_PREV = {"id":"common.time.months.show_prev","translations":{"common":{"fr":"Voir le mois pr\u00e9c\u00e9dent","en":"View previous month"}}};
export const COMMON_TIME_MONTHS_SHOW_NEXT = {"id":"common.time.months.show_next","translations":{"common":{"fr":"Voir le mois suivant","en":"View next month"}}};
export const COMMON_TIME_MONTHS_SHOW_OTHER = {"id":"common.time.months.show_other","translations":{"common":{"fr":"Voir un autre mois","en":"View another month"}}};
export const COMMON_TIME_MONTHS_JAN = {"id":"common.time.months.jan","translations":{"common":{"fr":"Janvier","en":"January"}}};
export const COMMON_TIME_MONTHS_FEB = {"id":"common.time.months.feb","translations":{"common":{"fr":"F\u00e9vrier","en":"February"}}};
export const COMMON_TIME_MONTHS_MARS = {"id":"common.time.months.mars","translations":{"common":{"fr":"Mars","en":"March"}}};
export const COMMON_TIME_MONTHS_APRIL = {"id":"common.time.months.april","translations":{"common":{"fr":"Avril","en":"April"}}};
export const COMMON_TIME_MONTHS_MAY = {"id":"common.time.months.may","translations":{"common":{"fr":"Mai","en":"May"}}};
export const COMMON_TIME_MONTHS_JUNE = {"id":"common.time.months.june","translations":{"common":{"fr":"Juin","en":"June"}}};
export const COMMON_TIME_MONTHS_JULY = {"id":"common.time.months.july","translations":{"common":{"fr":"Juillet","en":"July"}}};
export const COMMON_TIME_MONTHS_AUG = {"id":"common.time.months.aug","translations":{"common":{"fr":"Ao\u00fbt","en":"August"}}};
export const COMMON_TIME_MONTHS_SEPT = {"id":"common.time.months.sept","translations":{"common":{"fr":"Septembre","en":"September"}}};
export const COMMON_TIME_MONTHS_OCT = {"id":"common.time.months.oct","translations":{"common":{"fr":"Octobre","en":"October"}}};
export const COMMON_TIME_MONTHS_NOV = {"id":"common.time.months.nov","translations":{"common":{"fr":"Novembre","en":"November"}}};
export const COMMON_TIME_MONTHS_DEC = {"id":"common.time.months.dec","translations":{"common":{"fr":"D\u00e9cembre","en":"December"}}};
export const COMMON_TIME_MONTHS_SHORT_JAN = {"id":"common.time.months.short.jan","translations":{"common":{"fr":"Janv.","en":"Jan."}}};
export const COMMON_TIME_MONTHS_SHORT_FEB = {"id":"common.time.months.short.feb","translations":{"common":{"fr":"F\u00e9vr.","en":"Feb."}}};
export const COMMON_TIME_MONTHS_SHORT_MARS = {"id":"common.time.months.short.mars","translations":{"common":{"fr":"Mars","en":"Mar."}}};
export const COMMON_TIME_MONTHS_SHORT_APRIL = {"id":"common.time.months.short.april","translations":{"common":{"fr":"Avril","en":"Apr."}}};
export const COMMON_TIME_MONTHS_SHORT_MAY = {"id":"common.time.months.short.may","translations":{"common":{"fr":"Mai","en":"May"}}};
export const COMMON_TIME_MONTHS_SHORT_JUNE = {"id":"common.time.months.short.june","translations":{"common":{"fr":"Juin","en":"Jun."}}};
export const COMMON_TIME_MONTHS_SHORT_JULY = {"id":"common.time.months.short.july","translations":{"common":{"fr":"Juil.","en":"Jul."}}};
export const COMMON_TIME_MONTHS_SHORT_AUG = {"id":"common.time.months.short.aug","translations":{"common":{"fr":"Ao\u00fbt","en":"Aug."}}};
export const COMMON_TIME_MONTHS_SHORT_SEPT = {"id":"common.time.months.short.sept","translations":{"common":{"fr":"Sept.","en":"Sep."}}};
export const COMMON_TIME_MONTHS_SHORT_OCT = {"id":"common.time.months.short.oct","translations":{"common":{"fr":"Oct.","en":"Oct."}}};
export const COMMON_TIME_MONTHS_SHORT_NOV = {"id":"common.time.months.short.nov","translations":{"common":{"fr":"Nov.","en":"Nov."}}};
export const COMMON_TIME_MONTHS_SHORT_DEC = {"id":"common.time.months.short.dec","translations":{"common":{"fr":"D\u00e9c.","en":"Dec."}}};
export const COMMON_TIME_DAYS_MON = {"id":"common.time.days.mon","translations":{"common":{"fr":"Lundi","en":"Monday"}}};
export const COMMON_TIME_DAYS_TUES = {"id":"common.time.days.tues","translations":{"common":{"fr":"Mardi","en":"Tuesday"}}};
export const COMMON_TIME_DAYS_WED = {"id":"common.time.days.wed","translations":{"common":{"fr":"Mercredi","en":"Wednesday"}}};
export const COMMON_TIME_DAYS_THURS = {"id":"common.time.days.thurs","translations":{"common":{"fr":"Jeudi","en":"Thursday"}}};
export const COMMON_TIME_DAYS_FRI = {"id":"common.time.days.fri","translations":{"common":{"fr":"Vendredi","en":"Friday"}}};
export const COMMON_TIME_DAYS_SAT = {"id":"common.time.days.sat","translations":{"common":{"fr":"Samedi","en":"Saturday"}}};
export const COMMON_TIME_DAYS_SUN = {"id":"common.time.days.sun","translations":{"common":{"fr":"Dimanche","en":"Sunday"}}};
export const COMMON_TIME_DAYS_SHORT_MON = {"id":"common.time.days.short.mon","translations":{"common":{"fr":"Lun.","en":"Mon."}}};
export const COMMON_TIME_DAYS_SHORT_TUES = {"id":"common.time.days.short.tues","translations":{"common":{"fr":"Mar.","en":"Tue."}}};
export const COMMON_TIME_DAYS_SHORT_WED = {"id":"common.time.days.short.wed","translations":{"common":{"fr":"Mer.","en":"Wed."}}};
export const COMMON_TIME_DAYS_SHORT_THURS = {"id":"common.time.days.short.thurs","translations":{"common":{"fr":"Jeu.","en":"Thu."}}};
export const COMMON_TIME_DAYS_SHORT_FRI = {"id":"common.time.days.short.fri","translations":{"common":{"fr":"Ven.","en":"Fri."}}};
export const COMMON_TIME_DAYS_SHORT_SAT = {"id":"common.time.days.short.sat","translations":{"common":{"fr":"Sam.","en":"Sat."}}};
export const COMMON_TIME_DAYS_SHORT_SUN = {"id":"common.time.days.short.sun","translations":{"common":{"fr":"Dim.","en":"Sun."}}};
export const COMMON_TIME_DAYS_MIN_MON = {"id":"common.time.days.min.mon","translations":{"common":{"fr":"L","en":"M"}}};
export const COMMON_TIME_DAYS_MIN_TUES = {"id":"common.time.days.min.tues","translations":{"common":{"fr":"M","en":"T"}}};
export const COMMON_TIME_DAYS_MIN_WED = {"id":"common.time.days.min.wed","translations":{"common":{"fr":"M","en":"W"}}};
export const COMMON_TIME_DAYS_MIN_THURS = {"id":"common.time.days.min.thurs","translations":{"common":{"fr":"J","en":"T"}}};
export const COMMON_TIME_DAYS_MIN_FRI = {"id":"common.time.days.min.fri","translations":{"common":{"fr":"V","en":"F"}}};
export const COMMON_TIME_DAYS_MIN_SAT = {"id":"common.time.days.min.sat","translations":{"common":{"fr":"S","en":"S"}}};
export const COMMON_TIME_DAYS_MIN_SUN = {"id":"common.time.days.min.sun","translations":{"common":{"fr":"D","en":"S"}}};
export const CONNEXION_FIRST_CONNEXION_TITLE = {"id":"connexion.first_connexion.title","translations":{"connexion":{"fr":"Fiche de connexion de $patient","en":"Connection sheet for $patient"}}};
export const CONNEXION_FIRST_CONNEXION_FIRST_LINK_TEXT_A = {"id":"connexion.first_connexion.first_link.text_a","translations":{"connexion":{"fr":"Connectez-vous sur","en":"Log in at"}}};
export const CONNEXION_FIRST_CONNEXION_FIRST_LINK_TEXT_B = {"id":"connexion.first_connexion.first_link.text_b","translations":{"connexion":{"fr":"puis cliquez sur l'onglet SE CONNECTER.","en":"then click on the LOG IN tab."}}};
export const CONNEXION_FIRST_CONNEXION_SECOND_LINK_TEXT_A = {"id":"connexion.first_connexion.second_link.text_a","translations":{"connexion":{"fr":"Vous pouvez aussi suivre ce lien :","en":"You can also follow this link:"}}};
export const CONNEXION_FIRST_CONNEXION_SECOND_LINK_TEXT_B = {"id":"connexion.first_connexion.second_link.text_b","translations":{"connexion":{"fr":"(id\u00e9alement avec Chrome ou Firefox)","en":"(ideally with Chrome or Firefox)"}}};
export const CONSULTATION_TITLE_LIST = {"id":"consultation.title_list","translations":{"consultation":{"fr":"Consultations","en":"Consultations"}}};
export const CONSULTATION_LAST_SAVE = {"id":"consultation.last_save","translations":{"consultation":{"fr":"Derni\u00e8re sauvegarde \u00e0 ","en":"Last saved at "}}};
export const CONSULTATION_ACTS_DONE = {"id":"consultation.acts_done","translations":{"consultation":{"fr":"Actes effectu\u00e9s","en":"Completed acts"}}};
export const CONSULTATION_DOCS = {"id":"consultation.docs","translations":{"consultation":{"fr":"Documents de la consultation","en":"Consultation documents"}}};
export const CONSULTATION_NOT_FOUND = {"id":"consultation.not_found","translations":{"consultation":{"fr":"Aucune consultations trouv\u00e9e","en":"No consultations found"}}};
export const CONSULTATION_QUESTIONNAIRE = {"id":"consultation.questionnaire","translations":{"consultation":{"fr":"Questionnaire de consultation","en":"Consultation questionnaire"}}};
export const CONSULTATION_NOTE_TITLE = {"id":"consultation.note.title","translations":{"consultation":{"fr":"Note","en":"Note"}}};
export const CONSULTATION_NOTE_NOTE_TITLE = {"id":"consultation.note.note_title","translations":{"consultation":{"fr":"Titre de la note","en":"Note title"}}};
export const CONSULTATION_NOTE_CONTENT = {"id":"consultation.note.content","translations":{"consultation":{"fr":"Contenu de la note","en":"Note content"}}};
export const CONSULTATION_NOTE_IMPORTANT = {"id":"consultation.note.important","translations":{"consultation":{"fr":"Note Importante","en":"Important"}}};
export const CONSULTATION_NOTE_NOT_FOUND = {"id":"consultation.note.not_found","translations":{"consultation":{"fr":"Aucune note trouv\u00e9e","en":"No notes found"}}};
export const CONSULTATION_ACTIONS_BILL = {"id":"consultation.actions.bill","translations":{"consultation":{"fr":"Facturer","en":"Bill"}}};
export const CONSULTATION_ACTIONS_UPLOAD_DOCUMENT = {"id":"consultation.actions.upload_document","translations":{"consultation":{"fr":"Mettre un document en ligne","en":"Upload document"}}};
export const CONSULTATION_ACTIONS_SELECT_OWNER = {"id":"consultation.actions.select_owner","translations":{"consultation":{"fr":"Choisissez le propri\u00e9taire de cette consultation","en":"Choose the owner of this consultation"}}};
export const CONSULTATION_ACTIONS_CREATE = {"id":"consultation.actions.create","translations":{"consultation":{"fr":"Cr\u00e9er la consultation","en":"Create consultation"}}};
export const CONSULTATION_ACTIONS_READ_HEALTHCARD = {"id":"consultation.actions.read_healthcard","translations":{"consultation":{"fr":"Lire la carte","en":"Read card"}}};
export const CONSULTATION_ACTIONS_BILLING_WITHOUT_HEALTHCARD = {"id":"consultation.actions.billing_without_healthcard","translations":{"consultation":{"fr":"Facturer sans carte vitale","en":"Bill without Carte vitale"}}};
export const CONSULTATION_ACTIONS_CONTINUE_WITHOUT_HEALTHCARD = {"id":"consultation.actions.continue_without_healthcard","translations":{"consultation":{"fr":"Continuer sans carte vitale","en":"Continue without Carte vitale"}}};
export const CONSULTATION_ACTIONS_ADD = {"id":"consultation.actions.add","translations":{"consultation":{"fr":"Nouvelle consultation","en":"New consultation"}}};
export const CONSULTATION_ACTIONS_EDIT_DATE = {"id":"consultation.actions.edit_date","translations":{"consultation":{"fr":"Modifier la date de la consultation","en":"Edit consultation date"}}};
export const CONSULTATION_ACTIONS_SELECT_DATE = {"id":"consultation.actions.select_date","translations":{"consultation":{"fr":"Choisissez une date et une heure","en":"Choose date and time"}}};
export const CONSULTATION_ACTIONS_FILTER_DATE = {"id":"consultation.actions.filter_date","translations":{"consultation":{"fr":"Filtrer par date","en":"Filter by date"}}};
export const CONSULTATION_ACTIONS_FILTER_CATEGORIES = {"id":"consultation.actions.filter_categories","translations":{"consultation":{"fr":"Filtrer par cat\u00e9gorie","en":"Filter by category"}}};
export const CONSULTATION_ACTIONS_FILTER_OWNER = {"id":"consultation.actions.filter_owner","translations":{"consultation":{"fr":"Filtrer par m\u00e9decin","en":"Filter by doctor"}}};
export const CONSULTATION_ACTIONS_ADD_EDIT_NOTE = {"id":"consultation.actions.add_edit_note","translations":{"consultation":{"fr":"Ajouter ou modifier la note","en":"Add or edit note"}}};
export const CONSULTATION_ACTIONS_COMPLETE_QUESTIONNAIRE = {"id":"consultation.actions.complete_questionnaire","translations":{"consultation":{"fr":"R\u00e9pondre au questionnaire de consultation","en":"Answer the consultation questionnaire"}}};
export const CONSULTATION_ACTIONS_FILL_CERFA = {"id":"consultation.actions.fill_cerfa","translations":{"consultation":{"fr":"Remplir un Cerfa","en":"Fill out Cerfa"}}};
export const CONSULTATION_ACTIONS_CREATE_BILL = {"id":"consultation.actions.create_bill","translations":{"consultation":{"fr":"Cr\u00e9er une facture","en":"Create invoice"}}};
export const CONSULTATION_ACTIONS_SELECT_USERS = {"id":"consultation.actions.select_users","translations":{"consultation":{"fr":"Choisissez vos utilisateurs","en":"Choose your users"}}};
export const CONSULTATION_ACTIONS_PRINT_DOCS = {"id":"consultation.actions.print_docs","translations":{"consultation":{"fr":"Imprimer plusieurs documents","en":"Print multiple documents"}}};
export const CONSULTATION_ACTIONS_ADD_FILES = {"id":"consultation.actions.add_files","translations":{"consultation":{"fr":"Ajouter des fichiers","en":"Add files"}}};
export const CONSULTATION_ACTIONS_CHECK_ALL = {"id":"consultation.actions.check_all","translations":{"consultation":{"fr":"Tout cocher","en":"Check all"}}};
export const CONSULTATION_ACTIONS_SELECT_QUESTIONNAIRE = {"id":"consultation.actions.select_questionnaire","translations":{"consultation":{"fr":"Choisissez le questionnaire que vous souhaitez remplir","en":"Choose questionnaire you wish to complete"}}};
export const CONSULTATION_HELPER_FILL_SOCIAL_SECURITY_NUMBER = {"id":"consultation.helper.fill_social_security_number","translations":{"consultation":{"fr":"Si vous souhaitez facturer sans carte vitale (mode d\u00e9grad\u00e9), merci de renseigner le num\u00e9ro de s\u00e9curit\u00e9 sociale de votre patient(e) ainsi que sa date de naissance.","en":"If you want to bill without Carte vitale (degraded mode), please provide your patient's social security number and date of birth."}}};
export const CONSULTATION_HELPER_PRINT_DOCS_SELECTION = {"id":"consultation.helper.print_docs_selection","translations":{"consultation":{"fr":"Choisissez dans la liste ci-dessous les documents \u00e0 imprimer","en":"Choose from the list below documents to print"}}};
export const PARKING_DESCRIPTION = {"id":"parking.description","translations":{"consultation_location":{"fr":"Parking","en":"Parking"}}};
export const PARKING_LABEL = {"id":"parking.label","translations":{"consultation_location":{"fr":"Parking","en":"Parking"}}};
export const PARKING_PLACEHOLDER = {"id":"parking.placeholder","translations":{"consultation_location":{"fr":"Parking","en":"Parking"}}};
export const PARKING_VALUES_FREE = {"id":"parking.values.free","translations":{"consultation_location":{"fr":"Gratuit","en":"Free"}}};
export const PARKING_VALUES_PAID = {"id":"parking.values.paid","translations":{"consultation_location":{"fr":"Payant","en":"Not paid"}}};
export const PARKING_VALUES_NONE = {"id":"parking.values.none","translations":{"consultation_location":{"fr":"Aucun","en":"None"}}};
export const ACCESSIBILITY_DESCRIPTION = {"id":"accessibility.description","translations":{"consultation_location":{"fr":"Accessibilit\u00e9","en":"Accessibility"}}};
export const ACCESSIBILITY_LABEL = {"id":"accessibility.label","translations":{"consultation_location":{"fr":"Accessibilit\u00e9","en":"Accessibility"}}};
export const ACCESSIBILITY_PLACEHOLDER = {"id":"accessibility.placeholder","translations":{"consultation_location":{"fr":"Accessibilit\u00e9","en":"Accessibility"}}};
export const ACCESSIBILITY_VALUES_ELEVATOR = {"id":"accessibility.values.elevator","translations":{"consultation_location":{"fr":"Ascenseur","en":"Elevator"}}};
export const ACCESSIBILITY_VALUES_ACCESSIBLE_ENTRANCE = {"id":"accessibility.values.accessible_entrance","translations":{"consultation_location":{"fr":"Entr\u00e9e accessible","en":"Accessible entrance"}}};
export const VARIABLE_PROFILE_ID = {"id":"variable.profile.id","translations":{"consultations+intl-icu":{"fr":"Identifiant du profil","en":"Profile ID"}}};
export const VARIABLE_PROFILE_DECEASED_DATE = {"id":"variable.profile.deceasedDate","translations":{"consultations+intl-icu":{"fr":"Date de d\u00e9c\u00e8s","en":"Date of death"}}};
export const VARIABLE_PROFILE_ALLERGIES = {"id":"variable.profile.allergies","translations":{"consultations+intl-icu":{"fr":"Allergies","en":"Allergies"}}};
export const VARIABLE_PROFILE_IMC = {"id":"variable.profile.IMC","translations":{"consultations+intl-icu":{"fr":"IMC","en":"BMI"}}};
export const VARIABLE_PROFILE_SOCIAL_SECURITY_NUMBER = {"id":"variable.profile.socialSecurityNumber","translations":{"consultations+intl-icu":{"fr":"N\u00b0 de s\u00e9curit\u00e9 sociale","en":"Social security number"}}};
export const VARIABLE_PROFILE_SOCIAL_SECURITY_NUMBER_WITHOUT_KEY = {"id":"variable.profile.socialSecurityNumberWithoutKey","translations":{"consultations+intl-icu":{"fr":"N\u00b0 de s\u00e9curit\u00e9 sociale sans cl\u00e9","en":"Social security number without key"}}};
export const VARIABLE_PROFILE_SOCIAL_SECURITY_NUMBER_KEY = {"id":"variable.profile.socialSecurityNumberKey","translations":{"consultations+intl-icu":{"fr":"Cl\u00e9 du n\u00b0 de s\u00e9curit\u00e9 sociale","en":"Social security number key"}}};
export const VARIABLE_PROFILE_BIRTH_PLACE = {"id":"variable.profile.birthPlace","translations":{"consultations+intl-icu":{"fr":"Lieu de naissance","en":"Birthplace"}}};
export const VARIABLE_PROFILE_WEIGHT = {"id":"variable.profile.weight","translations":{"consultations+intl-icu":{"fr":"Poids","en":"Weight"}}};
export const VARIABLE_PROFILE_JOB_TITLE = {"id":"variable.profile.jobTitle","translations":{"consultations+intl-icu":{"fr":"Activit\u00e9","en":"Job title"}}};
export const VARIABLE_PROFILE_SEX = {"id":"variable.profile.sex","translations":{"consultations+intl-icu":{"fr":"Sexe","en":"Sex"}}};
export const VARIABLE_PROFILE_BODY_SURFACE_AREA = {"id":"variable.profile.bodySurfaceArea","translations":{"consultations+intl-icu":{"fr":"Surface corporelle","en":"Body surface area"}}};
export const VARIABLE_PROFILE_HEIGHT = {"id":"variable.profile.height","translations":{"consultations+intl-icu":{"fr":"Taille","en":"Height"}}};
export const VARIABLE_PROFILE_EYES_COLOR = {"id":"variable.profile.eyesColor","translations":{"consultations+intl-icu":{"fr":"Couleur des yeux","en":"Eye color"}}};
export const VARIABLE_PROFILE_HAIR_COLOR = {"id":"variable.profile.hairColor","translations":{"consultations+intl-icu":{"fr":"Couleur des cheveux","en":"Hair color"}}};
export const VARIABLE_PROFILE_LATERALITY = {"id":"variable.profile.laterality","translations":{"consultations+intl-icu":{"fr":"Lat\u00e9ralit\u00e9","en":"Laterality"}}};
export const VARIABLE_PROFILE_FAMILY_SITUATION = {"id":"variable.profile.familySituation","translations":{"consultations+intl-icu":{"fr":"Situation familiale","en":"Family situation"}}};
export const VARIABLE_PROFILE_PHYSICAL_ACTIVITIES = {"id":"variable.profile.physicalActivities","translations":{"consultations+intl-icu":{"fr":"Loisirs et activit\u00e9s physiques","en":"Leisure and physical activities"}}};
export const VARIABLE_PROFILE_HOME_HELP = {"id":"variable.profile.homeHelp","translations":{"consultations+intl-icu":{"fr":"Aide \u00e0 domicile","en":"Home help"}}};
export const VARIABLE_PROFILE_DISEASES = {"id":"variable.profile.diseases","translations":{"consultations+intl-icu":{"fr":"Maladie(s)","en":"Diseases"}}};
export const VARIABLE_PROFILE_BLOOD_TYPE = {"id":"variable.profile.bloodType","translations":{"consultations+intl-icu":{"fr":"Groupe sanguin","en":"Blood Type"}}};
export const VARIABLE_PROFILE_MUTUAL_NUMBER = {"id":"variable.profile.mutualNumber","translations":{"consultations+intl-icu":{"fr":"N\u00b0 de mutuelle","en":"Mutual number"}}};
export const VARIABLE_PROFILE_MUTUAL = {"id":"variable.profile.mutual","translations":{"consultations+intl-icu":{"fr":"Mutuelle","en":"Mutual"}}};
export const VARIABLE_PROFILE_COVER_TYPE = {"id":"variable.profile.coverType","translations":{"consultations+intl-icu":{"fr":"Type de couverture maladie","en":"Type of health coverage"}}};
export const VARIABLE_PROFILE_MEDICAL_TEAM_MEMBER_MEDICS_NAMES = {"id":"variable.profile.medicalTeamMemberMedicsNames","translations":{"consultations+intl-icu":{"fr":"M\u00e9decins de l\u2019\u00e9quipe soignante","en":"Care team doctors"}}};
export const VARIABLE_PROFILE_DRINKING_FREQUENCY = {"id":"variable.profile.drinkingFrequency","translations":{"consultations+intl-icu":{"fr":"Alcool","en":"Alcohol"}}};
export const VARIABLE_USER_ID = {"id":"variable.user.id","translations":{"consultations+intl-icu":{"fr":"Identifiant de l'utilisateur","en":"User ID"}}};
export const VARIABLE_USER_PERSONAL_ADDRESS = {"id":"variable.user.personalAddress","translations":{"consultations+intl-icu":{"fr":"Adresse du patient","en":"Patient address"}}};
export const VARIABLE_USER_TITLE = {"id":"variable.user.title","translations":{"consultations+intl-icu":{"fr":"Civilit\u00e9 du patient","en":"Patient title"}}};
export const VARIABLE_USER_DATE_OF_BIRTH = {"id":"variable.user.dateOfBirth","translations":{"consultations+intl-icu":{"fr":"Date de naissance","en":"Date of birth"}}};
export const VARIABLE_USER_AGE = {"id":"variable.user.age","translations":{"consultations+intl-icu":{"fr":"\u00c2ge (nombre)","en":"Age (number)"}}};
export const VARIABLE_USER_DATE_OF_BIRTH_AGE = {"id":"variable.user.dateOfBirth|age","translations":{"consultations+intl-icu":{"fr":"\u00c2ge","en":"Age"}}};
export const VARIABLE_USER_EMAIL = {"id":"variable.user.email","translations":{"consultations+intl-icu":{"fr":"Email du patient","en":"Patient email"}}};
export const VARIABLE_USER_USERNAME = {"id":"variable.user.username","translations":{"consultations+intl-icu":{"fr":"Identifiant du patient","en":"Patient ID"}}};
export const VARIABLE_USER_FULL_NAME = {"id":"variable.user.fullName","translations":{"consultations+intl-icu":{"fr":"Nom complet du patient","en":"Patient full name"}}};
export const VARIABLE_USER_NAME = {"id":"variable.user.name","translations":{"consultations+intl-icu":{"fr":"Nom du patient","en":"Patient name"}}};
export const VARIABLE_USER_NAME_WITH_LAST_AND_GIVEN = {"id":"variable.user.nameWithLastAndGiven","translations":{"consultations+intl-icu":{"fr":"Nom complet sans civilit\u00e9","en":"Full name without title"}}};
export const VARIABLE_USER_LAST_NAME = {"id":"variable.user.lastName","translations":{"consultations+intl-icu":{"fr":"Nom de naissance","en":"Birth name"}}};
export const VARIABLE_USER_GIVEN_NAME = {"id":"variable.user.givenName","translations":{"consultations+intl-icu":{"fr":"Nom d'usage","en":"Common name"}}};
export const VARIABLE_USER_FIRST_NAME = {"id":"variable.user.firstName","translations":{"consultations+intl-icu":{"fr":"Pr\u00e9nom du patient","en":"Patient first name"}}};
export const VARIABLE_USER_PHONE = {"id":"variable.user.phone","translations":{"consultations+intl-icu":{"fr":"T\u00e9l\u00e9phone du patient","en":"Patient phone"}}};
export const VARIABLE_USER_MOBILE_PHONE = {"id":"variable.user.mobilePhone","translations":{"consultations+intl-icu":{"fr":"T\u00e9l\u00e9phone mobile du patient","en":"Patient mobile phone"}}};
export const VARIABLE_USER_HOME_PHONE = {"id":"variable.user.homePhone","translations":{"consultations+intl-icu":{"fr":"T\u00e9l\u00e9phone fixe du patient","en":"Patient home phone"}}};
export const VARIABLE_USER_PHONE_NUMBER_LIST = {"id":"variable.user.phoneNumberList","translations":{"consultations+intl-icu":{"fr":"T\u00e9l\u00e9phones du patient","en":"Patient Phone numbers"}}};
export const VARIABLE_AUTHOR_EMAIL = {"id":"variable.author.email","translations":{"consultations+intl-icu":{"fr":"Email du m\u00e9decin","en":"Doctor's email"}}};
export const VARIABLE_AUTHOR_TITLE = {"id":"variable.author.title","translations":{"consultations+intl-icu":{"fr":"Civilit\u00e9 du m\u00e9decin","en":"Doctor's title"}}};
export const VARIABLE_AUTHOR_FULL_NAME = {"id":"variable.author.fullName","translations":{"consultations+intl-icu":{"fr":"Nom complet du m\u00e9decin","en":"Doctor's full name"}}};
export const VARIABLE_AUTHOR_NAME = {"id":"variable.author.name","translations":{"consultations+intl-icu":{"fr":"Nom du m\u00e9decin (sans civilit\u00e9)","en":"Name of the doctor (with no title)"}}};
export const VARIABLE_AUTHOR_LAST_NAME = {"id":"variable.author.lastName","translations":{"consultations+intl-icu":{"fr":"Nom du m\u00e9decin","en":"Doctor's name"}}};
export const VARIABLE_AUTHOR_FIRST_NAME = {"id":"variable.author.firstName","translations":{"consultations+intl-icu":{"fr":"Pr\u00e9nom du m\u00e9decin","en":"Doctor's first name"}}};
export const VARIABLE_AUTHOR_PUBLIC_PHONE = {"id":"variable.author.publicPhone","translations":{"consultations+intl-icu":{"fr":"T\u00e9l\u00e9phone","en":"Phone"}}};
export const VARIABLE_AUTHOR_LOCATION_ADDRESS = {"id":"variable.author.location_address","translations":{"consultations+intl-icu":{"fr":"Adresse {location}","en":"Address {location}"}}};
export const VARIABLE_AUTHOR_LOCATION_PHONE = {"id":"variable.author.location_phone","translations":{"consultations+intl-icu":{"fr":"T\u00e9l\u00e9phone {location}","en":"Phone {location}"}}};
export const VARIABLE_AUTHOR_PROFILE_ADDRESS = {"id":"variable.authorProfile.address","translations":{"consultations+intl-icu":{"fr":"Adresse du m\u00e9decin","en":"Doctor's address"}}};
export const VARIABLE_AUTHOR_PROFILE_ADDRESS_ONE_LINE = {"id":"variable.authorProfile.addressOneLine","translations":{"consultations+intl-icu":{"fr":"Adresse sur une ligne","en":"Address on a line"}}};
export const VARIABLE_AUTHOR_PROFILE_COMPANY_NAME = {"id":"variable.authorProfile.companyName","translations":{"consultations+intl-icu":{"fr":"Nom de l'entreprise","en":"Company name"}}};
export const VARIABLE_AUTHOR_PROFILE_STRUCTURE_NUMBER = {"id":"variable.authorProfile.structureNumber","translations":{"consultations+intl-icu":{"fr":"Num\u00e9ro de structure (RPPS, ADELI, FINESS)","en":"Structure number (RPPS, ADELI, FINESS)"}}};
export const VARIABLE_AUTHOR_PROFILE_ADDRESS_CITY = {"id":"variable.authorProfile.address.city","translations":{"consultations+intl-icu":{"fr":"Ville du m\u00e9decin","en":"Doctor's city"}}};
export const VARIABLE_AUTHOR_PROFILE_EMAIL_API_CRYPT = {"id":"variable.authorProfile.emailApiCrypt","translations":{"consultations+intl-icu":{"fr":"Email APICrypt","en":"APICrypt email"}}};
export const VARIABLE_AUTHOR_PROFILE_EMAIL_MS_SANTE = {"id":"variable.authorProfile.emailMsSante","translations":{"consultations+intl-icu":{"fr":"Email MSSant\u00e9","en":"MSSante email"}}};
export const VARIABLE_AUTHOR_PROFILE_FAX = {"id":"variable.authorProfile.fax","translations":{"consultations+intl-icu":{"fr":"Num\u00e9ro de fax","en":"Fax number"}}};
export const VARIABLE_AUTHOR_PROFILE_FINESS = {"id":"variable.authorProfile.finess","translations":{"consultations+intl-icu":{"fr":"N\u00b0FINESS","en":"FINESS number"}}};
export const VARIABLE_AUTHOR_PROFILE_FINESS_BARCODE = {"id":"variable.authorProfile.finess|barcode","translations":{"consultations+intl-icu":{"fr":"Code barre FINESS (Large)","en":"FINESS barcode (large)"}}};
export const VARIABLE_AUTHOR_PROFILE_FINESS_BARCODE_SMALL = {"id":"variable.authorProfile.finess|barcode-small","translations":{"consultations+intl-icu":{"fr":"Code barre FINESS","en":"FINESS barcode"}}};
export const VARIABLE_AUTHOR_PROFILE_ADELI = {"id":"variable.authorProfile.adeli","translations":{"consultations+intl-icu":{"fr":"N\u00b0ADELI","en":"ADELI number"}}};
export const VARIABLE_AUTHOR_PROFILE_ADELI_BARCODE = {"id":"variable.authorProfile.adeli|barcode","translations":{"consultations+intl-icu":{"fr":"Code barre ADELI (Large)","en":"ADELI barcode (large)"}}};
export const VARIABLE_AUTHOR_PROFILE_ADELI_BARCODE_SMALL = {"id":"variable.authorProfile.adeli|barcode-small","translations":{"consultations+intl-icu":{"fr":"Code barre ADELI","en":"ADELI barcode"}}};
export const VARIABLE_AUTHOR_PROFILE_RPPS = {"id":"variable.authorProfile.rpps","translations":{"consultations+intl-icu":{"fr":"N\u00b0RPPS","en":"RPPS number"}}};
export const VARIABLE_AUTHOR_PROFILE_RPPS_BARCODE = {"id":"variable.authorProfile.rpps|barcode","translations":{"consultations+intl-icu":{"fr":"Code barre RPPS (Large)","en":"RPPS barcode (large)"}}};
export const VARIABLE_AUTHOR_PROFILE_RPPS_BARCODE_SMALL = {"id":"variable.authorProfile.rpps|barcode-small","translations":{"consultations+intl-icu":{"fr":"Code barre RPPS","en":"RPPS barcode"}}};
export const VARIABLE_AUTHOR_PROFILE_SIRET_NUMBER = {"id":"variable.authorProfile.siretNumber","translations":{"consultations+intl-icu":{"fr":"N\u00b0SIRET","en":"SIRET number"}}};
export const VARIABLE_AUTHOR_PROFILE_WEBSITE = {"id":"variable.authorProfile.website","translations":{"consultations+intl-icu":{"fr":"Site internet","en":"Website"}}};
export const VARIABLE_AUTHOR_PROFILE_SIGNATURE_IMG = {"id":"variable.authorProfile.signature|img","translations":{"consultations+intl-icu":{"fr":"Signature","en":"Signature"}}};
export const VARIABLE_AUTHOR_PROFILE_BUSINESS_STAMP_IMG = {"id":"variable.authorProfile.businessStamp|img","translations":{"consultations+intl-icu":{"fr":"Tampon","en":"Stamp"}}};
export const VARIABLE_AUTHOR_PROFILE_SPECIALTIES = {"id":"variable.authorProfile.specialties","translations":{"consultations+intl-icu":{"fr":"Sp\u00e9cialit\u00e9s","en":"Specialties"}}};
export const VARIABLE_AUTHOR_PROFILE_MEDICAL_TEAM_MEMBER_MEDICS_NAMES = {"id":"variable.authorProfile.medicalTeamMemberMedicsNames","translations":{"consultations+intl-icu":{"fr":"M\u00e9decins de mon \u00e9quipe soignante","en":"Doctors in my care team"}}};
export const VARIABLE_PATIENT_DISEASE = {"id":"variable.patientDisease","translations":{"consultations+intl-icu":{"fr":"Maladie","en":"Disease"}}};
export const VARIABLE_PATIENT_TREATMENT = {"id":"variable.patientTreatment","translations":{"consultations+intl-icu":{"fr":"Traitement","en":"Treatment"}}};
export const VARIABLE_MEDICAL_BACKGROUND = {"id":"variable.medicalBackground","translations":{"consultations+intl-icu":{"fr":"Ant\u00e9c\u00e9dent","en":"Medical history"}}};
export const VARIABLE_PRIMARY_CARE_PHYSICIAN_PROFILE_ADDRESS = {"id":"variable.primaryCarePhysicianProfile.address","translations":{"consultations+intl-icu":{"fr":"Adresse du m\u00e9decin traitant","en":"Primary care physician's address"}}};
export const VARIABLE_PRIMARY_CARE_PHYSICIAN_PROFILE_FAX = {"id":"variable.primaryCarePhysicianProfile.fax","translations":{"consultations+intl-icu":{"fr":"Num\u00e9ro de fax du m\u00e9decin traitant","en":"Primary care physician's fax number"}}};
export const VARIABLE_PRIMARY_CARE_PHYSICIAN_PROFILE_SPECIALTIES = {"id":"variable.primaryCarePhysicianProfile.specialties","translations":{"consultations+intl-icu":{"fr":"Sp\u00e9cialit\u00e9s du m\u00e9decin traitant","en":"Primary care physician's specialties"}}};
export const VARIABLE_PRIMARY_CARE_PHYSICIAN_EMAIL = {"id":"variable.primaryCarePhysician.email","translations":{"consultations+intl-icu":{"fr":"Email du m\u00e9decin traitant","en":"Primary care physician's email"}}};
export const VARIABLE_PRIMARY_CARE_PHYSICIAN_FULL_NAME = {"id":"variable.primaryCarePhysician.fullName","translations":{"consultations+intl-icu":{"fr":"Nom complet du m\u00e9decin traitant","en":"Primary care physician's full name"}}};
export const VARIABLE_PRIMARY_CARE_PHYSICIAN_FIRST_NAME = {"id":"variable.primaryCarePhysician.firstName","translations":{"consultations+intl-icu":{"fr":"Pr\u00e9nom du m\u00e9decin traitant","en":"Primary care physician's first name"}}};
export const VARIABLE_PRIMARY_CARE_PHYSICIAN_LAST_NAME = {"id":"variable.primaryCarePhysician.lastName","translations":{"consultations+intl-icu":{"fr":"Nom du m\u00e9decin traitant","en":"Primary care physician's last name"}}};
export const VARIABLE_PRIMARY_CARE_PHYSICIAN_PUBLIC_PHONE = {"id":"variable.primaryCarePhysician.publicPhone","translations":{"consultations+intl-icu":{"fr":"T\u00e9l\u00e9phone du m\u00e9decin traitant","en":"Primary care physician's phone"}}};
export const VARIABLE_INSTITUTION_FINESS = {"id":"variable.institution.finess","translations":{"consultations+intl-icu":{"fr":"N\u00b0 FINESS (H\u00f4pital)","en":"FINESS number (hospital)"}}};
export const VARIABLE_INSTITUTION_FINESS_BARCODE = {"id":"variable.institution.finess|barcode","translations":{"consultations+intl-icu":{"fr":"Code barre FINESS large (H\u00f4pital)","en":"FINESS barcode large (hospital)"}}};
export const VARIABLE_INSTITUTION_FINESS_BARCODE_SMALL = {"id":"variable.institution.finess|barcode-small","translations":{"consultations+intl-icu":{"fr":"Code barre FINESS (H\u00f4pital)","en":"FINESS barcode (hospital)"}}};
export const VARIABLE_CONSULTATION_CREATED_DATE = {"id":"variable.consultation.createdDate","translations":{"consultations+intl-icu":{"fr":"Date de la consultation","en":"Consultation date"}}};
export const VARIABLE_CONSULTATION_FULL_CREATED_DATE = {"id":"variable.consultation.fullCreatedDate","translations":{"consultations+intl-icu":{"fr":"Date de la consultation (compl\u00e8te)","en":"Consultation date (full)"}}};
export const VARIABLE_CONSULTATION_TODAY = {"id":"variable.consultation.today","translations":{"consultations+intl-icu":{"fr":"Date du jour","en":"Today's date"}}};
export const VARIABLE_CONSULTATION_NOTE = {"id":"variable.consultation.note","translations":{"consultations+intl-icu":{"fr":"Note de la consultation","en":"Consultation note"}}};
export const VARIABLE_SITE_URL = {"id":"variable.site.url","translations":{"consultations+intl-icu":{"fr":"Url du site","en":"Website URL"}}};
export const VARIABLE_SITE_TODAY = {"id":"variable.site.today","translations":{"consultations+intl-icu":{"fr":"Date du jour","en":"Today's date"}}};
export const VARIABLE_SITE_FULL_TODAY = {"id":"variable.site.fullToday","translations":{"consultations+intl-icu":{"fr":"Date du jour (compl\u00e8te)","en":"Today's date (full)"}}};
export const VARIABLE_SITE_YESTERDAY = {"id":"variable.site.yesterday","translations":{"consultations+intl-icu":{"fr":"Date de la veille","en":"Previous day date"}}};
export const VARIABLE_SITE_FULL_YESTERDAY = {"id":"variable.site.fullYesterday","translations":{"consultations+intl-icu":{"fr":"Date de la veille (compl\u00e8te)","en":"Previous day date (full)"}}};
export const VARIABLE_MEDICAL_BACKGROUND_ALL = {"id":"variable.medical_background.all","translations":{"consultations+intl-icu":{"fr":"Tous les ant\u00e9c\u00e9dents","en":"All medical history"}}};
export const VARIABLE_MEDICAL_BACKGROUND1 = {"id":"variable.medical_background.1","translations":{"consultations+intl-icu":{"fr":"Ant\u00e9c\u00e9dents m\u00e9dicaux","en":"Medical history"}}};
export const VARIABLE_MEDICAL_BACKGROUND2 = {"id":"variable.medical_background.2","translations":{"consultations+intl-icu":{"fr":"Ant\u00e9c\u00e9dents familiaux","en":"Family history"}}};
export const VARIABLE_MEDICAL_BACKGROUND3 = {"id":"variable.medical_background.3","translations":{"consultations+intl-icu":{"fr":"Ant\u00e9c\u00e9dents chirurgicaux","en":"Surgical history"}}};
export const VARIABLE_MEDICAL_BACKGROUND4 = {"id":"variable.medical_background.4","translations":{"consultations+intl-icu":{"fr":"Ant\u00e9c\u00e9dents non m\u00e9dicaux","en":"Non-medical history"}}};
export const VARIABLE_MEDICAL_BACKGROUND5 = {"id":"variable.medical_background.5","translations":{"consultations+intl-icu":{"fr":"Ant\u00e9c\u00e9dents gyn\u00e9cologiques","en":"Gynecological history"}}};
export const VARIABLE_TEXTS_MEDICAL_BACKGROUND_DECEASED_ON1 = {"id":"variable.texts.medical_background.deceased_on.1","translations":{"consultations+intl-icu":{"fr":"D\u00e9c\u00e9d\u00e9 en","en":"Deceased in"}}};
export const VARIABLE_TEXTS_MEDICAL_BACKGROUND_DECEASED_ON2 = {"id":"variable.texts.medical_background.deceased_on.2","translations":{"consultations+intl-icu":{"fr":"D\u00e9c\u00e9d\u00e9e en","en":"Deceased in"}}};
export const VARIABLE_TEXTS_MEDICAL_BACKGROUND_BORN_ON1 = {"id":"variable.texts.medical_background.born_on.1","translations":{"consultations+intl-icu":{"fr":"N\u00e9 en","en":"Born in"}}};
export const VARIABLE_TEXTS_MEDICAL_BACKGROUND_BORN_ON2 = {"id":"variable.texts.medical_background.born_on.2","translations":{"consultations+intl-icu":{"fr":"N\u00e9e en","en":"Born in"}}};
export const VARIABLE_TEXTS_BORN_THE = {"id":"variable.texts.born_the","translations":{"consultations+intl-icu":{"fr":"{gender, select, female   {N\u00e9e le {date}} male     {N\u00e9 le {date}} other    {N\u00e9(e) le {date}} }","en":"{gender, select,  female  {Born on {date}}  male    {Born on {date}}  other   {Born on {date}}  }"}}};
export const RELATIONSHIP_DESCRIPTION = {"id":"relationship.description","translations":{"contact":{"fr":"Type de relation avec le patient","en":"Relationship type with the patient"}}};
export const RELATIONSHIP_LABEL = {"id":"relationship.label","translations":{"contact":{"fr":"Type de relation","en":"Relationship type"}}};
export const RELATIONSHIP_PLACEHOLDER = {"id":"relationship.placeholder","translations":{"contact":{"fr":"S\u00e9lectionnez un type de relation","en":"Select a relationship type"}}};
export const RELATIONSHIP_VALUES_MOTHER = {"id":"relationship.values.mother","translations":{"contact":{"fr":"M\u00e8re","en":"Mother"}}};
export const RELATIONSHIP_VALUES_FATHER = {"id":"relationship.values.father","translations":{"contact":{"fr":"P\u00e8re","en":"Father"}}};
export const RELATIONSHIP_VALUES_DAUGHTER = {"id":"relationship.values.daughter","translations":{"contact":{"fr":"Fille","en":"Daughter"}}};
export const RELATIONSHIP_VALUES_SON = {"id":"relationship.values.son","translations":{"contact":{"fr":"Fils","en":"Son"}}};
export const RELATIONSHIP_VALUES_SISTER = {"id":"relationship.values.sister","translations":{"contact":{"fr":"S\u0153ur","en":"Sister"}}};
export const RELATIONSHIP_VALUES_BROTHER = {"id":"relationship.values.brother","translations":{"contact":{"fr":"Fr\u00e8re","en":"Brother"}}};
export const RELATIONSHIP_VALUES_HUSBAND = {"id":"relationship.values.husband","translations":{"contact":{"fr":"Mari","en":"Husband"}}};
export const RELATIONSHIP_VALUES_WIFE = {"id":"relationship.values.wife","translations":{"contact":{"fr":"\u00c9pouse","en":"Wife"}}};
export const RELATIONSHIP_VALUES_PARTNER = {"id":"relationship.values.partner","translations":{"contact":{"fr":"Partenaire","en":"Partner"}}};
export const RELATIONSHIP_VALUES_HALF_BROTHER_PATERNAL = {"id":"relationship.values.half_brother_paternal","translations":{"contact":{"fr":"Demi-fr\u00e8re paternel","en":"Paternal half-brother"}}};
export const RELATIONSHIP_VALUES_HALF_SISTER_PATERNAL = {"id":"relationship.values.half_sister_paternal","translations":{"contact":{"fr":"Demi-s\u0153ur paternelle","en":"Paternal half-sister"}}};
export const RELATIONSHIP_VALUES_PATERNAL_UNCLE = {"id":"relationship.values.paternal_uncle","translations":{"contact":{"fr":"Oncle paternel","en":"Paternal uncle"}}};
export const RELATIONSHIP_VALUES_PATERNAL_AUNT = {"id":"relationship.values.paternal_aunt","translations":{"contact":{"fr":"Tante paternelle","en":"Paternal aunt"}}};
export const RELATIONSHIP_VALUES_PATERNAL_GRANDFATHER = {"id":"relationship.values.paternal_grandfather","translations":{"contact":{"fr":"Grand-p\u00e8re paternel","en":"Paternal grandfather"}}};
export const RELATIONSHIP_VALUES_PATERNAL_GRANDMOTHER = {"id":"relationship.values.paternal_grandmother","translations":{"contact":{"fr":"Grand-m\u00e8re paternelle","en":"Paternal grandmother"}}};
export const RELATIONSHIP_VALUES_PATERNAL_COUSIN_MALE = {"id":"relationship.values.paternal_cousin_male","translations":{"contact":{"fr":"Cousin paternel","en":"Paternal cousin (male)"}}};
export const RELATIONSHIP_VALUES_PATERNAL_COUSIN_FEMALE = {"id":"relationship.values.paternal_cousin_female","translations":{"contact":{"fr":"Cousine paternelle","en":"Paternal cousin (female)"}}};
export const RELATIONSHIP_VALUES_HALF_BROTHER_MATERNAL = {"id":"relationship.values.half_brother_maternal","translations":{"contact":{"fr":"Demi-fr\u00e8re maternel","en":"Maternal half-brother"}}};
export const RELATIONSHIP_VALUES_HALF_SISTER_MATERNAL = {"id":"relationship.values.half_sister_maternal","translations":{"contact":{"fr":"Demi-s\u0153ur maternelle","en":"Maternal half-sister"}}};
export const RELATIONSHIP_VALUES_MATERNAL_UNCLE = {"id":"relationship.values.maternal_uncle","translations":{"contact":{"fr":"Oncle maternel","en":"Maternal uncle"}}};
export const RELATIONSHIP_VALUES_MATERNAL_AUNT = {"id":"relationship.values.maternal_aunt","translations":{"contact":{"fr":"Tante maternelle","en":"Maternal aunt"}}};
export const RELATIONSHIP_VALUES_MATERNAL_GRANDFATHER = {"id":"relationship.values.maternal_grandfather","translations":{"contact":{"fr":"Grand-p\u00e8re maternel","en":"Maternal grandfather"}}};
export const RELATIONSHIP_VALUES_MATERNAL_GRANDMOTHER = {"id":"relationship.values.maternal_grandmother","translations":{"contact":{"fr":"Grand-m\u00e8re maternelle","en":"Maternal grandmother"}}};
export const RELATIONSHIP_VALUES_MATERNAL_COUSIN_MALE = {"id":"relationship.values.maternal_cousin_male","translations":{"contact":{"fr":"Cousin maternel","en":"Maternal cousin (male)"}}};
export const RELATIONSHIP_VALUES_MATERNAL_COUSIN_FEMALE = {"id":"relationship.values.maternal_cousin_female","translations":{"contact":{"fr":"Cousine maternelle","en":"Maternal cousin (female)"}}};
export const RELATIONSHIP_VALUES_OTHER = {"id":"relationship.values.other","translations":{"contact":{"fr":"Autre","en":"Other"}}};
export const TYPE_DESCRIPTION = {"id":"type.description","translations":{"device":{"fr":"Le type d'appareil qui est utilis\u00e9","en":"The type of device being used"},"identity_document":{"fr":"Type de document","en":"Document type"},"medical_background":{"fr":"Le type d'ant\u00e9c\u00e9dent qui est cr\u00e9\u00e9","en":"The type of medical background being created"},"template_variable":{"fr":"Le type de variable","en":"The type of variable"}}};
export const TYPE_LABEL = {"id":"type.label","translations":{"device":{"fr":"Type d'appareil","en":"Device type"},"identity_document":{"fr":"S\u00e9lectionnez un type de document","en":"Select document type"},"medical_background":{"fr":"Type d'ant\u00e9c\u00e9dents","en":"Type of medical background"},"template_variable":{"fr":"Type de variable","en":"Type of variable"}}};
export const TYPE_PLACEHOLDER = {"id":"type.placeholder","translations":{"device":{"fr":"S\u00e9lectionnez un type d'appareil","en":"Select device type"},"identity_document":{"fr":"S\u00e9lectionnez un type","en":"Select type"},"medical_background":{"fr":"Choisissez le type d'ant\u00e9c\u00e9dent...","en":"Choose the type of medical background..."},"template_variable":{"fr":"S\u00e9lectionnez un type de variable","en":"Select type of variable"}}};
export const TYPE_MOBILE = {"id":"type.mobile","translations":{"device":{"fr":"Tout type de device mobile (android \/ ios)","en":"Any type of mobile device (android \/ ios)"}}};
export const TYPE_ANDROID = {"id":"type.android","translations":{"device":{"fr":"application sous Android","en":"Android application"}}};
export const TYPE_IOS = {"id":"type.ios","translations":{"device":{"fr":"Application sous IOS","en":"IOS application"}}};
export const TYPE_WEB = {"id":"type.web","translations":{"device":{"fr":"Tout type de web push notifications","en":"Any type of web push notifications"}}};
export const VISIBILITY_DESCRIPTION = {"id":"visibility.description","translations":{"document":{"fr":"Qui peut voir le document","en":"Who can see the document"},"event":{"fr":"Qui peut voir l'\u00e9v\u00e9nement dans son calendrier","en":"Who can see the appointment in their calendar"},"patient_note":{"fr":"Qui peut voir la note","en":"Who can see the note"},"patient_synopsis":{"fr":"Qui peut voir la note","en":"Who can see the note"},"patient_treatment":{"fr":"Qui peut voir le document"}}};
export const VISIBILITY_LABEL = {"id":"visibility.label","translations":{"document":{"fr":"Choisissez une visibilit\u00e9","en":"Select visibility"},"event":{"fr":"Visibilit\u00e9","en":"Visibility"},"patient_note":{"fr":"Choisissez une visibilit\u00e9","en":"Select visibility"},"patient_synopsis":{"fr":"Choisissez une visibilit\u00e9","en":"Select visibility"},"patient_treatment":{"fr":"Choisissez une visibilit\u00e9"}}};
export const VISIBILITY_PLACEHOLDER = {"id":"visibility.placeholder","translations":{"document":{"fr":"S\u00e9lectionnez une visibilit\u00e9","en":"Select visibility"},"event":{"fr":"S\u00e9lectionnez une visibilit\u00e9","en":"Select visibility"},"patient_note":{"fr":"S\u00e9lectionnez une visibilit\u00e9","en":"Select visibility"},"patient_synopsis":{"fr":"S\u00e9lectionnez une visibilit\u00e9","en":"Select visibility"},"patient_treatment":{"fr":"S\u00e9lectionnez une visibilit\u00e9"}}};
export const VISIBILITY1 = {"id":"visibility.1","translations":{"document":{"fr":"Visible par tous","en":"Visible by all"},"patient_note":{"fr":"Visible par tous","en":"Visible by everyone"},"patient_treatment":{"fr":"Visible par tous"}}};
export const VISIBILITY2 = {"id":"visibility.2","translations":{"document":{"fr":"Visible par les soignants","en":"Visible by caregivers"},"patient_note":{"fr":"Visible par les soignants","en":"Visible by caregivers"},"patient_treatment":{"fr":"Visible par les soignants"}}};
export const VISIBILITY3 = {"id":"visibility.3","translations":{"document":{"fr":"Visible par certains utilisateurs","en":"Visible by specific users"},"patient_note":{"fr":"Visible par certains utilisateurs","en":"Visible by certain users"},"patient_treatment":{"fr":"Visible par certains utilisateurs"}}};
export const VISIBILITY4 = {"id":"visibility.4","translations":{"document":{"fr":"Visible seulement par le patient et moi","en":"Visible only by the patient and me"},"patient_note":{"fr":"Visible seulement par le patient et moi","en":"Visible only by patient and me"}}};
export const VISIBILITY5 = {"id":"visibility.5","translations":{"document":{"fr":"Cach\u00e9","en":"Hidden"},"patient_note":{"fr":"Cach\u00e9","en":"Hidden"}}};
export const VISIBILITY6 = {"id":"visibility.6","translations":{"document":{"fr":"Visible par les soignants de l'\u00e9tablissement","en":"Visible by caregivers of facility"},"patient_note":{"fr":"Visible par les soignants de l'\u00e9tablissement","en":"Visible by caregivers of the facility"}}};
export const VISIBILITY7 = {"id":"visibility.7","translations":{"document":{"fr":"Visible par le patient et les soignants de l'\u00e9tablissement","en":"Visible by the patient and caregivers of facility"},"patient_note":{"fr":"Visible par le patient et les soignants de l'\u00e9tablissement","en":"Visible by the patient and caregivers of the facility"}}};
export const VISIBILITY11 = {"id":"visibility.11","translations":{"document":{"fr":"Visible par le patient et mes assistants","en":"Visible by the patient and my assistants"}}};
export const VISIBILITY_PUBLIC6 = {"id":"visibility.public.6","translations":{"document":{"fr":"Visible par moi et mon \u00e9quipe soignante","en":"Visible by my care team and me"},"patient_note":{"fr":"Visible par moi et mon \u00e9quipe soignante","en":"Visible by my care team and me"},"patient_treatment":{"fr":"Visible par moi et mon \u00e9quipe soignante"}}};
export const VISIBILITY_PUBLIC7 = {"id":"visibility.public.7","translations":{"document":{"fr":"Visible par le patient, moi et mon \u00e9quipe soignante","en":"Visible by the patient, my care team and me"},"patient_note":{"fr":"Visible par le patient, moi et mon \u00e9quipe soignante","en":"Visible by the patient, my care team and me"},"patient_treatment":{"fr":"Visible par le patient, moi et mon \u00e9quipe soignante"}}};
export const VISIBILITY_PATIENT1 = {"id":"visibility.patient.1","translations":{"document":{"fr":"Visible par tous","en":"Visible by all"},"patient_note":{"fr":"Visible par tous","en":"Visible by everyone"},"patient_treatment":{"fr":"Visible par tous"}}};
export const VISIBILITY_PATIENT3 = {"id":"visibility.patient.3","translations":{"document":{"fr":"Visible par certains utilisateurs","en":"Visible by specific users"},"patient_note":{"fr":"Visible par certains utilisateurs","en":"Visible by certain users"},"patient_treatment":{"fr":"Visible par certains utilisateurs"}}};
export const VISIBILITY_PATIENT4 = {"id":"visibility.patient.4","translations":{"document":{"fr":"Visible seulement par moi","en":"Visible only by me"},"patient_note":{"fr":"Visible seulement par moi","en":"Visible only by me"}}};
export const SHORT_VISIBILITY_DESCRIPTION = {"id":"short_visibility.description","translations":{"document":{"fr":"Version courte des visibilit\u00e9s","en":"Who can see the document"}}};
export const SHORT_VISIBILITY_LABEL = {"id":"short_visibility.label","translations":{"document":{"fr":"Choisissez une visibilit\u00e9","en":"Select visibility"}}};
export const SHORT_VISIBILITY_PLACEHOLDER = {"id":"short_visibility.placeholder","translations":{"document":{"fr":"S\u00e9lectionnez une visibilit\u00e9","en":"Select visibility"}}};
export const SHORT_VISIBILITY1 = {"id":"short_visibility.1","translations":{"document":{"fr":"Tous","en":"All"}}};
export const SHORT_VISIBILITY2 = {"id":"short_visibility.2","translations":{"document":{"fr":"Les soignants","en":"Caregivers"}}};
export const SHORT_VISIBILITY3 = {"id":"short_visibility.3","translations":{"document":{"fr":"Certains utilisateurs","en":"Specific users"}}};
export const SHORT_VISIBILITY4 = {"id":"short_visibility.4","translations":{"document":{"fr":"Le patient et moi","en":"Patient and me"}}};
export const SHORT_VISIBILITY5 = {"id":"short_visibility.5","translations":{"document":{"fr":"Cach\u00e9","en":"Hidden"}}};
export const SHORT_VISIBILITY6 = {"id":"short_visibility.6","translations":{"document":{"fr":"Soignants de l'\u00e9tablissement","en":"Facility caregivers"}}};
export const SHORT_VISIBILITY7 = {"id":"short_visibility.7","translations":{"document":{"fr":"Patient et ssoignants de l'\u00e9tablissement","en":"Patient and facility caregivers"}}};
export const SHORT_VISIBILITY11 = {"id":"short_visibility.11","translations":{"document":{"fr":"Patient et mes assistants","en":"Patient and my assistants"}}};
export const SHORT_VISIBILITY_PUBLIC6 = {"id":"short_visibility.public.6","translations":{"document":{"fr":"Moi et mon \u00e9quipe","en":"My care team and me"}}};
export const SHORT_VISIBILITY_PUBLIC7 = {"id":"short_visibility.public.7","translations":{"document":{"fr":"Patient, moi et mon \u00e9quipe","en":"Patient, my care team and me"}}};
export const SHORT_VISIBILITY_PATIENT1 = {"id":"short_visibility.patient.1","translations":{"document":{"fr":"Tous","en":"All"}}};
export const SHORT_VISIBILITY_PATIENT3 = {"id":"short_visibility.patient.3","translations":{"document":{"fr":"Certains utilisateurs","en":"Specific users"}}};
export const SHORT_VISIBILITY_PATIENT4 = {"id":"short_visibility.patient.4","translations":{"document":{"fr":"Seulement moi","en":"Only me"}}};
export const DICOM_TITLE = {"id":"dicom.title","translations":{"document":{"fr":"Imagerie RPO du {{ date }} {{ name }}","en":"RPO Imaging of {{ date }} {{ name }}"}}};
export const DEFAULT_VISIBILITY_DESCRIPTION = {"id":"default_visibility.description","translations":{"document_type":{"fr":"La visibilit\u00e9 par d\u00e9faut de tous les documents g\u00e9n\u00e9r\u00e9s","en":"The default visibility of all generated documents"}}};
export const DEFAULT_VISIBILITY_LABEL = {"id":"default_visibility.label","translations":{"document_type":{"fr":"Visibilit\u00e9 par d\u00e9faut","en":"Default Visibility"}}};
export const DEFAULT_VISIBILITY_PLACEHOLDER = {"id":"default_visibility.placeholder","translations":{"document_type":{"fr":"S\u00e9lectionnez une visibilit\u00e9 par d\u00e9faut","en":"Select default visibility"}}};
export const DEFAULT_VISIBILITY1 = {"id":"default_visibility.1","translations":{"document_type":{"fr":"Visible par tous","en":"Visible by all"}}};
export const DEFAULT_VISIBILITY2 = {"id":"default_visibility.2","translations":{"document_type":{"fr":"Visible par les soignants","en":"Visible by caregivers"}}};
export const DEFAULT_VISIBILITY3 = {"id":"default_visibility.3","translations":{"document_type":{"fr":"Visible par certains utilisateurs","en":"Visible by certain users"}}};
export const DEFAULT_VISIBILITY4 = {"id":"default_visibility.4","translations":{"document_type":{"fr":"Visible seulement par le patient et moi","en":"Visible only by the patient and me"}}};
export const DEFAULT_VISIBILITY5 = {"id":"default_visibility.5","translations":{"document_type":{"fr":"Cach\u00e9","en":"Hidden"}}};
export const DEFAULT_VISIBILITY6 = {"id":"default_visibility.6","translations":{"document_type":{"fr":"Visible par les soignants de l'\u00e9tablissement","en":"Visible by caregivers of facility"}}};
export const DEFAULT_VISIBILITY7 = {"id":"default_visibility.7","translations":{"document_type":{"fr":"Visible par le patient et les soignants de l'\u00e9tablissement","en":"Visible by the patient and caregivers of facility"}}};
export const DOCUMENTS_DOC = {"id":"documents.doc","translations":{"documents":{"fr":"Document","en":"Document"}}};
export const DOCUMENTS_DOCUMENTS = {"id":"documents.documents","translations":{"documents":{"fr":"Documents","en":"Documents"}}};
export const DOCUMENTS_DOCUMENT = {"id":"documents.document","translations":{"documents":{"fr":"Document","en":"Document"}}};
export const DOCUMENTS_STAFF_TITLE = {"id":"documents.staff_title","translations":{"documents":{"fr":"Documents de $patient","en":"Documents of $patient"}}};
export const DOCUMENTS_FILE_TITLE = {"id":"documents.file_title","translations":{"documents":{"fr":"Dossier de $patient","en":"$patient's medical record"}}};
export const DOCUMENTS_MY_FILE = {"id":"documents.my_file","translations":{"documents":{"fr":"Mon dossier","en":"My medical record"}}};
export const DOCUMENTS_FILE_TITLE_SPAN = {"id":"documents.file_title_span","translations":{"documents":{"fr":"Dossier de <span class=\"profil-fullName\"> $patient <\/span>","en":"<span class=\"profil-fullName\"> $patient <\/span>'s medical record"}}};
export const DOCUMENTS_TITLE = {"id":"documents.title","translations":{"documents":{"fr":"Mes documents ","en":"My documents "}}};
export const DOCUMENTS_NOTE = {"id":"documents.note","translations":{"documents":{"fr":"Note","en":"Note"}}};
export const DOCUMENTS_DRAG_FILE = {"id":"documents.drag_file","translations":{"documents":{"fr":"D\u00e9posez vos fichiers ici","en":"Drag and drop your files here"}}};
export const DOCUMENTS_ADD_DOC = {"id":"documents.add_doc","translations":{"documents":{"fr":"Ajouter un document","en":"Add document"}}};
export const DOCUMENTS_ADD_FILE = {"id":"documents.add_file","translations":{"documents":{"fr":"Ajouter des fichiers","en":"Add files"}}};
export const DOCUMENTS_BACK_FILE = {"id":"documents.back_file","translations":{"documents":{"fr":"Retour au dossier","en":"Back to record"}}};
export const DOCUMENTS_REPORT = {"id":"documents.report","translations":{"documents":{"fr":"Compte-Rendu","en":"Medical report"}}};
export const DOCUMENTS_CREATE_REPORT = {"id":"documents.create_report","translations":{"documents":{"fr":"Cr\u00e9er un compte-rendu","en":"Create medical report"}}};
export const DOCUMENTS_PRESCRIPTION = {"id":"documents.prescription","translations":{"documents":{"fr":"Ordonnance","en":"Prescription"}}};
export const DOCUMENTS_CREATE_PRESCRIPTION = {"id":"documents.create_prescription","translations":{"documents":{"fr":"Cr\u00e9er une ordonnance","en":"Create prescription"}}};
export const DOCUMENTS_PRESCRIPTION_INFOS = {"id":"documents.prescription_infos","translations":{"documents":{"fr":"Ordonnance de $user du $date","en":"Prescription of $user from $date"}}};
export const DOCUMENTS_CREATE_DOC = {"id":"documents.create_doc","translations":{"documents":{"fr":"Cr\u00e9er un document","en":"Create document"}}};
export const DOCUMENTS_CREATE_CERFA = {"id":"documents.create_cerfa","translations":{"documents":{"fr":"Cr\u00e9er un CERFA","en":"Create CERFA"}}};
export const DOCUMENTS_DOC_NAME = {"id":"documents.doc_name","translations":{"documents":{"fr":"Nom du document","en":"Document name"}}};
export const DOCUMENTS_DOC_TYPE = {"id":"documents.doc_type","translations":{"documents":{"fr":"Type de document","en":"Document type"}}};
export const DOCUMENTS_TYPE_NAME = {"id":"documents.type_name","translations":{"documents":{"fr":"Nom du type de document","en":"Document type name"}}};
export const DOCUMENTS_TYPE_SLUG = {"id":"documents.type_slug","translations":{"documents":{"fr":"Slug du type de document","en":"Document type slug"}}};
export const DOCUMENTS_LOINC_ID = {"id":"documents.loinc_id","translations":{"documents":{"fr":"Id LOINC du document","en":"Document LOINC ID"}}};
export const DOCUMENTS_CHILDREN_TYPE = {"id":"documents.children_type","translations":{"documents":{"fr":"Types enfant","en":"Child types"}}};
export const DOCUMENTS_PARENT_TYPE = {"id":"documents.parent_type","translations":{"documents":{"fr":"Type parent","en":"Parent type"}}};
export const DOCUMENTS_AI_DESCRIPTION = {"id":"documents.ai_description","translations":{"documents":{"fr":"Exemples de documents dans ce type","en":"Examples of documents in this type"}}};
export const DOCUMENTS_NAME_FORMAT = {"id":"documents.name_format","translations":{"documents":{"fr":"Format du nom utilis\u00e9 pour ce type de document","en":"Name format used for this type of document"}}};
export const DOCUMENTS_DOC_OWNER = {"id":"documents.doc_owner","translations":{"documents":{"fr":"Propri\u00e9taire","en":"Owner"}}};
export const DOCUMENTS_DOC_TITLE = {"id":"documents.doc_title","translations":{"documents":{"fr":"Titre du document","en":"Document title"}}};
export const DOCUMENTS_DOC_CONTENT = {"id":"documents.doc_content","translations":{"documents":{"fr":"Contenu du document","en":"Document content"}}};
export const DOCUMENTS_OWNER_NAME = {"id":"documents.owner_name","translations":{"documents":{"fr":"Nom du propri\u00e9taire","en":"Owner name"}}};
export const DOCUMENTS_DOC_DATE = {"id":"documents.doc_date","translations":{"documents":{"fr":"Date du document","en":"Document date"}}};
export const DOCUMENTS_DOC_TEMPLATE = {"id":"documents.doc_template","translations":{"documents":{"fr":"Mod\u00e8le de document","en":"Document template"}}};
export const DOCUMENTS_TEMPLATE_NAME = {"id":"documents.template_name","translations":{"documents":{"fr":"Nom du mod\u00e8le","en":"Template name"}}};
export const DOCUMENTS_PUBLICATION_DATE = {"id":"documents.publication_date","translations":{"documents":{"fr":"Date de mise en ligne : ","en":"Uploaded date : "}}};
export const DOCUMENTS_VISIBILITY = {"id":"documents.visibility","translations":{"documents":{"fr":"Visibilit\u00e9","en":"Visibility"}}};
export const DOCUMENTS_SHARED_WITH = {"id":"documents.shared_with","translations":{"documents":{"fr":"Partag\u00e9 avec","en":"Shared with"}}};
export const DOCUMENTS_ALL_OWNER = {"id":"documents.all_owner","translations":{"documents":{"fr":"Tous les propri\u00e9taires","en":"All owners"}}};
export const DOCUMENTS_CHOSE_OWNER = {"id":"documents.chose_owner","translations":{"documents":{"fr":"Choisissez le propri\u00e9taire de ce document","en":"Choose owner of this document"}}};
export const DOCUMENTS_SOURCE = {"id":"documents.source","translations":{"documents":{"fr":"Source","en":"Source"}}};
export const DOCUMENTS_DOPASOIN = {"id":"documents.dopasoin","translations":{"documents":{"fr":"Dopasoin","en":"Dopasoin"}}};
export const DOCUMENTS_SERVICES = {"id":"documents.services","translations":{"documents":{"fr":"Services","en":"Departments"}}};
export const DOCUMENTS_ALL_SOURCES = {"id":"documents.all_sources","translations":{"documents":{"fr":"Toutes les sources","en":"All sources"}}};
export const DOCUMENTS_NAME_PLACEHOLDER = {"id":"documents.name_placeholder","translations":{"documents":{"fr":"Recherche par nom","en":"Search by name"}}};
export const DOCUMENTS_PATIENT = {"id":"documents.patient","translations":{"documents":{"fr":"Patient","en":"Patient"}}};
export const DOCUMENTS_NOT_FOUND = {"id":"documents.not_found","translations":{"documents":{"fr":"Aucun document trouv\u00e9","en":"No documents found"}}};
export const DOCUMENTS_SURVEY = {"id":"documents.survey","translations":{"documents":{"fr":"Questionnaire","en":"Questionnaire"}}};
export const DOCUMENTS_SUBJECT = {"id":"documents.subject","translations":{"documents":{"fr":"Objet de la discussion","en":"Topic subject"}}};
export const DOCUMENTS_MESSAGE = {"id":"documents.message","translations":{"documents":{"fr":"Votre message","en":"Your message"}}};
export const DOCUMENTS_FOR_USER = {"id":"documents.for_user","translations":{"documents":{"fr":" pour $patient","en":" for $patient"}}};
export const DOCUMENTS_NEW_DOC = {"id":"documents.new_doc","translations":{"documents":{"fr":"Nouveau document","en":"New document"}}};
export const DOCUMENTS_NEW_PRESCRIPTION = {"id":"documents.new_prescription","translations":{"documents":{"fr":"Nouvelle ordonnance","en":"New prescription"}}};
export const DOCUMENTS_NEW_REPORT = {"id":"documents.new_report","translations":{"documents":{"fr":"Nouveau compte-rendu","en":"New medical report"}}};
export const DOCUMENTS_REPORT_INFOS = {"id":"documents.report_infos","translations":{"documents":{"fr":"Compte rendu de $user du $date \u00e0 $hour","en":"Medical report of $user from $date to $hour"}}};
export const DOCUMENTS_DOC_EDITION = {"id":"documents.doc_edition","translations":{"documents":{"fr":"Edition de document","en":"Document editing"}}};
export const DOCUMENTS_BACK_CONSULT = {"id":"documents.back_consult","translations":{"documents":{"fr":"Retour \u00e0 la consultation","en":"Back to consultation"}}};
export const DOCUMENTS_BACK_DOC = {"id":"documents.back_doc","translations":{"documents":{"fr":"Retour aux documents","en":"Back to documents"}}};
export const DOCUMENTS_TEMPLATE_PARAM = {"id":"documents.template_param","translations":{"documents":{"fr":"Param\u00e8tres des mod\u00e8les","en":"Template settings"}}};
export const DOCUMENTS_MISSING_DATA = {"id":"documents.missing_data","translations":{"documents":{"fr":"Donn\u00e9e manquante","en":"Missing data"}}};
export const DOCUMENTS_LETTERHEAD = {"id":"documents.letterhead","translations":{"documents":{"fr":"Papier \u00e0 en-t\u00eate","en":"Letterhead"}}};
export const DOCUMENTS_INTELIGNES = {"id":"documents.intelignes","translations":{"documents":{"fr":"Intelignes","en":"Line spacing"}}};
export const DOCUMENTS_NEW_VERSION = {"id":"documents.new_version","translations":{"documents":{"fr":"Nouvelle version","en":"New version"}}};
export const DOCUMENTS_OLD_VERSION = {"id":"documents.old_version","translations":{"documents":{"fr":"Ancienne version","en":"Old version"}}};
export const DOCUMENTS_OVERRIDE = {"id":"documents.override","translations":{"documents":{"fr":"\u00c9crasable","en":"Overwritable"}}};
export const DOCUMENTS_UPDATED = {"id":"documents.updated","translations":{"documents":{"fr":"Le document a bien \u00e9t\u00e9 modifi\u00e9","en":"Document successfully updated."}}};
export const DOCUMENTS_VARIABLES = {"id":"documents.variables","translations":{"documents":{"fr":"Variables du document","en":"Document variables"}}};
export const DOCUMENTS_SHORT_VARIABLES = {"id":"documents.short_variables","translations":{"documents":{"fr":"Variables","en":"Variables"}}};
export const DOCUMENTS_MONOGRAPHY = {"id":"documents.monography","translations":{"documents":{"fr":"Monographie","en":"Monography"}}};
export const DOCUMENTS_RENEW = {"id":"documents.renew","translations":{"documents":{"fr":"Le document a bien \u00e9t\u00e9 renouvel\u00e9","en":"Document successfully renewed."}}};
export const DOCUMENTS_DOCS_UPLOADED = {"id":"documents.docs_uploaded","translations":{"documents":{"fr":"Les documents ont bien \u00e9t\u00e9 upload\u00e9","en":"The documents were successfully uploaded"}}};
export const DOCUMENTS_DOC_UPLOADED = {"id":"documents.doc_uploaded","translations":{"documents":{"fr":"Le document a bien \u00e9t\u00e9 upload\u00e9","en":"Document successfully uploaded"}}};
export const DOCUMENTS_UPLOADED = {"id":"documents.uploaded","translations":{"documents":{"fr":"Vos documents ont \u00e9t\u00e9 t\u00e9l\u00e9charg\u00e9s","en":"Your documents have been downloaded"}}};
export const DOCUMENTS_SORT = {"id":"documents.sort","translations":{"documents":{"fr":"Vos documents vont \u00eatre tri\u00e9s automatiquement","en":"Your documents will be sorted automatically"}}};
export const DOCUMENTS_SELECT_OWNER = {"id":"documents.select_owner","translations":{"documents":{"fr":"Veuillez choisir un propri\u00e9taire","en":"Please choose an owner"}}};
export const DOCUMENTS_EDITED = {"id":"documents.edited","translations":{"documents":{"fr":"Les documents ont bien \u00e9t\u00e9 modifi\u00e9s","en":"Documents have been successfully modified"}}};
export const DOCUMENTS_PDF_GENERATED = {"id":"documents.pdf_generated","translations":{"documents":{"fr":"Le pdf a bien \u00e9t\u00e9 g\u00e9n\u00e9r\u00e9","en":"The pdf was successfully generated"}}};
export const DOCUMENTS_ASSISTANTS_RIGHTS = {"id":"documents.assistants_rights","translations":{"documents":{"fr":"Les droits li\u00e9s \u00e0 vos assistants ont \u00e9t\u00e9 mis \u00e0 jour.","en":"Your assistants rights have been updated."}}};
export const DOCUMENTS_COLLEAGUE_RIGHTS = {"id":"documents.colleague_rights","translations":{"documents":{"fr":"Les droits li\u00e9s \u00e0 vos coll\u00e8gues ont \u00e9t\u00e9 mis \u00e0 jour.","en":"Your colleagues rights have been updated."}}};
export const DOCUMENTS_USER_RIGHTS = {"id":"documents.user_rights","translations":{"documents":{"fr":"Les droits li\u00e9s \u00e0 $user assistants ont \u00e9t\u00e9 mis \u00e0 jour.","en":"$user right have been updated."}}};
export const DOCUMENTS_QUESTIONNAIRE_SAVED = {"id":"documents.questionnaire_saved","translations":{"documents":{"fr":"Le questionnaire a bien \u00e9t\u00e9 enregistr\u00e9","en":"Your questionnaire has been successfully saved"}}};
export const DOCUMENTS_NOTES = {"id":"documents.notes","translations":{"documents":{"fr":"Notes","en":"Notes"}}};
export const DOCUMENTS_NEW_NOTE = {"id":"documents.new_note","translations":{"documents":{"fr":"Nouvelle note","en":"New note"}}};
export const DOCUMENTS_NOTE_SAVED = {"id":"documents.note_saved","translations":{"documents":{"fr":"La note a bien \u00e9t\u00e9 enregistr\u00e9e.","en":"The note was successfully saved."}}};
export const DOCUMENTS_NO_NOTE = {"id":"documents.no_note","translations":{"documents":{"fr":"Aucune note renseign\u00e9e","en":"No notes"}}};
export const DOCUMENTS_LAST_SAVE_AT = {"id":"documents.last_save_at","translations":{"documents":{"fr":"Derni\u00e8re sauvegarde \u00e0 ","en":"Last backed up on "}}};
export const DOCUMENTS_VIEW_DOCUMENT = {"id":"documents.view_document","translations":{"documents":{"fr":"Voir le document","en":"View document"}}};
export const DOCUMENTS_LINKED_QUESTIONNAIRES = {"id":"documents.linked_questionnaires","translations":{"documents":{"fr":"Questionnaire(s) li\u00e9(s) au patient","en":"Questionnaires linked to patient"}}};
export const DOCUMENTS_LINKED_QUESTIONNAIRES_UPDATED = {"id":"documents.linked_questionnaires_updated","translations":{"documents":{"fr":"Les questionnaires li\u00e9s ont bien \u00e9t\u00e9 modifi\u00e9s","en":"Linked questionnaires have been updated"}}};
export const DOCUMENTS_NOTE_DRAFT = {"id":"documents.note_draft","translations":{"documents":{"fr":"Note (voir le brouillon)","en":"Note (view draft)"}}};
export const DOCUMENTS_GENERATED = {"id":"documents.generated","translations":{"documents":{"fr":"Document g\u00e9n\u00e9r\u00e9 par $generator","en":"Document generated by $generator"}}};
export const DOCUMENTS_SYNOPSIS = {"id":"documents.synopsis","translations":{"documents":{"fr":"Synopsis de $name","en":"$name synopsis"}}};
export const DOCUMENTS_SYNOPSIS_CONTENT = {"id":"documents.synopsis_content","translations":{"documents":{"fr":"Contenu du synopsis","en":"Content"}}};
export const DOCUMENTS_SYNOPSIS_VISIBILITY = {"id":"documents.synopsis_visibility","translations":{"documents":{"fr":"Visibilit\u00e9 du synopsis","en":"Visbility"}}};
export const DOCUMENTS_DRAFT = {"id":"documents.draft","translations":{"documents":{"fr":"Brouillon","en":"Draft"}}};
export const DOCUMENTS_TEXT_EDITOR = {"id":"documents.text_editor","translations":{"documents":{"fr":"Editeur de texte","en":"Text editor"}}};
export const DOCUMENTS_TEXT_ZONE = {"id":"documents.text_zone","translations":{"documents":{"fr":"Zone de texte simple","en":"Simple text field"}}};
export const DOCUMENTS_ANNEXES = {"id":"documents.annexes","translations":{"documents":{"fr":"Annexes","en":"Attachments"}}};
export const DOCUMENTS_SHARED_BY = {"id":"documents.shared_by","translations":{"documents":{"fr":"Partag\u00e9 par $name","en":"Shared by $name"}}};
export const DOCUMENTS_EXPLANATION_TYPE = {"id":"documents.explanation_type","translations":{"documents":{"fr":"Texte explicatif","en":"Explanatory text"}}};
export const DOCUMENTS_QUOTE = {"id":"documents.quote","translations":{"documents":{"fr":"Devis","en":"Quote"}}};
export const DOCUMENTS_OWNER_TYPE_INSTAMED = {"id":"documents.owner_type.instamed","translations":{"documents":{"fr":"Instamed","en":"Instamed"}}};
export const DOCUMENTS_OWNER_TYPE_ASSISTANTS = {"id":"documents.owner_type.assistants","translations":{"documents":{"fr":"Assistant(e)s","en":"Assistants"}}};
export const DOCUMENTS_OWNER_TYPE_NOBODY = {"id":"documents.owner_type.nobody","translations":{"documents":{"fr":"Personne","en":"No one"}}};
export const DOCUMENTS_OWNER_TYPE_ALL = {"id":"documents.owner_type.all","translations":{"documents":{"fr":"Tous","en":"All"}}};
export const DOCUMENTS_OWNER_TYPE_DOCTORS = {"id":"documents.owner_type.doctors","translations":{"documents":{"fr":"M\u00e9decins","en":"Doctors"}}};
export const DOCUMENTS_HELPER_SAFE_TEMPLATE = {"id":"documents.helper.safe_template","translations":{"documents":{"fr":"En cliquant sur <strong>Sauvegarder le mod\u00e8le existant<\/strong>, vous allez \u00e9craser le mod\u00e8le existant <strong class='model-name'><\/strong> avec le contenu de l'\u00e9diteur.","en":"By clicking <strong>Save the existing template<\/strong>, you will overwrite the existing template <strong class='model-name'><\/strong> with content from editor."}}};
export const DOCUMENTS_HELPER_CHECK_VARIABLES = {"id":"documents.helper.check_variables","translations":{"documents":{"fr":"Veuillez v\u00e9rifier que l'\u00e9diteur ne contient aucune donn\u00e9e personnelle de patient hors variables.","en":"Please ensure that editor contains no personal patient data excluding variables."}}};
export const DOCUMENTS_HELPER_VARIABLES_UPDATED = {"id":"documents.helper.variables_updated","translations":{"documents":{"fr":"Le type de variable a bien \u00e9t\u00e9 modifi\u00e9","en":"Variable type was successfully modified"}}};
export const DOCUMENTS_HELPER_ENTER_TEMPLATE_NAME = {"id":"documents.helper.enter_template_name","translations":{"documents":{"fr":"Veuillez saisir le nom de votre nouveau mod\u00e8le de document.","en":"Please enter name of your new document template."}}};
export const DOCUMENTS_HELPER_CREATE_TEMPLATE = {"id":"documents.helper.create_template","translations":{"documents":{"fr":"Veuillez cr\u00e9er un mod\u00e8le pour continuer","en":"Please create a template to continue"}}};
export const DOCUMENTS_HELPER_NO_TEMPLATE = {"id":"documents.helper.no_template","translations":{"documents":{"fr":"Vous n'avez pas encore cr\u00e9\u00e9 de mod\u00e8les pour ce type de document.","en":"You have not yet created templates for this document type."}}};
export const DOCUMENTS_HELPER_FILL_SEX = {"id":"documents.helper.fill_sex","translations":{"documents":{"fr":"Le mod\u00e8le de document que vous utilisez dispose de donn\u00e9es dynamiques en fonction du sexe du patient. Veuillez renseigner ce dernier.","en":"The document template you are using contains dynamic data based on patient's gender. Please specify patient's gender."}}};
export const DOCUMENTS_HELPER_GENERATED_BACKGROUND = {"id":"documents.helper.generated_background","translations":{"documents":{"fr":"Les suggestions seront g\u00e9n\u00e9r\u00e9es en arri\u00e8re-plan pour ne pas perturber votre travail","en":"Suggestions will be generated in background to avoid interrupting your work."}}};
export const DOCUMENTS_HELPER_EMPTY = {"id":"documents.helper.empty","translations":{"documents":{"fr":"Aucune adresse renseign\u00e9e","en":"No address provided"}}};
export const DOCUMENTS_HELPER_NO_PATIENT_APPROVAL = {"id":"documents.helper.no_patient_approval","translations":{"documents":{"fr":"Ce patient ne vous autorise pas \u00e0 partager son dossier m\u00e9dical.","en":"This patient does not allow you to share his medical record."}}};
export const DOCUMENTS_HELPER_LOCKED_PROFILE = {"id":"documents.helper.locked_profile","translations":{"documents":{"fr":"Votre dossier m\u00e9dical ne peut \u00eatre partag\u00e9 avec un nouveau professionnel de sant\u00e9. Cliquez ici pour d\u00e9verrouiller le partage.","en":"Your medical record cannot be shared with a new caregiver. Click here to unlock sharing."}}};
export const DOCUMENTS_HELPER_LATEST_VERSION = {"id":"documents.helper.latest_version","translations":{"documents":{"fr":"Une version plus r\u00e9cente de ce formulaire a \u00e9t\u00e9 trouv\u00e9e, voulez-vous la charger ?","en":"A newer version of this form was found, do you want to load it?"}}};
export const DOCUMENTS_HELPER_SELECT_SUPPORTED_FILE = {"id":"documents.helper.select_supported_file","translations":{"documents":{"fr":"Veuillez s\u00e9lectionner un fichier dont le format est pris en charge (image, document...)","en":"Please select file in a supported format (image, document, etc.)"}}};
export const DOCUMENTS_HELPER_TOO_VOLUMINOUS = {"id":"documents.helper.too_voluminous","translations":{"documents":{"fr":"Ce fichier est trop volumineux. La taille maximale accept\u00e9e est de 8 Mo.","en":"This file is too large. Maximum size is 8 Mo."}}};
export const DOCUMENTS_HELPER_EDIT_TYPE = {"id":"documents.helper.edit_type","translations":{"documents":{"fr":"Vous ne pouvez pas s\u00e9lectionner un type diff\u00e9rent","en":"You cannot select a different type"}}};
export const DOCUMENTS_HELPER_OVERRIDE = {"id":"documents.helper.override","translations":{"documents":{"fr":"Le contenu de ce champ sera \u00e9cras\u00e9 en cas de changement de mod\u00e8le","en":"The content of this field will be overwritten when changing template"}}};
export const DOCUMENTS_HELPER_REQUIRED = {"id":"documents.helper.required","translations":{"documents":{"fr":"Ce champ est requis","en":"This field is required"}}};
export const DOCUMENTS_HELPER_HOOKS_NUMBER = {"id":"documents.helper.hooks_number","translations":{"documents":{"fr":"Le nombre de crochets ouvrants et fermants est diff\u00e9rent","en":"The number of opened and closed brackets is different"}}};
export const DOCUMENTS_HELPER_FILLABLE = {"id":"documents.helper.fillable","translations":{"documents":{"fr":"Aucun champ n'est personnalisable dans ce mod\u00e8le","en":"No field can be customized in this template"}}};
export const DOCUMENTS_HELPER_NOT_PROPERLY_FILLED = {"id":"documents.helper.not_properly_filled","translations":{"documents":{"fr":"Un ou plusieurs champ n'est pas correctement remplis","en":"One or more fields is not correctly filled in"}}};
export const DOCUMENTS_HELPER_MAX_FILES = {"id":"documents.helper.max_files","translations":{"documents":{"fr":"Vous ne pouvez pas s\u00e9lectionner plus de $count fichiers \u00e0 la fois.","en":"You cannot select more than $count files at once."}}};
export const DOCUMENTS_HELPER_POPUP_BLOCKED = {"id":"documents.helper.popup_blocked","translations":{"documents":{"fr":"Les pop-up sont bloqu\u00e9es pour ce site, merci de les autoriser afin d'activer le t\u00e9l\u00e9chargement.","en":"Pop-ups are blocked for this site. Please enable them to activate download."}}};
export const DOCUMENTS_HELPER_NOTE_DELETED = {"id":"documents.helper.note_deleted","translations":{"documents":{"fr":"\u00cates-vous s\u00fbr de vouloir supprimer cette note ?","en":"Are you sure you want to delete this note?"}}};
export const DOCUMENTS_HELPER_NOTE_SAVED = {"id":"documents.helper.note_saved","translations":{"documents":{"fr":"Votre note a bien \u00e9t\u00e9 enregistr\u00e9e","en":"Your note has been saved"}}};
export const DOCUMENTS_HELPER_MODIFICATIONS_SAVED = {"id":"documents.helper.modifications_saved","translations":{"documents":{"fr":"Les modifications ont \u00e9t\u00e9 enregistr\u00e9es","en":"Changes have been saved"}}};
export const DOCUMENTS_HELPER_DOC_DELETE = {"id":"documents.helper.doc_delete","translations":{"documents":{"fr":"\u00cates-vous s\u00fbr de supprimer ce document?","en":"Are you sure you want to delete this document?"}}};
export const DOCUMENTS_HELPER_DOC_DELETED = {"id":"documents.helper.doc_deleted","translations":{"documents":{"fr":"Le document a bien \u00e9t\u00e9 supprim\u00e9","en":"The document has been deleted"}}};
export const DOCUMENTS_HELPER_VALUE_DELETE = {"id":"documents.helper.value_delete","translations":{"documents":{"fr":"\u00cates-vous s\u00fbr que vous souhaitez supprimer cette valeur ?","en":"Are you sure you want to delete this value?"}}};
export const DOCUMENTS_HELPER_SYNOPSIS_DELETED = {"id":"documents.helper.synopsis_deleted","translations":{"documents":{"fr":"Le synopsis a bien \u00e9t\u00e9 supprim\u00e9","en":"The synopsis was deleted"}}};
export const DOCUMENTS_HELPER_DELETE_DRAFT = {"id":"documents.helper.delete_draft","translations":{"documents":{"fr":"\u00cates-vous s\u00fbr de vouloir supprimer ces brouillons ? Cette action est irr\u00e9versible","en":"Are you sure you want to delete these drafts? This action cannot be undone."}}};
export const DOCUMENTS_HELPER_DRAFT_DELETED = {"id":"documents.helper.draft_deleted","translations":{"documents":{"fr":"Les brouillons ont bien \u00e9t\u00e9 supprim\u00e9s","en":"These drafts are successfully deleted"}}};
export const DOCUMENTS_HELPER_DELETE_DOC = {"id":"documents.helper.delete_doc","translations":{"documents":{"fr":"\u00cates-vous s\u00fbr de vouloir supprimer ces documents ? Cette action est irr\u00e9versible","en":"Are you sure you want to delete these documents? This action cannot be undone."}}};
export const DOCUMENTS_HELPER_FILES_ADDED = {"id":"documents.helper.files_added","translations":{"documents":{"fr":"Les fichiers seront ajout\u00e9s dans le dossier du patient","en":"Files will be added to the patient's record"}}};
export const DOCUMENTS_HELPER_TYPE_MERGED = {"id":"documents.helper.type_merged","translations":{"documents":{"fr":"Les types de document ont bien \u00e9t\u00e9 fusionn\u00e9s","en":"Document types successfully merged"}}};
export const DOCUMENTS_HELPER_TYPE_DELETE = {"id":"documents.helper.type_delete","translations":{"documents":{"fr":"\u00cates-vous s\u00fbr de vouloir supprimer ce type de document ?","en":"Are you sure you want to delete this document type?"}}};
export const DOCUMENTS_HELPER_TYPE_DELETED = {"id":"documents.helper.type_deleted","translations":{"documents":{"fr":"Le type de document a bien \u00e9t\u00e9 supprim\u00e9","en":"Document type successfully deleted"}}};
export const DOCUMENTS_HELPER_TYPE_DISABLED = {"id":"documents.helper.type_disabled","translations":{"documents":{"fr":"Le type de document a bien \u00e9t\u00e9 retir\u00e9 de votre environnement","en":"Document type successfully removed from your environment"}}};
export const DOCUMENTS_HELPER_TYPE_ADDED = {"id":"documents.helper.type_added","translations":{"documents":{"fr":"Le type de document a bien \u00e9t\u00e9 ajout\u00e9 \u00e0 votre environnement","en":"Document type was successfully added to your environment"}}};
export const DOCUMENTS_HELPER_TYPE_SAVED = {"id":"documents.helper.type_saved","translations":{"documents":{"fr":"Le type de document a bien \u00e9t\u00e9 enregistr\u00e9","en":"Document type saved successfully"}}};
export const DOCUMENTS_HELPER_ERROR_IMAGE_TYPE = {"id":"documents.helper.error_image_type","translations":{"documents":{"fr":"Le fichier doit \u00eatre une image de format jpeg ou png, $type n'est pas autoris\u00e9","en":"File must be a jpeg or png image, $type is not allowed"}}};
export const DOCUMENTS_HELPER_ERROR_IMAGE_SIZE = {"id":"documents.helper.error_image_size","translations":{"documents":{"fr":"Le fichier image est trop gros, la taille maximum autoris\u00e9e est de 3Mo","en":"The image file is too large. The maximum allowed size is 3MB."}}};
export const DOCUMENTS_HELPER_REMOVE_VALUE = {"id":"documents.helper.remove_value","translations":{"documents":{"fr":"Pour retirer une valeur, maintenez \"ctrl\" ou \"cmd\" puis cliquez sur la valeur choisie","en":"To remove value, hold \"ctrl\" or \"cmd\" and click on selected value."}}};
export const DOCUMENTS_HELPER_FILE_TO_UPLOAD = {"id":"documents.helper.file_to_upload","translations":{"documents":{"fr":"Veuillez choisir un fichier \u00e0 uploader","en":"Please select file to upload"}}};
export const DOCUMENTS_HELPER_CSV_FILE = {"id":"documents.helper.csv_file","translations":{"documents":{"fr":"Veuillez choisir un fichier CSV","en":"Please select CSV file"}}};
export const DOCUMENTS_HELPER_WARNING_UNCHECK = {"id":"documents.helper.warning_uncheck","translations":{"documents":{"fr":"D\u00e9cocher cette option risque d'entrainer des probl\u00e8mes d'affichage dans les interlignes de vos documents. \u00cates-vous s\u00fbr de continuer ?","en":"Unchecking this option may cause display issues with line spacing in your documents. Are you sure you want to proceed?"}}};
export const DOCUMENTS_HELPER_SUPPORTED_TYPE = {"id":"documents.helper.supported_type","translations":{"documents":{"fr":"Veuillez s\u00e9lectionner un fichier dont le format est pris en charge (image, document...).","en":"Please select a file in a supported format (e.g., image, document, etc.)."}}};
export const DOCUMENTS_HELPER_MAX_SIZE = {"id":"documents.helper.max_size","translations":{"documents":{"fr":"Ce fichier est trop volumineux. La taille maximale accept\u00e9e est de 8 Mo.","en":"This file is too large. Maximum accepted size is 8 MB."}}};
export const DOCUMENTS_HELPER_MAX_SIZE_MESSAGE = {"id":"documents.helper.max_size_message","translations":{"documents":{"fr":"Ce fichier est trop volumineux. La taille maximale accept\u00e9e est de 2 Mo","en":"This file is too large. Maximum size allowed is 2 MB."}}};
export const DOCUMENTS_HELPER_AI_DESCRIPTION = {"id":"documents.helper.ai_description","translations":{"documents":{"fr":"Ce champ est utilis\u00e9 pour le classement avec Lia","en":"This field is used for Lia classification"}}};
export const DOCUMENTS_HELPER_NAME_FORMAT = {"id":"documents.helper.name_format","translations":{"documents":{"fr":"Ce champ est utilis\u00e9 pour le classement avec Lia","en":"This field is used for Lia classification"}}};
export const DOCUMENTS_HELPER_CREATABLE = {"id":"documents.helper.creatable","translations":{"documents":{"fr":"Ce type de document peut \u00eatre cr\u00e9\u00e9 via l'\u00e9diteur","en":"This document type can be created with the editor"}}};
export const DOCUMENTS_HELPER_VARIABLE_EDITION = {"id":"documents.helper.variable_edition","translations":{"documents":{"fr":"Si vous ne trouvez pas votre variable, recherchez la variable \u00e0 l\u2019aide de la barre de recherche.","en":"If you can't find your variable, search for it using the search bar."}}};
export const DOCUMENTS_ACTIONS_TITLE = {"id":"documents.actions.title","translations":{"documents":{"fr":"Actions","en":"Actions"}}};
export const DOCUMENTS_ACTIONS_ADD = {"id":"documents.actions.add","translations":{"documents":{"fr":"Ajouter $title","en":"Add $title"}}};
export const DOCUMENTS_ACTIONS_SEE_DRAFTS = {"id":"documents.actions.see_drafts","translations":{"documents":{"fr":"Voir mes brouillons","en":"View drafts"}}};
export const DOCUMENTS_ACTIONS_SEE_DOCS = {"id":"documents.actions.see_docs","translations":{"documents":{"fr":"Voir les documents","en":"View documents"}}};
export const DOCUMENTS_ACTIONS_SEE_DOC = {"id":"documents.actions.see_doc","translations":{"documents":{"fr":"Voir le document","en":"View document"}}};
export const DOCUMENTS_ACTIONS_ADD_IN_DOC = {"id":"documents.actions.add_in_doc","translations":{"documents":{"fr":"Ajouter dans les documents","en":"Add documents"}}};
export const DOCUMENTS_ACTIONS_DELETE = {"id":"documents.actions.delete","translations":{"documents":{"fr":"Supprimer le document","en":"Delete document"}}};
export const DOCUMENTS_ACTIONS_DELETE_SELECTION = {"id":"documents.actions.delete_selection","translations":{"documents":{"fr":"Supprimer la s\u00e9lection","en":"Delete selection"}}};
export const DOCUMENTS_ACTIONS_EDIT = {"id":"documents.actions.edit","translations":{"documents":{"fr":"Modifier le document","en":"Edit document"}}};
export const DOCUMENTS_ACTIONS_EDIT_SELECTION = {"id":"documents.actions.edit_selection","translations":{"documents":{"fr":"Modifier la s\u00e9lection","en":"Edit selection"}}};
export const DOCUMENTS_ACTIONS_PRINT = {"id":"documents.actions.print","translations":{"documents":{"fr":"Imprimer le document","en":"Print document"}}};
export const DOCUMENTS_ACTIONS_PRINT_SELECTION = {"id":"documents.actions.print_selection","translations":{"documents":{"fr":"Imprimer la s\u00e9lection","en":"Print selection"}}};
export const DOCUMENTS_ACTIONS_SEND_SELECTION = {"id":"documents.actions.send_selection","translations":{"documents":{"fr":"Envoyer la s\u00e9lection","en":"Send selection"}}};
export const DOCUMENTS_ACTIONS_EDIT_DOC = {"id":"documents.actions.edit_doc","translations":{"documents":{"fr":"Modifier le document","en":"Edit document"}}};
export const DOCUMENTS_ACTIONS_EDIT_PRESCRIPTION = {"id":"documents.actions.edit_prescription","translations":{"documents":{"fr":"Modifier l'ordonnance","en":"Edit prescription"}}};
export const DOCUMENTS_ACTIONS_EDIT_REPORT = {"id":"documents.actions.edit_report","translations":{"documents":{"fr":"Modifier le compte-rendu","en":"Edit medical report"}}};
export const DOCUMENTS_ACTIONS_DOWNLOAD = {"id":"documents.actions.download","translations":{"documents":{"fr":"T\u00e9l\u00e9charger le document","en":"Download document"}}};
export const DOCUMENTS_ACTIONS_DOWNLOAD_SELECTION = {"id":"documents.actions.download_selection","translations":{"documents":{"fr":"T\u00e9l\u00e9charger la s\u00e9lection","en":"Download selection"}}};
export const DOCUMENTS_ACTIONS_RENEW = {"id":"documents.actions.renew","translations":{"documents":{"fr":"Renouveler le document","en":"Renew document"}}};
export const DOCUMENTS_ACTIONS_CLASS = {"id":"documents.actions.class","translations":{"documents":{"fr":"Classer avec Lia","en":"Sort with Lia"}}};
export const DOCUMENTS_ACTIONS_DRAG = {"id":"documents.actions.drag","translations":{"documents":{"fr":"Glissez et d\u00e9posez vos documents","en":"Drag and drop your documents"}}};
export const DOCUMENTS_ACTIONS_FILL_FIELD = {"id":"documents.actions.fill_field","translations":{"documents":{"fr":"Remplir le champ","en":"Complete field"}}};
export const DOCUMENTS_ACTIONS_DRAG_HELPER = {"id":"documents.actions.drag_helper","translations":{"documents":{"fr":"Vous pouvez glisser et d\u00e9poser vos documents pour les charger dans le dossier du patient.","en":"You can drag and drop your documents to upload them to patient's record."}}};
export const DOCUMENTS_ACTIONS_SEND_DOC = {"id":"documents.actions.send_doc","translations":{"documents":{"fr":"Envoyer le document","en":"Send document\u2028"}}};
export const DOCUMENTS_ACTIONS_ANALYSE = {"id":"documents.actions.analyse","translations":{"documents":{"fr":"Analyser avec Lia","en":"Analyze with Lia"}}};
export const DOCUMENTS_ACTIONS_EDIT_CONTENT = {"id":"documents.actions.edit_content","translations":{"documents":{"fr":"Modifier le contenu","en":"Edit content"}}};
export const DOCUMENTS_ACTIONS_RENEW_EDIT = {"id":"documents.actions.renew_edit","translations":{"documents":{"fr":"Renouveler en modifiant le contenu","en":"Renew by editing content"}}};
export const DOCUMENTS_ACTIONS_RENEW_WITHOUT_EDIT = {"id":"documents.actions.renew_without_edit","translations":{"documents":{"fr":"Renouveler sans modifier le contenu","en":"Renew without editing content"}}};
export const DOCUMENTS_ACTIONS_GENERATE_PDF = {"id":"documents.actions.generate_pdf","translations":{"documents":{"fr":"G\u00e9n\u00e9rer le PDF","en":"Generate PDF"}}};
export const DOCUMENTS_ACTIONS_SELECT_SEND_TYPE = {"id":"documents.actions.select_send_type","translations":{"documents":{"fr":"S\u00e9lectionnez le type d'envoi","en":"Select sending type"}}};
export const DOCUMENTS_ACTIONS_SEARCH_RECEIVERS = {"id":"documents.actions.search_receivers","translations":{"documents":{"fr":"Recherchez un ou plusieurs destinataires","en":"Search for one or more recipients"}}};
export const DOCUMENTS_ACTIONS_SEARCH_DISCUSSION = {"id":"documents.actions.search_discussion","translations":{"documents":{"fr":"Recherchez une discussion existante","en":"Search for an existing conversation"}}};
export const DOCUMENTS_ACTIONS_SEARCH_FILE = {"id":"documents.actions.search_file","translations":{"documents":{"fr":"Rechercher un fichier","en":"Search"}}};
export const DOCUMENTS_ACTIONS_CREATE_DOC_FOR = {"id":"documents.actions.create_doc_for","translations":{"documents":{"fr":"Cr\u00e9er un document pour $patient","en":"Create document for $patient"}}};
export const DOCUMENTS_ACTIONS_RENEW_DOC = {"id":"documents.actions.renew_doc","translations":{"documents":{"fr":"Renouveler le document","en":"Renew document"}}};
export const DOCUMENTS_ACTIONS_RENEW_PRESCRIPTION = {"id":"documents.actions.renew_prescription","translations":{"documents":{"fr":"Renouveler l'ordonnance","en":"Renew prescription"}}};
export const DOCUMENTS_ACTIONS_RENEW_REPORT = {"id":"documents.actions.renew_report","translations":{"documents":{"fr":"Renouveler le compte-rendu","en":"Renew medical report"}}};
export const DOCUMENTS_ACTIONS_CHOOSE_TEMPLATE = {"id":"documents.actions.choose_template","translations":{"documents":{"fr":"Choisir un mod\u00e8le","en":"Choose template"}}};
export const DOCUMENTS_ACTIONS_MANAGE_TEMPLATES = {"id":"documents.actions.manage_templates","translations":{"documents":{"fr":"G\u00e9rer mes mod\u00e8les","en":"Manage templates"}}};
export const DOCUMENTS_ACTIONS_SAVE_TEMPLATE = {"id":"documents.actions.save_template","translations":{"documents":{"fr":"Sauvegarder le mod\u00e8le","en":"Save template"}}};
export const DOCUMENTS_ACTIONS_SEND = {"id":"documents.actions.send","translations":{"documents":{"fr":"Envoyer dans la messagerie","en":"Send "}}};
export const DOCUMENTS_ACTIONS_SAVE_NEW_TEMPLATE = {"id":"documents.actions.save_new_template","translations":{"documents":{"fr":"Enregistrer un nouveau mod\u00e8le","en":"Save new template"}}};
export const DOCUMENTS_ACTIONS_SEE_EDITOR = {"id":"documents.actions.see_editor","translations":{"documents":{"fr":"Voir l'\u00e9diteur","en":"View editor"}}};
export const DOCUMENTS_ACTIONS_SEE_STRUCTURED_DATA = {"id":"documents.actions.see_structured_data","translations":{"documents":{"fr":"Voir les champs","en":"View Fields"}}};
export const DOCUMENTS_ACTIONS_CONSULTATION_ADD_QUESTIONNAIRE = {"id":"documents.actions.consultation_add_questionnaire","translations":{"documents":{"fr":"Ajouter le r\u00e9sultat des questionnaires en annexe","en":"Add questionnaire as attachment"}}};
export const DOCUMENTS_ACTIONS_SAVE_DRAFT = {"id":"documents.actions.save_draft","translations":{"documents":{"fr":"Sauvegarder le brouillon","en":"Save draft"}}};
export const DOCUMENTS_ACTIONS_SEE_CALENDAR = {"id":"documents.actions.see_calendar","translations":{"documents":{"fr":"Voir le calendrier","en":"View calendar"}}};
export const DOCUMENTS_ACTIONS_SHARE_OTHER_MEDIC = {"id":"documents.actions.share_other_medic","translations":{"documents":{"fr":"Partager \u00e0 un autre m\u00e9decin","en":"Share to another doctor"}}};
export const DOCUMENTS_ACTIONS_SHOW_HISTORY = {"id":"documents.actions.show_history","translations":{"documents":{"fr":"Afficher l'ant\u00e9c\u00e9dent dans le document","en":"Display medical history in document"}}};
export const DOCUMENTS_ACTIONS_SAVE_EXISTING_TEMPLATES = {"id":"documents.actions.save_existing_templates","translations":{"documents":{"fr":"Sauvegarder le mod\u00e8le existant","en":"Save existing template"}}};
export const DOCUMENTS_ACTIONS_SELECT_FILE = {"id":"documents.actions.select_file","translations":{"documents":{"fr":"Choisissez un fichier","en":"Choose file"}}};
export const DOCUMENTS_ACTIONS_UPLOAD = {"id":"documents.actions.upload","translations":{"documents":{"fr":"Uploadez un document","en":"Upload a document"}}};
export const DOCUMENTS_ACTIONS_CREATE_TEMPLATE = {"id":"documents.actions.create_template","translations":{"documents":{"fr":"Cr\u00e9er un mod\u00e8le","en":"Create template"}}};
export const DOCUMENTS_ACTIONS_SHOW_EMPTY_VAR = {"id":"documents.actions.show_empty_var","translations":{"documents":{"fr":"Afficher les variables vides","en":"Display empty variables"}}};
export const DOCUMENTS_ACTIONS_HIDE_EMPTY_VAR = {"id":"documents.actions.hide_empty_var","translations":{"documents":{"fr":"Cacher les variables vides","en":"Hide empty variables"}}};
export const DOCUMENTS_ACTIONS_MANAGE_VAR = {"id":"documents.actions.manage_var","translations":{"documents":{"fr":"G\u00e9rer mes variables","en":"Manage variables"}}};
export const DOCUMENTS_ACTIONS_SPLIT_COMA = {"id":"documents.actions.split_coma","translations":{"documents":{"fr":"S\u00e9parer les valeurs par des virgules","en":"Separate values with commas"}}};
export const DOCUMENTS_ACTIONS_SELECT = {"id":"documents.actions.select","translations":{"documents":{"fr":"S\u00e9lectionnez un choix","en":"Select option"}}};
export const DOCUMENTS_ACTIONS_SELECT_OR_FILL = {"id":"documents.actions.select_or_fill","translations":{"documents":{"fr":"S\u00e9lectionnez un choix dans la liste ou entrez un texte libre","en":"Select an option from the list or enter free text"}}};
export const DOCUMENTS_ACTIONS_ADD_NOTE = {"id":"documents.actions.add_note","translations":{"documents":{"fr":"Ajouter une note","en":"Add note"}}};
export const DOCUMENTS_ACTIONS_EDIT_NOTE = {"id":"documents.actions.edit_note","translations":{"documents":{"fr":"Modification de la note","en":"Note edition"}}};
export const DOCUMENTS_ACTIONS_DUPLICATE_TEMPLATES = {"id":"documents.actions.duplicate_templates","translations":{"documents":{"fr":"Dupliquer plusieurs mod\u00e8les pour un nouveau m\u00e9decin","en":"Duplicate multiple templates for new doctor"}}};
export const DOCUMENTS_ACTIONS_EDIT_TEMPLATE = {"id":"documents.actions.edit_template","translations":{"documents":{"fr":"Modifier le mod\u00e8le de document","en":"Edit document template"}}};
export const DOCUMENTS_ACTIONS_EDIT_SEVERAL_TEMPLATES = {"id":"documents.actions.edit_several_templates","translations":{"documents":{"fr":"Modifiez plusieurs mod\u00e8les simultan\u00e9ment","en":"Edit multiple templates simultaneously"}}};
export const DOCUMENTS_ACTIONS_EDIT_NOTHING = {"id":"documents.actions.edit_nothing","translations":{"documents":{"fr":"Ne rien modifier","en":"Make no changes"}}};
export const DOCUMENTS_ACTIONS_SELECT_LETTERHEAD = {"id":"documents.actions.select_letterhead","translations":{"documents":{"fr":"Choisir le papier \u00e0 en-t\u00eate","en":"Select letterhead"}}};
export const DOCUMENTS_ACTIONS_GENERATE_TEMPLATES = {"id":"documents.actions.generate_templates","translations":{"documents":{"fr":"G\u00e9n\u00e9rer le mod\u00e8le","en":"Generate template"}}};
export const DOCUMENTS_ACTIONS_CONFIRM_DELETE_NOTE = {"id":"documents.actions.confirm_delete_note","translations":{"documents":{"fr":"\u00cates-vous s\u00fbr de vouloir supprimer cette note ?","en":"Are you sure you want to delete this note?"}}};
export const DOCUMENTS_ACTIONS_SELECT_USERS = {"id":"documents.actions.select_users","translations":{"documents":{"fr":"Choisissez vos utilisateurs","en":"Select users"}}};
export const DOCUMENTS_ACTIONS_CHECK_ALL = {"id":"documents.actions.check_all","translations":{"documents":{"fr":"Tout cocher","en":"Select all"}}};
export const DOCUMENTS_ACTIONS_UNCHECK_ALL = {"id":"documents.actions.uncheck_all","translations":{"documents":{"fr":"Tout d\u00e9cocher","en":"Uncheck all"}}};
export const DOCUMENTS_ACTIONS_SELECT_DOC_TYPE = {"id":"documents.actions.select_doc_type","translations":{"documents":{"fr":"Choisissez un type de document","en":"Choose document type"}}};
export const DOCUMENTS_ACTIONS_SHARE_WITH = {"id":"documents.actions.share_with","translations":{"documents":{"fr":"Partager avec","en":"Share with"}}};
export const DOCUMENTS_POPIN_ADD_DOC = {"id":"documents.popin.add_doc","translations":{"documents":{"fr":"Ajout de documents","en":"Add document"}}};
export const DOCUMENTS_POPIN_EDIT_DOC = {"id":"documents.popin.edit_doc","translations":{"documents":{"fr":"Modification de document","en":"Document editing"}}};
export const DOCUMENTS_POPIN_ALERT = {"id":"documents.popin.alert","translations":{"documents":{"fr":"Vous avez s\u00e9lectionn\u00e9 <span class=\"nb_files\"><\/span> fichiers.","en":"You selected <span class=\"nb_files\"><\/span> files."}}};
export const DOCUMENTS_POPIN_NO_PREVIEW = {"id":"documents.popin.no_preview","translations":{"documents":{"fr":"La pr\u00e9visualisation n'est pas support\u00e9e pour ce type de fichier. Il est en cours de t\u00e9l\u00e9chargement.","en":"Preview is not supported for this file type. It is being downloaded."}}};
export const DOCUMENTS_SEND_DOC_TITLE = {"id":"documents.send_doc.title","translations":{"documents":{"fr":"Envoyer des documents","en":"Send documents"}}};
export const DOCUMENTS_CERFA_TITLE = {"id":"documents.cerfa.title","translations":{"documents":{"fr":"Cerfa","en":"Cerfa"}}};
export const DOCUMENTS_CERFA_CHOOSE_TEMPLATE_CERFA = {"id":"documents.cerfa.choose_template_cerfa","translations":{"documents":{"fr":"Choisissez votre mod\u00e8le de Cerfa","en":"Choose your Cerfa template"}}};
export const DOCUMENTS_CERFA_CHOOSE_TEMPLATE = {"id":"documents.cerfa.choose_template","translations":{"documents":{"fr":"Choisir un mod\u00e8le","en":"Choose template"}}};
export const DOCUMENTS_CERFA_WORK_INTERRUPTION_TYPE = {"id":"documents.cerfa.work_interruption_type","translations":{"documents":{"fr":"Type d'arr\u00eat de travail","en":"Type of sick leave"}}};
export const DOCUMENTS_SIGNATURE_TITLE = {"id":"documents.signature.title","translations":{"documents":{"fr":"Votre signature","en":"Signature"}}};
export const DOCUMENTS_SIGNATURE_OR_DRAW = {"id":"documents.signature.or_draw","translations":{"documents":{"fr":"Ou dessinez votre signature","en":"Or draw your signature"}}};
export const DOCUMENTS_SIGNATURE_HELPER_DEFINE = {"id":"documents.signature.helper.define","translations":{"documents":{"fr":"Veuillez d\u00e9finir une signature afin de signer les documents que vous \u00e9ditez.","en":"Please define signature to sign the documents you are editing."}}};
export const DOCUMENTS_SIGNATURE_HELPER_EDIT = {"id":"documents.signature.helper.edit","translations":{"documents":{"fr":"Cette signature est \u00e9ditable \u00e0 tout moment dans les param\u00e8tres de votre compte.","en":"This signature can be edited at any time in your profile."}}};
export const DOCUMENTS_INVOICE_BENEFICIARY = {"id":"documents.invoice.beneficiary","translations":{"documents":{"fr":"B\u00e9n\u00e9ficiaire des soins","en":"Care recipient"}}};
export const DOCUMENTS_INVOICE_NUMBER = {"id":"documents.invoice.number","translations":{"documents":{"fr":"Facture n\u00b0","en":"Invoice #"}}};
export const DOCUMENTS_INVOICE_MEDIC = {"id":"documents.invoice.medic","translations":{"documents":{"fr":"Practicien","en":"Practitioner"}}};
export const DOCUMENTS_INVOICE_ACT = {"id":"documents.invoice.act","translations":{"documents":{"fr":"Acte","en":"Procedure"}}};
export const DOCUMENTS_INVOICE_CHARGED = {"id":"documents.invoice.charged","translations":{"documents":{"fr":"Factur\u00e9","en":"Invoiced"}}};
export const DOCUMENTS_INVOICE_PAID_BY = {"id":"documents.invoice.paid_by","translations":{"documents":{"fr":"Facture acquitt\u00e9e par $method le $date","en":"Invoice paid by $method on $date"}}};
export const DOCUMENTS_INVOICE_PAID = {"id":"documents.invoice.paid","translations":{"documents":{"fr":"Facture acquitt\u00e9e le $date","en":"Invoice paid on $date"}}};
export const DOCUMENTS_INVOICE_GENERATED = {"id":"documents.invoice.generated","translations":{"documents":{"fr":"G\u00e9n\u00e9r\u00e9e par Instamed","en":"Powered by Instamed"}}};
export const DOCUMENTS_INVOICE_PENDING = {"id":"documents.invoice.pending","translations":{"documents":{"fr":"En attente de paiement","en":"Pending payment"}}};
export const DOCUMENTS_TEMPLATE_CREATION_STEP1 = {"id":"documents.template_creation.step_1","translations":{"documents":{"fr":"1\/3 - Choisissez un nom de mod\u00e8le","en":"1\/3 - Choose template name"}}};
export const DOCUMENTS_TEMPLATE_CREATION_STEP2 = {"id":"documents.template_creation.step_2","translations":{"documents":{"fr":"2\/3 - Choisissez un papier \u00e0 en-t\u00eate","en":"2\/3 - Select letterhead"}}};
export const DOCUMENTS_TEMPLATE_CREATION_STEP3 = {"id":"documents.template_creation.step_3","translations":{"documents":{"fr":"3\/3 - D\u00e9finissez le contenu de votre mod\u00e8le","en":"3\/3 - Set content of your template"}}};
export const DOCUMENTS_TEMPLATE_CREATION_HELPER1 = {"id":"documents.template_creation.helper_1","translations":{"documents":{"fr":"Choisissez un nom pour votre mod\u00e8le afin de le retrouver facilement","en":"Choose unique name for your template so you can search it easily"}}};
export const DOCUMENTS_TEMPLATE_CREATION_TEMPLATE_NAME = {"id":"documents.template_creation.template_name","translations":{"documents":{"fr":"Nom du mod\u00e8le","en":"Template name"}}};
export const DOCUMENTS_TEMPLATE_CREATION_OWNER_TYPE = {"id":"documents.template_creation.owner_type","translations":{"documents":{"fr":"Type de propri\u00e9taire","en":"Owner type"}}};
export const DOCUMENTS_TEMPLATE_CREATION_LINK = {"id":"documents.template_creation.link","translations":{"documents":{"fr":"Lier le mod\u00e8le \u00e0 un soignant","en":"Link template to caregiver"}}};
export const DOCUMENTS_TEMPLATE_CREATION_SHARE = {"id":"documents.template_creation.share","translations":{"documents":{"fr":"Partager ce mod\u00e8le avec","en":"Share this template with"}}};
export const DOCUMENTS_TEMPLATE_CREATION_SAVED = {"id":"documents.template_creation.saved","translations":{"documents":{"fr":"Votre mod\u00e8le a bien \u00e9t\u00e9 enregistr\u00e9","en":"Your template has been successfully saved"}}};
export const DOCUMENTS_TEMPLATE_CREATION_LINK_HOSPITAL = {"id":"documents.template_creation.link_hospital","translations":{"documents":{"fr":"Lier le mod\u00e8le \u00e0 un h\u00f4pital","en":"Link template to healthcare facility"}}};
export const DOCUMENTS_TEMPLATE_CREATION_NEW_INTERLIGNES = {"id":"documents.template_creation.new_interlignes","translations":{"documents":{"fr":"Utiliser la nouvelle version des interlignes","en":"Use new interline version"}}};
export const DOCUMENTS_TEMPLATE_CREATION_CONFIRMATION = {"id":"documents.template_creation.confirmation","translations":{"documents":{"fr":"Votre mod\u00e8le a bien \u00e9t\u00e9 enregistr\u00e9, cliquez sur le bouton ci-dessous pour son contenu et sa structure","en":"Your template has been successfully saved. Click the button below to view its content and structure."}}};
export const DOCUMENTS_TEMPLATE_CREATION_SELECT_LETTERHEAD = {"id":"documents.template_creation.select_letterhead","translations":{"documents":{"fr":"Choisissez votre papier \u00e0 en-t\u00eate ou uploadez un nouveau fichier","en":"Select your letterhead or upload a new file"}}};
export const DOCUMENTS_TEMPLATE_CREATION_SHORT_SELECT_LETTERHEAD = {"id":"documents.template_creation.short_select_letterhead","translations":{"documents":{"fr":"Veuillez choisir un papier \u00e0 en-t\u00eate","en":"Please select letterhead"}}};
export const DOCUMENTS_TEMPLATE_CREATION_NEW_LETTERHEAD = {"id":"documents.template_creation.new_letterhead","translations":{"documents":{"fr":"Nouveau papier \u00e0 en-t\u00eate","en":"New letterhead"}}};
export const DOCUMENTS_TEMPLATE_CREATION_LETTERHEAD_SAVED = {"id":"documents.template_creation.letterhead_saved","translations":{"documents":{"fr":"Le papier \u00e0 en-t\u00eate a bien \u00e9t\u00e9 enregistr\u00e9","en":"The letterhead has been successfully saved"}}};
export const DOCUMENTS_TEMPLATE_CREATION_DELETE_LETTERHEAD = {"id":"documents.template_creation.delete_letterhead","translations":{"documents":{"fr":"\u00cates-vous s\u00fbr de vouloir supprimer ce papier \u00e0 en-t\u00eate ?","en":"Are you sure you want to delete this letterhead?"}}};
export const DOCUMENTS_TEMPLATE_CREATION_LETTERHEAD_DELETED = {"id":"documents.template_creation.letterhead_deleted","translations":{"documents":{"fr":"Le papier \u00e0 en-tete a bien \u00e9t\u00e9 supprim\u00e9","en":"The letterhead has been successfully deleted"}}};
export const DOCUMENTS_TEMPLATE_CREATION_LETTERHEAD_COPIED = {"id":"documents.template_creation.letterhead_copied","translations":{"documents":{"fr":"Le papier \u00e0 en-tete a bien \u00e9t\u00e9 copi\u00e9","en":"The letterhead has been successfully copied"}}};
export const DOCUMENTS_TEMPLATE_CREATION_LINKED = {"id":"documents.template_creation.linked","translations":{"documents":{"fr":"Li\u00e9s \u00e0 un m\u00e9decin","en":"Linked to a doctor"}}};
export const DOCUMENTS_TEMPLATE_CREATION_MINE = {"id":"documents.template_creation.mine","translations":{"documents":{"fr":"Les miens","en":"Mine"}}};
export const DOCUMENTS_TEMPLATE_CREATION_SHARED_WITH_ME = {"id":"documents.template_creation.shared_with_me","translations":{"documents":{"fr":"Partag\u00e9s avec moi","en":"Shared with me"}}};
export const DOCUMENTS_TEMPLATE_CREATION_FILE_NAME = {"id":"documents.template_creation.file_name","translations":{"documents":{"fr":"Nom du fichier","en":"File name"}}};
export const DOCUMENTS_TEMPLATE_CREATION_EMPTY_NAME = {"id":"documents.template_creation.empty_name","translations":{"documents":{"fr":"Le nom de votre mod\u00e8le ne peut pas \u00eatre vide","en":"The name of your template cannot be empty"}}};
export const DOCUMENTS_TEMPLATE_CREATION_SELECT_TYPE = {"id":"documents.template_creation.select_type","translations":{"documents":{"fr":"Veuillez choisir un type de document","en":"Please select document type"}}};
export const DOCUMENTS_TEMPLATE_CREATION_FAVORITE_TEMPLATE = {"id":"documents.template_creation.favorite_template","translations":{"documents":{"fr":"Mod\u00e8le favori","en":"Favorite"}}};
export const DOCUMENTS_TEMPLATE_CREATION_FAVORITE_LETTER = {"id":"documents.template_creation.favorite_letter","translations":{"documents":{"fr":"Papier favoris","en":"Favorite letter"}}};
export const DOCUMENTS_TEMPLATE_EDITION_TITLE = {"id":"documents.template_edition.title","translations":{"documents":{"fr":"Modification du mod\u00e8le $template","en":"Editing template $template"}}};
export const DOCUMENTS_TEMPLATE_EDITION_ACTION = {"id":"documents.template_edition.action","translations":{"documents":{"fr":"Modifiez le contenu du mod\u00e8le $template","en":"Edit the content of template $template"}}};
export const DOCUMENTS_TEMPLATE_EDITION_BACK = {"id":"documents.template_edition.back","translations":{"documents":{"fr":"Retour au mod\u00e8le","en":"Back to template"}}};
export const DOCUMENTS_TEMPLATE_EDITION_PREVIEW = {"id":"documents.template_edition.preview","translations":{"documents":{"fr":"Voir l'\u00e9diteur","en":"View editor"}}};
export const DOCUMENTS_TEMPLATE_EDITION_SEE_STRUCTURE = {"id":"documents.template_edition.see_structure","translations":{"documents":{"fr":"Voir les champs","en":"View fields"}}};
export const DOCUMENTS_TEMPLATE_EDITION_GENERATE_PREVIEW = {"id":"documents.template_edition.generate_preview","translations":{"documents":{"fr":"G\u00e9n\u00e9rer une pr\u00e9visualisation","en":"Generate preview"}}};
export const DOCUMENTS_TEMPLATE_EDITION_WARNING = {"id":"documents.template_edition.warning","translations":{"documents":{"fr":"Attention, si vous modifiez le nom de votre section, il est possible que les donn\u00e9es des anciens comptes-rendus de ce mod\u00e8le ne soient plus r\u00e9cup\u00e9r\u00e9es.","en":"Warning : If you change the name of your section, the data from previous medical reports using this template may no longer be retrieved."}}};
export const DOCUMENTS_TEMPLATE_EDITION_SECTION_NAME = {"id":"documents.template_edition.section_name","translations":{"documents":{"fr":"Nom de la section","en":"Section name"}}};
export const DOCUMENTS_TEMPLATE_EDITION_REQUIRED = {"id":"documents.template_edition.required","translations":{"documents":{"fr":"Le champ est obligatoire","en":"The field is mandatory"}}};
export const DOCUMENTS_TEMPLATE_EDITION_OVERRIDE = {"id":"documents.template_edition.override","translations":{"documents":{"fr":"Le champ \u00e9crase les donn\u00e9es existantes","en":"This field overwrites existing data"}}};
export const DOCUMENTS_TEMPLATE_EDITION_FIELD_EDIT = {"id":"documents.template_edition.field_edit","translations":{"documents":{"fr":"Modification du champ \" $title \"","en":"Edit field \" $title\""}}};
export const DOCUMENTS_TEMPLATE_EDITION_TIPS_TITLE1 = {"id":"documents.template_edition.tips.title_1","translations":{"documents":{"fr":"Personnalisez vos documents en fonction du sexe du patient","en":"Customize your documents based on patient's gender"}}};
export const DOCUMENTS_TEMPLATE_EDITION_TIPS_HELPER1 = {"id":"documents.template_edition.tips.helper_1","translations":{"documents":{"fr":"Le saviez-vous ? dans vos mod\u00e8les de documents, vous pouvez ajouter du texte qui sera automatiquement genris\u00e9 en fonction du sexe du patient. Pour ce faire, ajoutez votre texte sous ce format <code class=\"primary\" >[Masculin\/Feminin\/Autre]<\/code>","en":"Did you know? In your document templates, you can add text that will automatically adjust based on patient's gender. To do so, add your text in the following format : <code class=\"primary\">[Masculin\/Feminin\/Autre]<\/code>"}}};
export const DOCUMENTS_TEMPLATE_EDITION_TIPS_TITLE2 = {"id":"documents.template_edition.tips.title_2","translations":{"documents":{"fr":"Personnalisez vos documents en fonction du sexe du patient","en":"Customize your documents based on patient's gender"}}};
export const DOCUMENTS_TEMPLATE_EDITION_TIPS_HELPER2 = {"id":"documents.template_edition.tips.helper_2","translations":{"documents":{"fr":"Le saviez-vous ? dans vos mod\u00e8les de documents, vous pouvez ajouter du texte qui sera automatiquement genris\u00e9 en fonction du sexe du patient. Pour ce faire, ajoutez votre texte sous ce format <code class=\"primary\" >[Masculin\/Feminin\/Autre]<\/code>","en":"Did you know? In your document templates, you can add text that will automatically adjust based on patient's gender. To do so, add your text in the following format : <code class=\"primary\">[Masculin\/Feminin\/Autre]<\/code>"}}};
export const DOCUMENTS_EDIT_SEVERAL_TITLE = {"id":"documents.edit_several.title","translations":{"documents":{"fr":"Modifiez plusieurs documents simultan\u00e9ment","en":"Edit multiple documents simultaneously"}}};
export const DOCUMENTS_EDIT_SEVERAL_KEEP_VISIBILITY = {"id":"documents.edit_several.keep_visibility","translations":{"documents":{"fr":"Ne pas modifier la visibilit\u00e9","en":"Do not edit visibility"}}};
export const DOCUMENTS_FAVORITE_NO_FAVORITE = {"id":"documents.favorite.no_favorite","translations":{"documents":{"fr":"Vous n\u2019avez pas encore choisi vos favoris.","en":"You have favorites yet."}}};
export const DOCUMENTS_FAVORITE_HELPER = {"id":"documents.favorite.helper","translations":{"documents":{"fr":"Cliquez sur le bouton pour les s\u00e9lectionner.","en":"Click on the button to select them."}}};
export const DOCUMENTS_FAVORITE_ADD = {"id":"documents.favorite.add","translations":{"documents":{"fr":"Ajouter des types de documents favoris","en":"Add favorite document types"}}};
export const DOCUMENTS_STRUCTURED_DATA_DATA = {"id":"documents.structured_data.data","translations":{"documents":{"fr":"Donn\u00e9es structur\u00e9es","en":"Structured data"}}};
export const DOCUMENTS_STRUCTURED_DATA_EDIT = {"id":"documents.structured_data.edit","translations":{"documents":{"fr":"Modification de champs structur\u00e9s","en":"Editing structured fields"}}};
export const DOCUMENTS_STRUCTURED_DATA_EDITED = {"id":"documents.structured_data.edited","translations":{"documents":{"fr":"Les donn\u00e9es structur\u00e9es ont bien \u00e9t\u00e9 modifi\u00e9s","en":"Structured data has been successfully edited"}}};
export const DOCUMENTS_STRUCTURED_DATA_TITLE = {"id":"documents.structured_data.title","translations":{"documents":{"fr":"Mes champs structur\u00e9s","en":"My structure fields"}}};
export const DOCUMENTS_STRUCTURED_DATA_LIST = {"id":"documents.structured_data.list","translations":{"documents":{"fr":"Liste des champs structur\u00e9s","en":"List of structured fields"}}};
export const DOCUMENTS_STRUCTURED_DATA_NOT_FOUND = {"id":"documents.structured_data.not_found","translations":{"documents":{"fr":"Aucun champ structur\u00e9 trouv\u00e9","en":"No Structure field found"}}};
export const DOCUMENTS_STRUCTURED_DATA_SELECT_TYPE = {"id":"documents.structured_data.select_type","translations":{"documents":{"fr":"Veuillez choisir un type de donn\u00e9es structur\u00e9es","en":"Please select type of structured data"}}};
export const DOCUMENTS_STRUCTURED_DATA_ALREADY_EXIST = {"id":"documents.structured_data.already_exist","translations":{"documents":{"fr":"Votre document contient d\u00e9j\u00e0 un champ \" $title \"","en":"Your document already contains a field \" $title\""}}};
export const DOCUMENTS_VARIABLE_NAME = {"id":"documents.variable.name","translations":{"documents":{"fr":"Nom de la variable","en":"Name of variable"}}};
export const DOCUMENTS_VARIABLE_UNIT = {"id":"documents.variable.unit","translations":{"documents":{"fr":"Unit\u00e9 de la variable","en":"Unit of variable"}}};
export const DOCUMENTS_VARIABLE_SLUG = {"id":"documents.variable.slug","translations":{"documents":{"fr":"Slug","en":"Slug"}}};
export const DOCUMENTS_VARIABLE_MIN = {"id":"documents.variable.min","translations":{"documents":{"fr":"Valeure minimale","en":"Minimum value"}}};
export const DOCUMENTS_VARIABLE_MAX = {"id":"documents.variable.max","translations":{"documents":{"fr":"Valeure maximale","en":"Maximum value"}}};
export const DOCUMENTS_VARIABLE_STEP = {"id":"documents.variable.step","translations":{"documents":{"fr":"Pas entre 2 valeurs","en":"Step between 2 values"}}};
export const DOCUMENTS_VARIABLE_ALERT = {"id":"documents.variable.alert","translations":{"documents":{"fr":"Formule pour laquelle une alerte doit \u00eatre \u00e9mise","en":"Condition triggering alert"}}};
export const DOCUMENTS_VARIABLE_PLACEHOLDER_NAME = {"id":"documents.variable.placeholder.name","translations":{"documents":{"fr":"Poids","en":"Weight"}}};
export const DOCUMENTS_VARIABLE_PLACEHOLDER_UNIT = {"id":"documents.variable.placeholder.unit","translations":{"documents":{"fr":"kg","en":"kg"}}};
export const DOCUMENTS_LIA_SUGGESTIONS = {"id":"documents.lia.suggestions","translations":{"documents":{"fr":"Suggestions de Lia","en":"Lia suggestions"}}};
export const DOCUMENTS_LIA_CLASSED_TYPE = {"id":"documents.lia.classed_type","translations":{"documents":{"fr":"$description ... est class\u00e9e en $type","en":"$description ... is classified as $type"}}};
export const DOCUMENTS_LIA_NAMED_TYPE = {"id":"documents.lia.named_type","translations":{"documents":{"fr":"$format est nomm\u00e9 selon ce format $name","en":"$format is named in this $name format"}}};
export const DOCUMENTS_LIA_ANALYSIS_ONGOING = {"id":"documents.lia.analysis_ongoing","translations":{"documents":{"fr":"Les documents vont \u00eatre analys\u00e9s par Lia, vous serez notifi\u00e9 une fois le traitement termin\u00e9.","en":"The documents will be analyzed by Lia, you will be notified once process is completed."}}};
export const DOCUMENTS_LIA_NO_SUGGESTIONS = {"id":"documents.lia.no_suggestions","translations":{"documents":{"fr":"Lia n'a aucune suggestion \u00e0 vous faire sur ce document qui ne soit pas d\u00e9j\u00e0 sur le dossier du patient","en":"Lia has no suggestion for you on this document that is not already on patient's record"}}};
export const DOCUMENTS_LIA_SUGGESTION_COUNT = {"id":"documents.lia.suggestion_count","translations":{"documents":{"fr":"Lia a trouv\u00e9 $count suggestions que vous pouvez analyser d\u00e8s \u00e0 pr\u00e9sent","en":"Lia has found $count suggestions that you can review right now."}}};
export const DOCUMENTS_LIA_VALIDATE = {"id":"documents.lia.validate","translations":{"documents":{"fr":"Valider la suggestion","en":"Confirm suggestion"}}};
export const DOCUMENTS_LIA_SEE_DOC = {"id":"documents.lia.see_Doc","translations":{"documents":{"fr":"Voir le document associ\u00e9","en":"View related document"}}};
export const DOCUMENTS_LIA_REFUSE = {"id":"documents.lia.refuse","translations":{"documents":{"fr":"Refuser la suggestion","en":"Reject suggestion"}}};
export const DOCUMENTS_LIA_SEE_ACTUAL_VALUE = {"id":"documents.lia.see_actual_value","translations":{"documents":{"fr":"Voir la valeur actuelle","en":"View current value"}}};
export const DOCUMENTS_LIA_SEE = {"id":"documents.lia.see","translations":{"documents":{"fr":"Voir la suggestion","en":"View suggestion"}}};
export const DOCUMENTS_LIA_SEE_SUGGESTIONS = {"id":"documents.lia.see_suggestions","translations":{"documents":{"fr":"Voir les suggestions de Lia","en":"View Lia suggestions"}}};
export const DOCUMENTS_LIA_HELPER = {"id":"documents.lia.helper","translations":{"documents":{"fr":"Lia est votre assistant intelligent qui identifie automatiquement des donn\u00e9es issues des documents.","en":"Lia is your smart assistant who automatically identifies data from documents."}}};
export const DOCUMENTS_LIA_HELPER_VALIDATE = {"id":"documents.lia.helper_validate","translations":{"documents":{"fr":"Vous pouvez valider chaque champ individuellement ou tous les valider en cliquant sur le bouton \"Accepter\". Une fois valid\u00e9, chaque champ est mis \u00e0 jour dans le dossier du patient.","en":"You can validate each field individually or approve all at once by clicking \"Accept\" button. Once validated, each field will be updated in patient's record."}}};
export const DOCUMENTS_LIA_ACCEPTED = {"id":"documents.lia.accepted","translations":{"documents":{"fr":"Vous avez bien accept\u00e9 toutes les suggestions","en":"You accepted all suggestions successfully"}}};
export const DOCUMENTS_LIA_REFUSED = {"id":"documents.lia.refused","translations":{"documents":{"fr":"Vous avez bien refus\u00e9 la suggestion","en":"You rejected the suggestion successfully"}}};
export const DOCUMENTS_LIA_TREATED = {"id":"documents.lia.treated","translations":{"documents":{"fr":"Vous avez trait\u00e9 toutes les suggestions","en":"You processed all suggestions"}}};
export const DOCUMENTS_SYNAPSE_DRUGS_NOT_ALD = {"id":"documents.synapse.drugs_not_ald","translations":{"documents":{"fr":"M\u00e9dicaments non ALD Synapse","en":"Non ALD Synapse drugs"}}};
export const DOCUMENTS_SYNAPSE_DRUG_LOCATION = {"id":"documents.synapse.drug_location","translations":{"documents":{"fr":"Emplacement pour les m\u00e9dicaments non ALD Synapse","en":"Location for non-ALD Synapse drugs"}}};
export const DOCUMENTS_SYNAPSE_EDITOR = {"id":"documents.synapse.editor","translations":{"documents":{"fr":"Editeur synapse","en":"Synapse editor"}}};
export const DOCUMENTS_SYNAPSE_PRESCRIPTION_EDITOR = {"id":"documents.synapse.prescription_editor","translations":{"documents":{"fr":"Editeur d'ordonnances Synapse","en":"Synapse prescription editor"}}};
export const DOCUMENTS_SYNAPSE_ANALYSIS = {"id":"documents.synapse.analysis","translations":{"documents":{"fr":"Analyse des alertes remont\u00e9es","en":"Analysis of questionnaires alerts"}}};
export const DOCUMENTS_SYNAPSE_HELPER = {"id":"documents.synapse.helper","translations":{"documents":{"fr":"Vous devez ajouter le champ structur\u00e9 \"Editeur Synapse\" conjointement \u00e0 ce champ structur\u00e9 pour qu'il puisse \u00eatre utilis\u00e9.","en":"You must add the structured field \"Synapse Editor\" alongside this structured field for it to function properly."}}};
export const DOCUMENTS_SYNAPSE_SHOW_DRUGS = {"id":"documents.synapse.show_drugs","translations":{"documents":{"fr":"Ce champ vous permet d'afficher les m\u00e9dicaments non ALD dans le cadre d'une ordonnance bizone.","en":"This field allows you to display non-ALD medications in bizone prescription."}}};
export const DOCUMENTS_CK_EDITOR_FILL_CONTENT = {"id":"documents.ck_editor.fill_content","translations":{"documents":{"fr":"Merci de renseigner un contenu avant de g\u00e9n\u00e9rer votre document","en":"Please fill in content before generating your document"}}};
export const DOCUMENTS_CK_EDITOR_LAST_SAVE = {"id":"documents.ck_editor.last_save","translations":{"documents":{"fr":"Derni\u00e8re sauvegarde \u00e0 ","en":"Last save at "}}};
export const DOCUMENTS_CK_EDITOR_DATA_RECOVERED = {"id":"documents.ck_editor.data_recovered","translations":{"documents":{"fr":"Les donn\u00e9es du pr\u00e9c\u00e9dent document ont \u00e9t\u00e9 r\u00e9cup\u00e9r\u00e9es","en":"Data from the previous document have been recovered"}}};
export const DOCUMENTS_CK_EDITOR_TEMPLATE_SAVED = {"id":"documents.ck_editor.template_saved","translations":{"documents":{"fr":"Le mod\u00e8le a bien \u00e9t\u00e9 enregistr\u00e9","en":"Template saved"}}};
export const DOCUMENTS_CK_EDITOR_CHOOSE_SIGNATURE = {"id":"documents.ck_editor.choose_signature","translations":{"documents":{"fr":"Veuillez choisir un fichier ou bien dessiner une signature","en":"Please select a file or draw signature"}}};
export const DOCUMENTS_CK_EDITOR_SIGNATURE_SAVED = {"id":"documents.ck_editor.signature_saved","translations":{"documents":{"fr":"Votre signature a bien \u00e9t\u00e9 enregistr\u00e9e","en":"Signature have been successfully saved"}}};
export const DOCUMENTS_CK_EDITOR_SEX_SAVED = {"id":"documents.ck_editor.sex_saved","translations":{"documents":{"fr":"Le sexe du patient a bien \u00e9t\u00e9 enregistr\u00e9","en":"Sex have been successfully saved"}}};
export const DOCUMENTS_CK_EDITOR_DOCUMENT_GENERATED = {"id":"documents.ck_editor.document_generated","translations":{"documents":{"fr":"Le document a bien \u00e9t\u00e9 g\u00e9n\u00e9r\u00e9","en":"Document generated"}}};
export const DOCUMENTS_CK_EDITOR_INCOMPLETE_FIELD = {"id":"documents.ck_editor.incomplete_field","translations":{"documents":{"fr":"Un ou plusieurs champ(s) est incomplet","en":"One or more fields are incomplete"}}};
export const DOCUMENTS_CK_EDITOR_ERROR_INIT = {"id":"documents.ck_editor.error.init","translations":{"documents":{"fr":"Une erreur est survenue \u00e0 l'initialisation de l'\u00e9diteur","en":"An error has occurred during initialization of the editor"}}};
export const ENUM_NOTIFICATION_FREQUENCY24H_BEFORE = {"id":"enum.notification_frequency.24h_before","translations":{"enum":{"fr":"24h avant","en":"24h before"}}};
export const ENUM_NOTIFICATION_FREQUENCY2H_BEFORE = {"id":"enum.notification_frequency.2h_before","translations":{"enum":{"fr":"2h avant","en":"2h before"}}};
export const ENUM_NOTIFICATION_FREQUENCY48H_BEFORE = {"id":"enum.notification_frequency.48h_before","translations":{"enum":{"fr":"48h avant","en":"48h before"}}};
export const ENUM_NOTIFICATION_FREQUENCY7DAYS_BEFORE = {"id":"enum.notification_frequency.7days_before","translations":{"enum":{"fr":"7j avant","en":"7d before"}}};
export const ENUM_NOTIFICATION_FREQUENCY_EVENING_BEFORE = {"id":"enum.notification_frequency.evening_before","translations":{"enum":{"fr":"la veille au soir ( 18h30 )","en":"the evening before ( 6:30 PM )"}}};
export const AVAILABILITY_START = {"id":"availability_start","translations":{"event":{"fr":"Disponibilit\u00e9","en":"Availability"}}};
export const EVENT_TYPE_AVAILABILITY_START_DEFAULT_NAME = {"id":"event_type.availability_start.default_name","translations":{"event":{"fr":"Disponibilit\u00e9","en":"Availability"}}};
export const EVENT_TYPE_REPLY_SELF_EVALUATION_DEFAULT_NAME = {"id":"event_type.reply_self_evaluation.default_name","translations":{"event":{"fr":"R\u00e9pondre au questionnaire d'auto-\u00e9valuation","en":"Answer the reported outcome questionnaire"}}};
export const VISIBILITY_VALUES1 = {"id":"visibility.values.1","translations":{"event":{"fr":"Visible par tous","en":"Visible by all"}}};
export const VISIBILITY_VALUES2 = {"id":"visibility.values.2","translations":{"event":{"fr":"Visible par les soignants","en":"Visible by caregivers"},"patient_synopsis":{"fr":"Visible par tous les soignants","en":"Visible by all caregivers"}}};
export const STATUS_VALUES3 = {"id":"status.values.3","translations":{"event":{"fr":"Vu","en":"Seen"},"identity_document":{"fr":"Expir\u00e9","en":"Expired"},"medical_record_field":{"fr":"Readonly","en":"Read only"}}};
export const STATUS_VALUES4 = {"id":"status.values.4","translations":{"event":{"fr":"Absent excus\u00e9","en":"Excused absence"}}};
export const STATUS_VALUES5 = {"id":"status.values.5","translations":{"event":{"fr":"Absent non excus\u00e9","en":"Unexcused absence"}}};
export const CANCELLATION_REASON_DESCRIPTION = {"id":"cancellation_reason.description","translations":{"event":{"fr":"La raison de l'annulation de l'\u00e9v\u00e9nement","en":"The reason for appointment cancellation"},"subscription":{"fr":"Pourquoi souhaitez-vous d\u00e9souscrire d\u2019Instamed ?","en":"Why do you want to unsubscribe from Instamed?"}}};
export const CANCELLATION_REASON_LABEL = {"id":"cancellation_reason.label","translations":{"event":{"fr":"Raison de l'annulation","en":"Reason for cancellation"},"subscription":{"fr":"Raison de l'annulation","en":"Reason for Cancellation"}}};
export const CANCELLATION_REASON_PLACEHOLDER = {"id":"cancellation_reason.placeholder","translations":{"event":{"fr":"S\u00e9lectionnez une raison d'annulation","en":"Select reason for cancellation"},"subscription":{"fr":"S\u00e9lectionnez une raison d'annulation","en":"Select a reason for cancellation"}}};
export const CANCELLATION_REASON_VALUES0 = {"id":"cancellation_reason.values.0","translations":{"event":{"fr":"Autre (Pr\u00e9cisez)","en":"Other (specify)"}}};
export const CANCELLATION_REASON_VALUES1 = {"id":"cancellation_reason.values.1","translations":{"event":{"fr":"Emp\u00eachement de derni\u00e8re minute","en":"Last-minute inconvenience"},"subscription":{"fr":"Trop cher","en":"Too expensive"}}};
export const CANCELLATION_REASON_VALUES2 = {"id":"cancellation_reason.values.2","translations":{"event":{"fr":"La date ne convient plus","en":"Date no longer suits"},"subscription":{"fr":"Je ne l\u2019utilise pas","en":"I don't use it"}}};
export const CANCELLATION_REASON_VALUES3 = {"id":"cancellation_reason.values.3","translations":{"event":{"fr":"Le patient se sent mieux et n\u2019a plus besoin de ce rendez-vous","en":"Patient feels better and no longer needs this appointment"},"subscription":{"fr":"Manque de support","en":"Lack of support"}}};
export const CANCELLATION_REASON_VALUES4 = {"id":"cancellation_reason.values.4","translations":{"event":{"fr":"Le patient a trouv\u00e9 un autre rendez-vous","en":"Patient found another appointment"},"subscription":{"fr":"Ne convient pas \u00e0 mon activit\u00e9","en":"Not suitable for my activity"}}};
export const CANCELLATION_REASON_VALUES5 = {"id":"cancellation_reason.values.5","translations":{"event":{"fr":"Le patient n\u2019a pas pr\u00e9venu et n\u2019est pas venu au rendez-vous","en":"Patient did not notify and did not come to appointment"}}};
export const CANCELLATION_REASON_VALUES6 = {"id":"cancellation_reason.values.6","translations":{"event":{"fr":"La patient est d\u00e9c\u00e9d\u00e9","en":"Patient is deceased"}}};
export const CANCELLATION_REASON_VALUES_ROLE_PATIENT0 = {"id":"cancellation_reason.values.role_patient.0","translations":{"event":{"fr":"Autre (Pr\u00e9cisez)","en":"Other (specify)"}}};
export const CANCELLATION_REASON_VALUES_ROLE_PATIENT1 = {"id":"cancellation_reason.values.role_patient.1","translations":{"event":{"fr":"Emp\u00eachement de derni\u00e8re minute","en":"Last-minute inconvenience"}}};
export const CANCELLATION_REASON_VALUES_ROLE_PATIENT2 = {"id":"cancellation_reason.values.role_patient.2","translations":{"event":{"fr":"La date ne convient plus","en":"Date no longer suits"}}};
export const CANCELLATION_REASON_VALUES_ROLE_PATIENT3 = {"id":"cancellation_reason.values.role_patient.3","translations":{"event":{"fr":"Je me sens mieux et n\u2019ai plus besoin de ce rendez-vous","en":"I feel better and no longer need this appointment"}}};
export const CANCELLATION_REASON_VALUES_ROLE_PATIENT4 = {"id":"cancellation_reason.values.role_patient.4","translations":{"event":{"fr":"J\u2019ai trouv\u00e9 un autre rendez-vous","en":"I found another appointment"}}};
export const RECURRING_END_TYPE_VALUES_END_DATE = {"id":"recurring_end_type.values.endDate","translations":{"event":{"fr":"Date de fin","en":"End date"}}};
export const RECURRING_END_TYPE_VALUES_AFTER = {"id":"recurring_end_type.values.after","translations":{"event":{"fr":"Apr\u00e8s","en":"After"}}};
export const RECURRING_DAYS_PLURAL1 = {"id":"recurring_days.plural.1","translations":{"event":{"fr":"Lundis","en":"Mondays"}}};
export const RECURRING_DAYS_PLURAL2 = {"id":"recurring_days.plural.2","translations":{"event":{"fr":"Mardis","en":"Tuesdays"}}};
export const RECURRING_DAYS_PLURAL3 = {"id":"recurring_days.plural.3","translations":{"event":{"fr":"Mercredis","en":"Wednesdays"}}};
export const RECURRING_DAYS_PLURAL4 = {"id":"recurring_days.plural.4","translations":{"event":{"fr":"Jeudis","en":"Thursdays"}}};
export const RECURRING_DAYS_PLURAL5 = {"id":"recurring_days.plural.5","translations":{"event":{"fr":"Vendredis","en":"Fridays"}}};
export const RECURRING_DAYS_PLURAL6 = {"id":"recurring_days.plural.6","translations":{"event":{"fr":"Samedis","en":"Saturdays"}}};
export const RECURRING_DAYS_PLURAL7 = {"id":"recurring_days.plural.7","translations":{"event":{"fr":"Dimanches","en":"Sundays"}}};
export const RECURRING_DISPLAY_VALUE_EVERY_WEEK = {"id":"recurring_display_value.every_week","translations":{"event":{"fr":"Toutes les semaines","en":"Every week"}}};
export const RECURRING_DISPLAY_VALUE_EVERY_X_WEEKS = {"id":"recurring_display_value.every_x_weeks","translations":{"event":{"fr":"Toutes les {{ count }} semaines","en":"Every {{ count }} weeks"}}};
export const RECURRING_DISPLAY_VALUE_ENDS_AFTER_X_APPOINTMENTS = {"id":"recurring_display_value.ends_after_x_appointments","translations":{"event":{"fr":"se termine apr\u00e8s {{ count }} RDVs","en":"ends after {{ count }} appointments"}}};
export const RECURRING_DISPLAY_VALUE_ENDS_AFTER_X_OCCURENCES = {"id":"recurring_display_value.ends_after_x_occurences","translations":{"event":{"fr":"se termine apr\u00e8s {{ count }} Occurrences","en":"ends after {{ count }} occurrences"}}};
export const RECURRING_DISPLAY_VALUE_NEVER_ENDS = {"id":"recurring_display_value.never_ends","translations":{"event":{"fr":"sans date de fin","en":"no end date"}}};
export const RECURRING_DISPLAY_VALUE_ENDS_ON_DATE = {"id":"recurring_display_value.ends_on_date","translations":{"event":{"fr":"se termine le {{ date }}","en":"ends on {{ date }}"}}};
export const RECURRING_DISPLAY_VALUE_AND = {"id":"recurring_display_value.and","translations":{"event":{"fr":"et","en":"and"}}};
export const RECURRING_DISPLAY_VALUE_ON = {"id":"recurring_display_value.on","translations":{"event":{"fr":"le","en":"on"}}};
export const RECURRING_DISPLAY_VALUE_PLURAL_ON = {"id":"recurring_display_value.plural.on","translations":{"event":{"fr":"les","en":"on"}}};
export const PUBLIC_HOLIDAYS_NEW_YEAR = {"id":"public_holidays.new_year","translations":{"event":{"fr":"Jour de l'an","en":"New Year's day"}}};
export const PUBLIC_HOLIDAYS_EASTER = {"id":"public_holidays.easter","translations":{"event":{"fr":"Lundi de P\u00e2ques","en":"Easter Monday"}}};
export const PUBLIC_HOLIDAYS_MAY_DAY = {"id":"public_holidays.may_day","translations":{"event":{"fr":"F\u00eate du travail","en":"Labor Day"}}};
export const PUBLIC_HOLIDAYS_MAY8 = {"id":"public_holidays.may_8","translations":{"event":{"fr":"Victoire 1945","en":"1945 Victory"}}};
export const PUBLIC_HOLIDAYS_ASCENSION = {"id":"public_holidays.ascension","translations":{"event":{"fr":"Ascension","en":"Ascension day"}}};
export const PUBLIC_HOLIDAYS_PENTECOST = {"id":"public_holidays.pentecost","translations":{"event":{"fr":"Pentec\u00f4te","en":"Pentecost"}}};
export const PUBLIC_HOLIDAYS_BASTILLE_DAY = {"id":"public_holidays.bastille_day","translations":{"event":{"fr":"F\u00eate nationale","en":"National day (France)"}}};
export const PUBLIC_HOLIDAYS_ASSOMPTION = {"id":"public_holidays.assomption","translations":{"event":{"fr":"Assomption","en":"Assumption"}}};
export const PUBLIC_HOLIDAYS_ALL_SAINTS_DAY = {"id":"public_holidays.all_saints_day","translations":{"event":{"fr":"Toussaint","en":"All Saints' day"}}};
export const PUBLIC_HOLIDAYS_NOVEMBER11 = {"id":"public_holidays.november_11","translations":{"event":{"fr":"Armistice 1918","en":"Armistice 1918 Day"}}};
export const PUBLIC_HOLIDAYS_CHRISTMAS = {"id":"public_holidays.christmas","translations":{"event":{"fr":"No\u00ebl","en":"Christmas"}}};
export const COLOR_VALUES_DA544_E = {"id":"color.values.#DA544E","translations":{"event_type":{"fr":"Rouge fonc\u00e9","en":"Dark Red"}}};
export const COLOR_VALUES_F85656 = {"id":"color.values.#F85656","translations":{"event_type":{"fr":"Rouge","en":"Red"}}};
export const COLOR_VALUES_F9_A05_F = {"id":"color.values.#F9A05F","translations":{"event_type":{"fr":"Orange","en":"Orange"}}};
export const COLOR_VALUES_F4_BD46 = {"id":"color.values.#F4BD46","translations":{"event_type":{"fr":"Jaune moutarde","en":"Mustard Yellow"}}};
export const COLOR_VALUES_FFD54_C = {"id":"color.values.#FFD54C","translations":{"event_type":{"fr":"Jaune","en":"Yellow"}}};
export const COLOR_VALUES_FBEA57 = {"id":"color.values.#FBEA57","translations":{"event_type":{"fr":"Jaune clair","en":"Light Yellow"}}};
export const COLOR_VALUES_BECB41 = {"id":"color.values.#BECB41","translations":{"event_type":{"fr":"Vert olive","en":"Olive Green"}}};
export const COLOR_VALUES_D0_F56_F = {"id":"color.values.#D0F56F","translations":{"event_type":{"fr":"Vert citron","en":"Lime Green"}}};
export const COLOR_VALUES89_C23_C = {"id":"color.values.#89C23C","translations":{"event_type":{"fr":"Vert","en":"Green"}}};
export const COLOR_VALUES_A9_C388 = {"id":"color.values.#A9C388","translations":{"event_type":{"fr":"Vert mousse","en":"Moss Green"}}};
export const COLOR_VALUES63_CC67 = {"id":"color.values.#63CC67","translations":{"event_type":{"fr":"Vert menthe","en":"Mint Green"}}};
export const COLOR_VALUES99_EF83 = {"id":"color.values.#99EF83","translations":{"event_type":{"fr":"Vert clair","en":"Light Green"}}};
export const COLOR_VALUES69_E1_C1 = {"id":"color.values.#69E1C1","translations":{"event_type":{"fr":"Turquoise","en":"Turquoise"}}};
export const COLOR_VALUES82_DAED = {"id":"color.values.#82DAED","translations":{"event_type":{"fr":"Bleu ciel","en":"Sky Blue"}}};
export const COLOR_VALUES7_CBDFA = {"id":"color.values.#7CBDFA","translations":{"event_type":{"fr":"Bleu","en":"Blue"}}};
export const COLOR_VALUES5489_BE = {"id":"color.values.#5489BE","translations":{"event_type":{"fr":"Bleu acier","en":"Steel Blue"}}};
export const COLOR_VALUES4_D6_BEF = {"id":"color.values.#4D6BEF","translations":{"event_type":{"fr":"Bleu fonc\u00e9","en":"Dark Blue"}}};
export const COLOR_VALUES172_DC2 = {"id":"color.values.#172DC2","translations":{"event_type":{"fr":"Bleu indigo","en":"Indigo Blue"}}};
export const COLOR_VALUES746_BD5 = {"id":"color.values.#746BD5","translations":{"event_type":{"fr":"Bleu lavande","en":"Lavender Blue"}}};
export const COLOR_VALUES6_D3_DE1 = {"id":"color.values.#6D3DE1","translations":{"event_type":{"fr":"Violet fonc\u00e9","en":"Dark Violet"}}};
export const COLOR_VALUES_DF86_F8 = {"id":"color.values.#DF86F8","translations":{"event_type":{"fr":"Violet clair","en":"Light Violet"}}};
export const COLOR_VALUES_AD2_AB9 = {"id":"color.values.#AD2AB9","translations":{"event_type":{"fr":"Violet","en":"Purple"}}};
export const COLOR_VALUES_B988_F2 = {"id":"color.values.#B988F2","translations":{"event_type":{"fr":"Lavande","en":"Lavender"}}};
export const COLOR_VALUES_E29_AD2 = {"id":"color.values.#E29AD2","translations":{"event_type":{"fr":"Rose","en":"Pink"}}};
export const COLOR_VALUES_F3_A7_AB = {"id":"color.values.#F3A7AB","translations":{"event_type":{"fr":"Rose saumon","en":"Salmon Pink"}}};
export const COLOR_VALUES_DB5_DA8 = {"id":"color.values.#DB5DA8","translations":{"event_type":{"fr":"Rose fonc\u00e9","en":"Dark Pink"}}};
export const COLOR_VALUES_D88_AA6 = {"id":"color.values.#D88AA6","translations":{"event_type":{"fr":"Magenta clair","en":"Light Magenta"}}};
export const COLOR_VALUES_D32_D50 = {"id":"color.values.#D32D50","translations":{"event_type":{"fr":"Magenta fonc\u00e9","en":"Dark Magenta"}}};
export const COLOR_VALUES_EC5774 = {"id":"color.values.#EC5774","translations":{"event_type":{"fr":"Magenta","en":"Magenta"}}};
export const COLOR_VALUES_A02319 = {"id":"color.values.#A02319","translations":{"event_type":{"fr":"Bordeaux","en":"Burgundy"}}};
export const COLOR_VALUES_DE8954 = {"id":"color.values.#DE8954","translations":{"event_type":{"fr":"Marron clair","en":"Light Brown"}}};
export const COLOR_VALUES_AD6129 = {"id":"color.values.#AD6129","translations":{"event_type":{"fr":"Marron","en":"Brown"}}};
export const COLOR_VALUES_DB9_D36 = {"id":"color.values.#DB9D36","translations":{"event_type":{"fr":"Ocre","en":"Ochre"}}};
export const COLOR_VALUES_D4_D5_D9 = {"id":"color.values.#D4D5D9","translations":{"event_type":{"fr":"Gris clair","en":"Light Gray"}}};
export const COLOR_VALUES9_CA7_AF = {"id":"color.values.#9CA7AF","translations":{"event_type":{"fr":"Gris","en":"Gray"}}};
export const COLOR_VALUES517085 = {"id":"color.values.#517085","translations":{"event_type":{"fr":"Gris fonc\u00e9","en":"Dark Gray"}}};
export const MEAN_DURATION_DESCRIPTION = {"id":"mean_duration.description","translations":{"event_type":{"fr":"La dur\u00e9e du motif d'\u00e9v\u00e9nement","en":"The appointment type duration"}}};
export const MEAN_DURATION_LABEL = {"id":"mean_duration.label","translations":{"event_type":{"fr":"Dur\u00e9e","en":"Duration"}}};
export const MEAN_DURATION_PLACEHOLDER = {"id":"mean_duration.placeholder","translations":{"event_type":{"fr":"S\u00e9lectionnez une dur\u00e9e","en":"Select a duration"}}};
export const MEAN_DURATION_VALUES300 = {"id":"mean_duration.values.300","translations":{"event_type":{"fr":"5 min","en":"5 min"}}};
export const MEAN_DURATION_VALUES360 = {"id":"mean_duration.values.360","translations":{"event_type":{"fr":"6 min","en":"6 min"}}};
export const MEAN_DURATION_VALUES420 = {"id":"mean_duration.values.420","translations":{"event_type":{"fr":"7 min","en":"7 min"}}};
export const MEAN_DURATION_VALUES480 = {"id":"mean_duration.values.480","translations":{"event_type":{"fr":"8 min","en":"8 min"}}};
export const MEAN_DURATION_VALUES540 = {"id":"mean_duration.values.540","translations":{"event_type":{"fr":"9 min","en":"9 min"}}};
export const MEAN_DURATION_VALUES600 = {"id":"mean_duration.values.600","translations":{"event_type":{"fr":"10 min","en":"10 min"}}};
export const MEAN_DURATION_VALUES720 = {"id":"mean_duration.values.720","translations":{"event_type":{"fr":"12 min","en":"12 min"}}};
export const MEAN_DURATION_VALUES900 = {"id":"mean_duration.values.900","translations":{"event_type":{"fr":"15 min","en":"15 min"}}};
export const MEAN_DURATION_VALUES1200 = {"id":"mean_duration.values.1200","translations":{"event_type":{"fr":"20 min","en":"20 min"}}};
export const MEAN_DURATION_VALUES1440 = {"id":"mean_duration.values.1440","translations":{"event_type":{"fr":"24 min","en":"24 min"}}};
export const MEAN_DURATION_VALUES1500 = {"id":"mean_duration.values.1500","translations":{"event_type":{"fr":"25 min","en":"25 min"}}};
export const MEAN_DURATION_VALUES1800 = {"id":"mean_duration.values.1800","translations":{"event_type":{"fr":"30 min","en":"30 min"}}};
export const MEAN_DURATION_VALUES2100 = {"id":"mean_duration.values.2100","translations":{"event_type":{"fr":"35 min","en":"35 min"}}};
export const MEAN_DURATION_VALUES2400 = {"id":"mean_duration.values.2400","translations":{"event_type":{"fr":"40 min","en":"40 min"}}};
export const MEAN_DURATION_VALUES2700 = {"id":"mean_duration.values.2700","translations":{"event_type":{"fr":"45 min","en":"45 min"}}};
export const MEAN_DURATION_VALUES3000 = {"id":"mean_duration.values.3000","translations":{"event_type":{"fr":"50 min","en":"50 min"}}};
export const MEAN_DURATION_VALUES3300 = {"id":"mean_duration.values.3300","translations":{"event_type":{"fr":"55 min","en":"55 min"}}};
export const MEAN_DURATION_VALUES3600 = {"id":"mean_duration.values.3600","translations":{"event_type":{"fr":"1h","en":"1h"}}};
export const MEAN_DURATION_VALUES3900 = {"id":"mean_duration.values.3900","translations":{"event_type":{"fr":"1h05","en":"1h05"}}};
export const MEAN_DURATION_VALUES4200 = {"id":"mean_duration.values.4200","translations":{"event_type":{"fr":"1h10","en":"1h10"}}};
export const MEAN_DURATION_VALUES4500 = {"id":"mean_duration.values.4500","translations":{"event_type":{"fr":"1h15","en":"1h15"}}};
export const MEAN_DURATION_VALUES4800 = {"id":"mean_duration.values.4800","translations":{"event_type":{"fr":"1h20","en":"1h20"}}};
export const MEAN_DURATION_VALUES5100 = {"id":"mean_duration.values.5100","translations":{"event_type":{"fr":"1h25","en":"1h25"}}};
export const MEAN_DURATION_VALUES5400 = {"id":"mean_duration.values.5400","translations":{"event_type":{"fr":"1h30","en":"1h30"}}};
export const MEAN_DURATION_VALUES5700 = {"id":"mean_duration.values.5700","translations":{"event_type":{"fr":"1h35","en":"1h35"}}};
export const MEAN_DURATION_VALUES6000 = {"id":"mean_duration.values.6000","translations":{"event_type":{"fr":"1h40","en":"1h40"}}};
export const MEAN_DURATION_VALUES6300 = {"id":"mean_duration.values.6300","translations":{"event_type":{"fr":"1h45","en":"1h45"}}};
export const MEAN_DURATION_VALUES6600 = {"id":"mean_duration.values.6600","translations":{"event_type":{"fr":"1h50","en":"1h50"}}};
export const MEAN_DURATION_VALUES6900 = {"id":"mean_duration.values.6900","translations":{"event_type":{"fr":"1h55","en":"1h55"}}};
export const MEAN_DURATION_VALUES7200 = {"id":"mean_duration.values.7200","translations":{"event_type":{"fr":"2h","en":"2h"}}};
export const MEAN_DURATION_VALUES8100 = {"id":"mean_duration.values.8100","translations":{"event_type":{"fr":"2h15","en":"2h30"}}};
export const MEAN_DURATION_VALUES9000 = {"id":"mean_duration.values.9000","translations":{"event_type":{"fr":"2h30","en":"3h"}}};
export const MEAN_DURATION_VALUES9900 = {"id":"mean_duration.values.9900","translations":{"event_type":{"fr":"2h45","en":"3h30"}}};
export const MEAN_DURATION_VALUES10800 = {"id":"mean_duration.values.10800","translations":{"event_type":{"fr":"3h","en":"4h"}}};
export const MEAN_DURATION_VALUES11700 = {"id":"mean_duration.values.11700","translations":{"event_type":{"fr":"3h15","en":"4h30"}}};
export const MEAN_DURATION_VALUES12600 = {"id":"mean_duration.values.12600","translations":{"event_type":{"fr":"3h30","en":"5h"}}};
export const MEAN_DURATION_VALUES13500 = {"id":"mean_duration.values.13500","translations":{"event_type":{"fr":"3h45","en":"3h45"}}};
export const MEAN_DURATION_VALUES14400 = {"id":"mean_duration.values.14400","translations":{"event_type":{"fr":"4h","en":"4h"}}};
export const MEAN_DURATION_VALUES15300 = {"id":"mean_duration.values.15300","translations":{"event_type":{"fr":"4h15","en":"4h15"}}};
export const MEAN_DURATION_VALUES16200 = {"id":"mean_duration.values.16200","translations":{"event_type":{"fr":"4h30","en":"4h30"}}};
export const MEAN_DURATION_VALUES17100 = {"id":"mean_duration.values.17100","translations":{"event_type":{"fr":"4h45","en":"4h45"}}};
export const MEAN_DURATION_VALUES18000 = {"id":"mean_duration.values.18000","translations":{"event_type":{"fr":"5h","en":"6h"}}};
export const MEAN_DURATION_VALUES19800 = {"id":"mean_duration.values.19800","translations":{"event_type":{"fr":"5h30","en":"5h30"}}};
export const MEAN_DURATION_VALUES21600 = {"id":"mean_duration.values.21600","translations":{"event_type":{"fr":"6h","en":"6h"}}};
export const MEAN_DURATION_VALUES23400 = {"id":"mean_duration.values.23400","translations":{"event_type":{"fr":"6h30","en":"7h"}}};
export const MEAN_DURATION_VALUES25200 = {"id":"mean_duration.values.25200","translations":{"event_type":{"fr":"7h","en":"7h"}}};
export const MEAN_DURATION_VALUES27000 = {"id":"mean_duration.values.27000","translations":{"event_type":{"fr":"7h30","en":"7h30"}}};
export const MEAN_DURATION_VALUES28800 = {"id":"mean_duration.values.28800","translations":{"event_type":{"fr":"8h","en":"8h"}}};
export const MEAN_DURATION_VALUES30600 = {"id":"mean_duration.values.30600","translations":{"event_type":{"fr":"8h30","en":"8h30"}}};
export const MEAN_DURATION_VALUES32400 = {"id":"mean_duration.values.32400","translations":{"event_type":{"fr":"9h","en":"9h"}}};
export const MEAN_DURATION_VALUES34200 = {"id":"mean_duration.values.34200","translations":{"event_type":{"fr":"9h30","en":"9h"}}};
export const MEAN_DURATION_VALUES36000 = {"id":"mean_duration.values.36000","translations":{"event_type":{"fr":"10h","en":"10h"}}};
export const REMINDERS_LABEL = {"id":"reminders.label","translations":{"event_type":{"fr":"Rappels de l'\u00e9v\u00e9nement","en":"Appointment reminders"}}};
export const REMINDERS_DESCRIPTION = {"id":"reminders.description","translations":{"event_type":{"fr":"L'heure \u00e0 laquelle le rappel d'\u00e9v\u00e8nement doit \u00eatre envoy\u00e9","en":"The time at which the appointment reminder should be sent"}}};
export const REMINDERS_PLACEHOLDER = {"id":"reminders.placeholder","translations":{"event_type":{"fr":"S\u00e9lectionnez un rappel","en":"Select reminder"}}};
export const REMINDERS_VALUES1 = {"id":"reminders.values.1","translations":{"event_type":{"fr":"24h avant","en":"24h before"}}};
export const REMINDERS_VALUES2 = {"id":"reminders.values.2","translations":{"event_type":{"fr":"2h avant","en":"2h before"}}};
export const REMINDERS_VALUES3 = {"id":"reminders.values.3","translations":{"event_type":{"fr":"la veille au soir ( 18h30 )","en":"the night before ( 18h30 )"}}};
export const REMINDERS_VALUES4 = {"id":"reminders.values.4","translations":{"event_type":{"fr":"48h avant","en":"48h before"}}};
export const REMINDERS_VALUES5 = {"id":"reminders.values.5","translations":{"event_type":{"fr":"7 jours avant","en":"7 days before"}}};
export const NOTIFICATION_CHANNELS_LABEL = {"id":"notification_channels.label","translations":{"event_type":{"fr":"Types de notifications","en":"Notification types"},"notifiable":{"fr":"Notifications envoy\u00e9es","en":"Notifications sent"}}};
export const NOTIFICATION_CHANNELS_DESCRIPTION = {"id":"notification_channels.description","translations":{"event_type":{"fr":"Le type de notification qui sera envoy\u00e9","en":"The type of notification that will be sent"},"notifiable":{"fr":"Les canaux de communication par lesquels une notification sera envoy\u00e9e \u00e0 l'utilisateur","en":"The communication channels through which notification will be sent to the user"}}};
export const NOTIFICATION_CHANNELS_PLACEHOLDER = {"id":"notification_channels.placeholder","translations":{"event_type":{"fr":"Choisissez un ou plusieurs type de notification","en":"Select one or more notification types"},"notifiable":{"fr":"S\u00e9lectionnez un ou plusieurs canal de communication","en":"Select one or more communication channels"}}};
export const NOTIFICATION_CHANNELS_VALUES_PATIENT_SMS = {"id":"notification_channels.values.patient-sms","translations":{"event_type":{"fr":"SMS au patient","en":"SMS to patient"}}};
export const NOTIFICATION_CHANNELS_VALUES_PATIENT_EMAIL = {"id":"notification_channels.values.patient-email","translations":{"event_type":{"fr":"Email au patient","en":"Email to patient"}}};
export const NOTIFICATION_CHANNELS_VALUES_PATIENT_NOTIFICATION = {"id":"notification_channels.values.patient-notification","translations":{"event_type":{"fr":"Notification push au patient","en":"Push notification to patient"}}};
export const NOTIFICATION_CHANNELS_VALUES_STAFF_SMS = {"id":"notification_channels.values.staff-sms","translations":{"event_type":{"fr":"SMS au m\u00e9decin","en":"SMS to doctor"}}};
export const NOTIFICATION_CHANNELS_VALUES_STAFF_EMAIL = {"id":"notification_channels.values.staff-email","translations":{"event_type":{"fr":"Email au m\u00e9decin","en":"Email to doctor"}}};
export const NOTIFICATION_CHANNELS_VALUES_STAFF_NOTIFICATION = {"id":"notification_channels.values.staff-notification","translations":{"event_type":{"fr":"Notification push au m\u00e9decin","en":"Push notification to doctor"}}};
export const BOOKING_OPENING_DESCRIPTION = {"id":"booking_opening.description","translations":{"event_type_options":{"fr":"P\u00e9riode d'ouverture des r\u00e9servations","en":"Booking availability period"}}};
export const BOOKING_OPENING_LABEL = {"id":"booking_opening.label","translations":{"event_type_options":{"fr":"S\u00e9lectionnez la p\u00e9riode d'ouverture des r\u00e9servations","en":"Select the booking availability period"}}};
export const BOOKING_OPENING_PLACEHOLDER = {"id":"booking_opening.placeholder","translations":{"event_type_options":{"fr":"S\u00e9lectionnez une p\u00e9riode","en":"Select period"}}};
export const BOOKING_OPENING_VALUES1440 = {"id":"booking_opening.values.1440","translations":{"event_type_options":{"fr":"1 jour","en":"1 day"}}};
export const BOOKING_OPENING_VALUES2880 = {"id":"booking_opening.values.2880","translations":{"event_type_options":{"fr":"2 jours","en":"2 days"}}};
export const BOOKING_OPENING_VALUES4320 = {"id":"booking_opening.values.4320","translations":{"event_type_options":{"fr":"3 jours","en":"3 days"}}};
export const BOOKING_OPENING_VALUES5760 = {"id":"booking_opening.values.5760","translations":{"event_type_options":{"fr":"4 jours","en":"4 days"}}};
export const BOOKING_OPENING_VALUES10080 = {"id":"booking_opening.values.10080","translations":{"event_type_options":{"fr":"1 semaine","en":"1 week"}}};
export const BOOKING_OPENING_VALUES20160 = {"id":"booking_opening.values.20160","translations":{"event_type_options":{"fr":"2 semaines","en":"2 weeks"}}};
export const BOOKING_OPENING_VALUES30240 = {"id":"booking_opening.values.30240","translations":{"event_type_options":{"fr":"3 semaines","en":"3 weeks"}}};
export const BOOKING_OPENING_VALUES40320 = {"id":"booking_opening.values.40320","translations":{"event_type_options":{"fr":"4 semaines","en":"4 weeks"}}};
export const BOOKING_OPENING_VALUES43800 = {"id":"booking_opening.values.43800","translations":{"event_type_options":{"fr":"30 jours","en":"30 days"}}};
export const BOOKING_OPENING_VALUES60480 = {"id":"booking_opening.values.60480","translations":{"event_type_options":{"fr":"6 semaines","en":"6 weeks"}}};
export const BOOKING_OPENING_VALUES87600 = {"id":"booking_opening.values.87600","translations":{"event_type_options":{"fr":"2 mois","en":"2 months"}}};
export const BOOKING_OPENING_VALUES131400 = {"id":"booking_opening.values.131400","translations":{"event_type_options":{"fr":"3 mois","en":"3 months"}}};
export const BOOKING_OPENING_VALUES175200 = {"id":"booking_opening.values.175200","translations":{"event_type_options":{"fr":"4 mois","en":"4 months"}}};
export const BOOKING_OPENING_VALUES219000 = {"id":"booking_opening.values.219000","translations":{"event_type_options":{"fr":"5 mois","en":"5 months"}}};
export const BOOKING_OPENING_VALUES262800 = {"id":"booking_opening.values.262800","translations":{"event_type_options":{"fr":"6 mois","en":"6 months"}}};
export const BOOKING_OPENING_VALUES306600 = {"id":"booking_opening.values.306600","translations":{"event_type_options":{"fr":"7 mois","en":"7 months"}}};
export const BOOKING_OPENING_VALUES350400 = {"id":"booking_opening.values.350400","translations":{"event_type_options":{"fr":"8 mois","en":"8 months"}}};
export const BOOKING_OPENING_VALUES394200 = {"id":"booking_opening.values.394200","translations":{"event_type_options":{"fr":"9 mois","en":"9 months"}}};
export const BOOKING_OPENING_VALUES438000 = {"id":"booking_opening.values.438000","translations":{"event_type_options":{"fr":"10 mois","en":"10 months"}}};
export const BOOKING_OPENING_VALUES481800 = {"id":"booking_opening.values.481800","translations":{"event_type_options":{"fr":"11 mois","en":"11 months"}}};
export const BOOKING_OPENING_VALUES525600 = {"id":"booking_opening.values.525600","translations":{"event_type_options":{"fr":"1 an","en":"1 year"}}};
export const BOOKING_OPENING_VALUES569400 = {"id":"booking_opening.values.569400","translations":{"event_type_options":{"fr":"13 mois","en":"13 months"}}};
export const BOOKING_OPENING_VALUES613200 = {"id":"booking_opening.values.613200","translations":{"event_type_options":{"fr":"14 mois","en":"14 months"}}};
export const BOOKING_OPENING_VALUES657000 = {"id":"booking_opening.values.657000","translations":{"event_type_options":{"fr":"15 mois","en":"15 months"}}};
export const BOOKING_OPENING_VALUES700800 = {"id":"booking_opening.values.700800","translations":{"event_type_options":{"fr":"16 mois","en":"16 months"}}};
export const BOOKING_OPENING_VALUES744600 = {"id":"booking_opening.values.744600","translations":{"event_type_options":{"fr":"17 mois","en":"17 months"}}};
export const BOOKING_OPENING_VALUES788400 = {"id":"booking_opening.values.788400","translations":{"event_type_options":{"fr":"18 mois","en":"18 months"}}};
export const BOOKING_OPENING_VALUES832200 = {"id":"booking_opening.values.832200","translations":{"event_type_options":{"fr":"19 mois","en":"19 months"}}};
export const BOOKING_OPENING_VALUES876000 = {"id":"booking_opening.values.876000","translations":{"event_type_options":{"fr":"20 mois","en":"20 months"}}};
export const BOOKING_OPENING_VALUES919800 = {"id":"booking_opening.values.919800","translations":{"event_type_options":{"fr":"21 mois","en":"21 months"}}};
export const BOOKING_OPENING_VALUES963600 = {"id":"booking_opening.values.963600","translations":{"event_type_options":{"fr":"22 mois","en":"22 months"}}};
export const BOOKING_OPENING_VALUES1004400 = {"id":"booking_opening.values.1004400","translations":{"event_type_options":{"fr":"23 mois","en":"23 months"}}};
export const BOOKING_OPENING_VALUES1045200 = {"id":"booking_opening.values.1045200","translations":{"event_type_options":{"fr":"2 ans","en":"2 years"}}};
export const BOOKING_CLOSING_DESCRIPTION = {"id":"booking_closing.description","translations":{"event_type_options":{"fr":"P\u00e9riode de fermeture des r\u00e9servations","en":"Booking closure period"}}};
export const BOOKING_CLOSING_LABEL = {"id":"booking_closing.label","translations":{"event_type_options":{"fr":"S\u00e9lectionnez la p\u00e9riode de fermeture des r\u00e9servations","en":"Select booking closure period"}}};
export const BOOKING_CLOSING_PLACEHOLDER = {"id":"booking_closing.placeholder","translations":{"event_type_options":{"fr":"S\u00e9lectionnez une p\u00e9riode","en":"Select period"}}};
export const BOOKING_CLOSING_VALUES30 = {"id":"booking_closing.values.30","translations":{"event_type_options":{"fr":"30 minutes","en":"30 minutes"}}};
export const BOOKING_CLOSING_VALUES60 = {"id":"booking_closing.values.60","translations":{"event_type_options":{"fr":"1 heure","en":"1 hour"}}};
export const BOOKING_CLOSING_VALUES120 = {"id":"booking_closing.values.120","translations":{"event_type_options":{"fr":"2 heures","en":"2 hours"}}};
export const BOOKING_CLOSING_VALUES180 = {"id":"booking_closing.values.180","translations":{"event_type_options":{"fr":"3 heures","en":"3 hours"}}};
export const BOOKING_CLOSING_VALUES240 = {"id":"booking_closing.values.240","translations":{"event_type_options":{"fr":"4 heures","en":"4 hours"}}};
export const BOOKING_CLOSING_VALUES360 = {"id":"booking_closing.values.360","translations":{"event_type_options":{"fr":"6 heures","en":"6 hours"}}};
export const BOOKING_CLOSING_VALUES480 = {"id":"booking_closing.values.480","translations":{"event_type_options":{"fr":"8 heures","en":"8 hours"}}};
export const BOOKING_CLOSING_VALUES600 = {"id":"booking_closing.values.600","translations":{"event_type_options":{"fr":"10 heures","en":"10 hours"}}};
export const BOOKING_CLOSING_VALUES720 = {"id":"booking_closing.values.720","translations":{"event_type_options":{"fr":"12 heures","en":"12 hours"}}};
export const BOOKING_CLOSING_VALUES1440 = {"id":"booking_closing.values.1440","translations":{"event_type_options":{"fr":"1 jour","en":"1 day"}}};
export const BOOKING_CLOSING_VALUES2880 = {"id":"booking_closing.values.2880","translations":{"event_type_options":{"fr":"2 jours","en":"2 days"}}};
export const BOOKING_CLOSING_VALUES4320 = {"id":"booking_closing.values.4320","translations":{"event_type_options":{"fr":"3 jours","en":"3 days"}}};
export const BOOKING_CLOSING_VALUES5760 = {"id":"booking_closing.values.5760","translations":{"event_type_options":{"fr":"4 jours","en":"4 days"}}};
export const BOOKING_CLOSING_VALUES7200 = {"id":"booking_closing.values.7200","translations":{"event_type_options":{"fr":"5 jours","en":"5 days"}}};
export const BOOKING_CLOSING_VALUES8640 = {"id":"booking_closing.values.8640","translations":{"event_type_options":{"fr":"6 jours","en":"6 days"}}};
export const BOOKING_CLOSING_VALUES10080 = {"id":"booking_closing.values.10080","translations":{"event_type_options":{"fr":"1 semaine","en":"1 week"}}};
export const BOOKING_CLOSING_VALUES20160 = {"id":"booking_closing.values.20160","translations":{"event_type_options":{"fr":"2 semaines","en":"2 weeks"}}};
export const BOOKING_CLOSING_VALUES30240 = {"id":"booking_closing.values.30240","translations":{"event_type_options":{"fr":"3 semaines","en":"3 weeks"}}};
export const BOOKING_CLOSING_VALUES43800 = {"id":"booking_closing.values.43800","translations":{"event_type_options":{"fr":"1 mois","en":"1 month"}}};
export const BOOKING_CLOSING_VALUES60480 = {"id":"booking_closing.values.60480","translations":{"event_type_options":{"fr":"6 semaines","en":"6 weeks"}}};
export const BOOKING_CLOSING_VALUES87600 = {"id":"booking_closing.values.87600","translations":{"event_type_options":{"fr":"2 mois","en":"2 months"}}};
export const BOOKING_CLOSING_VALUES131400 = {"id":"booking_closing.values.131400","translations":{"event_type_options":{"fr":"3 mois","en":"3 months"}}};
export const BOOKING_CLOSING_VALUES175200 = {"id":"booking_closing.values.175200","translations":{"event_type_options":{"fr":"4 mois","en":"4 months"}}};
export const BOOKING_CLOSING_VALUES219000 = {"id":"booking_closing.values.219000","translations":{"event_type_options":{"fr":"5 mois","en":"5 months"}}};
export const BOOKING_CLOSING_VALUES262800 = {"id":"booking_closing.values.262800","translations":{"event_type_options":{"fr":"6 mois","en":"6 months"}}};
export const BOOKING_CLOSING_VALUES350400 = {"id":"booking_closing.values.350400","translations":{"event_type_options":{"fr":"8 mois","en":"8 months"}}};
export const BOOKING_CLOSING_VALUES438000 = {"id":"booking_closing.values.438000","translations":{"event_type_options":{"fr":"10 mois","en":"10 months"}}};
export const BOOKING_CLOSING_VALUES525600 = {"id":"booking_closing.values.525600","translations":{"event_type_options":{"fr":"1 an","en":"1 year"}}};
export const CANCELLATION_DEADLINE_DESCRIPTION = {"id":"cancellation_deadline.description","translations":{"event_type_options":{"fr":"D\u00e9lai d'annulation","en":"Cancellation period"}}};
export const CANCELLATION_DEADLINE_LABEL = {"id":"cancellation_deadline.label","translations":{"event_type_options":{"fr":"S\u00e9lectionnez le d\u00e9lai d'annulation","en":"Select a cancellation period"}}};
export const CANCELLATION_DEADLINE_PLACEHOLDER = {"id":"cancellation_deadline.placeholder","translations":{"event_type_options":{"fr":"S\u00e9lectionnez une p\u00e9riode","en":"Select period"}}};
export const CANCELLATION_DEADLINE_VALUES30 = {"id":"cancellation_deadline.values.30","translations":{"event_type_options":{"fr":"30 minutes","en":"30 minutes"}}};
export const CANCELLATION_DEADLINE_VALUES45 = {"id":"cancellation_deadline.values.45","translations":{"event_type_options":{"fr":"45 minutes","en":"45 minutes"}}};
export const CANCELLATION_DEADLINE_VALUES60 = {"id":"cancellation_deadline.values.60","translations":{"event_type_options":{"fr":"1 heure","en":"1 hour"}}};
export const CANCELLATION_DEADLINE_VALUES120 = {"id":"cancellation_deadline.values.120","translations":{"event_type_options":{"fr":"2 heures","en":"2 hours"}}};
export const CANCELLATION_DEADLINE_VALUES240 = {"id":"cancellation_deadline.values.240","translations":{"event_type_options":{"fr":"4 heures","en":"4 hours"}}};
export const CANCELLATION_DEADLINE_VALUES480 = {"id":"cancellation_deadline.values.480","translations":{"event_type_options":{"fr":"8 heures","en":"8 hours"}}};
export const CANCELLATION_DEADLINE_VALUES720 = {"id":"cancellation_deadline.values.720","translations":{"event_type_options":{"fr":"12 heures","en":"12 hours"}}};
export const CANCELLATION_DEADLINE_VALUES960 = {"id":"cancellation_deadline.values.960","translations":{"event_type_options":{"fr":"16 heures","en":"16 hours"}}};
export const MESSAGE_DELETED_TITLE = {"id":"message_deleted.title","translations":{"faq":{"fr":"Question d\u00e9j\u00e0 r\u00e9pondue","en":"Question already answered"}}};
export const MESSAGE_DELETED_MESSAGE = {"id":"message_deleted.message","translations":{"faq":{"fr":"Bonjour,\n\n Votre question a d\u00e9j\u00e0 \u00e9t\u00e9 r\u00e9pondue, merci de vous r\u00e9f\u00e9rer \u00e0 la FAQ pour y trouver la r\u00e9ponse, veuillez utiliser le moteur de recherche si besoin. \n\n Cordialement, \n\n L'\u00e9quipe Instamed","en":"Hello,\n\n Your question has already been answered, please refer to FAQ to find answer, use search engine if needed. \n\n Best regards, \n\n The Instamed team"}}};
export const FAQ_SHORT_TITLE = {"id":"faq.short_title","translations":{"faq":{"fr":"FAQ","en":"FAQ"}}};
export const FAQ_TITLE = {"id":"faq.title","translations":{"faq":{"fr":"Foire aux questions","en":"Frequently Asked Questions"}}};
export const FAQ_SHORT_FAQ_TIPS = {"id":"faq.short_faq_tips","translations":{"faq":{"fr":"FAQ & Astuces patients","en":"FAQ & Patient tips"}}};
export const FAQ_FAQ_TIPS = {"id":"faq.faq_tips","translations":{"faq":{"fr":"Foire Aux Questions & Astuces patients","en":"Frequently Asked Questions & Patient tips"}}};
export const FAQ_NEW_QUESTION = {"id":"faq.new_question","translations":{"faq":{"fr":"Nouvelle question","en":"New question"}}};
export const FAQ_WARNING_DELETE = {"id":"faq.warning_delete","translations":{"faq":{"fr":"\u00cates-vous s\u00fbr de vouloir supprimer cette question ?","en":"Are you sure you want to delete this question?"}}};
export const FAQ_NO_ANSWER = {"id":"faq.no_answer","translations":{"faq":{"fr":"Aucune r\u00e9ponse","en":"No reply"}}};
export const FAQ_PATIENT_QUESTION = {"id":"faq.patient_question","translations":{"faq":{"fr":"Questions patients","en":"Patient questions"}}};
export const FAQ_NO_PENDING = {"id":"faq.no_pending","translations":{"faq":{"fr":"Pas d'astuces de patient en attente","en":"No pending patient tips"}}};
export const FAQ_YOUR_QUESTION = {"id":"faq.your_question","translations":{"faq":{"fr":"Votre question","en":"Your question"}}};
export const FAQ_YOUR_ANSWER = {"id":"faq.your_answer","translations":{"faq":{"fr":"Votre r\u00e9ponse","en":"Your answer"}}};
export const FAQ_QUESTIONS_ASKED = {"id":"faq.questions_asked","translations":{"faq":{"fr":"Question pos\u00e9e par le patient","en":"Patient question"}}};
export const FAQ_QUESTION_IMAGE = {"id":"faq.question_image","translations":{"faq":{"fr":"Image accompagnant la question","en":"Add attachement"}}};
export const FAQ_RAISED = {"id":"faq.raised","translations":{"faq":{"fr":"Lev\u00e9e","en":"Resolved"}}};
export const FAQ_ANSWER_TITLE = {"id":"faq.answer.title","translations":{"faq":{"fr":"R\u00e9pondre - Foire aux questions -","en":"Reply - Frequently Asked Questions -"}}};
export const FAQ_ANSWER_SUBTITLE = {"id":"faq.answer.subtitle","translations":{"faq":{"fr":"Foire aux questions - R\u00e9pondre","en":"Frequently Asked Questions - Reply"}}};
export const FAQ_ACTIONS_CONSULT = {"id":"faq.actions.consult","translations":{"faq":{"fr":"Consulter les astuces patient","en":"View patient tips"}}};
export const FAQ_ACTIONS_CONSULT_FAQ = {"id":"faq.actions.consult_faq","translations":{"faq":{"fr":"Consulter la FAQ","en":"View FAQ"}}};
export const FAQ_ACTIONS_ASK = {"id":"faq.actions.ask","translations":{"faq":{"fr":"Poser votre question","en":"Ask your question"}}};
export const FAQ_ACTIONS_VALIDATE = {"id":"faq.actions.validate","translations":{"faq":{"fr":"Valider l'astuce","en":"Validate tip"}}};
export const FAQ_ACTIONS_EDIT = {"id":"faq.actions.edit","translations":{"faq":{"fr":"Editer","en":"Edit"}}};
export const FAQ_ACTIONS_DELETE = {"id":"faq.actions.delete","translations":{"faq":{"fr":"Supprimer l'astuce","en":"Delete tip"}}};
export const FAQ_ACTIONS_WRITE = {"id":"faq.actions.write","translations":{"faq":{"fr":"Ecrivez votre r\u00e9ponse ici","en":"Type your answer here"}}};
export const FAQ_ACTIONS_SELECT_FILE = {"id":"faq.actions.select_file","translations":{"faq":{"fr":"Veuillez s\u00e9lectionner un fichier dont le format est pris en charge (image, document...)","en":"Please select file in a supported format (image, document, etc.)"}}};
export const FAQ_HELPER_QUESTION = {"id":"faq.helper.question","translations":{"faq":{"fr":"Vous avez une question de sant\u00e9 ? Posez-la \u00e0 votre \u00e9quipe soignante :","en":"Have a question about health? Ask your care team :"}}};
export const FAQ_HELPER_FIND_QUESTION = {"id":"faq.helper.find_question","translations":{"faq":{"fr":"Vous retrouvez ici des questions avec des r\u00e9ponses que vous pouvez alimenter.","en":"Here you will find questions with answers that you can update."}}};
export const FAQ_MESSAGE_ANSWER_SUBMITTED = {"id":"faq.message.answer_submitted","translations":{"faq":{"fr":"Votre r\u00e9ponse a \u00e9t\u00e9 \u00e9crite, la question est d\u00e9sormais publique","en":"Your answer is submitted, the question is public"}}};
export const FAQ_MESSAGE_QUESTION_SUBMITTED = {"id":"faq.message.question_submitted","translations":{"faq":{"fr":"Votre question a \u00e9t\u00e9 envoy\u00e9e au service","en":"Your question has been sent to the department"}}};
export const FAQ_MESSAGE_QUESTION_DELETED = {"id":"faq.message.question_deleted","translations":{"faq":{"fr":"La question a bien \u00e9t\u00e9 supprim\u00e9e","en":"The question has been deleted"}}};
export const HEALTH_CARD_ACTIONS_ENTER_DATA = {"id":"health_card.actions.enter_data","translations":{"health_card":{"fr":"Continuer sans carte vitale","en":"Continue without Carte vitale"}}};
export const HEALTH_CARD_ACTIONS_READ_CARD = {"id":"health_card.actions.read_card","translations":{"health_card":{"fr":"Lire la carte vitale","en":"Read the Carte vitale"}}};
export const HEALTH_CARD_ACTIONS_SEND_MAIL = {"id":"health_card.actions.send_mail","translations":{"health_card":{"fr":"Envoyer l'e-mail d'activation","en":"Send activation email"}}};
export const HEALTH_CARD_ACTIONS_SEND_SMS = {"id":"health_card.actions.send_sms","translations":{"health_card":{"fr":"Envoyer le SMS d'activation","en":"Send activation SMS"}}};
export const HEALTH_CARD_ACTIONS_RESET = {"id":"health_card.actions.reset","translations":{"health_card":{"fr":"Relancer la lecture","en":"Restart reading"}}};
export const HEALTH_CARD_ACTIONS_RECONNECT = {"id":"health_card.actions.reconnect","translations":{"health_card":{"fr":"Me reconnecter sur Stellair","en":"Reconnect to Stellair"}}};
export const HEALTH_CARD_SUPERVISION_OWNER_SELECTION = {"id":"health_card.supervision.owner_selection","translations":{"health_card":{"fr":"Choix du m\u00e9decin","en":"Doctor selection"}}};
export const HEALTH_CARD_SUPERVISION_CARD_READING = {"id":"health_card.supervision.card_reading","translations":{"health_card":{"fr":"Lecture de la carte vitale","en":"Carte vitale reading"}}};
export const HEALTH_CARD_SUPERVISION_STELLAIR_CONNEXION = {"id":"health_card.supervision.stellair_connexion","translations":{"health_card":{"fr":"Connexion avec Stellair","en":"Connection with Stellair"}}};
export const HEALTH_CARD_SUPERVISION_PATIENT_SELECTION = {"id":"health_card.supervision.patient_selection","translations":{"health_card":{"fr":"Choix du patient","en":"Patient selection"}}};
export const HEALTH_CARD_SUPERVISION_MANUAL_PATIENT_SELECTION = {"id":"health_card.supervision.manual_patient_selection","translations":{"health_card":{"fr":"Choix du patient","en":"Manual patient selection"}}};
export const HEALTH_CARD_SUPERVISION_PATIENT_CONFIRMATION = {"id":"health_card.supervision.patient_confirmation","translations":{"health_card":{"fr":"V\u00e9rification du patient","en":"Patient verification"}}};
export const HEALTH_CARD_SUPERVISION_PATIENT_CONTACT = {"id":"health_card.supervision.patient_contact","translations":{"health_card":{"fr":"Contact du patient","en":"Patient contact"}}};
export const HEALTH_CARD_SUPERVISION_PATIENT_SEARCH = {"id":"health_card.supervision.patient_search","translations":{"health_card":{"fr":"Recherchez votre patient","en":"Search patient"}}};
export const HEALTH_CARD_SUPERVISION_CONSULTATION_CREATION = {"id":"health_card.supervision.consultation_creation","translations":{"health_card":{"fr":"Cr\u00e9ation de la consultation","en":"Consultation creation"}}};
export const HEALTH_CARD_SUPERVISION_OWNER_NAME = {"id":"health_card.supervision.owner_name","translations":{"health_card":{"fr":"Nom du m\u00e9decin","en":"Doctor's name"}}};
export const HEALTH_CARD_SUPERVISION_PATIENT_RESEARCH = {"id":"health_card.supervision.patient_research","translations":{"health_card":{"fr":"Recherchez votre patient","en":"Search patient"}}};
export const HEALTH_CARD_SUPERVISION_WRONG_PATIENT = {"id":"health_card.supervision.wrong_patient","translations":{"health_card":{"fr":"Il s'agit d'un patient diff\u00e9rent","en":"This is a different patient"}}};
export const HEALTH_CARD_SUPERVISION_LINK_PROFILE = {"id":"health_card.supervision.link_profile","translations":{"health_card":{"fr":"Voir le profil","en":"View profile"}}};
export const HISTORIC_TAB_TITLE = {"id":"historic.tab_title","translations":{"historic":{"fr":"Historique","en":"History"}}};
export const HISTORIC_TITLE = {"id":"historic.title","translations":{"historic":{"fr":"Historique des t\u00e9l\u00e9consultations","en":"Teleconsultation history"}}};
export const HISTORIC_NOT_FOUND = {"id":"historic.not_found","translations":{"historic":{"fr":"Aucun historique trouv\u00e9","en":"No history found"}}};
export const HISTORIC_NOTE_TAKING = {"id":"historic.note_taking","translations":{"historic":{"fr":"Prise de note","en":"Note taking"}}};
export const HISTORIC_TELECONSULTATION = {"id":"historic.teleconsultation","translations":{"historic":{"fr":"Historique des derni\u00e8res t\u00e9l\u00e9consultations","en":"History of recent teleconsultations"}}};
export const HISTORIC_HELPER_NOTE_TAKING = {"id":"historic.helper.note_taking","translations":{"historic":{"fr":"Vous pouvez prendre des notes ici qui seront consultables dans l'historique des t\u00e9l\u00e9consultations ainsi que dans le dossier du patient.","en":"You can take notes here that will be viewable in teleconsultation history as well as in patient's record."}}};
export const HISTORIC_MESSAGE_HISTORIC_DELETED = {"id":"historic.message.historic_deleted","translations":{"historic":{"fr":"Le compte rendu a bien \u00e9t\u00e9 supprim\u00e9.","en":"The report has been successfully deleted."}}};
export const HISTORIC_MESSAGE_FILL_NOTE = {"id":"historic.message.fill_note","translations":{"historic":{"fr":"Veuillez \u00e9crire une note.","en":"Please write note."}}};
export const HISTORIC_EDITION_TAB_TITLE = {"id":"historic.edition.tab_title","translations":{"historic":{"fr":"Edition historique","en":"Edit history"}}};
export const HISTORIC_EDITION_TITLE = {"id":"historic.edition.title","translations":{"historic":{"fr":"Edition de l'historique des t\u00e9l\u00e9consultations","en":"Edit teleconsultation history"}}};
export const HISTORIC_EDITION_DATE = {"id":"historic.edition.date","translations":{"historic":{"fr":"T\u00e9l\u00e9consultation du {{ date }} \u00e0 {{ hour }}","en":"Teleconsultation on {{ date }} at {{ hour }}"}}};
export const HISTORIC_EDITION_TEXT_INPUT = {"id":"historic.edition.text_input","translations":{"historic":{"fr":"\u00c9crivez votre compte rendu ici","en":"Write your report here"}}};
export const HISTORIC_EDITION_SHOW_HISTORIC = {"id":"historic.edition.show_historic","translations":{"historic":{"fr":"Afficher l'historique","en":"View history"}}};
export const HISTORIC_CONSULTATION_TITLE = {"id":"historic.consultation.title","translations":{"historic":{"fr":"T\u00e9l\u00e9consultation avec","en":"Teleconsultation with"}}};
export const HISTORIC_CONSULTATION_CONTENT = {"id":"historic.consultation.content","translations":{"historic":{"fr":"compte rendu","en":"report"}}};
export const TYPE_VALUES_DRIVER_LICENCE = {"id":"type.values.driver_licence","translations":{"identity_document":{"fr":"Permis de conduire","en":"Driver\u2019s license"}}};
export const TYPE_VALUES_ID_CARD = {"id":"type.values.id_card","translations":{"identity_document":{"fr":"Carte d\u2019identit\u00e9","en":"Identity card"}}};
export const TYPE_VALUES_PASSPORT = {"id":"type.values.passport","translations":{"identity_document":{"fr":"Passeport","en":"Passport"}}};
export const TYPE_VALUES_UNIQUE_IDENTITY_CERTIFICATE = {"id":"type.values.unique_identity_certificate","translations":{"identity_document":{"fr":"Justificatif d'identit\u00e9 \u00e0 usage unique","en":"Identity certificate"}}};
export const TYPE_VALUES_HEALTH_PROFESSIONAL_CARD = {"id":"type.values.health_professional_card","translations":{"identity_document":{"fr":"Carte CPS ou CPE","en":"Health professional card"}}};
export const MAIN_NAME = {"id":"main.name","translations":{"institution":{"fr":"Admin principal","en":"Main Admin"}}};
export const INSTITUTION_INSTITUTION = {"id":"institution.institution","translations":{"institution":{"fr":"Institution","en":"Facility"}}};
export const INSTITUTION_FIELDS_ID = {"id":"institution.fields.id","translations":{"institution":{"fr":"Id","en":"ID"}}};
export const INSTITUTION_FIELDS_NAME = {"id":"institution.fields.name","translations":{"institution":{"fr":"Nom","en":"Name"}}};
export const INSTITUTION_FIELDS_DOMAIN = {"id":"institution.fields.domain","translations":{"institution":{"fr":"Domaine","en":"Domain"}}};
export const INSTITUTION_FIELDS_ACTION = {"id":"institution.fields.action","translations":{"institution":{"fr":"Action","en":"Action"}}};
export const INSTITUTION_FIELDS_CANONICAL = {"id":"institution.fields.canonical","translations":{"institution":{"fr":"Canonical","en":"Canonical"}}};
export const INSTITUTION_FIELDS_INSTITUTION_NAME = {"id":"institution.fields.institution_name","translations":{"institution":{"fr":"Nom de l'institution","en":"Facility name"}}};
export const INSTITUTION_FIELDS_DOMAIN_NAME = {"id":"institution.fields.domain_name","translations":{"institution":{"fr":"Nom de domaine","en":"Domain name"}}};
export const INSTITUTION_FIELDS_PUBLIC = {"id":"institution.fields.public","translations":{"institution":{"fr":"L'institution est publique","en":"Facility is public"}}};
export const INSTITUTION_FIELDS_DEMO = {"id":"institution.fields.demo","translations":{"institution":{"fr":"L'institution est de d\u00e9mo","en":"Facility is demo"}}};
export const INSTITUTION_FIELDS_FINESS = {"id":"institution.fields.finess","translations":{"institution":{"fr":"N\u00b0 FINESS de l'\u00e9tablissement","en":"Facility FINESS No."}}};
export const INSTITUTION_FIELDS_LOGO = {"id":"institution.fields.logo","translations":{"institution":{"fr":"Logo","en":"Logo"}}};
export const INSTITUTION_FIELDS_MOBILE_LOGO = {"id":"institution.fields.mobile_logo","translations":{"institution":{"fr":"Logo mobile","en":"Mobile logo"}}};
export const INSTITUTION_FIELDS_MODULES = {"id":"institution.fields.modules","translations":{"institution":{"fr":"Modules","en":"Modules"}}};
export const INSTITUTION_FIELDS_SERVICE_NAME = {"id":"institution.fields.service_name","translations":{"institution":{"fr":"Nom du service","en":"Department name"}}};
export const INSTITUTION_FIELDS_SERVICE_DESCRIPTION = {"id":"institution.fields.service_description","translations":{"institution":{"fr":"Description du service","en":"Deparment description"}}};
export const INSTITUTION_FIELDS_SERVICE_FORCE_TWO_FA = {"id":"institution.fields.service_force_two_fa","translations":{"institution":{"fr":"Force 2FA","en":"Force 2FA"}}};
export const INSTITUTION_ACTIONS_CREATE = {"id":"institution.actions.create","translations":{"institution":{"fr":"Cr\u00e9er une nouvelle institution","en":"New facility"}}};
export const INSTITUTION_ACTIONS_EDIT = {"id":"institution.actions.edit","translations":{"institution":{"fr":"Modifier L'institution $institution","en":"Edit facility $institution"}}};
export const INSTITUTION_ACTIONS_SELECT = {"id":"institution.actions.select","translations":{"institution":{"fr":"Choisissez votre institution","en":"Choose your facility"}}};
export const INSTITUTION_ACTIONS_UPLOAD = {"id":"institution.actions.upload","translations":{"institution":{"fr":"Veuillez uploader une image","en":"Upload image"}}};
export const INSTITUTION_HELPER_SELECT = {"id":"institution.helper.select","translations":{"institution":{"fr":"Vous faites partie de plusieurs institutions, choisissez celle \u00e0 laquelle vous souhaitez vous connecter","en":"You are part of several facilities, select the one you want to connect to"}}};
export const INSTITUTION_HELPER_MAX_SIZE = {"id":"institution.helper.max_size","translations":{"institution":{"fr":"Ce fichier est trop volumineux. La taille maximale accept\u00e9e est de 8 Mo.","en":"This file is too large. Maximum accepted size is 8 MB."}}};
export const ACTION1 = {"id":"action.1","translations":{"log":{"fr":"Consultation","en":"Consultation"}}};
export const ACTION2 = {"id":"action.2","translations":{"log":{"fr":"Modification","en":"Editing"}}};
export const ACTION4 = {"id":"action.4","translations":{"log":{"fr":"Cr\u00e9ation","en":"Creation"}}};
export const ACTION3 = {"id":"action.3","translations":{"log":{"fr":"Suppression","en":"Deletion"}}};
export const ACTION5 = {"id":"action.5","translations":{"log":{"fr":"Partage","en":"Sharing"}}};
export const ACTION6 = {"id":"action.6","translations":{"log":{"fr":"Partage \u00e0 un autre \u00e9tablissement","en":"Sharing to another facility"}}};
export const STATUS3 = {"id":"status.3","translations":{"log":{"fr":"Annul\u00e9","en":"Cancelled"},"profile":{"fr":"Supprim\u00e9","en":"Deleted"},"task":{"fr":"Refus\u00e9","en":"Refused"}}};
export const JOB_TITLE_DESCRIPTION = {"id":"job_title.description","translations":{"medic_association+intl-icu":{"fr":"Le titre au sein de l'association","en":"The title within the association"}}};
export const JOB_TITLE_LABEL = {"id":"job_title.label","translations":{"medic_association+intl-icu":{"fr":"Fonction","en":"Job title"}}};
export const JOB_TITLE_PLACEHOLDER = {"id":"job_title.placeholder","translations":{"medic_association+intl-icu":{"fr":"Choisissez une fonction...","en":"Select job title..."}}};
export const JOB_TITLE_VALUES_PRESIDENT = {"id":"job_title.values.president","translations":{"medic_association+intl-icu":{"fr":"{__sex, select, 1 {Pr\u00e9sident}  2 {Pr\u00e9sidente}  other {Pr\u00e9sident(e)} }","en":"President"}}};
export const JOB_TITLE_VALUES_VICE_PRESIDENT = {"id":"job_title.values.vice_president","translations":{"medic_association+intl-icu":{"fr":"{__sex, select, 1 {Vice-pr\u00e9sident}  2 {Vice-pr\u00e9sidente}  other {Vice-pr\u00e9sident(e)} }","en":"Vice president"}}};
export const JOB_TITLE_VALUES_ASSISTANT = {"id":"job_title.values.assistant","translations":{"medic_association+intl-icu":{"fr":"Secr\u00e9taire","en":"Assistant"}}};
export const JOB_TITLE_VALUES_TREASURER = {"id":"job_title.values.treasurer","translations":{"medic_association+intl-icu":{"fr":"{__sex, select, 1 {Tr\u00e9sorier}  2 {Tr\u00e9sori\u00e8re}  other {Tr\u00e9sorier(\u00e8re)} }","en":"Treasurer"}}};
export const JOB_TITLE_VALUES_ADMIN_MEMBER = {"id":"job_title.values.admin_member","translations":{"medic_association+intl-icu":{"fr":"Membres du conseil d'administration","en":"Members of the board of directors"}}};
export const JOB_TITLE_VALUES_SPECIALIST_COMITY = {"id":"job_title.values.specialist_comity","translations":{"medic_association+intl-icu":{"fr":"Comit\u00e9 sp\u00e9cialis\u00e9","en":"Specialized committee"}}};
export const JOB_TITLE_VALUES_PROJECT_MANAGER = {"id":"job_title.values.project_manager","translations":{"medic_association+intl-icu":{"fr":"Responsable de projet","en":"Project manager"}}};
export const JOB_TITLE_VALUES_COORDINATOR = {"id":"job_title.values.coordinator","translations":{"medic_association+intl-icu":{"fr":"{__sex, select, 1 {Coordinateur}  2 {Coordinatrice}  other {Coordinateur(trice)} }","en":"Coordinator"}}};
export const JOB_TITLE_VALUES_COMMUNICATION_MANAGER = {"id":"job_title.values.communication_manager","translations":{"medic_association+intl-icu":{"fr":"{__sex, select, 1 {Charg\u00e9 de communication}  2 {Charg\u00e9e de communication}  other {Charg\u00e9(e) de communication} }","en":"Communication manager"}}};
export const JOB_TITLE_VALUES_PUBLIC_RELATION_MANAGER = {"id":"job_title.values.public_relation_manager","translations":{"medic_association+intl-icu":{"fr":"{__sex, select, 1 {Charg\u00e9 de relations publiques}  2 {Charg\u00e9e de relations publiques}  other {Charg\u00e9(e) de relations publiques} }","en":"Public relations manager"}}};
export const JOB_TITLE_VALUES_MEMBERS_MANAGER = {"id":"job_title.values.members_manager","translations":{"medic_association+intl-icu":{"fr":"Responsable des membres","en":"Member manager"}}};
export const JOB_TITLE_VALUES_EVENT_MANAGER = {"id":"job_title.values.event_manager","translations":{"medic_association+intl-icu":{"fr":"Responsable de l'\u00e9v\u00e9nementiel","en":"Appointment manager"}}};
export const JOB_TITLE_VALUES_SOCIAL_MEDIA_MANAGER = {"id":"job_title.values.social_media_manager","translations":{"medic_association+intl-icu":{"fr":"Responsable des r\u00e9seaux sociaux","en":"Social media manager"}}};
export const JOB_TITLE_VALUES_FUNDRAISING_MANAGER = {"id":"job_title.values.fundraising_manager","translations":{"medic_association+intl-icu":{"fr":"{__sex, select, 1 {Charg\u00e9 de la collecte de fonds}  2 {Charg\u00e9e de la collecte de fonds}  other {Charg\u00e9(e) de la collecte de fonds} }","en":"Fundraising Officer"}}};
export const JOB_TITLE_VALUES_LEGAL_COUNSELOR = {"id":"job_title.values.legal_counselor","translations":{"medic_association+intl-icu":{"fr":"Juriste ou conseiller juridique","en":"Legal advisor or legal counsel"}}};
export const JOB_TITLE_VALUES_MEMBER = {"id":"job_title.values.member","translations":{"medic_association+intl-icu":{"fr":"Membre","en":"Member"}}};
export const CONTENT_LABEL = {"id":"content.label","translations":{"medical_background":{"fr":"Contenu","en":"Content"}}};
export const DATE_LABEL = {"id":"date.label","translations":{"medical_background":{"fr":"Date de survenue","en":"Date of onset"}}};
export const TYPE_VALUES1 = {"id":"type.values.1","translations":{"medical_background":{"fr":"M\u00e9dical","en":"Medical"}}};
export const TYPE_VALUES2 = {"id":"type.values.2","translations":{"medical_background":{"fr":"Familial","en":"Family"}}};
export const TYPE_VALUES3 = {"id":"type.values.3","translations":{"medical_background":{"fr":"Chirurgical","en":"Surgical"}}};
export const TYPE_VALUES4 = {"id":"type.values.4","translations":{"medical_background":{"fr":"Non m\u00e9dical","en":"Non-medical"}}};
export const TYPE_VALUES5 = {"id":"type.values.5","translations":{"medical_background":{"fr":"Gyn\u00e9cologique","en":"Gynecological"}}};
export const TYPE1 = {"id":"type.1","translations":{"medical_background":{"fr":"M\u00e9dical","en":"Medical"},"pdf":{"fr":"Ordonnance","en":"Prescription"}}};
export const TYPE2 = {"id":"type.2","translations":{"medical_background":{"fr":"Familial","en":"Family"},"pdf":{"fr":"Questionnaire","en":"Questionnaire"}}};
export const TYPE3 = {"id":"type.3","translations":{"medical_background":{"fr":"Chirurgical","en":"Surgical"},"pdf":{"fr":"Compte-rendu","en":"Report"}}};
export const TYPE4 = {"id":"type.4","translations":{"medical_background":{"fr":"Non m\u00e9dical","en":"Non-medical"},"pdf":{"fr":"Document","en":"Document"}}};
export const TYPE5 = {"id":"type.5","translations":{"medical_background":{"fr":"Gyn\u00e9cologique","en":"Gynecological"}}};
export const FAMILY_MEMBER_TYPE_DESCRIPTION = {"id":"family_member_type.description","translations":{"medical_background":{"fr":"Le membre de la famille auquel l'ant\u00e9c\u00e9dent est rattach\u00e9.","en":"The family member with whom the medical background is associated."}}};
export const FAMILY_MEMBER_TYPE_LABEL = {"id":"family_member_type.label","translations":{"medical_background":{"fr":"Membre de la famille","en":"Family member"}}};
export const FAMILY_MEMBER_TYPE_DISPLAY_LABEL = {"id":"family_member_type.display_label","translations":{"medical_background":{"fr":"Membre de la famille","en":"Family member"}}};
export const FAMILY_MEMBER_TYPE_PLACEHOLDER = {"id":"family_member_type.placeholder","translations":{"medical_background":{"fr":"Choisissez le type...","en":"Choose the type..."}}};
export const FAMILY_MEMBER_TYPE_VALUES1 = {"id":"family_member_type.values.1","translations":{"medical_background":{"fr":"M\u00e8re","en":"Mother"}}};
export const FAMILY_MEMBER_TYPE_VALUES2 = {"id":"family_member_type.values.2","translations":{"medical_background":{"fr":"P\u00e8re","en":"Father"}}};
export const FAMILY_MEMBER_TYPE_VALUES3 = {"id":"family_member_type.values.3","translations":{"medical_background":{"fr":"Fille","en":"Daughter"}}};
export const FAMILY_MEMBER_TYPE_VALUES4 = {"id":"family_member_type.values.4","translations":{"medical_background":{"fr":"Fils","en":"Son"}}};
export const FAMILY_MEMBER_TYPE_VALUES5 = {"id":"family_member_type.values.5","translations":{"medical_background":{"fr":"Soeur","en":"Sister"}}};
export const FAMILY_MEMBER_TYPE_VALUES6 = {"id":"family_member_type.values.6","translations":{"medical_background":{"fr":"Fr\u00e8re","en":"Brother"}}};
export const FAMILY_MEMBER_TYPE_VALUES7 = {"id":"family_member_type.values.7","translations":{"medical_background":{"fr":"Demi-fr\u00e8re paternel","en":"Paternal brother-in-law"}}};
export const FAMILY_MEMBER_TYPE_VALUES8 = {"id":"family_member_type.values.8","translations":{"medical_background":{"fr":"Demi-soeur paternelle","en":"Paternal sister-in-law"}}};
export const FAMILY_MEMBER_TYPE_VALUES9 = {"id":"family_member_type.values.9","translations":{"medical_background":{"fr":"Oncle paternel","en":"Paternal uncle"}}};
export const FAMILY_MEMBER_TYPE_VALUES10 = {"id":"family_member_type.values.10","translations":{"medical_background":{"fr":"Tante paternelle","en":"Paternal aunt"}}};
export const FAMILY_MEMBER_TYPE_VALUES11 = {"id":"family_member_type.values.11","translations":{"medical_background":{"fr":"Grand-p\u00e8re paternel","en":"Paternal grandfather"}}};
export const FAMILY_MEMBER_TYPE_VALUES12 = {"id":"family_member_type.values.12","translations":{"medical_background":{"fr":"Grand-m\u00e8re paternelle","en":"Paternal grandmother"}}};
export const FAMILY_MEMBER_TYPE_VALUES13 = {"id":"family_member_type.values.13","translations":{"medical_background":{"fr":"Cousin paternel","en":"Paternal cousin (male)"}}};
export const FAMILY_MEMBER_TYPE_VALUES14 = {"id":"family_member_type.values.14","translations":{"medical_background":{"fr":"Cousine paternelle","en":"Paternal cousin (female)"}}};
export const FAMILY_MEMBER_TYPE_VALUES15 = {"id":"family_member_type.values.15","translations":{"medical_background":{"fr":"Demi-fr\u00e8re maternel","en":"Maternal brother-in-law"}}};
export const FAMILY_MEMBER_TYPE_VALUES16 = {"id":"family_member_type.values.16","translations":{"medical_background":{"fr":"Demi-soeur maternelle","en":"Maternal sister-in-law"}}};
export const FAMILY_MEMBER_TYPE_VALUES17 = {"id":"family_member_type.values.17","translations":{"medical_background":{"fr":"Oncle maternel","en":"Maternal uncle"}}};
export const FAMILY_MEMBER_TYPE_VALUES18 = {"id":"family_member_type.values.18","translations":{"medical_background":{"fr":"Tante maternelle","en":"Maternal aunt"}}};
export const FAMILY_MEMBER_TYPE_VALUES19 = {"id":"family_member_type.values.19","translations":{"medical_background":{"fr":"Grand-p\u00e8re maternel","en":"Maternal grandfather"}}};
export const FAMILY_MEMBER_TYPE_VALUES20 = {"id":"family_member_type.values.20","translations":{"medical_background":{"fr":"Grand-m\u00e8re maternelle","en":"Maternal grandmother"}}};
export const FAMILY_MEMBER_TYPE_VALUES21 = {"id":"family_member_type.values.21","translations":{"medical_background":{"fr":"Cousin maternel","en":"Maternal cousin (male)"}}};
export const FAMILY_MEMBER_TYPE_VALUES22 = {"id":"family_member_type.values.22","translations":{"medical_background":{"fr":"Cousine maternelle","en":"Maternal cousin (female)"}}};
export const FAMILY_MEMBER_TYPE_VALUES23 = {"id":"family_member_type.values.23","translations":{"medical_background":{"fr":"Autre","en":"Other"}}};
export const FAMILY_MEMBER_TYPE_OTHER_LABEL = {"id":"family_member_type_other.label","translations":{"medical_background":{"fr":"Si autre, pr\u00e9cisez","en":"If other, specify"}}};
export const FAMILY_MEMBER_TYPE_OTHER_DISPLAY_LABEL = {"id":"family_member_type_other.display_label","translations":{"medical_background":{"fr":"Autre","en":"Other"}}};
export const FAMILY_MEMBER_SEX_DESCRIPTION = {"id":"family_member_sex.description","translations":{"medical_background":{"fr":"Le sexe du membre de la famille auquel l'ant\u00e9c\u00e9dent est rattach\u00e9","en":"The sex of the family member with whom the medical background is associated"}}};
export const FAMILY_MEMBER_SEX_LABEL = {"id":"family_member_sex.label","translations":{"medical_background":{"fr":"Sexe de la personne","en":"Gender of the person"}}};
export const FAMILY_MEMBER_SEX_DISPLAY_LABEL = {"id":"family_member_sex.display_label","translations":{"medical_background":{"fr":"Sexe","en":"Gender"}}};
export const FAMILY_MEMBER_SEX_PLACEHOLDER = {"id":"family_member_sex.placeholder","translations":{"medical_background":{"fr":"Choisissez le sexe...","en":"Choose the gender..."}}};
export const FAMILY_MEMBER_SEX_VALUES1 = {"id":"family_member_sex.values.1","translations":{"medical_background":{"fr":"Masculin","en":"Male"}}};
export const FAMILY_MEMBER_SEX_VALUES2 = {"id":"family_member_sex.values.2","translations":{"medical_background":{"fr":"F\u00e9minin","en":"Female"}}};
export const FAMILY_MEMBER_SEX_VALUES3 = {"id":"family_member_sex.values.3","translations":{"medical_background":{"fr":"Autre","en":"Other"}}};
export const FAMILY_MEMBER_DATE_OF_BIRTH_LABEL = {"id":"family_member_date_of_birth.label","translations":{"medical_background":{"fr":"Date de naissance du membre de la famille","en":"Family member's date of birth"}}};
export const FAMILY_MEMBER_DATE_OF_BIRTH_DISPLAY_LABEL = {"id":"family_member_date_of_birth.display_label","translations":{"medical_background":{"fr":"Date de naissance","en":"Date of birth"}}};
export const FAMILY_MEMBER_ALIVE_DESCRIPTION = {"id":"family_member_alive.description","translations":{"medical_background":{"fr":"L'\u00e9tat du membre de la famille auquel l'ant\u00e9c\u00e9dent est rattach\u00e9","en":"The status of the family member to whom the medical history is linked"}}};
export const FAMILY_MEMBER_ALIVE_LABEL = {"id":"family_member_alive.label","translations":{"medical_background":{"fr":"Le membre de la famille est-il en vie ?","en":"Is the family member alive?"}}};
export const FAMILY_MEMBER_ALIVE_PLACEHOLDER = {"id":"family_member_alive.placeholder","translations":{"medical_background":{"fr":"S\u00e9lectionnez une valeur","en":"Select a value"}}};
export const FAMILY_MEMBER_ALIVE_DISPLAY_LABEL = {"id":"family_member_alive.display_label","translations":{"medical_background":{"fr":"En vie","en":"Alive"}}};
export const FAMILY_MEMBER_ALIVE_VALUES0 = {"id":"family_member_alive.values.0","translations":{"medical_background":{"fr":"Non","en":"No"}}};
export const FAMILY_MEMBER_ALIVE_VALUES1 = {"id":"family_member_alive.values.1","translations":{"medical_background":{"fr":"Oui","en":"Yes"}}};
export const FAMILY_MEMBER_ALIVE_VALUES2 = {"id":"family_member_alive.values.2","translations":{"medical_background":{"fr":"Ne sait pas","en":"Doesn't know"}}};
export const FAMILY_MEMBER_DATE_OF_DEATH_LABEL = {"id":"family_member_date_of_death.label","translations":{"medical_background":{"fr":"Ann\u00e9e de d\u00e9c\u00e8s du membre de la famille","en":"Family member's year of death"}}};
export const FAMILY_MEMBER_DATE_OF_DEATH_DISPLAY_LABEL = {"id":"family_member_date_of_death.display_label","translations":{"medical_background":{"fr":"Ann\u00e9e de d\u00e9c\u00e8s","en":"Year of death"}}};
export const FAMILY_MEMBER_EMAIL_LABEL = {"id":"family_member_email.label","translations":{"medical_background":{"fr":"Email (si connu) du membre de la famille","en":"Email (if known) of family member"}}};
export const FAMILY_MEMBER_TAKEN_CARE_OF_DESCRIPTION = {"id":"family_member_taken_care_of.description","translations":{"medical_background":{"fr":"Si le membre de la famille pour lequel l'ant\u00e9c\u00e9dent existe, est toujours \u00e0 la charge du patient","en":"If the family member for whom the history exists, is still dependent on the patient"}}};
export const FAMILY_MEMBER_TAKEN_CARE_OF_LABEL = {"id":"family_member_taken_care_of.label","translations":{"medical_background":{"fr":"L'enfant est-il \u00e0 charge ?","en":"Is the child dependent?"}}};
export const FAMILY_MEMBER_TAKEN_CARE_OF_PLACEHOLDER = {"id":"family_member_taken_care_of.placeholder","translations":{"medical_background":{"fr":"S\u00e9lectionnez une valeur","en":"Select value"}}};
export const FAMILY_MEMBER_TAKEN_CARE_OF_DISPLAY_LABEL = {"id":"family_member_taken_care_of.display_label","translations":{"medical_background":{"fr":"\u00c0 charge","en":"Dependent"}}};
export const FAMILY_MEMBER_TAKEN_CARE_OF_VALUES0 = {"id":"family_member_taken_care_of.values.0","translations":{"medical_background":{"fr":"Non","en":"No"}}};
export const FAMILY_MEMBER_TAKEN_CARE_OF_VALUES1 = {"id":"family_member_taken_care_of.values.1","translations":{"medical_background":{"fr":"Oui","en":"Yes"}}};
export const FAMILY_MEMBER_TAKEN_CARE_OF_VALUES2 = {"id":"family_member_taken_care_of.values.2","translations":{"medical_background":{"fr":"Ne sait pas","en":"Doesn't know"}}};
export const VISIBLE_LABEL = {"id":"visible.label","translations":{"medical_background":{"fr":"Afficher dans les documents via les variables ant\u00e9c\u00e9dents","en":"Display in documents by history variables"}}};
export const PRESCRIPTION_NAME = {"id":"prescription.name","translations":{"medical_document":{"fr":"Ordonnance de {{ patient }} du {{ date }}","en":"Prescription of {{ patient }} from {{ date }}"}}};
export const REPORT_NAME = {"id":"report.name","translations":{"medical_document":{"fr":"Compte-rendu de {{ patient }} du {{ date }} \u00e0 {{ hour }}","en":"Report of {{ patient }} from {{ date }} at {{ hour }}"}}};
export const CONSULTATION_QUESTIONNAIRE_NAME = {"id":"consultation_questionnaire.name","translations":{"medical_document":{"fr":"Questionnaire {{ questionnaire }} de {{ patient }} du {{ date }} \u00e0 {{ hour }}","en":"Questionnaire {{ questionnaire }} of {{ patient }} on {{ date }} at {{ hour }}"}}};
export const DOCUMENT_NAME = {"id":"document.name","translations":{"medical_document":{"fr":"{{ template }} de {{ patient }}","en":"{{ template }} of {{ patient }}"}}};
export const CERFA_NAME = {"id":"cerfa.name","translations":{"medical_document":{"fr":"{{ cerfa }} de {{ patient }}","en":"{{ cerfa }} of {{ patient }}"}}};
export const PRESCRIPTION_DETAILS_ADMINISTRATION_ROUTE = {"id":"prescription_details.administration_route","translations":{"medical_document":{"fr":" Voie {{ administrationRoute }}.","en":"{{ administrationRoute }} route."}}};
export const PRESCRIPTION_DETAILS_LONG_TERM = {"id":"prescription_details.long_term","translations":{"medical_document":{"fr":" TTT de fond.","en":" Background treatment."}}};
export const PRESCRIPTION_DETAILS_NOT_SUBSTITUABLE = {"id":"prescription_details.not_substituable","translations":{"medical_document":{"fr":" Non substituable ({{ notSubstituableReason }}).","en":" Not substituable ({{ notSubstituableReason }})."}}};
export const PRESCRIPTION_DETAILS_COMPOUNDED_MEDICATION = {"id":"prescription_details.compounded_medication","translations":{"medical_document":{"fr":"Pr\u00e9paration magistrale","en":"Magistral preparation"}}};
export const PRESCRIPTION_DETAILS_REIMBURSABLE_COMPOUNDED_MEDICATION = {"id":"prescription_details.reimbursable_compounded_medication","translations":{"medical_document":{"fr":"Prescription \u00e0 but th\u00e9rapeutique en l'absence de sp\u00e9cialit\u00e9s \u00e9quivalentes disponibles","en":"Prescription for therapeutic purposes in absence of available equivalent specialties"}}};
export const PRESCRIPTION_DETAILS_NOT_SUBSTITUABLE_REASONS_NARROW_THERAPEUTHIC_GAP = {"id":"prescription_details.not_substituable_reasons.narrow-therapeuthic-gap","translations":{"medical_document":{"fr":"marge th\u00e9rapeutique \u00e9troite","en":"narrow therapeutic range"}}};
export const PRESCRIPTION_DETAILS_NOT_SUBSTITUABLE_REASONS_FORMAL_CONTRAINDICATION = {"id":"prescription_details.not_substituable_reasons.formal-contraindication","translations":{"medical_document":{"fr":"Contre-indication formelle","en":"Formal contraindication"}}};
export const PRESCRIPTION_DETAILS_NOT_SUBSTITUABLE_REASONS_CHILD_GALENIC_FORM = {"id":"prescription_details.not_substituable_reasons.child-galenic-form","translations":{"medical_document":{"fr":"Enfant forme gal\u00e9nique","en":"Children galenic form"}}};
export const PRESCRIPTION_DETAILS_RENEWAL_TIMES = {"id":"prescription_details.renewal_times","translations":{"medical_document":{"fr":" \u00c0 renouveler {{ renewalTimes }} fois.","en":" To be renewed {{ renewalTimes }} times."}}};
export const PRESCRIPTION_DETAILS_REASON = {"id":"prescription_details.reason","translations":{"medical_document":{"fr":" Motif : {{ reason }}.","en":" Reason : {{ reason }}."}}};
export const PRESCRIPTION_DETAILS_DURATION_UNIT_WEEK = {"id":"prescription_details.duration_unit.WEEK","translations":{"medical_document":{"fr":"semaine(s)","en":"weeks"}}};
export const PRESCRIPTION_DETAILS_DURATION_UNIT_DAY = {"id":"prescription_details.duration_unit.DAY","translations":{"medical_document":{"fr":"jour(s)","en":"days"}}};
export const PRESCRIPTION_DETAILS_DURATION_UNIT_MONTH = {"id":"prescription_details.duration_unit.MONTH","translations":{"medical_document":{"fr":"mois","en":"month"}}};
export const PRESCRIPTION_DETAILS_DURATION = {"id":"prescription_details.duration","translations":{"medical_document":{"fr":" Dur\u00e9e : {{ duration }} {{ durationUnit }}.","en":" Duration : {{ duration }} {{ durationUnit }}."}}};
export const PRESCRIPTION_DETAILS_TIMES_TO_RENEW = {"id":"prescription_details.times_to_renew","translations":{"medical_document":{"fr":" Ordonnance \u00e0 renouveler {{ timesToRenew }} fois.","en":"Prescription to be renewed {{ timesToRenew }} times."}}};
export const PRESCRIPTION_DETAILS_START_DATE = {"id":"prescription_details.start_date","translations":{"medical_document":{"fr":" \u00c0 partir du  {{ startDate }}.","en":" From {{ startDate }}."}}};
export const PRESCRIPTION_DETAILS_END_DATE = {"id":"prescription_details.end_date","translations":{"medical_document":{"fr":" Jusqu'au {{ endDate }}.","en":" Until {{ endDate }}."}}};
export const PRESCRIPTION_DETAILS_PREVENTION = {"id":"prescription_details.prevention","translations":{"medical_document":{"fr":" En lien avec la pr\u00e9vention.","en":" Linked with prevention."}}};
export const PRESCRIPTION_DETAILS_ANTIBIOTIC_PROTOCOL = {"id":"prescription_details.antibiotic_protocol","translations":{"medical_document":{"fr":" En lien avec un protocole antibiotique.","en":" Linked with an antibiotic protocol."}}};
export const PRESCRIPTION_DETAILS_OFF_LABEL = {"id":"prescription_details.off_label","translations":{"medical_document":{"fr":" Hors AMM.","en":" Off-label."}}};
export const PRESCRIPTION_DETAILS_TEMPORARY_USE_AUTHORIZATION = {"id":"prescription_details.temporary_use_authorization","translations":{"medical_document":{"fr":" RTU.","en":" TUA."}}};
export const PRESCRIPTION_DETAILS_OCCUPATIONAL_DISEASE = {"id":"prescription_details.occupational_disease","translations":{"medical_document":{"fr":" En lien avec une maladie professionnelle.","en":" Linked with an occupational disease."}}};
export const PRESCRIPTION_DETAILS_WORK_ACCIDENT = {"id":"prescription_details.work_accident","translations":{"medical_document":{"fr":" En lien avec un accident du travail.","en":" Linked with an accident at work."}}};
export const PRESCRIPTION_DETAILS_NON_LISTED = {"id":"prescription_details.non_listed","translations":{"medical_document":{"fr":" Hors nomenclature.","en":" Out of bill of materials."}}};
export const PRESCRIPTION_DETAILS_NOT_REFUNDABLE = {"id":"prescription_details.not_refundable","translations":{"medical_document":{"fr":" Non remboursable.","en":" Non-refundable."}}};
export const PRESCRIPTION_DETAILS_EMPTY_STOMACH = {"id":"prescription_details.empty_stomach","translations":{"medical_document":{"fr":"\u00c0 r\u00e9aliser \u00e0 jeun","en":"To be performed on an empty stomach"}}};
export const PRESCRIPTION_DETAILS_NOT_ALD_TITLE = {"id":"prescription_details.not_ald.title","translations":{"medical_document":{"fr":"Prescriptions <strong>SANS RAPPORT<\/strong> avec l'affection de longue dur\u00e9e","en":"Prescriptions <strong>NOT RELATED<\/strong> to the long-term condition"}}};
export const PRESCRIPTION_DETAILS_NOT_ALD_SUBTITLE = {"id":"prescription_details.not_ald.subtitle","translations":{"medical_document":{"fr":"(MALADIES INTERCURRENTES)","en":"(INTERCURRENT DISEASES)"}}};
export const PRESCRIPTION_DETAILS_ALD_TITLE = {"id":"prescription_details.ald.title","translations":{"medical_document":{"fr":"Prescriptions relatives au traitement de l\u2019affection de longue dur\u00e9e reconnue (liste ou hors liste)","en":"Prescriptions for the treatment of a recognized long-term condition (listed or unlisted)"}}};
export const PRESCRIPTION_DETAILS_ALD_SUBTITLE = {"id":"prescription_details.ald.subtitle","translations":{"medical_document":{"fr":"<strong>(AFFECTION EXONERANTE)<\/strong>","en":"<strong>(EXEMPTING CONDITION)<\/strong>"}}};
export const MEDICAL_INVOICE_PAYMENT_METHOD1 = {"id":"medical_invoice.payment_method.1","translations":{"medical_invoice":{"fr":"esp\u00e8ces","en":"cash"}}};
export const MEDICAL_INVOICE_PAYMENT_METHOD2 = {"id":"medical_invoice.payment_method.2","translations":{"medical_invoice":{"fr":"ch\u00e8que","en":"check"}}};
export const MEDICAL_INVOICE_PAYMENT_METHOD3 = {"id":"medical_invoice.payment_method.3","translations":{"medical_invoice":{"fr":"virement","en":"bank transfer"}}};
export const MEDICAL_INVOICE_PAYMENT_METHOD4 = {"id":"medical_invoice.payment_method.4","translations":{"medical_invoice":{"fr":"carte bancaire","en":"credit card"}}};
export const MEDICAL_INVOICE_NAME = {"id":"medical_invoice.name","translations":{"medical_invoice":{"fr":"Facture {{ title }} de {{ patient }} du {{ date }}","en":"Invoice {{ title }} of {{ patient }} from {{ date }}"}}};
export const MEDICAL_INVOICE_TOTAL_INVOICED = {"id":"medical_invoice.total_invoiced","translations":{"medical_invoice":{"fr":"Total factur\u00e9","en":"Total invoiced"}}};
export const MEDICAL_INVOICE_TOTAL_PATIENT = {"id":"medical_invoice.total_patient","translations":{"medical_invoice":{"fr":"Total assur\u00e9","en":"Total insured"}}};
export const MEDICAL_INVOICE_TOTAL_AMO = {"id":"medical_invoice.total_amo","translations":{"medical_invoice":{"fr":"Total AMO","en":"Total AMO"}}};
export const MEDICAL_INVOICE_TOTAL_AMC = {"id":"medical_invoice.total_amc","translations":{"medical_invoice":{"fr":"Total AMC","en":"Total AMC"}}};
export const TYPE_BOOLEAN_VALUE_TRUE = {"id":"type.boolean.value.true","translations":{"medical_record_data":{"fr":"Oui","en":"Yes"}}};
export const TYPE_BOOLEAN_VALUE_FALSE = {"id":"type.boolean.value.false","translations":{"medical_record_data":{"fr":"Non","en":"No"}}};
export const FIELD_TYPE_DESCRIPTION = {"id":"field_type.description","translations":{"medical_record_field":{"fr":"Le type de champ qui est cr\u00e9\u00e9","en":"The type of field that is created"}}};
export const FIELD_TYPE_LABEL = {"id":"field_type.label","translations":{"medical_record_field":{"fr":"Type de champ","en":"Field type"}}};
export const FIELD_TYPE_PLACEHOLDER = {"id":"field_type.placeholder","translations":{"medical_record_field":{"fr":"Choisissez le type de champ...","en":"Select field type..."}}};
export const FIELD_TYPE_VALUES_SINGLE_CHOICE = {"id":"field_type.values.single_choice","translations":{"medical_record_field":{"fr":"Choix simple","en":"Single choice"}}};
export const FIELD_TYPE_VALUES_MULTIPLE_CHOICE = {"id":"field_type.values.multiple_choice","translations":{"medical_record_field":{"fr":"Choix multiple","en":"Multiple choice"}}};
export const FIELD_TYPE_VALUES_TEXT = {"id":"field_type.values.text","translations":{"medical_record_field":{"fr":"Texte","en":"Text"}}};
export const FIELD_TYPE_VALUES_TEXTAREA = {"id":"field_type.values.textarea","translations":{"medical_record_field":{"fr":"Zone de texte","en":"Textarea"}}};
export const FIELD_TYPE_VALUES_VARIABLE = {"id":"field_type.values.variable","translations":{"medical_record_field":{"fr":"Variable","en":"Variable"}}};
export const FIELD_TYPE_VALUES_BOOLEAN = {"id":"field_type.values.boolean","translations":{"medical_record_field":{"fr":"Oui ou non","en":"Yes or no"}}};
export const FIELD_TYPE_VALUES_DATE = {"id":"field_type.values.date","translations":{"medical_record_field":{"fr":"Date","en":"Date"}}};
export const FIELD_TYPE_VALUES_NUMBER = {"id":"field_type.values.number","translations":{"medical_record_field":{"fr":"Nombre","en":"Number"}}};
export const FIELD_TYPE_VALUES_CALCULATED = {"id":"field_type.values.calculated","translations":{"medical_record_field":{"fr":"Calcul\u00e9","en":"Calculated"}}};
export const FIELD_TYPE_VALUES_REPEATER = {"id":"field_type.values.repeater","translations":{"medical_record_field":{"fr":"R\u00e9p\u00e9teur","en":"Repeater"}}};
export const FIELD_TYPE_VALUES_CONSTANT = {"id":"field_type.values.constant","translations":{"medical_record_field":{"fr":"Valeur fixe","en":"Fixed value"}}};
export const SECTION_TYPE_DESCRIPTION = {"id":"section_type.description","translations":{"medical_record_section":{"fr":"Le type de section qui est cr\u00e9\u00e9","en":"The type of section that is created"}}};
export const SECTION_TYPE_LABEL = {"id":"section_type.label","translations":{"medical_record_section":{"fr":"Type de section","en":"Section Type"}}};
export const SECTION_TYPE_PLACEHOLDER = {"id":"section_type.placeholder","translations":{"medical_record_section":{"fr":"Choisissez le type de section...","en":"Choose the section type..."}}};
export const SECTION_TYPE_VALUES_FIELD_LIST = {"id":"section_type.values.field_list","translations":{"medical_record_section":{"fr":"Liste de champs","en":"Field list"}}};
export const SECTION_TYPE_VALUES_GENERAL_DATA = {"id":"section_type.values.general_data","translations":{"medical_record_section":{"fr":"Informations g\u00e9n\u00e9rales","en":"General information"}}};
export const SECTION_TYPE_VALUES_MEDICAL_DATA = {"id":"section_type.values.medical_data","translations":{"medical_record_section":{"fr":"Informations m\u00e9dicales","en":"Medical information"}}};
export const SECTION_TYPE_VALUES_MEDICAL_BACKGROUNDS = {"id":"section_type.values.medical_backgrounds","translations":{"medical_record_section":{"fr":"Maladies et Ant\u00e9c\u00e9dents","en":"Diseases and history"}}};
export const SECTION_TYPE_VALUES_ALERTS = {"id":"section_type.values.alerts","translations":{"medical_record_section":{"fr":"Alertes","en":"Alerts"}}};
export const SECTION_TYPE_VALUES_DOCUMENTS = {"id":"section_type.values.documents","translations":{"medical_record_section":{"fr":"Documents","en":"Documents"}}};
export const SECTION_TYPE_VALUES_DISCUSSIONS = {"id":"section_type.values.discussions","translations":{"medical_record_section":{"fr":"Messages","en":"Messages"}}};
export const SECTION_TYPE_VALUES_DISEASES_AND_TREATMENTS = {"id":"section_type.values.diseases_and_treatments","translations":{"medical_record_section":{"fr":"Traitements m\u00e9dicaux","en":"Medical treatments"}}};
export const SECTION_TYPE_VALUES_MEDICAL_TEAM = {"id":"section_type.values.medical_team","translations":{"medical_record_section":{"fr":"Equipe m\u00e9dicale","en":"Care team"}}};
export const SECTION_TYPE_VALUES_ANSWERS_GROUPS = {"id":"section_type.values.answers_groups","translations":{"medical_record_section":{"fr":"Auto-\u00e9valuation","en":"Reported outcome"}}};
export const SECTION_TYPE_VALUES_PHARMACIES = {"id":"section_type.values.pharmacies","translations":{"medical_record_section":{"fr":"Pharmacies","en":"Pharmacies"}}};
export const SECTION_TYPE_VALUES_LABORATORIES = {"id":"section_type.values.laboratories","translations":{"medical_record_section":{"fr":"Laboratoires","en":"Laboratories"}}};
export const SECTION_TYPE_VALUES_ADDRESS = {"id":"section_type.values.address","translations":{"medical_record_section":{"fr":"Autres adresses","en":"Other addresses"}}};
export const SECTION_TYPE_VALUES_NOTES = {"id":"section_type.values.notes","translations":{"medical_record_section":{"fr":"Notes","en":"Notes"}}};
export const SECTION_TYPE_VALUES_CONSULTATIONS = {"id":"section_type.values.consultations","translations":{"medical_record_section":{"fr":"Consultations","en":"Consultations"}}};
export const SECTION_TYPE_VALUES_LIFESTYLE = {"id":"section_type.values.lifestyle","translations":{"medical_record_section":{"fr":"Mode de vie","en":"Lifestyle"}}};
export const SECTION_TYPE_VALUES_EVENTS = {"id":"section_type.values.events","translations":{"medical_record_section":{"fr":"Historique des RDVs","en":"Appointments History"}}};
export const SECTION_TYPE_VALUES_HISTORIC = {"id":"section_type.values.historic","translations":{"medical_record_section":{"fr":"Historique des t\u00e9l\u00e9consultations","en":"Teleconsultation History"}}};
export const COLOR_VALUES_FFFFFF = {"id":"color.values.#ffffff","translations":{"medical_record_section":{"fr":"Blanc","en":"White"}}};
export const COLOR_VALUES000000 = {"id":"color.values.#000000","translations":{"medical_record_section":{"fr":"Noir","en":"Black"}}};
export const COLOR_VALUES0259CA = {"id":"color.values.#0259ca","translations":{"medical_record_section":{"fr":"Bleu","en":"Blue"}}};
export const COLOR_VALUES50CEA0 = {"id":"color.values.#50cea0","translations":{"medical_record_section":{"fr":"Vert","en":"Green"}}};
export const COLOR_VALUES_FB9C4B = {"id":"color.values.#fb9c4b","translations":{"medical_record_section":{"fr":"Orange","en":"Orange"}}};
export const COLOR_VALUES_DF4958 = {"id":"color.values.#df4958","translations":{"medical_record_section":{"fr":"Rouge","en":"Red"}}};
export const COLOR_VALUES_FFC833 = {"id":"color.values.#ffc833","translations":{"medical_record_section":{"fr":"Jaune","en":"Yellow"}}};
export const OWNER_TYPE_DESCRIPTION = {"id":"owner_type.description","translations":{"medical_resource":{"fr":"Le ou les types d'utilisateur avec lesquels l'entit\u00e9 sera partag\u00e9e","en":"The type or types of user with whom the entity will be shared"}}};
export const OWNER_TYPE_LABEL = {"id":"owner_type.label","translations":{"medical_resource":{"fr":"Partag\u00e9 avec","en":"Shared with"}}};
export const OWNER_TYPE_PLACEHOLDER = {"id":"owner_type.placeholder","translations":{"medical_resource":{"fr":"Choisissez une valeur","en":"Select value"}}};
export const OWNER_TYPE_ADMIN1 = {"id":"owner_type.admin.1","translations":{"medical_resource":{"fr":"Institution","en":"Facility"}}};
export const OWNER_TYPE_ADMIN3 = {"id":"owner_type.admin.3","translations":{"medical_resource":{"fr":"M\u00e9decin et les assistants de son \u00e9quipe soignante","en":"Doctor and assistants of their care team"}}};
export const OWNER_TYPE_ADMIN4 = {"id":"owner_type.admin.4","translations":{"medical_resource":{"fr":"M\u00e9decin seul","en":"Doctor alone"}}};
export const OWNER_TYPE_ADMIN5 = {"id":"owner_type.admin.5","translations":{"medical_resource":{"fr":"M\u00e9decin et son \u00e9quipe soignante","en":"Doctor and their care team"}}};
export const OWNER_TYPE_ADMIN6 = {"id":"owner_type.admin.6","translations":{"medical_resource":{"fr":"M\u00e9decin et les m\u00e9decins de son \u00e9quipe soignante","en":"Doctor and the doctors of their care team"}}};
export const OWNER_TYPE_MEDIC_LABEL = {"id":"owner_type.medic.label","translations":{"medical_resource":{"fr":"Type de propri\u00e9taire","en":"Owner type"}}};
export const OWNER_TYPE_MEDIC3 = {"id":"owner_type.medic.3","translations":{"medical_resource":{"fr":"Seulement les assistant(e)s de mon \u00e9quipe","en":"Only assistants of my team"}}};
export const OWNER_TYPE_MEDIC4 = {"id":"owner_type.medic.4","translations":{"medical_resource":{"fr":"Personne d\u2019autre que moi","en":"Only by me"}}};
export const OWNER_TYPE_MEDIC5 = {"id":"owner_type.medic.5","translations":{"medical_resource":{"fr":"Toute mon \u00e9quipe soignante","en":"My entire care team"}}};
export const OWNER_TYPE_MEDIC6 = {"id":"owner_type.medic.6","translations":{"medical_resource":{"fr":"Seulement les m\u00e9decins de mon \u00e9quipe","en":"Only doctors of my team"}}};
export const OWNER_TYPE_NURSE_LABEL = {"id":"owner_type.nurse.label","translations":{"medical_resource":{"fr":"Type de propri\u00e9taire","en":"Owner type"}}};
export const OWNER_TYPE_NURSE3 = {"id":"owner_type.nurse.3","translations":{"medical_resource":{"fr":"Seulement les assistant(e)s de son \u00e9quipe","en":"Only assistants of their team"}}};
export const OWNER_TYPE_NURSE5 = {"id":"owner_type.nurse.5","translations":{"medical_resource":{"fr":"Toute son \u00e9quipe soignante","en":"Their entire care team"}}};
export const OWNER_TYPE_FILTERS_MINE = {"id":"owner_type.filters.mine","translations":{"medical_resource":{"fr":"Les miens","en":"Mine"}}};
export const OWNER_TYPE_FILTERS_GLOBAL = {"id":"owner_type.filters.global","translations":{"medical_resource":{"fr":"Instamed","en":"Instamed"}}};
export const OWNER_TYPE_FILTERS_SHARED = {"id":"owner_type.filters.shared","translations":{"medical_resource":{"fr":"Partag\u00e9 avec moi","en":"Shared with me"}}};
export const OWNER_TYPE_FILTERS_DOCTOR = {"id":"owner_type.filters.doctor","translations":{"medical_resource":{"fr":"Un m\u00e9decin","en":"A doctor"}}};
export const ARCHIVED_LABEL = {"id":"archived.label","translations":{"medical_resource":{"fr":"Etat","en":"Status"}}};
export const ARCHIVED_VALUES_FALSE = {"id":"archived.values.false","translations":{"medical_resource":{"fr":"Actif","en":"Active"}}};
export const ARCHIVED_VALUES_TRUE = {"id":"archived.values.true","translations":{"medical_resource":{"fr":"Archiv\u00e9","en":"Archived"}}};
export const ARCHIVED_FILTERS_FALSE = {"id":"archived.filters.false","translations":{"medical_resource":{"fr":"Actifs","en":"Active"}}};
export const ARCHIVED_FILTERS_TRUE = {"id":"archived.filters.true","translations":{"medical_resource":{"fr":"Archiv\u00e9s","en":"Archived"}}};
export const MEDICAL_TEAM_PERSON_TRUST = {"id":"medical_team.person_trust","translations":{"medical_team":{"fr":"Personne de confiance","en":"Trusted person"}}};
export const MEDICAL_TEAM_DOCTOR = {"id":"medical_team.doctor","translations":{"medical_team":{"fr":"M\u00e9decin","en":"Doctor"}}};
export const MEDICAL_TEAM_CARER = {"id":"medical_team.carer","translations":{"medical_team":{"fr":"Soignants","en":"Carers"}}};
export const MEDICAL_TEAM_REFERRING = {"id":"medical_team.referring","translations":{"medical_team":{"fr":"M\u00e9decin traitant","en":"Primary doctor"}}};
export const MEDICAL_TEAM_NO_BACKGROUND = {"id":"medical_team.no_background","translations":{"medical_team":{"fr":"Aucun ant\u00e9c\u00e9dent renseign\u00e9","en":"No medical history provided"}}};
export const MEDICAL_TEAM_NO_TREATMENT = {"id":"medical_team.no_treatment","translations":{"medical_team":{"fr":"Aucun traitement renseign\u00e9","en":"No treatment provided"}}};
export const MEDICAL_TEAM_NO_DISEASE = {"id":"medical_team.no_disease","translations":{"medical_team":{"fr":"Aucune maladie renseign\u00e9e","en":"No disease provided"}}};
export const MEDICAL_TEAM_NEW_PATIENT_REPORT = {"id":"medical_team.new_patient_report","translations":{"medical_team":{"fr":"Nouveau dossier patient","en":"New patient record"}}};
export const MEDICAL_TEAM_NEW_PATIENT = {"id":"medical_team.new_patient","translations":{"medical_team":{"fr":"Nouveau patient","en":"New patient"}}};
export const MEDICAL_TEAM_CARER_TEAM = {"id":"medical_team.carer_team","translations":{"medical_team":{"fr":"Soignant et son \u00e9quipe soignante","en":"Carer and their care team"}}};
export const MEDICAL_TEAM_CARER_INSTITUTION = {"id":"medical_team.carer_institution","translations":{"medical_team":{"fr":"Soignants de l'\u00e9tablissement","en":"Caregivers from the facility"}}};
export const MEDICAL_TEAM_PATIENT_CARER_TEAM = {"id":"medical_team.patient_carer_team","translations":{"medical_team":{"fr":"Patient + Soignant et son \u00e9quipe soignante","en":"Patient + carer and their care team"}}};
export const MEDICAL_TEAM_PATIENT_CARER_INSTITUTION = {"id":"medical_team.patient_carer_institution","translations":{"medical_team":{"fr":"Patient + Soignants de l'\u00e9tablissement","en":"Patient + carers from the facility"}}};
export const MEDICAL_TEAM_SOME_USERS = {"id":"medical_team.some_users","translations":{"medical_team":{"fr":"Certains utilisateurs","en":"Some users"}}};
export const MEDICAL_TEAM_PATIENT_ONLY = {"id":"medical_team.patient_only","translations":{"medical_team":{"fr":"Patient uniquement","en":"Patient only"}}};
export const MEDICAL_TEAM_MIN_HOUR = {"id":"medical_team.min_hour","translations":{"medical_team":{"fr":"Heure minimale sur le calendrier","en":"Minimum hour on the calendar"}}};
export const MEDICAL_TEAM_MAX_HOUR = {"id":"medical_team.max_hour","translations":{"medical_team":{"fr":"Heure maximale sur le calendrier","en":"Maximum hour on the calendar"}}};
export const MEDICAL_TEAM_ACCOUNT_STATUS = {"id":"medical_team.account_status","translations":{"medical_team":{"fr":"Statut du compte","en":"Account status"}}};
export const MEDICAL_TEAM_COMPANY_NAME = {"id":"medical_team.company_name","translations":{"medical_team":{"fr":"Nom de l'entreprise","en":"Company name"}}};
export const MEDICAL_TEAM_DOCTOR_TO_LINK = {"id":"medical_team.doctor_to_link","translations":{"medical_team":{"fr":"M\u00e9decins \u00e0 lier","en":"Doctors to link"}}};
export const MEDICAL_TEAM_ACTIONS_MANAGE_TEAM = {"id":"medical_team.actions.manage_team","translations":{"medical_team":{"fr":"G\u00e9rer l'\u00e9quipe soignante","en":"Manage"}}};
export const MEDICAL_TEAM_ACTIONS_MANAGE_MY_TEAM = {"id":"medical_team.actions.manage_my_team","translations":{"medical_team":{"fr":"G\u00e9rer mon \u00e9quipe soignante","en":"Manage my care team"}}};
export const MEDICAL_TEAM_ACTIONS_CALL_USER = {"id":"medical_team.actions.call_user","translations":{"medical_team":{"fr":"Lancer la visio","en":"Start video call"}}};
export const MEDICAL_TEAM_ACTIONS_FILL_SYNOPSIS = {"id":"medical_team.actions.fill_synopsis","translations":{"medical_team":{"fr":"Renseignez le synopsis du patient.","en":"Add patient's synopsis"}}};
export const MEDICAL_TEAM_ACTIONS_EDIT_SYNOPSIS = {"id":"medical_team.actions.edit_synopsis","translations":{"medical_team":{"fr":"Editer le synopsis du patient","en":"Edit patient's synopsis"}}};
export const MEDICAL_TEAM_ACTIONS_INFORM_TREATMENT = {"id":"medical_team.actions.inform_treatment","translations":{"medical_team":{"fr":"Renseignez un traitement","en":"Enter treatment"}}};
export const MEDICAL_TEAM_ACTIONS_EDIT_PROFILE = {"id":"medical_team.actions.edit_profile","translations":{"medical_team":{"fr":"Modifier un profil patient","en":"Edit patient profile"}}};
export const MEDICAL_TEAM_ACTIONS_CREATE_PATIENT_REPORT = {"id":"medical_team.actions.create_patient_report","translations":{"medical_team":{"fr":"Cr\u00e9er un nouveau dossier","en":"New file"}}};
export const MEDICAL_TEAM_ACTIONS_CREATE_NEW_PATIENT = {"id":"medical_team.actions.create_new_patient","translations":{"medical_team":{"fr":"Cr\u00e9er un nouveau patient","en":"New patient"}}};
export const MEDICAL_TEAM_ACTIONS_ENABLE_NOTIF = {"id":"medical_team.actions.enable_notif","translations":{"medical_team":{"fr":"Activer les notifications","en":"Enable notifications"}}};
export const MEDICAL_TEAM_ACTIONS_BLOCK_ALL_NOTIF = {"id":"medical_team.actions.block_all_notif","translations":{"medical_team":{"fr":"Bloquer toutes les notifications","en":"Block all notifications"}}};
export const MEDICAL_TEAM_ACTIONS_DECLARE_PATIENT_DECEASED = {"id":"medical_team.actions.declare_patient_deceased","translations":{"medical_team":{"fr":"D\u00e9clarer ce patient comme d\u00e9c\u00e9d\u00e9","en":"Declare this patient deceased"}}};
export const MEDICAL_TEAM_ACTIONS_ADD_DEATH_DATE = {"id":"medical_team.actions.add_death_date","translations":{"medical_team":{"fr":"Ajouter une date de d\u00e9c\u00e8s","en":"Add a date of death"}}};
export const MEDICAL_TEAM_MESSAGES_ALERT_DRAFT_INVOICE = {"id":"medical_team.messages.alert_draft_invoice","translations":{"medical_team":{"fr":"Il semble que l'une de vos factures n'ait pas \u00e9t\u00e9 synchronis\u00e9e.","en":"It seems that one of your invoices has not been synchronized."}}};
export const MEDICAL_TEAM_MESSAGES_INVOICE_SYNC = {"id":"medical_team.messages.invoice_sync","translations":{"medical_team":{"fr":"La facture a bien \u00e9t\u00e9 synchronis\u00e9e","en":"The invoice has been successfully synchronized"}}};
export const MEDICAL_TEAM_MESSAGES_LEAVE_TEAM = {"id":"medical_team.messages.leave_team","translations":{"medical_team":{"fr":"\u00cates-vous s\u00fbr de vouloir quitter l'\u00e9quipe soignante de ce patient ? Il est possible que vous n'ayez plus acc\u00e8s \u00e0 son dossier.","en":"Are you sure you want to leave this patient's care team? You may no longer have access to their record."}}};
export const MEDICAL_TEAM_MESSAGES_NOT_IN_TEAM = {"id":"medical_team.messages.not_in_team","translations":{"medical_team":{"fr":"Vous ne faites plus partie de l'\u00e9quipe soignante de ce patient","en":"You are no longer part of this patient's care team"}}};
export const MEDICAL_TEAM_MESSAGES_REMOVE_DOCTOR = {"id":"medical_team.messages.remove_doctor","translations":{"medical_team":{"fr":"Vous \u00eates sur le point de retirer ce m\u00e9decin de votre \u00e9quipe soignante. Il n\u2019aura plus acc\u00e8s aux nouveaux \u00e9l\u00e9ments de votre dossier. Vous pourrez toujours le rajouter ult\u00e9rieurement.","en":"You are about to remove this doctor from your care team. They will no longer have access to new elements of your record. You can always add them back later."}}};
export const MEDICAL_TEAM_MESSAGES_REMOVE_DOCTOR_SHORT = {"id":"medical_team.messages.remove_doctor_short","translations":{"medical_team":{"fr":"Vous \u00eates sur le point de retirer ce m\u00e9decin correspondant de votre \u00e9quipe soignante.","en":"You are about to remove this doctor from your care team."}}};
export const MEDICAL_TEAM_MESSAGES_REMOVE_DOCTOR_PATIENT = {"id":"medical_team.messages.remove_doctor_patient","translations":{"medical_team":{"fr":"Vous \u00eates sur le point de retirer ce m\u00e9decin correspondant de l\u2019\u00e9quipe soignante de votre patient","en":"You are about to remove this doctor from your patient's care team."}}};
export const MEDICAL_TEAM_MESSAGES_USER_REMOVED = {"id":"medical_team.messages.user_removed","translations":{"medical_team":{"fr":"L'utilisateur a bien \u00e9t\u00e9 retir\u00e9 de l'\u00e9quipe soignante","en":"The user has been successfully removed from the care team"}}};
export const MEDICAL_TEAM_MESSAGES_IS_SPECIALTY_REFERRING = {"id":"medical_team.messages.is_specialty_referring","translations":{"medical_team":{"fr":"$doctor est d\u00e9sormais le r\u00e9f\u00e9rent de la sp\u00e9cialit\u00e9 : $specialty","en":"$doctor is now the specialist for: $specialty"}}};
export const MEDICAL_TEAM_MESSAGES_DOCTOR_SPECIALTY_REFERRING = {"id":"medical_team.messages.doctor_specialty_referring","translations":{"medical_team":{"fr":"Le m\u00e9decin est d\u00e9sormais le r\u00e9f\u00e9rent de la sp\u00e9cialit\u00e9 : $specialty","en":"The doctor is now specialist for: $specialty"}}};
export const MEDICAL_TEAM_MESSAGES_IS_NOT_SPECIALTY_REFERRING = {"id":"medical_team.messages.is_not_specialty_referring","translations":{"medical_team":{"fr":"$doctor n'est plus le r\u00e9f\u00e9rent de la sp\u00e9cialit\u00e9 : $specialty","en":"$doctor is no longer specialist for: $specialty"}}};
export const MEDICAL_TEAM_MESSAGES_DOCTOR_NOT_SPECIALTY_REFERRING = {"id":"medical_team.messages.doctor_not_specialty_referring","translations":{"medical_team":{"fr":"Le m\u00e9decin n'est plus le r\u00e9f\u00e9rent de la sp\u00e9cialit\u00e9 : $specialty","en":"The doctor is no longer specialist for: $specialty"}}};
export const MEDICAL_TEAM_MESSAGES_ENABLE_NOTIF = {"id":"medical_team.messages.enable_notif","translations":{"medical_team":{"fr":"\u00cates-vous s\u00fbr de vouloir activer les notifications pour ce patient ?","en":"Are you sure you want to enable notifications for this patient ?"}}};
export const MEDICAL_TEAM_MESSAGES_BLOCK_ALL_NOTIF = {"id":"medical_team.messages.block_all_notif","translations":{"medical_team":{"fr":"\u00cates-vous s\u00fbr de vouloir bloquer toutes les notifications pour ce patient ?","en":"Are you sure you want to block all notifications for this patient ?"}}};
export const MEDICAL_TEAM_MESSAGES_UNACTIVE_NOTIF = {"id":"medical_team.messages.unactive_notif","translations":{"medical_team":{"fr":"Les notifications pour ce patient sont d\u00e9sactiv\u00e9es. R\u00e9activez-les si n\u00e9cessaire.","en":"Notifications for this patient are disabled. Reactivate them if necessary"}}};
export const MEDICAL_TEAM_MESSAGES_NOTIF_ENABLE = {"id":"medical_team.messages.notif_enable","translations":{"medical_team":{"fr":"Les notifications pour ce dossier sont activ\u00e9es.","en":"Notifications for this record are enabled"}}};
export const MEDICAL_TEAM_MESSAGES_NOTIF_DISABLED = {"id":"medical_team.messages.notif_disabled","translations":{"medical_team":{"fr":"Les notifications pour ce dossier sont d\u00e9sactiv\u00e9es.","en":"Notifications for this record are disabled"}}};
export const MEDICAL_TEAM_MESSAGES_DOCTOR_UPDATED = {"id":"medical_team.messages.doctor_updated","translations":{"medical_team":{"fr":"Le m\u00e9decin \u00e0 bien \u00e9t\u00e9 modifi\u00e9","en":"The doctor has been modified"}}};
export const MEDICAL_TEAM_MESSAGES_PATIENT_NOT_DECEASED = {"id":"medical_team.messages.patient_not_deceased","translations":{"medical_team":{"fr":"Ce patient n'est pas d\u00e9c\u00e9d\u00e9","en":"This patient is not deceased"}}};
export const MEDICAL_TEAM_MESSAGES_MARKED_AS_DECEASED = {"id":"medical_team.messages.marked_as_deceased","translations":{"medical_team":{"fr":"Une fois marqu\u00e9 comme d\u00e9c\u00e9d\u00e9, ce patient sera automatiquement archiv\u00e9, et accessible via l'onglet \"Archiv\u00e9s\" de la liste.","en":"Once marked as deceased, this patient is automatically archived, and can be accessed by \u201cArchived\u201d tab of the list."}}};
export const MEDICAL_TEAM_MESSAGES_UNKNOWN_DEATH_DATE = {"id":"medical_team.messages.unknown_death_date","translations":{"medical_team":{"fr":"Date de d\u00e9c\u00e8s inconnue","en":"Unknown death date"}}};
export const MEDICAL_TEAM_ERROR_UPDATE_NOTIF = {"id":"medical_team.error.update_notif","translations":{"medical_team":{"fr":"Une erreur est survenue lors de la mise \u00e0 jour des notifications.","en":"An error occurred while updating notifications."}}};
export const MEDICAL_TEAM_HOME_TITLE = {"id":"medical_team.home.title","translations":{"medical_team":{"fr":"Mon \u00e9quipe soignante","en":"My care team"}}};
export const MEDICAL_TEAM_HOME_PROFESSIONALS_LIST_TITLE = {"id":"medical_team.home.professionals_list.title","translations":{"medical_team":{"fr":"Professionnels de sant\u00e9","en":"Caregivers"}}};
export const MEDICAL_TEAM_HOME_PROFESSIONALS_LIST_EMPTY = {"id":"medical_team.home.professionals_list.empty","translations":{"medical_team":{"fr":"Votre \u00e9quipe soignante est vide.","en":"Your care team is empty."}}};
export const MEDICAL_TEAM_HOME_PROFESSIONALS_LIST_ADD_MEMBER_TITLE = {"id":"medical_team.home.professionals_list.add_member.title","translations":{"medical_team":{"fr":"Ajouter un membre","en":"Add member"}}};
export const MEDICAL_TEAM_HOME_PROFESSIONALS_LIST_ADD_MEMBER_MEDICS = {"id":"medical_team.home.professionals_list.add_member.medics","translations":{"medical_team":{"fr":"Ajouter un m\u00e9decin","en":"Add doctor"}}};
export const MEDICAL_TEAM_HOME_PROFESSIONALS_LIST_ADD_MEMBER_NURSES = {"id":"medical_team.home.professionals_list.add_member.nurses","translations":{"medical_team":{"fr":"Ajouter un(e) param\u00e9dical \/ assistant(e)","en":"Add paramedic\/assistant"}}};
export const MEDICAL_TEAM_HOME_PATIENT_BOX_REMOVE_MEMBER = {"id":"medical_team.home.patient_box.remove_member","translations":{"medical_team":{"fr":"Retirer de l'\u00e9quipe soignante","en":"Remove from care team"}}};
export const MEDICAL_TEAM_INVITATION_WAITING_TITLE = {"id":"medical_team.invitation.waiting.title","translations":{"medical_team":{"fr":"Invitation en attente","en":"Pending invitation"}}};
export const MEDICAL_TEAM_INVITATION_WAITING_SEND_MESSAGE = {"id":"medical_team.invitation.waiting.send_message","translations":{"medical_team":{"fr":"Envoyer un message","en":"Send message"}}};
export const MEDICAL_TEAM_INVITATION_WAITING_CANCEL = {"id":"medical_team.invitation.waiting.cancel","translations":{"medical_team":{"fr":"Annuler l'invitation","en":"Cancel invitation"}}};
export const MEDICAL_TEAM_INVITATION_WAITING_NO_REQUEST = {"id":"medical_team.invitation.waiting.no_request","translations":{"medical_team":{"fr":"Vous n'avez pas d'invitations en attente.","en":"You have no pending invitations."}}};
export const MEDICAL_TEAM_INVITATION_INVITE_NURSE = {"id":"medical_team.invitation.invite.nurse","translations":{"medical_team":{"fr":"Ajouter un(e) param\u00e9dical \/ assistant(e)","en":"Add paramedic\/assistant"}}};
export const MEDICAL_TEAM_INVITATION_INVITE_MEDIC = {"id":"medical_team.invitation.invite.medic","translations":{"medical_team":{"fr":"Ajouter un m\u00e9decin","en":"Add doctor"}}};
export const MEDICAL_TEAM_INVITATION_INVITE_SEARCH_EXISTING = {"id":"medical_team.invitation.invite.search.existing","translations":{"medical_team":{"fr":"Cherchez si l'utilisateur est d\u00e9j\u00e0 sur Instamed","en":"Search if user is already on Instamed"}}};
export const MEDICAL_TEAM_INVITATION_INVITE_SEARCH_NOT_EXISTING = {"id":"medical_team.invitation.invite.search.not_existing","translations":{"medical_team":{"fr":"L'utilisateur n'est pas sur Instamed","en":"The user is not on Instamed"}}};
export const MEDICAL_TEAM_INVITATION_INVITE_SEARCH_INPUT_LABEL = {"id":"medical_team.invitation.invite.search.input_label","translations":{"medical_team":{"fr":"Recherchez le m\u00e9decin","en":"Search for doctor"}}};
export const MEDICAL_TEAM_INVITATION_INVITE_SEARCH_NOT_FOUND = {"id":"medical_team.invitation.invite.search.not_found","translations":{"medical_team":{"fr":"L'utilisateur n'est pas dans la liste","en":"The user is not in the list"}}};
export const MEDICAL_TEAM_INVITATION_INVITE_RIGHTS_GENERATE_PRESCRIPTION = {"id":"medical_team.invitation.invite.rights.generate_prescription","translations":{"medical_team":{"fr":"Donner les droits de g\u00e9n\u00e9rer des ordonnances en mon nom \u00e0 cet utilisateur","en":"Grant rights to generate prescriptions in my name to this user"}}};
export const MEDICAL_TEAM_INVITATION_INVITE_RIGHTS_COPY_IN_DISCUSSION = {"id":"medical_team.invitation.invite.rights.copy_in_discussion","translations":{"medical_team":{"fr":"Cet utilisateur sera en copie de mes messages dans la messagerie","en":"This user will be copied on my messages in messenger"}}};
export const MEDICAL_TEAM_INVITATION_INVITE_RIGHTS_USER_ACCEPT = {"id":"medical_team.invitation.invite.rights.user_accept","translations":{"medical_team":{"fr":"Vous \u00eates sur le point d\u2019ajouter un utilisateur \u00e0 votre \u00e9quipe soignante. Vous partagerez alors l\u2019un.e et l\u2019autre vos dossiers patients.","en":"You are about to add an user to your care team. You will then share each other's patient records."}}};
export const MEDICAL_TEAM_INVITATION_INVITE_RIGHTS_MEDIC_ACCEPT = {"id":"medical_team.invitation.invite.rights.medic_accept","translations":{"medical_team":{"fr":"Vous \u00eates sur le point d\u2019ajouter un m\u00e9decin dans votre \u00e9quipe soignante. Cela implique le partage de vos dossiers patients ainsi que vos mod\u00e8les de documents.","en":"You are about to add a doctor to your care team. This implies sharing your patient records as well as your document templates."}}};
export const MEDICAL_TEAM_INVITATION_INVITE_RIGHTS_NOTE = {"id":"medical_team.invitation.invite.rights.note","translations":{"medical_team":{"fr":"N.B. Cette action est r\u00e9versible \u00e0 tout moment en vous rendant dans Mon compte > Mon \u00e9quipe soignante.","en":"N.B. This action can be reversed at any time by going to My account > My care team."}}};
export const MEDICAL_TEAM_INVITATION_RECEIVED_ALERT = {"id":"medical_team.invitation.received.alert","translations":{"medical_team":{"fr":"vous invite \u00e0 rejoindre son \u00e9quipe soignante","en":"invites you to join their care team"}}};
export const MEDICAL_TEAM_INVITATION_RECEIVED_DISCLAIMER_HEADER = {"id":"medical_team.invitation.received.disclaimer.header","translations":{"medical_team":{"fr":"Clause de d\u00e9charge de responsabilit\u00e9 pour l\u2019\u00e9dition des ordonnances par les assistant(e)s m\u00e9dicales","en":"Liability disclaimer for prescription editing by medical assistants"}}};
export const MEDICAL_TEAM_INVITATION_RECEIVED_DISCLAIMER_CONTENT_A = {"id":"medical_team.invitation.received.disclaimer.content_a","translations":{"medical_team":{"fr":"En autorisant votre assistante m\u00e9dicale \u00e0 \u00e9diter des ordonnances depuis votre compte, vous acceptez la responsabilit\u00e9 de toutes les actions effectu\u00e9es par celle-ci ainsi que vous vous engagez \u00e0 les superviser et les contr\u00f4ler.","en":"By allowing your medical assistant to edit prescriptions from your account, you accept responsibility for all actions taken by them and agree to supervise and monitor them."}}};
export const MEDICAL_TEAM_INVITATION_RECEIVED_DISCLAIMER_CONTENT_B = {"id":"medical_team.invitation.received.disclaimer.content_b","translations":{"medical_team":{"fr":"La Plateforme Instamed ne sera en aucun cas responsable des erreurs, omissions, fautes ou infractions commises par le m\u00e9decin ou l\u2019assistante m\u00e9dicale dans le cadre de l\u2019\u00e9dition d\u2019ordonnances. Vous ainsi que votre assistante m\u00e9dicale d\u00e9gagez par la pr\u00e9sente la Plateforme Instamed de toute responsabilit\u00e9, r\u00e9clamation, perte, dommage, co\u00fbt ou d\u00e9pense d\u00e9coulant de, ou li\u00e9e \u00e0, l\u2019utilisation de la fonction d\u2019\u00e9dition d\u2019ordonnances par les assistantes m\u00e9dicales. Cette autorisation est r\u00e9vocable \u00e0 tout moment.","en":"The Instamed Platform shall not be held responsible for any errors, omissions, misconduct, or infractions committed by the doctor or the medical assistant during prescription editing. You and your medical assistant hereby release the Instamed Platform from any liability, claim, loss, damage, cost, or expense arising from or related to the use of the prescription editing function by medical assistants. This authorization can be revoked at any time."}}};
export const MEDICAL_TEAM_INVITATION_RECEIVED_DISCLAIMER_CONTENT_C = {"id":"medical_team.invitation.received.disclaimer.content_c","translations":{"medical_team":{"fr":"J'autorise les membres suivants de mon \u00e9quipe \u00e0 \u00e9diter des ordonnances pour moi.","en":"I authorize the following members of my team to edit prescriptions for me."}}};
export const MEDICAL_TEAM_INVITATION_CONFIRM_TITLE = {"id":"medical_team.invitation.confirm.title","translations":{"medical_team":{"fr":"Accepter l'invitation","en":"Accept invitation"}}};
export const MEDICAL_TEAM_INVITATION_CONFIRM_CONTENT = {"id":"medical_team.invitation.confirm.content","translations":{"medical_team":{"fr":"Vous \u00eates sur le point d\u2019accepter l\u2019invitation de cet utilisateur \u00e0 votre \u00e9quipe soignante. Vous partagerez alors l\u2019un.e et l\u2019autre vos dossiers patients.","en":"You are about to accept this user's invitation to your care team. You will then share each other's patient records."}}};
export const MEDICAL_TEAM_INVITATION_CONFIRM_NOTE = {"id":"medical_team.invitation.confirm.note","translations":{"medical_team":{"fr":"N.B. Cette action est r\u00e9versible \u00e0 tout moment en vous rendant dans Mon compte > ","en":"N.B. This action can be reversed at any time by going to My account >"}}};
export const MEDICAL_TEAM_INVITATION_WARNING_MAIN = {"id":"medical_team.invitation.warning.main","translations":{"medical_team":{"fr":"Vous ne pouvez pas rejoindre l'\u00e9quipe soignante de ce m\u00e9decin","en":"You cannot join this doctor's care team"}}};
export const MEDICAL_TEAM_INVITATION_WARNING_DESCRIPTION = {"id":"medical_team.invitation.warning.description","translations":{"medical_team":{"fr":"Votre abonnement actuel ne vous permet pas de rejoindre l'\u00e9quipe soignante d'un autre m\u00e9decin.","en":"Your current subscription does not allow you to join another doctor's care team."}}};
export const MEDICAL_TEAM_INVITATION_WARNING_UPDATE_SUBSCRIPTION = {"id":"medical_team.invitation.warning.update_subscription","translations":{"medical_team":{"fr":"Modifier mon abonnement","en":"Update my subscription"}}};
export const MEDICAL_TEAM_TREATMENT_ADD_TREATMENT = {"id":"medical_team.treatment.add_treatment","translations":{"medical_team":{"fr":"Vous pouvez rechercher un traitement dans la liste ou bien saisir le nom du traitement et appuyer sur la touche \u201cEntrer\u201d pour l\u2019ajouter.","en":"You can search for treatment in the list or type treatment name and press \"Enter\" to add it."}}};
export const MEDICAL_TEAM_TREATMENT_START_DATE = {"id":"medical_team.treatment.start_date","translations":{"medical_team":{"fr":"Date de d\u00e9but","en":"Start date"}}};
export const MEDICAL_TEAM_TREATMENT_RECURRENCES = {"id":"medical_team.treatment.recurrences","translations":{"medical_team":{"fr":"Nombre de traitements","en":"Number of treatments"}}};
export const MEDICAL_TEAM_TREATMENT_FREQUENCY = {"id":"medical_team.treatment.frequency","translations":{"medical_team":{"fr":"Fr\u00e9quence (jours)","en":"Frequency (days)"}}};
export const MEDICAL_TEAM_TREATMENT_NAME = {"id":"medical_team.treatment.name","translations":{"medical_team":{"fr":"Nom du traitement","en":"Name"}}};
export const MEDICAL_TEAM_TREATMENT_FINISH = {"id":"medical_team.treatment.finish","translations":{"medical_team":{"fr":"Terminer le traitement","en":"End treatment"}}};
export const MEDICAL_TEAM_BACKGROUND_DISPLAY_BACKGROUND = {"id":"medical_team.background.display_background","translations":{"medical_team":{"fr":"Afficher les ant\u00e9c\u00e9dents et les maladies","en":"View medical history and diseases"}}};
export const MEDICAL_TEAM_DRUGS_LINES_PRESCRIPTIONS = {"id":"medical_team.drugs_lines.prescriptions","translations":{"medical_team":{"fr":"Prescriptions","en":"Prescriptions"}}};
export const MEDICAL_TEAM_DRUGS_LINES_RENEW_PRESCRIPTION = {"id":"medical_team.drugs_lines.renew_prescription","translations":{"medical_team":{"fr":"Ordonnance \u00e0 renouveler $count fois.","en":"Prescription to be renewed $count times."}}};
export const MEDICAL_TEAM_DRUGS_LINES_ADMINISTRATION_ROUTE = {"id":"medical_team.drugs_lines.administration_route","translations":{"medical_team":{"fr":" Voie $route .","en":" Route $route."}}};
export const MEDICAL_TEAM_DRUGS_LINES_LONGTERM = {"id":"medical_team.drugs_lines.longterm","translations":{"medical_team":{"fr":" TTT de fond.","en":" Long-term treatment."}}};
export const MEDICAL_TEAM_DRUGS_LINES_NOT_SUBSTITUTABLE = {"id":"medical_team.drugs_lines.not_substitutable","translations":{"medical_team":{"fr":" Non substituable","en":" Non-substitutable"}}};
export const MEDICAL_TEAM_DRUGS_LINES_TO_RENEW = {"id":"medical_team.drugs_lines.to_renew","translations":{"medical_team":{"fr":" \u00c0 renouveler $count fois.","en":" To be renewed $count times."}}};
export const MEDICAL_TEAM_DRUGS_LINES_TO_RENEW_MONTH = {"id":"medical_team.drugs_lines.to_renew_month","translations":{"medical_team":{"fr":" \u00c0 renouveler toutes les $frequency $unit .","en":" To be renewed every $frequency $unit."}}};
export const MEDICAL_TEAM_DRUGS_LINES_TO_RENEW_OTHER = {"id":"medical_team.drugs_lines.to_renew_other","translations":{"medical_team":{"fr":" \u00c0 renouveler tous les $frequency $unit .","en":" To be renewed every $frequency $unit."}}};
export const MEDICAL_TEAM_DRUGS_LINES_REASON = {"id":"medical_team.drugs_lines.reason","translations":{"medical_team":{"fr":" Motif : $reason .","en":" Reason: $reason."}}};
export const MEDICAL_TEAM_DRUGS_LINES_START_DATE = {"id":"medical_team.drugs_lines.start_date","translations":{"medical_team":{"fr":" \u00c0 partir du $date .","en":" Starting from $date."}}};
export const MEDICAL_TEAM_DRUGS_LINES_END_DATE = {"id":"medical_team.drugs_lines.end_date","translations":{"medical_team":{"fr":" Jusqu'au $date .","en":" Until $date."}}};
export const MEDICAL_TEAM_DRUGS_LINES_PREVENTION = {"id":"medical_team.drugs_lines.prevention","translations":{"medical_team":{"fr":" En lien avec la pr\u00e9vention.","en":" Related to prevention."}}};
export const MEDICAL_TEAM_DRUGS_LINES_ANTIBIOTIC_PROTOCOL = {"id":"medical_team.drugs_lines.antibiotic_protocol","translations":{"medical_team":{"fr":" En lien avec un protocole antibiotique.","en":" Related to an antibiotic protocol."}}};
export const MEDICAL_TEAM_DRUGS_LINES_OFF_LABEL = {"id":"medical_team.drugs_lines.off_label","translations":{"medical_team":{"fr":" Hors AMM.","en":" Off-label."}}};
export const MEDICAL_TEAM_DRUGS_LINES_TEMPORARY_USE_AUTH = {"id":"medical_team.drugs_lines.temporary_use_auth","translations":{"medical_team":{"fr":" RTU.","en":" Temporary authorization for use."}}};
export const MEDICAL_TEAM_DRUGS_LINES_OCCUPATIONAL_DISEASE = {"id":"medical_team.drugs_lines.occupational_disease","translations":{"medical_team":{"fr":" En lien avec une maladie professionnelle.","en":" Related to an occupational disease."}}};
export const MEDICAL_TEAM_DRUGS_LINES_WORK_ACCIDENT = {"id":"medical_team.drugs_lines.work_accident","translations":{"medical_team":{"fr":" En lien avec un accident du travail.","en":" Related to a work accident."}}};
export const MEDICAL_TEAM_DRUGS_LINES_NOT_REFUNDABLE = {"id":"medical_team.drugs_lines.not_refundable","translations":{"medical_team":{"fr":" Non remboursable.","en":" Non-refundable."}}};
export const MEDICAL_TEAM_DRUGS_LINES_NOT_LISTED = {"id":"medical_team.drugs_lines.not_listed","translations":{"medical_team":{"fr":" Hors nomenclature.","en":" Not listed."}}};
export const MEDICAL_TEAM_DRUGS_LINES_EMPTY_STOMACH = {"id":"medical_team.drugs_lines.empty_stomach","translations":{"medical_team":{"fr":"\u00c0 r\u00e9aliser \u00e0 jeun","en":"To be taken on empty stomach"}}};
export const MEDICAL_TEAM_DRUGS_LINES_THERAPEUTIC = {"id":"medical_team.drugs_lines.therapeutic","translations":{"medical_team":{"fr":"Prescription \u00e0 but th\u00e9rapeutique en l'absence de sp\u00e9cialit\u00e9s \u00e9quivalentes disponibles","en":"Prescription for therapeutic purposes in absence of equivalent available specialties"}}};
export const MEDICAL_TEAM_DRUGS_LINES_MAGISTRAL = {"id":"medical_team.drugs_lines.magistral","translations":{"medical_team":{"fr":"Pr\u00e9paration magistrale","en":"Magistral preparation"}}};
export const MEDICAL_TEAM_DRUGS_LINES_UNRELATED = {"id":"medical_team.drugs_lines.unrelated","translations":{"medical_team":{"fr":"SANS RAPPORT","en":"UNRELATED"}}};
export const MEDICAL_TEAM_DRUGS_LINES_WITH_AFFECTION = {"id":"medical_team.drugs_lines.with_affection","translations":{"medical_team":{"fr":" avec l'affection de longue dur\u00e9e","en":" with long-term condition"}}};
export const MEDICAL_TEAM_DRUGS_LINES_INTERCURRENT = {"id":"medical_team.drugs_lines.intercurrent","translations":{"medical_team":{"fr":"(MALADIES INTERCURRENTES)","en":"(INTERCURRENT CONDITIONS)"}}};
export const MEDICAL_TEAM_DRUGS_LINES_LONG_TERM_PRESCRIPTION = {"id":"medical_team.drugs_lines.long_term_prescription","translations":{"medical_team":{"fr":"Prescriptions relatives au traitement de l'affection de longue dur\u00e9e reconnue (liste ou hors liste)","en":"Prescriptions related to the treatment of recognized long-term condition (listed or not listed)"}}};
export const MEDICAL_TEAM_DRUGS_LINES_EXEMPTING_CONDITION = {"id":"medical_team.drugs_lines.exempting_condition","translations":{"medical_team":{"fr":"(AFFECTION EXONERANTE)","en":"(EXEMPTION CONDITION)"}}};
export const MEDICAL_TEAM_DRUGS_LINES_FILL_INFORMATIONS = {"id":"medical_team.drugs_lines.fill_informations","translations":{"medical_team":{"fr":"Veuillez renseigner les informations du patient pour pouvoir utiliser la prescription structur\u00e9e.","en":"Please fill in the patient's information to use the structured prescription."}}};
export const MEDICAL_TEAM_DRUGS_LINES_FILL_FILE = {"id":"medical_team.drugs_lines.fill_file","translations":{"medical_team":{"fr":"Remplir le dossier","en":"Complete file"}}};
export const MEDICAL_TEAM_DRUGS_LINES_CLOSE_FILE = {"id":"medical_team.drugs_lines.close_file","translations":{"medical_team":{"fr":"Fermer le dossier","en":"Close file"}}};
export const MEDICAL_TEAM_DRUGS_LINES_ALERT_ALD_INFOS = {"id":"medical_team.drugs_lines.alert_ald_infos","translations":{"medical_team":{"fr":"Vous avez des m\u00e9dicaments ALD et non ALD sur votre ordonnance. Pour pouvoir les s\u00e9parer correctement la prochaine fois, veuillez ajouter le champ structur\u00e9 \"M\u00e9dicaments non ALD Synapse\" sur votre mod\u00e8le d\u2019ordonnance \u00e0 l\u2019endroit o\u00f9 vous souhaitez afficher les m\u00e9dicaments qui ne sont pas li\u00e9s \u00e0 l'ALD du patient.","en":"You have ALD and non-ALD medications on your prescription. To separate them correctly next time, please add the structured field 'Non-ALD Medications Synapse' to your prescription template where you want to display medications not related to the patient's ALD."}}};
export const MEDICAL_TEAM_DRUGS_LINES_ALERT_DETAILS = {"id":"medical_team.drugs_lines.alert_details","translations":{"medical_team":{"fr":"Voir le d\u00e9tail des alertes","en":"View alert details"}}};
export const MEDICAL_TEAM_DRUGS_LINES_ERROR_LOADING = {"id":"medical_team.drugs_lines.error_loading","translations":{"medical_team":{"fr":"Une erreur est survenue lors du chargement de l'\u00e9diteur Synapse.","en":"An error occurred while loading the Synapse editor."}}};
export const MEDICAL_TEAM_DRUGS_LINES_AUTH_EDITOR = {"id":"medical_team.drugs_lines.auth_editor","translations":{"medical_team":{"fr":"Cet \u00e9diteur est \u00e0 usage r\u00e9serv\u00e9 uniquement pour les m\u00e9decins et n'est pas accessible \u00e0 son \u00e9quipe soignante.","en":"This editor is reserved for use only by doctors and is not accessible to their care team."}}};
export const MEDICAL_TEAM_DRUGS_LINES_CONTACT = {"id":"medical_team.drugs_lines.contact","translations":{"medical_team":{"fr":"Si vous \u00eates client Synapse et voyez ce message, veuillez nous contacter sur contact@instamed.fr ou bien via le chat situ\u00e9 en bas \u00e0 droite de votre page.","en":"If you are a Synapse client and see this message, please contact us at contact@instamed.fr or by the chat located at the bottom right of your page."}}};
export const MEDICAL_TEAM_DRUGS_LINES_ANALYSIS = {"id":"medical_team.drugs_lines.analysis","translations":{"medical_team":{"fr":"Analyse des alertes remont\u00e9es","en":"Analysis of raised alerts"}}};
export const MEDICAL_TEAM_DRUGS_LINES_HELPER = {"id":"medical_team.drugs_lines.helper","translations":{"medical_team":{"fr":"Vous devez ajouter le champ structur\u00e9 \"Editeur Synapse\" conjointement de ce champ structur\u00e9 pour qu'il puisse \u00eatre utilis\u00e9.","en":"You must add the structured field 'Synapse Editor' along with this structured field for it to be usable."}}};
export const MEDICAL_TEAM_DRUGS_LINES_SHOW_DRUGS = {"id":"medical_team.drugs_lines.show_drugs","translations":{"medical_team":{"fr":"Ce champ vous permet d'afficher les m\u00e9dicaments non ALD dans le cadre d'une ordonnance bizone.","en":"This field allows you to display non-ALD medications within the framework of bipartite prescription."}}};
export const MENU_HOME = {"id":"menu.home","translations":{"menu":{"fr":"Accueil","en":"Home"}}};
export const MENU_ACCOUNT_MANAGEMENT = {"id":"menu.account_management","translations":{"menu":{"fr":"Gestion des comptes","en":"Account management"}}};
export const MENU_ALL_USERS = {"id":"menu.all_users","translations":{"menu":{"fr":"Tous","en":"All"}}};
export const MENU_PATIENT = {"id":"menu.patient","translations":{"menu":{"fr":"Patients","en":"Patients"}}};
export const MENU_PARAMEDIC = {"id":"menu.paramedic","translations":{"menu":{"fr":"Param\u00e9dicaux","en":"Paramedics"}}};
export const MENU_MEDIC = {"id":"menu.medic","translations":{"menu":{"fr":"M\u00e9decins","en":"Doctors"}}};
export const MENU_PENDING_MEDIC = {"id":"menu.pending_medic","translations":{"menu":{"fr":"M\u00e9decin en attente","en":"Pending doctors"}}};
export const MENU_COORDINATOR = {"id":"menu.coordinator","translations":{"menu":{"fr":"Coordinateurs","en":"Coordinators"}}};
export const MENU_ASSISTANT = {"id":"menu.assistant","translations":{"menu":{"fr":"Secr\u00e9taires","en":"Secretaries"}}};
export const MENU_MEDICAL_ASSISTANT = {"id":"menu.medical_assistant","translations":{"menu":{"fr":"Assistants","en":"Assistants"}}};
export const MENU_DELETED_ACCOUNT = {"id":"menu.deleted_account","translations":{"menu":{"fr":"Comptes supprim\u00e9s","en":"Deleted accounts"}}};
export const MENU_MERGE = {"id":"menu.merge","translations":{"menu":{"fr":"Dossiers en doublons","en":"Duplicate records"}}};
export const MENU_ADMIN = {"id":"menu.admin","translations":{"menu":{"fr":"Admin","en":"Admins"}}};
export const MENU_MANAGER = {"id":"menu.manager","translations":{"menu":{"fr":"Manager","en":"Managers"}}};
export const MENU_DEV = {"id":"menu.dev","translations":{"menu":{"fr":"D\u00e9veloppeur","en":"Developers"}}};
export const MENU_RESEARCH = {"id":"menu.research","translations":{"menu":{"fr":"Recherche","en":"Research"}}};
export const MENU_SUPPORT = {"id":"menu.support","translations":{"menu":{"fr":"Support","en":"Support"}}};
export const MENU_CREATE_SEPA_LINK = {"id":"menu.create_sepa_link","translations":{"menu":{"fr":"Cr\u00e9er un lien SEPA","en":"Create SEPA link"}}};
export const MENU_SPECIALTIES_MANAGEMENT = {"id":"menu.specialties_management","translations":{"menu":{"fr":"Gestion des sp\u00e9cialit\u00e9s","en":"Specialties management"}}};
export const MENU_RPPS_NOINDEX_MANAGEMENT = {"id":"menu.rpps_noindex_management","translations":{"menu":{"fr":"Pages non index\u00e9s","en":"Non-indexed pages"}}};
export const MENU_CALENDAR = {"id":"menu.calendar","translations":{"menu":{"fr":"Calendrier","en":"Calendar"}}};
export const MENU_ALL_CALENDAR = {"id":"menu.all_calendar","translations":{"menu":{"fr":"Tous les calendriers","en":"All calendars"}}};
export const MENU_CALENDAR_MANAGEMENT = {"id":"menu.calendar_management","translations":{"menu":{"fr":"Gestion des calendriers","en":"Calendar management"}}};
export const MENU_EVENT_MANAGEMENT = {"id":"menu.event_management","translations":{"menu":{"fr":"Gestion des \u00e9v\u00e9nements","en":"Appointment management"}}};
export const MENU_CONSULTATIONS_LOCATIONS = {"id":"menu.consultations_locations","translations":{"menu":{"fr":"Lieux de consultation","en":"Consultation locations"}}};
export const MENU_DISCUSSIONS = {"id":"menu.discussions","translations":{"menu":{"fr":"Messagerie","en":"Messenger"}}};
export const MENU_SEND_FILES = {"id":"menu.send_files","translations":{"menu":{"fr":"Envoi de documents","en":"Send documents"}}};
export const MENU_DISEASE_MANAGEMENT = {"id":"menu.disease_management","translations":{"menu":{"fr":"Gestion des maladies","en":"Disease management"}}};
export const MENU_TREATMENTS = {"id":"menu.treatments","translations":{"menu":{"fr":"Traitements","en":"Treatments"}}};
export const MENU_TRANSACTION_MANAGEMENT = {"id":"menu.transaction_management","translations":{"menu":{"fr":"Gestion des transactions","en":"Transaction management"}}};
export const MENU_SUBSCRIPTION_TYPE = {"id":"menu.subscription_type","translations":{"menu":{"fr":"Types d'abonnement","en":"Subscription types"}}};
export const MENU_PATIENT_RECORD = {"id":"menu.patient_record","translations":{"menu":{"fr":"Dossier patient","en":"Patient record"}}};
export const MENU_RECORD_FORM = {"id":"menu.record_form","translations":{"menu":{"fr":"Formulaire du dossier","en":"Record form"}}};
export const MENU_RECORD_TAB = {"id":"menu.record_tab","translations":{"menu":{"fr":"Onglets du dossier","en":"Record tabs"}}};
export const MENU_RECORD_SECTION = {"id":"menu.record_section","translations":{"menu":{"fr":"Sections du dossier","en":"Record sections"}}};
export const MENU_RECORD_FIELDS = {"id":"menu.record_fields","translations":{"menu":{"fr":"Champs du dossier","en":"Record fields"}}};
export const MENU_DOC_TYPE = {"id":"menu.doc_type","translations":{"menu":{"fr":"Types de document","en":"Document types"}}};
export const MENU_VAR_TYPE = {"id":"menu.var_type","translations":{"menu":{"fr":"Types de variables","en":"Variable types"}}};
export const MENU_PAGE_MANAGEMENT = {"id":"menu.page_management","translations":{"menu":{"fr":"Gestion des pages","en":"Page management"}}};
export const MENU_SELF_EVALUATION = {"id":"menu.self_evaluation","translations":{"menu":{"fr":"QUESTIONNAIRES PRO","en":"PRO QUESTIONNAIRES"}}};
export const MENU_QUESTIONNAIRE = {"id":"menu.questionnaire","translations":{"menu":{"fr":"Questionnaires PRO","en":"PRO questionnaires"}}};
export const MENU_RESULTS = {"id":"menu.results","translations":{"menu":{"fr":"R\u00e9sultats","en":"Results"}}};
export const MENU_ALERT = {"id":"menu.alert","translations":{"menu":{"fr":"Alertes","en":"Alerts"}}};
export const MENU_MONITORING = {"id":"menu.monitoring","translations":{"menu":{"fr":"Monitoring","en":"Monitoring"}}};
export const MENU_SHARED = {"id":"menu.shared","translations":{"menu":{"fr":"Partages","en":"Shares"}}};
export const MENU_MEDSPHERE_SYNC = {"id":"menu.medsphere_sync","translations":{"menu":{"fr":"Synchronisation Medsphere","en":"Medsphere synchronization"}}};
export const MENU_AUDIT_TRAIL = {"id":"menu.audit_trail","translations":{"menu":{"fr":"Audit trail","en":"Audit trail"}}};
export const MENU_APP_VERSION = {"id":"menu.app_version","translations":{"menu":{"fr":"Versions de l'app mobile","en":"Mobile app versions"}}};
export const MENU_AI_PLAYGROUND = {"id":"menu.ai_playground","translations":{"menu":{"fr":"AI Playground","en":"AI playground"}}};
export const MENU_AI_IMPORTS_HISTORY = {"id":"menu.ai_imports_history","translations":{"menu":{"fr":"Historique des imports IA","en":"AI imports history"}}};
export const MENU_CONSULTATIONS = {"id":"menu.consultations","translations":{"menu":{"fr":"Consultations","en":"Consultations"}}};
export const MENU_DOC_TEMPLATES = {"id":"menu.doc_templates","translations":{"menu":{"fr":"Mod\u00e8les de documents","en":"Document templates"}}};
export const MENU_TEMPLATES_VAR = {"id":"menu.templates_var","translations":{"menu":{"fr":"Variables de mod\u00e8les","en":"Template variables"}}};
export const MENU_CONSULTATIONS_QUESTIONNAIRE = {"id":"menu.consultations_questionnaire","translations":{"menu":{"fr":"Questionnaires consultation","en":"Consultation questionnaires"}}};
export const MENU_STRUCTURED_DATA = {"id":"menu.structured_data","translations":{"menu":{"fr":"Champs structur\u00e9s","en":"Structured fields"}}};
export const MENU_SITE_SETTINGS = {"id":"menu.site_settings","translations":{"menu":{"fr":"Param\u00e8tres du site","en":"Site settings"}}};
export const MENU_SERVICES = {"id":"menu.services","translations":{"menu":{"fr":"Services","en":"Departments"}}};
export const MENU_SETTINGS = {"id":"menu.settings","translations":{"menu":{"fr":"Param\u00e8tres","en":"Settings"}}};
export const MENU_MENUS_MANAGEMENT = {"id":"menu.menus_management","translations":{"menu":{"fr":"Gestion des menus","en":"Menu management"}}};
export const MENU_IP_ADDRESS = {"id":"menu.ip_address","translations":{"menu":{"fr":"Adresses IP","en":"IP addresses"}}};
export const MENU_MODULE = {"id":"menu.module","translations":{"menu":{"fr":"Modules","en":"Modules"}}};
export const MENU_INSTITUTIONS_MANAGEMENT = {"id":"menu.institutions_management","translations":{"menu":{"fr":"Gestion des institutions","en":"Facility management"}}};
export const MENU_SWITCH_INSTITUTION = {"id":"menu.switch_institution","translations":{"menu":{"fr":"Changer d'institution","en":"Switch facility"}}};
export const MENU_API_DOC = {"id":"menu.api_doc","translations":{"menu":{"fr":"Documentation API","en":"API documentation"}}};
export const MENU_DEBUG = {"id":"menu.debug","translations":{"menu":{"fr":"Debug","en":"Debug"}}};
export const MENU_RECORDS = {"id":"menu.records","translations":{"menu":{"fr":"Dossiers","en":"Records"}}};
export const MENU_MY_ACCOUNT = {"id":"menu.my_account","translations":{"menu":{"fr":"Mon compte","en":"My account"}}};
export const MENU_MY_TEAM = {"id":"menu.my_team","translations":{"menu":{"fr":"Mon \u00e9quipe","en":"My team"}}};
export const MENU_MY_TEMPLATES = {"id":"menu.my_templates","translations":{"menu":{"fr":"Mes mod\u00e8les","en":"My templates"}}};
export const MENU_MY_VARS = {"id":"menu.my_vars","translations":{"menu":{"fr":"Mes variables","en":"My variables"}}};
export const MENU_MY_HEALTH = {"id":"menu.my_health","translations":{"menu":{"fr":"Ma sant\u00e9","en":"My health"}}};
export const MENU_MY_RECORD = {"id":"menu.my_record","translations":{"menu":{"fr":"Mon dossier","en":"My medical record"}}};
export const MENU_MY_DOCS = {"id":"menu.my_docs","translations":{"menu":{"fr":"Mes documents","en":"My documents"}}};
export const MENU_HELP = {"id":"menu.help","translations":{"menu":{"fr":"Aide","en":"Help"}}};
export const MENU_NEW_PATIENT = {"id":"menu.new_patient","translations":{"menu":{"fr":"Nouveau patient","en":"New patient"}}};
export const MENU_TO_MERGE = {"id":"menu.to_merge","translations":{"menu":{"fr":"\u00c0 fusionner","en":"To merge"}}};
export const MENU_INSTAMED = {"id":"menu.instamed","translations":{"menu":{"fr":"Instamed","en":"Instamed"}}};
export const MENU_MS_SANTE = {"id":"menu.ms_sante","translations":{"menu":{"fr":"MSSant\u00e9","en":"MSSante"}}};
export const MENU_DOCS = {"id":"menu.docs","translations":{"menu":{"fr":"Documents","en":"Documents"}}};
export const MENU_FAQ_TIPS = {"id":"menu.faq_tips","translations":{"menu":{"fr":"FAQ & Astuces patients","en":"FAQ & Patient Tips"}}};
export const MENU_FAQ = {"id":"menu.faq","translations":{"menu":{"fr":"FAQ","en":"FAQ"}}};
export const MENU_TIPS = {"id":"menu.tips","translations":{"menu":{"fr":"Astuces Patient","en":"Patient tips"}}};
export const MENU_SUBSCRIPTIONS = {"id":"menu.subscriptions","translations":{"menu":{"fr":"Abonnements","en":"Subscriptions"}}};
export const MENU_STATISTICS = {"id":"menu.statistics","translations":{"menu":{"fr":"Statistiques","en":"Statistics"}}};
export const MENU_INFOS = {"id":"menu.infos","translations":{"menu":{"fr":"Informations","en":"Information"}}};
export const MENU_BANK_DATA = {"id":"menu.bank_data","translations":{"menu":{"fr":"Informations bancaires","en":"Bank information"}}};
export const MENU_UNDERSTAND_TREATMENT = {"id":"menu.understand_treatment","translations":{"menu":{"fr":"Comprendre mon traitement","en":"Understand my treatment"}}};
export const MENU_DISEASES = {"id":"menu.diseases","translations":{"menu":{"fr":"Maladies","en":"Diseases"}}};
export const MENU_FILE_FORM = {"id":"menu.file_form","translations":{"menu":{"fr":"Dossier patient","en":"Patient record"}}};
export const FORM_NEW_PASSWORD = {"id":"form.new_password","translations":{"messages":{"fr":"Nouveau mot de passe","en":"New Password"}}};
export const FORM_NEW_PASSWORD_CONFIRMATION = {"id":"form.new_password_confirmation","translations":{"messages":{"fr":"R\u00e9p\u00e9tez le nouveau mot de passe","en":"Repeat New Password"}}};
export const MESSAGE_PATIENT_ACCESS_FORBIDDEN = {"id":"message.patient.access_forbidden","translations":{"messages":{"fr":"Vous ne pouvez pas acc\u00e9der \u00e0 ce profil patient.","en":"You cannot access this patient's profile."}}};
export const MESSAGE_DISARCHIVED_BY_USER = {"id":"message.disarchived_by_user","translations":{"messages":{"fr":"{{ owner }} a d\u00e9sarchiv\u00e9 la discussion","en":"{{ owner }} has unarchived the conversation"}}};
export const MESSAGE_ARCHIVED_BY_USER = {"id":"message.archived_by_user","translations":{"messages":{"fr":"{{ owner }} a archiv\u00e9 la discussion","en":"{{ owner }} has archived the conversation"}}};
export const MESSAGE_DELETED_BY_USER = {"id":"message.deleted_by_user","translations":{"messages":{"fr":"{{ owner }} a quitt\u00e9 la discussion","en":"{{ owner }} has left the conversation"}}};
export const MESSAGE_PARTICIPANT_REMOVED_BY_USER = {"id":"message.participant_removed_by_user","translations":{"messages":{"fr":"{{ owner }} a retir\u00e9 {{ participant }} de la discussion","en":"{{ owner }} has removed {{ participant }} from the conversation"}}};
export const MESSAGE_PARTICIPANT_ADDED_BY_USER = {"id":"message.participant_added_by_user","translations":{"messages":{"fr":"{{ owner }} a ajout\u00e9 {{ participant }} \u00e0 la discussion","en":"{{ owner }} has added {{ participant }} to the conversation"}}};
export const MESSAGE_ACTIONS_SEE_MESSAGES = {"id":"message.actions.see_messages","translations":{"messages":{"fr":"Voir la messagerie","en":"View Messenger"}}};
export const ADDRESS = {"id":"address","translations":{"messages":{"fr":"Adresse","en":"Address"},"patient_medical_team":{"fr":"Adresse","en":"Address"}}};
export const FEATURE_FLAG_PATIENT_V2 = {"id":"feature_flag.patient_v2","translations":{"messages":{"fr":"Vous \u00eates sur l'ancienne interface du dossier patient qui va bient\u00f4t dispara\u00eetre. Cliquez sur ce bouton pour basculer sur la nouvelle interface.","en":"You are on the old medical record interface, which will soon disappear. Click this button to switch to the new interface."}}};
export const OPERATING_SYSTEM_DESCRIPTION = {"id":"operating_system.description","translations":{"mobile_app_version":{"fr":"Le syst\u00e8me d'op\u00e9ration de la version de l'application mobile","en":"The operating system of mobile app version"}}};
export const OPERATING_SYSTEM_LABEL = {"id":"operating_system.label","translations":{"mobile_app_version":{"fr":"Syst\u00e8me d'op\u00e9ration","en":"Operating system"}}};
export const OPERATING_SYSTEM_PLACEHOLDER = {"id":"operating_system.placeholder","translations":{"mobile_app_version":{"fr":"S\u00e9lectionnez un syst\u00e8me d'op\u00e9ration","en":"Select operating system"}}};
export const OPERATING_SYSTEM_VALUES_IOS = {"id":"operating_system.values.ios","translations":{"mobile_app_version":{"fr":"iOS","en":"iOS"}}};
export const OPERATING_SYSTEM_VALUES_ANDROID = {"id":"operating_system.values.android","translations":{"mobile_app_version":{"fr":"Android","en":"Android"}}};
export const STATUS_VALUES_ACTIVE = {"id":"status.values.active","translations":{"mobile_app_version":{"fr":"Active","en":"Active"}}};
export const STATUS_VALUES_DEPRECATED = {"id":"status.values.deprecated","translations":{"mobile_app_version":{"fr":"D\u00e9pr\u00e9ci\u00e9e","en":"Deprecated"}}};
export const STATUS_VALUES_OUTDATED = {"id":"status.values.outdated","translations":{"mobile_app_version":{"fr":"Outdated","en":"Outdated"}}};
export const MODULES_CORE = {"id":"modules.core","translations":{"modules":{"fr":"Coeur","en":"Core"}}};
export const MODULES_PAYMENT = {"id":"modules.payment","translations":{"modules":{"fr":"Paiement","en":"Payment"}}};
export const MODULES_FAQ = {"id":"modules.faq","translations":{"modules":{"fr":"FAQ","en":"FAQ"}}};
export const MODULES_TIP = {"id":"modules.tip","translations":{"modules":{"fr":"Astuces patient","en":"Patient tips"}}};
export const MODULES_VISIO = {"id":"modules.visio","translations":{"modules":{"fr":"T\u00e9l\u00e9consultation","en":"Teleconsultation"}}};
export const MODULES_MEASURE = {"id":"modules.measure","translations":{"modules":{"fr":"Surveillance","en":"Monitoring"}}};
export const MODULES_SELF_EVALUATION = {"id":"modules.self_evaluation","translations":{"modules":{"fr":"Auto-\u00e9valuation","en":"Patient reported outcome"}}};
export const MODULES_PUBLIC = {"id":"modules.public","translations":{"modules":{"fr":"Acc\u00e8s public","en":"Public access"}}};
export const MODULES_CONSULTATION = {"id":"modules.consultation","translations":{"modules":{"fr":"Consultations","en":"Consultations"}}};
export const NOTIFICATION_CHANNELS_PATIENT_SMS = {"id":"notification_channels.patient-sms","translations":{"notifiable":{"fr":"SMS (Patient)","en":"SMS (patient)"}}};
export const NOTIFICATION_CHANNELS_STAFF_SMS = {"id":"notification_channels.staff-sms","translations":{"notifiable":{"fr":"SMS (Staff)","en":"SMS (staff)"}}};
export const NOTIFICATION_CHANNELS_PATIENT_NOTIFICATION = {"id":"notification_channels.patient-notification","translations":{"notifiable":{"fr":"Notification push (Patient)","en":"Push notification (patient)"}}};
export const NOTIFICATION_CHANNELS_STAFF_NOTIFICATION = {"id":"notification_channels.staff-notification","translations":{"notifiable":{"fr":"Notification push (Staff)","en":"Push notification (staff)"}}};
export const NOTIFICATION_CHANNELS_PATIENT_EMAIL = {"id":"notification_channels.patient-email","translations":{"notifiable":{"fr":"E-mail (Patient)","en":"Email (patient)"}}};
export const NOTIFICATION_CHANNELS_STAFF_EMAIL = {"id":"notification_channels.staff-email","translations":{"notifiable":{"fr":"E-mail (Staff)","en":"Email (staff)"}}};
export const CHANNEL_PATIENT_SMS = {"id":"channel.patient-sms","translations":{"notifiable":{"fr":"SMS (Patient)","en":"SMS (patient)"}}};
export const CHANNEL_STAFF_SMS = {"id":"channel.staff-sms","translations":{"notifiable":{"fr":"SMS (Staff)","en":"SMS (staff)"}}};
export const CHANNEL_PATIENT_NOTIFICATION = {"id":"channel.patient-notification","translations":{"notifiable":{"fr":"Notification push (Patient)","en":"Push notification (patient)"}}};
export const CHANNEL_STAFF_NOTIFICATION = {"id":"channel.staff-notification","translations":{"notifiable":{"fr":"Notification push (Staff)","en":"Push notification (staff)"}}};
export const CHANNEL_PATIENT_EMAIL = {"id":"channel.patient-email","translations":{"notifiable":{"fr":"E-mail (Patient)","en":"Email (patient)"}}};
export const CHANNEL_STAFF_EMAIL = {"id":"channel.staff-email","translations":{"notifiable":{"fr":"E-mail (Staff)","en":"Email (staff)"}}};
export const CATEGORY_DESCRIPTION = {"id":"category.description","translations":{"notification":{"fr":"La cat\u00e9gorie de la notification","en":"Notification category"}}};
export const CATEGORY_LABEL = {"id":"category.label","translations":{"notification":{"fr":"","en":""}}};
export const CATEGORY_PLACEHOLDER = {"id":"category.placeholder","translations":{"notification":{"fr":"","en":""}}};
export const CATEGORY_VALUES_MESSAGE = {"id":"category.values.message","translations":{"notification":{"fr":"Messagerie","en":"Messages"}}};
export const CATEGORY_VALUES_EVENT = {"id":"category.values.event","translations":{"notification":{"fr":"Calendrier","en":"Calendar"}}};
export const CATEGORY_VALUES_MEDICAL_TEAM = {"id":"category.values.medical_team","translations":{"notification":{"fr":"Equipe m\u00e9dicale","en":"Care team"}}};
export const CATEGORY_VALUES_DOCUMENT = {"id":"category.values.document","translations":{"notification":{"fr":"Documents","en":"Documents"}}};
export const CATEGORY_VALUES_SELF_EVALUATION = {"id":"category.values.self_evaluation","translations":{"notification":{"fr":"Auto-\u00e9valuations","en":"Patient Reported Outcome"}}};
export const CATEGORY_VALUES_SHARE_PROFILE = {"id":"category.values.share_profile","translations":{"notification":{"fr":"Partages de dossiers"}}};
export const CATEGORY_VALUES_INVOICE = {"id":"category.values.invoice","translations":{"notification":{"fr":"Facturation","en":"Billing"}}};
export const CATEGORY_VALUES_ALERT = {"id":"category.values.alert","translations":{"notification":{"fr":"Alertes patient","en":"Patient alerts"}}};
export const CATEGORY_VALUES_PATIENT = {"id":"category.values.patient","translations":{"notification":{"fr":"Informations patient","en":"Patient information"}}};
export const NOTIFICATION_CATEGORY_EVENT_LABEL = {"id":"notification.category.event.label","translations":{"notification":{"fr":"Calendrier","en":"Calendar"}}};
export const NOTIFICATION_CATEGORY_EVENT_DESCRIPTION = {"id":"notification.category.event.description","translations":{"notification":{"fr":"Notifications li\u00e9es au calendrier","en":"Notifications related to calendar and appointments"}}};
export const NOTIFICATION_CATEGORY_MESSAGE_LABEL = {"id":"notification.category.message.label","translations":{"notification":{"fr":"Messagerie","en":"Messenger"}}};
export const NOTIFICATION_CATEGORY_MESSAGE_DESCRIPTION = {"id":"notification.category.message.description","translations":{"notification":{"fr":"Notifications li\u00e9es \u00e0 la messagerie","en":"Notifications related to Instamed messenger"}}};
export const NOTIFICATION_CATEGORY_SHARE_PROFILE_LABEL = {"id":"notification.category.share_profile.label","translations":{"notification":{"fr":"Partages de dossiers","en":"Medical record sharing"}}};
export const NOTIFICATION_CATEGORY_SHARE_PROFILE_DESCRIPTION = {"id":"notification.category.share_profile.description","translations":{"notification":{"fr":"Notifications li\u00e9es aux partages de dossiers","en":"Notifications related to medical record sharing"}}};
export const NOTIFICATION_CATEGORY_DOCUMENT_LABEL = {"id":"notification.category.document.label","translations":{"notification":{"fr":"Documents","en":"Documents"}}};
export const NOTIFICATION_CATEGORY_DOCUMENT_DESCRIPTION = {"id":"notification.category.document.description","translations":{"notification":{"fr":"Notifications li\u00e9es aux documents","en":"Notifications related to documents"}}};
export const NOTIFICATION_CATEGORY_SELF_EVALUATION_LABEL = {"id":"notification.category.self_evaluation.label","translations":{"notification":{"fr":"Auto-\u00e9valuations","en":"Patient Reported Outcomes"}}};
export const NOTIFICATION_CATEGORY_SELF_EVALUATION_DESCRIPTION = {"id":"notification.category.self_evaluation.description","translations":{"notification":{"fr":"Notifications li\u00e9es aux auto-\u00e9valuations patients","en":"Notifications related to patient reported outcomes"}}};
export const NOTIFICATION_CATEGORY_ALERT_LABEL = {"id":"notification.category.alert.label","translations":{"notification":{"fr":"Alertes patient","en":"Patient alerts"}}};
export const NOTIFICATION_CATEGORY_ALERT_DESCRIPTION = {"id":"notification.category.alert.description","translations":{"notification":{"fr":"Notifications li\u00e9es aux alertes patient","en":"Notifications related to patient alerts"}}};
export const NOTIFICATION_CATEGORY_PATIENT_LABEL = {"id":"notification.category.patient.label","translations":{"notification":{"fr":"Informations patient","en":"Patient information"}}};
export const NOTIFICATION_CATEGORY_PATIENT_DESCRIPTION = {"id":"notification.category.patient.description","translations":{"notification":{"fr":"Notifications li\u00e9es aux informations patient","en":"Notifications related to patient information"}}};
export const NOTIFICATION_TYPE_EVENT_NEW = {"id":"notification.type.event.new","translations":{"notification":{"fr":"Nouveau rendez-vous","en":"New appointment"}}};
export const NOTIFICATION_TYPE_EVENT_REMINDER = {"id":"notification.type.event.reminder","translations":{"notification":{"fr":"Rappel de rendez-vous","en":"Appointment reminder"}}};
export const NOTIFICATION_TYPE_EVENT_UPDATED = {"id":"notification.type.event.updated","translations":{"notification":{"fr":"Modification de rendez-vous","en":"Appointment modification"}}};
export const NOTIFICATION_TYPE_EVENT_CANCELED = {"id":"notification.type.event.canceled","translations":{"notification":{"fr":"Annulation de rendez-vous","en":"Appointment cancellation"}}};
export const NOTIFICATION_TYPE_DOCUMENT_NEW = {"id":"notification.type.document.new","translations":{"notification":{"fr":"Nouveau document","en":"New document"}}};
export const NOTIFICATION_TYPE_MEDICAL_TEAM = {"id":"notification.type.medical_team","translations":{"notification":{"fr":"Demande d'ajout \u00e0 l'\u00e9quipe soignante","en":"Request to join care team"}}};
export const NOTIFICATION_TYPE_SELF_EVALUATION_NEW = {"id":"notification.type.self_evaluation.new","translations":{"notification":{"fr":"Nouvelle auto-\u00e9valuation","en":"New patient reported outcome"}}};
export const NOTIFICATION_TYPE_SHARE_PROFILE_INVITED = {"id":"notification.type.share_profile.invited","translations":{"notification":{"fr":"Partage de dossier patient","en":"Patient record sharing"}}};
export const NOTIFICATION_TYPE_SHARE_PROFILE_HANDLED = {"id":"notification.type.share_profile.handled","translations":{"notification":{"fr":"Partage de dossier patient trait\u00e9","en":"Patient record sharing completed"}}};
export const NOTIFICATION_TYPE_PATIENT_MERGE_REQUEST = {"id":"notification.type.patient.merge_request","translations":{"notification":{"fr":"Demande d'acc\u00e8s \u00e0 un dossier patient","en":"Request to access patient record"}}};
export const NOTIFICATION_TYPE_ALERT_NEW = {"id":"notification.type.alert.new","translations":{"notification":{"fr":"Alerte patient","en":"Patient alert"}}};
export const NOTIFICATION_TYPE_MESSAGE_NEW = {"id":"notification.type.message.new","translations":{"notification":{"fr":"Nouveau message","en":"New message"}}};
export const NOTIFICATION_TYPE_INVOICE = {"id":"notification.type.invoice","translations":{"notification":{"fr":"Facturation","en":"Billing"}}};
export const DOCTOR_INVITED_BY_PATIENT_TITLE = {"id":"doctor.invited_by_patient.title","translations":{"notification":{"fr":"Nouveau partage patient","en":"New invitation"}}};
export const DOCTOR_INVITED_BY_PATIENT_CONTENT = {"id":"doctor.invited_by_patient.content","translations":{"notification":{"fr":"{{ patient }} vous a invit\u00e9 \u00e0 consulter son dossier m\u00e9dical.","en":"{{ patient }} has invited you to access their medical record."}}};
export const DOCTOR_INVITED_BY_MEDIC_TITLE = {"id":"doctor.invited_by_medic.title","translations":{"notification":{"fr":"Partage de dossier","en":"New record sharing"}}};
export const DOCTOR_INVITED_BY_MEDIC_CONTENT = {"id":"doctor.invited_by_medic.content","translations":{"notification":{"fr":"{{ sharer }} a partag\u00e9 avec vous le dossier m\u00e9dical de {{ patient }}. Vous pouvez maintenant y acc\u00e9der.","en":"{{ sharer }} has shared the medical record of {{ patient }}. You can now access it."}}};
export const DOCTOR_INVITING_DOCTOR_TITLE = {"id":"doctor.inviting_doctor.title","translations":{"notification":{"fr":"Acc\u00e8s autoris\u00e9","en":"Record sharing"}}};
export const DOCTOR_INVITING_DOCTOR_CONTENT = {"id":"doctor.inviting_doctor.content","translations":{"notification":{"fr":"{{ recipient }} peut d\u00e9sormais acc\u00e9der au dossier m\u00e9dical de {{ patient }}.","en":"{{ recipient }} has now access to the medical record of {{ patient }}."}}};
export const DOCTOR_REVOKE_INVITATION_TITLE = {"id":"doctor.revoke_invitation.title","translations":{"notification":{"fr":"Acc\u00e8s r\u00e9voqu\u00e9","en":"Revocation"}}};
export const DOCTOR_REVOKE_INVITATION_CONTENT = {"id":"doctor.revoke_invitation.content","translations":{"notification":{"fr":"Vous n'avez plus acc\u00e8s au dossier m\u00e9dical de {{ patient }}.","en":"You no longer have access to the medical record of {{ patient }}."}}};
export const PATIENT_DOCTOR_INVITED_TITLE = {"id":"patient.doctor_invited.title","translations":{"notification":{"fr":"Partage de votre dossier m\u00e9dical","en":"New invitation"}}};
export const PATIENT_DOCTOR_INVITED_CONTENT = {"id":"patient.doctor_invited.content","translations":{"notification":{"fr":"Votre dossier m\u00e9dical a \u00e9t\u00e9 partag\u00e9 avec {{ recipient }} par {{ sharer }}.","en":"Your medical record has been shared by {{ sharer }} with {{ recipient }}."}}};
export const PATIENT_INVITING_DOCTOR_TITLE = {"id":"patient.inviting_doctor.title","translations":{"notification":{"fr":"Nouveau partage de dossier","en":"New record sharing"}}};
export const PATIENT_INVITING_DOCTOR_CONTENT = {"id":"patient.inviting_doctor.content","translations":{"notification":{"fr":"{{ recipient }} a d\u00e9sormais acc\u00e8s \u00e0 votre dossier m\u00e9dical.","en":"{{ recipient }} has now access to your medical record."}}};
export const PATIENT_REVOKE_INVITATION_TITLE = {"id":"patient.revoke_invitation.title","translations":{"notification":{"fr":"Acc\u00e8s r\u00e9voqu\u00e9","en":"Revocation"}}};
export const PATIENT_REVOKE_INVITATION_CONTENT = {"id":"patient.revoke_invitation.content","translations":{"notification":{"fr":"{{ recipient }} n'a plus acc\u00e8s \u00e0 votre dossier m\u00e9dical.","en":"{{ recipient }} no longer has access to your medical record."}}};
export const PATIENT_SHARED_TO_INSTITUTION_TITLE = {"id":"patient.shared_to_institution.title","translations":{"notification":{"fr":"Partage de votre dossier avec un \u00e9tablissement","en":"New record share"}}};
export const PATIENT_SHARED_TO_INSTITUTION_CONTENT = {"id":"patient.shared_to_institution.content","translations":{"notification":{"fr":"Votre dossier m\u00e9dical a \u00e9t\u00e9 partag\u00e9 avec l'\u00e9tablissement {{ institution }} par {{ sharer }}.","en":"Your medical record has been shared by {{ sharer }} with the following department(s): {{ institution }}."}}};
export const PATIENT_REVOKE_INVITATION_TO_INSTITUTION_TITLE = {"id":"patient.revoke_invitation_to_institution.title","translations":{"notification":{"fr":"R\u00e9vocation du partage","en":"Revocation"}}};
export const PATIENT_REVOKE_INVITATION_TO_INSTITUTION_CONTENT = {"id":"patient.revoke_invitation_to_institution.content","translations":{"notification":{"fr":"Votre dossier m\u00e9dical n'est plus accessible par l'\u00e9tablissement {{ institution }}.","en":"Your medical record has been removed from {{ institution }} facility."}}};
export const PATIENT_IMPORT_TITLE = {"id":"patient.import.title","translations":{"notification":{"fr":"Importation de patients","en":"Patient import"},"patient":{"fr":"Importer des patients","en":"Import patients"}}};
export const PATIENT_IMPORT_CONTENT = {"id":"patient.import.content","translations":{"notification":{"fr":"L'import de vos {{ count }} patients est termin\u00e9.","en":"The import of your {{ count }} patients has been completed."}}};
export const NURSE_MEDICAL_RECORD_SHARED_COMPLETED_BY_MEDIC_TITLE = {"id":"nurse.medical_record_shared_completed_by_medic.title","translations":{"notification":{"fr":"Partage de dossier","en":"New record shared"}}};
export const NURSE_MEDICAL_RECORD_SHARED_COMPLETED_BY_MEDIC_CONTENT = {"id":"nurse.medical_record_shared_completed_by_medic.content","translations":{"notification":{"fr":"{{ sharer }} vous a partag\u00e9 le dossier m\u00e9dical de {{ patient }}. Vous pouvez maintenant y acc\u00e9der.","en":"{{ sharer }} has shared {{ patient }}'s medical record with you. You can now access it."}}};
export const NURSE_MEDICAL_RECORD_SHARED_COMPLETED_BY_PATIENT_TITLE = {"id":"nurse.medical_record_shared_completed_by_patient.title","translations":{"notification":{"fr":"Partage de dossier","en":"New record shared"}}};
export const NURSE_MEDICAL_RECORD_SHARED_COMPLETED_BY_PATIENT_CONTENT = {"id":"nurse.medical_record_shared_completed_by_patient.content","translations":{"notification":{"fr":"{{ sharer }} vous a partag\u00e9 son dossier m\u00e9dical. Vous pouvez maintenant y acc\u00e9der.","en":"{{ sharer }} has shared their medical record with you. You can now access it."}}};
export const NURSE_MEDICAL_RECORD_SHARED_COMPLETED_TO_NURSE_BY_MEDIC_TITLE = {"id":"nurse.medical_record_shared_completed_to_nurse_by_medic.title","translations":{"notification":{"fr":"Partage de dossier","en":"New record share"}}};
export const NURSE_MEDICAL_RECORD_SHARED_COMPLETED_TO_NURSE_BY_MEDIC_CONTENT = {"id":"nurse.medical_record_shared_completed_to_nurse_by_medic.content","translations":{"notification":{"fr":"{{ sharer }} a partag\u00e9 le dossier m\u00e9dical de {{ patient }} avec {{ nurse }}.","en":"{{ sharer }} has shared {{ patient }}'s medical record with {{ nurse }}."}}};
export const NURSE_MEDICAL_RECORD_SHARED_COMPLETED_TO_NURSE_BY_PATIENT_TITLE = {"id":"nurse.medical_record_shared_completed_to_nurse_by_patient.title","translations":{"notification":{"fr":"Partage de dossier","en":"New record share"}}};
export const NURSE_MEDICAL_RECORD_SHARED_COMPLETED_TO_NURSE_BY_PATIENT_CONTENT = {"id":"nurse.medical_record_shared_completed_to_nurse_by_patient.content","translations":{"notification":{"fr":"{{ sharer }} a partag\u00e9 son dossier m\u00e9dical avec {{ nurse }}.","en":"{{ sharer }} has shared their medical record with {{ nurse }}."}}};
export const NURSE_MEDICAL_RECORD_SHARED_COMPLETED_TO_LINKED_MEDIC_BY_PATIENT_TITLE = {"id":"nurse.medical_record_shared_completed_to_linked_medic_by_patient.title","translations":{"notification":{"fr":"Partage de dossier","en":"New record share"}}};
export const NURSE_MEDICAL_RECORD_SHARED_COMPLETED_TO_LINKED_MEDIC_BY_PATIENT_CONTENT = {"id":"nurse.medical_record_shared_completed_to_linked_medic_by_patient.content","translations":{"notification":{"fr":"{{ sharer }} a partag\u00e9 son dossier m\u00e9dical avec {{ medic }}.","en":"{{ sharer }} has shared their medical record with {{ medic }}."}}};
export const NURSE_MEDICAL_RECORD_SHARED_COMPLETED_TO_LINKED_MEDIC_BY_MEDIC_TITLE = {"id":"nurse.medical_record_shared_completed_to_linked_medic_by_medic.title","translations":{"notification":{"fr":"Nouveau partage de dossier","en":"New record share"}}};
export const NURSE_MEDICAL_RECORD_SHARED_COMPLETED_TO_LINKED_MEDIC_BY_MEDIC_CONTENT = {"id":"nurse.medical_record_shared_completed_to_linked_medic_by_medic.content","translations":{"notification":{"fr":"{{ sharer }} a partag\u00e9 le dossier m\u00e9dical de {{ patient }} avec {{ medic }}.","en":"{{ sharer }} has shared {{ patient }}'s medical record with {{ medic }}."}}};
export const MESSAGE_NEW_TITLE = {"id":"message.new.title","translations":{"notification":{"fr":"Nouveau message","en":"New message"}}};
export const MESSAGE_NEW_CONTENT = {"id":"message.new.content","translations":{"notification":{"fr":"Vous avez re\u00e7u un message de {{ user }}.","en":"You have received a new message from {{ user }}."}}};
export const MESSAGE_NEW_MS_SANTE_TITLE = {"id":"message.new_ms_sante.title","translations":{"notification":{"fr":"Nouveaux e-mails re\u00e7us","en":"New emails received"}}};
export const MESSAGE_NEW_MS_SANTE_CONTENT = {"id":"message.new_ms_sante.content","translations":{"notification":{"fr":"Vous avez re\u00e7u {{ count }} emails sur MSSante.","en":"You have received {{ count }} emails on MsSante."}}};
export const TEST_TITLE = {"id":"test.title","translations":{"notification":{"fr":"Notification de test","en":"Test notification"}}};
export const TEST_CONTENT = {"id":"test.content","translations":{"notification":{"fr":"Une notification de test a \u00e9t\u00e9 envoy\u00e9e \u00e0 {{ user }}.","en":"A test notification has been sent to {{ user }}."}}};
export const DOCUMENT_NEW_TITLE = {"id":"document.new.title","translations":{"notification":{"fr":"Nouveau document pour {{ user }}","en":"New document for {{ user }}"}}};
export const DOCUMENT_NEW_CONTENT = {"id":"document.new.content","translations":{"notification":{"fr":"Un nouveau document \"{{ document }}\" est disponible pour le patient {{ user }}.","en":"A new document \"{{ document }}\" is available for {{ user }} (patient)."}}};
export const DOCUMENT_NEW_BULK_TITLE = {"id":"document.new_bulk.title","translations":{"notification":{"fr":"Nouveaux documents pour {{ user }}","en":"New documents for {{ user }}"}}};
export const DOCUMENT_NEW_BULK_CONTENT = {"id":"document.new_bulk.content","translations":{"notification":{"fr":"De nouveaux documents sont disponibles pour le patient {{ user }}.","en":"New documents are available for {{ user }} (patient)."}}};
export const DOCUMENT_NEW_FOR_PATIENT_TITLE = {"id":"document.new_for_patient.title","translations":{"notification":{"fr":"Nouveau document disponible","en":"New document available"}}};
export const DOCUMENT_NEW_FOR_PATIENT_CONTENT = {"id":"document.new_for_patient.content","translations":{"notification":{"fr":"Un nouveau document \"{{ document }}\" a \u00e9t\u00e9 ajout\u00e9 \u00e0 votre dossier.","en":"A new document \"{{ document }}\" is available in your medical record."}}};
export const DOCUMENT_NEW_BULK_FOR_PATIENT_TITLE = {"id":"document.new_bulk_for_patient.title","translations":{"notification":{"fr":"Nouveaux documents disponibles","en":"You have new documents"}}};
export const DOCUMENT_NEW_BULK_FOR_PATIENT_CONTENT = {"id":"document.new_bulk_for_patient.content","translations":{"notification":{"fr":"De nouveaux documents ont \u00e9t\u00e9 ajout\u00e9s \u00e0 votre dossier m\u00e9dical.","en":"New documents are available in your medical record."}}};
export const DOCUMENT_PARSE_WITH_IA_TITLE = {"id":"document.parse_with_ia.title","translations":{"notification":{"fr":"Documents class\u00e9s avec succ\u00e8s","en":"Your documents have been successfully sorted"}}};
export const DOCUMENT_PARSE_WITH_IA_CONTENT = {"id":"document.parse_with_ia.content","translations":{"notification":{"fr":"Vos documents pour {{ patient }} ont \u00e9t\u00e9 ajout\u00e9s \u00e0 son dossier m\u00e9dical par Lia.","en":"Your documents for {{ patient }} have been successfully added to their medical record by Lia."}}};
export const DOCUMENT_PARSE_WITH_IA_MISSING_TITLE = {"id":"document.parse_with_ia_missing.title","translations":{"notification":{"fr":"Classement incomplet","en":"Some documents could not be sorted"}}};
export const DOCUMENT_PARSE_WITH_IA_MISSING_CONTENT = {"id":"document.parse_with_ia_missing.content","translations":{"notification":{"fr":"Le classement des documents de {{ patient }} est incomplet. Certains documents ont n\u00e9anmoins \u00e9t\u00e9 ajout\u00e9s \u00e0 son dossier.","en":"We have sorted some of {{ patient }}'s documents with Lia, but others could not be categorized, yet they have been added to the concerned patient's medical record."}}};
export const EVENT_NEW_KIND = {"id":"event.new.kind","translations":{"notification":{"fr":"Nouveau RDV","en":"New appointment"}}};
export const EVENT_NEW_TITLE = {"id":"event.new.title","translations":{"notification":{"fr":"Nouveau RDV le {{ date }}","en":"New appointment on {{ date }}"}}};
export const EVENT_NEW_CONTENT = {"id":"event.new.content","translations":{"notification":{"fr":"Vous avez un rendez-vous pr\u00e9vu le {{ date }} \u00e0 {{ hour }}.","en":"You have a new appointment scheduled for {{ date }} at {{ hour }}."}}};
export const EVENT_NEW_RECURRING_TITLE = {"id":"event.new_recurring.title","translations":{"notification":{"fr":"Plusieurs nouveaux rendez-vous","en":"Multiple new appointments"}}};
export const EVENT_NEW_RECURRING_CONTENT = {"id":"event.new_recurring.content","translations":{"notification":{"fr":"Vous avez plusieurs rendez-vous programm\u00e9s. Le premier est pr\u00e9vu le {{ date }} \u00e0 {{ hour }}.","en":"You have several upcoming appointments. The first one is scheduled for {{ date }} at {{ hour }}."}}};
export const EVENT_UPDATED_KIND = {"id":"event.updated.kind","translations":{"notification":{"fr":"Modification de RDV","en":"Appointment rescheduled"}}};
export const EVENT_UPDATED_TITLE = {"id":"event.updated.title","translations":{"notification":{"fr":"Votre rendez-vous du {{ oldDate }} a \u00e9t\u00e9 reprogramm\u00e9","en":"Your appointment on {{ oldDate }} has been rescheduled"}}};
export const EVENT_UPDATED_CONTENT = {"id":"event.updated.content","translations":{"notification":{"fr":"Votre rendez-vous pr\u00e9vu le {{ oldDate }} \u00e0 {{ oldHour }} a \u00e9t\u00e9 d\u00e9plac\u00e9 au {{ date }} \u00e0 {{ hour }}.","en":"Your appointment on {{ oldDate }} at {{ oldHour }} has been rescheduled to {{ date }} at {{ hour }}."}}};
export const EVENT_UPDATED_RECURRING_TITLE = {"id":"event.updated_recurring.title","translations":{"notification":{"fr":"Rendez-vous reprogramm\u00e9s","en":"Multiple appointments rescheduled"}}};
export const EVENT_UPDATED_RECURRING_CONTENT = {"id":"event.updated_recurring.content","translations":{"notification":{"fr":"Certains de vos rendez-vous ont \u00e9t\u00e9 reprogramm\u00e9s. Le premier est pass\u00e9 du {{ oldDate }} \u00e0 {{ oldHour }} au {{ date }} \u00e0 {{ hour }}.","en":"Several of your appointments have been rescheduled. The first one has been moved from {{ oldDate }} at {{ oldHour }} to {{ date }} at {{ hour }}."}}};
export const EVENT_CANCELED_KIND = {"id":"event.canceled.kind","translations":{"notification":{"fr":"Annulation de RDV","en":"Appointment cancellation"}}};
export const EVENT_CANCELED_TITLE = {"id":"event.canceled.title","translations":{"notification":{"fr":"Votre RDV du {{ date }} a \u00e9t\u00e9 annul\u00e9","en":"Appointment on {{ date }} has been canceled"}}};
export const EVENT_CANCELED_CONTENT = {"id":"event.canceled.content","translations":{"notification":{"fr":"Votre rendez-vous du {{ date }} \u00e0 {{ hour }} a \u00e9t\u00e9 annul\u00e9.","en":"Your appointment on {{ date }} at {{ hour }} has been canceled."}}};
export const EVENT_CANCELED_UNEXCUSED_KIND = {"id":"event.canceled_unexcused.kind","translations":{"notification":{"fr":"Annulation de RDV non justifi\u00e9e","en":"Unjustified cancellation"}}};
export const EVENT_CANCELED_UNEXCUSED_TITLE = {"id":"event.canceled_unexcused.title","translations":{"notification":{"fr":"Annulation de RDV non justifi\u00e9e","en":"Appointment on {{ date }} has been canceled"}}};
export const EVENT_CANCELED_UNEXCUSED_CONTENT = {"id":"event.canceled_unexcused.content","translations":{"notification":{"fr":"Votre rendez-vous du {{ date }} \u00e0 {{ hour }} a \u00e9t\u00e9 annul\u00e9. Nous comprenons que des impr\u00e9vus peuvent survenir, il est n\u00e9anmoins recommand\u00e9 de pr\u00e9venir votre m\u00e9decin au moins 24 heures \u00e0 l'avance si vous ne pouvez pas y assister.","en":"Your appointment on {{ date }} at {{ hour }} has been canceled. We understand that unexpected situations may arise, but please notify your doctor at least 24 hours in advance if you cannot attend."}}};
export const EVENT_CANCELED_RECURRING_TITLE = {"id":"event.canceled_recurring.title","translations":{"notification":{"fr":"Plusieurs RDVs annul\u00e9s","en":"Several appointments canceled"}}};
export const EVENT_CANCELED_RECURRING_CONTENT = {"id":"event.canceled_recurring.content","translations":{"notification":{"fr":"Certains de vos rendez-vous ont \u00e9t\u00e9 annul\u00e9s.","en":"Several of your upcoming appointments have been canceled."}}};
export const EVENT_REMINDER_TITLE = {"id":"event.reminder.title","translations":{"notification":{"fr":"Vous avez une consultation aujourd'hui.","en":"You have a consultation today."}}};
export const EVENT_REMINDER_CONTENT = {"id":"event.reminder.content","translations":{"notification":{"fr":"N'oubliez pas votre consultation aujourd\u2019hui \u00e0 {{ hour }}.","en":"Don't forget your consultation scheduled for today at {{ hour }}."}}};
export const EVENT_REMINDER_V2_TITLE = {"id":"event.reminder_v2.title","translations":{"notification":{"fr":"Votre prochain rendez-vous \u00e0 venir","en":"Upcoming appointment"}}};
export const EVENT_REMINDER_V2_CONTENT = {"id":"event.reminder_v2.content","translations":{"notification":{"fr":"Rappel: votre rendez-vous est pr\u00e9vu le {{ date }} \u00e0 {{ hour }} avec {{ fullName }}.","en":"Don't forget your appointment on {{ date }} at {{ hour }}."}}};
export const EVENT_REMINDER_V2_TODAY_TITLE = {"id":"event.reminder_v2_today.title","translations":{"notification":{"fr":"Votre rendez-vous aujourd'hui","en":"Appointment today"}}};
export const EVENT_REMINDER_V2_TODAY_CONTENT = {"id":"event.reminder_v2_today.content","translations":{"notification":{"fr":"N\u2019oubliez pas votre rendez-vous aujourd\u2019hui \u00e0 {{ hour }}.","en":"Don't forget your appointment today at {{ hour }}."}}};
export const EVENT_REMINDER_V2_TOMORROW_TITLE = {"id":"event.reminder_v2_tomorrow.title","translations":{"notification":{"fr":"Votre rendez-vous demain","en":"Appointment tomorrow"}}};
export const EVENT_REMINDER_V2_TOMORROW_CONTENT = {"id":"event.reminder_v2_tomorrow.content","translations":{"notification":{"fr":"Rappel : votre rendez-vous est pr\u00e9vu demain \u00e0 {{ hour }} avec {{ fullName }}.","en":"Don't forget your appointment tomorrow at {{ hour }}."}}};
export const EVENT_BOOKING_NEW_KIND = {"id":"event.booking_new.kind","translations":{"notification":{"fr":"Nouveau RDV","en":"New appointment"}}};
export const EVENT_BOOKING_NEW_TITLE = {"id":"event.booking_new.title","translations":{"notification":{"fr":"Rendez-vous confirm\u00e9 le {{ date }}","en":"Appointment confirmed for {{ date }}"}}};
export const EVENT_BOOKING_NEW_CONTENT = {"id":"event.booking_new.content","translations":{"notification":{"fr":"Votre rendez-vous avec {{ fullName }} est confirm\u00e9 pour le {{ date }} \u00e0 {{ hour }}.","en":"Your appointment with {{ fullName }} is confirmed for {{ date }} at {{ hour }}."}}};
export const EVENT_BOOKING_UPDATE_TITLE = {"id":"event.booking_update.title","translations":{"notification":{"fr":"Rendez-vous reprogramm\u00e9","en":"Appointment rescheduled"}}};
export const EVENT_BOOKING_UPDATE_CONTENT = {"id":"event.booking_update.content","translations":{"notification":{"fr":"Votre rendez-vous avec {{ fullName }} initialement pr\u00e9vu le {{ previous_date }} a \u00e9t\u00e9 reprogramm\u00e9 au {{ new_date }} \u00e0 {{ new_hour }}.","en":"Your appointment with {{ fullName }}, originally scheduled for {{ previous_date }}, has been rescheduled to {{ new_date }} at {{ new_hour }}."}}};
export const EVENT_BOOKING_CANCEL_TITLE = {"id":"event.booking_cancel.title","translations":{"notification":{"fr":"Rendez-vous annul\u00e9","en":"Appointment canceled"}}};
export const EVENT_BOOKING_CANCEL_CONTENT = {"id":"event.booking_cancel.content","translations":{"notification":{"fr":"Votre rendez-vous du {{ date }} avec {{ fullName }} a \u00e9t\u00e9 annul\u00e9. N'h\u00e9sitez pas \u00e0 r\u00e9server un nouveau cr\u00e9neau.","en":"Your appointment on {{ date }} with {{ fullName }} has been canceled. Feel free to book a new time slot that works for you."}}};
export const SELF_EVALUATION_NEW_TITLE = {"id":"self_evaluation.new.title","translations":{"notification":{"fr":"Nouvelle auto-\u00e9valuation disponible","en":"New questionnaire"}}};
export const SELF_EVALUATION_NEW_CONTENT = {"id":"self_evaluation.new.content","translations":{"notification":{"fr":"Un nouveau questionnaire d\u2019auto-\u00e9valuation est disponible. Vous pouvez le compl\u00e9ter d\u00e8s maintenant.","en":"A new questionnaire is available."}}};
export const SELF_EVALUATION_NEW_REPLIED_BY_PATIENT_TITLE = {"id":"self_evaluation.new_replied_by_patient.title","translations":{"notification":{"fr":"Questionnaire compl\u00e9t\u00e9","en":"Completed questionnaire"}}};
export const SELF_EVALUATION_NEW_REPLIED_BY_PATIENT_CONTENT = {"id":"self_evaluation.new_replied_by_patient.content","translations":{"notification":{"fr":"Le questionnaire \"{{ questionnaire }}\" a \u00e9t\u00e9 compl\u00e9t\u00e9 par {{ patient }} le {{ date }}.","en":"The questionnaire \"{{ questionnaire }}\" was completed by {{ patient }} on {{ date }}."}}};
export const SELF_EVALUATION_ADD_TITLE = {"id":"self_evaluation.add.title","translations":{"notification":{"fr":"Compl\u00e9tez votre questionnaire","en":"Answer your questionnaire"}}};
export const SELF_EVALUATION_ADD_CONTENT = {"id":"self_evaluation.add.content","translations":{"notification":{"fr":"Un nouveau questionnaire est en attente d\u2019\u00eatre compl\u00e9t\u00e9.","en":"A new questionnaire is awaiting to be completed."}}};
export const SELF_EVALUATION_REMINDER_TITLE = {"id":"self_evaluation.reminder.title","translations":{"notification":{"fr":"Rappel: questionnaire en attente","en":"Complete your questionnaire"}}};
export const SELF_EVALUATION_REMINDER_CONTENT = {"id":"self_evaluation.reminder.content","translations":{"notification":{"fr":"Votre questionnaire est en attente d'\u00eatre compl\u00e9t\u00e9.","en":"Your questionnaire is still pending completion. Please complete it as soon as possible."}}};
export const MERGE_REQUEST_NEW_TITLE = {"id":"merge_request.new.title","translations":{"notification":{"fr":"Demande d'acc\u00e8s \u00e0 un dossier","en":"Request to access a patient's record"}}};
export const MERGE_REQUEST_NEW_CONTENT = {"id":"merge_request.new.content","translations":{"notification":{"fr":"{{ owner }} souhaite consulter le dossier de {{ patient }}.","en":"{{ owner }} has requested access to {{ patient }}'s medical record."}}};
export const MERGE_REQUEST_CANCEL_TITLE = {"id":"merge_request.cancel.title","translations":{"notification":{"fr":"Acc\u00e8s refus\u00e9 au dossier de {{ patient }}","en":"Access request denied for {{ patient }}'s medical record"}}};
export const MERGE_REQUEST_CANCEL_CONTENT = {"id":"merge_request.cancel.content","translations":{"notification":{"fr":"{{ user }} a refus\u00e9 votre demande d\u2019acc\u00e8s au dossier de {{ patient }}.","en":"{{ user }} has denied your request to access {{ patient }}'s medical record."}}};
export const MERGE_REQUEST_DONE_TITLE = {"id":"merge_request.done.title","translations":{"notification":{"fr":"Acc\u00e8s accord\u00e9 au dossier de {{ patient }}","en":"Access request approved for {{ patient }}'s medical record"}}};
export const MERGE_REQUEST_DONE_CONTENT = {"id":"merge_request.done.content","translations":{"notification":{"fr":"L'acc\u00e8s au dossier de {{ patient }} est accord\u00e9.","en":"You now have access to {{ patient }}'s medical record."}}};
export const LINK_NURSE_TO_MEDIC_NEW_TITLE = {"id":"link_nurse_to_medic.new.title","translations":{"notification":{"fr":"Demande d\u2019ajout \u00e0 votre \u00e9quipe soignante","en":"Request to join your care team"}}};
export const LINK_NURSE_TO_MEDIC_NEW_CONTENT = {"id":"link_nurse_to_medic.new.content","translations":{"notification":{"fr":"{{ owner }} souhaite vous ajouter \u00e0 son \u00e9quipe soignante.","en":"{{ owner }} has requested to add you to their care team."}}};
export const LINK_NURSE_TO_MEDIC_DONE_TITLE = {"id":"link_nurse_to_medic.done.title","translations":{"notification":{"fr":"Demande d'ajout \u00e0 votre \u00e9quipe accept\u00e9e","en":"Request approved: you have joined the care team"}}};
export const LINK_NURSE_TO_MEDIC_DONE_CONTENT = {"id":"link_nurse_to_medic.done.content","translations":{"notification":{"fr":"{{ user }} a accept\u00e9 votre demande d\u2019ajout \u00e0 son \u00e9quipe soignante.","en":"{{ user }} has approved your request to join their care team."}}};
export const LINK_NURSE_TO_MEDIC_CANCEL_TITLE = {"id":"link_nurse_to_medic.cancel.title","translations":{"notification":{"fr":"Demande d\u2019ajout \u00e0 votre \u00e9quipe refus\u00e9e","en":"Request denied: addition to care team"}}};
export const LINK_NURSE_TO_MEDIC_CANCEL_CONTENT = {"id":"link_nurse_to_medic.cancel.content","translations":{"notification":{"fr":"{{ user }} a refus\u00e9 votre demande d\u2019ajout \u00e0 son \u00e9quipe soignante.","en":"{{ user }} has denied your request to join their care team."}}};
export const LINK_NURSE_TO_MEDIC_REVERT_TITLE = {"id":"link_nurse_to_medic.revert.title","translations":{"notification":{"fr":"Retrait de l'\u00e9quipe soignante","en":"Removed from care team"}}};
export const LINK_NURSE_TO_MEDIC_REVERT_CONTENT = {"id":"link_nurse_to_medic.revert.content","translations":{"notification":{"fr":"{{ user }} vous a retir\u00e9 de son \u00e9quipe soignante.","en":"{{ user }} has removed you from their care team."}}};
export const LINK_NURSE_TO_NURSE_NEW_TITLE = {"id":"link_nurse_to_nurse.new.title","translations":{"notification":{"fr":"Demande d\u2019ajout \u00e0 votre \u00e9quipe soignante","en":"Request to join your care team"}}};
export const LINK_NURSE_TO_NURSE_NEW_CONTENT = {"id":"link_nurse_to_nurse.new.content","translations":{"notification":{"fr":"{{ owner }} souhaite vous ajouter \u00e0 son \u00e9quipe soignante.","en":"{{ owner }} has requested to add you to their care team."}}};
export const LINK_NURSE_TO_NURSE_DONE_TITLE = {"id":"link_nurse_to_nurse.done.title","translations":{"notification":{"fr":"Demande d\u2019ajout \u00e0 votre \u00e9quipe accept\u00e9e","en":"Request approved: you have joined the care team"}}};
export const LINK_NURSE_TO_NURSE_DONE_CONTENT = {"id":"link_nurse_to_nurse.done.content","translations":{"notification":{"fr":"{{ user }} a accept\u00e9 votre demande d\u2019ajout \u00e0 son \u00e9quipe soignante.","en":"{{ user }} has approved your request to join their care team."}}};
export const LINK_NURSE_TO_NURSE_CANCEL_TITLE = {"id":"link_nurse_to_nurse.cancel.title","translations":{"notification":{"fr":"Demande d\u2019ajout \u00e0 votre \u00e9quipe refus\u00e9e","en":"Request denied: addition to care team"}}};
export const LINK_NURSE_TO_NURSE_CANCEL_CONTENT = {"id":"link_nurse_to_nurse.cancel.content","translations":{"notification":{"fr":"{{ user }} a refus\u00e9 votre demande d\u2019ajout \u00e0 son \u00e9quipe soignante.","en":"{{ user }} has denied your request to join their care team."}}};
export const LINK_NURSE_TO_NURSE_REVERT_TITLE = {"id":"link_nurse_to_nurse.revert.title","translations":{"notification":{"fr":"Retrait de l'\u00e9quipe soignante","en":"Removed from {{ user }}'s care team"}}};
export const LINK_NURSE_TO_NURSE_REVERT_CONTENT = {"id":"link_nurse_to_nurse.revert.content","translations":{"notification":{"fr":"{{ user }} vous a retir\u00e9 de son \u00e9quipe soignante.","en":"{{ user }} has removed you from their care team."}}};
export const LINK_MEDIC_TO_MEDIC_NEW_TITLE = {"id":"link_medic_to_medic.new.title","translations":{"notification":{"fr":"Demande d\u2019ajout \u00e0 votre \u00e9quipe soignante","en":"Request to join your medical team"}}};
export const LINK_MEDIC_TO_MEDIC_NEW_CONTENT = {"id":"link_medic_to_medic.new.content","translations":{"notification":{"fr":"{{ owner }} souhaite vous ajouter \u00e0 son \u00e9quipe soignante.","en":"{{ owner }} requested to add you to their care team."}}};
export const LINK_MEDIC_TO_MEDIC_DONE_TITLE = {"id":"link_medic_to_medic.done.title","translations":{"notification":{"fr":"Demande d\u2019ajout \u00e0 votre \u00e9quipe accept\u00e9e","en":"Request approved: you have joined the care team"}}};
export const LINK_MEDIC_TO_MEDIC_DONE_CONTENT = {"id":"link_medic_to_medic.done.content","translations":{"notification":{"fr":"{{ user }} a accept\u00e9 votre demande d\u2019ajout \u00e0 son \u00e9quipe soignante.","en":"{{ user }} has approved your request to join their care team."}}};
export const LINK_MEDIC_TO_MEDIC_CANCEL_TITLE = {"id":"link_medic_to_medic.cancel.title","translations":{"notification":{"fr":"Demande d\u2019ajout \u00e0 votre \u00e9quipe refus\u00e9e","en":"Request denied: addition to medical team"}}};
export const LINK_MEDIC_TO_MEDIC_CANCEL_CONTENT = {"id":"link_medic_to_medic.cancel.content","translations":{"notification":{"fr":"{{ user }} a refus\u00e9 votre demande d\u2019ajout \u00e0 son \u00e9quipe soignante.","en":"{{ user }} has denied your request to join their care team."}}};
export const LINK_MEDIC_TO_MEDIC_REVERT_TITLE = {"id":"link_medic_to_medic.revert.title","translations":{"notification":{"fr":"Retrait de l'\u00e9quipe soignante","en":"Removed from {{ user }}'s care team"}}};
export const LINK_MEDIC_TO_MEDIC_REVERT_CONTENT = {"id":"link_medic_to_medic.revert.content","translations":{"notification":{"fr":"{{ user }} vous a retir\u00e9 de son \u00e9quipe soignante.","en":"{{ user }} has removed you from their care team."}}};
export const ALERT_NEW_TITLE = {"id":"alert.new.title","translations":{"notification":{"fr":"Nouvelle alerte pour un patient","en":"Alert for a patient"}}};
export const ALERT_NEW_CONTENT = {"id":"alert.new.content","translations":{"notification":{"fr":"Alerte {{ priority }} pour le patient {{ patient }}.","en":"Alert: {{ priority }} priority for {{ patient }}."}}};
export const ALERT_NEW_FOR_PATIENT_TITLE = {"id":"alert.new_for_patient.title","translations":{"notification":{"fr":"Nouvelle alerte urgente","en":"Urgent alert"}}};
export const ALERT_NEW_FOR_PATIENT_CONTENT = {"id":"alert.new_for_patient.content","translations":{"notification":{"fr":"L'auto-\u00e9valuation indique certains \u00e9l\u00e9ments \u00e0 surveiller. Veuillez en informer l'\u00e9quipe soignante.","en":"Some of the data from your latest questionnaire requires attention. We recommend reaching out to your medical team and sharing how you feel for better guidance."}}};
export const INVOICE_PAYMENT_FAILED_TITLE = {"id":"invoice.payment_failed.title","translations":{"notification":{"fr":"\u00c9chec du paiement de votre abonnement","en":"Subscription payment failed"}}};
export const INVOICE_PAYMENT_FAILED_CONTENT = {"id":"invoice.payment_failed.content","translations":{"notification":{"fr":"Veuillez mettre \u00e0 jour vos informations de paiement dans la rubrique abonnement pour \u00e9viter toute interruption de service.","en":"Please update your payment details in the subscription section of your profile to resolve the issue."}}};
export const CARD_EXPIRING_TITLE = {"id":"card.expiring.title","translations":{"notification":{"fr":"Moyen de paiement bient\u00f4t expir\u00e9","en":"Payment method expiring soon"}}};
export const CARD_EXPIRING_CONTENT = {"id":"card.expiring.content","translations":{"notification":{"fr":"Votre moyen de paiement arrive \u00e0 expiration. Merci de le mettre \u00e0 jour afin d'\u00e9viter toute interruption de service.","en":"Your payment method is about to expire. Please update it to avoid service interruption."}}};
export const MEDICAL_DOCUMENT_NEW_TITLE = {"id":"medical_document.new.title","translations":{"notification":{"fr":"Nouveau document partag\u00e9 pour {{ user }}","en":"New document for {{ user }}"}}};
export const MEDICAL_DOCUMENT_NEW_CONTENT = {"id":"medical_document.new.content","translations":{"notification":{"fr":"{{ owner }} vous a partag\u00e9 le document \"{{ document }}\" pour le patient {{ user }}.","en":"{{ owner }} has sent you the document \"{{ document }}\" for {{ user }} (patient)."}}};
export const SHARE_PROFILE_ACKNOWLEDGED_TITLE = {"id":"share_profile.acknowledged.title","translations":{"notification":{"fr":"Consultation de dossier"}}};
export const SHARE_PROFILE_ACKNOWLEDGED_CONTENT = {"id":"share_profile.acknowledged.content","translations":{"notification":{"fr":"{{ recipient }} a consult\u00e9 le dossier m\u00e9dical de {{ patient }}."}}};
export const SHARE_PROFILE_HANDLED_TITLE = {"id":"share_profile.handled.title","translations":{"notification":{"fr":"Traitement de partage"}}};
export const SHARE_PROFILE_HANDLED_CONTENT = {"id":"share_profile.handled.content","translations":{"notification":{"fr":"{{ recipient }} a trait\u00e9 le dossier m\u00e9dical de {{ patient }} que vous avez partag\u00e9."}}};
export const PRO_SANTE_CONNECT_IMPERSONATED_CONNEXION_TITLE = {"id":"pro_sante_connect.impersonated_connexion.title","translations":{"notification":{"fr":"Demande de reconnexion \u00e0 Pro Sant\u00e9 Connect","en":"Request to reconnect to Pro Sante Connect"}}};
export const PRO_SANTE_CONNECT_IMPERSONATED_CONNEXION_CONTENT = {"id":"pro_sante_connect.impersonated_connexion.content","translations":{"notification":{"fr":"{{ user }} souhaite que vous vous reconnectiez \u00e0 Pro Sant\u00e9 Connect pour effectuer des actions en votre nom.","en":"{{ user }} is requesting that you reconnect to Pro Sant\u00e9 Connect to perform actions on your behalf."}}};
export const PRO_SANTE_CONNECT_CONNECTED_TITLE = {"id":"pro_sante_connect.connected.title","translations":{"notification":{"fr":"Connexion r\u00e9ussie \u00e0 Pro Sant\u00e9 Connect","en":"Successfully connected to Pro Sante Connect"}}};
export const PRO_SANTE_CONNECT_CONNECTED_CONTENT = {"id":"pro_sante_connect.connected.content","translations":{"notification":{"fr":"Votre compte a \u00e9t\u00e9 connect\u00e9 avec succ\u00e8s \u00e0 Pro Sant\u00e9 Connect.","en":"Your account has been successfully connected to Pro Sante Connect."}}};
export const PRO_SANTE_CONNECT_ERROR_TITLE = {"id":"pro_sante_connect.error.title","translations":{"notification":{"fr":"Erreur de connexion \u00e0 Pro Sant\u00e9 Connect","en":"Pro Sante Connect connection error"}}};
export const PRO_SANTE_CONNECT_ERROR_CONTENT = {"id":"pro_sante_connect.error.content","translations":{"notification":{"fr":"Une erreur est survenue lors de la tentative de connexion \u00e0 Pro Sant\u00e9 Connect.","en":"An error occurred while trying to connect to Pro Sante Connect."}}};
export const SEX_DESCRIPTION = {"id":"sex.description","translations":{"patient+intl-icu":{"fr":"Le sexe biologique du patient","en":"The biological gender of patient"}}};
export const SEX_LABEL = {"id":"sex.label","translations":{"patient+intl-icu":{"fr":"Sexe","en":"Gender"}}};
export const SEX_PLACEHOLDER = {"id":"sex.placeholder","translations":{"patient+intl-icu":{"fr":"S\u00e9lectionnez un sexe","en":"Select gender"}}};
export const SEX1 = {"id":"sex.1","translations":{"patient+intl-icu":{"fr":"Masculin","en":"Male"},"profile":{"fr":"Masculin","en":"Male"}}};
export const SEX2 = {"id":"sex.2","translations":{"patient+intl-icu":{"fr":"F\u00e9minin","en":"Female"},"profile":{"fr":"F\u00e9minin","en":"Female"}}};
export const SEX3 = {"id":"sex.3","translations":{"patient+intl-icu":{"fr":"Autre","en":"Other"}}};
export const BLOOD_TYPE_DESCRIPTION = {"id":"blood_type.description","translations":{"patient+intl-icu":{"fr":"Le groupe sanguin de l'utilisateur","en":"User's blood type"}}};
export const BLOOD_TYPE_LABEL = {"id":"blood_type.label","translations":{"patient+intl-icu":{"fr":"Groupe sanguin","en":"Blood type"}}};
export const BLOOD_TYPE_PLACEHOLDER = {"id":"blood_type.placeholder","translations":{"patient+intl-icu":{"fr":"S\u00e9lectionnez un groupe sanguin","en":"Select blood type"}}};
export const STATUS4 = {"id":"status.4","translations":{"patient+intl-icu":{"fr":"\u00c0 Surveiller","en":"To monitor"},"profile":{"fr":"\u00c0 Surveiller","en":"To monitor"},"task":{"fr":"Annul\u00e9","en":"Cancelled"}}};
export const STATUS5 = {"id":"status.5","translations":{"patient+intl-icu":{"fr":"Invalide","en":"Invalid"},"profile":{"fr":"Invalide","en":"Invalid"},"task":{"fr":"Doublon","en":"Duplicate"}}};
export const STATUS6 = {"id":"status.6","translations":{"patient+intl-icu":{"fr":"Incomplet","en":"Incomplete"},"profile":{"fr":"Incomplet","en":"Incomplete"},"task":{"fr":"Vu","en":"Seen"}}};
export const STATUS9 = {"id":"status.9","translations":{"patient+intl-icu":{"fr":"\u00c0 r\u00e9viser","en":"To review"},"profile":{"fr":"En salle d'attente","en":"In waiting room"}}};
export const STATUS32 = {"id":"status.32","translations":{"patient+intl-icu":{"fr":"Invit\u00e9 - En attente de finalisation du profil","en":"Guest - awaiting profile completion"},"profile":{"fr":"Invit\u00e9 - En attente de finalisation du profil","en":"Guest - awaiting profile completion"}}};
export const DRINKING_FREQUENCY_DESCRIPTION = {"id":"drinking_frequency.description","translations":{"patient+intl-icu":{"fr":"La fr\u00e9quence \u00e0 laquelle l'utilisateur boit de l'alcool","en":"Frequency at which user drinks alcohol"}}};
export const DRINKING_FREQUENCY_LABEL = {"id":"drinking_frequency.label","translations":{"patient+intl-icu":{"fr":"Buvez-vous de l'alcool ?","en":"Do you drink alcohol?"}}};
export const DRINKING_FREQUENCY_PLACEHOLDER = {"id":"drinking_frequency.placeholder","translations":{"patient+intl-icu":{"fr":"Choisissez une r\u00e9ponse","en":"Select answer"}}};
export const FAMILY_SITUATION_DESCRIPTION = {"id":"family_situation.description","translations":{"patient+intl-icu":{"fr":"La situation familiale du patient","en":"Patient's family situation"}}};
export const FAMILY_SITUATION_LABEL = {"id":"family_situation.label","translations":{"patient+intl-icu":{"fr":"Situation Familiale","en":"Family situation"}}};
export const FAMILY_SITUATION_PLACEHOLDER = {"id":"family_situation.placeholder","translations":{"patient+intl-icu":{"fr":"S\u00e9lectionnez la situation familiale","en":"Select family situation"}}};
export const FAMILY_SITUATION1 = {"id":"family_situation.1","translations":{"patient+intl-icu":{"fr":"C\u00e9libataire","en":"Single"}}};
export const FAMILY_SITUATION2 = {"id":"family_situation.2","translations":{"patient+intl-icu":{"fr":"En concubinage","en":"In cohabitation"}}};
export const FAMILY_SITUATION3 = {"id":"family_situation.3","translations":{"patient+intl-icu":{"fr":"{gender, select, female   {Mari\u00e9e} male     {Mari\u00e9} other    {Mari\u00e9(e)} }","en":"Married"}}};
export const FAMILY_SITUATION4 = {"id":"family_situation.4","translations":{"patient+intl-icu":{"fr":"{gender, select, female   {S\u00e9par\u00e9e} male     {S\u00e9par\u00e9} other    {S\u00e9par\u00e9(e)} }","en":"Separated"}}};
export const FAMILY_SITUATION5 = {"id":"family_situation.5","translations":{"patient+intl-icu":{"fr":"{gender, select, female   {Divorc\u00e9e} male     {Divorc\u00e9} other    {Divorc\u00e9(e)} }","en":"Divorced"}}};
export const FAMILY_SITUATION6 = {"id":"family_situation.6","translations":{"patient+intl-icu":{"fr":"{gender, select, female   {Veuve} male     {Veuf} other    {Veuf\/Veuve} }","en":"{gender, select,  female   {Widow}  male     {Widower}  other    {Widow\/Widower}  }"}}};
export const FAMILY_SITUATION7 = {"id":"family_situation.7","translations":{"patient+intl-icu":{"fr":"Inconnu","en":"Unknown"}}};
export const HAIR_COLOR_DESCRIPTION = {"id":"hair_color.description","translations":{"patient+intl-icu":{"fr":"La couleur des cheveux du patient","en":"The patient's hair color"}}};
export const HAIR_COLOR_LABEL = {"id":"hair_color.label","translations":{"patient+intl-icu":{"fr":"Couleur des cheveux","en":"Hair color"}}};
export const HAIR_COLOR_PLACEHOLDER = {"id":"hair_color.placeholder","translations":{"patient+intl-icu":{"fr":"S\u00e9lectionnez la couleur des cheveux","en":"Select hair color"}}};
export const HAIR_COLOR1 = {"id":"hair_color.1","translations":{"patient+intl-icu":{"fr":"Noir","en":"Black"}}};
export const HAIR_COLOR2 = {"id":"hair_color.2","translations":{"patient+intl-icu":{"fr":"Brun","en":"Brown"}}};
export const HAIR_COLOR3 = {"id":"hair_color.3","translations":{"patient+intl-icu":{"fr":"Roux","en":"Redhead"}}};
export const HAIR_COLOR4 = {"id":"hair_color.4","translations":{"patient+intl-icu":{"fr":"Auburn","en":"Auburn"}}};
export const HAIR_COLOR5 = {"id":"hair_color.5","translations":{"patient+intl-icu":{"fr":"Blond","en":"Blond"}}};
export const HAIR_COLOR6 = {"id":"hair_color.6","translations":{"patient+intl-icu":{"fr":"Ch\u00e2tain","en":"Chestnut"}}};
export const HAIR_COLOR7 = {"id":"hair_color.7","translations":{"patient+intl-icu":{"fr":"Blond v\u00e9nitien","en":"Strawberry blonde"}}};
export const COVER_TYPE_DESCRIPTION = {"id":"cover_type.description","translations":{"patient+intl-icu":{"fr":"Type de couverture m\u00e9dicale","en":"Type of medical cover"}}};
export const COVER_TYPE_LABEL = {"id":"cover_type.label","translations":{"patient+intl-icu":{"fr":"Couverture m\u00e9dicale","en":"Medical cover"}}};
export const COVER_TYPE_PLACEHOLDER = {"id":"cover_type.placeholder","translations":{"patient+intl-icu":{"fr":"S\u00e9lectionnez une couverture m\u00e9dicale","en":"Select medical cover"}}};
export const COVER_TYPE_VALUES_RG = {"id":"cover_type.values.RG","translations":{"patient+intl-icu":{"fr":"RG","en":"RG"}}};
export const COVER_TYPE_VALUES_MSA = {"id":"cover_type.values.MSA","translations":{"patient+intl-icu":{"fr":"MSA","en":"MSA"}}};
export const COVER_TYPE_VALUES_C = {"id":"cover_type.values.C","translations":{"patient+intl-icu":{"fr":"C","en":"C"}}};
export const COVER_TYPE_VALUES_CSS = {"id":"cover_type.values.CSS","translations":{"patient+intl-icu":{"fr":"CSS","en":"CSS"}}};
export const COVER_TYPE_VALUES_PUMA = {"id":"cover_type.values.PUMA","translations":{"patient+intl-icu":{"fr":"PUMA","en":"PUMA"}}};
export const COVER_TYPE_VALUES_NAS = {"id":"cover_type.values.NAS","translations":{"patient+intl-icu":{"fr":"Non assur\u00e9(e) social(e)","en":"Not medically insured"}}};
export const COVER_TYPE_VALUES_AME = {"id":"cover_type.values.AME","translations":{"patient+intl-icu":{"fr":"AME","en":"AME"}}};
export const COVER_TYPE_VALUES_RSI = {"id":"cover_type.values.RSI","translations":{"patient+intl-icu":{"fr":"RSI","en":"RSI"}}};
export const EYES_COLOR_DESCRIPTION = {"id":"eyes_color.description","translations":{"patient+intl-icu":{"fr":"La couleur des yeux du patient","en":"The patient's eye color"}}};
export const EYES_COLOR_LABEL = {"id":"eyes_color.label","translations":{"patient+intl-icu":{"fr":"Couleur des yeux","en":"Eye color"}}};
export const EYES_COLOR_PLACEHOLDER = {"id":"eyes_color.placeholder","translations":{"patient+intl-icu":{"fr":"S\u00e9lectionnez la couleur des yeux","en":"Select eye color"}}};
export const EYES_COLOR1 = {"id":"eyes_color.1","translations":{"patient+intl-icu":{"fr":"Noir","en":"Black"}}};
export const EYES_COLOR2 = {"id":"eyes_color.2","translations":{"patient+intl-icu":{"fr":"Brun","en":"Brown"}}};
export const EYES_COLOR3 = {"id":"eyes_color.3","translations":{"patient+intl-icu":{"fr":"Bleu","en":"Blue"}}};
export const EYES_COLOR4 = {"id":"eyes_color.4","translations":{"patient+intl-icu":{"fr":"Vert","en":"Green"}}};
export const EYES_COLOR5 = {"id":"eyes_color.5","translations":{"patient+intl-icu":{"fr":"Vairon","en":"Heterochromia"}}};
export const LATERALITY_DESCRIPTION = {"id":"laterality.description","translations":{"patient+intl-icu":{"fr":"La lat\u00e9ralit\u00e9 du patient","en":"The patient's laterality"}}};
export const LATERALITY_LABEL = {"id":"laterality.label","translations":{"patient+intl-icu":{"fr":"Lat\u00e9ralit\u00e9","en":"Laterality"}}};
export const LATERALITY_PLACEHOLDER = {"id":"laterality.placeholder","translations":{"patient+intl-icu":{"fr":"S\u00e9lectionnez la lat\u00e9ralit\u00e9 du patient","en":"Select patient's laterality"}}};
export const LATERALITY1 = {"id":"laterality.1","translations":{"patient+intl-icu":{"fr":"{gender, select, female   {Droiti\u00e8re} male     {Droitier} other    {Droitier(\u00e8re)} }","en":"Right-handed"}}};
export const LATERALITY2 = {"id":"laterality.2","translations":{"patient+intl-icu":{"fr":"{gender, select, female   {Gauch\u00e8re} male     {Gaucher} other    {Gaucher(\u00e8re)} }","en":"Left-handed"}}};
export const LATERALITY3 = {"id":"laterality.3","translations":{"patient+intl-icu":{"fr":"Ambidextre","en":"Ambidextrous"}}};
export const FREQUENCY0 = {"id":"frequency.0","translations":{"patient+intl-icu":{"fr":"Jamais","en":"Never"},"profile":{"fr":"Jamais","en":"Never"}}};
export const FREQUENCY1 = {"id":"frequency.1","translations":{"patient+intl-icu":{"fr":"\u00c0 l'occasion","en":"Occasionally"},"profile":{"fr":"\u00c0 l'occasion","en":"Occasionally"}}};
export const FREQUENCY2 = {"id":"frequency.2","translations":{"patient+intl-icu":{"fr":"Fr\u00e9quemment","en":"Frequently"},"profile":{"fr":"Fr\u00e9quemment","en":"Frequently"}}};
export const FREQUENCY3 = {"id":"frequency.3","translations":{"patient+intl-icu":{"fr":"Tous les jours","en":"Every day"},"profile":{"fr":"Tous les jours","en":"Every Day"}}};
export const INS_STATUS_DESCRIPTION = {"id":"ins_status.description","translations":{"patient+intl-icu":{"fr":"Le statut INS du patient","en":"The INS status of the patient"}}};
export const INS_STATUS_LABEL = {"id":"ins_status.label","translations":{"patient+intl-icu":{"fr":"Statut INS","en":"INS Status"}}};
export const INS_STATUS_PLACEHOLDER = {"id":"ins_status.placeholder","translations":{"patient+intl-icu":{"fr":"S\u00e9lectionnez un statut INS","en":"Select an INS status"}}};
export const INS_STATUS_IDENTITY_DOUBTFUL = {"id":"ins_status.identity_doubtful","translations":{"patient+intl-icu":{"fr":"Identit\u00e9 douteuse","en":"Doubtful identity"}}};
export const INS_STATUS_IDENTITY_FICTITIOUS = {"id":"ins_status.identity_fictitious","translations":{"patient+intl-icu":{"fr":"Identit\u00e9 fictive","en":"Fictitious identity"}}};
export const INS_STATUS_IDENTITY_PROVISIONAL = {"id":"ins_status.identity_provisional","translations":{"patient+intl-icu":{"fr":"Identit\u00e9 provisoire","en":"Provisional identity"}}};
export const INS_STATUS_IDENTITY_QUALIFIED = {"id":"ins_status.identity_qualified","translations":{"patient+intl-icu":{"fr":"Identit\u00e9 qualifi\u00e9e","en":"Qualified identity"}}};
export const INS_STATUS_IDENTITY_RETRIEVED = {"id":"ins_status.identity_retrieved","translations":{"patient+intl-icu":{"fr":"Identit\u00e9 r\u00e9cup\u00e9r\u00e9e","en":"Recovered identity"}}};
export const INS_STATUS_IDENTITY_VALIDATED = {"id":"ins_status.identity_validated","translations":{"patient+intl-icu":{"fr":"Identit\u00e9 valid\u00e9e","en":"Validated identity"}}};
export const PATIENT_MEDICAL_BACKGROUND = {"id":"patient.medical_background","translations":{"patient":{"fr":"Ant\u00e9c\u00e9dents & allergies","en":"Medical history & allergies"}}};
export const PATIENT_BACKGROUND = {"id":"patient.background","translations":{"patient":{"fr":"Ant\u00e9c\u00e9dents","en":"Medical history"}}};
export const PATIENT_PATIENT_BACKGROUND = {"id":"patient.patient_background","translations":{"patient":{"fr":"Ant\u00e9c\u00e9dents du patient","en":"Patient's medical history"}}};
export const PATIENT_MEDICAL_BACKGROUND_AND_COMORBIDITIES = {"id":"patient.medical_background_and_comorbidities","translations":{"patient":{"fr":"Ant\u00e9c\u00e9dents et comorbidit\u00e9s","en":"Medical History and comorbidities"}}};
export const PATIENT_DISEASE_HISTORY = {"id":"patient.disease_history","translations":{"patient":{"fr":"Histoire de la maladie","en":"Medical history"}}};
export const PATIENT_MEDICATION_NAME = {"id":"patient.medication_name","translations":{"patient":{"fr":"Nom du m\u00e9dicament","en":"Medication name"}}};
export const PATIENT_POSOLOGY = {"id":"patient.posology","translations":{"patient":{"fr":"Posologie","en":"Dosage"}}};
export const PATIENT_MALE = {"id":"patient.male","translations":{"patient":{"fr":"Masculin","en":"Male"}}};
export const PATIENT_FEMALE = {"id":"patient.female","translations":{"patient":{"fr":"F\u00e9minin","en":"Female"}}};
export const PATIENT_HOSPITAL_VISIT = {"id":"patient.hospital_visit","translations":{"patient":{"fr":"Ma venue \u00e0 l\u2019h\u00f4pital","en":"My Visit to the hospital"}}};
export const PATIENT_MY_ASSOCIATIONS = {"id":"patient.my_associations","translations":{"patient":{"fr":"Mes associations","en":"My associations"}}};
export const PATIENT_REPORT = {"id":"patient.report","translations":{"patient":{"fr":"Dossier patient","en":"Patient record"}}};
export const PATIENT_CONSULTATION = {"id":"patient.consultation","translations":{"patient":{"fr":"Consultation","en":"Consultation"}}};
export const PATIENT_DONT_EXIST = {"id":"patient.dont_exist","translations":{"patient":{"fr":"Le patient d'id $id n'existe pas.","en":"The patient with ID $id does not exist."}}};
export const PATIENT_NOT_FOUND = {"id":"patient.not_found","translations":{"patient":{"fr":"Ce patient n'existe pas","en":"This patient does not exist"}}};
export const PATIENT_CORRESPONDING_DOCTORS = {"id":"patient.corresponding_doctors","translations":{"patient":{"fr":"M\u00e9decins correspondants - Liste","en":"Corresponding Doctors - List"}}};
export const PATIENT_ANNEXES_IMAGING = {"id":"patient.annexes_imaging","translations":{"patient":{"fr":"Annexes - Imagerie","en":"Annexes - Imaging"}}};
export const PATIENT_LIFESTYLE = {"id":"patient.lifestyle","translations":{"patient":{"fr":"Mode de vie","en":"Lifestyle"}}};
export const PATIENT_TAKEN_CARE_OF = {"id":"patient.taken_care_of","translations":{"patient":{"fr":"\u00c0 charge","en":"Taken care of"}}};
export const PATIENT_HISTORY = {"id":"patient.history","translations":{"patient":{"fr":"Ant\u00e9c\u00e9dents","en":"History"}}};
export const PATIENT_NEW_HISTORY = {"id":"patient.new_history","translations":{"patient":{"fr":"Nouvel ant\u00e9c\u00e9dent","en":"New medical history"}}};
export const PATIENT_HISTORY_TYPE = {"id":"patient.history_type","translations":{"patient":{"fr":"Type d'ant\u00e9c\u00e9dent","en":"Type of history"}}};
export const PATIENT_HISTORY_SAVED = {"id":"patient.history_saved","translations":{"patient":{"fr":"L'ant\u00e9c\u00e9dent a bien \u00e9t\u00e9 enregistr\u00e9","en":"The history has been successfully saved"}}};
export const PATIENT_NO_HISTORY = {"id":"patient.no_history","translations":{"patient":{"fr":"Aucun ant\u00e9c\u00e9dent renseign\u00e9","en":"No medical history provided"}}};
export const PATIENT_SHARE_YOU = {"id":"patient.share_you","translations":{"patient":{"fr":"<strong>$owner<\/strong> vous a partag\u00e9 ce dossier","en":"shared this record with you"}}};
export const PATIENT_SHARE_TO_SUBJECT = {"id":"patient.share_to_subject","translations":{"patient":{"fr":"<strong>$owner<\/strong> a partag\u00e9 ce dossier \u00e0 <strong>$subject<\/strong>","en":"<strong>$owner<\/strong> shared this record to <strong>$subject<\/strong>"}}};
export const PATIENT_DOCTOR_SELECTION = {"id":"patient.doctor_selection","translations":{"patient":{"fr":"S\u00e9lection du m\u00e9decin $name en cours","en":"Currently selecting doctor $name"}}};
export const PATIENT_DOCTOR_LIST_LOADING = {"id":"patient.doctor_list_loading","translations":{"patient":{"fr":"Chargement de la liste de vos m\u00e9decins","en":"Loading your doctors' list"}}};
export const PATIENT_CREATED_FOR = {"id":"patient.created_for","translations":{"patient":{"fr":"Le patient sera cr\u00e9\u00e9 pour $name","en":"The patient will be created for $name"}}};
export const PATIENT_TELETRANSMISSION_OFF = {"id":"patient.teletransmission_off","translations":{"patient":{"fr":"La t\u00e9l\u00e9transmission n'est pas encore activ\u00e9e","en":"Teletransmission is not yet activated"}}};
export const PATIENT_PERSONAL_BACKGROUND = {"id":"patient.personal_background","translations":{"patient":{"fr":"Personnel","en":"Personal"}}};
export const PATIENT_FAMILIAL_BACKGROUND = {"id":"patient.familial_background","translations":{"patient":{"fr":"Familial","en":"Family-related"}}};
export const PATIENT_RIGHT_HANDED = {"id":"patient.right_handed","translations":{"patient":{"fr":"Droitier(\u00e8re)","en":"Right-handed"}}};
export const PATIENT_LEFT_HANDED = {"id":"patient.left_handed","translations":{"patient":{"fr":"Gaucher(\u00e8re)","en":"Left-handed"}}};
export const PATIENT_AMBIDEXTROUS = {"id":"patient.ambidextrous","translations":{"patient":{"fr":"Ambidextrous","en":"Ambidextrous"}}};
export const PATIENT_CITY_DOCTOR = {"id":"patient.city_doctor","translations":{"patient":{"fr":"M\u00e9decin de ville","en":"General practitioner"}}};
export const PATIENT_STATUS_ACTIVE = {"id":"patient.status.active","translations":{"patient":{"fr":"Actif","en":"Active"}}};
export const PATIENT_STATUS_ARCHIVED = {"id":"patient.status.archived","translations":{"patient":{"fr":"Archiv\u00e9","en":"Archived"}}};
export const PATIENT_STATUS_DELETED = {"id":"patient.status.deleted","translations":{"patient":{"fr":"Supprim\u00e9","en":"Deleted"}}};
export const PATIENT_STATUS_TO_WATCH = {"id":"patient.status.to_watch","translations":{"patient":{"fr":"\u00c0 Surveiller","en":"To monitor"}}};
export const PATIENT_STATUS_INVALID = {"id":"patient.status.invalid","translations":{"patient":{"fr":"Invalide","en":"Invalid"}}};
export const PATIENT_STATUS_INCOMPLETE = {"id":"patient.status.incomplete","translations":{"patient":{"fr":"Incomplet","en":"Incomplete"}}};
export const PATIENT_STATUS_UNDESIRABLE = {"id":"patient.status.undesirable","translations":{"patient":{"fr":"Ind\u00e9sirable","en":"Undesirable"}}};
export const PATIENT_STATUS_DECEASED = {"id":"patient.status.deceased","translations":{"patient":{"fr":"D\u00e9c\u00e9d\u00e9","en":"Deceased"}}};
export const PATIENT_STATUS_TO_REVIEW = {"id":"patient.status.to_review","translations":{"patient":{"fr":"\u00c0 r\u00e9viser","en":"To review"}}};
export const PATIENT_ACTIONS_FILL_WITH_PATIENT = {"id":"patient.actions.fill_with_patient","translations":{"patient":{"fr":"Ajouter le patient en copie","en":"Add patient to recipients"}}};
export const PATIENT_ACTIONS_FILL_WITH_MEDICAL_TEAM = {"id":"patient.actions.fill_with_medical_team","translations":{"patient":{"fr":"Remplir avec l\u2019\u00e9quipe soignante du patient","en":"Fill with patient's care team"}}};
export const PATIENT_ACTIONS_MERGE_PATIENT = {"id":"patient.actions.merge_patient","translations":{"patient":{"fr":"Fusionner deux patients","en":"Merge two patients"}}};
export const PATIENT_ACTIONS_ADD_MEDICATION = {"id":"patient.actions.add_medication","translations":{"patient":{"fr":"Ajouter un m\u00e9dicament","en":"Add medication"}}};
export const PATIENT_ACTIONS_ADD_HISTORY = {"id":"patient.actions.add_history","translations":{"patient":{"fr":"Ajouter un ant\u00e9c\u00e9dent","en":"Add medical history"}}};
export const PATIENT_ACTIONS_ADD_DOCTOR = {"id":"patient.actions.add_doctor","translations":{"patient":{"fr":"Ajouter un m\u00e9decin","en":"Add doctor"}}};
export const PATIENT_ACTIONS_ADD_PARAMEDICAL = {"id":"patient.actions.add_paramedical","translations":{"patient":{"fr":"Ajouter un param\u00e9dical","en":"Add paramedic"}}};
export const PATIENT_ACTIONS_ADD_PARAMEDIC = {"id":"patient.actions.add_paramedic","translations":{"patient":{"fr":"Ajouter le param\u00e9dical","en":"Add paramedic"}}};
export const PATIENT_ACTIONS_SELECT_POSOLOGY = {"id":"patient.actions.select_posology","translations":{"patient":{"fr":"S\u00e9lectionnez la posologie","en":"Select dosage"}}};
export const PATIENT_ACTIONS_SHARE_RECORD = {"id":"patient.actions.share_record","translations":{"patient":{"fr":"Partager le dossier de $patient","en":"Share $patient's record"}}};
export const PATIENT_ACTIONS_SHARE_YOUR_RECORD = {"id":"patient.actions.share_your_record","translations":{"patient":{"fr":"Partager votre dossier","en":"Share your record"}}};
export const PATIENT_ACTIONS_SELECT = {"id":"patient.actions.select","translations":{"patient":{"fr":"Choisissez un patient","en":"Select patient"}}};
export const PATIENT_ACTIONS_SELECT_DOCTOR = {"id":"patient.actions.select_doctor","translations":{"patient":{"fr":"Choisissez un m\u00e9decin","en":"Select doctor"}}};
export const PATIENT_ACTIONS_EDIT_HISTORY = {"id":"patient.actions.edit_history","translations":{"patient":{"fr":"Modificatier de l'ant\u00e9c\u00e9dent","en":"Edit history"}}};
export const PATIENT_ACTIONS_SHOW_IN_DOCS = {"id":"patient.actions.show_in_docs","translations":{"patient":{"fr":"Afficher dans les documents via les variables ant\u00e9c\u00e9dents","en":"View in documents medical history variables"}}};
export const PATIENT_ACTIONS_INFORM_REASON = {"id":"patient.actions.inform_reason","translations":{"patient":{"fr":" pour le motif suivant : <strong>$reason<\/strong>","en":" for the following reason : <strong>$reason<\/strong>"}}};
export const PATIENT_ACTIONS_INFORM = {"id":"patient.actions.inform","translations":{"patient":{"fr":"Informez-le que vous avez trait\u00e9 sa demande en cliquant sur ce bouton","en":"Inform them that you addressed their request by clicking this button"}}};
export const PATIENT_ACTIONS_INFORM_NOTE = {"id":"patient.actions.inform_note","translations":{"patient":{"fr":"<strong>Note de partage<\/strong> : $note","en":"<strong>Share note<\/strong> : $note"}}};
export const PATIENT_ACTIONS_MAKE_APPOINTMENT = {"id":"patient.actions.make_appointment","translations":{"patient":{"fr":"Prendre rendez-vous","en":"Book appointment"}}};
export const PATIENT_ACTIONS_DEFINE_AS_REFERRING = {"id":"patient.actions.define_as_referring","translations":{"patient":{"fr":"`D\u00e9finir comme r\u00e9f\u00e9rent ( $name )","en":"Set as primary doctor ($name)"}}};
export const PATIENT_ACTIONS_SELECT_SERVICE = {"id":"patient.actions.select_service","translations":{"patient":{"fr":"Choisissez le service","en":"Select service"}}};
export const PATIENT_ACTIONS_SELECT_INSTITUTION = {"id":"patient.actions.select_institution","translations":{"patient":{"fr":"Choisissez la ou les institutions","en":"Select facilities"}}};
export const PATIENT_ACTIONS_ACTIVATE_ENTITY = {"id":"patient.actions.activate_entity","translations":{"patient":{"fr":"Activer l'entit\u00e9","en":"Activate entity"}}};
export const PATIENT_TREATMENTS_TITLE = {"id":"patient.treatments.title","translations":{"patient":{"fr":"Maladies & Traitements","en":"Diseases & treatments"}}};
export const PATIENT_TREATMENTS_EDIT = {"id":"patient.treatments.edit","translations":{"patient":{"fr":"Modifier un traitement","en":"Edit treatment"}}};
export const PATIENT_TREATMENTS_EDITED = {"id":"patient.treatments.edited","translations":{"patient":{"fr":"Le traitement a bien \u00e9t\u00e9 modifi\u00e9","en":"The treatment has been successfully modified"}}};
export const PATIENT_TREATMENTS_ADDED = {"id":"patient.treatments.added","translations":{"patient":{"fr":"Le traitement a bien \u00e9t\u00e9 ajout\u00e9","en":"The treatment has been successfully added"}}};
export const PATIENT_TREATMENTS_CREATE_EDIT = {"id":"patient.treatments.create_edit","translations":{"patient":{"fr":"Cr\u00e9er ou modifier un traitement","en":"Create or edit a treatment"}}};
export const PATIENT_TREATMENTS_INFORMATIONS = {"id":"patient.treatments.informations","translations":{"patient":{"fr":"Informations du traitement","en":"Treatment information"}}};
export const PATIENT_TREATMENTS_UNDERSTAND = {"id":"patient.treatments.understand","translations":{"patient":{"fr":"Comprendre mon traitement","en":"Understand my treatment"}}};
export const PATIENT_TREATMENTS_RECURRENCE = {"id":"patient.treatments.recurrence","translations":{"patient":{"fr":"D\u00e9livr\u00e9 $count fois,","en":"Delivered $count times,"}}};
export const PATIENT_TREATMENTS_EVERYDAY = {"id":"patient.treatments.everyday","translations":{"patient":{"fr":"tous les jours","en":"every day"}}};
export const PATIENT_TREATMENTS_EVERY_X_DAYS = {"id":"patient.treatments.every_x_days","translations":{"patient":{"fr":"tous les $frequency jours","en":"every $frequency days"}}};
export const PATIENT_TREATMENTS_NOT_FILLED = {"id":"patient.treatments.not_filled","translations":{"patient":{"fr":"Aucun traitement actif renseign\u00e9","en":"No active treatments provided"}}};
export const PATIENT_HELPER_ADD_TO_MEDICAL_TEAM = {"id":"patient.helper.add_to_medical_team","translations":{"patient":{"fr":"Ajouter \u00e0 l'\u00e9quipe soignante","en":"Add to care team"}}};
export const PATIENT_HELPER_DISPLAY_MODE = {"id":"patient.helper.display_mode","translations":{"patient":{"fr":"Vous pouvez d\u00e9finir le type d'affichage dans ","en":"You can define the display type in"}}};
export const PATIENT_HELPER_EMAIL_SUBTITLE = {"id":"patient.helper.email_subtitle","translations":{"patient":{"fr":"Si le champ est laiss\u00e9 vide, un identifiant Instamed sera g\u00e9n\u00e9r\u00e9 pour permettre au patient de se connecter","en":"If left blank, an Instamed ID will be generated to allow the patient to log in"}}};
export const PATIENT_HELPER_ACCOUNT_PARAMETER = {"id":"patient.helper.account_parameter","translations":{"patient":{"fr":"vos param\u00e8tres de compte","en":"your account settings"}}};
export const PATIENT_HELPER_MERGE_PATIENT = {"id":"patient.helper.merge_patient","translations":{"patient":{"fr":"N'oubliez pas de v\u00e9rifier qu'il ne s'agit pas d'homonymes. En cliquant sur le bouton \u00ab Valider \u00bb, les deux dossiers seront fusionn\u00e9s.","en":"Ensure there are no duplicates. By clicking the 'Validate' button, the two records will be merged."}}};
export const PATIENT_HELPER_PATIENT_NOT_IN_SERVICE = {"id":"patient.helper.patient_not_in_service","translations":{"patient":{"fr":"Ce patient ne fait pas partie de votre service.","en":"This patient is not part of your service."}}};
export const PATIENT_HELPER_PATIENT_REFUSE_ACCOUNT = {"id":"patient.helper.patient_refuse_account","translations":{"patient":{"fr":"Ce patient a exprim\u00e9 son souhait de ne pas avoir de compte sur Instamed. Il ne recevra donc aucune notification ni aucun e-mail de notre part. Veuillez penser \u00e0 le contacter directement.","en":"This patient has expressed their wish not to have an Instamed account. They will not receive any notifications or emails from us. Please contact them directly."}}};
export const PATIENT_HELPER_NO_APPOINTMENTS = {"id":"patient.helper.no_appointments","translations":{"patient":{"fr":"Aucun RDV trouv\u00e9","en":"No appointments found"}}};
export const PATIENT_HELPER_NO_DATA = {"id":"patient.helper.no_data","translations":{"patient":{"fr":"Aucune donn\u00e9e renseign\u00e9e","en":"No data provided"}}};
export const PATIENT_HELPER_NO_HISTORY = {"id":"patient.helper.no_history","translations":{"patient":{"fr":"Aucun ant\u00e9c\u00e9dents ni allergies","en":"No medical history or allergies"}}};
export const PATIENT_HELPER_NO_DISEASE = {"id":"patient.helper.no_disease","translations":{"patient":{"fr":"Aucune maladie ni traitement en cours","en":"No current disease or treatment"}}};
export const PATIENT_HELPER_CONFIRM_DELETE_HISTORY = {"id":"patient.helper.confirm_delete_history","translations":{"patient":{"fr":"\u00cates-vous s\u00fbr de vouloir supprimer cet ant\u00e9c\u00e9dent","en":"Are you sure you want to delete this history?"}}};
export const PATIENT_HELPER_HISTORY_DELETED = {"id":"patient.helper.history_deleted","translations":{"patient":{"fr":"L'ant\u00e9c\u00e9dent a bien \u00e9t\u00e9 supprim\u00e9","en":"The history has been successfully deleted"}}};
export const PATIENT_HELPER_DUPLICATE = {"id":"patient.helper.duplicate","translations":{"patient":{"fr":"Il semblerait que ce patient ait un doublon. V\u00e9rifiez qu\u2019il ne s\u2019agit pas d\u2019un homonyme en ","en":"It seems this patient has a duplicate. Verify it's not a namesake by"}}};
export const PATIENT_HELPER_VIEWING = {"id":"patient.helper.viewing","translations":{"patient":{"fr":"visualisant le profil.","en":"viewing their profile."}}};
export const PATIENT_HELPER_PATIENT_DIFFERENT = {"id":"patient.helper.patient_different","translations":{"patient":{"fr":"Les patients sont diff\u00e9rents","en":"The patients are different"}}};
export const PATIENT_HELPER_MERGED = {"id":"patient.helper.merged","translations":{"patient":{"fr":"Les deux utilisateurs ont bien \u00e9t\u00e9 fusionn\u00e9s.","en":"The two users have been successfully merged."}}};
export const PATIENT_HELPER_KEPT = {"id":"patient.helper.kept","translations":{"patient":{"fr":"Les deux dossiers ont bien \u00e9t\u00e9 conserv\u00e9s.","en":"Both records have been successfully retained."}}};
export const PATIENT_HELPER_LOCK = {"id":"patient.helper.lock","translations":{"patient":{"fr":"Cette action verrouillera le partage de votre dossier. Vos m\u00e9decins correspondants ne pourront plus y acc\u00e9der. Cela affectera la fluidit\u00e9 de la communication sur votre \u00e9tat de sant\u00e9 aupr\u00e8s de votre \u00e9quipe soignante.","en":"This action will lock the sharing of your record. Corresponding doctors will no longer have access. It will impact communication on your health with your care team."}}};
export const PATIENT_HELPER_UNLOCK = {"id":"patient.helper.unlock","translations":{"patient":{"fr":"Cette action d\u00e9verrouillera le partage de votre dossier. Vos m\u00e9decins correspondants pourront y avoir acc\u00e8s si invit\u00e9s. Cela facilitera la communication sur votre \u00e9tat de sant\u00e9 aupr\u00e8s de votre \u00e9quipe soignante.","en":"This action will unlock sharing your record. Corresponding doctors will have access if invited, facilitating communication about your health with your care team."}}};
export const PATIENT_HELPER_LOCKED = {"id":"patient.helper.locked","translations":{"patient":{"fr":"Votre profil a bien \u00e9t\u00e9 v\u00e9rouill\u00e9","en":"Your profile has been successfully locked"}}};
export const PATIENT_HELPER_UNLOCKED = {"id":"patient.helper.unlocked","translations":{"patient":{"fr":"Votre profil a bien \u00e9t\u00e9 d\u00e9v\u00e9rouill\u00e9","en":"Your profile has been successfully unlocked"}}};
export const PATIENT_HELPER_ARCHIVED = {"id":"patient.helper.archived","translations":{"patient":{"fr":"Le patient a bien \u00e9t\u00e9 archiv\u00e9","en":"Patient has been successfully archived"}}};
export const PATIENT_HELPER_UNARCHIVED = {"id":"patient.helper.unarchived","translations":{"patient":{"fr":"Le patient a bien \u00e9t\u00e9 d\u00e9sarchiv\u00e9","en":"Patient has been successfully unarchived"}}};
export const PATIENT_HELPER_NOT_DECEASED = {"id":"patient.helper.not_deceased","translations":{"patient":{"fr":"Vous allez d\u00e9clarer le patient comme non d\u00e9c\u00e9d\u00e9, il n'appara\u00eetra plus dans les patients archiv\u00e9s","en":"You are declaring the patient as not deceased; they will no longer appear in archived patients."}}};
export const PATIENT_HELPER_UPDATED = {"id":"patient.helper.updated","translations":{"patient":{"fr":"Le patient a bien \u00e9t\u00e9 modifi\u00e9","en":"Patient has been successfully updated"}}};
export const PATIENT_HELPER_TREATED = {"id":"patient.helper.treated","translations":{"patient":{"fr":"Marquer comme trait\u00e9","en":"Mark as treated"}}};
export const PATIENT_HELPER_TREATED_INFORMED = {"id":"patient.helper.treated_informed","translations":{"patient":{"fr":"Le dossier a \u00e9t\u00e9 marqu\u00e9 comme trait\u00e9 avec succ\u00e8s. Votre correspondant a \u00e9t\u00e9 inform\u00e9 de votre r\u00e9ponse.","en":"Record has been successfully marked as treated. Your counterpart has been informed of your response."}}};
export const PATIENT_HELPER_TASK_TREATED = {"id":"patient.helper.task_treated","translations":{"patient":{"fr":"Cette t\u00e2che a d\u00e9j\u00e0 \u00e9t\u00e9 trait\u00e9e","en":"This task has already been treated"}}};
export const PATIENT_HELPER_PARAMEDIC_ADDED = {"id":"patient.helper.paramedic_added","translations":{"patient":{"fr":"Le param\u00e9dical a bien \u00e9t\u00e9 ajout\u00e9.","en":"Paramedic has been successfully added."}}};
export const PATIENT_HELPER_SHARED = {"id":"patient.helper.shared","translations":{"patient":{"fr":"Le patient a bien \u00e9t\u00e9 partag\u00e9","en":"The patient has been successfully shared"}}};
export const PATIENT_HELPER_MISSING_MAIL = {"id":"patient.helper.missing_mail","translations":{"patient":{"fr":"En l\u2019absence d\u2019une adresse e-mail, le m\u00e9decin ne pourra pas acc\u00e9der \u00e0 ce dossier m\u00e9dical.","en":"Without email address, the doctor will not have access to this medical record."}}};
export const PATIENT_HELPER_READ_HEALTH_CARD = {"id":"patient.helper.read_health_card","translations":{"patient":{"fr":"Veuillez ins\u00e9rer la carte vitale du patient puis cliquer sur \"Lire la carte vitale\"","en":"Please insert patient\u2019s Carte vitale and click on \"Read Carte vitale\""}}};
export const PATIENT_HELPER_READING_HEALTH_CARD = {"id":"patient.helper.reading_health_card","translations":{"patient":{"fr":"Lecture de la carte vitale en cours","en":"Reading Carte vitale in progress"}}};
export const PATIENT_HELPER_READING_ERROR = {"id":"patient.helper.reading_error","translations":{"patient":{"fr":"Une erreur est survenue lors de la lecture de la carte vitale","en":"An error occurred while reading the Carte vitale"}}};
export const PATIENT_HELPER_READING_DONE = {"id":"patient.helper.reading_done","translations":{"patient":{"fr":"Lecture de la carte vitale effectu\u00e9e","en":"Carte vitale successfully read"}}};
export const PATIENT_HELPER_DOCTOR_INVITED = {"id":"patient.helper.doctor_invited","translations":{"patient":{"fr":"Le m\u00e9decin a bien \u00e9t\u00e9 invit\u00e9","en":"The doctor has been successfully invited"}}};
export const PATIENT_HELPER_RECONNECT = {"id":"patient.helper.reconnect","translations":{"patient":{"fr":"Me reconnecter via Pro Sant\u00e9 Connect","en":"Reconnect with Pro Sante Connect"}}};
export const PATIENT_HELPER_ENTER_MANUALLY = {"id":"patient.helper.enter_manually","translations":{"patient":{"fr":"Vous continuez sans la carte vitale du patient : s\u00e9lectionnez manuellement le patient","en":"You are continuing without the patient\u2019s Carte vitale: select the patient manually"}}};
export const PATIENT_HELPER_SELECT = {"id":"patient.helper.select","translations":{"patient":{"fr":"Veuillez choisir le patient pour lequel vous voulez cr\u00e9er la consultation","en":"Please choose the patient for whom you want to create the consultation"}}};
export const PATIENT_HELPER_USER_SELECTED = {"id":"patient.helper.user_selected","translations":{"patient":{"fr":"Vous avez s\u00e9lectionn\u00e9 $name","en":"You selected $name"}}};
export const PATIENT_HELPER_SEARCHING = {"id":"patient.helper.searching","translations":{"patient":{"fr":"Recherche de dossier existant...","en":"Searching for an existing record..."}}};
export const PATIENT_HELPER_FILE_CREATED = {"id":"patient.helper.file_created","translations":{"patient":{"fr":"Un dossier a \u00e9t\u00e9 cr\u00e9\u00e9 pour $name","en":"A record has been created for $name"}}};
export const PATIENT_HELPER_ALREADY_EXIST = {"id":"patient.helper.already_exist","translations":{"patient":{"fr":"Le dossier de $name existait d\u00e9j\u00e0 et a \u00e9t\u00e9 r\u00e9cup\u00e9r\u00e9.","en":"$name's record already existed and has been retrieved."}}};
export const PATIENT_HELPER_INFO_FILLED = {"id":"patient.helper.info_filled","translations":{"patient":{"fr":"Les informations de contact du patient sont remplies","en":"The patient's contact information is filled"}}};
export const PATIENT_HELPER_FILL_INFO = {"id":"patient.helper.fill_info","translations":{"patient":{"fr":"Veuillez renseigner les informations de contact du patient","en":"Please fill in the patient's contact information"}}};
export const PATIENT_HELPER_IGNORED = {"id":"patient.helper.ignored","translations":{"patient":{"fr":"Les informations de contact sont ignor\u00e9es","en":"Contact information is ignored"}}};
export const PATIENT_HELPER_SAVE_INFO = {"id":"patient.helper.save_info","translations":{"patient":{"fr":"Enregistrement des informations de contact du patient","en":"Saving patient's contact information"}}};
export const PATIENT_HELPER_INFO_SAVED = {"id":"patient.helper.info_saved","translations":{"patient":{"fr":"Les informations de contact ont \u00e9t\u00e9 enregistr\u00e9es","en":"Contact information has been saved"}}};
export const PATIENT_HELPER_FOUND = {"id":"patient.helper.found","translations":{"patient":{"fr":"Un patient existant a \u00e9t\u00e9 trouv\u00e9, veuillez confirmer s'il s'agit de la m\u00eame personne","en":"An existing patient was found. Please confirm if this is the same person."}}};
export const PATIENT_HELPER_FOUND_SEVERAL = {"id":"patient.helper.found_several","translations":{"patient":{"fr":"Plusieurs patients similaires ont \u00e9t\u00e9 trouv\u00e9, veuillez choisir dans la liste ci-dessous","en":"Several similar patients were found. Please choose from the list below."}}};
export const PATIENT_HELPER_SAME = {"id":"patient.helper.same","translations":{"patient":{"fr":"Il s'agit du m\u00eame patient","en":"This is the same patient"}}};
export const PATIENT_HELPER_SAME_SECURITY_NUMBER = {"id":"patient.helper.same_security_number","translations":{"patient":{"fr":"Un dossier existant a \u00e9t\u00e9 trouv\u00e9 avec ce num\u00e9ro de s\u00e9curit\u00e9 sociale mais des informations diff\u00e9rentes, veuillez confirmer s'il s'agit de la m\u00eame personne ou non","en":"An existing patient with this social security number was found, but the information differs. Please confirm if it's the same person."}}};
export const PATIENT_HELPER_CREATION = {"id":"patient.helper.creation","translations":{"patient":{"fr":"Cr\u00e9ation du nouveau dossier en cours","en":"Creating a new patient in progress"}}};
export const PATIENT_HELPER_CREATED = {"id":"patient.helper.created","translations":{"patient":{"fr":"Un nouveau dossier a bien \u00e9t\u00e9 cr\u00e9\u00e9.","en":"A new record has been successfully created."}}};
export const PATIENT_HELPER_ASSOCIATION = {"id":"patient.helper.association","translations":{"patient":{"fr":"Association de la carte vitale au patient en cours...","en":"Associating the Carte vitale with the patient in progress..."}}};
export const PATIENT_HELPER_ASSOCIATE = {"id":"patient.helper.associate","translations":{"patient":{"fr":"La carte vitale a bien \u00e9t\u00e9 associ\u00e9e au dossier de $name","en":"The Carte vitale has been successfully associated with $name's record."}}};
export const PATIENT_HELPER_SELECT_BENEFICIARIES = {"id":"patient.helper.select_beneficiaries","translations":{"patient":{"fr":"Choisissez votre patient parmi les b\u00e9n\u00e9ficiaires de la carte vitale.","en":"Select your patient among the Carte vitale beneficiaries."}}};
export const PATIENT_HELPER_NO_DOCTOR_LINKED = {"id":"patient.helper.no_doctor_linked","translations":{"patient":{"fr":"Vous n'avez pas de m\u00e9decin li\u00e9 qui a activ\u00e9 la t\u00e9l\u00e9transmission, veuillez en ajouter un avant de continuer","en":"You do not have a doctor linked with active teletransmission. Please add one before continuing."}}};
export const PATIENT_HELPER_RECENT = {"id":"patient.helper.recent","translations":{"patient":{"fr":"Patients r\u00e9cemments consult\u00e9s","en":"Recently consulted patients"}}};
export const PATIENT_HELPER_FOUND_IN_FILE = {"id":"patient.helper.found_in_file","translations":{"patient":{"fr":"Nous avons trouv\u00e9 $count patients dans votre fichier.","en":"We found $count patients in your file."}}};
export const PATIENT_HELPER_MAKE_MATCH = {"id":"patient.helper.make_match","translations":{"patient":{"fr":"NB. Veuillez faire correspondre chaque titre des colonnes de votre fichier avec la bonne valeur du menu d\u00e9roulant.","en":"Note: Match each column header in your file with the correct dropdown menu value."}}};
export const PATIENT_HELPER_IMPORTING = {"id":"patient.helper.importing","translations":{"patient":{"fr":"Vos patients sont en cours d'importation. Vous recevrez une notification une fois l'import termin\u00e9.","en":"Your patients are being imported. You will receive a notification once the import is complete."}}};
export const PATIENT_HELPER_NOT_REFERRING = {"id":"patient.helper.not_referring","translations":{"patient":{"fr":"Ce m\u00e9decin n\u2019est plus r\u00e9f\u00e9rent ( $name )","en":"This doctor is no longer the primary doctor ($name)"}}};
export const PATIENT_ADVISES_TITLE = {"id":"patient.advises.title","translations":{"patient":{"fr":"Nos consignes \u00e0 suivre","en":"Our recommendations to follow"}}};
export const PATIENT_ADVISES_EMPTY_SECTION = {"id":"patient.advises.empty_section","translations":{"patient":{"fr":"La section des conseils est vide","en":"The recommendations section is empty"}}};
export const PATIENT_ADVISES_EMPTY_PAGES = {"id":"patient.advises.empty_pages","translations":{"patient":{"fr":"Pages est vide","en":"Pages are empty"}}};
export const PATIENT_ALERTS_TITLE = {"id":"patient.alerts.title","translations":{"patient":{"fr":"Alertes de $patient","en":"Alerts for $patient"}}};
export const PATIENT_ALERTS_BOX_TITLE = {"id":"patient.alerts.box_title","translations":{"patient":{"fr":"Alerte","en":"Alert"}}};
export const PATIENT_ALERTS_CARER_COM = {"id":"patient.alerts.carer_com","translations":{"patient":{"fr":"Commentaire du soignant","en":"Carer's comment"}}};
export const PATIENT_ALERTS_RAISED = {"id":"patient.alerts.raised","translations":{"patient":{"fr":"Lev\u00e9e","en":"Raised"}}};
export const PATIENT_ALERTS_BEEN_RAISED = {"id":"patient.alerts.been_raised","translations":{"patient":{"fr":"L'alerte a bien \u00e9t\u00e9 lev\u00e9e","en":"The alert has been successfully raised"}}};
export const PATIENT_ALERTS_JS_IS = {"id":"patient.alerts.js.is","translations":{"patient":{"fr":"est ","en":"is "}}};
export const PATIENT_ALERTS_JS_IS_NOT = {"id":"patient.alerts.js.is_not","translations":{"patient":{"fr":"n'est pas ","en":"is not "}}};
export const PATIENT_ALERTS_JS_EMPTY = {"id":"patient.alerts.js.empty","translations":{"patient":{"fr":"vide","en":"empty"}}};
export const PATIENT_ALERTS_LIST_NO_ALERT_ONGOING = {"id":"patient.alerts.list.no_alert_ongoing","translations":{"patient":{"fr":"Aucune alerte en cours pour ce patient","en":"No ongoing alerts for this patient"}}};
export const PATIENT_ALERTS_LIST_NO_ALERT = {"id":"patient.alerts.list.no_alert","translations":{"patient":{"fr":"Aucune alerte","en":"No alerts"}}};
export const PATIENT_ALERTS_LIST_TITLE = {"id":"patient.alerts.list.title","translations":{"patient":{"fr":"Alerte","en":"Alert"}}};
export const PATIENT_ALERTS_LIST_ALERT_ONGOING = {"id":"patient.alerts.list.alert_ongoing","translations":{"patient":{"fr":"Alertes en cours","en":"Ongoing alerts"}}};
export const PATIENT_ALERTS_LIST_QUESTIONNAIRE = {"id":"patient.alerts.list.questionnaire","translations":{"patient":{"fr":"Questionnaire","en":"Questionnaire"}}};
export const PATIENT_ALERTS_LIST_QUESTION = {"id":"patient.alerts.list.question","translations":{"patient":{"fr":"Question","en":"Question"}}};
export const PATIENT_ALERTS_LIST_ANSWER = {"id":"patient.alerts.list.answer","translations":{"patient":{"fr":"R\u00e9ponse","en":"Answer"}}};
export const PATIENT_ALERTS_LIST_SEE_ANSWERS = {"id":"patient.alerts.list.see_answers","translations":{"patient":{"fr":"Voir les r\u00e9ponses","en":"View answers"}}};
export const PATIENT_ALERTS_LIST_PREVIOUS_ANSWER = {"id":"patient.alerts.list.previous_answer","translations":{"patient":{"fr":"R\u00e9ponse pr\u00e9c\u00e9dente","en":"Previous answer"}}};
export const PATIENT_ALERTS_LIST_CONDITION = {"id":"patient.alerts.list.condition","translations":{"patient":{"fr":"Condition","en":"Condition"}}};
export const PATIENT_ALERTS_LIST_OBSERVATION = {"id":"patient.alerts.list.observation","translations":{"patient":{"fr":"Observation","en":"Observation"}}};
export const PATIENT_ALERTS_LIST_RAISE_ALERT = {"id":"patient.alerts.list.raise_alert","translations":{"patient":{"fr":"Lever l'alerte","en":"Raise alert"}}};
export const PATIENT_BLOOD_TEST_TITLE = {"id":"patient.blood_test.title","translations":{"patient":{"fr":"Comprendre un bilan sanguin","en":"Understanding a blood test"}}};
export const PATIENT_BLOOD_TEST_MARK_AS = {"id":"patient.blood_test.mark_as","translations":{"patient":{"fr":"Marquer comme bilan sanguin","en":"Mark as blood test"}}};
export const PATIENT_BLOOD_TEST_DELETE_MARK = {"id":"patient.blood_test.delete_mark","translations":{"patient":{"fr":"Supprimer le marquage comme bilan sanguin","en":"Remove blood test mark"}}};
export const PATIENT_BLOOD_TEST_HELPER_BLOOD_TEST_TITLE = {"id":"patient.blood_test.helper.blood_test.title","translations":{"patient":{"fr":"Le bilan sanguin est un crit\u00e8re de surveillance essentiel.","en":"The blood test is a key monitoring criterion."}}};
export const PATIENT_BLOOD_TEST_HELPER_BLOOD_TEST_TEXT_A = {"id":"patient.blood_test.helper.blood_test.text_a","translations":{"patient":{"fr":"Vous aurez des prises de sang r\u00e9guli\u00e8res \u00e0 faire dans votre laboratoire de ville qui nous seront automatiquement fax\u00e9es pour \u00eatre analys\u00e9es par les h\u00e9matologues du service.","en":"You will have regular blood tests at your local laboratory, which will be automatically faxed to be analyzed by the hematologists in the service."}}};
export const PATIENT_BLOOD_TEST_HELPER_BLOOD_TEST_TEXT_B = {"id":"patient.blood_test.helper.blood_test.text_b","translations":{"patient":{"fr":"Des variations du bilan sanguin sont fr\u00e9quentes sous chimioth\u00e9rapie ou traitements cibl\u00e9s. Elles sont le plus souvent transitoires.","en":"Blood test variations are common under chemotherapy or targeted treatments. They are usually temporary."}}};
export const PATIENT_BLOOD_TEST_HELPER_BLOOD_TEST_TEXT_C = {"id":"patient.blood_test.helper.blood_test.text_c","translations":{"patient":{"fr":"Certaines de ces variations sont normales et attendues.","en":"Some of these variations are normal and expected."}}};
export const PATIENT_BLOOD_TEST_HELPER_BLOOD_TEST_TEXT_D = {"id":"patient.blood_test.helper.blood_test.text_d","translations":{"patient":{"fr":"Seul votre h\u00e9matologue pourra vous renseigner pr\u00e9cis\u00e9ment. N\u2019h\u00e9sitez pas \u00e0 lui en parler.","en":"Only your hematologist can give you precise information. Don't hesitate to talk to them."}}};
export const PATIENT_BLOOD_TEST_HELPER_BLOOD_TEST_TEXT_E = {"id":"patient.blood_test.helper.blood_test.text_e","translations":{"patient":{"fr":"Pour faciliter l\u2019organisation des soins, munissez vous de votre dernier bilan lors de votre venue en hospitalisation ou en consultation ou lors d\u2019un appel \u00e0 un h\u00e9matologue.","en":"To facilitate care, bring your latest blood test results when visiting for hospitalization, consultation, or calling hematologist."}}};
export const PATIENT_BLOOD_TEST_HELPER_BLOOD_TEST_TEXT_F = {"id":"patient.blood_test.helper.blood_test.text_f","translations":{"patient":{"fr":"L\u2019examen de l\u2019h\u00e9mogramme encore appel\u00e9 num\u00e9ration formule sanguine sert \u00e0 mesurer la quantit\u00e9 des cellules du sang : globules rouges (h\u00e9maties), h\u00e9moglobine, globules blancs (leucocytes) et plaquettes dans le sang.","en":"The hemogram, also called a complete blood count, measures the amount of blood cells: red blood cells (erythrocytes), hemoglobin, white blood cells (leukocytes), and platelets."}}};
export const PATIENT_BLOOD_TEST_HELPER_BLOOD_TEST_TEXT_G = {"id":"patient.blood_test.helper.blood_test.text_g","translations":{"patient":{"fr":"La plupart des m\u00e9dicaments en h\u00e9matologie peuvent entra\u00eener de fa\u00e7on plus ou moins importante une diminution transitoire du nombre de cellules sanguines.","en":"Most hematology drugs can transiently reduce the number of blood cells to varying degrees."}}};
export const PATIENT_BLOOD_TEST_HELPER_RESULTS_IMPORTANT_DATA = {"id":"patient.blood_test.helper.results.important_data","translations":{"patient":{"fr":"Les \u00e9l\u00e9ments importants pour vous sont","en":"The key elements for you are"}}};
export const PATIENT_BLOOD_TEST_HELPER_RESULTS_DATA_A = {"id":"patient.blood_test.helper.results.data_a","translations":{"patient":{"fr":"L\u2019h\u00e9moglobine (et non le chiffre de globules rouges)","en":"Hemoglobin (not the red blood cell count)"}}};
export const PATIENT_BLOOD_TEST_HELPER_RESULTS_DATA_B = {"id":"patient.blood_test.helper.results.data_b","translations":{"patient":{"fr":"Les polynucl\u00e9aires neutrophiles (cat\u00e9gorie de globules blancs)","en":"Neutrophil granulocytes (a type of white blood cell)"}}};
export const PATIENT_BLOOD_TEST_HELPER_RESULTS_DATA_C = {"id":"patient.blood_test.helper.results.data_c","translations":{"patient":{"fr":"Les plaquettes","en":"Platelets"}}};
export const PATIENT_BLOOD_TEST_HELPER_RESULTS_SITUATIONS = {"id":"patient.blood_test.helper.results.situations","translations":{"patient":{"fr":"Trois situations","en":"Three situations"}}};
export const PATIENT_BLOOD_TEST_HELPER_RESULTS_SITUATION_A = {"id":"patient.blood_test.helper.results.situation_a","translations":{"patient":{"fr":"Normal","en":"Normal"}}};
export const PATIENT_BLOOD_TEST_HELPER_RESULTS_SITUATION_B = {"id":"patient.blood_test.helper.results.situation_b","translations":{"patient":{"fr":"Anormal mais non inqui\u00e9tant et sans risque (c\u2019est le plus fr\u00e9quent)","en":"Abnormal but not worrisome or risky (this is the most common)"}}};
export const PATIENT_BLOOD_TEST_HELPER_RESULTS_SITUATION_C = {"id":"patient.blood_test.helper.results.situation_c","translations":{"patient":{"fr":"Anormal avec n\u00e9cessit\u00e9 d\u2019une prise en charge imm\u00e9diate (qui d\u00e9pend souvent de votre \u00e9tat clinique et non pas uniquement des chiffres)","en":"Abnormal requiring immediate care (often depending on your clinical condition, not just the numbers)"}}};
export const PATIENT_BLOOD_TEST_HELPER_RESULTS_TEXT_A = {"id":"patient.blood_test.helper.results.text_a","translations":{"patient":{"fr":"Les perturbations importantes seront donc trait\u00e9es sur d\u00e9cision d\u2019un h\u00e9matologue du service qui vous contactera \u00e0 votre domicile.","en":"Significant disruptions will be treated based on a decision by a service hematologist who will contact you at home."}}};
export const PATIENT_BLOOD_TEST_HELPER_RESULTS_TEXT_B = {"id":"patient.blood_test.helper.results.text_b","translations":{"patient":{"fr":"Les m\u00e9dicaments sont \u00e9limin\u00e9s par le foie et les reins (pensez \u00e0 boire r\u00e9guli\u00e8rement entre les traitements pour favoriser leur \u00e9limination, entre 1l \u00bd et 2 L. par 24h)","en":"Drugs are eliminated by the liver and kidneys (remember to drink regularly between treatments to promote elimination, 1.5 to 2 liters per day)."}}};
export const PATIENT_BLOOD_TEST_HELPER_RESULTS_TEXT_C = {"id":"patient.blood_test.helper.results.text_c","translations":{"patient":{"fr":"La fonction r\u00e9nale et h\u00e9patique seront \u00e9valu\u00e9es syst\u00e9matiquement avant chaque nouveau traitement.","en":"Liver and kidney functions will be systematically evaluated before each new treatment."}}};
export const PATIENT_CGU_TITLE = {"id":"patient.cgu.title","translations":{"patient":{"fr":"Conditions G\u00e9n\u00e9rales d'Utilisation","en":"General Terms of Use"}}};
export const PATIENT_CGU_HELPER_TITLE = {"id":"patient.cgu.helper.title","translations":{"patient":{"fr":"Pour utiliser ce site, il vous est demand\u00e9 d'accepter les conditions g\u00e9n\u00e9rales d'utilisation","en":"To use this site, you must accept general terms of use"}}};
export const PATIENT_CGU_HELPER_INFORMATIONS = {"id":"patient.cgu.helper.informations","translations":{"patient":{"fr":"Les CGU informent les visiteurs sur diff\u00e9rentes informations comme","en":"The terms inform visitors about various details such as"}}};
export const PATIENT_CGU_HELPER_INFORMATION_A = {"id":"patient.cgu.helper.information_a","translations":{"patient":{"fr":"les mentions l\u00e9gales relatives \u00e0 la soci\u00e9t\u00e9, \u00e0 son si\u00e8ge social","en":"the legal notices related to the company and its headquarters"}}};
export const PATIENT_CGU_HELPER_INFORMATION_B = {"id":"patient.cgu.helper.information_b","translations":{"patient":{"fr":"les conditions d'acc\u00e8s au site","en":"the terms of access to the site"}}};
export const PATIENT_CGU_HELPER_INFORMATION_C = {"id":"patient.cgu.helper.information_c","translations":{"patient":{"fr":"les diff\u00e9rents services et les produits qu'offre le site","en":"the different services and products offered by the site"}}};
export const PATIENT_CGU_HELPER_INFORMATION_D = {"id":"patient.cgu.helper.information_d","translations":{"patient":{"fr":"les modalit\u00e9s relatives \u00e0 la cr\u00e9ation d'un compte visiteur, client","en":"the conditions for creating a visitor or client account"}}};
export const PATIENT_CGU_HELPER_INFORMATION_E = {"id":"patient.cgu.helper.information_e","translations":{"patient":{"fr":"la propri\u00e9t\u00e9 intellectuelle","en":"intellectual property"}}};
export const PATIENT_CGU_HELPER_INFORMATION_F = {"id":"patient.cgu.helper.information_f","translations":{"patient":{"fr":"la protection des donn\u00e9es personnelles","en":"personal data protection"}}};
export const PATIENT_CGU_HELPER_INFORMATION_G = {"id":"patient.cgu.helper.information_g","translations":{"patient":{"fr":"la responsabilit\u00e9 de l'\u00e9diteur et ses limites","en":"the publisher's liability and its limits"}}};
export const PATIENT_CGU_HELPER_INFORMATION_H = {"id":"patient.cgu.helper.information_h","translations":{"patient":{"fr":"la responsabilit\u00e9 du visiteur","en":"the visitor's liability"}}};
export const PATIENT_CGU_CHECKBOX = {"id":"patient.cgu.checkbox","translations":{"patient":{"fr":"En cochant cette case, je reconnais avoir pris connaissance et accept\u00e9 les","en":"By checking this box, I acknowledge that I have read and accepted the"}}};
export const PATIENT_CGU_LINK = {"id":"patient.cgu.link","translations":{"patient":{"fr":"Conditions G\u00e9n\u00e9rales d'Utilisation","en":"General Terms of Use"}}};
export const PATIENT_CGU_ENTER = {"id":"patient.cgu.enter","translations":{"patient":{"fr":"Acc\u00e9der au site","en":"Access the site"}}};
export const PATIENT_IMPORT_NEW_PATIENT = {"id":"patient.import.new_patient","translations":{"patient":{"fr":"Nouveau patient","en":"New patient"}}};
export const PATIENT_IMPORT_STEP_ONE_TITLE = {"id":"patient.import.step_one.title","translations":{"patient":{"fr":"1\/3 - Choisissez un fichier \u00e0 importer","en":"1\/3 - Select file to import"}}};
export const PATIENT_IMPORT_STEP_ONE_HELPER = {"id":"patient.import.step_one.helper","translations":{"patient":{"fr":"S\u00e9lectionnez un fichier CSV comportant les informations de vos patients (votre fichier doit comporter des en-t\u00eate de colonnes).","en":"Select a CSV file containing your patients' information (your file must include column headers)."}}};
export const PATIENT_IMPORT_STEP_ONE_DOWNLOAD = {"id":"patient.import.step_one.download","translations":{"patient":{"fr":"T\u00e9l\u00e9charger un fichier d'exemple","en":"Download example file"}}};
export const PATIENT_IMPORT_STEP_ONE_REQUIRED = {"id":"patient.import.step_one.required","translations":{"patient":{"fr":"Seul les champs 'Nom', 'Pr\u00e9nom' et 'E-mail' sont obligatoires.","en":"Only the fields 'Name,' 'First Name,' and 'Email' are mandatory."}}};
export const PATIENT_IMPORT_STEP_ONE_HOW_TO = {"id":"patient.import.step_one.how_to","translations":{"patient":{"fr":"Comment cr\u00e9er un fichier CSV ?","en":"How to create a CSV file?"}}};
export const PATIENT_IMPORT_STEP_TWO_TILE = {"id":"patient.import.step_two.tile","translations":{"patient":{"fr":"2\/3 - D\u00e9finissez vos colonnes","en":"2\/3 - Set your columns"}}};
export const PATIENT_IMPORT_STEP_THREE_TITLE = {"id":"patient.import.step_three.title","translations":{"patient":{"fr":"3\/3 - D\u00e9finissez vos options","en":"3\/3 - Set your options"}}};
export const PATIENT_IMPORT_STEP_THREE_INVITATION_MAIL = {"id":"patient.import.step_three.invitation_mail","translations":{"patient":{"fr":"Envoyer une invitation par email \u00e0 tous les nouveaux patients.","en":"Send invitation email to all new patients."}}};
export const PATIENT_IMPORT_STEP_THREE_INVITATION_SMS = {"id":"patient.import.step_three.invitation_sms","translations":{"patient":{"fr":"Envoyer une invitation par SMS \u00e0 tous les nouveaux patients.","en":"Send SMS invitation to all new patients."}}};
export const PATIENT_IMPORT_STEP_THREE_IMPORT = {"id":"patient.import.step_three.import","translations":{"patient":{"fr":"Lancer l'importation","en":"Start import"}}};
export const PATIENT_IMPORT_IN_PROGRESS_TITLE = {"id":"patient.import.in_progress.title","translations":{"patient":{"fr":"Importation en cours","en":"Import in progress"}}};
export const PATIENT_IMPORT_IN_PROGRESS_HELPER = {"id":"patient.import.in_progress.helper","translations":{"patient":{"fr":"Votre importation est en cours. En fonction de la taille du fichier, elle peut prendre plusieurs minutes.","en":"Your import is in progress. Depending on the file size, this may take several minutes."}}};
export const PATIENT_IMPORT_IN_PROGRESS_BACK_LIST = {"id":"patient.import.in_progress.back_list","translations":{"patient":{"fr":"Retour \u00e0 la liste des patients","en":"Back to patient list"}}};
export const PATIENT_SUPERVISION_TITLE = {"id":"patient.supervision.title","translations":{"patient":{"fr":"Ma surveillance","en":"My monitoring"}}};
export const PATIENT_TIP_TITLE = {"id":"patient.tip.title","translations":{"patient":{"fr":"Astuces de patients","en":"Patient tips"}}};
export const PATIENT_TIP_BACK_FAQ = {"id":"patient.tip.back_faq","translations":{"patient":{"fr":"Retourner \u00e0 la FAQ","en":"Return to FAQ"}}};
export const PATIENT_TIP_PROPOSAL = {"id":"patient.tip.proposal","translations":{"patient":{"fr":"Proposer votre astuce","en":"Submit your tip"}}};
export const PATIENT_TIP_HELPER = {"id":"patient.tip.helper","translations":{"patient":{"fr":"Vous avez une astuce \u00e0 sugg\u00e9rer ? Soumettez-l\u00e0 \u00e0 la validation de l'\u00e9quipe et rendez la publique. Si vous le souhaitez, vous pouvez ajouter votre pr\u00e9nom et votre \u00e2ge.","en":"Do you have a tip to suggest? Submit it for the team\u2019s validation and make it public. If you wish, you can add your first name and age."}}};
export const PATIENT_TIP_TIP_TITLE = {"id":"patient.tip.tip_title","translations":{"patient":{"fr":"Titre de l'astuce","en":"Tip title"}}};
export const PATIENT_TIP_TIP_TITLE_PLACEHOLDER = {"id":"patient.tip.tip_title_placeholder","translations":{"patient":{"fr":"Donnez un titre \u00e0 votre astuce","en":"Give a title to your tip"}}};
export const PATIENT_TIP_YOUR_TIP = {"id":"patient.tip.your_tip","translations":{"patient":{"fr":"Votre astuce","en":"Your tip"}}};
export const PATIENT_TIP_YOUR_TIP_PLACEHOLDER = {"id":"patient.tip.your_tip_placeholder","translations":{"patient":{"fr":"\u00c9crivez votre astuce ici","en":"Write your tip here"}}};
export const PATIENT_TIP_IMAGE = {"id":"patient.tip.image","translations":{"patient":{"fr":"Image accompagnant la question","en":"Add attachment"}}};
export const PATIENT_TREATMENT_TITLE = {"id":"patient.treatment.title","translations":{"patient":{"fr":"Mon traitement","en":"My treatment"}}};
export const PATIENT_TREATMENT_WORD = {"id":"patient.treatment.word","translations":{"patient":{"fr":"Traitement","en":"Treatment"}}};
export const PATIENT_TREATMENT_TREATMENTS = {"id":"patient.treatment.treatments","translations":{"patient":{"fr":"Traitements","en":"Treatments"}}};
export const PATIENT_TREATMENT_ABVD = {"id":"patient.treatment.abvd","translations":{"patient":{"fr":"ABVD","en":"ABVD"}}};
export const PATIENT_TREATMENT_DEFAULT = {"id":"patient.treatment.default","translations":{"patient":{"fr":"D\u00e9faut","en":"Default"}}};
export const PATIENT_TREATMENT_FREQUENCY = {"id":"patient.treatment.frequency","translations":{"patient":{"fr":"Fr\u00e9quence","en":"Frequency"}}};
export const PATIENT_TREATMENT_NOT_FOUND = {"id":"patient.treatment.not_found","translations":{"patient":{"fr":"Ce patient n'a pas de traitement pass\u00e9. <br \/>Aucun rapport d'\u00e9valuation n'est disponible.","en":"This patient has no past treatments. <br \/>No evaluation report is available."}}};
export const PATIENT_TREATMENT_ONGOING = {"id":"patient.treatment.ongoing","translations":{"patient":{"fr":"Traitements en cours","en":"Ongoing treatments"}}};
export const PATIENT_TREATMENT_OLD = {"id":"patient.treatment.old","translations":{"patient":{"fr":"Anciens traitements","en":"Past treatments"}}};
export const PATIENT_TREATMENT_ADD = {"id":"patient.treatment.add","translations":{"patient":{"fr":"Ajouter un traitement","en":"Add treatment"}}};
export const PATIENT_TREATMENT_CONFIRM_DELETE = {"id":"patient.treatment.confirm_delete","translations":{"patient":{"fr":"\u00cates-vous s\u00fbr de vouloir supprimer ce traitement ?","en":"Are you sure you want to delete this treatment?"}}};
export const PATIENT_TREATMENT_DELETED = {"id":"patient.treatment.deleted","translations":{"patient":{"fr":"Le traitement a bien \u00e9t\u00e9 supprim\u00e9","en":"The treatment has been successfully deleted"}}};
export const PATIENT_TREATMENT_STOP = {"id":"patient.treatment.stop","translations":{"patient":{"fr":"Arr\u00eater le traitement","en":"Stop treatment"}}};
export const PATIENT_TREATMENT_CONFIRM_STOP = {"id":"patient.treatment.confirm_stop","translations":{"patient":{"fr":"\u00cates-vous s\u00fbr de vouloir arr\u00eater ce traitement ?","en":"Are you sure you want to stop this treatment?"}}};
export const PATIENT_TREATMENT_STOPPED = {"id":"patient.treatment.stopped","translations":{"patient":{"fr":"Le traitement a bien \u00e9t\u00e9 arr\u00eat\u00e9","en":"The treatment has been successfully stopped"}}};
export const PATIENT_TREATMENT_FILL = {"id":"patient.treatment.fill","translations":{"patient":{"fr":"Veuillez saisir un traitement","en":"Please enter a treatment"}}};
export const PATIENT_TREATMENT_DRUG_CHOICE = {"id":"patient.treatment.drug_choice","translations":{"patient":{"fr":"Choix de m\u00e9dicaments","en":"Drug choice"}}};
export const PATIENT_TREATMENT_DRUG_LIST_TITLE = {"id":"patient.treatment.drug_list.title","translations":{"patient":{"fr":"Ce protocole comprend l'administration de 4 m\u00e9dicaments :","en":"This protocol includes the administration of 4 drugs:"}}};
export const PATIENT_TREATMENT_DRUG_LIST_DRUG_A = {"id":"patient.treatment.drug_list.drug_a","translations":{"patient":{"fr":"<strong>ADRIBLASTINE\u00ae<\/strong> Doxorubicine","en":"<strong>ADRIBLASTINE\u00ae<\/strong> Doxorubicin"}}};
export const PATIENT_TREATMENT_DRUG_LIST_DRUG_B = {"id":"patient.treatment.drug_list.drug_b","translations":{"patient":{"fr":"<strong>BLEOMYCINE\u00ae<\/strong> Bl\u00e9omycine","en":"<strong>BLEOMYCINE\u00ae<\/strong> Bleomycin"}}};
export const PATIENT_TREATMENT_DRUG_LIST_DRUG_C = {"id":"patient.treatment.drug_list.drug_c","translations":{"patient":{"fr":"<strong>VELBE\u00ae<\/strong> Vinblastine","en":"<strong>VELBE\u00ae\ufe0f<\/strong> Vinblastine"}}};
export const PATIENT_TREATMENT_DRUG_LIST_DRUG_D = {"id":"patient.treatment.drug_list.drug_d","translations":{"patient":{"fr":"<strong>DETICENE\u00ae<\/strong> Dacarbazine","en":"<strong>DETICENE\u00ae\ufe0f<\/strong> Dacarbazine"}}};
export const PATIENT_TREATMENT_DRUG_LIST_HELPER = {"id":"patient.treatment.drug_list.helper","translations":{"patient":{"fr":"Ces m\u00e9dicaments seront administr\u00e9s en perfusion intraveineuse \u00e0 J1 et J15. L'administration durera environ 3 heures. Ce cycle sera r\u00e9p\u00e9t\u00e9 tous les 28 jours","en":"These drugs will be administered intravenously on Day 1 and Day 15. Administration will last about 3 hours. This cycle will be repeated every 28 days."}}};
export const PATIENT_WORKSHOP_TITLE = {"id":"patient.workshop.title","translations":{"patient":{"fr":"Nos ateliers","en":"Our workshops"}}};
export const PATIENT_WORKSHOP_NO_DESCRIPTION = {"id":"patient.workshop.no_description","translations":{"patient":{"fr":"PAS DE DESCRIPTION pour la section","en":"NO DESCRIPTION for section"}}};
export const PATIENT_PRINT_TITLE = {"id":"patient.print.title","translations":{"patient":{"fr":"Acc\u00e8s de $firstName $lastName","en":"Access for $firstName $lastName"}}};
export const PATIENT_PRINT_PATIENT_CREATED = {"id":"patient.print.patient_created","translations":{"patient":{"fr":"Patient cr\u00e9\u00e9","en":"Patient created"}}};
export const PATIENT_PRINT_ACTIONS_ACCESS_PROFILE = {"id":"patient.print.actions.access_profile","translations":{"patient":{"fr":"Acc\u00e9der au profil du patient","en":"Access patient profile"}}};
export const PATIENT_ALREADY_EXISTING_HEADER = {"id":"patient.already_existing.header","translations":{"patient":{"fr":"Attention !","en":"Attention!"}}};
export const PATIENT_ALREADY_EXISTING_TITLE = {"id":"patient.already_existing.title","translations":{"patient":{"fr":"Ce patient dispose d\u00e9j\u00e0 d'un dossier li\u00e9 \u00e0 ces donn\u00e9es.","en":"This patient already has a record linked to this data."}}};
export const PATIENT_ALREADY_EXISTING_DESCRIPTION_CONFLICT = {"id":"patient.already_existing.description_conflict","translations":{"patient":{"fr":"En cliquant sur \"Oui\", une demande d\u2019acc\u00e8s \u00e0 son dossier existant lui sera envoy\u00e9e ainsi qu\u2019\u00e0 son \u00e9quipe soignante.<br\/><br\/>Une fois valid\u00e9e, vous aurez acc\u00e8s \u00e0 son dossier d\u00e9j\u00e0 existant.<br\/><br\/>En attendant, vous pouvez continuer votre consultation, les nouvelles donn\u00e9es seront sauvegard\u00e9es.","en":"By clicking \"Yes,\" an access request for their existing record will be sent to them and their healthcare team.<br\/><br\/>Once validated, you will have access to their existing record.<br\/><br\/>Meanwhile, you can continue your consultation; the new data will be saved."}}};
export const PATIENT_ALREADY_EXISTING_DESCRIPTION_MERGE = {"id":"patient.already_existing.description_merge","translations":{"patient":{"fr":"En cliquant sur \"Oui\", Le dossier actuel sera fusionn\u00e9 avec le dossier existant. Une fois la fusion finalis\u00e9e, vous serez redirig\u00e9(e) vers le dossier existant.<br\/><br\/>Toutes les donn\u00e9es entr\u00e9es dans le formulaire seront enregistr\u00e9es.","en":"By clicking \"Yes,\" the current record will be merged with the existing record. Once the merge is completed, you will be redirected to the existing record.<br\/><br\/>All data entered in the form will be saved."}}};
export const PATIENT_SHARE_TO_DOCTOR = {"id":"patient.share_to.doctor","translations":{"patient":{"fr":"Un m\u00e9decin","en":"A doctor"}}};
export const PATIENT_SHARE_TO_PARAMEDICAL = {"id":"patient.share_to.paramedical","translations":{"patient":{"fr":"Un param\u00e9dical","en":"A paramedic"}}};
export const PATIENT_SHARE_TO_ESTABLISHMENT = {"id":"patient.share_to.establishment","translations":{"patient":{"fr":"Un \u00e9tablissement","en":"A facility"}}};
export const PATIENT_SHARE_TO_SELECT_ESTABLISHMENT = {"id":"patient.share_to.select_establishment","translations":{"patient":{"fr":"Choisissez l'\u00e9tablissement","en":"Select the facility"}}};
export const PATIENT_SHARE_TO_SPECIFY_SERVICES = {"id":"patient.share_to.specify_services","translations":{"patient":{"fr":"Sp\u00e9cifiez le ou les services de l'\u00e9tablissement","en":"Specify the departments of facility"}}};
export const PATIENT_SHARE_TO_CHECKBOX = {"id":"patient.share_to.checkbox","translations":{"patient":{"fr":"En cochant cette case, vous certifiez que ce patient est trait\u00e9 dans cet \u00e9tablissement. Son dossier sera accessible par tous les professionnels de sant\u00e9 autoris\u00e9s de cet \u00e9tablissement.","en":"By checking this box, you certify that this patient is treated in this facility. Their record will be accessible by all authorized caregivers of this facility."}}};
export const PATIENT_SHARE_TO_SHARE_ESTABLISHMENT = {"id":"patient.share_to.share_establishment","translations":{"patient":{"fr":"Partager \u00e0 l'\u00e9tablissement","en":"Share"}}};
export const PATIENT_SHARE_TO_PARAMEDICAL_NAME = {"id":"patient.share_to.paramedical_name","translations":{"patient":{"fr":"Nom du param\u00e9dical","en":"Paramedic name"}}};
export const PATIENT_SHARE_TO_SEARCH_BY_ESTABLISHMENT = {"id":"patient.share_to.search_by_establishment","translations":{"patient":{"fr":"Si vous ne connaissez pas le nom du destinataire, veuillez effectuer une recherche en utilisant le nom du prestataire de soins \u00e0 domicile (ex. : Elivie).","en":"If you are unsure of the recipient\u2019s name, please search using the name of the homecare provider (e.g., Elivie)"}}};
export const PATIENT_SHARE_TO_REASON_TITLE = {"id":"patient.share_to.reason.title","translations":{"patient":{"fr":"Motif du partage","en":"Reason for sharing"}}};
export const PATIENT_SHARE_TO_REASON_CONVOCATION = {"id":"patient.share_to.reason.convocation","translations":{"patient":{"fr":"Pour convocation","en":"For appointment"}}};
export const PATIENT_SHARE_TO_REASON_ADVICE = {"id":"patient.share_to.reason.advice","translations":{"patient":{"fr":"Pour avis medical","en":"For medical advice"}}};
export const PATIENT_SHARE_TO_REASON_WILL_TAKE_APPOINTMENT = {"id":"patient.share_to.reason.will_take_appointment","translations":{"patient":{"fr":"Pour prise de RDV","en":"To take an appointment"}}};
export const PATIENT_SHARE_TO_REASON_ADMINISTRATIVE = {"id":"patient.share_to.reason.administrative","translations":{"patient":{"fr":"Pour gestion administrative","en":"For administrative management"}}};
export const PATIENT_SHARE_TO_REASON_FUSION = {"id":"patient.share_to.reason.fusion","translations":{"patient":{"fr":"Pour fusion de dossier ","en":"For record merge"}}};
export const PATIENT_SHARE_TO_PARAMEDICAL_NOTE = {"id":"patient.share_to.paramedical_note","translations":{"patient":{"fr":"Note \u00e0 destination du param\u00e9dical","en":"Note for the paramedic"}}};
export const PATIENT_SHARE_TO_DOCTOR_NOTE = {"id":"patient.share_to.doctor_note","translations":{"patient":{"fr":"Note \u00e0 destination du m\u00e9decin","en":"Note for the doctor"}}};
export const PATIENT_SHARE_TO_NOTE_HELPER = {"id":"patient.share_to.note_helper","translations":{"patient":{"fr":"Cette note sera visible dans son e-mail d\u2019invitation","en":"This note will be visible in their invitation email"}}};
export const PATIENT_SHARE_TO_PARAMEDICAL_NOTE_HELPER = {"id":"patient.share_to.paramedical_note_helper","translations":{"patient":{"fr":"Cette note sera visible dans son e-mail d\u2019invitation. Si vous partagez ce dossier pour un m\u00e9decin, merci de pr\u00e9ciser dans la note le nom du m\u00e9decin.","en":"This note will be visible in the invitation email. If you are sharing this file for a doctor, please specify the doctor's name in the note."}}};
export const PATIENT_SHARE_TO_SEARCH_BY_NAME = {"id":"patient.share_to.search_by_name","translations":{"patient":{"fr":"Rechercher le nom du professionnel de sant\u00e9 dans la base RPPS","en":"Search for the doctor\u2019s name in the RPPS database"}}};
export const PATIENT_SHARE_TO_REFERRING_DOCTOR = {"id":"patient.share_to.referring_doctor","translations":{"patient":{"fr":"D\u00e9finir comme mon m\u00e9decin traitant","en":"This doctor is my primary doctor"}}};
export const PATIENT_SHARE_TO_SET_REFERRING_DOCTOR = {"id":"patient.share_to.set_referring_doctor","translations":{"patient":{"fr":"D\u00e9finir comme m\u00e9decin traitant de ce patient","en":"Set as the patient's primary doctor"}}};
export const PATIENT_SHARE_TO_CHECKBOX_REFERRING_DOCTOR = {"id":"patient.share_to.checkbox_referring_doctor","translations":{"patient":{"fr":"Je confirme que ce professionnel de sant\u00e9 est autoris\u00e9 \u00e0 acc\u00e9der \u00e0 mon dossier m\u00e9dical. Il aura acc\u00e8s \u00e0 mon dossier uniquement \u00e0 des fins de consultation, et je serai notifi\u00e9 lorsqu\u2019il l\u2019aura consult\u00e9.","en":"By checking this box, you certify that this doctor is one of your primary doctors. They will have free access to your record. You will be notified as soon as they accept your invitation."}}};
export const PATIENT_SHARE_TO_CHECKBOX_PARTNER = {"id":"patient.share_to.checkbox_partner","translations":{"patient":{"fr":"Je confirme que ce professionnel de sant\u00e9 est autoris\u00e9 \u00e0 acc\u00e9der \u00e0 ce dossier m\u00e9dical. Il aura un acc\u00e8s gratuit uniquement \u00e0 des fins de consultation, et je serai notifi\u00e9 lorsqu\u2019il l\u2019aura consult\u00e9.","en":"I certify that this is one of my authorized healthcare professionals. They will be granted access solely to review my medical record, and I will be notified upon their access."}}};
export const PATIENT_SHARE_TO_MANUAL_FILL = {"id":"patient.share_to.manual_fill","translations":{"patient":{"fr":"Entrer manuellement les informations du m\u00e9decin","en":"Manually enter doctor's information"}}};
export const PATIENT_LIST_SEE_LIST = {"id":"patient.list.see_list","translations":{"patient":{"fr":"Voir sous forme de liste","en":"View as list"}}};
export const PATIENT_LIST_SEE_BOX = {"id":"patient.list.see_box","translations":{"patient":{"fr":"Voir sous forme de boites","en":"View as boxes"}}};
export const PATIENT_LIST_ACTIVE = {"id":"patient.list.active","translations":{"patient":{"fr":"Actifs","en":"Active"}}};
export const PATIENT_LIST_ARCHIVED = {"id":"patient.list.archived","translations":{"patient":{"fr":"Archiv\u00e9s","en":"Archived"}}};
export const PATIENT_LIST_DOCTORS = {"id":"patient.list.doctors","translations":{"patient":{"fr":"Chargement de la liste de vos m\u00e9decins","en":"Loading your doctors' list"}}};
export const PATIENT_LIST_CORRESPONDING_DOCTORS = {"id":"patient.list.corresponding_doctors","translations":{"patient":{"fr":"Liste des m\u00e9decins correspondants","en":"List of corresponding doctors"}}};
export const PATIENT_YOUR_PATIENT_TITLE = {"id":"patient.your_patient.title","translations":{"patient":{"fr":"Vos patients","en":"Your patients"}}};
export const PATIENT_YOUR_PATIENT_NEW_REPORT = {"id":"patient.your_patient.new_report","translations":{"patient":{"fr":"Nouvelle auto-\u00e9valuation disponible","en":"New reported outcome available"}}};
export const PATIENT_YOUR_PATIENT_NEW_NOTE = {"id":"patient.your_patient.new_note","translations":{"patient":{"fr":"Nouvelle note disponible","en":"New note available"}}};
export const PATIENT_YOUR_PATIENT_BLACKLISTED = {"id":"patient.your_patient.blacklisted","translations":{"patient":{"fr":"Patient ind\u00e9sirable","en":"Undesirable patient"}}};
export const PATIENT_YOUR_PATIENT_GENERAL_INFO = {"id":"patient.your_patient.general_info","translations":{"patient":{"fr":"Informations g\u00e9n\u00e9rales","en":"General Informations"}}};
export const PATIENT_YOUR_PATIENT_MEDICAL_INFO = {"id":"patient.your_patient.medical_info","translations":{"patient":{"fr":"Informations m\u00e9dicales","en":"Medical Informations"}}};
export const PATIENT_YOUR_PATIENT_DECEASED = {"id":"patient.your_patient.deceased","translations":{"patient":{"fr":"Le patient est d\u00e9c\u00e9d\u00e9.","en":"The patient is deceased."}}};
export const PATIENT_YOUR_PATIENT_DECEASED_DATE = {"id":"patient.your_patient.deceased_date","translations":{"patient":{"fr":"Le patient est d\u00e9c\u00e9d\u00e9 le $date.","en":"The patient passed away on $date."}}};
export const PATIENT_YOUR_PATIENT_SHORT_DECEASED = {"id":"patient.your_patient.short_deceased","translations":{"patient":{"fr":"D\u00e9c\u00e9d\u00e9(e)","en":"Deceased"}}};
export const PATIENT_YOUR_PATIENT_SHORT_DECEASED_DATE = {"id":"patient.your_patient.short_deceased_date","translations":{"patient":{"fr":"D\u00e9c\u00e9d\u00e9(e) en $date","en":"Deceased in $date"}}};
export const PATIENT_SELF_EVALUATION_SELF = {"id":"patient.self_evaluation.self","translations":{"patient":{"fr":"Auto-\u00e9valuation","en":"Reported outcome"}}};
export const PATIENT_SELF_EVALUATION_HELPER = {"id":"patient.self_evaluation.helper","translations":{"patient":{"fr":"Afin d'aider votre \u00e9quipe soignante, veuillez remplir l'auto-\u00e9valuation de vos symptomes.","en":"To assist your care team, please fill out the reported outcome of your symptoms."}}};
export const PATIENT_SELF_EVALUATION_SELF_FOR = {"id":"patient.self_evaluation.self_for","translations":{"patient":{"fr":"uto-\u00e9valuation pour $name","en":"Reported outcome for $name"}}};
export const PATIENT_SELF_EVALUATION_MEDICAL_SELF = {"id":"patient.self_evaluation.medical_self","translations":{"patient":{"fr":"Auto-\u00e9valuation(s) m\u00e9dicale(s)","en":"Medical reported outcomes"}}};
export const PATIENT_SELF_EVALUATION_MEDICAL_SELF_FOLLOWING = {"id":"patient.self_evaluation.medical_self_following","translations":{"patient":{"fr":"Auto-\u00e9valuation(s) m\u00e9dicale(s) suite au traitement du $date","en":"Medical reported outcomes following treatment on $date"}}};
export const PATIENT_SELF_EVALUATION_DATE_LAST = {"id":"patient.self_evaluation.date_last","translations":{"patient":{"fr":"Date des derni\u00e8res auto-\u00e9valuations","en":"Date of last reported outcome"}}};
export const PATIENT_SELF_EVALUATION_NO_REPORT = {"id":"patient.self_evaluation.no_report","translations":{"patient":{"fr":"Aucun rapport de sympt\u00f4mes","en":"No symptom reports"}}};
export const PATIENT_SELF_EVALUATION_ONGOING_QUESTIONNAIRE = {"id":"patient.self_evaluation.ongoing_questionnaire","translations":{"patient":{"fr":"Questionnaires en cours","en":"Ongoing questionnaires"}}};
export const PATIENT_SELF_EVALUATION_QUESTIONNAIRE_NOT_FOUND = {"id":"patient.self_evaluation.questionnaire_not_found","translations":{"patient":{"fr":"Aucun questionnaire associ\u00e9 au patient","en":"No questionnaire associated with the patient"}}};
export const PATIENT_SELF_EVALUATION_TITLE = {"id":"patient.self_evaluation.title","translations":{"patient":{"fr":"R\u00e9sultats","en":"Reported outcome Reports"}}};
export const PATIENT_SELF_EVALUATION_REPORT_OF = {"id":"patient.self_evaluation.report_of","translations":{"patient":{"fr":"R\u00e9sultats de $profile","en":"Reported outcome of $profile"}}};
export const PATIENT_SELF_EVALUATION_BACK_RECORD = {"id":"patient.self_evaluation.back_record","translations":{"patient":{"fr":"Retour au dossier","en":"Back to record"}}};
export const PATIENT_SELF_EVALUATION_REVIEWED = {"id":"patient.self_evaluation.reviewed","translations":{"patient":{"fr":"R\u00e9vis\u00e9","en":"Reviewed"}}};
export const PATIENT_SELF_EVALUATION_NOT_FOUND = {"id":"patient.self_evaluation.not_found","translations":{"patient":{"fr":"Aucune r\u00e9ponse trouv\u00e9e","en":"No responses found"}}};
export const PATIENT_SELF_EVALUATION_NOT_FOUND_QUESTIONNAIRE = {"id":"patient.self_evaluation.not_found_questionnaire","translations":{"patient":{"fr":"Aucun questionnaire trouv\u00e9","en":"No questionnaire found"}}};
export const PATIENT_SELF_EVALUATION_ALL = {"id":"patient.self_evaluation.all","translations":{"patient":{"fr":"Toutes les r\u00e9ponses","en":"All responses"}}};
export const PATIENT_SELF_EVALUATION_SEE = {"id":"patient.self_evaluation.see","translations":{"patient":{"fr":"Voir les r\u00e9ponses","en":"View responses"}}};
export const PATIENT_SELF_EVALUATION_HIDE = {"id":"patient.self_evaluation.hide","translations":{"patient":{"fr":"Masquer les r\u00e9ponses","en":"Hide responses"}}};
export const PATIENT_SELF_EVALUATION_LIST = {"id":"patient.self_evaluation.list","translations":{"patient":{"fr":"Liste des questionnaires de type :","en":"List of questionnaires of type:"}}};
export const PATIENT_SELF_EVALUATION_CREATE = {"id":"patient.self_evaluation.create","translations":{"patient":{"fr":"Cr\u00e9er un nouveau questionnaire","en":"Create a new questionnaire"}}};
export const PATIENT_SELF_EVALUATION_NAME = {"id":"patient.self_evaluation.name","translations":{"patient":{"fr":"Nom du questionnaire","en":"Questionnaire name"}}};
export const PATIENT_SELF_EVALUATION_EDITION = {"id":"patient.self_evaluation.edition","translations":{"patient":{"fr":"Modification du questionnaire","en":"Edit questionnaire"}}};
export const PATIENT_SELF_EVALUATION_CREATION = {"id":"patient.self_evaluation.creation","translations":{"patient":{"fr":"Cr\u00e9ation d'un nouveau questionnaire","en":"Create a new questionnaire"}}};
export const PATIENT_SELF_EVALUATION_TEST_MODE = {"id":"patient.self_evaluation.test_mode","translations":{"patient":{"fr":"Questionnaire : mode test","en":"Questionnaire: test mode"}}};
export const PATIENT_SELF_EVALUATION_THANKS = {"id":"patient.self_evaluation.thanks","translations":{"patient":{"fr":"Merci d'avoir rempli votre auto-\u00e9valuation. Ce questionnaire est une aide pr\u00e9cieuse pour votre \u00e9quipe soignante afin de mieux comprendre et suivre l'\u00e9volution de votre maladie.","en":"Thank you for completing your reported outcome. This questionnaire is a valuable tool for your healthcare team to better understand and monitor the progress of your condition."}}};
export const PATIENT_SELF_EVALUATION_MEDIC_INFO = {"id":"patient.self_evaluation.medic_info","translations":{"patient":{"fr":"Vous pouvez retrouver toutes vos informations m\u00e9dicales sur votre profil Instamed : ","en":"You can find all your medical information on your Instamed profile: "}}};
export const PATIENT_SELF_EVALUATION_ALREADY_FILL = {"id":"patient.self_evaluation.already_fill","translations":{"patient":{"fr":"Vous avez d\u00e9j\u00e0 rempli $count auto-\u00e9valuations sur ce traitement","en":"You have already completed $count reported outcome for this treatment"}}};
export const PATIENT_SELF_EVALUATION_ONE_FILL = {"id":"patient.self_evaluation.one_fill","translations":{"patient":{"fr":"Vous avez d\u00e9j\u00e0 rempli une auto-\u00e9valuation sur ce traitement","en":"You have already completed one reported outcome for this treatment"}}};
export const PATIENT_SELF_EVALUATION_NO_FILL = {"id":"patient.self_evaluation.no_fill","translations":{"patient":{"fr":"Vous n'avez rempli aucune une auto-\u00e9valuation sur ce traitement","en":"You haven't yet reported the outcome for this treatment."}}};
export const PATIENT_SELF_EVALUATION_NO_EVALUATION = {"id":"patient.self_evaluation.no_evaluation","translations":{"patient":{"fr":"Ce patient n'a pas encore rempli d'\u00e9valuation pour ce questionnaire.","en":"This patient has not yet completed an evaluation for this questionnaire."}}};
export const PATIENT_SELF_EVALUATION_SUBMITTED = {"id":"patient.self_evaluation.submitted","translations":{"patient":{"fr":"Vous avez pass\u00e9 votre \u00e9valuation de votre traitement. Vous pouvez le retrouver plus tard dans \"Ma sant\u00e9\" \"Auto-\u00e9valuation\"","en":"You have submitted your evaluation of your treatment. You can find it later in 'My Health' > 'reported outcome.'"}}};
export const PATIENT_SELF_EVALUATION_FILLED = {"id":"patient.self_evaluation.filled","translations":{"patient":{"fr":"Merci, votre questionnaire est d\u00e9sormais visible par votre \u00e9quipe soignante. Si besoin, un membre de votre \u00e9quipe vous contactera.","en":"Thank you, your questionnaire is now visible to your healthcare team. If necessary, a member of your team will contact you."}}};
export const PATIENT_SELF_EVALUATION_FILLED_WITH_DATE = {"id":"patient.self_evaluation.filled_with_date","translations":{"patient":{"fr":"Merci, votre questionnaire est d\u00e9sormais visible par votre \u00e9quipe soignante. Si besoin, un membre de votre \u00e9quipe vous contactera. Vous devez de nouveau y r\u00e9pondre le $date.","en":"Thank you, your questionnaire is now visible to your healthcare team. If necessary, a member of your team will contact you. You must complete it again on $date."}}};
export const PATIENT_SELF_EVALUATION_SHORT_FILLED = {"id":"patient.self_evaluation.short_filled","translations":{"patient":{"fr":"Le questionnaire d\u2019auto-\u00e9valuation a bien \u00e9t\u00e9 rempli","en":"The reported outcome questionnaire has been successfully completed"}}};
export const PATIENT_SELF_EVALUATION_ALREADY_FILLED = {"id":"patient.self_evaluation.already_filled","translations":{"patient":{"fr":"Vous avez d\u00e9j\u00e0 r\u00e9pondu \u00e0 ce questionnaire.","en":"You have already answered this questionnaire."}}};
export const PATIENT_SELF_EVALUATION_CAN_ANSWER_FROM = {"id":"patient.self_evaluation.can_answer_from","translations":{"patient":{"fr":"Vous pourrez y r\u00e9pondre \u00e0 nouveau \u00e0 partir du $date.","en":"You can answer it again from $date."}}};
export const PATIENT_SELF_EVALUATION_UNARCHIVED = {"id":"patient.self_evaluation.unarchived","translations":{"patient":{"fr":"Le questionnaire a bien \u00e9t\u00e9 d\u00e9sarchiv\u00e9","en":"The questionnaire has been successfully unarchived"}}};
export const PATIENT_SELF_EVALUATION_ARCHIVED = {"id":"patient.self_evaluation.archived","translations":{"patient":{"fr":"Le questionnaire a bien \u00e9t\u00e9 archiv\u00e9","en":"The questionnaire has been successfully archived"}}};
export const PATIENT_SELF_EVALUATION_DUPLICATED = {"id":"patient.self_evaluation.duplicated","translations":{"patient":{"fr":"Le questionnaire a bien \u00e9t\u00e9 dupliqu\u00e9","en":"The questionnaire has been successfully duplicated"}}};
export const PATIENT_SELF_EVALUATION_CONFIRM_DELETE = {"id":"patient.self_evaluation.confirm_delete","translations":{"patient":{"fr":"\u00cates-vous s\u00fbr de vouloir supprimer ce questionnaire ?","en":"Are you sure you want to delete this questionnaire?"}}};
export const PATIENT_SELF_EVALUATION_DELETED = {"id":"patient.self_evaluation.deleted","translations":{"patient":{"fr":"Le questionnaire a bien \u00e9t\u00e9 supprim\u00e9","en":"The questionnaire has been successfully deleted"}}};
export const PATIENT_SELF_EVALUATION_SAVED = {"id":"patient.self_evaluation.saved","translations":{"patient":{"fr":"Votre questionnaire \u00e0 bien \u00e9t\u00e9 enregistr\u00e9","en":"Your questionnaire has been successfully saved"}}};
export const PATIENT_SELF_EVALUATION_ACTIONS_SHOW_TABLE = {"id":"patient.self_evaluation.actions.show_table","translations":{"patient":{"fr":"Afficher le tableau","en":"View table"}}};
export const PATIENT_SELF_EVALUATION_ACTIONS_EDIT_QUESTIONNAIRE = {"id":"patient.self_evaluation.actions.edit_questionnaire","translations":{"patient":{"fr":"Modifier le questionnaire","en":"Edit questionnaire"}}};
export const PATIENT_SELF_EVALUATION_ACTIONS_SEE_LEGEND = {"id":"patient.self_evaluation.actions.see_legend","translations":{"patient":{"fr":"Voir la l\u00e9gende","en":"View legend"}}};
export const PATIENT_SELF_EVALUATION_ACTIONS_VIEW_TREATMENT = {"id":"patient.self_evaluation.actions.view_treatment","translations":{"patient":{"fr":"Comprendre mon traitement","en":"Understand my treatment"}}};
export const PATIENT_SELF_EVALUATION_ACTIONS_SELECT_ANSWER = {"id":"patient.self_evaluation.actions.select_answer","translations":{"patient":{"fr":"S\u00e9lectionner une r\u00e9ponse","en":"Select an answer"}}};
export const PATIENT_SELF_EVALUATION_MANAGE_VARIABLES_TITLE = {"id":"patient.self_evaluation.manage.variables.title","translations":{"patient":{"fr":"1 - D\u00e9finissez les variables du questionnaire","en":"1 - Define the questionnaire variables"}}};
export const PATIENT_SELF_EVALUATION_MANAGE_VARIABLES_SELECT_NAME = {"id":"patient.self_evaluation.manage.variables.select_name","translations":{"patient":{"fr":"Choisissez un nom pour votre questionnaire.","en":"Choose a name for your questionnaire."}}};
export const PATIENT_SELF_EVALUATION_MANAGE_VARIABLES_NAME_HELPER = {"id":"patient.self_evaluation.manage.variables.name_helper","translations":{"patient":{"fr":"Note : Ce nom sera visible par tous les utilisateurs (patients et professionnels de sant\u00e9)","en":"Note: This name will be visible to all users (patients and caregivers)."}}};
export const PATIENT_SELF_EVALUATION_MANAGE_VARIABLES_SELECT_LEGEND = {"id":"patient.self_evaluation.manage.variables.select_legend","translations":{"patient":{"fr":"D\u00e9finissez la l\u00e9gende du questionnaire.","en":"Define the questionnaire legend."}}};
export const PATIENT_SELF_EVALUATION_MANAGE_VARIABLES_LEGEND_HELPER = {"id":"patient.self_evaluation.manage.variables.legend_helper","translations":{"patient":{"fr":"Cette l\u00e9gende sera affich\u00e9e au dessus du questionnaire.","en":"This legend will be displayed above the questionnaire."}}};
export const PATIENT_SELF_EVALUATION_MANAGE_VARIABLES_MAIL_FREQUENCY = {"id":"patient.self_evaluation.manage.variables.mail_frequency","translations":{"patient":{"fr":"Fr\u00e9quence d'envoi des notifications","en":"Notification sending frequency"}}};
export const PATIENT_SELF_EVALUATION_MANAGE_VARIABLES_FREQUENCY_HELPER = {"id":"patient.self_evaluation.manage.variables.frequency_helper","translations":{"patient":{"fr":"Si vous associez ce questionnaire \u00e0 un patient, ce dernier recevra des notifications tous les X jours par e-mail, ou notification push afin d'y r\u00e9pondre.","en":"If you associate this questionnaire with a patient, they will receive notifications every X days by email or push notification to complete it."}}};
export const PATIENT_SELF_EVALUATION_MANAGE_VARIABLES_RESTRICTION = {"id":"patient.self_evaluation.manage.variables.restriction","translations":{"patient":{"fr":"Restriction de r\u00e9ponse","en":"Response restriction"}}};
export const PATIENT_SELF_EVALUATION_MANAGE_VARIABLES_HELPER_RESTRICTION = {"id":"patient.self_evaluation.manage.variables.helper_restriction","translations":{"patient":{"fr":"En cochant cette case, aucune r\u00e9ponse ne sera autoris\u00e9e au questionnaire avant les X prochains jours, correspondant \u00e0 la fr\u00e9quence, une fois qu'il a d\u00e9j\u00e0 \u00e9t\u00e9 r\u00e9pondu une fois.","en":"By checking this box, no responses will be allowed for the questionnaire before the next X days, corresponding to the frequency, once it has already been answered."}}};
export const PATIENT_SELF_EVALUATION_MANAGE_VARIABLES_HELPER_SERVICE = {"id":"patient.self_evaluation.manage.variables.helper_service","translations":{"patient":{"fr":"Ce questionnaire sera accessible uniquement aux utilisateurs de ce service.","en":"This questionnaire will only be accessible to users of this service."}}};
export const PATIENT_SELF_EVALUATION_MANAGE_VARIABLES_SPECIALTY = {"id":"patient.self_evaluation.manage.variables.specialty","translations":{"patient":{"fr":"Sp\u00e9cialit\u00e9s","en":"Specialties"}}};
export const PATIENT_SELF_EVALUATION_MANAGE_VARIABLES_SPECIALTY_HELPER = {"id":"patient.self_evaluation.manage.variables.specialty_helper","translations":{"patient":{"fr":"Seul le personnel soignant ayant l'une de ces sp\u00e9cialit\u00e9s aura acc\u00e8s \u00e0 ce formulaire.","en":"Only caregivers with one of these specialties will have access to this form."}}};
export const PATIENT_SELF_EVALUATION_MANAGE_VARIABLES_SELECT_TREATMENT = {"id":"patient.self_evaluation.manage.variables.select_treatment","translations":{"patient":{"fr":"Choisissez un traitement","en":"Choose treatment"}}};
export const PATIENT_SELF_EVALUATION_MANAGE_VARIABLES_TREATMENT_HELPER = {"id":"patient.self_evaluation.manage.variables.treatment_helper","translations":{"patient":{"fr":"Ce questionnaire sera associ\u00e9 par d\u00e9faut \u00e0 tous les patients qui suivent ces traitements.","en":"This questionnaire will be associated by default with all patients following these treatments."}}};
export const PATIENT_SELF_EVALUATION_MANAGE_VARIABLES_DEFAULT = {"id":"patient.self_evaluation.manage.variables.default","translations":{"patient":{"fr":"Questionnaire par d\u00e9faut","en":"Default questionnaire"}}};
export const PATIENT_SELF_EVALUATION_MANAGE_VARIABLES_DEFAULT_HELPER = {"id":"patient.self_evaluation.manage.variables.default_helper","translations":{"patient":{"fr":"En cochant cette case, ce questionnaire sera donn\u00e9 \u00e0 tous les patients n'ayant aucun questionnaire li\u00e9.","en":"By checking this box, this questionnaire will be assigned to all patients without a linked questionnaire."}}};
export const PATIENT_SELF_EVALUATION_MANAGE_VARIABLES_CONSULTATION_DEFAULT_HELPER = {"id":"patient.self_evaluation.manage.variables.consultation_default_helper","translations":{"patient":{"fr":"En cochant cette case, ce questionnaire sera accessible \u00e0 tous les soignant, m\u00eame ceux qui ont d\u00e9j\u00e0 des questionnaires li\u00e9s \u00e0 leur sp\u00e9cialit\u00e9.","en":"By checking this box, this questionnaire will be accessible to all healthcare professionals, even those who already have questionnaires related to their specialty."}}};
export const PATIENT_SELF_EVALUATION_MANAGE_QUESTIONS_WORD = {"id":"patient.self_evaluation.manage.questions.word","translations":{"patient":{"fr":"Question","en":"Question"}}};
export const PATIENT_SELF_EVALUATION_MANAGE_QUESTIONS_ADD = {"id":"patient.self_evaluation.manage.questions.add","translations":{"patient":{"fr":"Ajouter une question","en":"Add question"}}};
export const PATIENT_SELF_EVALUATION_MANAGE_QUESTIONS_TITLE = {"id":"patient.self_evaluation.manage.questions.title","translations":{"patient":{"fr":"2 - D\u00e9finissez les questions","en":"2 - Define questions"}}};
export const PATIENT_SELF_EVALUATION_MANAGE_QUESTIONS_HELPER = {"id":"patient.self_evaluation.manage.questions.helper","translations":{"patient":{"fr":"Dans cette partie, vous pouvez cr\u00e9er des sections contenant une ou plusieurs questions. Les sections vous permettent de structurer votre questionnaire. Vous pouvez \u00e0 tout moment d\u00e9placer vos sections \u00e0 l'aide des boutons <span class=\"material-icons\">keyboard_arrow_up<\/span> et <span class=\"material-icons\">keyboard_arrow_down<\/span>","en":"In this section, you can create sections containing one or more questions. Sections allow you to structure your questionnaire. You can move sections anytime using the buttons <span class='material-icons'>keyboard_arrow_up<\/span> and <span class='material-icons'>keyboard_arrow_down<\/span>."}}};
export const PATIENT_SELF_EVALUATION_MANAGE_QUESTIONS_BY_CLICKING = {"id":"patient.self_evaluation.manage.questions.by_clicking","translations":{"patient":{"fr":"En cliquant sur le bouton ","en":"By clicking the button"}}};
export const PATIENT_SELF_EVALUATION_MANAGE_QUESTIONS_CREATE_SECTION = {"id":"patient.self_evaluation.manage.questions.create_section","translations":{"patient":{"fr":" vous pouvez cr\u00e9er une nouvelle section.","en":" you can create a new section."}}};
export const PATIENT_SELF_EVALUATION_MANAGE_QUESTIONS_CREATE_QUESTION = {"id":"patient.self_evaluation.manage.questions.create_question","translations":{"patient":{"fr":" vous pouvez cr\u00e9er une nouvelle question.","en":" you can create a new question."}}};
export const PATIENT_SELF_EVALUATION_MANAGE_QUESTIONS_ADD_SECTION_AFTER_QUESTION = {"id":"patient.self_evaluation.manage.questions.add_section_after_question","translations":{"patient":{"fr":"Ajouter une section apr\u00e8s cette question","en":"Add section after this question"}}};
export const PATIENT_SELF_EVALUATION_MANAGE_QUESTIONS_ADD_SECTION = {"id":"patient.self_evaluation.manage.questions.add_section","translations":{"patient":{"fr":"Ajouter une section","en":"Add section"}}};
export const PATIENT_SELF_EVALUATION_MANAGE_QUESTIONS_SAVE = {"id":"patient.self_evaluation.manage.questions.save","translations":{"patient":{"fr":"Enregistrer le questionnaire","en":"Save the questionnaire"}}};
export const PATIENT_SELF_EVALUATION_MANAGE_QUESTIONS_TYPE = {"id":"patient.self_evaluation.manage.questions.type","translations":{"patient":{"fr":"Type de question","en":"Question type"}}};
export const PATIENT_SELF_EVALUATION_MANAGE_QUESTIONS_SCALE = {"id":"patient.self_evaluation.manage.questions.scale","translations":{"patient":{"fr":"Echelle (0 -> 4)","en":"Scale (0 -> 4)"}}};
export const PATIENT_SELF_EVALUATION_MANAGE_QUESTIONS_BIG_SCALE = {"id":"patient.self_evaluation.manage.questions.big_scale","translations":{"patient":{"fr":"Echelle (1 -> 10)","en":"Scale (1 -> 10)"}}};
export const PATIENT_SELF_EVALUATION_MANAGE_QUESTIONS_TEXT = {"id":"patient.self_evaluation.manage.questions.text","translations":{"patient":{"fr":"Texte libre (255 car.)","en":"Free text (255 characters)"}}};
export const PATIENT_SELF_EVALUATION_MANAGE_QUESTIONS_TEXT_AREA = {"id":"patient.self_evaluation.manage.questions.text_area","translations":{"patient":{"fr":"Zone de texte (255 car.)","en":"Text area (255 characters)"}}};
export const PATIENT_SELF_EVALUATION_MANAGE_QUESTIONS_NUMBER = {"id":"patient.self_evaluation.manage.questions.number","translations":{"patient":{"fr":"Nombre","en":"Number"}}};
export const PATIENT_SELF_EVALUATION_MANAGE_QUESTIONS_CHECKBOX = {"id":"patient.self_evaluation.manage.questions.checkbox","translations":{"patient":{"fr":"Oui \/ Non","en":"Yes \/ No"}}};
export const PATIENT_SELF_EVALUATION_MANAGE_QUESTIONS_MULTIPLE_CHOICE = {"id":"patient.self_evaluation.manage.questions.multiple_choice","translations":{"patient":{"fr":"Choix Multiple","en":"Multiple choice"}}};
export const PATIENT_SELF_EVALUATION_MANAGE_QUESTIONS_VARIABLES = {"id":"patient.self_evaluation.manage.questions.variables","translations":{"patient":{"fr":"Constante vitale","en":"Vital constant"}}};
export const PATIENT_SELF_EVALUATION_MANAGE_QUESTIONS_QUESTION_TITLE = {"id":"patient.self_evaluation.manage.questions.question_title","translations":{"patient":{"fr":"Intitul\u00e9","en":"Question title"}}};
export const PATIENT_SELF_EVALUATION_MANAGE_QUESTIONS_TITLE_PLACEHOLDER = {"id":"patient.self_evaluation.manage.questions.title_placeholder","translations":{"patient":{"fr":"Titre de la question","en":"Question title"}}};
export const PATIENT_SELF_EVALUATION_MANAGE_QUESTIONS_HELP_TEXT = {"id":"patient.self_evaluation.manage.questions.help_text","translations":{"patient":{"fr":"Texte d'aide","en":"Help text"}}};
export const PATIENT_SELF_EVALUATION_MANAGE_QUESTIONS_CHOICES_LIST = {"id":"patient.self_evaluation.manage.questions.choices_list","translations":{"patient":{"fr":"Liste des choix possible","en":"List of possible choices"}}};
export const PATIENT_SELF_EVALUATION_MANAGE_QUESTIONS_BY_LINE = {"id":"patient.self_evaluation.manage.questions.by_line","translations":{"patient":{"fr":"Un choix par ligne. ","en":"One choice per line."}}};
export const PATIENT_SELF_EVALUATION_MANAGE_QUESTIONS_HELPER_MULTICHOICE = {"id":"patient.self_evaluation.manage.questions.helper_multichoice","translations":{"patient":{"fr":"Les choix correspondent au sens de l'\u00e9chelle. 1er = 0, 2nd = 1...","en":"The choices correspond to the scale order. 1st = 0, 2nd = 1..."}}};
export const PATIENT_SELF_EVALUATION_MANAGE_QUESTIONS_CONSTANT_TYPE = {"id":"patient.self_evaluation.manage.questions.constant_type","translations":{"patient":{"fr":"Type de constante","en":"Constant type"}}};
export const PATIENT_SELF_EVALUATION_MANAGE_QUESTIONS_SELECT_CONSTANT = {"id":"patient.self_evaluation.manage.questions.select_constant","translations":{"patient":{"fr":"Choisissez le type de constante par la r\u00e9ponse \u00e0 cette question.","en":"Choose the type of constant based on the answer to this question."}}};
export const PATIENT_SELF_EVALUATION_MANAGE_QUESTIONS_MIN_VALUE = {"id":"patient.self_evaluation.manage.questions.min_value","translations":{"patient":{"fr":"Valeur minimale","en":"Minimum value"}}};
export const PATIENT_SELF_EVALUATION_MANAGE_QUESTIONS_MAX_VALUE = {"id":"patient.self_evaluation.manage.questions.max_value","translations":{"patient":{"fr":"Valeur maximale","en":"Maximum value"}}};
export const PATIENT_SELF_EVALUATION_MANAGE_QUESTIONS_STEP = {"id":"patient.self_evaluation.manage.questions.step","translations":{"patient":{"fr":"Pas entre 2 valeurs","en":"Step between two values"}}};
export const PATIENT_SELF_EVALUATION_MANAGE_QUESTIONS_REQUIRED = {"id":"patient.self_evaluation.manage.questions.required","translations":{"patient":{"fr":"Obligatoire","en":"Mandatory"}}};
export const PATIENT_SELF_EVALUATION_MANAGE_QUESTIONS_ALERTS = {"id":"patient.self_evaluation.manage.questions.alerts","translations":{"patient":{"fr":"Alertes li\u00e9es \u00e0 la question","en":"Alerts linked to the question"}}};
export const PATIENT_SELF_EVALUATION_MANAGE_QUESTIONS_HELPER_ALERTS = {"id":"patient.self_evaluation.manage.questions.helper_alerts","translations":{"patient":{"fr":"Les alertes li\u00e9es aux questions permettent de recevoir des notifications si certaines valeurs sont entr\u00e9es dans le questionnaire.","en":"Alerts linked to questions allow you to receive notifications if certain values are entered in the questionnaire."}}};
export const PATIENT_SELF_EVALUATION_MANAGE_QUESTIONS_ADD_ALERTS = {"id":"patient.self_evaluation.manage.questions.add_alerts","translations":{"patient":{"fr":"Ajouter une alerte","en":"Add alert"}}};
export const PATIENT_SELF_EVALUATION_MANAGE_QUESTIONS_DELETE_ALERT = {"id":"patient.self_evaluation.manage.questions.delete_alert","translations":{"patient":{"fr":"Supprimer l'alerte","en":"Delete alert"}}};
export const PATIENT_SELF_EVALUATION_MANAGE_QUESTIONS_PRIORITY = {"id":"patient.self_evaluation.manage.questions.priority","translations":{"patient":{"fr":"Priorit\u00e9","en":"Priority"}}};
export const PATIENT_SELF_EVALUATION_MANAGE_QUESTIONS_AVAILABLE = {"id":"patient.self_evaluation.manage.questions.available","translations":{"patient":{"fr":"Plusieurs priorit\u00e9s sont disponibles :","en":"Several priorities are available:"}}};
export const PATIENT_SELF_EVALUATION_MANAGE_QUESTIONS_LOW = {"id":"patient.self_evaluation.manage.questions.low","translations":{"patient":{"fr":"Basse","en":"Low"}}};
export const PATIENT_SELF_EVALUATION_MANAGE_QUESTIONS_LOW_HELPER = {"id":"patient.self_evaluation.manage.questions.low_helper","translations":{"patient":{"fr":"Une notification sera envoy\u00e9e \u00e0 l'\u00e9quipe soignante du patient.","en":"Notification will be sent to the patient's healthcare team."}}};
export const PATIENT_SELF_EVALUATION_MANAGE_QUESTIONS_MIDDLE = {"id":"patient.self_evaluation.manage.questions.middle","translations":{"patient":{"fr":"Moyenne","en":"Medium"}}};
export const PATIENT_SELF_EVALUATION_MANAGE_QUESTIONS_MIDDLE_HELPER = {"id":"patient.self_evaluation.manage.questions.middle_helper","translations":{"patient":{"fr":"Une notification et un e-mail seront envoy\u00e9s \u00e0 l'\u00e9quipe soignante du patient.","en":"Notification and email will be sent to the patient's healthcare team."}}};
export const PATIENT_SELF_EVALUATION_MANAGE_QUESTIONS_HIGH = {"id":"patient.self_evaluation.manage.questions.high","translations":{"patient":{"fr":"Elev\u00e9e","en":"High"}}};
export const PATIENT_SELF_EVALUATION_MANAGE_QUESTIONS_HIGH_HELPER = {"id":"patient.self_evaluation.manage.questions.high_helper","translations":{"patient":{"fr":"Une notification, un e-mail et un SMS seront envoy\u00e9s \u00e0 l'\u00e9quipe soignante du patient.","en":"Notification, email, and SMS will be sent to the patient's healthcare team."}}};
export const PATIENT_SELF_EVALUATION_MANAGE_QUESTIONS_CRITICAL = {"id":"patient.self_evaluation.manage.questions.critical","translations":{"patient":{"fr":"Critique","en":"Critical"}}};
export const PATIENT_SELF_EVALUATION_MANAGE_QUESTIONS_CRITICAL_HELPER = {"id":"patient.self_evaluation.manage.questions.critical_helper","translations":{"patient":{"fr":"Une notification, un e-mail et un SMS seront envoy\u00e9s \u00e0 l'\u00e9quipe soignante du patient. Une notification sera envoy\u00e9e au patient et un bandeau sera affich\u00e9 sur son profil lui demandant de prendre contact avec son \u00e9quipe soignante imm\u00e9diatement ou bien de se rendre \u00e0 son \u00e9tablissement de sant\u00e9.","en":"Notification, email, and SMS will be sent to the patient's healthcare team. Notification will also be sent to the patient, and banner will appear on their profile asking them to immediately contact their healthcare team or visit their health facility."}}};
export const PATIENT_SELF_EVALUATION_MANAGE_QUESTIONS_CONDITION = {"id":"patient.self_evaluation.manage.questions.condition","translations":{"patient":{"fr":"Condition","en":"Condition"}}};
export const PATIENT_SELF_EVALUATION_MANAGE_QUESTIONS_ADD_CONDITION = {"id":"patient.self_evaluation.manage.questions.add_condition","translations":{"patient":{"fr":"Ajouter une condition","en":"Add condition"}}};
export const PATIENT_SELF_EVALUATION_MANAGE_QUESTIONS_COMPARED_VALUE = {"id":"patient.self_evaluation.manage.questions.compared_value","translations":{"patient":{"fr":"Valeur compar\u00e9e","en":"Compared value"}}};
export const PATIENT_SELF_EVALUATION_MANAGE_QUESTIONS_CURRENT_VALUE = {"id":"patient.self_evaluation.manage.questions.current_value","translations":{"patient":{"fr":"Valeur actuelle","en":"Current value"}}};
export const PATIENT_SELF_EVALUATION_MANAGE_QUESTIONS_PREVIOUS_VALUE = {"id":"patient.self_evaluation.manage.questions.previous_value","translations":{"patient":{"fr":"Pr\u00e9c\u00e9dente valeur","en":"Previous value"}}};
export const PATIENT_SELF_EVALUATION_MANAGE_QUESTIONS_OR_OPERATOR = {"id":"patient.self_evaluation.manage.questions.or_operator","translations":{"patient":{"fr":"Au moins une des conditions est valide","en":"At least one of the conditions is valid"}}};
export const PATIENT_SELF_EVALUATION_MANAGE_QUESTIONS_AND_OPERATOR = {"id":"patient.self_evaluation.manage.questions.and_operator","translations":{"patient":{"fr":"Toutes les conditions sont valides","en":"All conditions are valid"}}};
export const PATIENT_SELF_EVALUATION_MANAGE_QUESTIONS_TRIGGER_CONDITIONS = {"id":"patient.self_evaluation.manage.questions.trigger_conditions","translations":{"patient":{"fr":"Condition(s) de d\u00e9clenchement","en":"Trigger conditions"}}};
export const PATIENT_SELF_EVALUATION_MANAGE_QUESTIONS_TRIGGER = {"id":"patient.self_evaluation.manage.questions.trigger","translations":{"patient":{"fr":"D\u00e9clencheur","en":"Trigger"}}};
export const PATIENT_SELF_EVALUATION_MANAGE_QUESTIONS_GREATER = {"id":"patient.self_evaluation.manage.questions.greater","translations":{"patient":{"fr":"Sup\u00e9rieure \u00e0","en":"Greater than"}}};
export const PATIENT_SELF_EVALUATION_MANAGE_QUESTIONS_GREATER_EQUAL = {"id":"patient.self_evaluation.manage.questions.greater_equal","translations":{"patient":{"fr":"Sup\u00e9rieure ou \u00e9gale \u00e0","en":"Greater than or equal to"}}};
export const PATIENT_SELF_EVALUATION_MANAGE_QUESTIONS_EQUAL = {"id":"patient.self_evaluation.manage.questions.equal","translations":{"patient":{"fr":"Egale \u00e0","en":"Equal to"}}};
export const PATIENT_SELF_EVALUATION_MANAGE_QUESTIONS_LOWER = {"id":"patient.self_evaluation.manage.questions.lower","translations":{"patient":{"fr":"Inf\u00e9rieure \u00e0","en":"Less than"}}};
export const PATIENT_SELF_EVALUATION_MANAGE_QUESTIONS_LOWER_EQUAL = {"id":"patient.self_evaluation.manage.questions.lower_equal","translations":{"patient":{"fr":"Inf\u00e9rieure ou \u00e9gale \u00e0","en":"Less than or equal to"}}};
export const PATIENT_SELF_EVALUATION_MANAGE_QUESTIONS_DIFFERENT = {"id":"patient.self_evaluation.manage.questions.different","translations":{"patient":{"fr":"Diff\u00e9rent de","en":"Different from"}}};
export const PATIENT_SELF_EVALUATION_MANAGE_QUESTIONS_EMPTY = {"id":"patient.self_evaluation.manage.questions.empty","translations":{"patient":{"fr":"Est Vide","en":"Is empty"}}};
export const PATIENT_SELF_EVALUATION_MANAGE_QUESTIONS_NOT_EMPTY = {"id":"patient.self_evaluation.manage.questions.not_empty","translations":{"patient":{"fr":"N'est pas vide","en":"Is not empty"}}};
export const PATIENT_SELF_EVALUATION_MANAGE_QUESTIONS_REMOVE_CONDITION = {"id":"patient.self_evaluation.manage.questions.remove_condition","translations":{"patient":{"fr":"Retirer la condition","en":"Remove condition"}}};
export const PATIENT_SELF_EVALUATION_MANAGE_SECTION_TITLE = {"id":"patient.self_evaluation.manage.section.title","translations":{"patient":{"fr":"Section","en":"Section"}}};
export const PATIENT_SELF_EVALUATION_MANAGE_SECTION_ADD = {"id":"patient.self_evaluation.manage.section.add","translations":{"patient":{"fr":"Ajouter une section","en":"Add section"}}};
export const PATIENT_SELF_EVALUATION_MANAGE_SECTION_ADD_QUESTION = {"id":"patient.self_evaluation.manage.section.add_question","translations":{"patient":{"fr":"Ajouter une question dans la section","en":"Add question in section"}}};
export const PATIENT_SELF_EVALUATION_MANAGE_SECTION_ADD_SECTION = {"id":"patient.self_evaluation.manage.section.add_section","translations":{"patient":{"fr":"Ajouter une section apr\u00e8s cette section","en":"Add section after this section"}}};
export const PATIENT_SELF_EVALUATION_MANAGE_SECTION_NAME = {"id":"patient.self_evaluation.manage.section.name","translations":{"patient":{"fr":"Nom de la section","en":"Section name"}}};
export const PATIENT_SELF_EVALUATION_MANAGE_SECTION_TITLE_SECTION = {"id":"patient.self_evaluation.manage.section.title_section","translations":{"patient":{"fr":"Titre de la section","en":"Section title"}}};
export const PATIENT_SELF_EVALUATION_MANAGE_SECTION_QUESTIONS = {"id":"patient.self_evaluation.manage.section.questions","translations":{"patient":{"fr":"Questions de la section","en":"Section questions"}}};
export const PATIENT_SELF_EVALUATION_MANAGE_SECTION_DESCRIPTION = {"id":"patient.self_evaluation.manage.section.description","translations":{"patient":{"fr":"Description de la section","en":"Section description"}}};
export const PATIENT_SELF_EVALUATION_MANAGE_SECTION_CONFIRM_DELETE = {"id":"patient.self_evaluation.manage.section.confirm_delete","translations":{"patient":{"fr":"\u00cates vous s\u00fbr de vouloir supprimer cette section ? Toutes les questions associ\u00e9es seront supprim\u00e9es.","en":"Are you sure you want to delete this section? All related questions will be deleted."}}};
export const PATIENT_CREATE_TAB_TITLE = {"id":"patient.create.tab_title","translations":{"patient":{"fr":"Cr\u00e9er un patient","en":"Create patient"}}};
export const PATIENT_CREATE_TITLE = {"id":"patient.create.title","translations":{"patient":{"fr":"Cr\u00e9er un nouveau patient","en":"New patient"}}};
export const PATIENT_CREATE_IMPORT_PATIENTS = {"id":"patient.create.import_patients","translations":{"patient":{"fr":"Importer des patients","en":"Import patients"}}};
export const PATIENT_CREATE_TEST_ACCOUNT = {"id":"patient.create.test_account","translations":{"patient":{"fr":"Compte de test","en":"Test account"}}};
export const PATIENT_CREATE_COMMUNICATION_HELP_TEXT = {"id":"patient.create.communication_help_text","translations":{"patient":{"fr":"En cochant cette case, le patient ne pourra pas recevoir de communication de la part d'Instamed. Vous pourrez le r\u00e9activer \u00e0 tout moment depuis son profil","en":"By checking this box, the patient will not receive any communication from Instamed. You can enable them at anytime from their medical record."}}};
export const PATIENT_CREATE_REQUEST = {"id":"patient.create.request","translations":{"patient":{"fr":"Vous souhaitez ?","en":"What would you like to do?"}}};
export const PATIENT_CREATE_FINALIZE = {"id":"patient.create.finalize","translations":{"patient":{"fr":"Finaliser la cr\u00e9ation d'un patient","en":"Finalize patient creation"}}};
export const PATIENT_CREATE_NO_FINALIZE = {"id":"patient.create.no_finalize","translations":{"patient":{"fr":"Aucun profil de patient n'est \u00e0 finalis\u00e9e","en":"No patient profile to finalize"}}};
export const PATIENT_CREATE_MEDICAL_COVERAGE_TYPE = {"id":"patient.create.medical_coverage_type","translations":{"patient":{"fr":"Type de couverture m\u00e9dicale","en":"Type of Medical Coverage"}}};
export const PATIENT_CREATE_MUTUAL = {"id":"patient.create.mutual","translations":{"patient":{"fr":"Mutuelle","en":"Mutual insurance"}}};
export const PATIENT_CREATE_MUTUAL_NUMBER = {"id":"patient.create.mutualNumber","translations":{"patient":{"fr":"N\u00b0 de mutuelle","en":"Mutual number"}}};
export const PATIENT_CREATE_WEIGHT = {"id":"patient.create.weight","translations":{"patient":{"fr":"Poids (en kg)","en":"Weight (in kg)"}}};
export const PATIENT_CREATE_HEIGHT = {"id":"patient.create.height","translations":{"patient":{"fr":"Taille (en cm)","en":"Height (in cm)"}}};
export const PATIENT_CREATE_REFERRING_DOCTOR = {"id":"patient.create.referring_doctor","translations":{"patient":{"fr":"M\u00e9decin r\u00e9f\u00e9rent","en":"Referring doctor"}}};
export const PATIENT_CREATE_REFERRING_PARAMEDICALS = {"id":"patient.create.referring_paramedicals","translations":{"patient":{"fr":"Param\u00e9dicaux r\u00e9f\u00e9rents","en":"Referring paramedics"}}};
export const PATIENT_DISEASE_TITLE = {"id":"patient.disease.title","translations":{"patient":{"fr":"Maladies","en":"Diseases"}}};
export const PATIENT_DISEASE_TITLE_HISTORY = {"id":"patient.disease.title_history","translations":{"patient":{"fr":"Maladies et Ant\u00e9c\u00e9dents","en":"Diseases and medical history"}}};
export const PATIENT_DISEASE_HISTORY_1 = {"id":"patient.disease.history","translations":{"patient":{"fr":"Ant\u00e9c\u00e9dent(s)","en":"History"}}};
export const PATIENT_DISEASE_CLICK_TO_EDIT = {"id":"patient.disease.click_to_edit","translations":{"patient":{"fr":"Cliquez sur la maladie pour \u00e9diter la partie \"comprendre ma maladie\"","en":"Click on disease to edit 'Understand my disease' section"}}};
export const PATIENT_DISEASE_ADD = {"id":"patient.disease.add","translations":{"patient":{"fr":"Ajouter une maladie","en":"Add disease"}}};
export const PATIENT_DISEASE_ADD_OTHER = {"id":"patient.disease.add_other","translations":{"patient":{"fr":"Ajouter une autre maladie","en":"Add another disease"}}};
export const PATIENT_DISEASE_NEW = {"id":"patient.disease.new","translations":{"patient":{"fr":"Nouvelle maladie","en":"New disease"}}};
export const PATIENT_DISEASE_DISEASE = {"id":"patient.disease.disease","translations":{"patient":{"fr":"Maladie","en":"Disease"}}};
export const PATIENT_DISEASE_TREATMENT = {"id":"patient.disease.treatment","translations":{"patient":{"fr":"Traitements","en":"Treatments"}}};
export const PATIENT_DISEASE_CONFIRM_DELETE = {"id":"patient.disease.confirm_delete","translations":{"patient":{"fr":"Veuillez confirmer la suppression ?","en":"Please confirm the deletion."}}};
export const PATIENT_DISEASE_NOT_FOUND = {"id":"patient.disease.not_found","translations":{"patient":{"fr":"Aucune maladie trouv\u00e9","en":"No disease found"}}};
export const PATIENT_DISEASE_EDIT = {"id":"patient.disease.edit","translations":{"patient":{"fr":"Modifier la maladie","en":"Edit disease"}}};
export const PATIENT_DISEASE_CREATE = {"id":"patient.disease.create","translations":{"patient":{"fr":"Cr\u00e9er une nouvelle maladie","en":"Create a new disease"}}};
export const PATIENT_DISEASE_DIAG_DATE = {"id":"patient.disease.diag_date","translations":{"patient":{"fr":"Date de diagnostic","en":"Diagnostic date"}}};
export const PATIENT_DISEASE_ASK_DELETE = {"id":"patient.disease.ask_delete","translations":{"patient":{"fr":"\u00cates-vous s\u00fbr de vouloir supprimer cette maladie","en":"Are you sure you want to delete this disease?"}}};
export const PATIENT_DISEASE_DELETED = {"id":"patient.disease.deleted","translations":{"patient":{"fr":"La maladie a bien \u00e9t\u00e9 supprim\u00e9","en":"The disease has been successfully deleted"}}};
export const PATIENT_DISEASE_END = {"id":"patient.disease.end","translations":{"patient":{"fr":"Terminer la maladie et basculer dans les ant\u00e9c\u00e9dents m\u00e9dicaux","en":"End the disease and move to medical history"}}};
export const PATIENT_DISEASE_SAVED = {"id":"patient.disease.saved","translations":{"patient":{"fr":"La maladie a bien \u00e9t\u00e9 enregistr\u00e9e","en":"The disease has been successfully saved"}}};
export const PATIENT_DISEASE_ONGOING = {"id":"patient.disease.ongoing","translations":{"patient":{"fr":"Maladie(s) en cours","en":"Ongoing diseases"}}};
export const PATIENT_DISEASE_NO_DISEASE = {"id":"patient.disease.no_disease","translations":{"patient":{"fr":"Aucune maladie renseign\u00e9e","en":"No disease provided"}}};
export const PATIENT_DISEASE_FILL = {"id":"patient.disease.fill","translations":{"patient":{"fr":"Veuillez saisir une maladie","en":"Please enter a disease"}}};
export const PATIENT_DISEASE_SEARCH = {"id":"patient.disease.search","translations":{"patient":{"fr":"Veuillez rechercher une maladie","en":"Please search for a disease"}}};
export const PATIENT_DISEASE_NARROW_THERAPEUTIC_GAP = {"id":"patient.disease.narrow_therapeutic_gap","translations":{"patient":{"fr":"marge th\u00e9rapeutique \u00e9troite","en":"Narrow therapeutic margin"}}};
export const PATIENT_DISEASE_FORMAL_CONTRAINDICATION = {"id":"patient.disease.formal_contraindication","translations":{"patient":{"fr":"Contre-indication formelle","en":"Formal contraindication"}}};
export const PATIENT_DISEASE_CHILD_GALENIC_FORM = {"id":"patient.disease.child_galenic_form","translations":{"patient":{"fr":"Enfant forme gal\u00e9nique","en":"Child galenic form"}}};
export const PATIENT_TRANSPORT_TITLE = {"id":"patient.transport.title","translations":{"patient":{"fr":"Mes transports","en":"My transport"}}};
export const PATIENT_TRANSPORT_CAN_BE_REFUND = {"id":"patient.transport.can_be_refund","translations":{"patient":{"fr":"Tous les transports en communs et en voiture particuli\u00e8re du domicile \u00e0 l\u2019h\u00f4pital aller et ou retour et les tickets de parcm\u00e8tre peuvent \u00eatre rembours\u00e9s. Renseignez-vous aupr\u00e8s de votre caisse de s\u00e9curit\u00e9 sociale.","en":"All public transport and personal vehicle trips to and from the hospital, as well as parking meter tickets, may be reimbursed. Check with your social security office."}}};
export const PATIENT_TRANSPORT_HOW_TO_REFUND = {"id":"patient.transport.how_to_refund","translations":{"patient":{"fr":"Vous devrez envoyer les justificatifs de transport, un bon de transport et un bulletin de pr\u00e9sence que vous demanderez \u00e0 la secr\u00e9taire hospitali\u00e8re le jour de votre venue.","en":"You will need to send transportation receipts, a transportation voucher, and a presence certificate that you can request from the hospital secretary on the day of your visit."}}};
export const PATIENT_TRANSPORT_DURING_TREATMENT = {"id":"patient.transport.during_treatment","translations":{"patient":{"fr":"Pendant vos traitements et en fonction de votre \u00e9tat g\u00e9n\u00e9ral, vous pourrez b\u00e9n\u00e9ficier d\u2019un transport en taxis conventionn\u00e9s sur prescription m\u00e9dicale.","en":"During your treatments, depending on your general condition, you may benefit from transportation by contracted taxis with a medical prescription."}}};
export const PATIENT_TRANSPORT_VOUCHER = {"id":"patient.transport.voucher","translations":{"patient":{"fr":"Ce bon de transport vous sera remis par la secr\u00e9taire hospitali\u00e8re du service pour le retour et le prochain aller si n\u00e9cessaire. Seuls les transports en taxis conventionn\u00e9s seront pris en charge par l'Assurance Maladie.","en":"This voucher will be provided by the hospital secretary for the return trip and the next necessary trip. Only contracted taxis are covered by Health Insurance."}}};
export const PATIENT_TRANSPORT_TAXI = {"id":"patient.transport.taxi","translations":{"patient":{"fr":"Vous pouvez trouver des artisans taxis conventionn\u00e9s en banlieue et province.","en":"You can find contracted taxi operators in suburbs and provinces."}}};
export const PATIENT_TRANSPORT_TAXI_LIST = {"id":"patient.transport.taxi_list","translations":{"patient":{"fr":"3 soci\u00e9t\u00e9s de taxis sur Paris et banlieue parisienne assurent des taxis conventionn\u00e9s :","en":"Three taxi companies in Paris and its suburbs provide contracted taxis:"}}};
export const PATIENT_TRANSPORT_ALPHA = {"id":"patient.transport.alpha","translations":{"patient":{"fr":"Alpha taxi : 01 45 85 85 85","en":"Alpha Taxi: 01 45 85 85 85"}}};
export const PATIENT_TRANSPORT_BLEU = {"id":"patient.transport.bleu","translations":{"patient":{"fr":"Taxi bleu : 01 49 36 10 10","en":"Taxi Bleu: 01 49 36 10 10"}}};
export const PATIENT_TRANSPORT_G7 = {"id":"patient.transport.g7","translations":{"patient":{"fr":"Taxi G7 : 01 47 39 47 39","en":"Taxi G7: 01 47 39 47 39"}}};
export const PATIENT_TRANSPORT_HEALTHCARE_PREPAYMENT = {"id":"patient.transport.healthcare_prepayment","translations":{"patient":{"fr":"Pour b\u00e9n\u00e9ficier de la prise en charge de l\u2019assurance Maladie et de la dispense d'avance des frais, vous devrez imp\u00e9rativement pr\u00e9senter au chauffeur de taxi :","en":"To benefit from Health Insurance coverage and avoid upfront costs, you must present the taxi driver with:"}}};
export const PATIENT_TRANSPORT_ATTESTATION = {"id":"patient.transport.attestation","translations":{"patient":{"fr":"une photocopie de votre attestation de droits en cours de validit\u00e9  avec votre adresse o\u00f9 figure votre prise en charge \u00e0 100 % (vous pouvez l\u2019imprimer sur ameli.fr)","en":"A photocopy of your valid entitlement certificate showing your 100% coverage (you can print it on ameli.fr)"}}};
export const PATIENT_TRANSPORT_PRESCRIPTION = {"id":"patient.transport.prescription","translations":{"patient":{"fr":"votre \u00ab prescription m\u00e9dicale de transport \u00bb","en":"Your 'medical transportation prescription'"}}};
export const PATIENT_TRANSPORT_HEALTHCARD = {"id":"patient.transport.healthcard","translations":{"patient":{"fr":"votre carte vitale","en":"Your Carte vitale"}}};
export const PATIENT_TRANSPORT_BILL = {"id":"patient.transport.bill","translations":{"patient":{"fr":"si le taxi demande le paiement de la course, ne remettez aucun de vos documents au taxi, r\u00e9cup\u00e9rez une facture acquitt\u00e9e avec mention \u00ab taxi conventionn\u00e9 \u00bb que vous adresserez \u00e0 votre caisse d\u2019assurance maladie avec la prescription m\u00e9dicale de transport et un bulletin de pr\u00e9sence demand\u00e9 \u00e0 la secr\u00e9taire hospitali\u00e8re.","en":"If the taxi demands payment for the ride, do not hand over any of your documents to the taxi. Request a paid invoice marked 'contracted taxi' and send it to your health insurance fund along with the medical transportation prescription and a presence certificate requested from the hospital secretary."}}};
export const PATIENT_TRANSPORT_WARNING = {"id":"patient.transport.warning","translations":{"patient":{"fr":"<b>Attention tout trajet aller simple \u00e9gal ou sup\u00e9rieur \u00e0 150km, quelques soit le moyen de transport,<\/b> fait l\u2019objet d\u2019une demande d\u2019entente pr\u00e9alable pour \u00eatre rembours\u00e9. Votre m\u00e9decin traitant en ville ou l\u2019h\u00e9matologue \u00e0 l\u2019h\u00f4pital doit remplir le formulaire que vous envoyez d\u00e9s le d\u00e9but de votre prise en charge hospitali\u00e8re \u00e0 votre caisse de s\u00e9curit\u00e9 sociale.","en":"<b>Attention! Any one-way trip of 150 km or more, regardless of the mode of transportation,<\/b> requires prior approval for reimbursement. Your general practitioner or hospital hematologist must complete the form and send it to your social security office at the beginning of your hospital care."}}};
export const PATIENT_SYMPTOMS_TITLE = {"id":"patient.symptoms.title","translations":{"patient":{"fr":"Mes sympt\u00f4mes","en":"My symptoms"}}};
export const PATIENT_SYMPTOMS_HELPER = {"id":"patient.symptoms.helper","translations":{"patient":{"fr":"Vous n'avez aucune \u00e9valuation \u00e0 remplir actuellement. <br\/> Merci de revenir sur cette page apr\u00e8s votre prochain traitement.","en":"You currently have no evaluation to complete. <br\/> Please return to this page after your next treatment."}}};
export const PATIENT_SYMPTOMS_SYMPTOMS_OF = {"id":"patient.symptoms.symptoms_of","translations":{"patient":{"fr":"Sympt\u00f4mes et effets secondaires de $name","en":"Symptoms and side effects of $name"}}};
export const PATIENT_SYMPTOMS_SELECT_QUESTIONNAIRE = {"id":"patient.symptoms.select_questionnaire","translations":{"patient":{"fr":"S\u00e9lectionnez un questionnaire","en":"Select questionnaire"}}};
export const PATIENT_SYMPTOMS_SELECT_HELPER = {"id":"patient.symptoms.select_helper","translations":{"patient":{"fr":"Merci de s\u00e9lectionner la ou les colonne(s) que vous souhaitez imprimer (celles non s\u00e9lectionn\u00e9es seront gris\u00e9es automatiquement).","en":"Please select the columns you wish to print (unselected ones will automatically be grayed out)."}}};
export const PATIENT_SYMPTOMS_FILL_DATE = {"id":"patient.symptoms.fill_date","translations":{"patient":{"fr":"Date de remplissage","en":"Fill date"}}};
export const PATIENT_TIPS_TITLE = {"id":"patient.tips.title","translations":{"patient":{"fr":"Astuces patients","en":"Patient tips"}}};
export const PATIENT_TIPS_SUGGEST = {"id":"patient.tips.suggest","translations":{"patient":{"fr":"Proposer une astuce","en":"Submit tip"}}};
export const PATIENT_TIPS_NEW = {"id":"patient.tips.new","translations":{"patient":{"fr":"Nouvelle Astuce","en":"New tip"}}};
export const PATIENT_TIPS_NOT_FOUND = {"id":"patient.tips.not_found","translations":{"patient":{"fr":"Pas d'astuces de patient en attente","en":"No patient tips pending approval"}}};
export const PATIENT_TIPS_HELPER = {"id":"patient.tips.helper","translations":{"patient":{"fr":"Vous retrouvez ici des astuces de patients ou soignants. Vous pouvez \u00e9galement ajouter de nouvelles astuces.","en":"Here you will find tips from patients or caregivers. You can also add new tips."}}};
export const PATIENT_TIPS_EDIT = {"id":"patient.tips.edit","translations":{"patient":{"fr":"Modifiez l'astuce n\u00b0$id","en":"Edit tip no. $id"}}};
export const PATIENT_TIPS_SUBMIT_HELPER = {"id":"patient.tips.submit_helper","translations":{"patient":{"fr":"Vous avez une astuce \u00e0 sugg\u00e9rer ? Soumettez-l\u00e0 \u00e0 la validation de l'\u00e9quipe et rendez la publique. Si vous le souhaitez, vous pouvez ajouter votre pr\u00e9nom et votre \u00e2ge.","en":"Do you have tip to suggest? Submit it for approval by the team and make it public. If you wish, you can add your first name and age."}}};
export const PATIENT_TIPS_SENT = {"id":"patient.tips.sent","translations":{"patient":{"fr":"Votre astuce a bien \u00e9t\u00e9 envoy\u00e9e au service. Elle devra \u00eatre valid\u00e9e pour figurer dans la FAQ","en":"Your tip has been successfully sent to the service. It must be approved to appear in the FAQ."}}};
export const PATIENT_TIPS_UPDATED = {"id":"patient.tips.updated","translations":{"patient":{"fr":"L'astuce a bien \u00e9t\u00e9 modifi\u00e9e","en":"The tip has been successfully updated."}}};
export const PATIENT_TIPS_CONFIRM_REJECT = {"id":"patient.tips.confirm_reject","translations":{"patient":{"fr":"\u00cates vous s\u00fbr de vouloir refuser cette astuce ?","en":"Are you sure you want to reject this tip?"}}};
export const PATIENT_TIPS_REJECTED = {"id":"patient.tips.rejected","translations":{"patient":{"fr":"L'astuce patient a bien \u00e9t\u00e9 rejet\u00e9e","en":"The patient tip has been successfully rejected."}}};
export const PATIENT_TIPS_CONFIRM_DELETE = {"id":"patient.tips.confirm_delete","translations":{"patient":{"fr":"\u00cates vous s\u00fbr de vouloir supprimer cette astuce ?","en":"Are you sure you want to delete this tip?"}}};
export const PATIENT_TIPS_DELETED = {"id":"patient.tips.deleted","translations":{"patient":{"fr":"L'astuce patient a bien \u00e9t\u00e9 supprim\u00e9e","en":"The patient tip has been successfully deleted."}}};
export const PATIENT_TIPS_VALIDATED = {"id":"patient.tips.validated","translations":{"patient":{"fr":"L'astuce patient a bien \u00e9t\u00e9 valid\u00e9e","en":"The patient tip has been successfully validated."}}};
export const PATIENT_PROFILE_NO_RESULT_DISCUSSION = {"id":"patient.profile.no_result.discussion","translations":{"patient":{"fr":"Aucune discussion li\u00e9e \u00e0 ce patient trouv\u00e9e","en":"No existing message related to this patient"}}};
export const PATIENT_CONSULTATIONS_ALL = {"id":"patient.consultations.all","translations":{"patient":{"fr":"Toutes les consultations","en":"All consultations"}}};
export const PATIENT_CONSULTATIONS_DOCTORS = {"id":"patient.consultations.doctors","translations":{"patient":{"fr":"Consultations des m\u00e9decins","en":"Doctors' consultations"}}};
export const PATIENT_CONSULTATIONS_PARAMEDICS = {"id":"patient.consultations.paramedics","translations":{"patient":{"fr":"Consultations des param\u00e9dicaux + coordinateurs","en":"Paramedic + coordinators' consultations"}}};
export const PATIENT_CONSULTATIONS_DATE_EDITED = {"id":"patient.consultations.date_edited","translations":{"patient":{"fr":"La date de la consultation a \u00e9t\u00e9 modifi\u00e9e","en":"The consultation date has been modified."}}};
export const PATIENT_CONSULTATIONS_CONFIRM_DELETE = {"id":"patient.consultations.confirm_delete","translations":{"patient":{"fr":"\u00cates-vous s\u00fbr de supprimer cette consultation ? Tous les documents attach\u00e9s seront supprim\u00e9s","en":"Are you sure you want to delete this consultation? All attached documents will be deleted."}}};
export const PATIENT_CONSULTATIONS_DELETED = {"id":"patient.consultations.deleted","translations":{"patient":{"fr":"La consultation a bien \u00e9t\u00e9 supprim\u00e9e","en":"The consultation has been successfully deleted."}}};
export const PATIENT_CONSULTATIONS_NEW = {"id":"patient.consultations.new","translations":{"patient":{"fr":"Nouvelle consultation","en":"New consultation"}}};
export const PATIENT_CONSULTATIONS_LOADING = {"id":"patient.consultations.loading","translations":{"patient":{"fr":"Chargement de la consultation du jour","en":"Loading today's consultation"}}};
export const PATIENT_CONSULTATIONS_FOUND = {"id":"patient.consultations.found","translations":{"patient":{"fr":"Une consultation a \u00e9t\u00e9 trouv\u00e9e pour aujourd'hui, redirection sur le profil du patient en cours","en":"A consultation has been found for today, redirecting to patient's profile."}}};
export const PATIENT_CONSULTATIONS_NOT_FOUND = {"id":"patient.consultations.not_found","translations":{"patient":{"fr":"Une consultation a \u00e9t\u00e9 trouv\u00e9e pour aujourd'hui, redirection sur le profil du patient en cours","en":"A consultation has been found for today, redirecting to patient's profile."}}};
export const PATIENT_CONSULTATIONS_CREATED = {"id":"patient.consultations.created","translations":{"patient":{"fr":"Consultation cr\u00e9\u00e9e, redirection sur le profil du patient en cours","en":"Consultation created, redirecting to the patient's profile."}}};
export const PATIENT_CONSULTATIONS_CREATED_FOR = {"id":"patient.consultations.created_for","translations":{"patient":{"fr":"La consultation sera cr\u00e9\u00e9e pour $name","en":"The consultation will be created for $name"}}};
export const PATIENT_CONSULTATIONS_SELECT_OWNER = {"id":"patient.consultations.select_owner","translations":{"patient":{"fr":"Choisissez le propri\u00e9taire de cette consultation","en":"Select owner of this consultation"}}};
export const PATIENT_CONSULTATIONS_SELECT_DOCTOR = {"id":"patient.consultations.select_doctor","translations":{"patient":{"fr":"Choisissez le m\u00e9decin li\u00e9 \u00e0 ce patient","en":"Select doctor linked to this patient"}}};
export const PATIENT_CONSULTATIONS_SELECT_PATIENT = {"id":"patient.consultations.select_patient","translations":{"patient":{"fr":"Choisissez le patient que vous consultez","en":"Select patient you are consulting"}}};
export const PATIENT_CONSULTATIONS_ADD_IN = {"id":"patient.consultations.add_in","translations":{"patient":{"fr":"Ajouter dans la consultation","en":"Add to consultation"}}};
export const PATIENT_CONSULTATIONS_FILES_ADDED = {"id":"patient.consultations.files_added","translations":{"patient":{"fr":"Les fichiers seront ajout\u00e9s dans la consultation","en":"Files will be added to the consultation"}}};
export const MEDICAL_TEAM_OF = {"id":"medical_team_of","translations":{"patient_medical_team":{"fr":"Equipe m\u00e9dicale de {{ user }}","en":"Care team of {{ user }}"}}};
export const MY_MEDICAL_TEAM = {"id":"my_medical_team","translations":{"patient_medical_team":{"fr":"Mon \u00e9quipe soignante","en":"My care team"}}};
export const RETURN_TO_RECORD = {"id":"return_to_record","translations":{"patient_medical_team":{"fr":"Retour au dossier","en":"Back to record"}}};
export const LEAVE_MEDICAL_TEAM = {"id":"leave_medical_team","translations":{"patient_medical_team":{"fr":"Quitter l'\u00e9quipe soignante","en":"Leave care team"}}};
export const JOIN_MEDICAL_TEAM = {"id":"join_medical_team","translations":{"patient_medical_team":{"fr":"Rejoindre l'\u00e9quipe soignante","en":"Join care team"}}};
export const HEALTH_PROFESSIONALS = {"id":"health_professionals","translations":{"patient_medical_team":{"fr":"Professionnels de sant\u00e9","en":"Caregivers"}}};
export const INVITE_DOCTOR = {"id":"invite_doctor","translations":{"patient_medical_team":{"fr":"Inviter un m\u00e9decin","en":"Invite doctor"}}};
export const ADD_MEMBER = {"id":"add_member","translations":{"patient_medical_team":{"fr":"Ajouter un membre","en":"Add member"}}};
export const ADD_DOCTOR = {"id":"add_doctor","translations":{"patient_medical_team":{"fr":"Ajouter un m\u00e9decin","en":"Add doctor"}}};
export const ADD_PARAMEDIC = {"id":"add_paramedic","translations":{"patient_medical_team":{"fr":"Ajouter un param\u00e9dical","en":"Add paramedic"}}};
export const NO_PERMISSION_TO_SHARE = {"id":"no_permission_to_share","translations":{"patient_medical_team":{"fr":"Ce patient ne vous autorise pas \u00e0 partager son dossier m\u00e9dical.","en":"This patient does not allow you to share his medical record."}}};
export const EMERGENCY_CONTACT = {"id":"emergency_contact","translations":{"patient_medical_team":{"fr":"Contact en cas d'urgence","en":"Emergency contact"}}};
export const SEND_MESSAGE = {"id":"send_message","translations":{"patient_medical_team":{"fr":"Envoyer un message","en":"Send message"}}};
export const DEFINE_AS_MY_PRIMARY_DOCTOR = {"id":"define_as_my_primary_doctor","translations":{"patient_medical_team":{"fr":"D\u00e9finir comme mon m\u00e9decin traitant","en":"Set as my primary doctor"}}};
export const DEFINE_AS_PRIMARY_DOCTOR = {"id":"define_as_primary_doctor","translations":{"patient_medical_team":{"fr":"D\u00e9finir comme m\u00e9decin traitant","en":"Set as primary doctor"}}};
export const NO_LONGER_MY_PRIMARY_DOCTOR = {"id":"no_longer_my_primary_doctor","translations":{"patient_medical_team":{"fr":"Ce n'est plus mon m\u00e9decin traitant","en":"This is no longer my primary doctor"}}};
export const REMOVE_AS_PRIMARY_DOCTOR = {"id":"remove_as_primary_doctor","translations":{"patient_medical_team":{"fr":"Retirer en tant que m\u00e9decin traitant","en":"Remove as a primary doctor"}}};
export const PROVIDE_MISSING_INFO = {"id":"provide_missing_info","translations":{"patient_medical_team":{"fr":"Renseigner les informations manquantes","en":"Fill in missing information"}}};
export const REMOVE_FROM_MY_TEAM = {"id":"remove_from_my_team","translations":{"patient_medical_team":{"fr":"Retirer de mon \u00e9quipe soignante","en":"Remove from my care team"}}};
export const VIEW_PROFILE = {"id":"view_profile","translations":{"patient_medical_team":{"fr":"Voir le profil","en":"View profile"}}};
export const MAKE_APPOINTMENT = {"id":"make_appointment","translations":{"patient_medical_team":{"fr":"Prendre rendez-vous","en":"Book appointment"}}};
export const REMOVE_FROM_TEAM = {"id":"remove_from_team","translations":{"patient_medical_team":{"fr":"Retirer de l'\u00e9quipe soignante","en":"Remove from care team"}}};
export const ROLE = {"id":"role","translations":{"patient_medical_team":{"fr":"R\u00f4le","en":"Role"}}};
export const SPECIALTIES = {"id":"specialties","translations":{"patient_medical_team":{"fr":"Sp\u00e9cialit\u00e9(s)","en":"Specialities"}}};
export const SERVICES = {"id":"services","translations":{"patient_medical_team":{"fr":"Service(s)","en":"Departments"}}};
export const EDIT_CONTACT = {"id":"edit_contact","translations":{"patient_medical_team":{"fr":"Modifier le contact","en":"Edit contact"}}};
export const DELETE = {"id":"delete","translations":{"patient_medical_team":{"fr":"Supprimer","en":"Delete"}}};
export const NO_EMERGENCY_CONTACT = {"id":"no_emergency_contact","translations":{"patient_medical_team":{"fr":"Vous n'avez pas de contact en cas d'urgence.","en":"You have no emergency contact."}}};
export const ADD_NEW_CONTACT = {"id":"add_new_contact","translations":{"patient_medical_team":{"fr":"Ajouter un nouveau contact","en":"New contact"}}};
export const NO_DOCTOR_IN_TEAM = {"id":"no_doctor_in_team","translations":{"patient_medical_team":{"fr":"Vous n'avez pas de m\u00e9decin dans votre \u00e9quipe soignante.","en":"You do not have doctor in your care team."}}};
export const FIRST_NAME = {"id":"first_name","translations":{"patient_medical_team":{"fr":"Pr\u00e9nom","en":"First name"}}};
export const LAST_NAME = {"id":"last_name","translations":{"patient_medical_team":{"fr":"Nom","en":"Last name"}}};
export const EMAIL = {"id":"email","translations":{"patient_medical_team":{"fr":"E-mail","en":"Email"}}};
export const RELATIONSHIP = {"id":"relationship","translations":{"patient_medical_team":{"fr":"Lien de parent\u00e9","en":"Family relationship"}}};
export const OTHER_RELATIONSHIP = {"id":"other_relationship","translations":{"patient_medical_team":{"fr":"Autre lien de parent\u00e9"}}};
export const OTHER_RELATIONSHIP_PLACEHOLDER = {"id":"other_relationship_placeholder","translations":{"patient_medical_team":{"fr":"Sp\u00e9cifiez le lien de parent\u00e9"}}};
export const PHONE = {"id":"phone","translations":{"patient_medical_team":{"fr":"T\u00e9l\u00e9phone","en":"Phone number"}}};
export const VALIDATE = {"id":"validate","translations":{"patient_medical_team":{"fr":"Valider","en":"Save"}}};
export const TRUSTED_PERSON = {"id":"trusted_person","translations":{"patient_medical_team":{"fr":"Personne de confiance","en":"Trusted person"}}};
export const LEARN_MORE = {"id":"learn_more","translations":{"patient_medical_team":{"fr":"Pour en savoir plus sur la personne de confiance,","en":"To learn more about the trusted person,"}}};
export const LEARN_MORE_CLICK = {"id":"learn_more_click","translations":{"patient_medical_team":{"fr":"cliquez ici","en":"click here"}}};
export const VISIBILITY8 = {"id":"visibility.8","translations":{"patient_note":{"fr":"Visible par mon \u00e9quipe soignante et moi","en":"Visible by my care team and me"}}};
export const VISIBILITY9 = {"id":"visibility.9","translations":{"patient_note":{"fr":"Visible seulement par moi","en":"Visible only by me"}}};
export const VISIBILITY_VALUES8 = {"id":"visibility.values.8","translations":{"patient_synopsis":{"fr":"Visible par mon \u00e9quipe soignante et moi","en":"Visible only by my care team and me"}}};
export const VISIBILITY_VALUES9 = {"id":"visibility.values.9","translations":{"patient_synopsis":{"fr":"Visible seulement par moi","en":"Visible only by me"}}};
export const VISIBILITY_VALUES10 = {"id":"visibility.values.10","translations":{"patient_synopsis":{"fr":"Visible seulement par les soignants de ma sp\u00e9cialit\u00e9","en":"Visible only by caregivers of my specialty"}}};
export const TREATMENT_TYPE_DESCRIPTION = {"id":"treatment_type.description","translations":{"patient_treatment":{"fr":"Le type de traitement","en":"The type of treatment"}}};
export const TREATMENT_TYPE_LABEL = {"id":"treatment_type.label","translations":{"patient_treatment":{"fr":"Type","en":"Type"}}};
export const TREATMENT_TYPE_PLACEHOLDER = {"id":"treatment_type.placeholder","translations":{"patient_treatment":{"fr":"Choisissez un type de traitement","en":"Choose type of treatment"}}};
export const TREATMENT_TYPE_VALUES_BACKGROUND = {"id":"treatment_type.values.background","translations":{"patient_treatment":{"fr":"Traitement de fond","en":"Long-term treatment"}}};
export const TREATMENT_TYPE_VALUES_SPOT = {"id":"treatment_type.values.spot","translations":{"patient_treatment":{"fr":"Traitement ponctuel","en":"One-time treatment"}}};
export const DRUG_DATABASE_DESCRIPTION = {"id":"drug_database.description","translations":{"patient_treatment":{"fr":"Le type de base de donn\u00e9e dans laquelle le traitement est pris","en":"The Datababase from which the treatment is taken"}}};
export const DRUG_DATABASE_LABEL = {"id":"drug_database.label","translations":{"patient_treatment":{"fr":"Choisissez la base de donn\u00e9es de d\u00e9part","en":"Choose your database"}}};
export const DRUG_DATABASE_PLACEHOLDER = {"id":"drug_database.placeholder","translations":{"patient_treatment":{"fr":"Choisissez la base de donn\u00e9es de d\u00e9part","en":"Choose your database"}}};
export const DRUG_DATABASE_VALUES_INSTAMED = {"id":"drug_database.values.instamed","translations":{"patient_treatment":{"fr":"Base Instamed","en":"Instamed database"}}};
export const DRUG_DATABASE_VALUES_CCAM = {"id":"drug_database.values.ccam","translations":{"patient_treatment":{"fr":"Base CCAM","en":"CCAM Database"}}};
export const DRUG_DATABASE_VALUES_SYNAPSE = {"id":"drug_database.values.synapse","translations":{"patient_treatment":{"fr":"M\u00e9dicaments","en":"Synapse"}}};
export const DRUG_DATABASE_VALUES_INSTAMED_DRUGS = {"id":"drug_database.values.instamed_drugs","translations":{"patient_treatment":{"fr":"M\u00e9dicaments"}}};
export const DRUG_DATABASE_VALUES_CUSTOM = {"id":"drug_database.values.custom","translations":{"patient_treatment":{"fr":"Saisie libre","en":"Free input"}}};
export const PATIENT_TREATMENT_POSOLOGY_RECURRENCES = {"id":"patient_treatment.posology.recurrences","translations":{"patient_treatment":{"fr":"D\u00e9livr\u00e9 %count% fois,","en":"Delivered %count% times,"}}};
export const PATIENT_TREATMENT_POSOLOGY_FREQUENCY_DAILY = {"id":"patient_treatment.posology.frequency.daily","translations":{"patient_treatment":{"fr":"tous les jours","en":"every day"}}};
export const PATIENT_TREATMENT_POSOLOGY_FREQUENCY_EVERY_X_DAYS = {"id":"patient_treatment.posology.frequency.every_x_days","translations":{"patient_treatment":{"fr":"tous les %days% jours","en":"every %days% days"}}};
export const SYNAPSE_DESCRIPTION = {"id":"synapse.description","translations":{"pdf_template":{"fr":"Le mod\u00e8le de document est li\u00e9 \u00e0 Synapse","en":"The document template is linked to Synapse"}}};
export const SYNAPSE_LABEL = {"id":"synapse.label","translations":{"pdf_template":{"fr":"Synapse","en":"Synapse"}}};
export const SYNAPSE_PLACEHOLDER = {"id":"synapse.placeholder","translations":{"pdf_template":{"fr":"S\u00e9lectionnez une valeur","en":"Select value"}}};
export const SYNAPSE_VALUES0 = {"id":"synapse.values.0","translations":{"pdf_template":{"fr":"Non","en":"No"}}};
export const SYNAPSE_VALUES1 = {"id":"synapse.values.1","translations":{"pdf_template":{"fr":"Oui","en":"Yes"}}};
export const MONTH = {"id":"month","translations":{"pricing":{"fr":"mois","en":"month"},"stripe":{"fr":"mois","en":"month"}}};
export const YEAR = {"id":"year","translations":{"pricing":{"fr":"an","en":"year"},"stripe":{"fr":"an","en":"year"}}};
export const PSC_ERROR_ONLY_DOCTORS_ALLOWED = {"id":"psc.error.only_doctors_allowed","translations":{"pro_sante_connect":{"fr":"Seuls les m\u00e9decins sont autoris\u00e9s \u00e0 acc\u00e9der \u00e0 cette application via Pro Sant\u00e9 Connect.","en":"Only doctors are authorized to access this application by Pro Sant\u00e9 Connect."}}};
export const STATUS7 = {"id":"status.7","translations":{"profile":{"fr":"Blacklist\u00e9","en":"Blacklisted"},"task":{"fr":"Trait\u00e9","en":"Processed"}}};
export const STATUS8 = {"id":"status.8","translations":{"profile":{"fr":"D\u00e9c\u00e9d\u00e9","en":"Deceased"}}};
export const STATUS10 = {"id":"status.10","translations":{"profile":{"fr":"En attente de validation du document d'identit\u00e9","en":"Pending identity document validation"}}};
export const STATUS31 = {"id":"status.31","translations":{"profile":{"fr":"En attente de paiement","en":"Pending payment"}}};
export const STATUS_MEDIC1 = {"id":"status.medic.1","translations":{"profile":{"fr":"Activ\u00e9","en":"Activated"}}};
export const STATUS_MEDIC2 = {"id":"status.medic.2","translations":{"profile":{"fr":"Archiv\u00e9","en":"Archived"}}};
export const STATUS_MEDIC3 = {"id":"status.medic.3","translations":{"profile":{"fr":"Supprim\u00e9","en":"Deleted"}}};
export const STATUS_MEDIC6 = {"id":"status.medic.6","translations":{"profile":{"fr":"Incomplet","en":"Incomplete"}}};
export const STATUS_MEDIC9 = {"id":"status.medic.9","translations":{"profile":{"fr":"\u00c0 activer","en":"To activate"}}};
export const STATUS_MEDIC10 = {"id":"status.medic.10","translations":{"profile":{"fr":"En attente de validation du document d'identit\u00e9","en":"Pending identity document validation"}}};
export const STATUS_MEDIC31 = {"id":"status.medic.31","translations":{"profile":{"fr":"En attente de paiement","en":"Pending payment"}}};
export const STATUS_MEDIC32 = {"id":"status.medic.32","translations":{"profile":{"fr":"Invit\u00e9 - En attente de finalisation du profil","en":"Guest - Awaiting profile completion"}}};
export const FREQUENCY_ACTIVITY0 = {"id":"frequency.activity.0","translations":{"profile":{"fr":"Jamais","en":"Never"}}};
export const FREQUENCY_ACTIVITY1 = {"id":"frequency.activity.1","translations":{"profile":{"fr":"\u00c0 l'occasion","en":"Occasionally"}}};
export const FREQUENCY_ACTIVITY2 = {"id":"frequency.activity.2","translations":{"profile":{"fr":"Une fois par semaine","en":"Once a week"}}};
export const FREQUENCY_ACTIVITY3 = {"id":"frequency.activity.3","translations":{"profile":{"fr":"Plusieurs fois par semaine","en":"Several times a week"}}};
export const FAMILY_SITUATION_OLD_VALUE = {"id":"family_situation.old_value","translations":{"profile":{"fr":"Ancienne valeur: \"{{ oldValue }}\"","en":"Old value: \"{{ oldValue }}\""}}};
export const LANGUAGES_DESCRIPTION = {"id":"languages.description","translations":{"public_profile":{"fr":"Les langues parl\u00e9es par le m\u00e9decin","en":"Languages spoken by the doctor"}}};
export const LANGUAGES_LABEL = {"id":"languages.label","translations":{"public_profile":{"fr":"Langues parl\u00e9es","en":"Languages spoken"}}};
export const LANGUAGES_PLACEHOLDER = {"id":"languages.placeholder","translations":{"public_profile":{"fr":"Choisissez une ou plusieurs langues...","en":"Choose one or more languages..."}}};
export const LANGUAGES_VALUES_AF = {"id":"languages.values.af","translations":{"public_profile":{"fr":"Afrikaans","en":"Afrikaans"}}};
export const LANGUAGES_VALUES_AK = {"id":"languages.values.ak","translations":{"public_profile":{"fr":"Akan","en":"Akan"}}};
export const LANGUAGES_VALUES_SQ = {"id":"languages.values.sq","translations":{"public_profile":{"fr":"Albanais","en":"Albanian"}}};
export const LANGUAGES_VALUES_DE = {"id":"languages.values.de","translations":{"public_profile":{"fr":"Allemand","en":"German"}}};
export const LANGUAGES_VALUES_GSW = {"id":"languages.values.gsw","translations":{"public_profile":{"fr":"Alsacien","en":"Alsatian"}}};
export const LANGUAGES_VALUES_EN = {"id":"languages.values.en","translations":{"public_profile":{"fr":"Anglais","en":"English"}}};
export const LANGUAGES_VALUES_AR = {"id":"languages.values.ar","translations":{"public_profile":{"fr":"Arabe","en":"Arabic"}}};
export const LANGUAGES_VALUES_ARC = {"id":"languages.values.arc","translations":{"public_profile":{"fr":"Aram\u00e9en","en":"Aramaic"}}};
export const LANGUAGES_VALUES_HY = {"id":"languages.values.hy","translations":{"public_profile":{"fr":"Arm\u00e9nien","en":"Armenian"}}};
export const LANGUAGES_VALUES_AY = {"id":"languages.values.ay","translations":{"public_profile":{"fr":"Aymara","en":"Aymara"}}};
export const LANGUAGES_VALUES_AZ = {"id":"languages.values.az","translations":{"public_profile":{"fr":"Az\u00e9ri","en":"Azerbaijani"}}};
export const LANGUAGES_VALUES_BM = {"id":"languages.values.bm","translations":{"public_profile":{"fr":"Bambara","en":"Bambara"}}};
export const LANGUAGES_VALUES_EU = {"id":"languages.values.eu","translations":{"public_profile":{"fr":"Basque","en":"Basque"}}};
export const LANGUAGES_VALUES_BN = {"id":"languages.values.bn","translations":{"public_profile":{"fr":"Bengali","en":"Bengali"}}};
export const LANGUAGES_VALUES_BER = {"id":"languages.values.ber","translations":{"public_profile":{"fr":"Berb\u00e8re","en":"Berber"}}};
export const LANGUAGES_VALUES_MY = {"id":"languages.values.my","translations":{"public_profile":{"fr":"Birman","en":"Burmese"}}};
export const LANGUAGES_VALUES_BE = {"id":"languages.values.be","translations":{"public_profile":{"fr":"Bi\u00e9lorusse","en":"Belarusian"}}};
export const LANGUAGES_VALUES_BS = {"id":"languages.values.bs","translations":{"public_profile":{"fr":"Bosnien","en":"Bosnian"}}};
export const LANGUAGES_VALUES_BR = {"id":"languages.values.br","translations":{"public_profile":{"fr":"Breton","en":"Breton"}}};
export const LANGUAGES_VALUES_BG = {"id":"languages.values.bg","translations":{"public_profile":{"fr":"Bulgare","en":"Bulgarian"}}};
export const LANGUAGES_VALUES_CA = {"id":"languages.values.ca","translations":{"public_profile":{"fr":"Caralan","en":"Caralan"}}};
export const LANGUAGES_VALUES_ZH = {"id":"languages.values.zh","translations":{"public_profile":{"fr":"Chinois (Mandarin)","en":"Chinese (Mandarin)"}}};
export const LANGUAGES_VALUES_CO = {"id":"languages.values.co","translations":{"public_profile":{"fr":"Corse","en":"Corsican"}}};
export const LANGUAGES_VALUES_KO = {"id":"languages.values.ko","translations":{"public_profile":{"fr":"Cor\u00e9en","en":"Korean"}}};
export const LANGUAGES_VALUES_HR = {"id":"languages.values.hr","translations":{"public_profile":{"fr":"Croate","en":"Croatian"}}};
export const LANGUAGES_VALUES_ACF = {"id":"languages.values.acf","translations":{"public_profile":{"fr":"Cr\u00e9ole Antillais","en":"Antillean Creole"}}};
export const LANGUAGES_VALUES_HT = {"id":"languages.values.ht","translations":{"public_profile":{"fr":"Cr\u00e9ole ha\u00eftien","en":"Haitian Creole"}}};
export const LANGUAGES_VALUES_MCF = {"id":"languages.values.mcf","translations":{"public_profile":{"fr":"Cr\u00e9ole mauricien","en":"Mauritian Creole"}}};
export const LANGUAGES_VALUES_RCF = {"id":"languages.values.rcf","translations":{"public_profile":{"fr":"Cr\u00e9ole r\u00e9unionnais","en":"Reunion Creole"}}};
export const LANGUAGES_VALUES_DA = {"id":"languages.values.da","translations":{"public_profile":{"fr":"Danois","en":"Danish"}}};
export const LANGUAGES_VALUES_ES = {"id":"languages.values.es","translations":{"public_profile":{"fr":"Espagnol","en":"Spanish"}}};
export const LANGUAGES_VALUES_ET = {"id":"languages.values.et","translations":{"public_profile":{"fr":"Estonien","en":"Estonian"}}};
export const LANGUAGES_VALUES_FJ = {"id":"languages.values.fj","translations":{"public_profile":{"fr":"Fidjien","en":"Fijian"}}};
export const LANGUAGES_VALUES_FIL = {"id":"languages.values.fil","translations":{"public_profile":{"fr":"Filipino","en":"Filipino"}}};
export const LANGUAGES_VALUES_FI = {"id":"languages.values.fi","translations":{"public_profile":{"fr":"Finnois","en":"Finnish"}}};
export const LANGUAGES_VALUES_FR = {"id":"languages.values.fr","translations":{"public_profile":{"fr":"Fran\u00e7ais","en":"French"}}};
export const LANGUAGES_VALUES_EL = {"id":"languages.values.el","translations":{"public_profile":{"fr":"Grec","en":"Greek"}}};
export const LANGUAGES_VALUES_GN = {"id":"languages.values.gn","translations":{"public_profile":{"fr":"Guarani","en":"Guarani"}}};
export const LANGUAGES_VALUES_GU = {"id":"languages.values.gu","translations":{"public_profile":{"fr":"Gujarati","en":"Gujarati"}}};
export const LANGUAGES_VALUES_KA = {"id":"languages.values.ka","translations":{"public_profile":{"fr":"G\u00e9orgien","en":"Georgian"}}};
export const LANGUAGES_VALUES_HI = {"id":"languages.values.hi","translations":{"public_profile":{"fr":"Hindi","en":"Hindi"}}};
export const LANGUAGES_VALUES_HU = {"id":"languages.values.hu","translations":{"public_profile":{"fr":"Hongrois","en":"Hungarian"}}};
export const LANGUAGES_VALUES_HE = {"id":"languages.values.he","translations":{"public_profile":{"fr":"H\u00e9breu","en":"Hebrew"}}};
export const LANGUAGES_VALUES_ID = {"id":"languages.values.id","translations":{"public_profile":{"fr":"Indon\u00e9sien","en":"Indonesian"}}};
export const LANGUAGES_VALUES_IRA = {"id":"languages.values.ira","translations":{"public_profile":{"fr":"Iranien","en":"Iranian"}}};
export const LANGUAGES_VALUES_GA = {"id":"languages.values.ga","translations":{"public_profile":{"fr":"Irlandais","en":"Irish"}}};
export const LANGUAGES_VALUES_IS = {"id":"languages.values.is","translations":{"public_profile":{"fr":"Islandais","en":"Icelandic"}}};
export const LANGUAGES_VALUES_IT = {"id":"languages.values.it","translations":{"public_profile":{"fr":"Italien","en":"Italian"}}};
export const LANGUAGES_VALUES_JA = {"id":"languages.values.ja","translations":{"public_profile":{"fr":"Japonais","en":"Japanese"}}};
export const LANGUAGES_VALUES_KAB = {"id":"languages.values.kab","translations":{"public_profile":{"fr":"Kabyle","en":"Kabyle"}}};
export const LANGUAGES_VALUES_KK = {"id":"languages.values.kk","translations":{"public_profile":{"fr":"Kazakh","en":"Kazakh"}}};
export const LANGUAGES_VALUES_KM = {"id":"languages.values.km","translations":{"public_profile":{"fr":"Khmer","en":"Khmer"}}};
export const LANGUAGES_VALUES_KG = {"id":"languages.values.kg","translations":{"public_profile":{"fr":"Kikongo","en":"Kikongo"}}};
export const LANGUAGES_VALUES_KY = {"id":"languages.values.ky","translations":{"public_profile":{"fr":"Kirghiz","en":"Kyrgyz"}}};
export const LANGUAGES_VALUES_RN = {"id":"languages.values.rn","translations":{"public_profile":{"fr":"Kirundi","en":"Kirundi"}}};
export const LANGUAGES_VALUES_KU = {"id":"languages.values.ku","translations":{"public_profile":{"fr":"Kurde","en":"Kurdish"}}};
export const LANGUAGES_VALUES_SGN = {"id":"languages.values.sgn","translations":{"public_profile":{"fr":"Langue des signes","en":"Sign Language"}}};
export const LANGUAGES_VALUES_LO = {"id":"languages.values.lo","translations":{"public_profile":{"fr":"Laotien","en":"Lao"}}};
export const LANGUAGES_VALUES_LV = {"id":"languages.values.lv","translations":{"public_profile":{"fr":"Letton","en":"Latvian"}}};
export const LANGUAGES_VALUES_LN = {"id":"languages.values.ln","translations":{"public_profile":{"fr":"Lingala","en":"Lingala"}}};
export const LANGUAGES_VALUES_LT = {"id":"languages.values.lt","translations":{"public_profile":{"fr":"Lituatien","en":"Lithuanian"}}};
export const LANGUAGES_VALUES_LB = {"id":"languages.values.lb","translations":{"public_profile":{"fr":"Luwembourgeois","en":"Luxembourgish"}}};
export const LANGUAGES_VALUES_MK = {"id":"languages.values.mk","translations":{"public_profile":{"fr":"Mac\u00e9donien","en":"Macedonian"}}};
export const LANGUAGES_VALUES_MG = {"id":"languages.values.mg","translations":{"public_profile":{"fr":"Malgache","en":"Malagasy"}}};
export const LANGUAGES_VALUES_MT = {"id":"languages.values.mt","translations":{"public_profile":{"fr":"Maltais","en":"Maltese"}}};
export const LANGUAGES_VALUES_MN = {"id":"languages.values.mn","translations":{"public_profile":{"fr":"Mongol","en":"Mongolian"}}};
export const LANGUAGES_VALUES_CNR = {"id":"languages.values.cnr","translations":{"public_profile":{"fr":"Mont\u00e9n\u00e9grin","en":"Montenegrin"}}};
export const LANGUAGES_VALUES_NO = {"id":"languages.values.no","translations":{"public_profile":{"fr":"Norv\u00e9gien","en":"Norwegian"}}};
export const LANGUAGES_VALUES_NL = {"id":"languages.values.nl","translations":{"public_profile":{"fr":"N\u00e9erlandais","en":"Dutch"}}};
export const LANGUAGES_VALUES_NE = {"id":"languages.values.ne","translations":{"public_profile":{"fr":"N\u00e9palais","en":"Nepali"}}};
export const LANGUAGES_VALUES_OC = {"id":"languages.values.oc","translations":{"public_profile":{"fr":"Occitan","en":"Occitan"}}};
export const LANGUAGES_VALUES_UZ = {"id":"languages.values.uz","translations":{"public_profile":{"fr":"Ouzbek","en":"Uzbek"}}};
export const LANGUAGES_VALUES_UG = {"id":"languages.values.ug","translations":{"public_profile":{"fr":"Ou\u00efghour","en":"Uyghur"}}};
export const LANGUAGES_VALUES_PS = {"id":"languages.values.ps","translations":{"public_profile":{"fr":"Pachto","en":"Pashto"}}};
export const LANGUAGES_VALUES_FA = {"id":"languages.values.fa","translations":{"public_profile":{"fr":"Persan","en":"Persian"}}};
export const LANGUAGES_VALUES_FF = {"id":"languages.values.ff","translations":{"public_profile":{"fr":"Peul","en":"Fula"}}};
export const LANGUAGES_VALUES_PL = {"id":"languages.values.pl","translations":{"public_profile":{"fr":"Polonais","en":"Polish"}}};
export const LANGUAGES_VALUES_PT = {"id":"languages.values.pt","translations":{"public_profile":{"fr":"Portugais","en":"Portuguese"}}};
export const LANGUAGES_VALUES_QU = {"id":"languages.values.qu","translations":{"public_profile":{"fr":"Quechua","en":"Quechua"}}};
export const LANGUAGES_VALUES_RO = {"id":"languages.values.ro","translations":{"public_profile":{"fr":"Roumain","en":"Romanian"}}};
export const LANGUAGES_VALUES_RU = {"id":"languages.values.ru","translations":{"public_profile":{"fr":"Russe","en":"Russian"}}};
export const LANGUAGES_VALUES_SM = {"id":"languages.values.sm","translations":{"public_profile":{"fr":"Samoan","en":"Samoan"}}};
export const LANGUAGES_VALUES_SG = {"id":"languages.values.sg","translations":{"public_profile":{"fr":"Sango","en":"Sango"}}};
export const LANGUAGES_VALUES_SR = {"id":"languages.values.sr","translations":{"public_profile":{"fr":"Serbe","en":"Serbian"}}};
export const LANGUAGES_VALUES_SN = {"id":"languages.values.sn","translations":{"public_profile":{"fr":"Shona","en":"Shona"}}};
export const LANGUAGES_VALUES_SI = {"id":"languages.values.si","translations":{"public_profile":{"fr":"Singhalais","en":"Sinhala"}}};
export const LANGUAGES_VALUES_SK = {"id":"languages.values.sk","translations":{"public_profile":{"fr":"Slovaque","en":"Slovak"}}};
export const LANGUAGES_VALUES_SL = {"id":"languages.values.sl","translations":{"public_profile":{"fr":"Slov\u00e8ne","en":"Slovene"}}};
export const LANGUAGES_VALUES_SO = {"id":"languages.values.so","translations":{"public_profile":{"fr":"Somali","en":"Somali"}}};
export const LANGUAGES_VALUES_SNK = {"id":"languages.values.snk","translations":{"public_profile":{"fr":"Sonink\u00e9","en":"Soninke"}}};
export const LANGUAGES_VALUES_SRN = {"id":"languages.values.srn","translations":{"public_profile":{"fr":"Sranan Tongo","en":"Sranan Tongo"}}};
export const LANGUAGES_VALUES_SV = {"id":"languages.values.sv","translations":{"public_profile":{"fr":"Su\u00e9dois","en":"Swedish"}}};
export const LANGUAGES_VALUES_SW = {"id":"languages.values.sw","translations":{"public_profile":{"fr":"Swahili","en":"Swahili"}}};
export const LANGUAGES_VALUES_TG = {"id":"languages.values.tg","translations":{"public_profile":{"fr":"Tadjik","en":"Tajik"}}};
export const LANGUAGES_VALUES_TL = {"id":"languages.values.tl","translations":{"public_profile":{"fr":"Tagalog","en":"Tagalog"}}};
export const LANGUAGES_VALUES_TA = {"id":"languages.values.ta","translations":{"public_profile":{"fr":"Tamoul","en":"Tamil"}}};
export const LANGUAGES_VALUES_CS = {"id":"languages.values.cs","translations":{"public_profile":{"fr":"Tch\u00e8que","en":"Czech"}}};
export const LANGUAGES_VALUES_TH = {"id":"languages.values.th","translations":{"public_profile":{"fr":"Tha\u00ef","en":"Thai"}}};
export const LANGUAGES_VALUES_TR = {"id":"languages.values.tr","translations":{"public_profile":{"fr":"Turc","en":"Turkish"}}};
export const LANGUAGES_VALUES_TK = {"id":"languages.values.tk","translations":{"public_profile":{"fr":"Turkm\u00e8ne","en":"Turkmen"}}};
export const LANGUAGES_VALUES_TE = {"id":"languages.values.te","translations":{"public_profile":{"fr":"T\u00e9lougou","en":"Telugu"}}};
export const LANGUAGES_VALUES_UK = {"id":"languages.values.uk","translations":{"public_profile":{"fr":"Ukrainien","en":"Ukrainian"}}};
export const LANGUAGES_VALUES_UR = {"id":"languages.values.ur","translations":{"public_profile":{"fr":"Urdu","en":"Urdu"}}};
export const LANGUAGES_VALUES_VI = {"id":"languages.values.vi","translations":{"public_profile":{"fr":"Vietnamien","en":"Vietnamese"}}};
export const LANGUAGES_VALUES_ZU = {"id":"languages.values.zu","translations":{"public_profile":{"fr":"Zoulou","en":"Zulu"}}};
export const PAYMENT_METHODS_DESCRIPTION = {"id":"payment_methods.description","translations":{"public_profile":{"fr":"Les moyens de paiement accept\u00e9s par le m\u00e9decin","en":"The payment methods accepted by the doctor"}}};
export const PAYMENT_METHODS_LABEL = {"id":"payment_methods.label","translations":{"public_profile":{"fr":"Moyens de paiement accept\u00e9s","en":"Accepted payment methods"}}};
export const PAYMENT_METHODS_PLACEHOLDER = {"id":"payment_methods.placeholder","translations":{"public_profile":{"fr":"Choisissez un ou plusieurs moyen de paiement...","en":"Choose one or more payment methods..."}}};
export const PAYMENT_METHODS_VALUES1 = {"id":"payment_methods.values.1","translations":{"public_profile":{"fr":"Esp\u00e8ces","en":"Cash"}}};
export const PAYMENT_METHODS_VALUES2 = {"id":"payment_methods.values.2","translations":{"public_profile":{"fr":"Ch\u00e8ques","en":"Checks"}}};
export const PAYMENT_METHODS_VALUES3 = {"id":"payment_methods.values.3","translations":{"public_profile":{"fr":"Cartes bancaires","en":"Bank cards"}}};
export const PAYMENT_METHODS_VALUES4 = {"id":"payment_methods.values.4","translations":{"public_profile":{"fr":"Virement bancaire","en":"Bank transfer"}}};
export const THIRD_PARTY_PAYMENT_TYPE_DESCRIPTION = {"id":"third_party_payment_type.description","translations":{"public_profile":{"fr":"Le type de tiers payant du m\u00e9decin","en":"The type of third-party payment by the doctor"}}};
export const THIRD_PARTY_PAYMENT_TYPE_LABEL = {"id":"third_party_payment_type.label","translations":{"public_profile":{"fr":"Tiers payant","en":"Third-party payment"}}};
export const THIRD_PARTY_PAYMENT_TYPE_PLACEHOLDER = {"id":"third_party_payment_type.placeholder","translations":{"public_profile":{"fr":"Quel type de tiers payant acceptez-vous ?","en":"What type of third-party payment do you accept?"}}};
export const THIRD_PARTY_PAYMENT_TYPE_VALUES_SOCIAL_SECURITY_THIRD_PARTY_PAYMENT = {"id":"third_party_payment_type.values.socialSecurityThirdPartyPayment","translations":{"public_profile":{"fr":"Tiers payant : S\u00e9curit\u00e9 sociale","en":"Third-party payment: Social security"}}};
export const THIRD_PARTY_PAYMENT_TYPE_VALUES_FULL_THIRD_PAYMENT = {"id":"third_party_payment_type.values.fullThirdPayment","translations":{"public_profile":{"fr":"Tiers payant int\u00e9gral : S\u00e9curit\u00e9 sociale et mutuelle","en":"Full third-party payment: Social Security and mutual insurance"}}};
export const CONVENTION_SECTOR_DESCRIPTION = {"id":"convention_sector.description","translations":{"public_profile":{"fr":"Le secteur du m\u00e9decin","en":"The doctor's sector"}}};
export const CONVENTION_SECTOR_LABEL = {"id":"convention_sector.label","translations":{"public_profile":{"fr":"Secteur conventionn\u00e9","en":"Contractual sector"}}};
export const CONVENTION_SECTOR_PLACEHOLDER = {"id":"convention_sector.placeholder","translations":{"public_profile":{"fr":"Choisissez un secteur...","en":"Choose sector..."}}};
export const CONVENTION_SECTOR_VALUES0 = {"id":"convention_sector.values.0","translations":{"public_profile":{"fr":"Non sp\u00e9cifi\u00e9","en":"Not specified"}}};
export const CONVENTION_SECTOR_VALUES1 = {"id":"convention_sector.values.1","translations":{"public_profile":{"fr":"Conventionn\u00e9 secteur 1","en":"Contractual sector 1"}}};
export const CONVENTION_SECTOR_VALUES2 = {"id":"convention_sector.values.2","translations":{"public_profile":{"fr":"Conventionn\u00e9 secteur 1 avec droit permanent \u00e0 d\u00e9passement","en":"Contractual sector 1 with permanent right to exceed fees"}}};
export const CONVENTION_SECTOR_VALUES3 = {"id":"convention_sector.values.3","translations":{"public_profile":{"fr":"Conventionn\u00e9 secteur 2","en":"Contractual sector 2"}}};
export const CONVENTION_SECTOR_VALUES4 = {"id":"convention_sector.values.4","translations":{"public_profile":{"fr":"Conventionn\u00e9 secteur 1 avec OPTAM","en":"Contractual sector 1 with OPTAM"}}};
export const CONVENTION_SECTOR_VALUES5 = {"id":"convention_sector.values.5","translations":{"public_profile":{"fr":"Conventionn\u00e9 secteur 2 avec OPTAM","en":"Contractual sector 2 with OPTAM"}}};
export const CONVENTION_SECTOR_VALUES6 = {"id":"convention_sector.values.6","translations":{"public_profile":{"fr":"Conventionn\u00e9 secteur 1 avec OPTAM-CO","en":"Contractual sector 1 with OPTAM-CO"}}};
export const CONVENTION_SECTOR_VALUES7 = {"id":"convention_sector.values.7","translations":{"public_profile":{"fr":"Conventionn\u00e9 secteur 2 avec OPTAM-CO","en":"Contractual sector 2 with OPTAM-CO"}}};
export const CONVENTION_SECTOR_VALUES8 = {"id":"convention_sector.values.8","translations":{"public_profile":{"fr":"Secteur 3 (non-conventionn\u00e9)","en":"Sector 3 (non-contracted)"}}};
export const DISABLE_INDEX_REASON_DESCRIPTION = {"id":"disable_index_reason.description","translations":{"public_profile":{"fr":"Raison de la supression de votre profil","en":"Reason of deletion"}}};
export const DISABLE_INDEX_REASON_LABEL = {"id":"disable_index_reason.label","translations":{"public_profile":{"fr":"Raison de la supression de votre profil","en":"Why you no longer wish to appear on Instamed"}}};
export const DISABLE_INDEX_REASON_PLACEHOLDER = {"id":"disable_index_reason.placeholder","translations":{"public_profile":{"fr":"Raison","en":"Reason of deletion"}}};
export const DISABLE_INDEX_REASON_VALUES1 = {"id":"disable_index_reason.values.1","translations":{"public_profile":{"fr":"Je ne pratique plus","en":"I don't practice anymore"}}};
export const DISABLE_INDEX_REASON_VALUES2 = {"id":"disable_index_reason.values.2","translations":{"public_profile":{"fr":"Je ne souhaite pas \u00eatre r\u00e9f\u00e9renc\u00e9(e)","en":"I don't want to be referenced"}}};
export const USER_TYPE_DESCRIPTION = {"id":"user_type.description","translations":{"public_profile":{"fr":"Pr\u00e9cisez votre lien avec ce soignant","en":"Please specify your connection with this caregiver"}}};
export const USER_TYPE_LABEL = {"id":"user_type.label","translations":{"public_profile":{"fr":"Vous \u00eates...","en":"You are..."}}};
export const USER_TYPE_PLACEHOLDER = {"id":"user_type.placeholder","translations":{"public_profile":{"fr":"Selectionnez...","en":"Select..."}}};
export const USER_TYPE_VALUES1 = {"id":"user_type.values.1","translations":{"public_profile":{"fr":"Un patient","en":"A patient"}}};
export const USER_TYPE_VALUES2 = {"id":"user_type.values.2","translations":{"public_profile":{"fr":"Le professionnel de sant\u00e9 concern\u00e9","en":"This caregiver"}}};
export const USER_TYPE_VALUES3 = {"id":"user_type.values.3","translations":{"public_profile":{"fr":"Un professionnel de sant\u00e9","en":"An other caregiver"}}};
export const ANSWER_TYPE_LABEL = {"id":"answerType.label","translations":{"question":{"fr":"Type de question","en":"Question type"}}};
export const ANSWER_TYPE_DESCRIPTION = {"id":"answerType.description","translations":{"question":{"fr":"Le type de question","en":"The question type"}}};
export const ANSWER_TYPE_TEXT = {"id":"answerType.Text","translations":{"question":{"fr":"Texte libre (< 255 car.)","en":"Free text (< 255 char.)"}}};
export const ANSWER_TYPE_SCALE = {"id":"answerType.Scale","translations":{"question":{"fr":"Echelle (0 -> 4)","en":"Scale (0 -> 4)"}}};
export const ANSWER_TYPE_TEXT_AREA = {"id":"answerType.TextArea","translations":{"question":{"fr":"Zone de texte (> 255 car.)","en":"Text area (> 255 char.)"}}};
export const ANSWER_TYPE_BIG_SCALE = {"id":"answerType.BigScale","translations":{"question":{"fr":"Echelle (0 -> 10)","en":"Scale (0 -> 10)"}}};
export const ANSWER_TYPE_NUMBER = {"id":"answerType.Number","translations":{"question":{"fr":"Nombre","en":"Number"}}};
export const ANSWER_TYPE_CHECKBOX = {"id":"answerType.Checkbox","translations":{"question":{"fr":"Oui \/ Non","en":"Yes \/ No"}}};
export const ANSWER_TYPE_TINY_MCE = {"id":"answerType.TinyMce","translations":{"question":{"fr":"Editeur WYSIWYG","en":"WYSIWYG editor"}}};
export const ANSWER_TYPE_MULTIPLE_CHOICE = {"id":"answerType.MultipleChoice","translations":{"question":{"fr":"Choix Multiple","en":"Multiple choice"}}};
export const ANSWER_TYPE_DATE = {"id":"answerType.Date","translations":{"question":{"fr":"Date","en":"Date"}}};
export const ANSWER_TYPE_VARIABLE = {"id":"answerType.Variable","translations":{"question":{"fr":"Constante vitale","en":"Vital sign"}}};
export const DASHBOARD_SEE_ALL = {"id":"dashboard.seeAll","translations":{"questionnaires":{"fr":"Voir tous les rapports","en":"See all reports"}}};
export const DASHBOARD_ALERTS = {"id":"dashboard.alerts","translations":{"questionnaires":{"fr":"{0} Vous n'avez aucun rapport d'auto-\u00e9valuation.|{1} Vous avez actuellement <strong>1 rapport d'auto-\u00e9valuation \u00e0 revoir.<\/strong>|]1,Inf[ Vous avez actuellement <strong>%count% rapports d'auto-\u00e9valuations \u00e0 revoir.<\/strong>","en":"{0} You have no reported outcome.|{1} You currently have <strong>1 report outcome to review.<\\\/strong>|]1,Inf[ You currently have <strong>%count% reported outcome to review.<\\\/strong>"}}};
export const KIND_DESCRIPTION = {"id":"kind.description","translations":{"record":{"fr":"Type d'\u00e9v\u00e9nement","en":"Appointment type"}}};
export const KIND_LABEL = {"id":"kind.label","translations":{"record":{"fr":"Type d'\u00e9v\u00e9nement","en":"Appointment type"}}};
export const KIND_PLACEHOLDER = {"id":"kind.placeholder","translations":{"record":{"fr":"S\u00e9lectionnez un type d'\u00e9v\u00e9nement","en":"Select an appointment type"}}};
export const KIND_VALUES_AVAILABILITY_CREATED = {"id":"kind.values.availability_created","translations":{"record":{"fr":"Disponibilit\u00e9 cr\u00e9\u00e9e","en":"Availability created"}}};
export const KIND_VALUES_AVAILABILITY_UPDATED = {"id":"kind.values.availability_updated","translations":{"record":{"fr":"Disponibilit\u00e9 mise \u00e0 jour","en":"Availability updated"}}};
export const KIND_VALUES_AVAILABILITY_DELETED = {"id":"kind.values.availability_deleted","translations":{"record":{"fr":"Disponibilit\u00e9 supprim\u00e9e","en":"Availability deleted"}}};
export const KIND_VALUES_DOCUMENT_MEDICAL_DOCUMENT_GENERATED = {"id":"kind.values.document_medical_document_generated","translations":{"record":{"fr":"Document m\u00e9dical g\u00e9n\u00e9r\u00e9","en":"Medical document generated"}}};
export const KIND_VALUES_DOCUMENT_UPDATED = {"id":"kind.values.document_updated","translations":{"record":{"fr":"Document mis \u00e0 jour","en":"Document updated"}}};
export const KIND_VALUES_DOCUMENT_SENT = {"id":"kind.values.document_sent","translations":{"record":{"fr":"Document envoy\u00e9","en":"Document sent"}}};
export const KIND_VALUES_DOCUMENT_READ = {"id":"kind.values.document_read","translations":{"record":{"fr":"Document consult\u00e9","en":"Document read"}}};
export const KIND_VALUES_EVENT_CANCELED = {"id":"kind.values.event_canceled","translations":{"record":{"fr":"Ev\u00e9nement annul\u00e9","en":"Appointment canceled"}}};
export const KIND_VALUES_EVENT_PARTICIPANT_ADDED = {"id":"kind.values.event_participant_added","translations":{"record":{"fr":"Participant ajout\u00e9 \u00e0 l'\u00e9v\u00e9nement","en":"Participant added to appointment"}}};
export const KIND_VALUES_EVENT_PARTICIPANT_REMOVED = {"id":"kind.values.event_participant_removed","translations":{"record":{"fr":"Participant retir\u00e9 de l'\u00e9v\u00e9nement","en":"Participant removed from appointment"}}};
export const KIND_VALUES_EVENT_UNCANCELED = {"id":"kind.values.event_uncanceled","translations":{"record":{"fr":"Ev\u00e9nement non annul\u00e9","en":"Appointment uncanceled"}}};
export const KIND_VALUES_EVENT_UPDATED = {"id":"kind.values.event_updated","translations":{"record":{"fr":"Ev\u00e9nement mis \u00e0 jour","en":"Appointment updated"}}};
export const KIND_VALUES_MEDICAL_RECORD_UPDATED = {"id":"kind.values.medical_record_updated","translations":{"record":{"fr":"Dossier m\u00e9dical mis \u00e0 jour","en":"Medical record updated"}}};
export const KIND_VALUES_ONLINE_APPOINTMENT_SUBSCRIPTION_CREATED = {"id":"kind.values.online_appointment_subscription_created","translations":{"record":{"fr":"Abonnement \u00e0 la prise de rendez-vous cr\u00e9\u00e9","en":"Online appointment subscription created"}}};
export const KIND_VALUES_PATIENT_BILLING_ACCOUNT_CREATED = {"id":"kind.values.patient_billing_account_created","translations":{"record":{"fr":"Compte de t\u00e9l\u00e9transmission cr\u00e9\u00e9","en":"Billing account created"}}};
export const KIND_VALUES_PATIENT_BILLING_ACCOUNT_ENABLED = {"id":"kind.values.patient_billing_account_enabled","translations":{"record":{"fr":"Compte de t\u00e9l\u00e9transmission activ\u00e9","en":"Billing account enabled"}}};
export const KIND_VALUES_PATIENT_BILLING_INVOICE_DRAFT = {"id":"kind.values.patient_billing_invoice_draft","translations":{"record":{"fr":"Brouillon de facture cr\u00e9\u00e9","en":"Invoice draft created"}}};
export const KIND_VALUES_PATIENT_BILLING_INVOICE_CREATED = {"id":"kind.values.patient_billing_invoice_created","translations":{"record":{"fr":"Facture cr\u00e9\u00e9e","en":"Invoice created"}}};
export const KIND_VALUES_PATIENT_BILLING_INVOICE_DELETED = {"id":"kind.values.patient_billing_invoice_deleted","translations":{"record":{"fr":"Facture supprim\u00e9e","en":"Invoice deleted"}}};
export const KIND_VALUES_PATIENT_BILLING_INVOICE_GENERATED = {"id":"kind.values.patient_billing_invoice_generated","translations":{"record":{"fr":"Facture g\u00e9n\u00e9r\u00e9e","en":"Invoice generated"}}};
export const KIND_VALUES_PATIENT_EDUCATION_FAQ_CONSULTED = {"id":"kind.values.patient_education_faq_consulted","translations":{"record":{"fr":"FAQ patient consult\u00e9e","en":"Patient FAQ consulted"}}};
export const KIND_VALUES_PATIENT_EDUCATION_TIP_CONSULTED = {"id":"kind.values.patient_education_tip_consulted","translations":{"record":{"fr":"Astuce patient consult\u00e9e","en":"Patient Tip consulted"}}};
export const KIND_VALUES_PRESCRIPTION_ASSISTANCE_SUBSCRIPTION_CREATED = {"id":"kind.values.prescription_assistance_subscription_created","translations":{"record":{"fr":"Abonnement \u00e0 l'aide \u00e0 la prescription cr\u00e9\u00e9","en":"Prescription assistance subscription created"}}};
export const KIND_VALUES_MEDICAL_RECORD_CONSULTED = {"id":"kind.values.medical_record_consulted","translations":{"record":{"fr":"Dossier m\u00e9dical consult\u00e9","en":"Medical record consulted"}}};
export const KIND_VALUES_MEDICAL_RECORD_CREATED = {"id":"kind.values.medical_record_created","translations":{"record":{"fr":"Dossier m\u00e9dical cr\u00e9\u00e9","en":"Medical record created"}}};
export const KIND_VALUES_MEDICAL_RECORD_SHARED_INITIATED = {"id":"kind.values.medical_record_shared_initiated","translations":{"record":{"fr":"Dossier m\u00e9dical en cours de partage","en":"Medical record sharing initiated"}}};
export const KIND_VALUES_MEDICAL_RECORD_SHARED_COMPLETED = {"id":"kind.values.medical_record_shared_completed","translations":{"record":{"fr":"Dossier m\u00e9dical partag\u00e9","en":"Medical record shared"}}};
export const KIND_VALUES_MEDICAL_RECORD_PATIENT_MARKED_AS_DECEASED = {"id":"kind.values.medical_record_patient_marked_as_deceased","translations":{"record":{"fr":"Patient d\u00e9c\u00e9d\u00e9","en":"Patient marked as deceased"}}};
export const KIND_VALUES_MEDICAL_RECORD_PATIENT_UN_MARKED_AS_DECEASED = {"id":"kind.values.medical_record_patient_un_marked_as_deceased","translations":{"record":{"fr":"Patient marqu\u00e9 comme non d\u00e9c\u00e9d\u00e9","en":"Patient unmarked as deceased"}}};
export const KIND_VALUES_MEDICAL_RECORD_SOCIAL_SECURITY_NUMBER_UPDATED = {"id":"kind.values.medical_record_social_security_number_updated","translations":{"record":{"fr":"N\u00b0 de s\u00e9curit\u00e9 sociale mis \u00e0 jour","en":"Social security number updated"}}};
export const KIND_VALUES_MEDICAL_RECORD_PATIENT_MEDICAL_TEAM_MEMBER_ADDED = {"id":"kind.values.medical_record_patient_medical_team_member_added","translations":{"record":{"fr":"Membre ajout\u00e9 dans l'\u00e9quipe soignante","en":"Care team member added"}}};
export const KIND_VALUES_MEDICAL_RECORD_PATIENT_MEDICAL_TEAM_MEMBER_REMOVED = {"id":"kind.values.medical_record_patient_medical_team_member_removed","translations":{"record":{"fr":"Membre retir\u00e9 de l'\u00e9quipe soignante","en":"Care team member removed"}}};
export const KIND_VALUES_MEDICAL_RECORD_PATIENT_ADDRESS_ADDED = {"id":"kind.values.medical_record_patient_address_added","translations":{"record":{"fr":"Adresse ajout\u00e9e","en":"Address added"}}};
export const KIND_VALUES_MEDICAL_RECORD_PATIENT_ADDRESS_UPDATED = {"id":"kind.values.medical_record_patient_address_updated","translations":{"record":{"fr":"Adresse modifi\u00e9e","en":"Address updated"}}};
export const KIND_VALUES_MEDICAL_RECORD_PATIENT_NOTE_CREATED = {"id":"kind.values.medical_record_patient_note_created","translations":{"record":{"fr":"Note cr\u00e9\u00e9e","en":"Note created"}}};
export const KIND_VALUES_MEDICAL_RECORD_PATIENT_NOTE_UPDATED = {"id":"kind.values.medical_record_patient_note_updated","translations":{"record":{"fr":"Note modifi\u00e9e","en":"Note updated"}}};
export const KIND_VALUES_MEDICAL_RECORD_PATIENT_NOTE_DELETED = {"id":"kind.values.medical_record_patient_note_deleted","translations":{"record":{"fr":"Note supprim\u00e9e","en":"Note deleted"}}};
export const KIND_VALUES_MEDICAL_RECORD_PATIENT_DISEASE_CREATED = {"id":"kind.values.medical_record_patient_disease_created","translations":{"record":{"fr":"Maladie ajout\u00e9e","en":"Disease added"}}};
export const KIND_VALUES_MEDICAL_RECORD_PATIENT_DISEASE_FINISHED = {"id":"kind.values.medical_record_patient_disease_finished","translations":{"record":{"fr":"Maladie termin\u00e9e","en":"Disease finished"}}};
export const KIND_VALUES_MEDICAL_RECORD_PATIENT_MEDICAL_BACKGROUND_CREATED = {"id":"kind.values.medical_record_patient_medical_background_created","translations":{"record":{"fr":"Ant\u00e9c\u00e9dent cr\u00e9\u00e9","en":"Medical background created"}}};
export const KIND_VALUES_USER_CREATED = {"id":"kind.values.user_created","translations":{"record":{"fr":"Utilisateur cr\u00e9\u00e9","en":"User created"}}};
export const KIND_VALUES_USER_ARCHIVED = {"id":"kind.values.user_archived","translations":{"record":{"fr":"Utilisateur archiv\u00e9","en":"User archived"}}};
export const KIND_VALUES_USER_UN_ARCHIVED = {"id":"kind.values.user_un_archived","translations":{"record":{"fr":"Utilisateur d\u00e9sarchiv\u00e9","en":"User unarchived"}}};
export const KIND_VALUES_USER_SOFT_DELETED = {"id":"kind.values.user_soft_deleted","translations":{"record":{"fr":"Utilisateur marqu\u00e9 comme supprim\u00e9","en":"User marked as deleted"}}};
export const KIND_VALUES_USER_DELETED = {"id":"kind.values.user_deleted","translations":{"record":{"fr":"Utilisateur supprim\u00e9 d\u00e9finitivement","en":"User permanently deleted"}}};
export const KIND_VALUES_USER_MERGED = {"id":"kind.values.user_merged","translations":{"record":{"fr":"Utilisateur fusionn\u00e9","en":"User merged"}}};
export const KIND_VALUES_USER_EMAIL_UPDATED = {"id":"kind.values.user_email_updated","translations":{"record":{"fr":"Email mis \u00e0 jour","en":"Email updated"}}};
export const KIND_VALUES_USER_ACCOUNT_CREATION_COMPLETED = {"id":"kind.values.user_account_creation_completed","translations":{"record":{"fr":"Cr\u00e9ation de compte utilisateur termin\u00e9e","en":"User account creation completed"}}};
export const KIND_VALUES_USER_DISABLED = {"id":"kind.values.user_disabled","translations":{"record":{"fr":"Utilisateur d\u00e9sactiv\u00e9","en":"User disabled"}}};
export const KIND_VALUES_USER_ENABLED = {"id":"kind.values.user_enabled","translations":{"record":{"fr":"Utilisateur activ\u00e9","en":"User enabled"}}};
export const KIND_VALUES_USER_LOGIN = {"id":"kind.values.user_login","translations":{"record":{"fr":"Connexion utilisateur","en":"User login"}}};
export const KIND_VALUES_USER_TWO_FA_ENABLED = {"id":"kind.values.user_two_fa_enabled","translations":{"record":{"fr":"Authentification \u00e0 deux facteurs activ\u00e9e","en":"Two-factor authentication enabled"}}};
export const KIND_VALUES_USER_UPDATED = {"id":"kind.values.user_updated","translations":{"record":{"fr":"Utilisateur mis \u00e0 jour","en":"User updated"}}};
export const KIND_VALUES_DOCUMENT_CREATED = {"id":"kind.values.document_created","translations":{"record":{"fr":"Document cr\u00e9\u00e9","en":"Document created"}}};
export const KIND_VALUES_DOCUMENT_VISIBILITY_UPDATED = {"id":"kind.values.document_visibility_updated","translations":{"record":{"fr":"Visibilit\u00e9 du document mise \u00e0 jour","en":"Document visibility updated"}}};
export const KIND_VALUES_DOCUMENT_TYPE_UPDATED = {"id":"kind.values.document_type_updated","translations":{"record":{"fr":"Type du document mis \u00e0 jour","en":"Document type updated"}}};
export const KIND_VALUES_DOCUMENT_DELETED = {"id":"kind.values.document_deleted","translations":{"record":{"fr":"Document supprim\u00e9","en":"Document deleted"}}};
export const KIND_VALUES_DOCUMENT_TEMPLATE_CREATED = {"id":"kind.values.document_template_created","translations":{"record":{"fr":"Mod\u00e8le de document cr\u00e9\u00e9","en":"Document template created"}}};
export const KIND_VALUES_DOCUMENT_TEMPLATE_UPDATED = {"id":"kind.values.document_template_updated","translations":{"record":{"fr":"Mod\u00e8le de document modifi\u00e9","en":"Document template updated"}}};
export const KIND_VALUES_DOCUMENT_TEMPLATE_ARCHIVED = {"id":"kind.values.document_template_archived","translations":{"record":{"fr":"Mod\u00e8le de document archiv\u00e9","en":"Document template archived"}}};
export const KIND_VALUES_DOCUMENT_TEMPLATE_UN_ARCHIVED = {"id":"kind.values.document_template_un_archived","translations":{"record":{"fr":"Mod\u00e8le de document d\u00e9sarchiv\u00e9","en":"Document template unarchived"}}};
export const KIND_VALUES_DOCUMENT_TEMPLATE_DELETED = {"id":"kind.values.document_template_deleted","translations":{"record":{"fr":"Mod\u00e8le de document supprim\u00e9","en":"Document template deleted"}}};
export const KIND_VALUES_DOCUMENT_MEDICAL_DOCUMENT_CREATED = {"id":"kind.values.document_medical_document_created","translations":{"record":{"fr":"Document m\u00e9dical cr\u00e9\u00e9","en":"Medical document created"}}};
export const KIND_VALUES_DOCUMENT_MEDICAL_DOCUMENT_UPDATED = {"id":"kind.values.document_medical_document_updated","translations":{"record":{"fr":"Document m\u00e9dical modifi\u00e9","en":"Medical document updated"}}};
export const KIND_VALUES_DOCUMENT_MEDICAL_DOCUMENT_RENEWED = {"id":"kind.values.document_medical_document_renewed","translations":{"record":{"fr":"Document m\u00e9dical renouvel\u00e9","en":"Medical document renewed"}}};
export const KIND_VALUES_DOCUMENT_MEDICAL_DOCUMENT_DELETED = {"id":"kind.values.document_medical_document_deleted","translations":{"record":{"fr":"Document m\u00e9dical supprim\u00e9","en":"Medical document deleted"}}};
export const KIND_VALUES_SELF_EVALUATION_QUESTIONNAIRE_ANSWERED = {"id":"kind.values.self_evaluation_questionnaire_answered","translations":{"record":{"fr":"Auto-\u00e9valuation compl\u00e9t\u00e9e","en":"Reported outcome completed"}}};
export const KIND_VALUES_SELF_EVALUATION_QUESTIONNAIRE_REVIEWED = {"id":"kind.values.self_evaluation_questionnaire_reviewed","translations":{"record":{"fr":"Auto-\u00e9valuation r\u00e9vis\u00e9e","en":"Reported outcome reviewed"}}};
export const KIND_VALUES_DISCUSSION_CREATED = {"id":"kind.values.discussion_created","translations":{"record":{"fr":"Discussion cr\u00e9\u00e9e","en":"Conversation created"}}};
export const KIND_VALUES_DISCUSSION_ARCHIVED = {"id":"kind.values.discussion_archived","translations":{"record":{"fr":"Discussion archiv\u00e9e","en":"Conversation archived"}}};
export const KIND_VALUES_DISCUSSION_UN_ARCHIVED = {"id":"kind.values.discussion_un_archived","translations":{"record":{"fr":"Discussion d\u00e9sarchiv\u00e9e","en":"Conversation unarchived"}}};
export const KIND_VALUES_DISCUSSION_MESSAGE_SENT = {"id":"kind.values.discussion_message_sent","translations":{"record":{"fr":"Message envoy\u00e9","en":"Message sent"}}};
export const KIND_VALUES_DISCUSSION_MESSAGE_DELETED = {"id":"kind.values.discussion_message_deleted","translations":{"record":{"fr":"Message supprim\u00e9","en":"Message deleted"}}};
export const KIND_VALUES_DISCUSSION_MESSAGE_PATIENT_TAGGED = {"id":"kind.values.discussion_message_patient_tagged","translations":{"record":{"fr":"Message avec tag patient envoy\u00e9","en":"Message tagged with patient sent"}}};
export const KIND_VALUES_DISCUSSION_DELETED = {"id":"kind.values.discussion_deleted","translations":{"record":{"fr":"Discussion supprim\u00e9e","en":"Conversation deleted"}}};
export const KIND_VALUES_DISCUSSION_PARTICIPANT_ADDED = {"id":"kind.values.discussion_participant_added","translations":{"record":{"fr":"Participant ajout\u00e9 \u00e0 la discussion","en":"Participant added to conversation"}}};
export const KIND_VALUES_DISCUSSION_PARTICIPANT_REMOVED = {"id":"kind.values.discussion_participant_removed","translations":{"record":{"fr":"Participant supprim\u00e9 de la discussion","en":"Participant removed from conversation"}}};
export const KIND_VALUES_EVENT_CREATED = {"id":"kind.values.event_created","translations":{"record":{"fr":"Ev\u00e9nement cr\u00e9\u00e9","en":"Appointment created"}}};
export const KIND_VALUES_EVENT_DATE_UPDATED = {"id":"kind.values.event_date_updated","translations":{"record":{"fr":"Date de l'\u00e9v\u00e9nement modifi\u00e9e","en":"Appointment date updated"}}};
export const KIND_VALUES_EVENT_DELETED = {"id":"kind.values.event_deleted","translations":{"record":{"fr":"Ev\u00e9nement supprim\u00e9","en":"Appointment deleted"}}};
export const KIND_VALUES_CONSULTATION_CREATED = {"id":"kind.values.consultation_created","translations":{"record":{"fr":"Consultation cr\u00e9\u00e9e","en":"Consultation created"}}};
export const KIND_VALUES_CONSULTATION_QUESTIONNAIRE_ANSWERED = {"id":"kind.values.consultation_questionnaire_answered","translations":{"record":{"fr":"Questionnaire de consultation compl\u00e9t\u00e9","en":"Consultation questionnaire completed"}}};
export const KIND_VALUES_CONSULTATION_NOTE_CREATED = {"id":"kind.values.consultation_note_created","translations":{"record":{"fr":"Note de consultation cr\u00e9\u00e9e","en":"Consultation note created"}}};
export const KIND_VALUES_CONSULTATION_NOTE_UPDATED = {"id":"kind.values.consultation_note_updated","translations":{"record":{"fr":"Note de consultation mise \u00e0 jour","en":"Consultation note updated"}}};
export const KIND_VALUES_CONSULTATION_NOTE_DELETED = {"id":"kind.values.consultation_note_deleted","translations":{"record":{"fr":"Note de consultation supprim\u00e9e","en":"Consultation note deleted"}}};
export const KIND_VALUES_NOTIFICATION_SENT = {"id":"kind.values.notification_sent","translations":{"record":{"fr":"Notification envoy\u00e9e","en":"Notification sent"}}};
export const KIND_VALUES_EMAIL_SENT = {"id":"kind.values.email_sent","translations":{"record":{"fr":"Email envoy\u00e9","en":"Email sent"}}};
export const KIND_VALUES_SMS_SENT = {"id":"kind.values.sms_sent","translations":{"record":{"fr":"SMS envoy\u00e9","en":"SMS sent"}}};
export const OTP = {"id":"otp","translations":{"sms":{"fr":"Votre code de confirmation INSTAMED est le suivant : {{ code }}","en":"Your INSTAMED confirmation code is: {{ code }}"}}};
export const SELF_EVALUATION_NEW = {"id":"self_evaluation.new","translations":{"sms":{"fr":"Vous pouvez remplir votre auto-\u00e9valuation INSTAMED en cliquant sur ce lien : {{ link }}","en":"You can complete your INSTAMED reported outcome by clicking on this link: {{ link }}"}}};
export const VALIDATION = {"id":"validation","translations":{"sms":{"fr":"Bonjour {{ name }},\nPour valider votre num\u00e9ro de t\u00e9l\u00e9phone sur votre dossier INSTAMED, veuillez r\u00e9pondre \"OUI\" \u00e0 ce SMS.","en":"Hello {{ name }},\nTo verify your phone number on your INSTAMED profile, please reply \"YES\" to this SMS."}}};
export const PATIENT_INVITATION = {"id":"patient_invitation","translations":{"sms":{"fr":"Bonjour, {{ name }} vous invite \u00e0 rejoindre Instamed. Pour acc\u00e9der \u00e0 votre dossier m\u00e9dical, veuillez suivre ce lien {{ link }}","en":"Hello, {{ name }} invites you to join Instamed. To access your medical record, please follow this link {{ link }}"}}};
export const MEDIC_INVITATION = {"id":"medic_invitation","translations":{"sms":{"fr":"Bonjour, vous avez \u00e9t\u00e9 invit\u00e9 \u00e0 rejoindre la plateforme Instamed. Pour y acc\u00e9der, veuillez suivre ce lien {{ link }}","en":"Hello, you have been invited to join the Instamed platform. To access it, please follow this link {{ link }}"}}};
export const ALERT_NEW = {"id":"alert.new","translations":{"sms":{"fr":"Une nouvelle alerte a \u00e9t\u00e9 lanc\u00e9e sur le profil de {{ patient }}. Pour la consulter, veuillez suivre ce lien : {{ link }}","en":"A new alert has been triggered on {{ patient }}'s profile. To view it, please follow this link: {{ link }}"}}};
export const DOCUMENT_NEW = {"id":"document.new","translations":{"sms":{"fr":"Nouveau document \"{{ document }}\" disponible pour {{ user }}. {{ link }}","en":"New document \"{{ document }}\" available for {{ user }}. {{ link }}"}}};
export const DOCUMENT_NEW_BULK = {"id":"document.new_bulk","translations":{"sms":{"fr":"Nouveaux documents disponible pour {{ user }}. {{ link }}","en":"New documents available for {{ user }}. {{ link }}"}}};
export const DOCUMENT_NEW_FOR_PATIENT = {"id":"document.new_for_patient","translations":{"sms":{"fr":"Nouveau document \"{{ document }}\" disponible sur Instamed. {{ link }}","en":"New document \"{{ document }}\" available on Instamed. {{ link }}"}}};
export const DOCUMENT_NEW_BULK_FOR_PATIENT = {"id":"document.new_bulk_for_patient","translations":{"sms":{"fr":"Nouveaux documents disponible sur Instamed. {{ link }}","en":"New documents available on Instamed. {{ link }}"}}};
export const EVENT_NEW = {"id":"event.new","translations":{"sms":{"fr":"Vous avez un nouvel \u00e9v\u00e9nement programm\u00e9 le {{ date }} \u00e0 {{ hour }}. Consultez les d\u00e9tails ici : {{ link }}","en":"You have a new appointment \"{{ name }}\" on {{ date }} at {{ hour }}. To view it, please follow this link: {{ link }}"}}};
export const EVENT_UPDATED = {"id":"event.updated","translations":{"sms":{"fr":"Votre \u00e9v\u00e9nement initialement pr\u00e9vu le {{ oldDate }} est reprogramm\u00e9 au {{ date }} \u00e0 {{ hour }}. Consultez les d\u00e9tails ici : {{ link }}","en":"Your appointment \"{{ name }}\" on {{ oldDate }} has been rescheduled to {{ date }} at {{ hour }}. To view it, please follow this link: {{ link }}"}}};
export const EVENT_DELETED = {"id":"event.deleted","translations":{"sms":{"fr":"Nous vous informons que l'\u00e9v\u00e9nement pr\u00e9vu le {{ date }} \u00e0 {{ hour }} a \u00e9t\u00e9 annul\u00e9. N'h\u00e9sitez pas \u00e0 r\u00e9server un nouveau cr\u00e9neau qui vous convient.","en":"Your appointment \"{{ name }}\" on {{ date }} at {{ hour }} has been canceled."}}};
export const EVENT_REMINDER = {"id":"event.reminder","translations":{"sms":{"fr":"Rappel : votre \u00e9v\u00e9nement \"{{ eventType }}\"","en":"Reminder: {{eventType}} {{day}} {{date}} at {{hour}}:{{min}} with {{owner.fullName}}"}}};
export const EVENT_REMINDER_OWNER = {"id":"event.reminder_owner","translations":{"sms":{"fr":"Vous avez programm\u00e9 un \u00e9v\u00e9nement le {{ day }} {{ date }} \u00e0 {{ hour }}h{{ min }}.","en":"Reminder: {{eventType}} {{day}} {{date}} at {{hour}}:{{min}}"}}};
export const ROLES_DESCRIPTION = {"id":"roles.description","translations":{"specialty":{"fr":"R\u00f4les li\u00e9s \u00e0 la sp\u00e9cialit\u00e9","en":"Specialty-related roles"}}};
export const ROLES_LABEL = {"id":"roles.label","translations":{"specialty":{"fr":"Choisissez un ou plusieurs r\u00f4le","en":"Choose one or more roles"}}};
export const ROLES_PLACEHOLDER = {"id":"roles.placeholder","translations":{"specialty":{"fr":"Choisissez un ou plusieurs r\u00f4le","en":"Choose one or more roles"}}};
export const ROLES_VALUES_ROLEINFIRMIERE = {"id":"roles.values.ROLE_INFIRMIERE","translations":{"specialty":{"fr":"Param\u00e9dical","en":"Paramedic"}}};
export const ROLES_VALUES_ROLEMEDECIN = {"id":"roles.values.ROLE_MEDECIN","translations":{"specialty":{"fr":"M\u00e9decin","en":"Doctor"}}};
export const ROLES_VALUES_ROLEMEDICALCOORDINATOR = {"id":"roles.values.ROLE_MEDICAL_COORDINATOR","translations":{"specialty":{"fr":"Coordinateur m\u00e9dical","en":"Medical coordinator"}}};
export const FORM_LABEL = {"id":"form_label","translations":{"specialty":{"fr":"Sp\u00e9cialit\u00e9s","en":"Specialties"}}};
export const STATISTICS_PATIENT_COUNT = {"id":"statistics.patient_count","translations":{"statistics":{"fr":"Nombre de patients","en":"Number of patients"}}};
export const STATISTICS_MALE_COUNT = {"id":"statistics.male_count","translations":{"statistics":{"fr":"Hommes","en":"Males"}}};
export const STATISTICS_FEMALE_COUNT = {"id":"statistics.female_count","translations":{"statistics":{"fr":"Femmes","en":"Females"}}};
export const STATISTICS_PATIENTS_SEEN_THIS_WEEK = {"id":"statistics.patients_seen_this_week","translations":{"statistics":{"fr":"Patients consult\u00e9s cette semaine","en":"Patients seen this week"}}};
export const STATISTICS_ANSWERS_GROUPS_COUNT = {"id":"statistics.answers_groups_count","translations":{"statistics":{"fr":"Questionnaires r\u00e9pondus","en":"Questionnaires answered"}}};
export const STATISTICS_PATIENTS_SHARED = {"id":"statistics.patients_shared","translations":{"statistics":{"fr":"Dossiers partag\u00e9s","en":"Records shared"}}};
export const EUR = {"id":"eur","translations":{"stripe":{"fr":"\u20ac","en":"\u20ac"}}};
export const TRIALING = {"id":"trialing","translations":{"stripe":{"fr":"Essai gratuit","en":"Free trial"}}};
export const ACTIVE = {"id":"active","translations":{"stripe":{"fr":"Actif","en":"Active"}}};
export const CANCELED = {"id":"canceled","translations":{"stripe":{"fr":"Annul\u00e9","en":"Cancelled"}}};
export const STRIPE_SELECT_CARD = {"id":"stripe.select_card","translations":{"stripe":{"fr":"Choisissez une carte","en":"Choose card"}}};
export const STRIPE_CARD_CUMBER = {"id":"stripe.card_cumber","translations":{"stripe":{"fr":"Num\u00e9ro de carte","en":"Card number"}}};
export const STRIPE_EXPIRATION_DATE = {"id":"stripe.expiration_date","translations":{"stripe":{"fr":"Date d'expiration","en":"Expiration date"}}};
export const STRIPE_CCV = {"id":"stripe.ccv","translations":{"stripe":{"fr":"CCV","en":"CVV"}}};
export const STRIPE_CLIENT_ID = {"id":"stripe.client_id","translations":{"stripe":{"fr":"Stripe client ID","en":"Customer stripe ID"}}};
export const STRIPE_EVENT_DESCRIPTION = {"id":"stripe.event_description","translations":{"stripe":{"fr":"n\u00b0 $id Du $date avec $owner","en":"$id $date with $owner"}}};
export const STRIPE_EVENT = {"id":"stripe.event","translations":{"stripe":{"fr":"Rendez-vous Instamed $description","en":"Instamed appointment $description"}}};
export const STRIPE_MESSAGES_INFORMATIONS_SAVED = {"id":"stripe.messages.informations_saved","translations":{"stripe":{"fr":"Les informations renseign\u00e9es ont bien \u00e9t\u00e9 enregistr\u00e9es.","en":"Contact information has been saved."}}};
export const STRIPE_MESSAGES_TRANSACTION_STATUS = {"id":"stripe.messages.transaction_status","translations":{"stripe":{"fr":"La transaction est en l'\u00e9tat : $status","en":"Transaction status : $status"}}};
export const STRIPE_MESSAGES_DOCTOR_NOT_CONFIGURED = {"id":"stripe.messages.doctor_not_configured","translations":{"stripe":{"fr":"Le profil de ce m\u00e9decin n'est pas configur\u00e9 pour accepter les paiements","en":"The profile of this doctor is not configured to accept payments"}}};
export const STRIPE_MESSAGES_UNKNOW_STATUS = {"id":"stripe.messages.unknow_status","translations":{"stripe":{"fr":"Le status $status n'a pas \u00e9t\u00e9 pris en compte","en":"The status $status has not been applied."}}};
export const STRIPE_MESSAGES_MODULE_INACTIVE = {"id":"stripe.messages.module_inactive","translations":{"stripe":{"fr":"Le module de paiement n'est pas actif sur cette instance","en":"Payment module is not active on this instance"}}};
export const STRIPE_MESSAGES_ACCOUNT_NOT_FOUND = {"id":"stripe.messages.account_not_found","translations":{"stripe":{"fr":"Le profil n'a pas de compte Stripe","en":"Profile has no Stripe account"}}};
export const STRIPE_MESSAGES_INVALID_TOKEN = {"id":"stripe.messages.invalid_token","translations":{"stripe":{"fr":"Le token n'est pas valide","en":"Token is invalid"}}};
export const STRIPE_MESSAGES_SAVE_IBAN = {"id":"stripe.messages.save_iban","translations":{"stripe":{"fr":"Vous n'avez pas encore sauvegard\u00e9 d'IBAN. Vous pouvez le faire en cliquant sur <a href='$router' >ce lien<\/a>","en":"You have not saved any IBAN yet. You can do so by clicking <a href='$router' >this link<\/a>"}}};
export const STRIPE_MESSAGES_TRANSFER_FAILED = {"id":"stripe.messages.transfer_failed","translations":{"stripe":{"fr":"Le paiement n'a pas \u00e9t\u00e9 transf\u00e9r\u00e9","en":"The payment has not been sent."}}};
export const STRIPE_MESSAGES_AMOUNT_UNAVAILABLE = {"id":"stripe.messages.amount_unavailable","translations":{"stripe":{"fr":"Le montant n'est pas encore disponible pour la transaction $id","en":"Amount is not available yet for transaction $id"}}};
export const STRIPE_MESSAGES_CONFIRM_DELETE_CARD = {"id":"stripe.messages.confirm_delete_card","translations":{"stripe":{"fr":"\u00cates-vous s\u00fbr de vouloir supprimer cette carte ? Cette action est irr\u00e9versible","en":"Are you sure you want to delete this card? This action cannot be undone."}}};
export const STRIPE_MESSAGES_DEBITED = {"id":"stripe.messages.debited","translations":{"stripe":{"fr":"Le paiement a bien \u00e9t\u00e9 d\u00e9bit\u00e9","en":"Payment was successful"}}};
export const STRIPE_MESSAGES_REIMBURSED = {"id":"stripe.messages.reimbursed","translations":{"stripe":{"fr":"La transaction a bien \u00e9t\u00e9 rembours\u00e9e du montant de $amount \u20ac","en":"Transaction has been refunded for $amount\u20ac"}}};
export const STRIPE_MESSAGES_PRE_APPROVAL_CANCELED = {"id":"stripe.messages.pre_approval_canceled","translations":{"stripe":{"fr":"La pr\u00e9-autorisation a bien \u00e9t\u00e9 annul\u00e9e","en":"Pre authorization was successfully cancelled"}}};
export const STRIPE_MESSAGES_COMPLETE_PROFILE = {"id":"stripe.messages.complete_profile","translations":{"stripe":{"fr":"Veuillez compl\u00e9ter votre profil avant d'entrer votre IBAN","en":"Please complete your profile before entering your IBAN"}}};
export const STRIPE_MESSAGES_INVALID_CARD = {"id":"stripe.messages.invalid_card","translations":{"stripe":{"fr":"Votre carte est invalide","en":"Invalid card"}}};
export const STRIPE_MESSAGES_CARD_INFO_SAVED = {"id":"stripe.messages.card_info_saved","translations":{"stripe":{"fr":"Vos informations de cartes sont enregistr\u00e9es","en":"Card details are saved"}}};
export const STRIPE_WARNING_ADDRESS = {"id":"stripe.warning.address","translations":{"stripe":{"fr":"Veuillez entrer une adresse dans votre profil","en":"Please enter an address in your profile"}}};
export const STRIPE_WARNING_BIRTHDATE = {"id":"stripe.warning.birthdate","translations":{"stripe":{"fr":"Veuillez entrer une date de naissance dans votre profil","en":"Please enter a date of birth in your profile"}}};
export const STRIPE_WARNING_ID = {"id":"stripe.warning.id","translations":{"stripe":{"fr":"Veuillez charger une pi\u00e8ce d\u2019identit\u00e9 dans votre profil.","en":"Please upload an ID to your profile."}}};
export const STRIPE_WARNING_PHONE = {"id":"stripe.warning.phone","translations":{"stripe":{"fr":"Veuillez entrer un num\u00e9ro de t\u00e9l\u00e9phone dans votre profil","en":"Please enter a date of birth in your profile"}}};
export const STRIPE_TRANSLATIONS_DOCUMENT_CORRUPT = {"id":"stripe.translations.document_corrupt","translations":{"stripe":{"fr":"Le document upload\u00e9 est invalid ou corrompu, merci d'uploader une nouvelle image","en":"The uploaded document is invalid, please upload a new image"}}};
export const STRIPE_TRANSLATIONS_DOCUMENT_COUNTRY_NOT_SUPPORTED = {"id":"stripe.translations.document_country_not_supported","translations":{"stripe":{"fr":"Le pays de votre document n'est pas support\u00e9","en":"Your document's country of origin is not supported"}}};
export const STRIPE_TRANSLATIONS_DOCUMENT_EXPIRED = {"id":"stripe.translations.document_expired","translations":{"stripe":{"fr":"Le document est expir\u00e9","en":"Document is expired"}}};
export const STRIPE_TRANSLATIONS_DOCUMENT_FAILED_OTHER = {"id":"stripe.translations.document_failed_other","translations":{"stripe":{"fr":"Une erreur est survenue sur le document, merci d'uploader une nouvelle image","en":"An error occurred on the document, please upload a new image"}}};
export const STRIPE_TRANSLATIONS_DOCUMENT_FAILED_TEST_MODE = {"id":"stripe.translations.document_failed_test_mode","translations":{"stripe":{"fr":"Une erreur est survenue sur l'upload du document","en":"An error occurred while uploading the document"}}};
export const STRIPE_TRANSLATIONS_DOCUMENT_FRAUDULENT = {"id":"stripe.translations.document_fraudulent","translations":{"stripe":{"fr":"Le document est faudulent, merci d'uploader une nouvelle image","en":"The uploaded document is invalid, please upload a new image"}}};
export const STRIPE_TRANSLATIONS_DOCUMENT_FAILED_GREYSCALE = {"id":"stripe.translations.document_failed_greyscale","translations":{"stripe":{"fr":"Le document que vous avez upload\u00e9 n'a pas pu \u00eatre analys\u00e9, merci d'uploader une nouvelle image","en":"The document you uploaded could not be analyzed, please upload a new image"}}};
export const STRIPE_TRANSLATIONS_DOCUMENT_INCOMPLETE = {"id":"stripe.translations.document_incomplete","translations":{"stripe":{"fr":"Le document est incomplet merci d'uploader une nouvelle image","en":"The document is incomplete please upload a new image"}}};
export const STRIPE_TRANSLATIONS_DOCUMENT_INVALID = {"id":"stripe.translations.document_invalid","translations":{"stripe":{"fr":"Le document est invalide, merci d'uploader une nouvelle image","en":"The document is invalid, please upload a new image"}}};
export const STRIPE_TRANSLATIONS_DOCUMENT_MANIPULATED = {"id":"stripe.translations.document_manipulated","translations":{"stripe":{"fr":"Le document a \u00e9t\u00e9 \u00e9dit\u00e9, merci d'uploader une nouvelle image","en":"The document was edited, please upload a new image"}}};
export const STRIPE_TRANSLATIONS_DOCUMENT_MISSING_BACK = {"id":"stripe.translations.document_missing_back","translations":{"stripe":{"fr":"Veuillez uploader le back","en":"Please upload the back-side"}}};
export const STRIPE_TRANSLATIONS_DOCUMENT_TYPE_NOT_SUPPORTED = {"id":"stripe.translations.document_type_not_supported","translations":{"stripe":{"fr":"Le type de document n'est pas support\u00e9.","en":"This document type is not supported."}}};
export const STRIPE_TRANSLATIONS_INCORRECT_NUMBER = {"id":"stripe.translations.incorrect_number","translations":{"stripe":{"fr":"Le num\u00e9ro de carte est incorrect.","en":"Card number is incorrect."}}};
export const STRIPE_TRANSLATIONS_DOCUMENT_ADDRESS_MISMATCH = {"id":"stripe.translations.document_address_mismatch","translations":{"stripe":{"fr":"L'adresse sur les documents n'est pas la m\u00eames","en":"The address on the documents is not the same"}}};
export const STRIPE_TRANSLATIONS_DOCUMENT_DOB_MISMATCH = {"id":"stripe.translations.document_dob_mismatch","translations":{"stripe":{"fr":"La date de naissance sur le document n'est pas la m\u00eame que celle entr\u00e9e sur votre profil","en":"The date of birth on the document is not the same as the date entered on your profile"}}};
export const STRIPE_TRANSLATIONS_DOCUMENT_ID_NUMBER_MISMATCH = {"id":"stripe.translations.document_id_number_mismatch","translations":{"stripe":{"fr":"Le recto et verso que vous avez upload\u00e9 ne proviennent pas du m\u00eame document","en":"The front-side and back-side you uploaded do not come from the same document"}}};
export const STRIPE_TRANSLATIONS_DOCUMENT_NAME_MISMATCH = {"id":"stripe.translations.document_name_mismatch","translations":{"stripe":{"fr":"Le nom sur le document n'est pas celui entr\u00e9 dans votre profil","en":"The name on the document is not the one entered in your profile"}}};
export const STRIPE_TRANSLATIONS_DOCUMENT_NATIONALITY_MISMATCH = {"id":"stripe.translations.document_nationality_mismatch","translations":{"stripe":{"fr":"Votre nationalit\u00e9 n'est pas celle entr\u00e9e dans votre profil","en":"Your nationality is not the one entered in your profile"}}};
export const STRIPE_TRANSLATIONS_FAILED_KEYED_IDENTITY = {"id":"stripe.translations.failed_keyed_identity","translations":{"stripe":{"fr":"Vous n'avez fourni aucun document d'identit\u00e9","en":"You have not provided any ID documents"}}};
export const STRIPE_TRANSLATIONS_FAILED_OTHER = {"id":"stripe.translations.failed_other","translations":{"stripe":{"fr":"Une erreur est survenue sur le document, merci d'uploader une nouvelle image","en":"An error occurred on the document, please upload a new image"}}};
export const STRIPE_TRANSLATIONS_INVALID_NUMBER = {"id":"stripe.translations.invalid_number","translations":{"stripe":{"fr":"Num\u00e9ro de carte invalide","en":"Card number is invalid."}}};
export const STRIPE_TRANSLATIONS_INVALID_EXPIRY_MONTH = {"id":"stripe.translations.invalid_expiry_month","translations":{"stripe":{"fr":"Le mois d\u2019expiration de la carte n\u2019est pas valide.","en":"Card expiry month is invalid."}}};
export const STRIPE_TRANSLATIONS_INVALID_EXPIRY_YEAR = {"id":"stripe.translations.invalid_expiry_year","translations":{"stripe":{"fr":"L\u2019ann\u00e9e d\u2019expiration de la carte n\u2019est pas valide.","en":"Card expiry year is invalid."}}};
export const STRIPE_TRANSLATIONS_INVALID_CVC = {"id":"stripe.translations.invalid_cvc","translations":{"stripe":{"fr":"Le code de s\u00e9curit\u00e9 de la carte n\u2019est pas valide.","en":"The card's security code is invalid."}}};
export const STRIPE_TRANSLATIONS_EXPIRED_CARD = {"id":"stripe.translations.expired_card","translations":{"stripe":{"fr":"La carte a expir\u00e9.","en":"Card has expired."}}};
export const STRIPE_TRANSLATIONS_INCORRECT_CVC = {"id":"stripe.translations.incorrect_cvc","translations":{"stripe":{"fr":"Le code de s\u00e9curit\u00e9 de la carte est incorrect.","en":"The card's security code is incorrect."}}};
export const STRIPE_TRANSLATIONS_INCORRECT_ZIP = {"id":"stripe.translations.incorrect_zip","translations":{"stripe":{"fr":"La validation du code postal de la carte a \u00e9chou\u00e9.","en":"The zip code validation failed."}}};
export const STRIPE_TRANSLATIONS_CARD_DECLINED = {"id":"stripe.translations.card_declined","translations":{"stripe":{"fr":"La carte a \u00e9t\u00e9 refus\u00e9e.","en":"Card declined"}}};
export const STRIPE_TRANSLATIONS_MISSING = {"id":"stripe.translations.missing","translations":{"stripe":{"fr":"Aucun client associ\u00e9 \u00e0 cette carte","en":"No customer associated with this card"}}};
export const STRIPE_TRANSLATIONS_PROCESSING_ERROR = {"id":"stripe.translations.processing_error","translations":{"stripe":{"fr":"Une erreur est survenue lors du contr\u00f4le de la carte.","en":"An error occurred while checking card."}}};
export const STRIPE_TRANSLATIONS_RATE_LIMIT = {"id":"stripe.translations.rate_limit","translations":{"stripe":{"fr":"Une erreur est survenue en raison d'un trop grand nombre de requ\u00eates vers le serveur. Veuillez nous contacter si vous rencontrez cette erreur syst\u00e9matiquement.","en":"An error occurred due to too many requests to the server. Please contact us if you encounter this error systematically."}}};
export const STRIPE_TRANSLATIONS_ACCOUNT_ALREADY_EXIST = {"id":"stripe.translations.account_already_exist","translations":{"stripe":{"fr":"L'adresse \u00e9lectronique fournie pour la cr\u00e9ation d'un compte diff\u00e9r\u00e9 est d\u00e9j\u00e0 associ\u00e9e \u00e0 un compte. Utilisez le flux OAuth pour connecter le compte existant \u00e0 votre plateforme.","en":"The email address provided for creating a deferred account is already associated with an existing account. Use the OAuth flow to connect the existing account to your platform."}}};
export const STRIPE_TRANSLATIONS_ACCOUNT_COUNTRY_INVALID_ADDRESS = {"id":"stripe.translations.account_country_invalid_address","translations":{"stripe":{"fr":"Le pays de l'adresse commerciale fournie ne correspond pas au pays du compte. Les entreprises doivent \u00eatre situ\u00e9es dans le m\u00eame pays que le compte.","en":"The country of the business address provided does not match the country of the account. Businesses must be located in the same country as the account."}}};
export const STRIPE_TRANSLATIONS_ACCOUNT_INVALID = {"id":"stripe.translations.account_invalid","translations":{"stripe":{"fr":"L'ID de compte fourni comme valeur pour l'en-t\u00eate Stripe-Account n'est pas valide. V\u00e9rifiez que vos demandes sp\u00e9cifient un identifiant de compte valide.","en":"The account ID provided as a value for the Stripe-Account header is invalid. Check that your requests are specifying a valid account ID."}}};
export const STRIPE_TRANSLATIONS_ACCOUNT_NUMBER_INVALID = {"id":"stripe.translations.account_number_invalid","translations":{"stripe":{"fr":"Le num\u00e9ro de compte bancaire fourni n'est pas valide (par exemple, il manque des chiffres). Les informations relatives aux comptes bancaires varient d'un pays \u00e0 l'autre. Nous vous recommandons de cr\u00e9er des validations dans vos formulaires d'inscription sur la base des formats de compte bancaire que nous fournissons.","en":"The bank account number provided is invalid (e.g., missing digits). Bank account information varies from country to country. We recommend creating validations in your entry forms based on the bank account formats we provide."}}};
export const STRIPE_TRANSLATIONS_ALIPAY_UPGRADE_REQUIRED = {"id":"stripe.translations.alipay_upgrade_required","translations":{"stripe":{"fr":"Cette m\u00e9thode de cr\u00e9ation de paiements Alipay n'est plus prise en charge. Veuillez mettre \u00e0 jour votre int\u00e9gration pour utiliser Sources \u00e0 la place.","en":"This method for creating Alipay payments is not supported anymore. Please upgrade your integration to use Sources instead."}}};
export const STRIPE_TRANSLATIONS_AMOUNT_TOO_LARGE = {"id":"stripe.translations.amount_too_large","translations":{"stripe":{"fr":"Le montant sp\u00e9cifi\u00e9 est sup\u00e9rieur au montant maximum autoris\u00e9. Utilisez un montant inf\u00e9rieur et r\u00e9essayez.","en":"The specified amount is greater than the maximum amount allowed. Use a lower amount and try again."}}};
export const STRIPE_TRANSLATIONS_AMOUNT_TOO_SMALL = {"id":"stripe.translations.amount_too_small","translations":{"stripe":{"fr":"Le montant sp\u00e9cifi\u00e9 est inf\u00e9rieur au montant minimum autoris\u00e9. Utilisez un montant plus \u00e9lev\u00e9 et r\u00e9essayez.","en":"The specified amount is less than the minimum amount allowed. Use a higher amount and try again."}}};
export const STRIPE_TRANSLATIONS_API_KEY_EXPIRED = {"id":"stripe.translations.api_key_expired","translations":{"stripe":{"fr":"La cl\u00e9 API fournie a expir\u00e9. Obtenez vos cl\u00e9s d'API actuelles \u00e0 partir du tableau de bord et mettez \u00e0 jour votre int\u00e9gration pour les utiliser.","en":"The API key provided has expired. Obtain your current API keys from the Dashboard and update your integration to use them."}}};
export const STRIPE_TRANSLATIONS_BALANCE_INSUFFICIENT = {"id":"stripe.translations.balance_insufficient","translations":{"stripe":{"fr":"Le virement ou le paiement n'a pas pu \u00eatre effectu\u00e9 car le compte associ\u00e9 ne dispose pas d'un solde suffisant. Cr\u00e9ez un nouveau virement ou paiement en utilisant un montant inf\u00e9rieur ou \u00e9gal au solde disponible du compte.","en":"The transfer or payout could not be completed because the associated account does not have a sufficient balance available. Create a new transfer or payout using an amount less than or equal to the account\u2019s available balance."}}};
export const STRIPE_TRANSLATIONS_BANK_ACCOUNT_EXISTS = {"id":"stripe.translations.bank_account_exists","translations":{"stripe":{"fr":"Le compte bancaire fourni existe d\u00e9j\u00e0 dans l'objet Client sp\u00e9cifi\u00e9. Si le compte bancaire doit \u00eatre rattach\u00e9 \u00e0 un autre client, il convient d'indiquer l'identifiant correct du client lors de la nouvelle demande.","en":"The bank account provided already exists on the specified Customer object. If the bank account should also be attached to a different customer, include the correct customer ID when making the request again."}}};
export const STRIPE_TRANSLATIONS_BANK_ACCOUNT_UNUSABLE = {"id":"stripe.translations.bank_account_unusable","translations":{"stripe":{"fr":"Le compte bancaire fourni ne peut pas \u00eatre utilis\u00e9 pour les paiements. Un autre compte bancaire doit \u00eatre utilis\u00e9.","en":"The bank account provided cannot be used for payouts. A different bank account must be used."}}};
export const STRIPE_TRANSLATIONS_BANK_ACCOUNT_UNVERIFIED = {"id":"stripe.translations.bank_account_unverified","translations":{"stripe":{"fr":"Votre plateforme Connect tente de partager un compte bancaire non v\u00e9rifi\u00e9 avec un compte connect\u00e9.","en":"Your Connect platform is attempting to share an unverified bank account with a connected account."}}};
export const STRIPE_TRANSLATIONS_BITCOIN_UPGRADE_REQUIRED = {"id":"stripe.translations.bitcoin_upgrade_required","translations":{"stripe":{"fr":"Cette m\u00e9thode de cr\u00e9ation de paiements en bitcoins n'est plus prise en charge. Veuillez mettre \u00e0 jour votre int\u00e9gration pour utiliser Sources \u00e0 la place.","en":"This method for creating Bitcoin payments is not supported anymore. Please upgrade your integration to use Sources instead."}}};
export const STRIPE_TRANSLATIONS_CHARGE_ALREADY_CAPTURED = {"id":"stripe.translations.charge_already_captured","translations":{"stripe":{"fr":"La charge que vous essayez de capturer a d\u00e9j\u00e0 \u00e9t\u00e9 captur\u00e9e. Mettez \u00e0 jour la demande avec un identifiant de frais non captur\u00e9.","en":"The charge you\u2019re attempting to capture has already been captured. Update the request with an uncaptured charge ID."}}};
export const STRIPE_TRANSLATIONS_CHARGE_ALREADY_REFUNDED = {"id":"stripe.translations.charge_already_refunded","translations":{"stripe":{"fr":"Le montant que vous tentez de rembourser a d\u00e9j\u00e0 \u00e9t\u00e9 rembours\u00e9. Mettez \u00e0 jour la demande en utilisant l'identifiant d'un pr\u00e9l\u00e8vement qui n'a pas \u00e9t\u00e9 rembours\u00e9.","en":"The charge you\u2019re attempting to refund has already been refunded. Update the request to use the ID of a charge that has not been refunded."}}};
export const STRIPE_TRANSLATIONS_CHARGE_DISPUTED = {"id":"stripe.translations.charge_disputed","translations":{"stripe":{"fr":"Le montant que vous tentez de rembourser a \u00e9t\u00e9 d\u00e9bit\u00e9. Consultez la documentation sur les litiges pour savoir comment r\u00e9pondre au litige.","en":"The charge you\u2019re attempting to refund has been charged back. Check the disputes documentation to learn how to respond to the dispute."}}};
export const STRIPE_TRANSLATIONS_CHARGE_EXCEEDS_SOURCE_LIMIT = {"id":"stripe.translations.charge_exceeds_source_limit","translations":{"stripe":{"fr":"Ce pr\u00e9l\u00e8vement entra\u00eenerait un d\u00e9passement de la limite de traitement de la fen\u00eatre mobile pour ce type de source. Veuillez r\u00e9essayer plus tard ou nous contacter pour demander une limite de traitement plus \u00e9lev\u00e9e.","en":"This charge would cause you to exceed your rolling-window processing limit for this source type. Please retry the charge later, or contact us to request a higher processing limit."}}};
export const STRIPE_TRANSLATIONS_CHARGE_EXPIRED_FOR_CAPTURE = {"id":"stripe.translations.charge_expired_for_capture","translations":{"stripe":{"fr":"Les frais ne peuvent pas \u00eatre saisis car l'autorisation a expir\u00e9. Les frais d'autorisation et de saisie doivent \u00eatre saisis dans les sept jours.","en":"The charge cannot be captured as the authorization has expired. Auth and capture charges must be captured within seven days."}}};
export const STRIPE_TRANSLATIONS_COUNTRY_UNSUPPORTED = {"id":"stripe.translations.country_unsupported","translations":{"stripe":{"fr":"Votre plateforme a tent\u00e9 de cr\u00e9er un compte personnalis\u00e9 dans un pays qui n'est pas encore pris en charge. Assurez-vous que les utilisateurs ne peuvent s'inscrire que dans les pays pris en charge par les comptes personnalis\u00e9s.","en":"Your platform attempted to create a custom account in a country that is not yet supported. Make sure that users can only sign up in countries supported by custom accounts."}}};
export const STRIPE_TRANSLATIONS_COUPON_EXPIRED = {"id":"stripe.translations.coupon_expired","translations":{"stripe":{"fr":"Le coupon fourni pour un abonnement ou une commande a expir\u00e9. Cr\u00e9ez un nouveau coupon ou utilisez un coupon existant qui est valide.","en":"The coupon provided for a subscription or order has expired. Either create a new coupon, or use an existing one that is valid."}}};
export const STRIPE_TRANSLATIONS_CUSTOMER_MAX_SUBSCRIPTIONS = {"id":"stripe.translations.customer_max_subscriptions","translations":{"stripe":{"fr":"Le nombre maximum d'abonnements pour un client a \u00e9t\u00e9 atteint. Contactez-nous si vous recevez cette erreur.","en":"The maximum number of subscriptions for a customer has been reached. Contact us if you are receiving this error."}}};
export const STRIPE_TRANSLATIONS_EMAIL_INVALID = {"id":"stripe.translations.email_invalid","translations":{"stripe":{"fr":"L'adresse \u00e9lectronique n'est pas valide (par exemple, elle n'est pas correctement format\u00e9e). V\u00e9rifiez que l'adresse \u00e9lectronique est correctement format\u00e9e et qu'elle ne contient que des caract\u00e8res autoris\u00e9s.","en":"The email address is invalid (e.g., not properly formatted). Check that the email address is properly formatted and only includes allowed characters."}}};
export const STRIPE_TRANSLATIONS_IDEMPOTENCY_KEY_IN_USE = {"id":"stripe.translations.idempotency_key_in_use","translations":{"stripe":{"fr":"La cl\u00e9 d'idempotence fournie est actuellement utilis\u00e9e dans une autre demande. Cela se produit si votre int\u00e9gration effectue des demandes en double simultan\u00e9ment.","en":"The idempotency key provided is currently being used in another request. This occurs if your integration is making duplicate requests simultaneously."}}};
export const STRIPE_TRANSLATIONS_INCORRECT_ADDRESS = {"id":"stripe.translations.incorrect_address","translations":{"stripe":{"fr":"L'adresse de la carte est incorrecte. V\u00e9rifiez l'adresse de la carte ou utilisez une autre carte.","en":"The card\u2019s address is incorrect. Check the card\u2019s address or use a different card."}}};
export const STRIPE_TRANSLATIONS_INSTANT_PAYOUTS_UNSUPPORTED = {"id":"stripe.translations.instant_payouts_unsupported","translations":{"stripe":{"fr":"La carte de d\u00e9bit fournie comme compte externe ne permet pas les paiements instantan\u00e9s. Fournissez une autre carte de d\u00e9bit ou utilisez un compte bancaire \u00e0 la place.","en":"The debit card provided as an external account does not support instant payouts. Provide another debit card or use a bank account instead."}}};
export const STRIPE_TRANSLATIONS_INVALID_CARD_TYPE = {"id":"stripe.translations.invalid_card_type","translations":{"stripe":{"fr":"La carte fournie comme compte externe n'est pas une carte de d\u00e9bit. Fournir une carte de d\u00e9bit ou utiliser un compte bancaire \u00e0 la place.","en":"The card provided as an external account is not a debit card. Provide a debit card or use a bank account instead."}}};
export const STRIPE_TRANSLATIONS_INVALID_CHARGE_AMOUNT = {"id":"stripe.translations.invalid_charge_amount","translations":{"stripe":{"fr":"Le montant sp\u00e9cifi\u00e9 n'est pas valide. Le montant de la taxe doit \u00eatre un nombre entier positif dans la plus petite unit\u00e9 mon\u00e9taire et ne doit pas d\u00e9passer le montant minimum ou maximum.","en":"The specified amount is invalid. The charge amount must be a positive integer in the smallest currency unit, and not exceed the minimum or maximum amount."}}};
export const STRIPE_TRANSLATIONS_INVALID_SOURCE_USAGE = {"id":"stripe.translations.invalid_source_usage","translations":{"stripe":{"fr":"La source ne peut pas \u00eatre utilis\u00e9e car elle n'est pas dans le bon \u00e9tat (par exemple, une demande de paiement tente d'utiliser une source en attente, en \u00e9chec ou consomm\u00e9e). V\u00e9rifiez l'\u00e9tat de la source que vous essayez d'utiliser.","en":"The source cannot be used because it is not in the correct state (e.g., a charge request is trying to use a source with a pending, failed, or consumed source). Check the status of the source you are attempting to use."}}};
export const STRIPE_TRANSLATIONS_INVOICE_NO_CUSTOMER_LINE_ITEMS = {"id":"stripe.translations.invoice_no_customer_line_items","translations":{"stripe":{"fr":"An invoice cannot be generated for the specified customer as there are no pending invoice items. Check that the correct customer is being specified or create any necessary invoice items first.","en":"An invoice cannot be generated for the specified customer as there is no pending invoice items. Check that the correct customer is being specified or create any necessary invoice items first."}}};
export const STRIPE_TRANSLATIONS_INVOICE_NO_SUBSCRIPTION_LINE_ITEMS = {"id":"stripe.translations.invoice_no_subscription_line_items","translations":{"stripe":{"fr":"Une facture ne peut pas \u00eatre g\u00e9n\u00e9r\u00e9e pour l'abonnement sp\u00e9cifi\u00e9 car il n'y a pas d'\u00e9l\u00e9ments de facture en attente. V\u00e9rifiez que l'abonnement sp\u00e9cifi\u00e9 est correct ou cr\u00e9ez d'abord les \u00e9l\u00e9ments de facture n\u00e9cessaires.","en":"An invoice cannot be generated for the specified subscription as there are no pending invoice items. Check that the correct subscription is being specified or create any necessary invoice items first."}}};
export const STRIPE_TRANSLATIONS_INVOICE_NOT_EDITABLE = {"id":"stripe.translations.invoice_not_editable","translations":{"stripe":{"fr":"La facture sp\u00e9cifi\u00e9e ne peut plus \u00eatre modifi\u00e9e. Pensez plut\u00f4t \u00e0 cr\u00e9er des \u00e9l\u00e9ments de facture suppl\u00e9mentaires qui seront appliqu\u00e9s \u00e0 la facture suivante. Vous pouvez g\u00e9n\u00e9rer manuellement la facture suivante ou attendre qu'elle soit g\u00e9n\u00e9r\u00e9e automatiquement \u00e0 la fin du cycle de facturation.","en":"The specified invoice can no longer be edited. Instead, consider creating additional invoice items that will be applied to the next invoice. You can either manually generate the next invoice or wait for it to be automatically generated at the end of the billing cycle."}}};
export const STRIPE_TRANSLATIONS_INVOICE_UPCOMING_NONE = {"id":"stripe.translations.invoice_upcoming_none","translations":{"stripe":{"fr":"Il n'y a pas de facture \u00e0 venir pour le client sp\u00e9cifi\u00e9 \u00e0 pr\u00e9visualiser. Seuls les clients ayant des abonnements actifs ou des factures en attente ont des factures qui peuvent \u00eatre visualis\u00e9es.","en":"There is no upcoming invoice on the specified customer to preview. Only customers with active subscriptions or pending invoice items have invoices that can be previewed."}}};
export const STRIPE_TRANSLATIONS_LIVEMODE_MISMATCH = {"id":"stripe.translations.livemode_mismatch","translations":{"stripe":{"fr":"Les cl\u00e9s, requ\u00eates et objets de l'API en mode test et en mode r\u00e9el ne sont disponibles que dans le mode dans lequel ils se trouvent.","en":"Test and live mode API keys, requests, and objects are only available within the mode they are in."}}};
export const STRIPE_TRANSLATIONS_NOT_ALLOWED_ON_STANDARD_ACCOUNT = {"id":"stripe.translations.not_allowed_on_standard_account","translations":{"stripe":{"fr":"Les transferts et les paiements pour le compte d'un compte connect\u00e9 Standard ne sont pas autoris\u00e9s.","en":"Transfers and payouts on behalf of a Standard connected account are not allowed."}}};
export const STRIPE_TRANSLATIONS_ORDER_CREATION_FAILED = {"id":"stripe.translations.order_creation_failed","translations":{"stripe":{"fr":"La commande n'a pas pu \u00eatre cr\u00e9\u00e9e. V\u00e9rifiez les d\u00e9tails de la commande et r\u00e9essayez.","en":"The order could not be created. Check the order details and then try again."}}};
export const STRIPE_TRANSLATIONS_ORDER_REQUIRED_SETTINGS = {"id":"stripe.translations.order_required_settings","translations":{"stripe":{"fr":"La commande n'a pas pu \u00eatre trait\u00e9e car il manque des informations obligatoires. V\u00e9rifiez les informations fournies et r\u00e9essayez.","en":"The order could not be processed as it is missing required information. Check the information provided and try again."}}};
export const STRIPE_TRANSLATIONS_ORDER_STATUS_INVALID = {"id":"stripe.translations.order_status_invalid","translations":{"stripe":{"fr":"La commande ne peut pas \u00eatre mise \u00e0 jour car le statut fourni n'est pas valide ou ne suit pas le cycle de vie de la commande (par exemple, une commande ne peut pas passer de cr\u00e9\u00e9e \u00e0 ex\u00e9cut\u00e9e sans d'abord passer \u00e0 pay\u00e9e).","en":"The order cannot be updated because the status provided is either invalid or does not follow the order lifecycle (e.g., an order cannot transition from created to fulfilled without first transitioning to paid)."}}};
export const STRIPE_TRANSLATIONS_ORDER_UPSTREAM_TIMEOUT = {"id":"stripe.translations.order_upstream_timeout","translations":{"stripe":{"fr":"La demande a expir\u00e9. R\u00e9essayez plus tard.","en":"The request timed out. Try again later."}}};
export const STRIPE_TRANSLATIONS_OUT_OF_INVENTORY = {"id":"stripe.translations.out_of_inventory","translations":{"stripe":{"fr":"Le SKU est en rupture de stock. Si le stock est plus important, mettez \u00e0 jour la quantit\u00e9 de stock de ce SKU et r\u00e9essayez.","en":"The SKU is out of stock. If more stock is available, update the SKU\u2019s inventory quantity and try again."}}};
export const STRIPE_TRANSLATIONS_PARAMETER_INVALID_EMPTY = {"id":"stripe.translations.parameter_invalid_empty","translations":{"stripe":{"fr":"Une ou plusieurs valeurs requises n'ont pas \u00e9t\u00e9 fournies. Veillez \u00e0 ce que les demandes incluent tous les param\u00e8tres requis.","en":"One or more required values were not provided. Make sure requests include all required parameters."}}};
export const STRIPE_TRANSLATIONS_PARAMETER_INVALID_INTEGER = {"id":"stripe.translations.parameter_invalid_integer","translations":{"stripe":{"fr":"Un ou plusieurs param\u00e8tres requi\u00e8rent un nombre entier, mais les valeurs fournies sont d'un type diff\u00e9rent. Assurez-vous que seules les valeurs prises en charge sont fournies pour chaque attribut. Reportez-vous \u00e0 la documentation de notre API pour conna\u00eetre le type de donn\u00e9es pris en charge par chaque attribut.","en":"One or more of the parameters requires an integer, but the values provided were a different type. Make sure that only supported values are provided for each attribute. Refer to our API documentation to look up the type of data each attribute supports."}}};
export const STRIPE_TRANSLATIONS_PARAMETER_INVALID_STRING_BLANK = {"id":"stripe.translations.parameter_invalid_string_blank","translations":{"stripe":{"fr":"Une ou plusieurs valeurs fournies ne contiennent que des espaces. V\u00e9rifiez les valeurs de votre demande et mettez \u00e0 jour celles qui ne contiennent que des espaces.","en":"One or more values provided only included whitespace. Check the values in your request and update any that contain only whitespace."}}};
export const STRIPE_TRANSLATIONS_PARAMETER_INVALID_STRING_EMPTY = {"id":"stripe.translations.parameter_invalid_string_empty","translations":{"stripe":{"fr":"Une ou plusieurs valeurs de cha\u00eene requises sont vides. Assurez-vous que les valeurs de la cha\u00eene contiennent au moins un caract\u00e8re.","en":"One or more required string values is empty. Make sure that string values contain at least one character."}}};
export const STRIPE_TRANSLATIONS_PARAMETER_MISSING = {"id":"stripe.translations.parameter_missing","translations":{"stripe":{"fr":"Une ou plusieurs valeurs requises sont manquantes. Consultez la documentation de notre API pour conna\u00eetre les valeurs requises pour cr\u00e9er ou modifier la ressource sp\u00e9cifi\u00e9e.","en":"One or more required values are missing. Check our API documentation to see which values are required to create or modify the specified resource."}}};
export const STRIPE_TRANSLATIONS_PARAMETER_UNKNOWN = {"id":"stripe.translations.parameter_unknown","translations":{"stripe":{"fr":"La demande contient un ou plusieurs param\u00e8tres inattendus. Supprimez-les et r\u00e9essayez.","en":"The request contains one or more unexpected parameters. Remove these and try again."}}};
export const STRIPE_TRANSLATIONS_PARAMETERS_EXCLUSIVE = {"id":"stripe.translations.parameters_exclusive","translations":{"stripe":{"fr":"Deux param\u00e8tres ou plus s'excluant mutuellement ont \u00e9t\u00e9 fournis. Consultez la documentation de notre API ou le message d'erreur renvoy\u00e9 pour conna\u00eetre les valeurs autoris\u00e9es lors de la cr\u00e9ation ou de la modification de la ressource sp\u00e9cifi\u00e9e.","en":"Two or more mutually exclusive parameters were provided. Check our API documentation or the returned error message to see which values are permitted when creating or modifying the specified resource."}}};
export const STRIPE_TRANSLATIONS_PAYMENT_INTENT_AUTHENTICATION_FAILURE = {"id":"stripe.translations.payment_intent_authentication_failure","translations":{"stripe":{"fr":"L'authentification de la source fournie a \u00e9chou\u00e9. Fournissez les donn\u00e9es source_data ou une nouvelle source pour tenter \u00e0 nouveau d'ex\u00e9cuter ce PaymentIntent.","en":"The provided source has failed authentication. Provide source_data or a new source to attempt to fulfill this PaymentIntent again."}}};
export const STRIPE_TRANSLATIONS_PAYMENT_INTENT_INCOMPATIBLE_PAYMENT_METHOD = {"id":"stripe.translations.payment_intent_incompatible_payment_method","translations":{"stripe":{"fr":"Le PaymentIntent s'attendait \u00e0 une m\u00e9thode de paiement avec des propri\u00e9t\u00e9s diff\u00e9rentes de celles qui ont \u00e9t\u00e9 fournies.","en":"The PaymentIntent expected a payment method with different properties than what was provided."}}};
export const STRIPE_TRANSLATIONS_PAYMENT_INTENT_INVALID_PARAMETER = {"id":"stripe.translations.payment_intent_invalid_parameter","translations":{"stripe":{"fr":"Un ou plusieurs param\u00e8tres fournis n'\u00e9taient pas autoris\u00e9s pour l'op\u00e9ration donn\u00e9e sur le PaymentIntent. Consultez notre r\u00e9f\u00e9rence API ou le message d'erreur renvoy\u00e9 pour savoir quelles valeurs n'\u00e9taient pas correctes pour ce PaymentIntent.","en":"One or more provided parameters was not allowed for the given operation on the PaymentIntent. Check our API reference or the returned error message to see which values were not correct for that PaymentIntent."}}};
export const STRIPE_TRANSLATIONS_PAYMENT_INTENT_PAYMENT_ATTEMPT_FAILED = {"id":"stripe.translations.payment_intent_payment_attempt_failed","translations":{"stripe":{"fr":"La derni\u00e8re tentative de paiement pour le PaymentIntent a \u00e9chou\u00e9. V\u00e9rifiez la propri\u00e9t\u00e9 last_payment_error du PaymentIntent pour plus de d\u00e9tails, et fournissez source_data ou une nouvelle source pour tenter d'ex\u00e9cuter \u00e0 nouveau ce PaymentIntent.","en":"The latest payment attempt for the PaymentIntent has failed. Check the last_payment_error property on the PaymentIntent for more details, and provide source_data or a new source to attempt to fulfill this PaymentIntent again."}}};
export const STRIPE_TRANSLATIONS_PAYMENT_INTENT_UNEXPECTED_STATE = {"id":"stripe.translations.payment_intent_unexpected_state","translations":{"stripe":{"fr":"L'\u00e9tat du PaymentIntent \u00e9tait incompatible avec l'op\u00e9ration que vous essayiez d'effectuer.","en":"The PaymentIntent\u2019s state was incompatible with the operation you were trying to perform."}}};
export const STRIPE_TRANSLATIONS_PAYMENT_METHOD_UNACTIVATED = {"id":"stripe.translations.payment_method_unactivated","translations":{"stripe":{"fr":"Le pr\u00e9l\u00e8vement ne peut pas \u00eatre cr\u00e9\u00e9 car le mode de paiement utilis\u00e9 n'a pas \u00e9t\u00e9 activ\u00e9. Activez le mode de paiement dans le tableau de bord, puis r\u00e9essayez.","en":"The charge cannot be created as the payment method used has not been activated. Activate the payment method in the Dashboard, then try again."}}};
export const STRIPE_TRANSLATIONS_PAYMENT_METHOD_UNEXPECTED_STATE = {"id":"stripe.translations.payment_method_unexpected_state","translations":{"stripe":{"fr":"L'\u00e9tat du mode de paiement fourni est incompatible avec l'op\u00e9ration que vous essayez d'effectuer. Confirmez que le mode de paiement est dans un \u00e9tat autoris\u00e9 pour l'op\u00e9ration donn\u00e9e avant de tenter de l'effectuer.","en":"The provided payment method\u2019s state was incompatible with the operation you were trying to perform. Confirm that the payment method is in an allowed state for the given operation before attempting to perform it."}}};
export const STRIPE_TRANSLATIONS_PAYOUTS_NOT_ALLOWED = {"id":"stripe.translations.payouts_not_allowed","translations":{"stripe":{"fr":"Les paiements ont \u00e9t\u00e9 d\u00e9sactiv\u00e9s sur le compte connect\u00e9. V\u00e9rifiez le statut du compte connect\u00e9 pour voir si des informations suppl\u00e9mentaires doivent \u00eatre fournies ou si les paiements ont \u00e9t\u00e9 d\u00e9sactiv\u00e9s pour une autre raison.","en":"Payouts have been disabled on the connected account. Check the connected account\u2019s status to see if any additional information needs to be provided, or if payouts have been disabled for another reason."}}};
export const STRIPE_TRANSLATIONS_PLATFORM_API_KEY_EXPIRED = {"id":"stripe.translations.platform_api_key_expired","translations":{"stripe":{"fr":"La cl\u00e9 API fournie par votre plateforme Connect a expir\u00e9. Cela se produit si votre plateforme a g\u00e9n\u00e9r\u00e9 une nouvelle cl\u00e9 ou si le compte connect\u00e9 a \u00e9t\u00e9 d\u00e9connect\u00e9 de la plateforme. Obtenez vos cl\u00e9s API actuelles \u00e0 partir du tableau de bord et mettez \u00e0 jour votre int\u00e9gration, ou contactez l'utilisateur et reconnectez le compte.","en":"The API key provided by your Connect platform has expired. This occurs if your platform has either generated a new key or the connected account has been disconnected from the platform. Obtain your current API keys from the Dashboard and update your integration, or reach out to the user and reconnect the account."}}};
export const STRIPE_TRANSLATIONS_POSTAL_CODE_INVALID = {"id":"stripe.translations.postal_code_invalid","translations":{"stripe":{"fr":"Le code postal fourni \u00e9tait incorrect.","en":"The postal code provided was incorrect."}}};
export const STRIPE_TRANSLATIONS_PRODUCT_INACTIVE = {"id":"stripe.translations.product_inactive","translations":{"stripe":{"fr":"Le produit auquel appartient cet SKU n'est plus disponible \u00e0 l'achat.","en":"The product this SKU belongs to is no longer available for purchase."}}};
export const STRIPE_TRANSLATIONS_RESOURCE_ALREADY_EXISTS = {"id":"stripe.translations.resource_already_exists","translations":{"stripe":{"fr":"Une ressource avec un identifiant sp\u00e9cifi\u00e9 par l'utilisateur (par exemple, un plan ou un coupon) existe d\u00e9j\u00e0. Utilisez une valeur diff\u00e9rente et unique pour l'identifiant et r\u00e9essayez.","en":"A resource with a user-specified ID (e.g., plan or coupon) already exists. Use a different, unique value for id and try again."}}};
export const STRIPE_TRANSLATIONS_RESOURCE_MISSING = {"id":"stripe.translations.resource_missing","translations":{"stripe":{"fr":"L'identifiant fourni n'est pas valide. Soit la ressource n'existe pas, soit un identifiant pour une ressource diff\u00e9rente a \u00e9t\u00e9 fourni.","en":"The ID provided is not valid. Either the resource does not exist, or an ID for a different resource has been provided."}}};
export const STRIPE_TRANSLATIONS_ROUTING_NUMBER_INVALID = {"id":"stripe.translations.routing_number_invalid","translations":{"stripe":{"fr":"The bank routing number provided is invalid.","en":"The bank routing number provided is invalid."}}};
export const STRIPE_TRANSLATIONS_SECRET_KEY_REQUIRED = {"id":"stripe.translations.secret_key_required","translations":{"stripe":{"fr":"La cl\u00e9 API fournie est une cl\u00e9 publiable, mais une cl\u00e9 secr\u00e8te est n\u00e9cessaire. Obtenez vos cl\u00e9s d'API actuelles \u00e0 partir du tableau de bord et mettez \u00e0 jour votre int\u00e9gration pour les utiliser.","en":"The API key provided is a publishable key, but a secret key is required. Obtain your current API keys from the Dashboard and update your integration to use them."}}};
export const STRIPE_TRANSLATIONS_SEPA_UNSUPPORTED_ACCOUNT = {"id":"stripe.translations.sepa_unsupported_account","translations":{"stripe":{"fr":"Votre compte ne supporte pas les paiements SEPA.","en":"Your account does not support SEPA payments."}}};
export const STRIPE_TRANSLATIONS_SHIPPING_CALCULATION_FAILED = {"id":"stripe.translations.shipping_calculation_failed","translations":{"stripe":{"fr":"Le calcul de l'exp\u00e9dition a \u00e9chou\u00e9 car les informations fournies \u00e9taient incorrectes ou n'ont pas pu \u00eatre v\u00e9rifi\u00e9es.","en":"Shipping calculation failed as the information provided was either incorrect or could not be verified."}}};
export const STRIPE_TRANSLATIONS_SKU_INACTIVE = {"id":"stripe.translations.sku_inactive","translations":{"stripe":{"fr":"Le SKU est inactif et n'est plus disponible \u00e0 l'achat. Utilisez un autre SKU ou rendez le SKU actuel \u00e0 nouveau actif.","en":"The SKU is inactive and no longer available for purchase. Use a different SKU, or make the current SKU active again."}}};
export const STRIPE_TRANSLATIONS_STATE_UNSUPPORTED = {"id":"stripe.translations.state_unsupported","translations":{"stripe":{"fr":"Se produit lors de la fourniture des informations legal_entity pour un compte personnalis\u00e9 am\u00e9ricain, si l'\u00e9tat fourni n'est pas pris en charge. (Il s'agit principalement d'\u00e9tats et de territoires associ\u00e9s).","en":"Occurs when providing the legal_entity information for a U.S. custom account, if the provided state is not supported. (This is mostly associated states and territories.)"}}};
export const STRIPE_TRANSLATIONS_TAX_ID_INVALID = {"id":"stripe.translations.tax_id_invalid","translations":{"stripe":{"fr":"Le num\u00e9ro d'identification fiscale fourni n'est pas valide (par exemple, il manque des chiffres). Le num\u00e9ro d'identification fiscale varie d'un pays \u00e0 l'autre, mais il doit comporter au moins neuf chiffres.","en":"The tax ID number provided is invalid (e.g., missing digits). Tax ID information varies from country to country, but must be at least nine digits."}}};
export const STRIPE_TRANSLATIONS_TAXES_CALCULATION_FAILED = {"id":"stripe.translations.taxes_calculation_failed","translations":{"stripe":{"fr":"Le calcul de la taxe pour la commande a \u00e9chou\u00e9.","en":"Tax calculation for the order failed."}}};
export const STRIPE_TRANSLATIONS_TESTMODE_CHARGES_ONLY = {"id":"stripe.translations.testmode_charges_only","translations":{"stripe":{"fr":"Votre compte n'a pas \u00e9t\u00e9 activ\u00e9 et ne peut effectuer que des pr\u00e9l\u00e8vements de test. Activez votre compte dans le tableau de bord pour commencer \u00e0 traiter des frais r\u00e9els.","en":"Your account has not been activated and can only make test charges. Activate your account in the Dashboard to begin processing live charges."}}};
export const STRIPE_TRANSLATIONS_TLS_VERSION_UNSUPPORTED = {"id":"stripe.translations.tls_version_unsupported","translations":{"stripe":{"fr":"Votre int\u00e9gration utilise une ancienne version de TLS qui n'est pas prise en charge. Vous devez utiliser TLS 1.2 ou une version sup\u00e9rieure.","en":"Your integration is using an older version of TLS that is unsupported. You must be using TLS 1.2 or above."}}};
export const STRIPE_TRANSLATIONS_TOKEN_ALREADY_USED = {"id":"stripe.translations.token_already_used","translations":{"stripe":{"fr":"Le jeton fourni a d\u00e9j\u00e0 \u00e9t\u00e9 utilis\u00e9. Vous devez cr\u00e9er un nouveau jeton avant de pouvoir r\u00e9essayer cette demande.","en":"The token provided has already been used. You must create a new token before you can retry this request."}}};
export const STRIPE_TRANSLATIONS_TOKEN_IN_USE = {"id":"stripe.translations.token_in_use","translations":{"stripe":{"fr":"Le jeton fourni est actuellement utilis\u00e9 dans une autre demande. Cela se produit si votre int\u00e9gration effectue simultan\u00e9ment des demandes en double.","en":"The token provided is currently being used in another request. This occurs if your integration is making duplicate requests simultaneously."}}};
export const STRIPE_TRANSLATIONS_TRANSFERS_NOT_ALLOWED = {"id":"stripe.translations.transfers_not_allowed","translations":{"stripe":{"fr":"Le transfert demand\u00e9 ne peut pas \u00eatre cr\u00e9\u00e9. Contactez-nous si vous recevez cette erreur.","en":"The requested transfer cannot be created. Contact us if you are receiving this error."}}};
export const STRIPE_TRANSLATIONS_UPSTREAM_ORDER_CREATION_FAILED = {"id":"stripe.translations.upstream_order_creation_failed","translations":{"stripe":{"fr":"La commande n'a pas pu \u00eatre cr\u00e9\u00e9e. V\u00e9rifiez les d\u00e9tails de la commande et r\u00e9essayez.","en":"The order could not be created. Check the order details and then try again."}}};
export const STRIPE_TRANSLATIONS_URL_INVALID = {"id":"stripe.translations.url_invalid","translations":{"stripe":{"fr":"L'URL fournie n'est pas valide.","en":"The URL provided is invalid."}}};
export const PATIENT_BILLING_SUBSCRIPTION_TYPE_DESCRIPTION = {"id":"patient_billing_subscription_type.description","translations":{"subscription":{"fr":"Quel type d'abonnement voulez-vous ?","en":"What type of subscription do you want?"}}};
export const PATIENT_BILLING_SUBSCRIPTION_TYPE_LABEL = {"id":"patient_billing_subscription_type.label","translations":{"subscription":{"fr":"Type d'abonnement","en":"Subscription type"}}};
export const PATIENT_BILLING_SUBSCRIPTION_TYPE_PLACEHOLDER = {"id":"patient_billing_subscription_type.placeholder","translations":{"subscription":{"fr":"S\u00e9lectionnez une type d'abonnement","en":"Select subscription type"}}};
export const PATIENT_BILLING_SUBSCRIPTION_TYPE_VALUES_WITHOUT_CARD_READER = {"id":"patient_billing_subscription_type.values.without_card_reader","translations":{"subscription":{"fr":"Lecteur de carte vitale seul","en":"Carte vitale reader only"}}};
export const PATIENT_BILLING_SUBSCRIPTION_TYPE_VALUES_WITH_CARD_READER = {"id":"patient_billing_subscription_type.values.with_card_reader","translations":{"subscription":{"fr":"Lecteur de carte vitale et TPE","en":"Carte vitale reader and TPE"}}};
export const AVAILABILITY_DESCRIPTION = {"id":"availability.description","translations":{"subscription_group":{"fr":"La disponibilit\u00e9 du type d'abonnement","en":"Subscription type availability"}}};
export const AVAILABILITY_LABEL = {"id":"availability.label","translations":{"subscription_group":{"fr":"Disponibilit\u00e9","en":"Availability"}}};
export const AVAILABILITY_PLACEHOLDER = {"id":"availability.placeholder","translations":{"subscription_group":{"fr":"Choisissez la disponibilit\u00e9","en":"Choose availability"}}};
export const AVAILABILITY_VALUES1 = {"id":"availability.values.1","translations":{"subscription_group":{"fr":"Disponible","en":"Available"}}};
export const AVAILABILITY_VALUES2 = {"id":"availability.values.2","translations":{"subscription_group":{"fr":"Disponible bient\u00f4t","en":"Available soon"}}};
export const AVAILABILITY_VALUES3 = {"id":"availability.values.3","translations":{"subscription_group":{"fr":"Non disponible","en":"Unavailable"}}};
export const AVAILABILITY_VALUES4 = {"id":"availability.values.4","translations":{"subscription_group":{"fr":"Abonnement premium requis","en":"Premium subscription required"}}};
export const CANONICAL_DESCRIPTION = {"id":"canonical.description","translations":{"subscription_group":{"fr":"Le type d'abonnement","en":"Subscription type"}}};
export const CANONICAL_LABEL = {"id":"canonical.label","translations":{"subscription_group":{"fr":"Type d'abonnement","en":"Subscription type"}}};
export const CANONICAL_PLACEHOLDER = {"id":"canonical.placeholder","translations":{"subscription_group":{"fr":"Choisissez une valeur","en":"Choose value"}}};
export const CANONICAL_VALUES_CORE = {"id":"canonical.values.core","translations":{"subscription_group":{"fr":"Instamed","en":"Instamed"}}};
export const CANONICAL_VALUES_PATIENT_BILLING = {"id":"canonical.values.patient_billing","translations":{"subscription_group":{"fr":"T\u00e9l\u00e9transmission","en":"Patient billing"}}};
export const CANONICAL_VALUES_PRESCRIPTION_ASSISTANCE_SOFTWARE = {"id":"canonical.values.prescription_assistance_software","translations":{"subscription_group":{"fr":"Aide \u00e0 la prescription","en":"Prescription assistance"}}};
export const CANONICAL_VALUES_ONLINE_APPOINTMENTS = {"id":"canonical.values.online_appointments","translations":{"subscription_group":{"fr":"Prise de rendez-vous","en":"Appointment booking"}}};
export const CANONICAL_VALUES_LIA = {"id":"canonical.values.lia","translations":{"subscription_group":{"fr":"Lia, votre assistante virtuelle","en":"Lia, your virtual assistant"}}};
export const CANONICAL_VALUES_SECURED_MESSAGING = {"id":"canonical.values.secured_messaging","translations":{"subscription_group":{"fr":"Messagerie s\u00e9curis\u00e9e","en":"Secured messenger"}}};
export const CANONICAL_VALUES_TEST = {"id":"canonical.values.test","translations":{"subscription_group":{"fr":"Test","en":"Test"}}};
export const TYPE_MERGE_REQUEST = {"id":"type.merge_request","translations":{"task":{"fr":"Demande de fusion","en":"Merge Request"}}};
export const TYPE_SHARED_TO_MEDIC = {"id":"type.shared_to_medic","translations":{"task":{"fr":"Partage a un m\u00e9decin","en":"Shared to doctor"}}};
export const TYPE_SHARED_TO_INSTITUTION = {"id":"type.shared_to_institution","translations":{"task":{"fr":"Partage \u00e0 un autre \u00e9tablissement","en":"Shared to another facility"}}};
export const TYPE_LINK_NURSE_TO_MEDIC = {"id":"type.link_nurse_to_medic","translations":{"task":{"fr":"Liaison entre 2 utilisateurs","en":"Connection between 2 users"}}};
export const SHARING_REASON_DESCRIPTION = {"id":"sharing_reason.description","translations":{"task":{"fr":"Motif du partage","en":"Sharing Reason"}}};
export const SHARING_REASON_LABEL = {"id":"sharing_reason.label","translations":{"task":{"fr":"Choisissez un motif","en":"Choose reason"}}};
export const SHARING_REASON_PLACEHOLDER = {"id":"sharing_reason.placeholder","translations":{"task":{"fr":"Choisissez un motif","en":"Choose reason"}}};
export const SHARING_REASON1 = {"id":"sharing_reason.1","translations":{"task":{"fr":"Convocation","en":"Consultation"}}};
export const SHARING_REASON2 = {"id":"sharing_reason.2","translations":{"task":{"fr":"Ajout \u00e0 l'\u00e9quipe soignante","en":"Added to the care team"}}};
export const SHARING_REASON3 = {"id":"sharing_reason.3","translations":{"task":{"fr":"Partag\u00e9 par le patient","en":"Shared by the patient"}}};
export const SHARING_REASON4 = {"id":"sharing_reason.4","translations":{"task":{"fr":"Pour avoir acc\u00e8s \u00e0 l'historique du patient","en":"Access the patient history"}}};
export const SHARING_REASON5 = {"id":"sharing_reason.5","translations":{"task":{"fr":"Pour fusion de dossiers","en":"Merge records"}}};
export const SHARING_REASON6 = {"id":"sharing_reason.6","translations":{"task":{"fr":"Pour avis sur dossier","en":"Case review"}}};
export const SHARING_REASON7 = {"id":"sharing_reason.7","translations":{"task":{"fr":"Pour prise de RDV","en":"To take an appointment"}}};
export const TYPE_SINGLE_CHOICE = {"id":"type.single_choice","translations":{"template_variable":{"fr":"Choix simple","en":"Single choice"}}};
export const TYPE_MULTIPLE_CHOICE = {"id":"type.multiple_choice","translations":{"template_variable":{"fr":"Choix multiple","en":"Multiple choice"}}};
export const TYPE_TEXT = {"id":"type.text","translations":{"template_variable":{"fr":"Texte","en":"Text"}}};
export const TYPE_DATE = {"id":"type.date","translations":{"template_variable":{"fr":"Date","en":"Date"}}};
export const TYPE_NUMBER = {"id":"type.number","translations":{"template_variable":{"fr":"Nombre","en":"Number"}}};
export const TYPE_CONSTANT = {"id":"type.constant","translations":{"template_variable":{"fr":"Valeur fixe","en":"Constant value"}}};
export const STATUS_NOT_FOUND = {"id":"status.not_found","translations":{"transaction":{"fr":"Aucune transaction trouv\u00e9e","en":"No transaction found"}}};
export const STATUS_NOT_CAPTURED = {"id":"status.not_captured","translations":{"transaction":{"fr":"Pr\u00e9authoris\u00e9","en":"Pre-authorized"}}};
export const STATUS_REFUNDED = {"id":"status.refunded","translations":{"transaction":{"fr":"Rembours\u00e9","en":"Refunded"}}};
export const STATUS_PENDING_TRANSFER = {"id":"status.pending_transfer","translations":{"transaction":{"fr":"En attente de virement","en":"Pending transfer"}}};
export const STATUS_REJECTED = {"id":"status.rejected","translations":{"transaction":{"fr":"L'appel a \u00e9t\u00e9 refus\u00e9 par l'utilisateur","en":"The call was refused by the user"}}};
export const STATUS_DO_NOT_CHARGE = {"id":"status.do_not_charge","translations":{"transaction":{"fr":"L'appel n'a pas \u00e9t\u00e9 d\u00e9bit\u00e9","en":"The call was not charged"}}};
export const STATUS_TRANSFERED = {"id":"status.transfered","translations":{"transaction":{"fr":"Vir\u00e9 sur votre compte","en":"Transferred to your account"}}};
export const STATUS_CONFLICT = {"id":"status.conflict","translations":{"transaction":{"fr":"La transaction doit \u00eatre valid\u00e9e","en":"The transaction must be validated"}}};
export const ADMIN_LABEL_TEST_ACCOUNT = {"id":"admin.label.test_account","translations":{"translations":{"fr":"Compte de test","en":"Test account"}}};
export const ADMIN_LABEL_SELECT_INSTITUTION = {"id":"admin.label.select_institution","translations":{"translations":{"fr":"Choisir son institution li\u00e9e","en":"Select linked facility"}}};
export const ADMIN_LABEL_SELECT_LINKED_INSTITUTION = {"id":"admin.label.select_linked_institution","translations":{"translations":{"fr":"Choisir son institution li\u00e9e","en":"Select linked facility"}}};
export const ADMIN_LABEL_SELECT_SERVICES = {"id":"admin.label.select_services","translations":{"translations":{"fr":"Choisir ses services","en":"Choose departments"}}};
export const ADMIN_LABEL_EMAIL_USER_TO_IMPORT = {"id":"admin.label.email_user_to_import","translations":{"translations":{"fr":"E-mail de l'utilisateur \u00e0 importer","en":"User email to import"}}};
export const ADMIN_LABEL_MAIN_MOBILE_APP_USER = {"id":"admin.label.main_mobile_app_user","translations":{"translations":{"fr":"Utilisateur principal de l\u2019app mobile","en":"Primary mobile app user"}}};
export const ADMIN_LABEL_MOBILE_SERVICE_SCORE = {"id":"admin.label.mobile_service_score","translations":{"translations":{"fr":"Mobile Service Score","en":"Mobile service score"}}};
export const ADMIN_LABEL_MIN_HOUR = {"id":"admin.label.min_hour","translations":{"translations":{"fr":"","en":""}}};
export const ADMIN_ADMIN_MANAGE_PAGE_TITLE_MODIFY_PROFILE = {"id":"admin.admin.manage.page_title.modify_profile","translations":{"translations":{"fr":"Modifier le profil de {{ user }}","en":"Edit {{ user }} profile"}}};
export const ADMIN_ADMIN_MANAGE_PAGE_TITLE_CREATE_NEW_ADMIN = {"id":"admin.admin.manage.page_title.create_new_admin","translations":{"translations":{"fr":"Cr\u00e9er un nouvel admin","en":"New admin"}}};
export const ADMIN_ASSISTANT_CREATE_PAGE_TITLE = {"id":"admin.assistant.create.pageTitle","translations":{"translations":{"fr":"Cr\u00e9er un profil Secr\u00e9taire","en":"Create assistant profile"}}};
export const ADMIN_ALERTS_LIST_TITLE = {"id":"admin.alerts.list.title","translations":{"translations":{"fr":"Liste des alertes","en":"List of alerts"}}};
export const ADMIN_ALERTS_LIST_HISTORY_TITLE = {"id":"admin.alerts.list.history_title","translations":{"translations":{"fr":"Historique des alertes","en":"Alerts history"}}};
export const ADMIN_ALERTS_LIST_FILTER_DATE = {"id":"admin.alerts.list.filter.date","translations":{"translations":{"fr":"Date","en":"Date"}}};
export const ADMIN_ALERTS_LIST_FILTER_DATE_PLACEHOLDER = {"id":"admin.alerts.list.filter.date_placeholder","translations":{"translations":{"fr":"Dates","en":"Dates"}}};
export const ADMIN_ALERTS_LIST_FILTER_PATIENT = {"id":"admin.alerts.list.filter.patient","translations":{"translations":{"fr":"Patient","en":"Patient"}}};
export const ADMIN_ALERTS_LIST_FILTER_MEDIC = {"id":"admin.alerts.list.filter.medic","translations":{"translations":{"fr":"Soignant","en":"Caregiver"}}};
export const ADMIN_ALERTS_LIST_FILTER_SERVICES = {"id":"admin.alerts.list.filter.services","translations":{"translations":{"fr":"Services","en":"Departments"}}};
export const ADMIN_ALERTS_LIST_FILTER_BUTTON = {"id":"admin.alerts.list.filter.button","translations":{"translations":{"fr":"Filtrer","en":"Filter"}}};
export const ADMIN_ALERTS_LIST_TABLE_DATE = {"id":"admin.alerts.list.table.date","translations":{"translations":{"fr":"Date","en":"Date"}}};
export const ADMIN_ALERTS_LIST_TABLE_QUESTIONNAIRE = {"id":"admin.alerts.list.table.questionnaire","translations":{"translations":{"fr":"Questionnaire","en":"Questionnaire"}}};
export const ADMIN_ALERTS_LIST_TABLE_PATIENT = {"id":"admin.alerts.list.table.patient","translations":{"translations":{"fr":"Patient","en":"Patient"}}};
export const ADMIN_ALERTS_LIST_TABLE_MEDIC = {"id":"admin.alerts.list.table.medic","translations":{"translations":{"fr":"Soignant","en":"Caregivers"}}};
export const ADMIN_ALERTS_LIST_TABLE_INSTITUTION = {"id":"admin.alerts.list.table.institution","translations":{"translations":{"fr":"Institution","en":"Facility"}}};
export const ADMIN_ALERTS_LIST_TABLE_STATUS = {"id":"admin.alerts.list.table.status","translations":{"translations":{"fr":"Statut","en":"Status"}}};
export const ADMIN_ALERTS_LIST_TABLE_COMMENT = {"id":"admin.alerts.list.table.comment","translations":{"translations":{"fr":"Commentaire","en":"Comment"}}};
export const ADMIN_ALERTS_LIST_NOT_FOUND = {"id":"admin.alerts.list.not_found","translations":{"translations":{"fr":"Aucune alerte trouv\u00e9e","en":"No alertsfound"}}};
export const ADMIN_ALERTS_LIST_VIEW_DETAILS = {"id":"admin.alerts.list.view_details","translations":{"translations":{"fr":"Voir le d\u00e9tail","en":"View details"}}};
export const ADMIN_ALERTS_LIST_HIDE_DETAILS = {"id":"admin.alerts.list.hide_details","translations":{"translations":{"fr":"Masquer le d\u00e9tail","en":"Hide details"}}};
export const ADMIN_DELETED_CONFIRM = {"id":"admin.deleted.confirm","translations":{"translations":{"fr":"\u00cates-vous s\u00fbr de bien vouloir supprimer cet utilisateur ?","en":"Are you sure you want to remove this user?"}}};
export const ADMIN_DELETED_USER_DELETED = {"id":"admin.deleted.user_deleted","translations":{"translations":{"fr":"L'utilisateur a bien \u00e9t\u00e9 supprim\u00e9","en":"User successfully removed"}}};
export const ADMIN_DELETED_USER_RESTORED = {"id":"admin.deleted.user_restored","translations":{"translations":{"fr":"L'utilisateur a bien \u00e9t\u00e9 restaur\u00e9","en":"User has been successfully restored"}}};
export const ADMIN_DELETED_LIST_TITLE = {"id":"admin.deleted.list.title","translations":{"translations":{"fr":"Liste des comptes supprim\u00e9s","en":"List of deleted accounts"}}};
export const ADMIN_DELETED_LIST_PAGE_TITLE = {"id":"admin.deleted.list.pageTitle","translations":{"translations":{"fr":"Liste des comptes supprim\u00e9s","en":"List of deleted accounts"}}};
export const ADMIN_DELETED_LIST_CONFIRM_DELETE_ALL = {"id":"admin.deleted.list.confirm_delete_all","translations":{"translations":{"fr":"\u00cates-vous s\u00fbr de vouloir supprimer tous les utilisateurs de la liste ?","en":"Are you sure you want to remove all users from the list?"}}};
export const ADMIN_DELETED_LIST_DELETE_ALL_BUTTON = {"id":"admin.deleted.list.delete_all_button","translations":{"translations":{"fr":"Tout supprimer","en":"Delete all"}}};
export const ADMIN_DELETED_LIST_TABLE_ID = {"id":"admin.deleted.list.table.id","translations":{"translations":{"fr":"Id","en":"ID"}}};
export const ADMIN_DELETED_LIST_TABLE_FULL_NAME = {"id":"admin.deleted.list.table.full_name","translations":{"translations":{"fr":"Nom complet","en":"Full name"}}};
export const ADMIN_DELETED_LIST_TABLE_SERVICES = {"id":"admin.deleted.list.table.services","translations":{"translations":{"fr":"Services","en":"Departments"}}};
export const ADMIN_DELETED_LIST_TABLE_ROLES = {"id":"admin.deleted.list.table.roles","translations":{"translations":{"fr":"Roles","en":"Roles"}}};
export const ADMIN_DELETED_LIST_TABLE_ACTIONS = {"id":"admin.deleted.list.table.actions","translations":{"translations":{"fr":"Actions","en":"Actions"}}};
export const ADMIN_DELETED_LIST_RESTORE_USER = {"id":"admin.deleted.list.restore_user","translations":{"translations":{"fr":"Restaurer l'utilisateur","en":"Restore user"}}};
export const ADMIN_DELETED_LIST_DELETE_USER = {"id":"admin.deleted.list.delete_user","translations":{"translations":{"fr":"Supprimer d\u00e9finitivement","en":"Delete permanently"}}};
export const ADMIN_DEVELOPER_MANAGE_MODIFY_PROFILE = {"id":"admin.developer.manage.modify_profile","translations":{"translations":{"fr":"Modifier le profil de {{ user }}","en":"Edit {{ user }} profile"}}};
export const ADMIN_DEVELOPER_MANAGE_CREATE_NEW = {"id":"admin.developer.manage.create_new","translations":{"translations":{"fr":"Cr\u00e9er un nouveau d\u00e9veloppeur","en":"New developer"}}};
export const ADMIN_DOCUMENT_TYPE_CREATE_MODIFY = {"id":"admin.document_type.create.modify","translations":{"translations":{"fr":"Modifier le type de document {{ type }}","en":"Edit document type {{ type }}"}}};
export const ADMIN_DOCUMENT_TYPE_CREATE_NEW = {"id":"admin.document_type.create.new","translations":{"translations":{"fr":"Cr\u00e9er un nouveau type de document","en":"New document type"}}};
export const ADMIN_DOCUMENT_TYPE_LIST_TITLE = {"id":"admin.document_type.list.title","translations":{"translations":{"fr":"Liste des types de document","en":"List of document types"}}};
export const ADMIN_DOCUMENT_TYPE_LIST_SUBTITLE = {"id":"admin.document_type.list.subtitle","translations":{"translations":{"fr":"Fusionner 2 types de document","en":"Merge 2 document types"}}};
export const ADMIN_DOCUMENT_TYPE_LIST_TYPE_NAME = {"id":"admin.document_type.list.type_name","translations":{"translations":{"fr":"Nom du type de document","en":"Document type name"}}};
export const ADMIN_DOCUMENT_TYPE_LIST_MODIFY = {"id":"admin.document_type.list.modify","translations":{"translations":{"fr":"Modifier les types de documents","en":"Edit document types"}}};
export const ADMIN_DOCUMENT_TYPE_LIST_MODIFY_SEVERAL = {"id":"admin.document_type.list.modify_several","translations":{"translations":{"fr":"Modifiez plusieurs types de documents simultan\u00e9ment","en":"Edit multiple document types simultaneously"}}};
export const ADMIN_DOCUMENT_TYPE_LIST_HELPER = {"id":"admin.document_type.list.helper","translations":{"translations":{"fr":"Veuillez choisir les documents que vous souhaitez fusionner","en":"Please select the documents you want to merge"}}};
export const ADMIN_DOCUMENT_TYPE_LIST_TYPE1 = {"id":"admin.document_type.list.type_1","translations":{"translations":{"fr":"Type 1","en":"Type 1"}}};
export const ADMIN_DOCUMENT_TYPE_LIST_TYPE2 = {"id":"admin.document_type.list.type_2","translations":{"translations":{"fr":"Type 2","en":"Type 2"}}};
export const ADMIN_DOCUMENT_TYPE_LIST_NOT_FOUND = {"id":"admin.document_type.list.not_found","translations":{"translations":{"fr":"Aucun type de document trouv\u00e9","en":"No document type found"}}};
export const ADMIN_DOCUMENT_TYPE_LIST_DEFAULT_VISIBILITY = {"id":"admin.document_type.list.default_visibility","translations":{"translations":{"fr":"Visibilit\u00e9 par d\u00e9faut","en":"Default visibility"}}};
export const ADMIN_DOCUMENT_TYPE_LIST_NO_MODIFY = {"id":"admin.document_type.list.no_modify","translations":{"translations":{"fr":"Visibilit\u00e9 par d\u00e9faut","en":"Default visibility"}}};
export const ADMIN_DOCUMENT_TYPE_LIST_NO_MODIFY_STATUS = {"id":"admin.document_type.list.no_modify_status","translations":{"translations":{"fr":"Ne pas modifier le status","en":"Do not update status"}}};
export const ADMIN_DOCUMENT_TYPE_LIST_NO_MODIFY_NOTIFICATIONS = {"id":"admin.document_type.list.no_modify_notifications","translations":{"translations":{"fr":"Ne pas modifier les notifications","en":"Do not update notifications"}}};
export const ADMIN_DOCUMENT_TYPE_LIST_SEND_NOTIFICATIONS = {"id":"admin.document_type.list.send_notifications","translations":{"translations":{"fr":"Notifications envoy\u00e9es","en":"Notifications sent"}}};
export const ADMIN_DOCUMENT_TYPE_LIST_BACK = {"id":"admin.document_type.list.back","translations":{"translations":{"fr":"Retour \u00e0 la liste","en":"Back to list"}}};
export const ADMIN_HL7_TITLE = {"id":"admin.hl7.title","translations":{"translations":{"fr":"Liste des messages re\u00e7u","en":"List of new messages"}}};
export const ADMIN_HL7_ID_HARTMANN = {"id":"admin.hl7.id_hartmann","translations":{"translations":{"fr":"Id Hartmann","en":"Hartmann ID"}}};
export const ADMIN_HL7_EMPTY_LIST = {"id":"admin.hl7.empty_list","translations":{"translations":{"fr":"La liste des messages sur ces crit\u00e8res est vide.","en":"The list of results for these criteria is empty."}}};
export const ADMIN_HL7_RESULTS = {"id":"admin.hl7.results","translations":{"translations":{"fr":"$count r\u00e9sultats trouv\u00e9s","en":"$count results found"}}};
export const ADMIN_HL7_SEARCH = {"id":"admin.hl7.search","translations":{"translations":{"fr":"Rechercher un message","en":"Search for a message"}}};
export const ADMIN_HL7_NOT_FOUND = {"id":"admin.hl7.not_found","translations":{"translations":{"fr":"Utilisateur non trouv\u00e9 pour l'identifiant $id","en":"User not found for $id ID"}}};
export const ADMIN_HL7_SUCCESS_REFRESH = {"id":"admin.hl7.success_refresh","translations":{"translations":{"fr":"Utilisateur $user rafraichi avec succ\u00e8s","en":"User $user refreshed successfully"}}};
export const ADMIN_INSTITUTION_LIST_TITLE = {"id":"admin.institution.list.title","translations":{"translations":{"fr":"Liste des institutions","en":"List of facilities"}}};
export const ADMIN_INSTITUTION_LIST_EMPTY = {"id":"admin.institution.list.empty","translations":{"translations":{"fr":"La liste des institutions est vide.","en":"The list of facilities is empty."}}};
export const ADMIN_INSTITUTION_LIST_RESULTS = {"id":"admin.institution.list.results","translations":{"translations":{"fr":"Nous avons des<b> $count <\/b>r\u00e9sultats.","en":"We have<b> $count <\/b>results."}}};
export const ADMIN_INSTITUTION_LIST_CLICK_TO_EDIT = {"id":"admin.institution.list.click_to_edit","translations":{"translations":{"fr":"Cliquer dans la liste pour modifier une institution","en":"Click in the list to edit a facility"}}};
export const ADMIN_INSTITUTION_MANAGER_EDIT = {"id":"admin.institution.manager.edit","translations":{"translations":{"fr":"Modifier le profil de $user","en":"Edit $user profile"}}};
export const ADMIN_INSTITUTION_MANAGER_CREATE = {"id":"admin.institution.manager.create","translations":{"translations":{"fr":"Cr\u00e9er un nouveau manager","en":"New manager"}}};
export const ADMIN_IP_ADDRESS_UPDATED = {"id":"admin.ip_address.updated","translations":{"translations":{"fr":"L'adresse IP \u00e0 bien \u00e9t\u00e9 modifi\u00e9e","en":"IP address updated successfully"}}};
export const ADMIN_IP_ADDRESS_FIELD = {"id":"admin.ip_address.field","translations":{"translations":{"fr":"Adresse IP","en":"IP address"}}};
export const ADMIN_IP_ADDRESS_MASTER = {"id":"admin.ip_address.master","translations":{"translations":{"fr":"Master adresse (ne peut pas \u00eatre bloqu\u00e9e)","en":"Master address (cannot be blocked)"}}};
export const ADMIN_IP_ADDRESS_BAN = {"id":"admin.ip_address.ban","translations":{"translations":{"fr":"Bannir l'adresse","en":"Ban address"}}};
export const ADMIN_IP_ADDRESS_END_BAN = {"id":"admin.ip_address.end_ban","translations":{"translations":{"fr":"Date de fin de banissement","en":"Ban end date"}}};
export const ADMIN_IP_ADDRESS_LIST_TITLE = {"id":"admin.ip_address.list.title","translations":{"translations":{"fr":"Liste des adresses IP","en":"List of IP addresses"}}};
export const ADMIN_IP_ADDRESS_LIST_ADD = {"id":"admin.ip_address.list.add","translations":{"translations":{"fr":"Ajouter une nouvelle adresse IP","en":"New IP address"}}};
export const ADMIN_IP_ADDRESS_LIST_ADDRESS = {"id":"admin.ip_address.list.address","translations":{"translations":{"fr":"Adresse","en":"Address"}}};
export const ADMIN_IP_ADDRESS_LIST_MASTER = {"id":"admin.ip_address.list.master","translations":{"translations":{"fr":"Master","en":"Master"}}};
export const ADMIN_IP_ADDRESS_LIST_BANNED = {"id":"admin.ip_address.list.banned","translations":{"translations":{"fr":"Banned","en":"Banned"}}};
export const ADMIN_IP_ADDRESS_LIST_UNBAN_DATE = {"id":"admin.ip_address.list.unban_date","translations":{"translations":{"fr":"Date de d\u00e9banissement","en":"Ban end date"}}};
export const ADMIN_IP_ADDRESS_MANAGE_TITLE = {"id":"admin.ip_address.manage.title","translations":{"translations":{"fr":"G\u00e9rer l'adresse IP","en":"Manage IP address"}}};
export const ADMIN_IP_ADDRESS_MANAGE_USED_BY = {"id":"admin.ip_address.manage.used_by","translations":{"translations":{"fr":"Adresse utilis\u00e9e par les utilisateurs suivants :","en":"Address used by the following users :"}}};
export const ADMIN_LOG_TITLE = {"id":"admin.log.title","translations":{"translations":{"fr":"Liste des logs","en":"List of logs"}}};
export const ADMIN_LOG_SELECT_OBJECT = {"id":"admin.log.select_object","translations":{"translations":{"fr":"Choisissez un objet","en":"Choose an item"}}};
export const ADMIN_LOG_ALL = {"id":"admin.log.all","translations":{"translations":{"fr":"Tous les objets","en":"All items"}}};
export const ADMIN_LOG_DOCUMENTS = {"id":"admin.log.documents","translations":{"translations":{"fr":"Documents","en":"Documents"}}};
export const ADMIN_LOG_PATIENT_PROFILE = {"id":"admin.log.patient_profile","translations":{"translations":{"fr":"Profil Patient","en":"Patient profile"}}};
export const ADMIN_LOG_RESULTS_BY_PAGE = {"id":"admin.log.results_by_page","translations":{"translations":{"fr":"r\u00e9sultats par page","en":"results per page"}}};
export const ADMIN_MEDIC_EDIT = {"id":"admin.medic.edit","translations":{"translations":{"fr":"Modifier un profil M\u00e9decin","en":"Edit a doctor profile"}}};
export const ADMIN_MEDIC_CREATE = {"id":"admin.medic.create","translations":{"translations":{"fr":"Cr\u00e9er un profil M\u00e9decin","en":"Create a doctor profile"}}};
export const ADMIN_MEDIC_ID_DOCUMENT = {"id":"admin.medic.id_document","translations":{"translations":{"fr":"Documents d'identit\u00e9","en":"ID documents"}}};
export const ADMIN_MEDIC_DELIVERY_COUNTRY = {"id":"admin.medic.delivery_country","translations":{"translations":{"fr":"Pays de d\u00e9livrance : ","en":"Country of issuance : "}}};
export const ADMIN_MEDIC_DELIVERY_DATE = {"id":"admin.medic.delivery_date","translations":{"translations":{"fr":"Date de d\u00e9livrance : ","en":"Date of issuance : "}}};
export const ADMIN_MEDIC_EXPIRATION_DATE = {"id":"admin.medic.expiration_date","translations":{"translations":{"fr":"Date d'expiration : ","en":"Expiration date : "}}};
export const ADMIN_MEDIC_STATUS = {"id":"admin.medic.status","translations":{"translations":{"fr":"Statut : ","en":"Status : "}}};
export const ADMIN_MEDIC_REFUSAL_REASON = {"id":"admin.medic.refusal_reason","translations":{"translations":{"fr":"Raison du refus : ","en":"Reason for refusal : "}}};
export const ADMIN_MEDIC_UNSPECIFIED = {"id":"admin.medic.unspecified","translations":{"translations":{"fr":"Non sp\u00e9cifi\u00e9e","en":"Unspecified"}}};
export const ADMIN_MEDIC_SEE_RECTO = {"id":"admin.medic.see_recto","translations":{"translations":{"fr":"Voir le fichier (recto)","en":"View file (front-side)"}}};
export const ADMIN_MEDIC_SEE_VERSO = {"id":"admin.medic.see_verso","translations":{"translations":{"fr":"Voir le fichier (verso)","en":"View file (back-side)"}}};
export const ADMIN_MEDIC_SUBSCRIPTION_TITLE = {"id":"admin.medic.subscription.title","translations":{"translations":{"fr":"Abonnement(s)","en":"Subscriptions"}}};
export const ADMIN_MEDIC_SUBSCRIPTION_GENERATE_SEPA_LINK = {"id":"admin.medic.subscription.generate_sepa_link","translations":{"translations":{"fr":"G\u00e9n\u00e9rer le lien Sepa","en":"Generate SEPA link"}}};
export const ADMIN_MEDIC_SUBSCRIPTION_SUBSCRIPTION = {"id":"admin.medic.subscription.subscription","translations":{"translations":{"fr":"Abonnement : ","en":"Subscription : "}}};
export const ADMIN_MEDIC_SUBSCRIPTION_AMOUNT = {"id":"admin.medic.subscription.amount","translations":{"translations":{"fr":"Montant : ","en":"Amount : "}}};
export const ADMIN_MEDIC_SUBSCRIPTION_ITEMS = {"id":"admin.medic.subscription.items","translations":{"translations":{"fr":"Items : ","en":"Items : "}}};
export const ADMIN_MEDIC_SUBSCRIPTION_SEE_STRIPE = {"id":"admin.medic.subscription.see_stripe","translations":{"translations":{"fr":"Voir dans Stripe","en":"View in Stripe"}}};
export const ADMIN_MEDIC_LIA_TITLE = {"id":"admin.medic.lia.title","translations":{"translations":{"fr":"Cr\u00e9dits Lia ","en":"Lia credits "}}};
export const ADMIN_MEDIC_LIA_CREDIT = {"id":"admin.medic.lia.credit","translations":{"translations":{"fr":"Credit:","en":"Credit :"}}};
export const ADMIN_MEDIC_LIA_EXPIRATION = {"id":"admin.medic.lia.expiration","translations":{"translations":{"fr":"Expiration Date (optional) :","en":"Expiration date (optional) :"}}};
export const ADMIN_MEDIC_COORDINATOR_CREATE = {"id":"admin.medic_coordinator.create","translations":{"translations":{"fr":"Cr\u00e9er un profil Coordinateur m\u00e9dical","en":"Create medical coordinator profile"}}};
export const ADMIN_MODULE_TITLE = {"id":"admin.module.title","translations":{"translations":{"fr":"Liste des modules","en":"List of modules"}}};
export const ADMIN_MODULE_EMPTY = {"id":"admin.module.empty","translations":{"translations":{"fr":" La liste des modules est vide. ","en":" The module list is empty. "}}};
export const ADMIN_MODULE_CLICK_TO_EDIT = {"id":"admin.module.click_to_edit","translations":{"translations":{"fr":"Cliquer dans la liste pour modifier un module","en":"Click in the list to edit a module"}}};
export const ADMIN_MODULE_EDIT = {"id":"admin.module.edit","translations":{"translations":{"fr":"Modifier Le module","en":"Edit module"}}};
export const ADMIN_MODULE_SLUG = {"id":"admin.module.slug","translations":{"translations":{"fr":"Slug du module","en":"Module slug"}}};
export const ADMIN_MODULE_DATA = {"id":"admin.module.data","translations":{"translations":{"fr":"Donn\u00e9es du module","en":"Module data"}}};
export const ADMIN_MODULE_ROLES = {"id":"admin.module.roles","translations":{"translations":{"fr":"R\u00f4les actifs","en":"Active roles"}}};
export const ADMIN_NURSE_LINK_TO = {"id":"admin.nurse.link_to","translations":{"translations":{"fr":"Lier un ou plusieurs m\u00e9decins \u00e0 $profile","en":"Link one or more doctors to $profile"}}};
export const ADMIN_NURSE_LINK_PARA_MEDIC = {"id":"admin.nurse.link_para_medic","translations":{"translations":{"fr":"Lier un profil param\u00e9dical \u00e0 un m\u00e9decin","en":"Link paramedic profile to doctor"}}};
export const ADMIN_NURSE_HELPER = {"id":"admin.nurse.helper","translations":{"translations":{"fr":"Si vous liez un profil \"Param\u00e9dical\" \u00e0 un profil \"M\u00e9decin\", ce dernier sera mise automatiquement en copie de tous les \u00e9changes de messagerie cr\u00e9\u00e9s via la messagerie s\u00e9curis\u00e9e Instamed.","en":"If you link a \"Paramedic\" profile to a \"Doctor\" profile, the latter will automatically be copied on all message exchanges created through the Instamed Messenger."}}};
export const ADMIN_NURSE_COMPLETE_NAME = {"id":"admin.nurse.complete_name","translations":{"translations":{"fr":"Nom complet","en":"Full name"}}};
export const ADMIN_NURSE_LINKED_MEDICS = {"id":"admin.nurse.linked_medics","translations":{"translations":{"fr":"M\u00e9decins li\u00e9s","en":"Linked doctors"}}};
export const ADMIN_NURSE_LINK_TO_MEDIC = {"id":"admin.nurse.link_to_medic","translations":{"translations":{"fr":"Lier \u00e0 un m\u00e9decin","en":"Link to doctor"}}};
export const ADMIN_NURSE_EDIT = {"id":"admin.nurse.edit","translations":{"translations":{"fr":"Modifier le profil param\u00e9dical n\u00b0 $id","en":"Edit paramedic profile $id"}}};
export const ADMIN_NURSE_CREATE = {"id":"admin.nurse.create","translations":{"translations":{"fr":"Cr\u00e9er un profil param\u00e9dical","en":"Create paramedic profile"}}};
export const ADMIN_PAGES_TITLE = {"id":"admin.pages.title","translations":{"translations":{"fr":"Liste des Pages","en":"List of pages"}}};
export const ADMIN_PAGES_CREATE = {"id":"admin.pages.create","translations":{"translations":{"fr":"Cr\u00e9er une nouvelle page","en":"New page"}}};
export const ADMIN_PAGES_PAGE_TITLE = {"id":"admin.pages.page_title","translations":{"translations":{"fr":"Titre de la page","en":"Page title"}}};
export const ADMIN_PAGES_CANONICAL = {"id":"admin.pages.canonical","translations":{"translations":{"fr":"Nom canonique (pour admin)","en":"Canonical name (for admin)"}}};
export const ADMIN_PAGES_SLUG = {"id":"admin.pages.slug","translations":{"translations":{"fr":"Slug","en":"Slug"}}};
export const ADMIN_PAGES_IN_MENU = {"id":"admin.pages.in_menu","translations":{"translations":{"fr":"Afficher dans le menu patient","en":"Display in patient menu"}}};
export const ADMIN_PAGES_ICON = {"id":"admin.pages.icon","translations":{"translations":{"fr":"Ic\u00f4ne","en":"Icon"}}};
export const ADMIN_PAGES_ORDER = {"id":"admin.pages.order","translations":{"translations":{"fr":"Ordre dans le menu","en":"Menu order"}}};
export const ADMIN_PAGES_MENU = {"id":"admin.pages.menu","translations":{"translations":{"fr":"Menu patient","en":"Patient menu"}}};
export const ADMIN_PAGES_SECTIONS = {"id":"admin.pages.sections","translations":{"translations":{"fr":"Sections","en":"Sections"}}};
export const ADMIN_PAGES_SECTIONS_TITLE = {"id":"admin.pages.sections_title","translations":{"translations":{"fr":"Section(s)","en":"Sections"}}};
export const ADMIN_PAGES_CONFIRM_DELETE = {"id":"admin.pages.confirm_delete","translations":{"translations":{"fr":"\u00cates-vous s\u00fbr de vouloir supprimer cette page ? ","en":"Are you sure you want to delete the page?"}}};
export const ADMIN_PAGES_CREATE_EDIT = {"id":"admin.pages.create_edit","translations":{"translations":{"fr":"Cr\u00e9er ou modifier une nouvelle page","en":"Create or edit a new page"}}};
export const ADMIN_PAGES_INFORMATIONS = {"id":"admin.pages.informations","translations":{"translations":{"fr":"Informations de la page","en":"Page information"}}};
export const ADMIN_PAGES_PAGE_UPDATED = {"id":"admin.pages.page_updated","translations":{"translations":{"fr":"La page '$page' a bien \u00e9t\u00e9 modifi\u00e9e","en":"The page '$page' has been successfully edited"}}};
export const ADMIN_PAGES_NOT_CREATED = {"id":"admin.pages.not_created","translations":{"translations":{"fr":"La page $canonical n'a pas encore \u00e9t\u00e9 cr\u00e9\u00e9e. Veuillez vous rapprocher de votre administrateur.","en":"Page $canonical has not been created yet. Please contact your administrator."}}};
export const ADMIN_PAGES_NOT_FOUND = {"id":"admin.pages.not_found","translations":{"translations":{"fr":"Page introuvable.","en":"Page not found."}}};
export const ADMIN_PATIENT_MERGE_TITLE = {"id":"admin.patient.merge.title","translations":{"translations":{"fr":"Fusionner deux patients","en":"Merge two records"}}};
export const ADMIN_PATIENT_MERGE_TO_MERGE = {"id":"admin.patient.merge.to_merge","translations":{"translations":{"fr":"\u00c0 fusionner","en":"To merge"}}};
export const ADMIN_PATIENT_MERGE_HELPER = {"id":"admin.patient.merge.helper","translations":{"translations":{"fr":"Si la d\u00e9tection automatique ne donne aucun r\u00e9sultat, saisissez le pr\u00e9nom, le nom et la date de naissance de vos doublons. Nous afficherons les enregistrements correspondants que vous pourrez ensuite fusionner.","en":"If automatic detection displays no results, enter the first name, last name, and date of birth of your duplicates. We will display the matching records which you can then merge."}}};
export const ADMIN_PATIENT_MERGE_MERGE_HELPER = {"id":"admin.patient.merge.merge_helper","translations":{"translations":{"fr":"<span class=\"infoColor\">Notre syst\u00e8me a d\u00e9tect\u00e9 <span id=\"count\"><\/span> doublons.<\/span> Veuillez v\u00e9rifier leurs dossiers avant de fusionner.","en":"<span class=\"infoColor\">Our system detected <span id=\"count\"><\/span> duplicate records.<\/span> Please check their records before merging."}}};
export const ADMIN_PATIENT_MERGE_SEARCH = {"id":"admin.patient.merge.search","translations":{"translations":{"fr":"Rechercher les doublons","en":"Search duplicate records"}}};
export const ADMIN_PATIENT_MERGE_MERGE = {"id":"admin.patient.merge.merge","translations":{"translations":{"fr":"Fusionner","en":"Merge"}}};
export const ADMIN_TRANSACTIONS_TITLE = {"id":"admin.transactions.title","translations":{"translations":{"fr":"Liste des transactions","en":"Transaction list"}}};
export const ADMIN_TRANSACTIONS_YOURS = {"id":"admin.transactions.yours","translations":{"translations":{"fr":"Vos transactions","en":"Your transactions"}}};
export const ADMIN_TRANSACTIONS_EMPTY = {"id":"admin.transactions.empty","translations":{"translations":{"fr":"La liste des transactions est vide.","en":"Transaction list is empty."}}};
export const ADMIN_TRANSACTIONS_DETAILS = {"id":"admin.transactions.details","translations":{"translations":{"fr":"D\u00e9tail de la transaction","en":"Transaction details"}}};
export const ADMIN_TRANSACTIONS_APPOINTMENT_DETAILS = {"id":"admin.transactions.appointment_details","translations":{"translations":{"fr":"D\u00e9tail du rendez-vous","en":"Appointment detail"}}};
export const ADMIN_TRANSACTIONS_REFUND_DETAILS = {"id":"admin.transactions.refund_details","translations":{"translations":{"fr":"D\u00e9tail du remboursement","en":"Refund details"}}};
export const ADMIN_TRANSACTIONS_PAYOUT_DETAILS = {"id":"admin.transactions.payout_details","translations":{"translations":{"fr":"D\u00e9tail du virement vers votre compte","en":"Transfer details to your account"}}};
export const ADMIN_TRANSACTIONS_PATIENT = {"id":"admin.transactions.patient","translations":{"translations":{"fr":"Patient : ","en":"Patient : "}}};
export const ADMIN_TRANSACTIONS_EXPECTED_DURATION = {"id":"admin.transactions.expected_duration","translations":{"translations":{"fr":"Dur\u00e9e pr\u00e9vue : ","en":"Estimated duration : "}}};
export const ADMIN_TRANSACTIONS_DATE = {"id":"admin.transactions.date","translations":{"translations":{"fr":"Date : ","en":"Date : "}}};
export const ADMIN_TRANSACTIONS_CALL = {"id":"admin.transactions.call","translations":{"translations":{"fr":"Appel n\u00b0 $id","en":"Call number $id"}}};
export const ADMIN_TRANSACTIONS_START_DATE = {"id":"admin.transactions.start_date","translations":{"translations":{"fr":"Date de d\u00e9but : ","en":"Start date : "}}};
export const ADMIN_TRANSACTIONS_END_DATE = {"id":"admin.transactions.end_date","translations":{"translations":{"fr":"Date de fin : ","en":"End date : "}}};
export const ADMIN_TRANSACTIONS_DURATION = {"id":"admin.transactions.duration","translations":{"translations":{"fr":"Dur\u00e9e : ","en":"Duration : "}}};
export const ADMIN_TRANSACTIONS_ID = {"id":"admin.transactions.id","translations":{"translations":{"fr":"Id : ","en":"ID : "}}};
export const ADMIN_TRANSACTIONS_AUTHOR = {"id":"admin.transactions.author","translations":{"translations":{"fr":"Auteur : ","en":"Author : "}}};
export const ADMIN_TRANSACTIONS_AMOUNT = {"id":"admin.transactions.amount","translations":{"translations":{"fr":"Montant : ","en":"Amount : "}}};
export const ADMIN_TRANSACTIONS_SHIPPING_COST = {"id":"admin.transactions.shipping_cost","translations":{"translations":{"fr":"(Frais de plateforme : <strong> $price \u20ac)<\/strong>","en":"(Platform fee : <strong> $price \u20ac)<\/strong>"}}};
export const ADMIN_TRANSACTIONS_SEE_RECEIPT = {"id":"admin.transactions.see_receipt","translations":{"translations":{"fr":"Voir le re\u00e7u","en":"View receipt"}}};
export const ADMIN_TRANSACTIONS_AMOUNT_NOT_NULL = {"id":"admin.transactions.amount_not_null","translations":{"translations":{"fr":"Le montant de la transaction ne peut pas \u00eatre 0","en":"Transaction amount cannot be 0"}}};
export const ADMIN_TRANSACTIONS_HELPER = {"id":"admin.transactions.helper","translations":{"translations":{"fr":"Ce rendez-vous semble ne pas avoir \u00e9t\u00e9 correctement effectu\u00e9, merci de confirmer si vous souhaitez le d\u00e9biter ou non.","en":"This appointment appears to have not been properly completed. Please confirm if you would like to charge it or not."}}};
export const ADMIN_TRANSACTIONS_LIST_TITLE = {"id":"admin.transactions.list.title","translations":{"translations":{"fr":"Liste des transactions","en":"Transaction list"}}};
export const ADMIN_TRANSACTIONS_LIST_APPOINTMENT = {"id":"admin.transactions.list.appointment","translations":{"translations":{"fr":"Rendez-vous n\u00b0 $id","en":"Appointment # $id"}}};
export const ADMIN_TRANSACTIONS_LIST_REFUND = {"id":"admin.transactions.list.refund","translations":{"translations":{"fr":"Rembourser","en":"Refund"}}};
export const ADMIN_TRANSACTIONS_LIST_DEBIT = {"id":"admin.transactions.list.debit","translations":{"translations":{"fr":"D\u00e9biter","en":"Debit"}}};
export const ADMIN_TRANSACTIONS_LIST_NOT_DEBIT = {"id":"admin.transactions.list.not_debit","translations":{"translations":{"fr":"Ne pas d\u00e9biter","en":"Do not debit"}}};
export const ADMIN_SERVICES_TITLE = {"id":"admin.services.title","translations":{"translations":{"fr":"Liste des services","en":"List of departments"}}};
export const ADMIN_SERVICES_CREATE = {"id":"admin.services.create","translations":{"translations":{"fr":"Cr\u00e9er un nouveau service","en":"Create a new department"}}};
export const ADMIN_SERVICES_EMPTY = {"id":"admin.services.empty","translations":{"translations":{"fr":"La liste des services est vide.","en":"The list of departments is empty."}}};
export const ADMIN_SERVICES_EDIT = {"id":"admin.services.edit","translations":{"translations":{"fr":"Modifier Le service $name","en":"Edit $name derpartment"}}};
export const ADMIN_SERVICES_SELECT = {"id":"admin.services.select","translations":{"translations":{"fr":"Choisissez le service","en":"Select department"}}};
export const ADMIN_SERVICES_ALL = {"id":"admin.services.all","translations":{"translations":{"fr":"Tous les services","en":"All departments"}}};
export const ADMIN_SUPPORT_EDIT = {"id":"admin.support.edit","translations":{"translations":{"fr":"Modifier le profil de $user","en":"Edit {{ user }} profile"}}};
export const ADMIN_SUPPORT_CREATE = {"id":"admin.support.create","translations":{"translations":{"fr":"Cr\u00e9er un nouvel utilisateur de support","en":"New support user"}}};
export const ADMIN_SUPPORT_ADD_FROM = {"id":"admin.support.add_from","translations":{"translations":{"fr":"Ajouter un utilisateur existant dans un autre h\u00f4pital","en":"Add an existing user to another healthcare facility"}}};
export const ADMIN_USERS_TITLE = {"id":"admin.users.title","translations":{"translations":{"fr":"Liste des utilisateurs","en":"List of users"}}};
export const ADMIN_USERS_CREATE = {"id":"admin.users.create","translations":{"translations":{"fr":"Cr\u00e9er un nouveau compte","en":"New account"}}};
export const ADMIN_USERS_NEW_DOCTOR = {"id":"admin.users.new_doctor","translations":{"translations":{"fr":"M\u00e9decin","en":"Doctor"}}};
export const ADMIN_USERS_NEW_PATIENT = {"id":"admin.users.new_patient","translations":{"translations":{"fr":"Patient","en":"Patient"}}};
export const ADMIN_USERS_NEW_PARAMEDICAL = {"id":"admin.users.new_paramedical","translations":{"translations":{"fr":"Param\u00e9dical","en":"Paramedic"}}};
export const ADMIN_USERS_NEW_MEDIC_COORDINATOR = {"id":"admin.users.new_medic_coordinator","translations":{"translations":{"fr":"Coordinateur m\u00e9dical","en":"Medical coordinator"}}};
export const ADMIN_USERS_NEW_SECRETARY = {"id":"admin.users.new_secretary","translations":{"translations":{"fr":"Secr\u00e9taire","en":"Secretary"}}};
export const ADMIN_USERS_EXISTING_ACCOUNT = {"id":"admin.users.existing_account","translations":{"translations":{"fr":"Compte existant dans un autre h\u00f4pital","en":"Account already exists in another facility"}}};
export const ADMIN_USERS_ADMIN = {"id":"admin.users.admin","translations":{"translations":{"fr":"Admin","en":"Admin"}}};
export const ADMIN_USERS_PRINCIPAL_ADMIN = {"id":"admin.users.principal_admin","translations":{"translations":{"fr":"Admin principal","en":"Master admin"}}};
export const ADMIN_USERS_NEW_ADMIN = {"id":"admin.users.new_admin","translations":{"translations":{"fr":"Nouvel Admin","en":"New admin"}}};
export const ADMIN_USERS_MANAGER = {"id":"admin.users.manager","translations":{"translations":{"fr":"Manager","en":"Manager"}}};
export const ADMIN_USERS_NEW_MANAGER = {"id":"admin.users.new_manager","translations":{"translations":{"fr":"Nouveau Manager","en":"New manager"}}};
export const ADMIN_USERS_DEV = {"id":"admin.users.dev","translations":{"translations":{"fr":"D\u00e9veloppeur","en":"Developer"}}};
export const ADMIN_USERS_NEW_DEV = {"id":"admin.users.new_dev","translations":{"translations":{"fr":"Nouveau D\u00e9veloppeur","en":"New developer"}}};
export const ADMIN_USERS_SUPPORT = {"id":"admin.users.support","translations":{"translations":{"fr":"Support","en":"Support"}}};
export const ADMIN_USERS_NEW_SUPPORT = {"id":"admin.users.new_support","translations":{"translations":{"fr":"Nouveau Support","en":"New support"}}};
export const ADMIN_USERS_RESEARCH = {"id":"admin.users.research","translations":{"translations":{"fr":"Recherche","en":"Research"}}};
export const ADMIN_USERS_NEW_RESEARCHER = {"id":"admin.users.new_researcher","translations":{"translations":{"fr":"Nouveau Chercheur","en":"New researcher"}}};
export const ADMIN_USERS_SEARCH_BY_NAME = {"id":"admin.users.search_by_name","translations":{"translations":{"fr":"Recherche par nom","en":"Search by name"}}};
export const ADMIN_USERS_ALL_STATUS = {"id":"admin.users.all_status","translations":{"translations":{"fr":"Tous les statuts","en":"All statuses"}}};
export const ADMIN_USERS_LAST_CONNEXION = {"id":"admin.users.last_connexion","translations":{"translations":{"fr":"Derni\u00e8re connexion","en":"Last login"}}};
export const ADMIN_USERS_NOT_FOUND = {"id":"admin.users.not_found","translations":{"translations":{"fr":"Aucun utilisateur trouv\u00e9","en":"No user found"}}};
export const ADMIN_USERS_TAKE_CONTROL = {"id":"admin.users.take_control","translations":{"translations":{"fr":"Prendre le contr\u00f4le","en":"Take control"}}};
export const ADMIN_USERS_RESEND_INVITATION = {"id":"admin.users.resend_invitation","translations":{"translations":{"fr":"Renvoyer l'invitation","en":"Resend invitation"}}};
export const ADMIN_USERS_CONFIRM_DEACTIVATE = {"id":"admin.users.confirm_deactivate","translations":{"translations":{"fr":"\u00cates-vous s\u00fbr de vouloir d\u00e9sactiver cet utilisateur ? ","en":"Are you sure you want to disable this user?"}}};
export const ADMIN_USERS_HELPER_DEACTIVATE = {"id":"admin.users.helper_deactivate","translations":{"translations":{"fr":"Il recevra un e-mail de d\u00e9sactivation et ne pourra plus acc\u00e9der \u00e0 son dossier m\u00e9dical","en":"User will receive an email notification and will no longer be able to access their medical record."}}};
export const ADMIN_USERS_DEACTIVATED = {"id":"admin.users.deactivated","translations":{"translations":{"fr":"L'utilisateur a bien \u00e9t\u00e9 d\u00e9sactiv\u00e9","en":"User has been successfully disabled"}}};
export const ADMIN_USERS_ACTIVATED = {"id":"admin.users.activated","translations":{"translations":{"fr":"L'utilisateur a bien \u00e9t\u00e9 activ\u00e9","en":"User has been successfully activated"}}};
export const ADMIN_VARIABLE_TITLE = {"id":"admin.variable.title","translations":{"translations":{"fr":"Liste des types de variable","en":"List of variable types"}}};
export const ADMIN_VARIABLE_CREATE = {"id":"admin.variable.create","translations":{"translations":{"fr":"Cr\u00e9er un nouveau type de variable","en":"New variable type"}}};
export const ADMIN_VARIABLE_MANAGE = {"id":"admin.variable.manage","translations":{"translations":{"fr":"G\u00e9rer le type de variable","en":"Manage variable type"}}};
export const ADMIN_ERRORS_PARAMETERS = {"id":"admin.errors.parameters","translations":{"translations":{"fr":"Param\u00e8tres du site","en":"Website settings"}}};
export const ADMIN_ERRORS_MANAGE = {"id":"admin.errors.manage","translations":{"translations":{"fr":"Gestion des erreurs du site","en":"Website error management"}}};
export const ADMIN_ERRORS_CLEAR_LOGS = {"id":"admin.errors.clear_logs","translations":{"translations":{"fr":"Vider les logs","en":"Clear logs"}}};
export const ADMIN_ERRORS_VARIABLES = {"id":"admin.errors.variables","translations":{"translations":{"fr":"Variables de l'application","en":"Application variables"}}};
export const ADMIN_ERRORS_ENVIRONMENT = {"id":"admin.errors.environment","translations":{"translations":{"fr":"Environnement : ","en":"Environment :"}}};
export const ADMIN_ERRORS_DEBUG = {"id":"admin.errors.debug","translations":{"translations":{"fr":"Debug : ","en":"Debug : "}}};
export const ADMIN_ERRORS_CACHE_RIGHTS = {"id":"admin.errors.cache_rights","translations":{"translations":{"fr":"Permissions cache : ","en":"Cache permissions :"}}};
export const ADMIN_ERRORS_ERRORS = {"id":"admin.errors.errors","translations":{"translations":{"fr":"Erreurs : ","en":"Errors :"}}};
export const ADMIN_ERRORS_LINK = {"id":"admin.errors.link","translations":{"translations":{"fr":"Lien de la page : ","en":"Page link : "}}};
export const ADMIN_ERRORS_DATE = {"id":"admin.errors.date","translations":{"translations":{"fr":"Date : ","en":"Date : "}}};
export const ADMIN_ERRORS_USERS = {"id":"admin.errors.users","translations":{"translations":{"fr":"Utilisateur : ","en":"User : "}}};
export const ADMIN_ERRORS_ROLES = {"id":"admin.errors.roles","translations":{"translations":{"fr":"Roles : ","en":"Roles : "}}};
export const ADMIN_ERRORS_HAPPENED = {"id":"admin.errors.happened","translations":{"translations":{"fr":"Une erreur \" $code $text \" vient de se produire","en":"An error \" $code $text \" has occurred"}}};
export const ADMIN_ERRORS_BACK = {"id":"admin.errors.back","translations":{"translations":{"fr":"Cliquez <a href=\"javascript:history.back()\">ici<\/a> pour retourner sur la page pr\u00e9c\u00e9dente.","en":"Click <a href=\"javascript:history.back()\">here<\/a> to return to the previous page."}}};
export const ADMIN_ERRORS_SORRY = {"id":"admin.errors.sorry","translations":{"translations":{"fr":"Nous nous excusons pour cet incident.","en":"We apologise for this incident."}}};
export const ADMIN_ERRORS_TRACE = {"id":"admin.errors.trace","translations":{"translations":{"fr":"Trace stack","en":"Stack trace"}}};
export const ADMIN_LAYOUT_GENERAL_INFORMATIONS = {"id":"admin.layout.general_informations","translations":{"translations":{"fr":"Informations G\u00e9n\u00e9rales","en":"General information"}}};
export const ADMIN_LAYOUT_SECURITY = {"id":"admin.layout.security","translations":{"translations":{"fr":"S\u00e9curit\u00e9","en":"Security"}}};
export const ADMIN_LAYOUT_HELPER = {"id":"admin.layout.helper","translations":{"translations":{"fr":"Votre mot de passe doit \u00eatre de minimum 8 caract\u00e8res, contenir au moins un chiffre, une minuscule et une majuscule.","en":"Your password must be at least 8 characters long and include at least one digit, one lowercase letter, and one uppercase letter."}}};
export const ADMIN_LAYOUT_RESULTS = {"id":"admin.layout.results","translations":{"translations":{"fr":"$count r\u00e9sultats trouv\u00e9s","en":"{count} results found"}}};
export const ADMIN_LAYOUT_SEARCH = {"id":"admin.layout.search","translations":{"translations":{"fr":"Rechercher une entit\u00e9","en":"Search entity"}}};
export const ADMIN_LAYOUT_RESULTS_BY_PAGE = {"id":"admin.layout.results_by_page","translations":{"translations":{"fr":"r\u00e9sultats par page","en":"results per page"}}};
export const ADMIN_LAYOUT_EMPTY = {"id":"admin.layout.empty","translations":{"translations":{"fr":"La liste des r\u00e9sultats sur ces crit\u00e8res est vide.","en":"List of results for these criteria is empty."}}};
export const ADMIN_LAYOUT_CREATE_SEPA = {"id":"admin.layout.create_sepa","translations":{"translations":{"fr":"Cr\u00e9er un lien SEPA","en":"Create SEPA link"}}};
export const ADMIN_LAYOUT_STRIPE_INFORMATIONS = {"id":"admin.layout.stripe_informations","translations":{"translations":{"fr":"Informations Stripe","en":"Stripe information"}}};
export const ADMIN_LAYOUT_CLIENT_ID = {"id":"admin.layout.client_id","translations":{"translations":{"fr":"Id du client :","en":"Client ID :"}}};
export const ADMIN_LAYOUT_CLIENT_NAME = {"id":"admin.layout.client_name","translations":{"translations":{"fr":"Nom du client :","en":"Client name :"}}};
export const ADMIN_LAYOUT_CLIENT_EMAIL = {"id":"admin.layout.client_email","translations":{"translations":{"fr":"Email du client :","en":"Client email :"}}};
export const ADMIN_LAYOUT_LINK = {"id":"admin.layout.link","translations":{"translations":{"fr":"Lien SEPA :","en":"SEPA link :"}}};
export const ADMIN_MENU_MANAGE = {"id":"admin.menu.manage","translations":{"translations":{"fr":"Gestion des menus","en":"Menu management"}}};
export const ADMIN_MENU_HIDE = {"id":"admin.menu.hide","translations":{"translations":{"fr":"Cacher des \u00e9l\u00e9ments du menu pour le r\u00f4le : $role","en":"Hide menu items for : $role"}}};
export const ADMIN_MENU_CHECK = {"id":"admin.menu.check","translations":{"translations":{"fr":"Cocher dans la liste des \u00e9l\u00e9ments que vous souhaitez cacher du menu","en":"Check the items in the list that you want to hide from the menu."}}};
export const ADMIN_PARAMETERS_TITLE = {"id":"admin.parameters.title","translations":{"translations":{"fr":"Param\u00e8tres du site","en":"Website settings"}}};
export const ADMIN_PARAMETERS_MANAGE = {"id":"admin.parameters.manage","translations":{"translations":{"fr":"Gestion des param\u00e8tres du site","en":"Manage website settings"}}};
export const ADMIN_PARAMETERS_FORM = {"id":"admin.parameters.form","translations":{"translations":{"fr":"Formulaire de gestion des param\u00e8tres de l'application","en":"Application settings management form"}}};
export const ADMIN_PARAMETERS_EDIT = {"id":"admin.parameters.edit","translations":{"translations":{"fr":"Modifier les param\u00e8tres","en":"Change settings"}}};
export const ADMIN_DASHBOARD_TITLE = {"id":"admin.dashboard.title","translations":{"translations":{"fr":"Tableau de bord","en":"Dashboard"}}};
export const ADMIN_MESSAGES_PARAMETERS_UPDATED = {"id":"admin.messages.parameters_updated","translations":{"translations":{"fr":"Les param\u00e8tres ont \u00e9t\u00e9 modifi\u00e9.","en":"Settings have been edited."}}};
export const ADMIN_MESSAGES_PROFILE_UPDATED = {"id":"admin.messages.profile_updated","translations":{"translations":{"fr":"Le profil de $user a bien \u00e9t\u00e9 modifi\u00e9","en":"Profile of $user was successfully updated"}}};
export const ADMIN_MESSAGES_DOCTOR_UPDATED = {"id":"admin.messages.doctor_updated","translations":{"translations":{"fr":"Le M\u00e9decin $doctor a bien \u00e9t\u00e9 modifi\u00e9","en":"Doctor $doctor was successfully updated"}}};
export const ADMIN_MESSAGES_PATIENT_UPDATED = {"id":"admin.messages.patient_updated","translations":{"translations":{"fr":"Le patient $patient a bien \u00e9t\u00e9 modifi\u00e9","en":"Profile of $patient was successfully updated"}}};
export const ADMIN_MESSAGES_SERVICE_UPDATED = {"id":"admin.messages.service_updated","translations":{"translations":{"fr":"Le service a bien \u00e9t\u00e9 modifi\u00e9","en":"Department has been updated"}}};
export const ADMIN_MESSAGES_INSTITUTION_SAVED = {"id":"admin.messages.institution_saved","translations":{"translations":{"fr":"L'institution %s a bien \u00e9t\u00e9 enregistr\u00e9e","en":"Facility %s has been successfully saved"}}};
export const ADMIN_MESSAGES_MODULE_SAVED = {"id":"admin.messages.module_saved","translations":{"translations":{"fr":"Le module %s a bien \u00e9t\u00e9 enregistr\u00e9","en":"The %s module has been successfully saved"}}};
export const ADMIN_MESSAGES_COORDINATOR_UPDATED = {"id":"admin.messages.coordinator_updated","translations":{"translations":{"fr":"Le coordinateur m\u00e9dical $coordinator a bien \u00e9t\u00e9 enregistr\u00e9","en":"Medical coordinator $coordinator has been successfully saved"}}};
export const ADMIN_MESSAGES_ERROR_FROM_INSTITUTION = {"id":"admin.messages.error_from_institution","translations":{"translations":{"fr":"Vous ne pouvez pas aller sur cette page depuis l'institution principale","en":"You cannot access this page from the main institution"}}};
export const ADMIN_MESSAGES_MAIL_USER_NOT_FOUND = {"id":"admin.messages.mail_user_not_found","translations":{"translations":{"fr":"Aucun utilisateur trouv\u00e9 avec l'adresse $email","en":"No users found with the address $email"}}};
export const ADMIN_MESSAGES_USER_ALREADY_EXISTING = {"id":"admin.messages.user_already_existing","translations":{"translations":{"fr":"L'utilisateur $email existe d\u00e9j\u00e0 dans votre h\u00f4pital avec un r\u00f4le $role","en":"User $email already exists in your facility with the role of $role"}}};
export const ADMIN_MESSAGES_CANT_ADD_ROLE = {"id":"admin.messages.cant_add_role","translations":{"translations":{"fr":"Le r\u00f4le $role ne peut pas \u00eatre ajout\u00e9 \u00e0 plusieurs institutions","en":"Role $role cannot be added in multiple institutions"}}};
export const ADMIN_MESSAGES_SUCCESS_ADD_ROLE = {"id":"admin.messages.success_add_role","translations":{"translations":{"fr":"L'utilisateur $user a bien \u00e9t\u00e9 rajout\u00e9 \u00e0 votre h\u00f4pital avec le r\u00f4le $role","en":"User $user was successfully added to your facility with the role $role"}}};
export const ADMIN_MESSAGES_WARNING_DELETE_USER_LIMIT = {"id":"admin.messages.warning_delete_user_limit","translations":{"translations":{"fr":"Pour des raisons de s\u00e9curit\u00e9, vous ne pouvez pas supprimer plus de 50 comptes \u00e0 la fois","en":"For security reasons, you cannot delete more than 50 accounts at once"}}};
export const ADMIN_MESSAGES_ERROR_JSON = {"id":"admin.messages.error_json","translations":{"translations":{"fr":"'Il y a une erreur dans votre fichier JSON : $message","en":"There is an error in your JSON file : $message"}}};
export const ADMIN_MESSAGES_DOCUMENT_TYPE_DELETED = {"id":"admin.messages.document_type_deleted","translations":{"translations":{"fr":"Le type de document a bien \u00e9t\u00e9 supprim\u00e9","en":"Document type successfully deleted"}}};
export const ADMIN_MESSAGES_CHECK_DOUBLON = {"id":"admin.messages.check_doublon","translations":{"translations":{"fr":"Votre fichier comporte $count email en doublon ( $diff ). Merci de le corriger et de r\u00e9essayer l'import.","en":"Your file has $count duplicate email ( $diff ). Please correct it and try again."}}};
export const ADMIN_MESSAGES_ACCOUNT_GENERATED = {"id":"admin.messages.account_generated","translations":{"translations":{"fr":"Le compte a bien \u00e9t\u00e9 g\u00e9n\u00e9r\u00e9, un e-mail lui a \u00e9t\u00e9 envoy\u00e9 lui demandant de cr\u00e9er son mot de passe.","en":"Account successfully created, an email was sent to user asking him to create a password."}}};
export const ADMIN_MESSAGES_RPPS_ALREADY_EXISTING = {"id":"admin.messages.rpps_already_existing","translations":{"translations":{"fr":"Le num\u00e9ro RPPS est d\u00e9j\u00e0 dans la liste.","en":"The RPPS number is already in the list."}}};
export const ADMIN_MESSAGES_RPPS_ADDED = {"id":"admin.messages.rpps_added","translations":{"translations":{"fr":"La fiche a \u00e9t\u00e9 d\u00e9sactiv\u00e9e","en":"The profile has been disabled"}}};
export const AGE_YEARS = {"id":"age.years","translations":{"user":{"fr":"{{ years }} ans","en":"{{ years }} years old"}}};
export const AGE_YEARS_AND_MONTH = {"id":"age.years_and_month","translations":{"user":{"fr":"{{ years }} ans et {{ month }} mois","en":"{{ years }} years and {{ month }} months old"}}};
export const AGE_MONTH = {"id":"age.month","translations":{"user":{"fr":"{{ month }} mois","en":"{{ month }} months old"}}};
export const AGE_DAYS = {"id":"age.days","translations":{"user":{"fr":"{{ days }} jours","en":"{{ days }} days old"}}};
export const TWO_FA_METHOD_SMS = {"id":"two_fa.method.sms","translations":{"user":{"fr":"SMS","en":"SMS"}}};
export const TWO_FA_METHOD_EMAIL = {"id":"two_fa.method.email","translations":{"user":{"fr":"E-mail","en":"Email"}}};
export const TWO_FA_METHOD_TOTP = {"id":"two_fa.method.totp","translations":{"user":{"fr":"application 2FA","en":"2FA application"}}};
export const ROLE_DESCRIPTION = {"id":"role.description","translations":{"user":{"fr":"Le r\u00f4le de l'utilisateur","en":"The user's role"}}};
export const ROLE_LABEL = {"id":"role.label","translations":{"user":{"fr":"R\u00f4le","en":"Role"}}};
export const ROLE_PLACEHOLDER = {"id":"role.placeholder","translations":{"user":{"fr":"S\u00e9lectionnez un r\u00f4le","en":"Select a role"}}};
export const ROLE_ROLEPATIENT = {"id":"role.ROLE_PATIENT","translations":{"user":{"fr":"Patient","en":"Patient"}}};
export const ROLE_ROLEINFIRMIERE = {"id":"role.ROLE_INFIRMIERE","translations":{"user":{"fr":"Param\u00e9dical","en":"Nurse"}}};
export const ROLE_ROLEMEDECIN = {"id":"role.ROLE_MEDECIN","translations":{"user":{"fr":"M\u00e9decin","en":"Doctor"}}};
export const ROLE_ROLEASSISTANT = {"id":"role.ROLE_ASSISTANT","translations":{"user":{"fr":"Secr\u00e9taire","en":"Secretary"}}};
export const ROLE_ROLEMEDECINPENDING = {"id":"role.ROLE_MEDECIN_PENDING","translations":{"user":{"fr":"M\u00e9decin en attente","en":"Pending doctor"}}};
export const ROLE_ROLEMEDICALCOORDINATOR = {"id":"role.ROLE_MEDICAL_COORDINATOR","translations":{"user":{"fr":"Coordinateur m\u00e9dical","en":"Medical coordinator"}}};
export const ROLE_ROLEADMIN = {"id":"role.ROLE_ADMIN","translations":{"user":{"fr":"Admin principal","en":"Primary admin"}}};
export const ROLE_ROLEHOSPITALADMIN = {"id":"role.ROLE_HOSPITAL_ADMIN","translations":{"user":{"fr":"Admin H\u00f4pital","en":"Hospital admin"}}};
export const ROLE_ROLEDEVELOPER = {"id":"role.ROLE_DEVELOPER","translations":{"user":{"fr":"D\u00e9veloppeur","en":"Developer"}}};
export const ROLE_ROLEINSTITUTIONMANAGER = {"id":"role.ROLE_INSTITUTION_MANAGER","translations":{"user":{"fr":"Manager d'institution","en":"Facility manager"}}};
export const ROLE_ROLESUPPORT = {"id":"role.ROLE_SUPPORT","translations":{"user":{"fr":"Support","en":"Support"}}};
export const ROLE_ROLERESEARCH = {"id":"role.ROLE_RESEARCH","translations":{"user":{"fr":"Recherche","en":"Research"}}};
export const LOCALE_DESCRIPTION = {"id":"locale.description","translations":{"user":{"fr":"La locale de l'utilisateur","en":"The user's locale"}}};
export const LOCALE_LABEL = {"id":"locale.label","translations":{"user":{"fr":"Langue","en":"Language"}}};
export const LOCALE_PLACEHOLDER = {"id":"locale.placeholder","translations":{"user":{"fr":"S\u00e9lectionnez une langue","en":"Select language"}}};
export const LOCALE_VALUES_FR = {"id":"locale.values.fr","translations":{"user":{"fr":"Fran\u00e7ais","en":"Fran\u00e7ais"}}};
export const LOCALE_VALUES_EN = {"id":"locale.values.en","translations":{"user":{"fr":"English","en":"English"}}};
export const TITLE_DESCRIPTION = {"id":"title.description","translations":{"user":{"fr":"La civilit\u00e9 de l'utilisateur","en":"The user's title"}}};
export const TITLE_LABEL = {"id":"title.label","translations":{"user":{"fr":"Civilit\u00e9","en":"Title"}}};
export const TITLE_PLACEHOLDER = {"id":"title.placeholder","translations":{"user":{"fr":"S\u00e9lectionnez une civilit\u00e9","en":"Select title"}}};
export const TITLE_MR = {"id":"title.mr","translations":{"user":{"fr":"Monsieur","en":"Mr."}}};
export const TITLE_MRS = {"id":"title.mrs","translations":{"user":{"fr":"Madame","en":"Mrs."}}};
export const TITLE_OTHER = {"id":"title.other","translations":{"user":{"fr":"Autre","en":"Other"}}};
export const TITLE_PROF = {"id":"title.prof","translations":{"user":{"fr":"Professeur","en":"Professor"}}};
export const TITLE_DR = {"id":"title.dr","translations":{"user":{"fr":"Docteur","en":"Doctor"}}};
export const USER_COUNTRY = {"id":"user.country","translations":{"user":{"fr":"FR","en":"FR"}}};
export const USER_ACTIVATION = {"id":"user.activation","translations":{"user":{"fr":"Activation","en":"Activation"}}};
export const USER_HOME_OFFICE = {"id":"user.home_office","translations":{"user":{"fr":"Accueil secr\u00e9tariat","en":"Secretary's office"}}};
export const USER_NOTIFICATION_LIST = {"id":"user.notification_list","translations":{"user":{"fr":"Liste des notifications","en":"List of notifications"}}};
export const USER_TUTO_HELP = {"id":"user.tuto_help","translations":{"user":{"fr":"Tutoriels & Aide","en":"Tutorials & help"}}};
export const USER_NOTIFICATIONS = {"id":"user.notifications","translations":{"user":{"fr":"Notifications","en":"Notifications"}}};
export const USER_NOTIFICATIONS_UNREAD = {"id":"user.notifications_unread","translations":{"user":{"fr":"non-lue(s)","en":"unread"}}};
export const USER_ALL_USERS = {"id":"user.all_users","translations":{"user":{"fr":"Tous les utilisateurs","en":"All users"}}};
export const USER_DATE_APPOINTMENT = {"id":"user.date_appointment","translations":{"user":{"fr":"rendez-vous du $date","en":"appointment of $date"}}};
export const USER_APPOINTMENT_ID = {"id":"user.appointment_id","translations":{"user":{"fr":"Rdv n\u00b0 $id","en":"Appointment # $id"}}};
export const USER_ACCESS_INFORMATIONS = {"id":"user.access_informations","translations":{"user":{"fr":"Informations d'acc\u00e8s","en":"Access information"}}};
export const USER_EVENT_LIST = {"id":"user.event_list","translations":{"user":{"fr":"Liste des \u00e9v\u00e9nements Instamed du $date","en":"List of Instamed appointments of $date"}}};
export const USER_PERMIT_PAYMENT = {"id":"user.permit_payment","translations":{"user":{"fr":"Pour accepter, vous devez autoriser son paiement du montant de ","en":"To accept, you must authorize the payment of "}}};
export const USER_EMAIL_RESENTED = {"id":"user.email_resented","translations":{"user":{"fr":"L'e-mail a bien \u00e9t\u00e9 renvoy\u00e9","en":"Email was successfully resent"}}};
export const USER_MESSAGE_SENTED = {"id":"user.message_sented","translations":{"user":{"fr":"Message envoy\u00e9 avec succ\u00e8s","en":"Message sent"}}};
export const USER_MESSAGE_ADDED_TO_QUEUE = {"id":"user.message_added_to_queue","translations":{"user":{"fr":"Le message a bien \u00e9t\u00e9 ajout\u00e9 \u00e0 la file d'attente"}}};
export const USER_CONTACT_INFORMATIONS = {"id":"user.contact_informations","translations":{"user":{"fr":"Informations de contact","en":"Contact information"}}};
export const USER_CONSULTATION_TODAY = {"id":"user.consultation_today","translations":{"user":{"fr":"Consultation aujourd'hui \u00e0 $hour","en":"Consultation today at $hour"}}};
export const USER_NEXT_CONSULTATION = {"id":"user.next_consultation","translations":{"user":{"fr":"Prochaine consult. le $date","en":"Next consultation the $date"}}};
export const USER_SEEN = {"id":"user.seen","translations":{"user":{"fr":"Vu le $date","en":"Seen the $date"}}};
export const USER_SEEN_TODAY = {"id":"user.seen_today","translations":{"user":{"fr":"Vu aujourd'hui \u00e0 $hour","en":"Seen today at $hour"}}};
export const USER_EMAIL_SENT = {"id":"user.email_sent","translations":{"user":{"fr":"L'email d'invitation a bien \u00e9t\u00e9 renvoy\u00e9","en":"Invitation email has been resent"}}};
export const USER_RESET_MAIL_SENT = {"id":"user.reset_mail_sent","translations":{"user":{"fr":"L'email de r\u00e9initialisation de mot de passe a bien \u00e9t\u00e9 renvoy\u00e9","en":"Password reset email successfully resent"}}};
export const USER_SMS_SENT = {"id":"user.sms_sent","translations":{"user":{"fr":"Le SMS d'invitation a bien \u00e9t\u00e9 renvoy\u00e9","en":"Invitation SMS has been resent"}}};
export const USER_ACCESS_REQUEST = {"id":"user.access_request","translations":{"user":{"fr":"Demande d'acc\u00e8s au profil","en":"Record access request"}}};
export const USER_WISH_ACCESS = {"id":"user.wish_access","translations":{"user":{"fr":"$name souhaiterait acc\u00e9der \u00e0 ce profil.","en":"$name would like to access this record."}}};
export const USER_ACCESS_GRANTED = {"id":"user.access_granted","translations":{"user":{"fr":"L'acc\u00e8s au dossier a bien \u00e9t\u00e9 accord\u00e9 \u00e0 $name","en":"Record access was successfully granted to $name"}}};
export const USER_ACCESS_REFUSED = {"id":"user.access_refused","translations":{"user":{"fr":"L'acc\u00e8s au dossier a bien \u00e9t\u00e9 refus\u00e9 \u00e0 $name","en":"Record access was successfully denied to $name"}}};
export const USER_POSTAL_ADDRESS_PLACEHOLDER = {"id":"user.postal_address_placeholder","translations":{"user":{"fr":"Entrez l\u2019adresse postale\u2026","en":"Enter postal address\u2026"}}};
export const USER_COPIED_LINK = {"id":"user.copied_link","translations":{"user":{"fr":"Lien copi\u00e9","en":"Copied link"}}};
export const USER_CGU_AGREED = {"id":"user.cgu_agreed","translations":{"user":{"fr":"J'ai lu et j'accepte les conditions g\u00e9n\u00e9rales d'utilisation Instamed","en":"I have read and agreed to Instamed's Terms of Use"}}};
export const USER_ADDRESS_TYPE_ADDRESS = {"id":"user.address_type.address","translations":{"user":{"fr":"Domicile","en":"Home"}}};
export const USER_ADDRESS_TYPE_LABORATORY = {"id":"user.address_type.laboratory","translations":{"user":{"fr":"Laboratoire","en":"Laboratory"}}};
export const USER_ADDRESS_TYPE_LABORATORIES = {"id":"user.address_type.laboratories","translations":{"user":{"fr":"Laboratoires","en":"Laboratories"}}};
export const USER_ADDRESS_TYPE_PHARMACY = {"id":"user.address_type.pharmacy","translations":{"user":{"fr":"Pharmacie","en":"Pharmacy"}}};
export const USER_ADDRESS_TYPE_PHARMACIES = {"id":"user.address_type.pharmacies","translations":{"user":{"fr":"Pharmacies","en":"Pharmacies"}}};
export const USER_TITLE_LABEL = {"id":"user.title.label","translations":{"user":{"fr":"Civilit\u00e9","en":"Title"}}};
export const USER_TITLE_SIR = {"id":"user.title.sir","translations":{"user":{"fr":"Monsieur","en":"Mr"}}};
export const USER_TITLE_MADAM = {"id":"user.title.madam","translations":{"user":{"fr":"Madame","en":"Mrs"}}};
export const USER_TITLE_OTHER = {"id":"user.title.other","translations":{"user":{"fr":"Autre","en":"Other"}}};
export const USER_TITLE_PROFESSOR = {"id":"user.title.professor","translations":{"user":{"fr":"Professeur","en":"Professor"}}};
export const USER_TITLE_DOCTOR = {"id":"user.title.doctor","translations":{"user":{"fr":"Docteur","en":"Doctor"}}};
export const USER_TITLE_NURSE = {"id":"user.title.nurse","translations":{"user":{"fr":"Infirmi\u00e8re","en":"Nurse"}}};
export const USER_TITLE_SHORT_SIR = {"id":"user.title.short.sir","translations":{"user":{"fr":"M.","en":"Mr"}}};
export const USER_TITLE_SHORT_MADAM = {"id":"user.title.short.madam","translations":{"user":{"fr":"Mme.","en":"Mrs"}}};
export const USER_TITLE_SHORT_OTHER = {"id":"user.title.short.other","translations":{"user":{"fr":"Autre","en":"Other"}}};
export const USER_TITLE_SHORT_PROFESSOR = {"id":"user.title.short.professor","translations":{"user":{"fr":"Pr","en":"Pr"}}};
export const USER_TITLE_SHORT_DOCTOR = {"id":"user.title.short.doctor","translations":{"user":{"fr":"Dr","en":"Dr"}}};
export const USER_TITLE_SHORT_NURSE = {"id":"user.title.short.nurse","translations":{"user":{"fr":"Inf","en":"Inf"}}};
export const USER_ROLE_PATIENTS = {"id":"user.role.patients","translations":{"user":{"fr":"Patients","en":"Patients"}}};
export const USER_ROLE_PATIENT = {"id":"user.role.patient","translations":{"user":{"fr":"Patient","en":"Patient"}}};
export const USER_ROLE_PARAMEDICALS = {"id":"user.role.paramedicals","translations":{"user":{"fr":"Param\u00e9dicaux","en":"Paramedic"}}};
export const USER_ROLE_PARAMEDICAL = {"id":"user.role.paramedical","translations":{"user":{"fr":"Param\u00e9dical","en":"Paramedic"}}};
export const USER_ROLE_DOCTORS = {"id":"user.role.doctors","translations":{"user":{"fr":"M\u00e9decins","en":"Doctors"}}};
export const USER_ROLE_DOCTOR = {"id":"user.role.doctor","translations":{"user":{"fr":"M\u00e9decin","en":"Doctor"}}};
export const USER_ROLE_COORDINATORS = {"id":"user.role.coordinators","translations":{"user":{"fr":"Coordinateurs m\u00e9dicaux","en":"Medical coordinators"}}};
export const USER_ROLE_COORDINATOR = {"id":"user.role.coordinator","translations":{"user":{"fr":"Coordinateur M\u00e9dical","en":"Medical coordinator"}}};
export const USER_ROLE_DEV = {"id":"user.role.dev","translations":{"user":{"fr":"D\u00e9veloppeur","en":"Developer"}}};
export const USER_ROLE_RESEARCHERS = {"id":"user.role.researchers","translations":{"user":{"fr":"Chercheurs","en":"Researchers"}}};
export const USER_ROLE_RESEARCHER = {"id":"user.role.researcher","translations":{"user":{"fr":"Chercheur","en":"Researcher"}}};
export const USER_ROLE_SUPPORTS = {"id":"user.role.supports","translations":{"user":{"fr":"Supports","en":"Supports"}}};
export const USER_ROLE_SUPPORT = {"id":"user.role.support","translations":{"user":{"fr":"Support","en":"Support"}}};
export const USER_ROLE_WEBMASTER = {"id":"user.role.webmaster","translations":{"user":{"fr":"Webmaster","en":"Webmaster"}}};
export const USER_ROLE_WEBMASTERS = {"id":"user.role.webmasters","translations":{"user":{"fr":"Webmasters","en":"Webmasters"}}};
export const USER_ROLE_MANAGER = {"id":"user.role.manager","translations":{"user":{"fr":"Manager","en":"Manager"}}};
export const USER_ROLE_MANAGERS = {"id":"user.role.managers","translations":{"user":{"fr":"Managers","en":"Managers"}}};
export const USER_ROLE_PENDING_DOCTOR = {"id":"user.role.pending_doctor","translations":{"user":{"fr":"M\u00e9decin invit\u00e9","en":"Invited doctor"}}};
export const USER_ROLE_ASSISTANT = {"id":"user.role.assistant","translations":{"user":{"fr":"Secr\u00e9taire","en":"Secretary"}}};
export const USER_ROLE_ASSISTANTS = {"id":"user.role.assistants","translations":{"user":{"fr":"Secr\u00e9taires","en":"Secretaries"}}};
export const USER_WORDS_PROFILE = {"id":"user.words.profile","translations":{"user":{"fr":"votre profil","en":"your profile"}}};
export const USER_WORDS_NOT_FOUND = {"id":"user.words.not_found","translations":{"user":{"fr":"Aucun utilisateur trouv\u00e9","en":"No user found"}}};
export const USER_WORDS_SECURITY = {"id":"user.words.security","translations":{"user":{"fr":"S\u00e9curit\u00e9","en":"Security"}}};
export const USER_FIELDS_TITLE = {"id":"user.fields.title","translations":{"user":{"fr":"Titre","en":"Title"}}};
export const USER_FIELDS_CONTENT = {"id":"user.fields.content","translations":{"user":{"fr":"Contenu","en":"Content"}}};
export const USER_FIELDS_INVALID = {"id":"user.fields.invalid","translations":{"user":{"fr":"Ce champ est invalide","en":"This field is invalid"}}};
export const USER_FIELDS_YOUR_SPECIALTIES = {"id":"user.fields.your_specialties","translations":{"user":{"fr":"Vos sp\u00e9cialit\u00e9s","en":"Specialties"}}};
export const USER_FIELDS_SPECIALITIES = {"id":"user.fields.specialities","translations":{"user":{"fr":"Sp\u00e9cialit\u00e9(s)","en":"Specialities"}}};
export const USER_FIELDS_SERVICES = {"id":"user.fields.services","translations":{"user":{"fr":"Service(s)","en":"Departments"}}};
export const USER_FIELDS_RIGHTS = {"id":"user.fields.rights","translations":{"user":{"fr":"Droits(s)","en":"Rights"}}};
export const USER_FIELDS_FIRST_NAME = {"id":"user.fields.first_name","translations":{"user":{"fr":"Pr\u00e9nom","en":"First name"}}};
export const USER_FIELDS_FIRST_NAMES = {"id":"user.fields.first_names","translations":{"user":{"fr":"Pr\u00e9nom(s)","en":"First Names"}}};
export const USER_FIELDS_LAST_NAME = {"id":"user.fields.last_name","translations":{"user":{"fr":"Nom","en":"Name"}}};
export const USER_FIELDS_BIRTH_NAME = {"id":"user.fields.birth_name","translations":{"user":{"fr":"Nom de naissance","en":"Birth name"}}};
export const USER_FIELDS_COMMON_NAME = {"id":"user.fields.common_name","translations":{"user":{"fr":"Nom d'usage","en":"Preferred name"}}};
export const USER_FIELDS_USERNAME = {"id":"user.fields.username","translations":{"user":{"fr":"Nom d'utilisateur","en":"Username"}}};
export const USER_FIELDS_BIRTHDATE = {"id":"user.fields.birthdate","translations":{"user":{"fr":"Date de naissance","en":"Date of birth"}}};
export const USER_FIELDS_BIRTH_PLACE = {"id":"user.fields.birth_place","translations":{"user":{"fr":"Lieu de naissance","en":"Place of birth"}}};
export const USER_FIELDS_CREATION_DATE = {"id":"user.fields.creation_date","translations":{"user":{"fr":"Date de cr\u00e9ation","en":"Date of creation"}}};
export const USER_FIELDS_EXTERNAL_IDS = {"id":"user.fields.external_ids","translations":{"user":{"fr":"Ids externe de l'utilisateur","en":"User external IDs"}}};
export const USER_FIELDS_YOUR_ADDRESS = {"id":"user.fields.your_address","translations":{"user":{"fr":"Votre adresse","en":"Address"}}};
export const USER_FIELDS_ADDRESS = {"id":"user.fields.address","translations":{"user":{"fr":"Adresse","en":"Address"}}};
export const USER_FIELDS_ADDRESSES = {"id":"user.fields.addresses","translations":{"user":{"fr":"Adresses","en":"Addresses"}}};
export const USER_FIELDS_ADDRESS_EXTENSION = {"id":"user.fields.address_extension","translations":{"user":{"fr":"Compl\u00e9ment d'adresse","en":"Address extension"}}};
export const USER_FIELDS_ADDRESS_TYPE = {"id":"user.fields.address_type","translations":{"user":{"fr":"Type d'adresse","en":"Address type"}}};
export const USER_FIELDS_POSTAL_ADDRESS = {"id":"user.fields.postal_address","translations":{"user":{"fr":"Adresse postale","en":"Address"}}};
export const USER_FIELDS_POSTAL_ADDRESS_PLACEHOLDER = {"id":"user.fields.postal_address_placeholder","translations":{"user":{"fr":"Entrez l\u2019adresse postale\u2026","en":"Enter postal address\u2026"}}};
export const USER_FIELDS_POSTAL_CODE = {"id":"user.fields.postal_code","translations":{"user":{"fr":"Code postal","en":"Postal code"}}};
export const USER_FIELDS_CITY = {"id":"user.fields.city","translations":{"user":{"fr":"Ville","en":"City"}}};
export const USER_FIELDS_COUNTRY = {"id":"user.fields.country","translations":{"user":{"fr":"Pays","en":"Country"}}};
export const USER_FIELDS_EMAIL = {"id":"user.fields.email","translations":{"user":{"fr":"E-mail","en":"Email"}}};
export const USER_FIELDS_PHONE = {"id":"user.fields.phone","translations":{"user":{"fr":"T\u00e9l\u00e9phone","en":"Phone number"}}};
export const USER_FIELDS_PHONES = {"id":"user.fields.phones","translations":{"user":{"fr":"T\u00e9l\u00e9phone(s)","en":"Phone numbers"}}};
export const USER_FIELDS_MOBILE = {"id":"user.fields.mobile","translations":{"user":{"fr":"Tel Mobile","en":"Mobile phone"}}};
export const USER_FIELDS_LANDLINE = {"id":"user.fields.landline","translations":{"user":{"fr":"Tel fix","en":"Permanent phone "}}};
export const USER_FIELDS_OTHER_PHONE = {"id":"user.fields.other_phone","translations":{"user":{"fr":"Autre Tel","en":"Other phone number"}}};
export const USER_FIELDS_NOTE = {"id":"user.fields.note","translations":{"user":{"fr":"Note","en":"Note"}}};
export const USER_FIELDS_ID = {"id":"user.fields.id","translations":{"user":{"fr":"Identifiant","en":"Username"}}};
export const USER_FIELDS_ID_SHORT = {"id":"user.fields.id_short","translations":{"user":{"fr":"Id","en":"ID"}}};
export const USER_FIELDS_CONTACT = {"id":"user.fields.contact","translations":{"user":{"fr":"Contact","en":"Contact"}}};
export const USER_FIELDS_PASSWORD = {"id":"user.fields.password","translations":{"user":{"fr":"Mot de passe","en":"Password"}}};
export const USER_FIELDS_ID_CARD = {"id":"user.fields.id_card","translations":{"user":{"fr":"Pi\u00e8ce d'identit\u00e9","en":"ID Document"}}};
export const USER_FIELDS_BLOOD_TYPE = {"id":"user.fields.blood_type","translations":{"user":{"fr":"Groupe Sanguin","en":"Blood type"}}};
export const USER_FIELDS_BLOOD_TYPE_PLACEHOLDER = {"id":"user.fields.blood_type_placeholder","translations":{"user":{"fr":"S\u00e9lectionnez un groupe sanguin","en":"Select blood type"}}};
export const USER_FIELDS_EMAIL_MSSANTE = {"id":"user.fields.email_mssante","translations":{"user":{"fr":"S\u00e9lectionner l\u2019adresse MSSant\u00e9","en":"Select the MSSante address"}}};
export const USER_FIELDS_MSSANTE_OWNER = {"id":"user.fields.mssante_owner","translations":{"user":{"fr":"S\u00e9lectionner le m\u00e9decin pour lequel envoyer l'invitation"}}};
export const USER_FIELDS_SEX = {"id":"user.fields.sex","translations":{"user":{"fr":"Sexe","en":"Gender"}}};
export const USER_FIELDS_MALE = {"id":"user.fields.male","translations":{"user":{"fr":"Masculin","en":"Male"}}};
export const USER_FIELDS_FEMALE = {"id":"user.fields.female","translations":{"user":{"fr":"Feminin","en":"Female"}}};
export const USER_FIELDS_SEX_PLACEHOLDER = {"id":"user.fields.sex_placeholder","translations":{"user":{"fr":"Choisissez un sexe","en":"Select gender"}}};
export const USER_FIELDS_PATIENT_SEX = {"id":"user.fields.patient_sex","translations":{"user":{"fr":"Sexe du patient : $sex","en":"Patient gender : $sex"}}};
export const USER_FIELDS_SIZE = {"id":"user.fields.size","translations":{"user":{"fr":"Taille","en":"Height"}}};
export const USER_FIELDS_WEIGHT = {"id":"user.fields.weight","translations":{"user":{"fr":"Poids","en":"Weight"}}};
export const USER_FIELDS_AGE = {"id":"user.fields.age","translations":{"user":{"fr":"Age","en":"Age"}}};
export const USER_FIELDS_ROLE = {"id":"user.fields.role","translations":{"user":{"fr":"R\u00f4le","en":"Role"}}};
export const USER_FIELDS_ALLERGIES = {"id":"user.fields.allergies","translations":{"user":{"fr":"Allergies","en":"Allergies"}}};
export const USER_FIELDS_PARENT = {"id":"user.fields.parent","translations":{"user":{"fr":"Parent","en":"Parent"}}};
export const USER_FIELDS_COPY_IN = {"id":"user.fields.copy_in","translations":{"user":{"fr":"Est en copie de mes messages","en":"Is in copy of my messages"}}};
export const USER_FIELDS_USE_LIA = {"id":"user.fields.use_lia","translations":{"user":{"fr":"Peut utiliser mes cr\u00e9dits  Lia","en":"Can use my Lia credits"}}};
export const USER_FIELDS_CREATE_PRESCRIPTION = {"id":"user.fields.create_prescription","translations":{"user":{"fr":"Peut cr\u00e9er des ordonnances","en":"Can create prescriptions"}}};
export const USER_FIELDS_MEDICAL_COVERAGE = {"id":"user.fields.medical_coverage","translations":{"user":{"fr":"Couverture m\u00e9dicale","en":"Medical coverage"}}};
export const USER_FIELDS_MUTUAL_INSURANCE = {"id":"user.fields.mutual_insurance","translations":{"user":{"fr":"Mutuelle","en":"Medical insurance"}}};
export const USER_FIELDS_MUTUAL_NUMBER = {"id":"user.fields.mutual_number","translations":{"user":{"fr":"N\u00b0 de mutuelle","en":"Insurance number"}}};
export const USER_FIELDS_PROFILE_PICTURE = {"id":"user.fields.profile_picture","translations":{"user":{"fr":"Photo de profil","en":"Profile picture"}}};
export const USER_FIELDS_EXTERNAL_ID = {"id":"user.fields.external_id","translations":{"user":{"fr":"N\u00b0{{ provider }} (IPP)","en":"No. {{ provider }} (IPP) "}}};
export const USER_FIELDS_SOCIAL_SECURITY_NUMBER = {"id":"user.fields.social_security_number","translations":{"user":{"fr":"N\u00b0 de s\u00e9curit\u00e9 sociale","en":"Social security number"}}};
export const USER_FIELDS_PLACEHOLDER = {"id":"user.fields.placeholder","translations":{"user":{"fr":"Nom, email, date de naissance...","en":"Name, email, date of birth..."}}};
export const USER_FIELDS_DOCTOR_PHONE = {"id":"user.fields.doctor_phone","translations":{"user":{"fr":"T\u00e9l\u00e9phone du m\u00e9decin","en":"Doctor's phone"}}};
export const USER_FIELDS_DOCTOR_NAME = {"id":"user.fields.doctor_name","translations":{"user":{"fr":"Nom du m\u00e9decin","en":"Doctor's name"}}};
export const USER_FIELDS_DOCTOR_ADDRESS = {"id":"user.fields.doctor_address","translations":{"user":{"fr":"Adresse compl\u00e8te du m\u00e9decin","en":"Full address of the doctor"}}};
export const USER_FIELDS_DOCTOR_SPECIALTY = {"id":"user.fields.doctor_specialty","translations":{"user":{"fr":"Sp\u00e9cialit\u00e9 du m\u00e9decin : $specialties","en":"Doctor's speciality : $specialties"}}};
export const USER_FIELDS_DOOR_CODE = {"id":"user.fields.door_code","translations":{"user":{"fr":"Digicode","en":"Entrance code"}}};
export const USER_FIELDS_DOOR_CODE2 = {"id":"user.fields.door_code_2","translations":{"user":{"fr":"Digicode 2","en":"Entrance code 2"}}};
export const USER_FIELDS_PATIENT_NAME = {"id":"user.fields.patient_name","translations":{"user":{"fr":"Nom du Patient","en":"Patient name"}}};
export const USER_FIELDS_PATIENT_ADDRESS = {"id":"user.fields.patient_address","translations":{"user":{"fr":"Adresse du patient","en":"Patient address"}}};
export const USER_FIELDS_STOREY = {"id":"user.fields.storey","translations":{"user":{"fr":"Etage","en":"Floor"}}};
export const USER_FIELDS_TIMEZONE = {"id":"user.fields.timezone","translations":{"user":{"fr":"Fuseau horaire","en":"Timezone"}}};
export const USER_FIELDS_CPS_NUMBER = {"id":"user.fields.cps_number","translations":{"user":{"fr":"N\u00b0 de votre carte CPS","en":"CPS card No."}}};
export const USER_FIELDS_CPS = {"id":"user.fields.cps","translations":{"user":{"fr":"Votre carte CPS","en":"CPS card"}}};
export const USER_FIELDS_RPPS = {"id":"user.fields.rpps","translations":{"user":{"fr":"N\u00b0 RPPS","en":"RPPS No."}}};
export const USER_FIELDS_EMAIL_API_CRYPT = {"id":"user.fields.email_api_crypt","translations":{"user":{"fr":"Email ApiCrypt","en":"ApiCrypt email"}}};
export const USER_FIELDS_EMAIL_MS_SANTE = {"id":"user.fields.email_ms_sante","translations":{"user":{"fr":"Email MSSant\u00e9","en":"MSSante email"}}};
export const USER_FIELDS_WEBSITE = {"id":"user.fields.website","translations":{"user":{"fr":"Site internet","en":"Website"}}};
export const USER_FIELDS_ADELI = {"id":"user.fields.adeli","translations":{"user":{"fr":"N\u00b0 ADELI","en":"ADELI No."}}};
export const USER_FIELDS_SIRET = {"id":"user.fields.siret","translations":{"user":{"fr":"N\u00b0 Siret","en":"Siret No."}}};
export const USER_FIELDS_FINESS = {"id":"user.fields.finess","translations":{"user":{"fr":"N\u00b0 FINESS","en":"FINESS No."}}};
export const USER_FIELDS_FAX = {"id":"user.fields.fax","translations":{"user":{"fr":"N\u00b0 de fax","en":"Fax No."}}};
export const USER_FIELDS_DEPENDENT = {"id":"user.fields.dependent","translations":{"user":{"fr":" \u00e0 charge","en":" dependent"}}};
export const USER_FIELDS_INDEPENDENT = {"id":"user.fields.independent","translations":{"user":{"fr":" non \u00e0 charge","en":"non dependent"}}};
export const USER_FIELDS_DEPENDENT_CHILD = {"id":"user.fields.dependent_child","translations":{"user":{"fr":"L'enfant est-il \u00e0 charge ?","en":"Is the child a dependent?"}}};
export const USER_FIELDS_CONSULTATION_DATE = {"id":"user.fields.consultation_date","translations":{"user":{"fr":"Date de la consultation : $date","en":"Consultation date : $date"}}};
export const USER_FIELDS_PATIENT_DISEASE = {"id":"user.fields.patient_disease","translations":{"user":{"fr":"Maladies du patient: $diseases","en":"Patient diseases: $diseases"}}};
export const USER_FIELDS_BODY_PART = {"id":"user.fields.body_part","translations":{"user":{"fr":"Partie du corps : $body_part","en":"Body part : $body_part"}}};
export const USER_FIELDS_DEATH_DATE = {"id":"user.fields.death_date","translations":{"user":{"fr":"Date de d\u00e9c\u00e8s","en":"Death date"}}};
export const USER_FIELDS_FAMILY_MEMBER_TITLE = {"id":"user.fields.family_member.title","translations":{"user":{"fr":"Membre de la famille","en":"Family member"}}};
export const USER_FIELDS_FAMILY_MEMBER_BIRTHDATE = {"id":"user.fields.family_member.birthdate","translations":{"user":{"fr":"Date de naissance du membre de la famille","en":"Family member's date of birth"}}};
export const USER_FIELDS_FAMILY_MEMBER_SEX = {"id":"user.fields.family_member.sex","translations":{"user":{"fr":"Sexe de la personne","en":"Gender of family member"}}};
export const USER_FIELDS_FAMILY_MEMBER_EMAIL = {"id":"user.fields.family_member.email","translations":{"user":{"fr":"Email du membre de la famille","en":"Family member's email"}}};
export const USER_FIELDS_FAMILY_MEMBER_IS_ALIVE = {"id":"user.fields.family_member.is_alive","translations":{"user":{"fr":"Le membre de la famille est-il en vie ?","en":"Is the family member alive?"}}};
export const USER_FIELDS_FAMILY_MEMBER_DECEASED = {"id":"user.fields.family_member.deceased","translations":{"user":{"fr":"Ann\u00e9e de d\u00e9c\u00e8s du membre de la famille","en":"Family member's year of death"}}};
export const USER_ACTIONS_MODIFY_PROFILE_OF = {"id":"user.actions.modify_profile_of","translations":{"user":{"fr":"Modifier les informations de ","en":"Edit information for "}}};
export const USER_ACTIONS_SHOW_ALERTS = {"id":"user.actions.show_alerts","translations":{"user":{"fr":"Voir le d\u00e9tail des alertes","en":"View alert details"}}};
export const USER_ACTIONS_SYNCHRONIZE_CALENDAR = {"id":"user.actions.synchronize_calendar","translations":{"user":{"fr":"Synchroniser dans mon calendrier","en":"Sync in my calendar"}}};
export const USER_ACTIONS_SWITCH_INTERFACE = {"id":"user.actions.switch_interface","translations":{"user":{"fr":"Basculer sur la nouvelle interface","en":"Switch to new interface"}}};
export const USER_ACTIONS_DOWNLOAD_APP = {"id":"user.actions.download_app","translations":{"user":{"fr":"T\u00e9l\u00e9charger l'application mobile","en":"Download mobile app"}}};
export const USER_ACTIONS_CHANGE_PASSWORD = {"id":"user.actions.change_password","translations":{"user":{"fr":"Modifiez votre mot de passe","en":"Change password"}}};
export const USER_ACTIONS_NOT_CHANGE_PASSWORD = {"id":"user.actions.not_change_password","translations":{"user":{"fr":"Ne pas modifier mon mot de passe","en":"Do not change my password"}}};
export const USER_ACTIONS_CREATE_CONSULTATION = {"id":"user.actions.create_consultation","translations":{"user":{"fr":"Cr\u00e9er une consultation","en":"Create a consultation"}}};
export const USER_ACTIONS_SEARCH_PATIENT = {"id":"user.actions.search_patient","translations":{"user":{"fr":"Rechercher un patient","en":"Search for a patient"}}};
export const USER_ACTIONS_CLASS_DOCS = {"id":"user.actions.class_docs","translations":{"user":{"fr":"Classer les documents","en":"Sort documents"}}};
export const USER_ACTIONS_OPEN_HELP = {"id":"user.actions.open_help","translations":{"user":{"fr":"Ouvrir le Centre d'aide","en":"Open help center"}}};
export const USER_ACTIONS_MARK_AS_READ = {"id":"user.actions.mark_as_read","translations":{"user":{"fr":"Tout marquer comme lu","en":"Mark all as read"}}};
export const USER_ACTIONS_DOWNLOAD_FIREFOX = {"id":"user.actions.download_firefox","translations":{"user":{"fr":"T\u00e9l\u00e9charger Mozilla Firefox","en":"Download Mozilla Firefox"}}};
export const USER_ACTIONS_DOWNLOAD_CHROME = {"id":"user.actions.download_chrome","translations":{"user":{"fr":"T\u00e9l\u00e9charger Google Chrome","en":"Download Google Chrome"}}};
export const USER_ACTIONS_CONNECT = {"id":"user.actions.connect","translations":{"user":{"fr":"Me connecter \u00e0 mon compte","en":"Log in"}}};
export const USER_ACTIONS_COMPLETE_PROFILE = {"id":"user.actions.complete_profile","translations":{"user":{"fr":"Compl\u00e9tez votre profil","en":"Complete your profile"}}};
export const USER_ACTIONS_UPDATE_PASSWORD = {"id":"user.actions.update_password","translations":{"user":{"fr":"Modifier le mot de passe","en":"Change password"}}};
export const USER_ACTIONS_BACK_ADMIN = {"id":"user.actions.back_admin","translations":{"user":{"fr":"Retour \u00e0 l'admin principal","en":"Back to main admin"}}};
export const USER_ACTIONS_ALLOW_SHARE = {"id":"user.actions.allow_share","translations":{"user":{"fr":"Autoriser le partage de mon dossier entre m\u00e9decins","en":"Allow my medical record to be shared between caregivers"}}};
export const USER_ACTIONS_HANDLE_NOTIF = {"id":"user.actions.handle_notif","translations":{"user":{"fr":"G\u00e9rer les notifications","en":"Manage notifications"}}};
export const USER_ACTIONS_SEND_RESET_PASSWORD_MAIL = {"id":"user.actions.send_reset_password_mail","translations":{"user":{"fr":"Envoyer un email de r\u00e9initialisation de mot de passe","en":"Send a password reset email"}}};
export const USER_ACTIONS_SEND_ACTIVATION_MAIL = {"id":"user.actions.send_activation_mail","translations":{"user":{"fr":"Envoyer l'e-mail d'activation","en":"Send activation email"}}};
export const USER_ACTIONS_SEND_SMS_INVITATION = {"id":"user.actions.send_sms_invitation","translations":{"user":{"fr":"Envoyer un SMS d'invitation","en":"Send SMS invitation "}}};
export const USER_ACTIONS_RESEND_ACTIVATION_MAIL = {"id":"user.actions.resend_activation_mail","translations":{"user":{"fr":"Renvoyer l'e-mail d'activation","en":"Resend activation e-mail"}}};
export const USER_ACTIONS_RESEND_SMS_INVITATION = {"id":"user.actions.resend_sms_invitation","translations":{"user":{"fr":"Renvoyer un SMS d'invitation","en":"Resend SMS invitation"}}};
export const USER_ACTIONS_BLOCK_ALL_COMMUNICATIONS = {"id":"user.actions.block_all_communications","translations":{"user":{"fr":"Bloquer toutes les notifications envoy\u00e9es au patient","en":"Block all notifications sent to the patient"}}};
export const USER_ACTIONS_LINK_PROFILE = {"id":"user.actions.link_profile","translations":{"user":{"fr":"Lier ce profil \u00e0 un ou plusieurs m\u00e9decins","en":"Link this record to one or more caregivers"}}};
export const USER_ACTIONS_CREATE_PATIENT = {"id":"user.actions.create_patient","translations":{"user":{"fr":"Cr\u00e9er le patient","en":"Create patient"}}};
export const USER_ACTIONS_SEARCH_PLACE = {"id":"user.actions.search_place","translations":{"user":{"fr":"Recherchez un lieu","en":"Search for a location"}}};
export const USER_ACTIONS_SELECT_CIVILITY = {"id":"user.actions.select_civility","translations":{"user":{"fr":"Choisissez une civilit\u00e9","en":"Select title"}}};
export const USER_ACTIONS_ACTIVATE_ACCOUNT = {"id":"user.actions.activate_account","translations":{"user":{"fr":"Activer le compte","en":"Activate account"}}};
export const USER_ACTIONS_SELECT_SERVICES = {"id":"user.actions.select_services","translations":{"user":{"fr":"Choisissez les services","en":"Select departments"}}};
export const USER_ACTIONS_SHOW_SUBSCRIPTION = {"id":"user.actions.show_subscription","translations":{"user":{"fr":"Voir les abonnements","en":"View plans"}}};
export const USER_ACTIONS_ADD_PHONE = {"id":"user.actions.add_phone","translations":{"user":{"fr":"Ajouter un t\u00e9l\u00e9phone","en":"Add phone"}}};
export const USER_ACTIONS_ADD_IN_CONVERSATION = {"id":"user.actions.add_in_conversation","translations":{"user":{"fr":"Ajouter dans la conversation","en":"Add to conversation"}}};
export const USER_ACTIONS_ADD_ADDRESS = {"id":"user.actions.add_address","translations":{"user":{"fr":"Ajouter une adresse","en":"Add address"}}};
export const USER_ACTIONS_ADD_LABORATORY = {"id":"user.actions.add_laboratory","translations":{"user":{"fr":"Ajouter un laboratoire","en":"Add lab"}}};
export const USER_ACTIONS_ADD_PHARMACY = {"id":"user.actions.add_pharmacy","translations":{"user":{"fr":"Ajouter une pharmacie","en":"Add pharmacy"}}};
export const USER_ACTIONS_ADD_ELEMENT = {"id":"user.actions.add_element","translations":{"user":{"fr":"Ajouter un \u00e9l\u00e9ment apr\u00e8s celui l\u00e0","en":"Add an item after this one"}}};
export const USER_ERROR_OCCURRED = {"id":"user.error.occurred","translations":{"user":{"fr":"Une erreur est survenue, veuillez r\u00e9essayer","en":"An error occurred, please try again"}}};
export const USER_ERROR_DURING_UPLOAD = {"id":"user.error.during_upload","translations":{"user":{"fr":"Une erreur est survenue lors de l'upload du document, veuillez r\u00e9essayer plus tard.","en":"An error occurred while uploading the document, please try again later."}}};
export const USER_ERROR_ALREADY_CONNECTED = {"id":"user.error.already_connected","translations":{"user":{"fr":"Vous \u00eates d\u00e9j\u00e0 connect\u00e9 sur un autre compte.","en":"You are already logged in to another account."}}};
export const USER_ERROR_ALREADY_ACTIVATED = {"id":"user.error.already_activated","translations":{"user":{"fr":"Le compte est d\u00e9j\u00e0 activ\u00e9","en":"This account is already active"}}};
export const USER_ERROR_INCORRECT_LINK = {"id":"user.error.incorrect_link","translations":{"user":{"fr":"Ce lien est incorrect, veuillez retourner sur votre page d'accueil.","en":"This link is incorrect, please return to your homepage."}}};
export const USER_ERROR_INVALID_PHONE = {"id":"user.error.invalid_phone","translations":{"user":{"fr":"Le num\u00e9ro de t\u00e9l\u00e9phone est invalide","en":"The phone number is invalid"}}};
export const USER_ERROR_PSC_CONNECT = {"id":"user.error.psc_connect","translations":{"user":{"fr":"Une erreur est survenue lors de la connexion \u00e0 Pro Sant\u00e9 Connect. Veuillez r\u00e9essayer plus tard.","en":"An error occurred while connecting to Pro Sante Connect. Please try again later."}}};
export const USER_ERROR_PSC_DISCONNECT = {"id":"user.error.psc_disconnect","translations":{"user":{"fr":"Une erreur s'est produite lors de la d\u00e9connexion de votre compte Pro Sant\u00e9 Connect.","en":"An error occured while disconnecting your Pro Sante Connect account."}}};
export const USER_ERROR_INTERFACE_ACTIVATION = {"id":"user.error.interface_activation","translations":{"user":{"fr":"Erreur lors de l\u2019activation de la nouvelle interface. Veuillez r\u00e9essayer.","en":"An error occurred while activating the new interface. Please try again."}}};
export const USER_ERROR_INCORRECT_FIELD = {"id":"user.error.incorrect_field","translations":{"user":{"fr":"Un ou plusieurs champs sont incorrects","en":"One or more fields are incorrect"}}};
export const USER_ERROR_ADDRESS_NOT_FOUND = {"id":"user.error.address_not_found","translations":{"user":{"fr":"Je ne trouve pas l'adresse dans la liste","en":"I can't find the address in the list"}}};
export const USER_ERROR_NOT_FOUND_DOCTOR = {"id":"user.error.not_found.doctor","translations":{"user":{"fr":"Aucun m\u00e9decin trouv\u00e9","en":"No doctors found"}}};
export const USER_ERROR_NOT_FOUND_TEMPLATE = {"id":"user.error.not_found.template","translations":{"user":{"fr":"Aucun mod\u00e8le trouv\u00e9","en":"No template found"}}};
export const USER_ERROR_NOT_FOUND_USER = {"id":"user.error.not_found.user","translations":{"user":{"fr":"Aucun utilisateur trouv\u00e9","en":"No user found"}}};
export const USER_ERROR_NOT_FOUND_DRUGS = {"id":"user.error.not_found.drugs","translations":{"user":{"fr":"Aucun m\u00e9dicament trouv\u00e9","en":"No medication found"}}};
export const USER_ERROR_NOT_FOUND_LETTERHEAD = {"id":"user.error.not_found.letterhead","translations":{"user":{"fr":"Aucun papier \u00e0 en-t\u00eate trouv\u00e9","en":"No letterhead found"}}};
export const USER_ERROR_NOT_FOUND_QUESTIONNAIRE = {"id":"user.error.not_found.questionnaire","translations":{"user":{"fr":"Aucun questionnaire trouv\u00e9","en":"No questionnaire found"}}};
export const USER_ERROR_NOT_FOUND_SPECIALITY = {"id":"user.error.not_found.speciality","translations":{"user":{"fr":"Aucune sp\u00e9cialit\u00e9 trouv\u00e9e","en":"No specialty found"}}};
export const USER_ERROR_NOT_FOUND_INSTITUTION = {"id":"user.error.not_found.institution","translations":{"user":{"fr":"Aucune institution trouv\u00e9e","en":"No facilities found"}}};
export const USER_ERROR_NOT_FOUND_VARIABLE = {"id":"user.error.not_found.variable","translations":{"user":{"fr":"Aucun type de variable trouv\u00e9","en":"No variable type found"}}};
export const USER_ERROR_NOT_FOUND_DOC_TEMPLATE = {"id":"user.error.not_found.doc_template","translations":{"user":{"fr":"Aucun mod\u00e8le de document trouv\u00e9","en":"No document template found"}}};
export const USER_ERROR_NOT_FOUND_ALLERGEN = {"id":"user.error.not_found.allergen","translations":{"user":{"fr":"Aucun allerg\u00e8ne trouv\u00e9","en":"No allergens found"}}};
export const USER_ERROR_NOT_FOUND_DISEASE = {"id":"user.error.not_found.disease","translations":{"user":{"fr":"Aucune maladie trouv\u00e9e","en":"No disease found"}}};
export const USER_ERROR_NOT_FOUND_DOC_TYPE = {"id":"user.error.not_found.doc_type","translations":{"user":{"fr":"Aucun type de document trouv\u00e9","en":"No document type found"}}};
export const USER_HELPER_SHARE_MSSANTE = {"id":"user.helper.share_mssante","translations":{"user":{"fr":"Partager via MSSant\u00e9","en":"Share with MSSante"}}};
export const USER_HELPER_CHOSEN_PASSWORD = {"id":"user.helper.chosen_password","translations":{"user":{"fr":"Le mot de passe que vous avez d\u00e9fini","en":"The password you have set"}}};
export const USER_HELPER_CHANGE_PASSWORD = {"id":"user.helper.change_password","translations":{"user":{"fr":"Pour continuer et acc\u00e9der \u00e0 votre compte, Veuillez modifier votre mot de passe.","en":"To continue and access your account, please change your password."}}};
export const USER_HELPER_PASSWORD_CONTENT = {"id":"user.helper.password_content","translations":{"user":{"fr":"Votre mot de passe doit \u00eatre de minimum 8 caract\u00e8res, contenir au moins un chiffre, une minuscule et une majuscule","en":"Your password must be at least 8 characters long, contain at least one digit, one lowercase, and one uppercase letter"}}};
export const USER_HELPER_REINITIALIZE = {"id":"user.helper.reinitialize","translations":{"user":{"fr":"Pour r\u00e9initialiser votre mot de passe, suivez ce lien","en":"To reset your password, follow this link"}}};
export const USER_HELPER_PASSWORD_SECURITY = {"id":"user.helper.password_security","translations":{"user":{"fr":"Pour des raisons de s\u00e9curit\u00e9, lors de votre premi\u00e8re connexion, il vous sera demand\u00e9 de modifier votre mot de passe. Veuillez noter le nouveau mot de passe que vous avez saisi.","en":"For security reasons, upon your first login, you will be prompted to change your password. Please make sure to note down the new password you have entered."}}};
export const USER_HELPER_EMAIL_UNKNOWN = {"id":"user.helper.email_unknown","translations":{"user":{"fr":"Je ne connais pas l\u2019e-mail du m\u00e9decin","en":"I don't know the email address of the doctor"}}};
export const USER_HELPER_ALREADY_REGISTERED = {"id":"user.helper.already_registered","translations":{"user":{"fr":"Ce m\u00e9decin est d\u00e9j\u00e0 pr\u00e9sent sur Instamed. Vous n'avez donc pas besoin de saisir son adresse e-mail.","en":"This doctor is already on Instamed, you therefore do not need to enter their email address."}}};
export const USER_HELPER_ALREADY_LINKED = {"id":"user.helper.already_linked","translations":{"user":{"fr":"Vous avez d\u00e9j\u00e0 li\u00e9 votre compte Stellair \u00e0 votre compte Instamed","en":"You have already linked your Stellair account to your Instamed account"}}};
export const USER_HELPER_ASK_RECONNECT = {"id":"user.helper.ask_reconnect","translations":{"user":{"fr":"Veuillez demander \u00e0 $name de se reconnecter sur Stellair","en":"Please ask $name to reconnect to Stellair"}}};
export const USER_HELPER_LINK_LOADING = {"id":"user.helper.link_loading","translations":{"user":{"fr":"Chargement du lien de reconnexion","en":"Loading reconnection link"}}};
export const USER_HELPER_CLICK_TO_RECONNECT = {"id":"user.helper.click_to_reconnect","translations":{"user":{"fr":"Cliquez sur le bouton ci-dessous pour vous reconnecter sur Stellair.","en":"Click the button below to reconnect to Stellair."}}};
export const USER_HELPER_CHECK_EMAIL = {"id":"user.helper.check_email","translations":{"user":{"fr":"Ce m\u00e9decin a d\u00e9j\u00e0 \u00e9t\u00e9 invit\u00e9 sur Instamed mais ne s\u2019est jamais connect\u00e9. Veuillez v\u00e9rifier son adresse e-mail. Vous pouvez la modifier si elle est incorrecte.","en":"This doctor has already been invited to Instamed but has never logged in. Please verify their email address and update it if it is incorrect."}}};
export const USER_HELPER_NO_EMAIL = {"id":"user.helper.no_email","translations":{"user":{"fr":"Ce m\u00e9decin a d\u00e9j\u00e0 \u00e9t\u00e9 invit\u00e9 sur Instamed mais son adresse e-mail n'a pas \u00e9t\u00e9 renseign\u00e9e. Veuillez l'ajouter afin qu'il puisse avoir acc\u00e8s au dossier","en":"This doctor has already been invited to Instamed, but their email address has not been provided. Please add it to grant them access to the record."}}};
export const USER_HELPER_RECORD_QUOTA = {"id":"user.helper.record_quota","translations":{"user":{"fr":"Ce m\u00e9decin a d\u00e9pass\u00e9 son quota de dossiers re\u00e7us gratuitement. Il devra souscrire \u00e0 l\u2019abonnement Instamed PARTENAIRE pour pouvoir consulter ce nouveau dossier.","en":"This doctor has exceeded their quota of consultable files as a guest. To consult any new files, he must subscribe to the Partner subscription."}}};
export const USER_HELPER_SYNCHRONIZE_CALENDAR = {"id":"user.helper.synchronize_calendar","translations":{"user":{"fr":"Pour synchroniser votre calendrier Instamed sur un autre agenda (Calendrier Apple, Google Calendar, Outlook, etc.), il suffit de copier le lien ci-dessous et de le coller sur votre agenda (via la fonctionnalit\u00e9 \u201cs\u2019abonner \u00e0 un autre agenda\u201d de Google ou Apple par exemple) :","en":"To sync your Instamed calendar with another calendar (Apple Calendar, Google Calendar, Outlook, etc.), simply copy the link below and paste it into your calendar (using the \"subscribe to another calendar\" feature in Google or Apple) :"}}};
export const USER_HELPER_TUTORIAL = {"id":"user.helper.tutorial","translations":{"user":{"fr":"Pour plus d\u2019aide, vous pouvez vous r\u00e9f\u00e9rer \u00e0 notre tutoriel.","en":"For further assistance, you can refer to our tutorial."}}};
export const USER_HELPER_BROWSER_OPTIMISATION = {"id":"user.helper.browser_optimisation","translations":{"user":{"fr":"Instamed est optimis\u00e9 pour les navigateurs Mozilla Firefox et Google Chrome, et ne prends pas correctement en charge","en":"Instamed is optimized for Mozilla Firefox and Google Chrome browsers, and does not support properly"}}};
export const USER_HELPER_BROWSER_OPTIMAL = {"id":"user.helper.browser_optimal","translations":{"user":{"fr":"Pour une utilisation optimale de la plateforme veuillez utiliser l'un de ces deux navigateurs :","en":"For an optimal use of our services please use one of these two browsers :"}}};
export const USER_HELPER_COMPLETE_PROFILE = {"id":"user.helper.complete_profile","translations":{"user":{"fr":"Avant de pouvoir acc\u00e9der au site, il vous faut compl\u00e9ter votre profil.","en":"Before you can access the site, you must complete your profile."}}};
export const USER_HELPER_PROFILE_SAVED = {"id":"user.helper.profile_saved","translations":{"user":{"fr":"Le profil de $profile a bien \u00e9t\u00e9 enregistr\u00e9","en":"The profile of $profile has been successfully saved"}}};
export const USER_HELPER_ADDRESS_NOT_FOUND = {"id":"user.helper.address_not_found","translations":{"user":{"fr":"Je ne trouve pas l'adresse dans la liste","en":"I cannot find the address in the list"}}};
export const USER_HELPER_MISSING_ADDRESS = {"id":"user.helper.missing_address","translations":{"user":{"fr":"Adresse non renseign\u00e9e","en":"Address not provided"}}};
export const USER_HELPER_REQUEST_TAKEN = {"id":"user.helper.request_taken","translations":{"user":{"fr":"Votre demande a bien \u00e9t\u00e9 prise en compte. Une notification vous sera envoy\u00e9e une fois trait\u00e9e.","en":"Your request has been successfully processed. A notification will be sent to you once processed."}}};
export const USER_HELPER_MERGED = {"id":"user.helper.merged","translations":{"user":{"fr":"Les deux utilisateurs ont bien \u00e9t\u00e9 fusionn\u00e9s.","en":"The two records have been successfully merged."}}};
export const USER_HELPER_MERGED_KEPT = {"id":"user.helper.merged_kept","translations":{"user":{"fr":"Les profils ont bien \u00e9t\u00e9 fusionn\u00e9s. Le compte n\u00b0 $id a \u00e9t\u00e9 conserv\u00e9.","en":"Records successfully merged. Account #$id has been saved."}}};
export const USER_HELPER_CONFIRM_DELETE_PSC = {"id":"user.helper.confirm_delete_psc","translations":{"user":{"fr":"\u00cates-vous s\u00fbr de vouloir supprimer la connexion avec votre compte Pro Sant\u00e9 Connect ?","en":"Are you sure you want to delete the connection with your Pro Sante Connect account?"}}};
export const USER_HELPER_DISCONNECTED_PSC = {"id":"user.helper.disconnected_psc","translations":{"user":{"fr":"Votre compte Pro Sant\u00e9 Connect a \u00e9t\u00e9 d\u00e9connect\u00e9 avec succ\u00e8s.","en":"Your Pro Sante Connect account has been successfully disconnected."}}};
export const USER_HELPER_FAV_SAVED = {"id":"user.helper.fav_saved","translations":{"user":{"fr":"Vos pr\u00e9f\u00e9rences ont bien \u00e9t\u00e9 modifi\u00e9s","en":"Your preferences have been updated"}}};
export const USER_HELPER_INTERFACE_ACTIVATED = {"id":"user.helper.interface_activated","translations":{"user":{"fr":"Nouvelle interface activ\u00e9e","en":"New interface activated"}}};
export const USER_HELPER_PROFILE_UPDATED = {"id":"user.helper.profile_updated","translations":{"user":{"fr":"Votre profil a bien \u00e9t\u00e9 modifi\u00e9","en":"Your profile has been successfully updated"}}};
export const USER_HELPER_ATTACHMENT = {"id":"user.helper.attachment","translations":{"user":{"fr":"Les fichiers seront ajout\u00e9s en pi\u00e8ces jointes","en":"Files will be added in attachments"}}};
export const USER_HELPER_DISCUSSION_LEAVE = {"id":"user.helper.discussion_leave","translations":{"user":{"fr":"\u00cates-vous s\u00fbr(e) de vouloir quitter cette discussion ?","en":"Are you sure you want to leave this conversation?"}}};
export const USER_HELPER_DISCUSSION_DELETE = {"id":"user.helper.discussion_delete","translations":{"user":{"fr":"\u00cates-vous s\u00fbr(e) de vouloir supprimer cette discussion ?","en":"Are you sure you want to delete this conversation?"}}};
export const USER_HELPER_DISCUSSION_LEAVED = {"id":"user.helper.discussion_leaved","translations":{"user":{"fr":"Vous avez bien quitt\u00e9 la discussion","en":"You left the conversation"}}};
export const USER_HELPER_DISCUSSION_DELETED = {"id":"user.helper.discussion_deleted","translations":{"user":{"fr":"Vous avez bien supprim\u00e9 la discussion","en":"Conversation deleted"}}};
export const USER_HELPER_MARK_AS_READ = {"id":"user.helper.mark_as_read","translations":{"user":{"fr":"La discussion est marqu\u00e9e comme non lue.","en":"Conversation is marked as unread."}}};
export const USER_HELPER_DELETE_SELECTION = {"id":"user.helper.delete_selection","translations":{"user":{"fr":"\u00cates-vous s\u00fbr de vouloir supprimer la s\u00e9lection ? L'action est irr\u00e9versible","en":"Are you sure you want to delete the selection? This action cannot be undone."}}};
export const USER_HELPER_SELECTION_DELETED = {"id":"user.helper.selection_deleted","translations":{"user":{"fr":"La s\u00e9lection a bien \u00e9t\u00e9 supprim\u00e9e","en":"The selection has been deleted"}}};
export const USER_HELPER_ADD_REQUEST = {"id":"user.helper.add_request","translations":{"user":{"fr":"Une demande d'ajout a \u00e9t\u00e9 envoy\u00e9 \u00e0 l'utilisateur.","en":"A request to add user has been sent."}}};
export const USER_HELPER_INVITED = {"id":"user.helper.invited","translations":{"user":{"fr":"L'utilisateur a bien \u00e9t\u00e9 invit\u00e9.","en":"The user was successfully invited."}}};
export const USER_HELPER_INVITATION_CANCELED = {"id":"user.helper.invitation_canceled","translations":{"user":{"fr":"L'invitation a bien \u00e9t\u00e9 annul\u00e9e","en":"Invitation is successfully cancelled"}}};
export const USER_HELPER_INVITATION_ACCEPTED = {"id":"user.helper.invitation_accepted","translations":{"user":{"fr":"L'invitation a bien \u00e9t\u00e9 accept\u00e9e","en":"Invitation accepted"}}};
export const USER_HELPER_WARNING_REMOVE = {"id":"user.helper.warning_remove","translations":{"user":{"fr":"Vous \u00eates sur le point de retirer cet utilisateur de votre \u00e9quipe soignante. Il n\u2019aura plus acc\u00e8s \u00e0 vos patients. Vous pourrez toujours le rajouter ult\u00e9rieurement.","en":"You are about to remove this user from your care team. It will no longer have access to your patients. You can always add it again later."}}};
export const USER_HELPER_REMOVED = {"id":"user.helper.removed","translations":{"user":{"fr":"L'utilisateur a bien \u00e9t\u00e9 retir\u00e9 de l'\u00e9quipe soignante","en":"User has been successfully removed from the care team"}}};
export const USER_HELPER_CONTACT_EDITED = {"id":"user.helper.contact_edited","translations":{"user":{"fr":"Le contact a bien \u00e9t\u00e9 modifi\u00e9","en":"The contact was successfully updated"}}};
export const USER_HELPER_CONFIRM_CONTACT_DELETE = {"id":"user.helper.confirm_contact_delete","translations":{"user":{"fr":"\u00cates-vous s\u00fbr de vouloir supprimer ce contact en cas d'urgence ?","en":"Are you sure you want to delete this emergency contact?"}}};
export const USER_HELPER_CONTACT_DELETED = {"id":"user.helper.contact_deleted","translations":{"user":{"fr":"Le contact a bien \u00e9t\u00e9 supprim\u00e9","en":"The contact has been deleted"}}};
export const USER_BANK_DATA_CARD_NUMBER = {"id":"user.bank_data.card_number","translations":{"user":{"fr":"Num\u00e9ro de carte","en":"Card number"}}};
export const USER_BANK_DATA_EXPIRATION_DATE = {"id":"user.bank_data.expiration_date","translations":{"user":{"fr":"Date d'expiration","en":"Expiration date"}}};
export const USER_BANK_DATA_MANAGE_TITLE = {"id":"user.bank_data.manage.title","translations":{"user":{"fr":"G\u00e9rer vos informations bancaires","en":"Manage your bank details"}}};
export const USER_BANK_DATA_MANAGE_ADD_IBAN = {"id":"user.bank_data.manage.add_iban","translations":{"user":{"fr":"Ajoutez votre IBAN \u00e0 votre compte","en":"Add your IBAN to your account"}}};
export const USER_BANK_DATA_MANAGE_VERIFY = {"id":"user.bank_data.manage.verify","translations":{"user":{"fr":"V\u00e9rifiez vos informations avant d'enregistrer","en":"Please check your information before saving"}}};
export const USER_BANK_DATA_MANAGE_DEFAULT = {"id":"user.bank_data.manage.default","translations":{"user":{"fr":"Carte par d\u00e9faut","en":"Default card"}}};
export const USER_BANK_DATA_MANAGE_DEFINE_DEFAULT = {"id":"user.bank_data.manage.define_default","translations":{"user":{"fr":"D\u00e9finir par d\u00e9faut","en":"Set as default"}}};
export const USER_BANK_DATA_ADD_CARD_ACTION = {"id":"user.bank_data.add_card.action","translations":{"user":{"fr":"Ajouter une nouvelle carte","en":"New card"}}};
export const USER_BANK_DATA_ADD_CARD_TITLE = {"id":"user.bank_data.add_card.title","translations":{"user":{"fr":"Ajoutez une nouvelle carte de cr\u00e9dit sur votre profil","en":"New credit card to your profile"}}};
export const USER_BANK_DATA_ADD_CARD_SAVE = {"id":"user.bank_data.add_card.save","translations":{"user":{"fr":"Enregistrer la carte","en":"Save card"}}};
export const USER_BANK_DATA_HELPERS_CAN_MODIFY = {"id":"user.bank_data.helpers.can_modify","translations":{"user":{"fr":"Si certaines informations sont incorrectes ou incompl\u00e8tes, vous pouvez les modifier dans ","en":"If information are incorrect or incomplete, you can update it in "}}};
export const USER_BANK_DATA_HELPERS_FIND = {"id":"user.bank_data.helpers.find","translations":{"user":{"fr":"Vous pouvez retrouver ci-dessous toutes vos informations bancaires","en":"Below you can find all your bank details"}}};
export const USER_PHONE_ACTIONS_VERIFY = {"id":"user.phone.actions.verify","translations":{"user":{"fr":"V\u00e9rifiez votre num\u00e9ro de t\u00e9l\u00e9phone","en":"Verify your phone number"}}};
export const USER_PHONE_ACTIONS_SEND_SMS = {"id":"user.phone.actions.send_sms","translations":{"user":{"fr":"Entrez le code re\u00e7u par SMS","en":"Enter code received by SMS"}}};
export const USER_PHONE_ACTIONS_ENTER_CODE = {"id":"user.phone.actions.enter_code","translations":{"user":{"fr":"Veuillez entrer le code re\u00e7u par SMS.","en":"Enter code received by SMS."}}};
export const USER_PHONE_ACTIONS_VALIDATE = {"id":"user.phone.actions.validate","translations":{"user":{"fr":"Valider le num\u00e9ro","en":"Confirm number"}}};
export const USER_PHONE_HELPER = {"id":"user.phone.helper","translations":{"user":{"fr":"Afin de s\u00e9curiser votre compte et vos donn\u00e9es, nous vous demandons de valider votre num\u00e9ro de t\u00e9l\u00e9phone.","en":"To secure your account, we request you to verify your phone number."}}};
export const USER_PHONE_PLACEHOLDER = {"id":"user.phone.placeholder","translations":{"user":{"fr":"Votre code","en":"Code"}}};
export const USER_PHONE_ALREADY_VALIDATED = {"id":"user.phone.already_validated","translations":{"user":{"fr":"Votre num\u00e9ro de t\u00e9l\u00e9phone est d\u00e9j\u00e0 valid\u00e9","en":"Your phone number has already been validated"}}};
export const USER_PHONE_VALIDATED = {"id":"user.phone.validated","translations":{"user":{"fr":"Votre num\u00e9ro de t\u00e9l\u00e9phone a bien \u00e9t\u00e9 valid\u00e9.","en":"Your phone number has been validated."}}};
export const USER_CONNEXION_TITLE = {"id":"user.connexion.title","translations":{"user":{"fr":"Fiche de connexion","en":"Log in card"}}};
export const USER_CONNEXION_HELPERS_CONNECT_ON = {"id":"user.connexion.helpers.connect_on","translations":{"user":{"fr":"Connectez-vous sur ","en":"Log in to "}}};
export const USER_CONNEXION_HELPERS_THEN_CLICK = {"id":"user.connexion.helpers.then_click","translations":{"user":{"fr":"puis cliquez sur l'onglet <strong>SE CONNECTER<\/strong>","en":"then click on the <strong>Log in<\/strong> tab."}}};
export const USER_CONNEXION_HELPERS_ALTERNATIVE = {"id":"user.connexion.helpers.alternative","translations":{"user":{"fr":"Vous pouvez aussi suivre ce lien : ","en":"You can also click on this link : "}}};
export const USER_CONNEXION_HELPERS_BROWSERS = {"id":"user.connexion.helpers.browsers","translations":{"user":{"fr":"(id\u00e9alement avec Chrome ou Firefox)","en":"(ideally with Chrome or Firefox)"}}};
export const USER_CONNEXION_RESTORE_PASSWORD = {"id":"user.connexion.restore_password","translations":{"user":{"fr":"R\u00e9initialiser le mot de passe par d\u00e9faut","en":"Reset password"}}};
export const USER_CONNEXION_DOWNLOAD = {"id":"user.connexion.download","translations":{"user":{"fr":"T\u00e9l\u00e9charger la fiche de connexion","en":"Download the log in card"}}};
export const USER_EXPIRED_SESSION_TITLE = {"id":"user.expired_session.title","translations":{"user":{"fr":"Votre session a expir\u00e9","en":"Your session has expired"}}};
export const USER_EXPIRED_SESSION_FILL_PASSWORD = {"id":"user.expired_session.fill_password","translations":{"user":{"fr":"Votre session a expir\u00e9, veuillez renseigner votre mot de passe afin de vous reconnecter.","en":"Your session has expired, please enter your password to log in again."}}};
export const USER_EXPIRED_SESSION_RECONNECT = {"id":"user.expired_session.reconnect","translations":{"user":{"fr":"Vous avez \u00e9t\u00e9 d\u00e9connect\u00e9, car une autre session a \u00e9t\u00e9 ouverte avec vos identifiants. Merci de vous reconnecter.","en":"You have been logged out because another session was opened with your credentials. Please log in again."}}};
export const USER_SUBSCRIPTION_ACTION = {"id":"user.subscription.action","translations":{"user":{"fr":"S\u2019abonner","en":"Subscribe"}}};
export const USER_SUBSCRIPTION_PREMIUM = {"id":"user.subscription.premium","translations":{"user":{"fr":"Instamed Premium","en":"Instamed Premium"}}};
export const USER_SUBSCRIPTION_SELECT_SUBSCRIPTION = {"id":"user.subscription.select_subscription","translations":{"user":{"fr":"Pour acc\u00e9der \u00e0 toutes les fonctionnalit\u00e9s d\u2019Instamed comme la messagerie s\u00e9curis\u00e9e, la cr\u00e9ation de comptes-rendus ou d\u2019ordonnances, veuillez choisir un abonnement.","en":"To access all Instamed features such as secure messenger, creating reports or prescriptions, please choose a subscription."}}};
export const USER_SUBSCRIPTION_ACTIVE_TRANSMISSION = {"id":"user.subscription.active_transmission","translations":{"user":{"fr":"Activer la t\u00e9l\u00e9transmission","en":"Enable teletransmission"}}};
export const USER_SUBSCRIPTION_TRANSMISSION = {"id":"user.subscription.transmission","translations":{"user":{"fr":"Abonnez-vous \u00e0 la t\u00e9l\u00e9transmission pour pouvoir facturer depuis Instamed !","en":"Subscribe to teletransmission to be able to bill directly from Instamed."}}};
export const USER_SUBSCRIPTION_SELECT_OFFER = {"id":"user.subscription.select_offer","translations":{"user":{"fr":"Choisissez votre offre","en":"Choose your plan"}}};
export const USER_SUBSCRIPTION_SELECT_THIS_OFFER = {"id":"user.subscription.select_this_offer","translations":{"user":{"fr":"Choisir cette offre","en":"Choose this plan"}}};
export const USER_SUBSCRIPTION_MONTH = {"id":"user.subscription.month","translations":{"user":{"fr":"Mensuel","en":"Monthly"}}};
export const USER_SUBSCRIPTION_ANNUEL = {"id":"user.subscription.annuel","translations":{"user":{"fr":"Annuel","en":"Annual"}}};
export const USER_SUBSCRIPTION_EDIT = {"id":"user.subscription.edit","translations":{"user":{"fr":"Modification de votre abonnement","en":"Change your subscription"}}};
export const USER_SUBSCRIPTION_CONFIRM_EDIT = {"id":"user.subscription.confirm_edit","translations":{"user":{"fr":"Confirmer la modification","en":"Confirm change"}}};
export const USER_SUBSCRIPTION_EDITED = {"id":"user.subscription.edited","translations":{"user":{"fr":"Votre abonnment a bien \u00e9t\u00e9 modifi\u00e9","en":"Your subscription has been successfully modified"}}};
export const USER_SUBSCRIPTION_CONFIRM = {"id":"user.subscription.confirm","translations":{"user":{"fr":"Vous confirmez la modification de votre abonnement de l'offre <strong class=\"old-plan\"><\/strong> vers l'offre <strong class=\"new-plan\"><\/strong> pour un montant de <strong><span class=\"price\"><\/span> par <span class=\"interval\"><\/span><\/strong> (TTC)","en":"You confirm the modification of your subscription from the <strong class=\"old-plan\"><\/strong> plan to the <strong class=\"new-plan\"><\/strong> plan for an amount of <strong><span class=\"price\"><\/span> per <span class=\"interval\"><\/span><\/strong> (including tax)."}}};
export const USER_SUBSCRIPTION_EFFECTIVE_UPGRADE = {"id":"user.subscription.effective_upgrade","translations":{"user":{"fr":"Cette derni\u00e8re sera effective imm\u00e9diatement","en":"The latter will be effective immediately"}}};
export const USER_SUBSCRIPTION_EFFECTIVE_DOWNGRADE = {"id":"user.subscription.effective_downgrade","translations":{"user":{"fr":"Cette derni\u00e8re sera effective \u00e0 la date de renouvellement de votre abonnement (le <strong class=\"date\"><\/strong>)","en":"This will take effect on the renewal date of your subscription (on <strong class=\"date\"><\/strong>)."}}};
export const USER_SUBSCRIPTION_NO_ENGAGEMENT = {"id":"user.subscription.no_engagement","translations":{"user":{"fr":"Offre sans engagement","en":"No-commitment plan"}}};
export const USER_SUBSCRIPTION_RECOMMENDED = {"id":"user.subscription.recommended","translations":{"user":{"fr":"Recommand\u00e9 pour vous","en":"Recommended for you"}}};
export const USER_SUBSCRIPTION_PARTNER = {"id":"user.subscription.partner","translations":{"user":{"fr":"Abonnement \"Partenaire\"","en":"\"Partner\" subscription"}}};
export const USER_SUBSCRIPTION_INSUFFICIENT_CREDIT = {"id":"user.subscription.insufficient_credit","translations":{"user":{"fr":"Cr\u00e9dits insuffisants","en":"Insufficient credits"}}};
export const USER_SUBSCRIPTION_HELPER_CREDIT = {"id":"user.subscription.helper_credit","translations":{"user":{"fr":"Vous n'avez pas assez de cr\u00e9dits pour effectuer cette action. Merci de recharger votre compte.","en":"You don't have enough credits to perform this action. Please top up your account."}}};
export const USER_SUBSCRIPTION_BUY_CREDITS = {"id":"user.subscription.buy_credits","translations":{"user":{"fr":"Acheter des cr\u00e9dits","en":"Buy credits"}}};
export const USER_SUBSCRIPTION_CREDITS_ADDED = {"id":"user.subscription.credits_added","translations":{"user":{"fr":"Cr\u00e9dit ajout\u00e9 avec succ\u00e8s","en":"Credit added successfully"}}};
export const USER_SUBSCRIPTION_FREE = {"id":"user.subscription.free","translations":{"user":{"fr":"Gratuit","en":"Free"}}};
export const USER_SUBSCRIPTION_RECURRING = {"id":"user.subscription.recurring","translations":{"user":{"fr":"mois TTC","en":"months incl. tax"}}};
export const USER_SUBSCRIPTION_ANNUALLY = {"id":"user.subscription.annually","translations":{"user":{"fr":"Factur\u00e9 annuellement","en":"Billed annually"}}};
export const USER_SUBSCRIPTION_ACTUAL_PLAN = {"id":"user.subscription.actual_plan","translations":{"user":{"fr":"Votre plan actuel","en":"Current plan"}}};
export const USER_VISIO_TITLE = {"id":"user.visio.title","translations":{"user":{"fr":"Video-conf\u00e9rence","en":"Video-conference"}}};
export const USER_VISIO_CONSULT = {"id":"user.visio.consult","translations":{"user":{"fr":"T\u00e9l\u00e9consultation","en":"Teleconsultation"}}};
export const USER_VISIO_NEW = {"id":"user.visio.new","translations":{"user":{"fr":"Nouvelle visioconf\u00e9rence","en":"New teleconsultation"}}};
export const USER_VISIO_PRICE = {"id":"user.visio.price","translations":{"user":{"fr":"Cet appel lui sera factur\u00e9 le prix suivant :","en":"This call will be invoiced at the following price :"}}};
export const USER_VISIO_HELPER = {"id":"user.visio.helper","translations":{"user":{"fr":"Si vous laissez le prix \u00e0 0, l'appel ne sera pas factur\u00e9 au patient","en":"If you set the price at 0, the teleconsultation will not be invoiced to the patient"}}};
export const USER_VISIO_START_CALL = {"id":"user.visio.start_call","translations":{"user":{"fr":"Lancer l'appel","en":"Start call"}}};
export const USER_VISIO_ACTIVATE = {"id":"user.visio.activate","translations":{"user":{"fr":"Veuillez activer l'acc\u00e8s \u00e0 votre webcam et \u00e0 votre micro","en":"Please enable access to your camera and microphone"}}};
export const USER_VISIO_PERMISSION_DENIED = {"id":"user.visio.permission_denied","translations":{"user":{"fr":"Vous devez accepter l'acc\u00e8s au micro et \u00e0 la webcam pour commencer la conf\u00e9rence.","en":"You must accept microphone and webcam access to start the conference."}}};
export const USER_VISIO_ONGOING = {"id":"user.visio.ongoing","translations":{"user":{"fr":"Vous avez un appel en cours, rejoignez-le !","en":"You have a call in progress, join it"}}};
export const USER_VISIO_JOIN = {"id":"user.visio.join","translations":{"user":{"fr":"Rejoindre l'appel","en":"Join call"}}};
export const USER_VISIO_CALL_ENDED = {"id":"user.visio.call_ended","translations":{"user":{"fr":"L'appel est termin\u00e9","en":"Call ended"}}};
export const USER_VISIO_CALL_ENDED_ID = {"id":"user.visio.call_ended_id","translations":{"user":{"fr":"Appel $id termin\u00e9","en":"Call $id ended"}}};
export const USER_VISIO_HOW_CALL_WENT = {"id":"user.visio.how_call_went","translations":{"user":{"fr":"Cet appel s'est-il d\u00e9roul\u00e9 correctement ?","en":"Did this call go as expected?"}}};
export const USER_VISIO_YES = {"id":"user.visio.yes","translations":{"user":{"fr":"Si <span class=\"green\">Oui<\/span>, la consultation sera d\u00e9bit\u00e9e.","en":"If <span class=\"green\">Yes<\/span>, consultation will be debited."}}};
export const USER_VISIO_NO = {"id":"user.visio.no","translations":{"user":{"fr":" Si <span class=\"red\">Non<\/span>, la consultation ne sera pas d\u00e9bit\u00e9e","en":" If <span class=\"red\">No<\/span>, consultation will not be debited"}}};
export const USER_VISIO_REJECTED = {"id":"user.visio.rejected","translations":{"user":{"fr":"L'appel a \u00e9t\u00e9 rejet\u00e9 par le patient","en":"The call was rejected by the patient"}}};
export const USER_VISIO_CLICK_TO_END = {"id":"user.visio.click_to_end","translations":{"user":{"fr":"Cliquez sur le bouton ci-dessous pour terminer cet appel.","en":"Click the button below to end this call."}}};
export const USER_VISIO_END_CALL = {"id":"user.visio.end_call","translations":{"user":{"fr":"Terminer l'appel","en":"End call"}}};
export const USER_VISIO_NO_PARTICIPANT = {"id":"user.visio.no_participant","translations":{"user":{"fr":"Vous ne pouvez pas effectuer un appel tout seul.","en":"You cannot make a call by yourself."}}};
export const USER_VISIO_NOT_FOUND = {"id":"user.visio.not_found","translations":{"user":{"fr":"Cet appel n'existe plus ou votre correspondant a lanc\u00e9 un nouvel appel.","en":"This call no longer exists or your correspondent is in a new call."}}};
export const USER_VISIO_ACCESS_DENIED = {"id":"user.visio.access_denied","translations":{"user":{"fr":"Vous n'\u00eates pas autoris\u00e9 \u00e0 r\u00e9pondre \u00e0 cet appel","en":"You are not allowed to answer this call"}}};
export const USER_VISIO_USER_CALLS = {"id":"user.visio.user_calls","translations":{"user":{"fr":"$user vous appelle. D\u00e9crocher ?","en":"$user is calling you, answer?"}}};
export const USER_VISIO_BROWSER_INCOMPATIBLE = {"id":"user.visio.browser_incompatible","translations":{"user":{"fr":"Votre navigateur n'est pas compatible avec la visioconf\u00e9rence","en":"Your browser is not compatible with our teleconsultation module"}}};
export const USER_VISIO_ERROR_MIC = {"id":"user.visio.error_mic","translations":{"user":{"fr":"Une erreur est survenue lors de la gestion de votre micro, veuillez r\u00e9essayer plus tard.","en":"An error occurred while generating the token, please try again later."}}};
export const USER_VISIO_TEST_ACTION = {"id":"user.visio.test.action","translations":{"user":{"fr":"Tester","en":"Test"}}};
export const USER_VISIO_TEST_SYSTEM = {"id":"user.visio.test.system","translations":{"user":{"fr":"Test syst\u00e8me","en":"System test"}}};
export const USER_VISIO_TEST_CALL = {"id":"user.visio.test.call","translations":{"user":{"fr":"Testez votre \u00e9quipement avant de lancer la visio-conf\u00e9rence","en":"Test your camera, microphone and sound before launching the videoconference"}}};
export const USER_VISIO_TEST_MIC = {"id":"user.visio.test.mic","translations":{"user":{"fr":"Test microphone","en":"Microphone test"}}};
export const USER_VISIO_TEST_AUDIO = {"id":"user.visio.test.audio","translations":{"user":{"fr":"Test sortie audio","en":"Test audio output"}}};
export const USER_VISIO_TEST_CAM = {"id":"user.visio.test.cam","translations":{"user":{"fr":"Test cam\u00e9ra","en":"Camera test"}}};
export const USER_VISIO_TEST_CONFIRM = {"id":"user.visio.test.confirm","translations":{"user":{"fr":"Confirmer le fonctionnement","en":"Confirm functionning"}}};
export const USER_VISIO_TEST_VALIDATE = {"id":"user.visio.test.validate","translations":{"user":{"fr":"J\u2019ai test\u00e9 et je valide","en":"I tested and validate"}}};
export const USER_VISIO_TEST_VALIDATED = {"id":"user.visio.test.validated","translations":{"user":{"fr":"Votre test de la visioconference a \u00e9t\u00e9 valid\u00e9.","en":"Your videoconference set up is successful."}}};
export const USER_VISIO_TEST_HELPER_MIC = {"id":"user.visio.test.helper_mic","translations":{"user":{"fr":"Cliquez sur \"Tester\" et observez les mouvements de la barre verte lorsque vous produisez un son pr\u00e8s de votre ordinateur.","en":"Click on \"Test\" and watch the green bar movements when you make a sound from your computer."}}};
export const USER_VISIO_TEST_HELPER_AUDIO = {"id":"user.visio.test.helper_audio","translations":{"user":{"fr":"Cliquez sur \"Tester\" et \u00e9coutez le son jou\u00e9, si vous n'entendez rien, augmentez le volume sonore de votre ordinateur et rejouez le son.","en":"Click on \"Test\" and listen to the sound, if you don't hear anything, increase the sound volume of your computer and play the sound again."}}};
export const USER_VISIO_TEST_HELPER_CAM = {"id":"user.visio.test.helper_cam","translations":{"user":{"fr":"Cliquez sur \"Tester\" et visualisez votre image captur\u00e9e par votre webcam.","en":"Click on \"Test\" and view your image captured by your webcam."}}};
export const USER_VISIO_TEST_HELPER_CONFIRM = {"id":"user.visio.test.helper_confirm","translations":{"user":{"fr":"Avant votre premi\u00e8re t\u00e9l\u00e9consultation, nous vous invitons \u00e0 effectuer les trois tests de cette page. Puis \u00e0 confirmer le bon d\u00e9roulement de chacun.","en":"Before your first teleconsultation, we invite you to perform the three tests on this page and ensure work properly."}}};
export const USER_PASSWORD_YOUR_PASSWORD = {"id":"user.password.your_password","translations":{"user":{"fr":"Votre mot de passe","en":"Password"}}};
export const USER_PASSWORD_ACTUAL = {"id":"user.password.actual","translations":{"user":{"fr":"Mot de passe actuel","en":"Current password"}}};
export const USER_PASSWORD_NEW = {"id":"user.password.new","translations":{"user":{"fr":"Nouveau mot de passe","en":"New password"}}};
export const USER_PASSWORD_REPEAT = {"id":"user.password.repeat","translations":{"user":{"fr":"R\u00e9p\u00e9tez votre mot de passe","en":"Repeat password"}}};
export const USER_PASSWORD_ACTIVATE_TWO_FACTORS = {"id":"user.password.activate_two_factors","translations":{"user":{"fr":"Activer l\u2019authentification \u00e0 deux facteurs","en":"Enable two factor authentication"}}};
export const USER_PASSWORD_SELECTED = {"id":"user.password.selected","translations":{"user":{"fr":"S\u00e9lectionn\u00e9e","en":"Selected"}}};
export const USER_PASSWORD_TOGGLE_TITLE = {"id":"user.password.toggle_title","translations":{"user":{"fr":"Application d'authentification (\u00e0 t\u00e9l\u00e9charger)","en":"Authentication app (to download)"}}};
export const USER_PASSWORD_PRO_SANTE = {"id":"user.password.pro_sante","translations":{"user":{"fr":"Pro Sant\u00e9 Connect","en":"Pro Sante Connect"}}};
export const USER_PASSWORD_CONNECT_PRO_SANTE = {"id":"user.password.connect_pro_sante","translations":{"user":{"fr":"S'identifier avec Pro Sant\u00e9 Connect","en":"Log in with Pro Sante Connect"}}};
export const USER_PASSWORD_ALREADY_CONNECTED = {"id":"user.password.already_connected","translations":{"user":{"fr":"Votre compte Instamed est d\u00e9j\u00e0 li\u00e9 \u00e0 votre compte Pro Sant\u00e9 Connect. Pour retirer cette liaison, cliquez ici.","en":"Your Instamed account is already linked to your Pro Sante Connect account. To unlink accounts, click here."}}};
export const USER_PASSWORD_DELETE_CONNEXION = {"id":"user.password.delete_connexion","translations":{"user":{"fr":"Supprimer la connexion","en":"Remove connection"}}};
export const USER_PASSWORD_CONFIGURE = {"id":"user.password.configure","translations":{"user":{"fr":"Configurez votre application","en":"Set up your app"}}};
export const USER_PASSWORD_QR_CODE = {"id":"user.password.qr_code","translations":{"user":{"fr":"Scannez le QR Code suivant via l\u2019application d\u2019authentification \u00e0 deux facteurs de votre choix.","en":"Scan the following QR Code through the two-factor authentication app of your choice."}}};
export const USER_PASSWORD_PASSWORD_UPDATED = {"id":"user.password.password_updated","translations":{"user":{"fr":"Le mot de passe a bien \u00e9t\u00e9 modifi\u00e9","en":"Password updated"}}};
export const USER_PASSWORD_ACTIVATED = {"id":"user.password.activated","translations":{"user":{"fr":"Votre authentification a 2 facteurs a bien \u00e9t\u00e9 activ\u00e9e","en":"Two-factor authentication has been successfully activated"}}};
export const USER_PASSWORD_UPDATED = {"id":"user.password.updated","translations":{"user":{"fr":"Votre mot de passe a bien \u00e9t\u00e9 modifi\u00e9","en":"Your password has been updated successfully"}}};
export const USER_PASSWORD_CONFIRM = {"id":"user.password.confirm","translations":{"user":{"fr":"Confirmez votre mot de passe","en":"Confirm password"}}};
export const USER_PASSWORD_HELPER_TWO_FACTORS = {"id":"user.password.helper.two_factors","translations":{"user":{"fr":"L\u2019authentification \u00e0 deux facteurs vous permet de s\u00e9curiser votre compte.","en":"Two-factor authentication allows you to secure your account."}}};
export const USER_PASSWORD_HELPER_TO_DOWNLOAD = {"id":"user.password.helper.to_download","translations":{"user":{"fr":"Codes temporels et uniques \u00e0 6 chiffres g\u00e9n\u00e9r\u00e9s via une application d'authentification externe (comme","en":"Unique 6-digit codes generated in real-time by an external authentication application"}}};
export const USER_PASSWORD_HELPER_RECOVERY = {"id":"user.password.helper.recovery","translations":{"user":{"fr":"Ci-dessous se trouvent 4 codes de r\u00e9cup\u00e9ration. Copiez les et stockez les dans un lieu s\u00fbr. Si vous n'avez pas acc\u00e8s \u00e0 votre appareil pour l'authentification \u00e0 deux facteurs, vous pouvez reprendre contr\u00f4le de votre compte via ces codes.","en":"Below are 4 recovery codes. Copy them and store them in a safe place. If you no longer have access to your device for two-factor authentication, you can regain control of your account using these codes."}}};
export const USER_PASSWORD_HELPER_PRO_SANTE = {"id":"user.password.helper.pro_sante","translations":{"user":{"fr":"Pro Sant\u00e9 Connect vous permet de vous identifier avec votre carte CPS. La connexion vous permet aussi d'acc\u00e9der plus simplement aux plateformes de nos partenaires.","en":"Pro Sante Connect allows you to identify yourself with your CPS card. The connection also allows you to more easily access our partners' platforms."}}};
export const USER_PASSWORD_HELPER_QR_CODE = {"id":"user.password.helper.qr_code","translations":{"user":{"fr":"Une fois scann\u00e9, entrez le code \u00e0 6 chiffres g\u00e9n\u00e9r\u00e9 puis cliquez sur le bouton valider.","en":"Once scanned, enter the 6 digits code and click on confirm button."}}};
export const USER_PASSWORD_HELPER_INCORRECT_PASSWORD = {"id":"user.password.helper.incorrect_password","translations":{"user":{"fr":"Le mot de passe actuel est incorect","en":"Current password is incorrect"}}};
export const USER_PASSWORD_HELPER_NOT_IDENTICAL = {"id":"user.password.helper.not_identical","translations":{"user":{"fr":"Les mots de passe ne sont pas identique","en":"Passwords do not match"}}};
export const USER_PASSWORD_CODE_RECEIVE = {"id":"user.password.code.receive","translations":{"user":{"fr":"Recevoir mon code","en":"Receive my code"}}};
export const USER_PASSWORD_CODE_SMS_CODE = {"id":"user.password.code.sms_code","translations":{"user":{"fr":"Code \u00e0 6 chiffres","en":"6-digit code"}}};
export const USER_PASSWORD_CODE_RECEIVED = {"id":"user.password.code.received","translations":{"user":{"fr":"Code re\u00e7u par sms sur votre num\u00e9ro de t\u00e9l\u00e9phone.","en":"Code received by SMS on your phone number."}}};
export const USER_PASSWORD_CODE_RECEIVE_BY_SMS = {"id":"user.password.code.receive_by_sms","translations":{"user":{"fr":"Recevez votre code par SMS","en":"Receive your code by SMS"}}};
export const USER_PASSWORD_CODE_RECEIVE_BY_MAIL = {"id":"user.password.code.receive_by_mail","translations":{"user":{"fr":"Recevez votre code par e-mail","en":"Receive your code by email"}}};
export const USER_PASSWORD_CODE_MAIL_SEND = {"id":"user.password.code.mail_send","translations":{"user":{"fr":"Un e-mail vous a \u00e9t\u00e9 envoy\u00e9 contenant un code \u00e0 6 chiffres","en":"An email was sent with a 6-digit code"}}};
export const USER_PASSWORD_CODE_SELECT_PHONE = {"id":"user.password.code.select_phone","translations":{"user":{"fr":"Choisissez votre num\u00e9ro de t\u00e9l\u00e9phone puis cliquez sur \"recevoir mon code\".","en":"Select your phone number and click on \"receive my code\"."}}};
export const USER_PASSWORD_CODE_YOUR_PHONE = {"id":"user.password.code.your_phone","translations":{"user":{"fr":"Votre num\u00e9ro de t\u00e9l\u00e9phone","en":"Your phone number"}}};
export const USER_PASSWORD_CODE_BY_SMS = {"id":"user.password.code.by_sms","translations":{"user":{"fr":"Code par SMS","en":"SMS code"}}};
export const USER_PASSWORD_CODE_BY_MAIL = {"id":"user.password.code.by_mail","translations":{"user":{"fr":"Code par e-mail","en":"Email code"}}};
export const USER_PASSWORD_CODE_FOLLOWING_MAIL = {"id":"user.password.code.following_mail","translations":{"user":{"fr":"Code re\u00e7u par e-mail \u00e0 l'adresse suivante : ","en":"Code received by email on : "}}};
export const USER_PASSWORD_CODE_RECOVERY = {"id":"user.password.code.recovery","translations":{"user":{"fr":"Codes de r\u00e9cup\u00e9ration","en":"Recovery codes"}}};
export const USER_PASSWORD_CODE_FILL = {"id":"user.password.code.fill","translations":{"user":{"fr":"Entrez le code \u00e0 6 chiffres","en":"Enter your 6-digit code"}}};
export const USER_CALENDAR_OLD_VERSION = {"id":"user.calendar.old_version","translations":{"user":{"fr":"Vous \u00eates sur l'ancienne interface du calendrier qui va bient\u00f4t dispara\u00eetre. Cliquez sur ce bouton pour basculer sur la nouvelle interface.","en":"You are on the old calendar interface that will soon be obsolete. Switch on the new interface by clicking bellow button."}}};
export const USER_BOOKING_APPOINTMENT_WITH = {"id":"user.booking.appointment_with","translations":{"user":{"fr":"Rendez-vous $type avec $name","en":"Appointment $type with $name"}}};
export const USER_BOOKING_TO_UPDATE = {"id":"user.booking.to_update","translations":{"user":{"fr":"$type avec $name. \n Pour modifier votre RDV, cliquez sur le lien ci-dessous : \n $url","en":"$type with $name. \n To edit your appointment, click on link below : \n $url"}}};
export const USER_FORCE_TWO_FA = {"id":"user.force_two_fa","translations":{"user":{"fr":"Forcer la 2FA lors de la connexion","en":"Force 2FA on login"}}};
export const USER_STRIPE_CUSTOMER_ID = {"id":"user.stripe_customer_id","translations":{"user":{"fr":"Identifiant Stripe de l'utilisateur","en":"Stripe customer id"}}};
export const USER_IS_MAIN_MOBILE_APP_USER = {"id":"user.is_main_mobile_app_user","translations":{"user":{"fr":"Utilisateur principal de l\u2019app mobile","en":"Main mobile app user"}}};
export const USER_MOBILE_SERVICE_SCORE = {"id":"user.mobile_service_score","translations":{"user":{"fr":"Score de l'app mobile","en":"Mobile Service Score"}}};
export const USER_CPS_CARD = {"id":"user.cps_card","translations":{"user":{"fr":"Carte CPS","en":"CPS Card"}}};
export const COUNTRY_CODE_DESCRIPTION = {"id":"country_code.description","translations":{"user":{"fr":"La nationalit\u00e9 de l'utilisateur","en":"The user's nationality"}}};
export const COUNTRY_CODE_LABEL = {"id":"country_code.label","translations":{"user":{"fr":"Nationalit\u00e9","en":"Nationality"}}};
export const COUNTRY_CODE_PLACEHOLDER = {"id":"country_code.placeholder","translations":{"user":{"fr":"S\u00e9lectionnez une nationalit\u00e9","en":"Select a nationality"}}};
export const COUNTRY_CODE_VALUES_AF = {"id":"country_code.values.AF","translations":{"user":{"fr":"Afghan","en":"Afghan"}}};
export const COUNTRY_CODE_VALUES_AX = {"id":"country_code.values.AX","translations":{"user":{"fr":"\u00c5landais","en":"\u00c5land Islands"}}};
export const COUNTRY_CODE_VALUES_AL = {"id":"country_code.values.AL","translations":{"user":{"fr":"Albanais","en":"Albanian"}}};
export const COUNTRY_CODE_VALUES_DZ = {"id":"country_code.values.DZ","translations":{"user":{"fr":"Alg\u00e9rien","en":"Algerian"}}};
export const COUNTRY_CODE_VALUES_AS = {"id":"country_code.values.AS","translations":{"user":{"fr":"Samoan","en":"Samoan"}}};
export const COUNTRY_CODE_VALUES_AD = {"id":"country_code.values.AD","translations":{"user":{"fr":"Andorran","en":"Andorran"}}};
export const COUNTRY_CODE_VALUES_AO = {"id":"country_code.values.AO","translations":{"user":{"fr":"Angolais","en":"Angolan"}}};
export const COUNTRY_CODE_VALUES_AI = {"id":"country_code.values.AI","translations":{"user":{"fr":"Anguillan","en":"Anguillan"}}};
export const COUNTRY_CODE_VALUES_AG = {"id":"country_code.values.AG","translations":{"user":{"fr":"Antiguais-et-Barbudien","en":"Antiguan and Barbudan"}}};
export const COUNTRY_CODE_VALUES_AR = {"id":"country_code.values.AR","translations":{"user":{"fr":"Argentin","en":"Argentinian"}}};
export const COUNTRY_CODE_VALUES_AM = {"id":"country_code.values.AM","translations":{"user":{"fr":"Arm\u00e9nien","en":"Armenian"}}};
export const COUNTRY_CODE_VALUES_AW = {"id":"country_code.values.AW","translations":{"user":{"fr":"Arubain","en":"Aruban"}}};
export const COUNTRY_CODE_VALUES_AU = {"id":"country_code.values.AU","translations":{"user":{"fr":"Australien","en":"Australian"}}};
export const COUNTRY_CODE_VALUES_AT = {"id":"country_code.values.AT","translations":{"user":{"fr":"Autrichien","en":"Austrian"}}};
export const COUNTRY_CODE_VALUES_AZ = {"id":"country_code.values.AZ","translations":{"user":{"fr":"Azerba\u00efdjanais","en":"Azerbaijani"}}};
export const COUNTRY_CODE_VALUES_BS = {"id":"country_code.values.BS","translations":{"user":{"fr":"Baham\u00e9en","en":"Bahamian"}}};
export const COUNTRY_CODE_VALUES_BH = {"id":"country_code.values.BH","translations":{"user":{"fr":"Bahrainien","en":"Bahraini"}}};
export const COUNTRY_CODE_VALUES_BD = {"id":"country_code.values.BD","translations":{"user":{"fr":"Bangladais","en":"Bangladeshi"}}};
export const COUNTRY_CODE_VALUES_BB = {"id":"country_code.values.BB","translations":{"user":{"fr":"Barbadien","en":"Barbadian"}}};
export const COUNTRY_CODE_VALUES_BY = {"id":"country_code.values.BY","translations":{"user":{"fr":"Bi\u00e9lorusse","en":"Belarusian"}}};
export const COUNTRY_CODE_VALUES_BE = {"id":"country_code.values.BE","translations":{"user":{"fr":"Belge","en":"Belgian"}}};
export const COUNTRY_CODE_VALUES_BZ = {"id":"country_code.values.BZ","translations":{"user":{"fr":"B\u00e9lizien","en":"Belizean"}}};
export const COUNTRY_CODE_VALUES_BJ = {"id":"country_code.values.BJ","translations":{"user":{"fr":"B\u00e9ninois","en":"Beninese"}}};
export const COUNTRY_CODE_VALUES_BT = {"id":"country_code.values.BT","translations":{"user":{"fr":"Bhoutanais","en":"Bhutanese"}}};
export const COUNTRY_CODE_VALUES_BO = {"id":"country_code.values.BO","translations":{"user":{"fr":"Bolivien","en":"Bolivian"}}};
export const COUNTRY_CODE_VALUES_BA = {"id":"country_code.values.BA","translations":{"user":{"fr":"Bosnien","en":"Bosnian"}}};
export const COUNTRY_CODE_VALUES_BW = {"id":"country_code.values.BW","translations":{"user":{"fr":"Botswanais","en":"Botswanan"}}};
export const COUNTRY_CODE_VALUES_BR = {"id":"country_code.values.BR","translations":{"user":{"fr":"Br\u00e9silien","en":"Brazilian"}}};
export const COUNTRY_CODE_VALUES_BN = {"id":"country_code.values.BN","translations":{"user":{"fr":"Brun\u00e9ien","en":"Bruneian"}}};
export const COUNTRY_CODE_VALUES_BG = {"id":"country_code.values.BG","translations":{"user":{"fr":"Bulgare","en":"Bulgarian"}}};
export const COUNTRY_CODE_VALUES_BF = {"id":"country_code.values.BF","translations":{"user":{"fr":"Burkinab\u00e9","en":"Burkinabe"}}};
export const COUNTRY_CODE_VALUES_BI = {"id":"country_code.values.BI","translations":{"user":{"fr":"Burundais","en":"Burundian"}}};
export const COUNTRY_CODE_VALUES_KH = {"id":"country_code.values.KH","translations":{"user":{"fr":"Cambodgien","en":"Cambodian"}}};
export const COUNTRY_CODE_VALUES_CM = {"id":"country_code.values.CM","translations":{"user":{"fr":"Camerounais","en":"Cameroonian"}}};
export const COUNTRY_CODE_VALUES_CA = {"id":"country_code.values.CA","translations":{"user":{"fr":"Canadien","en":"Canadian"}}};
export const COUNTRY_CODE_VALUES_CV = {"id":"country_code.values.CV","translations":{"user":{"fr":"Cap-Verdien","en":"Cape Verdean"}}};
export const COUNTRY_CODE_VALUES_CF = {"id":"country_code.values.CF","translations":{"user":{"fr":"Centrafricain","en":"Central African"}}};
export const COUNTRY_CODE_VALUES_TD = {"id":"country_code.values.TD","translations":{"user":{"fr":"Tchadien","en":"Chadian"}}};
export const COUNTRY_CODE_VALUES_CL = {"id":"country_code.values.CL","translations":{"user":{"fr":"Chilien","en":"Chilean"}}};
export const COUNTRY_CODE_VALUES_CN = {"id":"country_code.values.CN","translations":{"user":{"fr":"Chinois","en":"Chinese"}}};
export const COUNTRY_CODE_VALUES_CO = {"id":"country_code.values.CO","translations":{"user":{"fr":"Colombien","en":"Colombian"}}};
export const COUNTRY_CODE_VALUES_KM = {"id":"country_code.values.KM","translations":{"user":{"fr":"Comorien","en":"Comorian"}}};
export const COUNTRY_CODE_VALUES_CG = {"id":"country_code.values.CG","translations":{"user":{"fr":"Congolais","en":"Congolese"}}};
export const COUNTRY_CODE_VALUES_CD = {"id":"country_code.values.CD","translations":{"user":{"fr":"Congolais","en":"Congolese"}}};
export const COUNTRY_CODE_VALUES_CR = {"id":"country_code.values.CR","translations":{"user":{"fr":"Costaricain","en":"Costa Rican"}}};
export const COUNTRY_CODE_VALUES_CI = {"id":"country_code.values.CI","translations":{"user":{"fr":"Ivoirien","en":"Ivorian"}}};
export const COUNTRY_CODE_VALUES_HR = {"id":"country_code.values.HR","translations":{"user":{"fr":"Croate","en":"Croatian"}}};
export const COUNTRY_CODE_VALUES_CU = {"id":"country_code.values.CU","translations":{"user":{"fr":"Cubain","en":"Cuban"}}};
export const COUNTRY_CODE_VALUES_CY = {"id":"country_code.values.CY","translations":{"user":{"fr":"Chypriote","en":"Cypriot"}}};
export const COUNTRY_CODE_VALUES_CZ = {"id":"country_code.values.CZ","translations":{"user":{"fr":"Tch\u00e8que","en":"Czech"}}};
export const COUNTRY_CODE_VALUES_DK = {"id":"country_code.values.DK","translations":{"user":{"fr":"Danois","en":"Danish"}}};
export const COUNTRY_CODE_VALUES_DJ = {"id":"country_code.values.DJ","translations":{"user":{"fr":"Djiboutien","en":"Djiboutian"}}};
export const COUNTRY_CODE_VALUES_DM = {"id":"country_code.values.DM","translations":{"user":{"fr":"Dominiquais","en":"Dominican"}}};
export const COUNTRY_CODE_VALUES_DO = {"id":"country_code.values.DO","translations":{"user":{"fr":"Dominicain","en":"Dominican"}}};
export const COUNTRY_CODE_VALUES_EC = {"id":"country_code.values.EC","translations":{"user":{"fr":"\u00c9quatorien","en":"Ecuadorian"}}};
export const COUNTRY_CODE_VALUES_EG = {"id":"country_code.values.EG","translations":{"user":{"fr":"\u00c9gyptien","en":"Egyptian"}}};
export const COUNTRY_CODE_VALUES_SV = {"id":"country_code.values.SV","translations":{"user":{"fr":"Salvadorien","en":"Salvadoran"}}};
export const COUNTRY_CODE_VALUES_GQ = {"id":"country_code.values.GQ","translations":{"user":{"fr":"\u00c9quato-Guin\u00e9en","en":"Equatorial Guinean"}}};
export const COUNTRY_CODE_VALUES_ER = {"id":"country_code.values.ER","translations":{"user":{"fr":"\u00c9rythr\u00e9en","en":"Eritrean"}}};
export const COUNTRY_CODE_VALUES_EE = {"id":"country_code.values.EE","translations":{"user":{"fr":"Estonien","en":"Estonian"}}};
export const COUNTRY_CODE_VALUES_ET = {"id":"country_code.values.ET","translations":{"user":{"fr":"\u00c9thiopien","en":"Ethiopian"}}};
export const COUNTRY_CODE_VALUES_FI = {"id":"country_code.values.FI","translations":{"user":{"fr":"Finlandais","en":"Finnish"}}};
export const COUNTRY_CODE_VALUES_FR = {"id":"country_code.values.FR","translations":{"user":{"fr":"Fran\u00e7ais","en":"French"}}};
export const COUNTRY_CODE_VALUES_GA = {"id":"country_code.values.GA","translations":{"user":{"fr":"Gabonais","en":"Gabonese"}}};
export const COUNTRY_CODE_VALUES_GM = {"id":"country_code.values.GM","translations":{"user":{"fr":"Gambien","en":"Gambian"}}};
export const COUNTRY_CODE_VALUES_GE = {"id":"country_code.values.GE","translations":{"user":{"fr":"G\u00e9orgien","en":"Georgian"}}};
export const COUNTRY_CODE_VALUES_DE = {"id":"country_code.values.DE","translations":{"user":{"fr":"Allemand","en":"German"}}};
export const COUNTRY_CODE_VALUES_GH = {"id":"country_code.values.GH","translations":{"user":{"fr":"Ghan\u00e9en","en":"Ghanaian"}}};
export const COUNTRY_CODE_VALUES_GR = {"id":"country_code.values.GR","translations":{"user":{"fr":"Grec","en":"Greek"}}};
export const COUNTRY_CODE_VALUES_GD = {"id":"country_code.values.GD","translations":{"user":{"fr":"Grenadien","en":"Grenadian"}}};
export const COUNTRY_CODE_VALUES_GT = {"id":"country_code.values.GT","translations":{"user":{"fr":"Guat\u00e9malt\u00e8que","en":"Guatemalan"}}};
export const COUNTRY_CODE_VALUES_GN = {"id":"country_code.values.GN","translations":{"user":{"fr":"Guin\u00e9en","en":"Guinean"}}};
export const COUNTRY_CODE_VALUES_GW = {"id":"country_code.values.GW","translations":{"user":{"fr":"Bissau-Guin\u00e9en","en":"Bissau-Guinean"}}};
export const COUNTRY_CODE_VALUES_GY = {"id":"country_code.values.GY","translations":{"user":{"fr":"Guyanien","en":"Guyanese"}}};
export const COUNTRY_CODE_VALUES_HT = {"id":"country_code.values.HT","translations":{"user":{"fr":"Ha\u00eftien","en":"Haitian"}}};
export const COUNTRY_CODE_VALUES_HN = {"id":"country_code.values.HN","translations":{"user":{"fr":"Hondurien","en":"Honduran"}}};
export const COUNTRY_CODE_VALUES_HU = {"id":"country_code.values.HU","translations":{"user":{"fr":"Hongrois","en":"Hungarian"}}};
export const COUNTRY_CODE_VALUES_IS = {"id":"country_code.values.IS","translations":{"user":{"fr":"Islandais","en":"Icelandic"}}};
export const COUNTRY_CODE_VALUES_IN = {"id":"country_code.values.IN","translations":{"user":{"fr":"Indien","en":"Indian"}}};
export const COUNTRY_CODE_VALUES_ID = {"id":"country_code.values.ID","translations":{"user":{"fr":"Indon\u00e9sien","en":"Indonesian"}}};
export const COUNTRY_CODE_VALUES_IR = {"id":"country_code.values.IR","translations":{"user":{"fr":"Iranien","en":"Iranian"}}};
export const COUNTRY_CODE_VALUES_IQ = {"id":"country_code.values.IQ","translations":{"user":{"fr":"Irakien","en":"Iraqi"}}};
export const COUNTRY_CODE_VALUES_IE = {"id":"country_code.values.IE","translations":{"user":{"fr":"Irlandais","en":"Irish"}}};
export const COUNTRY_CODE_VALUES_IL = {"id":"country_code.values.IL","translations":{"user":{"fr":"Isra\u00e9lien","en":"Israeli"}}};
export const COUNTRY_CODE_VALUES_IT = {"id":"country_code.values.IT","translations":{"user":{"fr":"Italien","en":"Italian"}}};
export const COUNTRY_CODE_VALUES_JM = {"id":"country_code.values.JM","translations":{"user":{"fr":"Jama\u00efcain","en":"Jamaican"}}};
export const COUNTRY_CODE_VALUES_JP = {"id":"country_code.values.JP","translations":{"user":{"fr":"Japonais","en":"Japanese"}}};
export const COUNTRY_CODE_VALUES_JO = {"id":"country_code.values.JO","translations":{"user":{"fr":"Jordanien","en":"Jordanian"}}};
export const COUNTRY_CODE_VALUES_KZ = {"id":"country_code.values.KZ","translations":{"user":{"fr":"Kazakh","en":"Kazakh"}}};
export const COUNTRY_CODE_VALUES_KE = {"id":"country_code.values.KE","translations":{"user":{"fr":"Kenyan","en":"Kenyan"}}};
export const COUNTRY_CODE_VALUES_KI = {"id":"country_code.values.KI","translations":{"user":{"fr":"Kiribatien","en":"Kiribati"}}};
export const COUNTRY_CODE_VALUES_KP = {"id":"country_code.values.KP","translations":{"user":{"fr":"Nord-Cor\u00e9en","en":"North Korean"}}};
export const COUNTRY_CODE_VALUES_KR = {"id":"country_code.values.KR","translations":{"user":{"fr":"Sud-Cor\u00e9en","en":"South Korean"}}};
export const COUNTRY_CODE_VALUES_KW = {"id":"country_code.values.KW","translations":{"user":{"fr":"Kowe\u00eftien","en":"Kuwaiti"}}};
export const COUNTRY_CODE_VALUES_KG = {"id":"country_code.values.KG","translations":{"user":{"fr":"Kirghize","en":"Kyrgyz"}}};
export const COUNTRY_CODE_VALUES_LA = {"id":"country_code.values.LA","translations":{"user":{"fr":"Laotien","en":"Laotian"}}};
export const COUNTRY_CODE_VALUES_LV = {"id":"country_code.values.LV","translations":{"user":{"fr":"Letton","en":"Latvian"}}};
export const COUNTRY_CODE_VALUES_LB = {"id":"country_code.values.LB","translations":{"user":{"fr":"Libanais","en":"Lebanese"}}};
export const COUNTRY_CODE_VALUES_LS = {"id":"country_code.values.LS","translations":{"user":{"fr":"Lesothan","en":"Lesothoan"}}};
export const COUNTRY_CODE_VALUES_LR = {"id":"country_code.values.LR","translations":{"user":{"fr":"Lib\u00e9rien","en":"Liberian"}}};
export const COUNTRY_CODE_VALUES_LY = {"id":"country_code.values.LY","translations":{"user":{"fr":"Libyen","en":"Libyan"}}};
export const COUNTRY_CODE_VALUES_LI = {"id":"country_code.values.LI","translations":{"user":{"fr":"Liechtensteinois","en":"Liechtensteinian"}}};
export const COUNTRY_CODE_VALUES_LT = {"id":"country_code.values.LT","translations":{"user":{"fr":"Lituanien","en":"Lithuanian"}}};
export const COUNTRY_CODE_VALUES_LU = {"id":"country_code.values.LU","translations":{"user":{"fr":"Luxembourgeois","en":"Luxembourgish"}}};
export const COUNTRY_CODE_VALUES_MK = {"id":"country_code.values.MK","translations":{"user":{"fr":"Mac\u00e9donien","en":"Macedonian"}}};
export const COUNTRY_CODE_VALUES_MG = {"id":"country_code.values.MG","translations":{"user":{"fr":"Malgache","en":"Malagasy"}}};
export const COUNTRY_CODE_VALUES_MW = {"id":"country_code.values.MW","translations":{"user":{"fr":"Malawite","en":"Malawian"}}};
export const COUNTRY_CODE_VALUES_MY = {"id":"country_code.values.MY","translations":{"user":{"fr":"Malaisien","en":"Malaysian"}}};
export const COUNTRY_CODE_VALUES_MV = {"id":"country_code.values.MV","translations":{"user":{"fr":"Maldivien","en":"Maldivian"}}};
export const COUNTRY_CODE_VALUES_ML = {"id":"country_code.values.ML","translations":{"user":{"fr":"Malien","en":"Malian"}}};
export const COUNTRY_CODE_VALUES_MT = {"id":"country_code.values.MT","translations":{"user":{"fr":"Maltais","en":"Maltese"}}};
export const COUNTRY_CODE_VALUES_MH = {"id":"country_code.values.MH","translations":{"user":{"fr":"Marshallais","en":"Marshallese"}}};
export const COUNTRY_CODE_VALUES_MR = {"id":"country_code.values.MR","translations":{"user":{"fr":"Mauritanien","en":"Mauritanian"}}};
export const COUNTRY_CODE_VALUES_MU = {"id":"country_code.values.MU","translations":{"user":{"fr":"Mauricien","en":"Mauritian"}}};
export const COUNTRY_CODE_VALUES_MX = {"id":"country_code.values.MX","translations":{"user":{"fr":"Mexicain","en":"Mexican"}}};
export const COUNTRY_CODE_VALUES_FM = {"id":"country_code.values.FM","translations":{"user":{"fr":"Micron\u00e9sien","en":"Micronesian"}}};
export const COUNTRY_CODE_VALUES_MD = {"id":"country_code.values.MD","translations":{"user":{"fr":"Moldave","en":"Moldovan"}}};
export const COUNTRY_CODE_VALUES_MC = {"id":"country_code.values.MC","translations":{"user":{"fr":"Mon\u00e9gasque","en":"Monegasque"}}};
export const COUNTRY_CODE_VALUES_MN = {"id":"country_code.values.MN","translations":{"user":{"fr":"Mongol","en":"Mongolian"}}};
export const COUNTRY_CODE_VALUES_ME = {"id":"country_code.values.ME","translations":{"user":{"fr":"Mont\u00e9n\u00e9grin","en":"Montenegrin"}}};
export const COUNTRY_CODE_VALUES_MA = {"id":"country_code.values.MA","translations":{"user":{"fr":"Marocain","en":"Moroccan"}}};
export const COUNTRY_CODE_VALUES_MZ = {"id":"country_code.values.MZ","translations":{"user":{"fr":"Mozambicain","en":"Mozambican"}}};
export const COUNTRY_CODE_VALUES_MM = {"id":"country_code.values.MM","translations":{"user":{"fr":"Birman","en":"Burmese"}}};
export const COUNTRY_CODE_VALUES_NA = {"id":"country_code.values.NA","translations":{"user":{"fr":"Namibien","en":"Namibian"}}};
export const COUNTRY_CODE_VALUES_NR = {"id":"country_code.values.NR","translations":{"user":{"fr":"Nauruan","en":"Nauruan"}}};
export const COUNTRY_CODE_VALUES_NP = {"id":"country_code.values.NP","translations":{"user":{"fr":"N\u00e9palais","en":"Nepalese"}}};
export const COUNTRY_CODE_VALUES_NL = {"id":"country_code.values.NL","translations":{"user":{"fr":"N\u00e9erlandais","en":"Dutch"}}};
export const COUNTRY_CODE_VALUES_NZ = {"id":"country_code.values.NZ","translations":{"user":{"fr":"N\u00e9o-Z\u00e9landais","en":"New Zealander"}}};
export const COUNTRY_CODE_VALUES_NI = {"id":"country_code.values.NI","translations":{"user":{"fr":"Nicaraguayen","en":"Nicaraguan"}}};
export const COUNTRY_CODE_VALUES_NE = {"id":"country_code.values.NE","translations":{"user":{"fr":"Nig\u00e9rien","en":"Nigerien"}}};
export const COUNTRY_CODE_VALUES_NG = {"id":"country_code.values.NG","translations":{"user":{"fr":"Nig\u00e9rian","en":"Nigerian"}}};
export const COUNTRY_CODE_VALUES_NO = {"id":"country_code.values.NO","translations":{"user":{"fr":"Norv\u00e9gien","en":"Norwegian"}}};
export const COUNTRY_CODE_VALUES_OM = {"id":"country_code.values.OM","translations":{"user":{"fr":"Omanais","en":"Omani"}}};
export const COUNTRY_CODE_VALUES_PK = {"id":"country_code.values.PK","translations":{"user":{"fr":"Pakistanais","en":"Pakistani"}}};
export const COUNTRY_CODE_VALUES_PW = {"id":"country_code.values.PW","translations":{"user":{"fr":"Paluan","en":"Palauan"}}};
export const COUNTRY_CODE_VALUES_PS = {"id":"country_code.values.PS","translations":{"user":{"fr":"Palestinien","en":"Palestinian"}}};
export const COUNTRY_CODE_VALUES_PA = {"id":"country_code.values.PA","translations":{"user":{"fr":"Panam\u00e9en","en":"Panamanian"}}};
export const COUNTRY_CODE_VALUES_PG = {"id":"country_code.values.PG","translations":{"user":{"fr":"Papou-N\u00e9o-Guin\u00e9en","en":"Papua New Guinean"}}};
export const COUNTRY_CODE_VALUES_PY = {"id":"country_code.values.PY","translations":{"user":{"fr":"Paraguayen","en":"Paraguayan"}}};
export const COUNTRY_CODE_VALUES_PE = {"id":"country_code.values.PE","translations":{"user":{"fr":"P\u00e9ruvien","en":"Peruvian"}}};
export const COUNTRY_CODE_VALUES_PH = {"id":"country_code.values.PH","translations":{"user":{"fr":"Philippin","en":"Filipino"}}};
export const COUNTRY_CODE_VALUES_PL = {"id":"country_code.values.PL","translations":{"user":{"fr":"Polonais","en":"Polish"}}};
export const COUNTRY_CODE_VALUES_PT = {"id":"country_code.values.PT","translations":{"user":{"fr":"Portugais","en":"Portuguese"}}};
export const COUNTRY_CODE_VALUES_QA = {"id":"country_code.values.QA","translations":{"user":{"fr":"Qatarien","en":"Qatari"}}};
export const COUNTRY_CODE_VALUES_RO = {"id":"country_code.values.RO","translations":{"user":{"fr":"Roumain","en":"Romanian"}}};
export const COUNTRY_CODE_VALUES_RU = {"id":"country_code.values.RU","translations":{"user":{"fr":"Russe","en":"Russian"}}};
export const COUNTRY_CODE_VALUES_RW = {"id":"country_code.values.RW","translations":{"user":{"fr":"Rwandais","en":"Rwandan"}}};
export const COUNTRY_CODE_VALUES_SA = {"id":"country_code.values.SA","translations":{"user":{"fr":"Saoudien","en":"Saudi Arabian"}}};
export const COUNTRY_CODE_VALUES_SN = {"id":"country_code.values.SN","translations":{"user":{"fr":"S\u00e9n\u00e9galais","en":"Senegalese"}}};
export const COUNTRY_CODE_VALUES_RS = {"id":"country_code.values.RS","translations":{"user":{"fr":"Serbe","en":"Serbian"}}};
export const COUNTRY_CODE_VALUES_SC = {"id":"country_code.values.SC","translations":{"user":{"fr":"Seychellois","en":"Seychellois"}}};
export const COUNTRY_CODE_VALUES_SL = {"id":"country_code.values.SL","translations":{"user":{"fr":"Sierra-L\u00e9onais","en":"Sierra Leonean"}}};
export const COUNTRY_CODE_VALUES_SG = {"id":"country_code.values.SG","translations":{"user":{"fr":"Singapourien","en":"Singaporean"}}};
export const COUNTRY_CODE_VALUES_SK = {"id":"country_code.values.SK","translations":{"user":{"fr":"Slovaque","en":"Slovak"}}};
export const COUNTRY_CODE_VALUES_SI = {"id":"country_code.values.SI","translations":{"user":{"fr":"Slov\u00e8ne","en":"Slovenian"}}};
export const COUNTRY_CODE_VALUES_SB = {"id":"country_code.values.SB","translations":{"user":{"fr":"Salomonais","en":"Solomon Islander"}}};
export const COUNTRY_CODE_VALUES_SO = {"id":"country_code.values.SO","translations":{"user":{"fr":"Somalien","en":"Somali"}}};
export const COUNTRY_CODE_VALUES_ZA = {"id":"country_code.values.ZA","translations":{"user":{"fr":"Sud-Africain","en":"South African"}}};
export const COUNTRY_CODE_VALUES_SS = {"id":"country_code.values.SS","translations":{"user":{"fr":"Sud-Soudanais","en":"South Sudanese"}}};
export const COUNTRY_CODE_VALUES_ES = {"id":"country_code.values.ES","translations":{"user":{"fr":"Espagnol","en":"Spanish"}}};
export const COUNTRY_CODE_VALUES_LK = {"id":"country_code.values.LK","translations":{"user":{"fr":"Sri-Lankais","en":"Sri Lankan"}}};
export const COUNTRY_CODE_VALUES_SD = {"id":"country_code.values.SD","translations":{"user":{"fr":"Soudanais","en":"Sudanese"}}};
export const COUNTRY_CODE_VALUES_SR = {"id":"country_code.values.SR","translations":{"user":{"fr":"Surinamien","en":"Surinamese"}}};
export const COUNTRY_CODE_VALUES_SE = {"id":"country_code.values.SE","translations":{"user":{"fr":"Su\u00e9dois","en":"Swedish"}}};
export const COUNTRY_CODE_VALUES_CH = {"id":"country_code.values.CH","translations":{"user":{"fr":"Suisse","en":"Swiss"}}};
export const COUNTRY_CODE_VALUES_SY = {"id":"country_code.values.SY","translations":{"user":{"fr":"Syrien","en":"Syrian"}}};
export const COUNTRY_CODE_VALUES_TW = {"id":"country_code.values.TW","translations":{"user":{"fr":"Ta\u00efwanais","en":"Taiwanese"}}};
export const COUNTRY_CODE_VALUES_TJ = {"id":"country_code.values.TJ","translations":{"user":{"fr":"Tadjik","en":"Tajik"}}};
export const COUNTRY_CODE_VALUES_TZ = {"id":"country_code.values.TZ","translations":{"user":{"fr":"Tanzanien","en":"Tanzanian"}}};
export const COUNTRY_CODE_VALUES_TH = {"id":"country_code.values.TH","translations":{"user":{"fr":"Tha\u00eflandais","en":"Thai"}}};
export const COUNTRY_CODE_VALUES_TL = {"id":"country_code.values.TL","translations":{"user":{"fr":"Timorais","en":"Timorese"}}};
export const COUNTRY_CODE_VALUES_TG = {"id":"country_code.values.TG","translations":{"user":{"fr":"Togolais","en":"Togolese"}}};
export const COUNTRY_CODE_VALUES_TO = {"id":"country_code.values.TO","translations":{"user":{"fr":"Tongien","en":"Tongan"}}};
export const COUNTRY_CODE_VALUES_TT = {"id":"country_code.values.TT","translations":{"user":{"fr":"Trinidadien","en":"Trinidadian"}}};
export const COUNTRY_CODE_VALUES_TN = {"id":"country_code.values.TN","translations":{"user":{"fr":"Tunisien","en":"Tunisian"}}};
export const COUNTRY_CODE_VALUES_TR = {"id":"country_code.values.TR","translations":{"user":{"fr":"Turc","en":"Turkish"}}};
export const COUNTRY_CODE_VALUES_TM = {"id":"country_code.values.TM","translations":{"user":{"fr":"Turkm\u00e8ne","en":"Turkmen"}}};
export const COUNTRY_CODE_VALUES_TV = {"id":"country_code.values.TV","translations":{"user":{"fr":"Tuvaluan","en":"Tuvaluan"}}};
export const COUNTRY_CODE_VALUES_UG = {"id":"country_code.values.UG","translations":{"user":{"fr":"Ougandais","en":"Ugandan"}}};
export const COUNTRY_CODE_VALUES_UA = {"id":"country_code.values.UA","translations":{"user":{"fr":"Ukrainien","en":"Ukrainian"}}};
export const COUNTRY_CODE_VALUES_AE = {"id":"country_code.values.AE","translations":{"user":{"fr":"\u00c9mirati","en":"Emirati"}}};
export const COUNTRY_CODE_VALUES_GB = {"id":"country_code.values.GB","translations":{"user":{"fr":"Britannique","en":"British"}}};
export const COUNTRY_CODE_VALUES_US = {"id":"country_code.values.US","translations":{"user":{"fr":"Am\u00e9ricain","en":"American"}}};
export const COUNTRY_CODE_VALUES_UY = {"id":"country_code.values.UY","translations":{"user":{"fr":"Uruguayen","en":"Uruguayan"}}};
export const COUNTRY_CODE_VALUES_UZ = {"id":"country_code.values.UZ","translations":{"user":{"fr":"Ouzbek","en":"Uzbek"}}};
export const COUNTRY_CODE_VALUES_VU = {"id":"country_code.values.VU","translations":{"user":{"fr":"Vanuatais","en":"Vanuatuan"}}};
export const COUNTRY_CODE_VALUES_VE = {"id":"country_code.values.VE","translations":{"user":{"fr":"V\u00e9n\u00e9zu\u00e9lien","en":"Venezuelan"}}};
export const COUNTRY_CODE_VALUES_VN = {"id":"country_code.values.VN","translations":{"user":{"fr":"Vietnamien","en":"Vietnamese"}}};
export const COUNTRY_CODE_VALUES_YE = {"id":"country_code.values.YE","translations":{"user":{"fr":"Y\u00e9m\u00e9nite","en":"Yemeni"}}};
export const COUNTRY_CODE_VALUES_ZM = {"id":"country_code.values.ZM","translations":{"user":{"fr":"Zambien","en":"Zambian"}}};
export const COUNTRY_CODE_VALUES_ZW = {"id":"country_code.values.ZW","translations":{"user":{"fr":"Zimbabw\u00e9en","en":"Zimbabwean"}}};
export const CATEGORY_VALUES_SHARE_RECORD = {"id":"category.values.share_record","translations":{"notification":{"en":"Record sharing"}}};
export const SHARE_RECORD_ACKNOWLEDGED_TITLE = {"id":"share_record.acknowledged.title","translations":{"notification":{"en":"Visit information"}}};
export const SHARE_RECORD_ACKNOWLEDGED_CONTENT = {"id":"share_record.acknowledged.content","translations":{"notification":{"en":"{{ recipient }} has accessed {{ patient }}'s medical record"}}};
export const SHARE_RECORD_HANDLED_TITLE = {"id":"share_record.handled.title","translations":{"notification":{"en":"Record processing update"}}};
export const SHARE_RECORD_HANDLED_CONTENT = {"id":"share_record.handled.content","translations":{"notification":{"en":"{{ recipient }} has successfully accessed {{ patient }}'s medical record you shared."}}};
