import {
  capitalizeFirstLetter,
  GET,
  isPatient, parseId,
  secondsToHms,
  slugify,
  ts,
  v2DisplayImage
} from './common';
import { MEDICAL_RESOURCE } from "./utils";
import Portal from "../Portal";
import {
  COMMON_WORDS_ERROR_OCCURED,
  COMMON_WORDS_FAVORITE, COMMON_WORDS_LOADING,
  COMMON_WORDS_OTHER,
  DOCUMENTS_SHARED_BY,
  PATIENT_DISEASE_SEARCH,
  trans,
  USER_ERROR_NOT_FOUND_ALLERGEN,
  USER_ERROR_NOT_FOUND_DISEASE,
  USER_ERROR_NOT_FOUND_DOC_TEMPLATE,
  USER_ERROR_NOT_FOUND_DOC_TYPE,
  USER_ERROR_NOT_FOUND_DOCTOR,
  USER_ERROR_NOT_FOUND_DRUGS,
  USER_ERROR_NOT_FOUND_INSTITUTION,
  USER_ERROR_NOT_FOUND_LETTERHEAD,
  USER_ERROR_NOT_FOUND_QUESTIONNAIRE,
  USER_ERROR_NOT_FOUND_SPECIALITY,
  USER_ERROR_NOT_FOUND_TEMPLATE,
  USER_ERROR_NOT_FOUND_USER,
  USER_ERROR_NOT_FOUND_VARIABLE
} from "../../translator";

/**
 *
 */
export default class TagsAutocompleter extends EventTarget {

  scrollTimeoutHandler;

  onItemRemove = () => {

  };
  onItemAdd = () => {

  };
  onAutocomplete = async () => {

  };
  onTagsClick = () => {

  };

  throwOnError;


  /**
     * Used for timeout in search
     */
  timeout;

  /**
     * Text displayed if no result is found
     */
  notFoundText;

  /**
     * The data inside the autocompletor
     */
  data = [];

  /**
     * The groups inside the autocompletor
     *
     * @type {{}}
     */
  groups = {};
  groupsItems = {};

  // Options
  unique = false;
  pretty = false;
  disableChoices = false;
  inline = false;
  drawerClass;
  freeInput = false;
  autoLoad;

  asPortal = false;
  withPagination = false;

  /**
     * @var Portal
     */
  portal;

  searchKey = "search";

  // Used for pagination;
  /**
     * The current page
     *
     * @type {number}
     */
  page = 1;

  /**
     * If the data is loading
     *
     * @type {boolean}
     */
  isLoading = false;

  /**
     * The max results
     *
     * @type {number}
     */
  maxResults = 999;

  get isDisabled() {
    return this.input.prop('disabled');
  }


  get parser() {
    const p = `${this._parser}Parser`;

    return (this[p] && typeof this[p] === "function") ? this[p] : this.defaultParser;

  }

  /**
     *
     * @return {*}
     */
  get templateParser() {

    let parser = "add" + capitalizeFirstLetter(this._parser);

    return (this[parser] && typeof this[parser] === "function") ? this[parser] : this.addDefault;

  }


  set parser(_parser) {
    this._parser = _parser;
  }

  _parser = "default";

  // DOM Elements
  input;
  drawer;
  loader;
  url;
  choices;
  limit = 20;

  get wrapper() {
    return this.input.closest('.v2-autocomplete');
  }

  get tagger() {
    return this.wrapper.find('.autocompleter');
  }

  get internalInput() {
    return $(this.input.tagsinput('input'));
  }

  // Ajax request
  req;
  previousVal;
  params = {};
  isInit = false;

  set value(value) {

    if (typeof value === "string") {
      this._value = value;
    }

    if (value && (value.text || typeof value.push === 'function')) {
      this._value = value;
    }

    if (this.isInit) {
      this.setValue();
    }
  }

  /**
     *
     */
  get value() {
    let v = this.input.tagsinput('items');

    if (!v) {
      return [];
    }

    if (!v.map) {
      console.error(v);
      return [];
    }
    // If the value and id are the same, it's just a text
    v = v.map((val) => {
      if (val.id === val.text) {
        return val.id;
      }
      return val;
    });

    if (this.unique) {
      return v[0] ? v[0] : null;
    }

    return v;

  }


  /**
     *
     * @return {string[]}
     */
  getIds() {

    let ids = [];

    if (this.unique) {
      return this.value && this.value.data && this.value.data['@id'] ? [this.value.data['@id']] : [];
    } else {
      this.value.forEach((v) => {
        if (v && v.data && v.data['@id']) {
          ids.push(v.data['@id']);
        }
      });
    }

    return ids;

  }


  /**
     *
     * @return {*}
     */
  getId() {
    let id = this.getIds()[0];

    return id ? id : null;

  }

  async getFirstValue() {
    await this.loadValues(true);

    let d = this.getData();

    return d[0] ? d[0] : null;

  }


  /**
     *
     * @return {*|*[]}
     */
  getData() {
    return this.data['hydra:member'] ? this.data['hydra:member'] : this.data;
  }


  /**
     *
     * @param input
     */
  constructor(input) {
    super();
    this.input = input;
    this.initOptionsFromInput();
  }


  initOptionsFromInput() {
    if (this.input.attr('choices')) {
      this.choices = this.input.attr('choices');
      if (this.choices) {
        this.choices = this.choices.split(/[,;]/g);
      }
    }
    if (this.input.attr('url')) {
      this.url = this.input.attr('url');
    }

    if (this.input.attr('limit')) {
      this.limit = this.input.attr('limit');
    }

    if (this.input.attr('drawer-class')) {
      this.drawerClass = this.input.attr('drawer-class');
    }
    if (this.input.attr('not-found-text')) {
      this.notFoundText = this.input.attr('not-found-text');
    }
    if (this.input.attr('asPortal')) {
      this.asPortal = !!this.input.attr('asPortal');
    }

    if (this.input.attr('as-portal')) {
      this.asPortal = !!this.input.attr('as-portal');
    }

    if (this.input.attr('with-pagination')) {
      this.withPagination = !!this.input.attr('with-pagination');
    }

    if (this.input.attr('unique')) {
      this.unique = this.input.attr('unique');
    }

    if (this.input.attr('pretty')) {
      this.pretty = this.input.attr('pretty');
    }

    if (this.input.attr('inline')) {
      this.inline = this.input.attr('inline');
    }

    if (this.input.attr('autoload')) {
      this.autoLoad = this.input.attr('autoload');
    }
    if (this.input.data('params')) {
      this.params = this.input.data('params');
    }

    if (this.input.data('value')) {
      this._value = this.input.data('value');
    }
    if (this.input.attr('freeInput')) {
      this.freeInput = this.input.attr('freeInput');
    }

    if (this.input.attr('disableChoices')) {
      this.disableChoices = this.input.attr('disableChoices');
      this.freeInput = true;
    }

    if (this.input.attr('parser')) {
      this.parser = this.input.attr('parser');
    }

    if (!this._value) {
      this.value = this.input.val();
    }

    this.previousVal = this.input.val();
  }


  async init(setValues) {

    if (this.params._per_page) {
      this.limit = this.params._per_page;
    }

    await this.initInput();

    if ((this.autoLoad && this.autoLoad !== "false") || this.input.val()) {
      if (this.autoLoad === "empty") {
        if (!this._value) {
          await this.loadValues(true);
        }
      } else {
        await this.loadValues(true);
      }
    }

    this.setValue();

    if (this.asPortal) {
      this.portal = new Portal(this.drawer);
    }

    this.registerEvents();

    this.isInit = true;

    if (setValues) {
      this.initValues();
    }

    this.internalInput.attr('loaded', 'true');

    this.input.trigger('loaded');

  }


  /**
     *
     */
  initValues() {
    if (!this._value) {
      this.addValueFromString(this.input.val());
    }
  }


  /**
     * Init the input and its values
     */
  initInput() {

    this.input.removeClass('v2-autocompletor--autoload');

    if (this.wrapper.find('.bootstrap-tagsinput').length === 0) {
      this.input.tagsinput({
        itemValue: 'id',
        itemText: 'text',
        itemTitle: (el) => {
          return el.text;
        }
      });

      this.input.on('beforeItemRemove', (event) => {
        if (event.item.readonly) {
          event.cancel = true;
        }
      });

      this.input.on('itemRemoved', () => {
        this.internalInput.show();
      });
    }

    this.loader = $('<i class="fa fa-circle-notch fa-spin input-loader" style="display: none;" ></i>');
    this.wrapper.append(this.loader);

    this.drawer = $(`<div class="v2-autocomplete--drawer ${this.drawerClass ?? ''}"></div>`);
    this.input.after(this.drawer);
    this.drawer.hide();

    this.input.prop('autocompletor', this);

    if (this.pretty) {
      this.wrapper.find('.bootstrap-tagsinput').addClass('form-control');
    }

    if (this.inline) {
      this.wrapper.addClass('input-inline');
    }

    if (this.unique) {
      this.wrapper.addClass('unique');
    }

    this.setValue();

    // Else it is set to required and form validation has a problem
    setInterval(() => {
      this.wrapper.find('.bootstrap-tagsinput').find('input').prop('required', false);
    }, 2000);


  }


  setValue() {
    let prop = this._value;

    // Add all tags
    if (prop && typeof prop.forEach === "function") {

      const added = [];
      prop.forEach((v) => {
        if (typeof v === "string") {
          added.push(this.addTag(v));
        } else {
          added.push(this.addValue(v));
        }
      });

      this.value.forEach((item => {
        const id = item?.id ?? item;
        if (!added.includes(id)) {
          this.input.tagsinput('remove',item);
        }
      }));

      return;
    }
    // Add only one tag
    if (prop && typeof prop === "string") {
      prop = prop.trim();
      prop = prop.split(/[,;]/g);
      prop.forEach((v) => {
        if (v && v.trim() !== '[object Object]') {
          this.addTag(v);
        }
      });
      return;
    }

    if (prop && Object.keys(prop).length) {
      this.addValue(prop);
    }
  }

  /**
     *
     */
  registerEvents() {

    this.wrapper.off('click').on('click', (e) => {

      let $target = $(e.target);

      this.wrapper.find('.bootstrap-tagsinput').find('input').show();

      if ($target.hasClass('tag')) {
        return;
      }

      if ($target.closest('.tag').length) {
        return;
      }

      if ($target.closest('.v2-autocomplete--drawer').length) {
        return;
      }

      if (this.isDisabled) {
        return;
      }

      this.loadValues();

    });

    this.wrapper.off('keyup').on('keyup', () => {
      this.loadData();
    });

    this.internalInput.on('keyup', () => {
      // Always reset the page
      this.page = 1;
      this.loadData();
    });

    this.drawer.on('click', '.autocompleter-value', (e) => {
      this.onAutoCompleteClick($(e.currentTarget));

      this.dispatchEvent(new CustomEvent('autocomplete-click', {}));

    });

    this.wrapper.on('click', '.tag', (e) => {
      this.onTagsClick($(e.currentTarget));

      this.dispatchEvent(new CustomEvent('tags-click', {}));

    });

    $('body').on('click', (e) => {
      let $e = $(e.target);
      if ($e.closest('.v2-autocomplete').length === 0) {
        this.closeDrawer();
      }
    });

    this.internalInput.on('keydown', this.onKeyDown.bind(this));


    this.input.on('itemRemoved', (event) => {

      this.dispatchEvent(new CustomEvent('itemRemoved', { detail: event }));
      if (typeof this.onItemRemove === "function") {
        this.onItemRemove(event);
      }
    });
    this.input.on('itemAdded', (event) => {

      this.dispatchEvent(new CustomEvent('itemAdded', { detail: event }));

      if (typeof this.onItemAdd === "function") {
        this.onItemAdd(event);
      }
    });

    this.drawer.on('scroll', (e) => {
      this.onScroll(e);
    });

  }


  onScroll(e) {

    e.preventDefault();

    if (!this.withPagination) {
      return;
    }

    if (!(this.drawer[0].scrollTop + this.drawer[0].clientHeight >= (this.drawer[0].scrollHeight - 250))) {
      return;
    }

    // Do not search if you have reached the end
    if (this.data.length >= this.maxResults) {
      return;
    }

    if (this.isLoading) {
      return;
    }

    this.page++;

    this.showPaginationLoader();

    this.loadValues(true, true, false);

  }


  showPaginationLoader() {
    this.drawer.append(`<span class="tagsinput-drawer-loader" >
<i class="fa fa-circle-notch fa-spin input-loader"></i>
<span>${trans(COMMON_WORDS_LOADING, {}, 'common')}</span>
</span>`);
  }

  onKeyDown(e) {
    if (e.key === "Tab") {
      this.closeDrawer();
    }

    if (['ArrowUp', 'ArrowDown', 'Enter'].includes(e.key)) {
      if (!this.drawer.is(':visible')) {
        e.preventDefault();
        this.showDrawer();
        return;
      }
    }

    // Do something
    if (e.key === "ArrowUp") {
      this.selectOption(-1);
    }

    if (e.key === "ArrowDown") {
      this.selectOption(+1);
    }

    if (e.key === "Enter") {
      e.preventDefault();
      const $selected = this.drawer.find('.autocompleter-value.selected');
      if ($selected.length) {
        this.onAutoCompleteClick($selected);
        $selected.removeClass('selected');
        this.closeDrawer();
      }

      if(this.freeInput && this.drawer.find('.autocompleter-value').length === 1) {
        const $selected = this.drawer.find('.autocompleter-value');
        this.onAutoCompleteClick($selected);
        this.closeDrawer();
      }

    }

  }


  selectOption(index) {

    const rows = this.drawer.find('.autocompleter-value');

    let selectedOptionIndex = rows.index(this.drawer.find('.autocompleter-value.selected'));

    selectedOptionIndex += index;
    selectedOptionIndex = Math.min(rows.length - 1, Math.max(0, selectedOptionIndex));

    const $row = $(rows[selectedOptionIndex]);

    rows.removeClass('selected');

    $row.addClass('selected');
    if ($row.get(0).getBoundingClientRect().top > this.drawer.get(0).scrollHeight) {
      this.drawer.get(0).scrollTop += $row.get(0).getBoundingClientRect().height;
    }

    if ($row.get(0).getBoundingClientRect().bottom < this.drawer.scrollHeight) {
      this.drawer.get(0).scrollTop -= $row.get(0).getBoundingClientRect().height;
    }


  }


  loadData() {
    clearTimeout(this.timeout);

    this.abortPreviousReq();

    this.timeout = setTimeout(() => {
      this.loadValues();
    }, 300);
  }

  /**
     *
     * @param silently
     * @param force
     * @param reset
     */
  async loadValues(silently, force = false, reset = true) {

    let val = this.internalInput.val();

    this.removeError();

    if (this.drawer.find('.autocompleter-value').length && !this.hasChanged() && !force) {
      if (!silently) {
        this.showDrawer();
      }
      return;
    }

    this.loader.show();

    const excluded = this.unique ?
      this.value?.id :
      this.value?.map((v) => v.id) ?? [];

    this.params = {
      ...this.params, ...{
        limit: this.limit,
        _per_page: this.limit,
        page: this.page
      }
    };

    if (excluded) {
      this.params.excluded = excluded;
    }

    // At some point, this will be removed when I
    // won't be lazy to look where it is used and use "this.searchKey" instead
    if (!this.isExternal()) {
      this.params.name = val;
    }

    this.params[this.searchKey] = val;

    this.abortPreviousReq();

    try {
      this.isLoading = true;
      const data = await this.initRequest();

      this.isLoading = false;

      if (reset) {
        this.data = [];
        this.groups = {};
        this.groupsItems = {};
        this.drawer.html('');
      }
      this.req = null;

      this.drawer.find('.tagsinput-drawer-loader').remove();

      this.parser(data, val);

      if (data['hydra:totalItems']) {
        this.maxResults = data['hydra:totalItems'];


        if (!data['hydra:member']?.length) {
          this.maxResults = this.getData().length;
        }

      }

      if (this.getData().length === 0 && !this.freeInput) {
        this.maxResults = 0;
        this.addNotFoundPanel();
      }

      if (this.freeInput) {
        this.addFreeInput(val);
      }

      this.previousVal = val;

      if (!silently || this.internalInput.is(':focus')) {
        if (!this.drawer.is(':empty')) {
          this.showDrawer();
        }
      }

      this.loader.hide();

      return this.data;
    } catch (response) {

      if (null == response) {
        return;
      }

      if (this.throwOnError) {
        throw response;
      }

      if (response.statusText === "abort") {
        return;
      }
      this.drawer.html('');
      this.loader.hide();
      this.req = null;

      if (response && response.responseJSON && response.responseJSON.error) {
        this.drawer.append('<span class="autocomplete-error" >' + response.responseJSON.error + '</span>');
      } else {
        this.drawer.append('<span class="autocomplete-error" >' +
          trans(COMMON_WORDS_ERROR_OCCURED, {}, 'common') + '</span>');
      }

      return [];
    }
  }


  showDrawer() {

    if (this.portal) {
      this.portal.show();
      this.portal.reload();

    } else {
      this.drawer.show();
    }

  }


  selectAll() {
    this.drawer.find('.autocompleter-value').each((k, elem) => {
      this.onAutoCompleteClick($(elem));
    });
  }


  abortPreviousReq() {
    // Abort previous request
    if (this.req && this.req.abort) {
      this.req.abort();
    }

  }


  /**
     *
     * @return {Promise<*>}
     */
  async initRequest() {
    if (this.choices) {
      this.req = this.selectFromChoices();
      return this.req;
    }

    if (this.disableChoices) {
      return [];
    }

    let url;

    if (this.isExternal()) {
      url = this.url + '?' + new URLSearchParams(this.params).toString();
    } else {
      url = Routing.generate(this.url, this.params);
    }

    return GET(url, null, {}, (req) => {
      this.req = req;
    }, {
      silently: this.throwOnError
    });

  }


  /**
     *
     * @return {*}
     */
  isExternal() {
    return this.url && this.url.match('https://') !== null;
  }


  /**
     * Returns the list of all the elements that are in the list
     */
  selectFromChoices() {

    let r = [];

    if (!this.params.name) {
      return this.getChoices();
    }

    this.choices.forEach((choice) => {

      let _choice = slugify(choice);

      if (_choice.includes(slugify(this.params.name.trim()))) {
        r.push(choice);
      }

    });

    return r;

  }


  /**
     *
     * @param $elem
     */
  onAutoCompleteClick($elem) {

    let val = $elem.attr('value');
    let label = $elem.data('label');
    let data = $elem.prop('data-data');

    if (val) {
      this.addTag(val, label, data);
    } else {
      // Fallback, if the tag is only a label. Ex : in case of user free input
      this.addTag(label);
    }

    this.onAutocomplete(val, data);

  }

  /**
     *
     * @param val
     */
  addValue(val) {

    if(!val) {
      return;
    }

    // Avoid reload if not needed
    if (this.unique && val === this.value) {
      return;
    }

    // Clear if the input is supposed to be unique
    if (this.unique) {
      this.clear();
    }

    this.input.tagsinput('add', val);

    this.internalInput.val('');

    this.closeDrawer();

    return val.id;

  }

  reset() {
    this.clear();
    this.req = null;
    this.data = [];
    this.page = 1;
    this.groups = {};
    this.groupsItems = {};
    this.drawer.html('');
    this.internalInput.val("");
  }

  closeDrawer() {

    if (this.portal) {
      this.portal.hide();
    } else {
      this.drawer.hide();
    }

  }


  /**
     *
     * @param id
     * @param text
     * @param data
     * @param readonly
     */
  addTag(id, text, data, readonly) {
    let val = { id: id, text: id };

    if (text) {
      val.text = text;
    }

    if (data) {
      val.data = data;
    }

    if (readonly) {
      val.readonly = true;
    }

    this.addValue(val);

    return id;

  }


  /**
     *
     * @return {boolean}
     */
  hasChanged() {

    return this.internalInput.val() !== this.previousVal;
  }


  /**
     *
     * @param data
     */
  addDrugs(data) {
    let $row = $('<span class="autocompleter-value multi-line drugs-data">' +
            '<em class="v bold">' + data.name + '</em>' +
            '<em class="sub-v" >' + data.pharmaceuticalForm + '</em></span>');
    $row.attr('value', data.cisId);
    $row.attr('data-label', data.name);
    $row.prop('data-data', data);
    return $row;
  }


  addCim11(data) {

    // We add the hierarchy only if there is a search and not the historic
    let cls = this.internalInput.val() ? `level-${data.hierarchyLevel}` : 'level-1';

    let $row = $(`<span class="autocompleter-value ${cls} cim-11" title="${data.name}">
               <i class="material-icons not-hover">radio_button_unchecked</i>
               <i class="material-icons hover">check_circle</i>
                <em class="v bold">${data.name}</em>
            </span>`);

    $row.attr('value', data.code);
    $row.attr('data-label', data.name);
    $row.prop('data-data', data);
    return $row;
  }

  /**
     *
     * @param data
     */
  addDefault(data) {


    const value = data.value ? data.value : data;
    const label = data.label ? data.label : data;

    if (label?.toString() === "[object Object]") {
      console.error(`Please select a correct parser inside your autocompleter.
             For this you need to :
              1) add the parser="parserName" attribute on the html element
              2) Add the corresponding parser in the TagsAutocompleter class; 
              with this format : add{ParserName} if it does not already exist`);
    }

    let $row = $(`<span class="autocompleter-value "><em class="v bold">${ts(label)}</em></span>`);
    $row.attr('value', value);
    $row.attr('data-label', label);
    $row.prop('data-data', data);
    return $row;
  }


  /**
     *
     * @param data
     */
  addCim10(data) {
    let $row = $(`<span class="autocompleter-value multi-line drugs-data">
                    <em class="v bold">${data.name} (${data.cim})</em>
                    <em class="sub-v" >${data.category.name}</em>
                    </span>`);

    data.id = null;

    $row.attr('value', data.cim);
    $row.attr('data-label', data.name);
    $row.prop('data-data', data);

    return $row;
  }


  /**
     *
     * @param data
     */
  addAllergens(data) {
    let $row = $('<span class="autocompleter-value multi-line drugs-data">' +
            '<em class="v bold">' + data.name + '</em>' +
            '<em class="sub-v" >' + data.group + '</em></span>');
    $row.attr('value', data.name);
    $row.attr('data-label', data.name);
    $row.prop('data-data', data);
    return $row;
  }

  /**
     *
     * @param data
     */
  addStructuredData(data) {
    let $row = $('<span class="autocompleter-value multi-line">' +
            '<em class="v bold">' + data.title + '</em>' +
            '<em class="sub-v" >' + ts("structured_data." + data.type) + '</em></span>');
    $row.attr('value', data.key);
    $row.attr('data-label', data.title);
    $row.prop('data-data', data);
    return $row;
  }


  addPatientBillingMedic(data) {

    let $row = $(`<span class="autocompleter-value multi-line drugs-data">
<em class="v bold">${data.fullName}</em>
<em class="sub-v" >${data.label}</em></span>`);
    $row.attr('value', data.id);
    $row.attr('data-label', data.fullName);
    $row.prop('data-data', data);
    return $row;
  }

  addMssanteUser(data) {
    const subtitle = [];

    if (data.specialty) {
      subtitle.push(data.specialty);
    }

    if (data.city && data.postalCode) {
      subtitle.push(data.postalCode + " " + data.city.toUpperCase());
    }

    let $row = $(`<span class="autocompleter-value multi-line drugs-data">
<em class="v bold">${data.firstName} ${data.lastName}</em>
<em class="sub-v mb-1" >${subtitle.join((', '))}&nbsp;</em><br>
<em class="sub-v" >${data.email}&nbsp;</em></span>
`);
    $row.attr('value', data.email);
    $row.attr('data-label', data.firstName + ' ' + data.lastName);
    $row.prop('data-data', data);
    return $row;

  }

  /**
     *
     * @param data
     */
  addRpps(data) {

    const subtitle = [];

    if (data.specialty) {
      subtitle.push(data.specialty);
    }

    if (data.city && data.zipcode) {
      subtitle.push(data.zipcode + " " + data.city.toUpperCase());
    }

    let $row = $(`<span class="autocompleter-value multi-line drugs-data">
<em class="v bold">${data.fullName}</em>
<em class="sub-v" >${subtitle.join((', '))}&nbsp;</em></span>`);
    $row.attr('value', data.idRpps);
    $row.attr('data-label', data.firstName + ' ' + data.lastName);
    $row.prop('data-data', data);
    return $row;
  }

  /**
     *
     * @param data
     */
  addMedicalField(data) {

    const row = `<span class="autocompleter-value multi-line drugs-data">
        <em class="v bold">${data.label}</em>
        ${data.group ? `<em class="sub-v" >${data.group}</em>` : ''}
        </span>`;

    const $row = $(row);
    $row.attr('value', data.value);
    $row.attr('data-label', data.label);
    $row.prop('data-data', data);
    return $row;
  }


  addMedicalRecordField(data) {

    const type = ts('medical_record_field_type.' + data.fieldType);

    const row = `<span class="autocompleter-value multi-line drugs-data">
        <em class="v bold">${data.name}</em>
        <em class="sub-v" >${type}</em>
        </span>`;

    const $row = $(row);
    $row.attr('value', data.id);
    $row.attr('data-label', data.name);
    $row.prop('data-data', data);
    return $row;
  }


  /**
     *
     * @param data
     */
  addVariableType(data) {
    let $row = $(`<span class="autocompleter-value multi-line drugs-data">
<em class="v bold">${data.name}</em>
<em class="sub-v" >(${data.unit})</em></span>`);
    $row.attr('value', data.id);
    $row.attr('data-label', data.name);
    $row.prop('data-data', data);
    return $row;
  }

  /**
     *
     * @param data
     */
  addTreatment(data) {
    let $row = $(`<span class="autocompleter-value"><em class="v bold">${data.name}</em></span>`);
    $row.attr('value', data.id);
    $row.attr('data-label', data.name);
    $row.prop('data-data', data);
    return $row;
  }

  /**
     *
     * @param data
     */
  pdfTemplatesParser(data) {

    data['hydra:member'].sort((a, b) => {
      return a.type.name - b.type.name;
    });

    data['hydra:member'].sort((a) => {
      return a.favorite ? -1 : 1;
    });

    this.defaultParser(data);

  }


  /**
     *
     * @param data
     */
  defaultParser(data) {

    let collection = [];

    // api platform
    if (data['hydra:member']) {
      collection = data['hydra:member'];
      // cim11, audit trail, stripe...
    } else if (data.data) {
      collection = data.data;
    } else {
      collection = data;
    }

    if (!collection) {
      return;
    }

    collection.forEach((doc) => {
      this.addTemplate(doc);
    });
  }

  /**
     *
     * @param data
     * @param select
     */
  addTemplate(data, select) {
    let $row = this.templateParser(data);

    // Only add if it's not added
    if ($row.parents('html').length === 0) {
      this.drawer.append($row);
    }

    this.data.push(data);

    if (select) {
      this.onAutoCompleteClick($row);
    }

  }

  addPdfTemplates(doc) {

    let group = doc.favorite ? "favorites" : doc.type.canonical;

    if (!this.groups[group]) {

      let name = doc.favorite ? trans(COMMON_WORDS_FAVORITE, {}, 'common') : doc.type.name;
      this.groups[group] = this.groups[group] ?? $(`<span class="autocompleter-group">${name}</span>`);
      this.drawer.append(this.groups[group]);
    }

    let shared, $row;

    if (current_user && (parseId(doc.owner['@id'], true) !== current_user.id)) {
      switch (doc.ownerType) {
        case MEDICAL_RESOURCE.OWNER_TYPE.INSTITUTION:
          shared = `Instamed`;
          break;
        case MEDICAL_RESOURCE.OWNER_TYPE.MEDICAL_TEAM:
        case MEDICAL_RESOURCE.OWNER_TYPE.MEDICS_MEDICAL_TEAM:
        case MEDICAL_RESOURCE.OWNER_TYPE.NURSES_MEDICAL_TEAM:
          shared = trans(DOCUMENTS_SHARED_BY, { '$name': doc.owner.fullName }, 'documents');
          break;

      }
    }


    if (shared) {
      $row = $(`<span class="autocompleter-value grouped multi-line">
                            <em class="v v-name bold">${doc.name}</em>
                            <em class="v owner">${shared}</em>
                        </span>`);
    } else {
      $row = $(`<span class="autocompleter-value grouped multi-line">
                            <em class="v v-name bold">${doc.name}</em>
                        </span>`);
    }

    if(doc.synapse) {
      // eslint-disable-next-line max-len
      $row.find('.v-name').append(`<img alt="Logo synapse" class="synapse" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAMAAABF0y+mAAAAe1BMVEX///83VmsuTWRccYGOm6W/xswAIEQANFEAMk8ALUwAI0YAFj91hpPr7vAAN1MAEDyIlaAACzoAKkoAJUcpSmL19vfU2Ny7wcezvMJOZngAGD8AME4iRl+eqbGVoasAHEJoeommsLgUPllEXnHc4OPL0dUAHUJUa3zh5eh4v310AAABM0lEQVR4AXWRBRLDMAwEFSY7zMzJ/19YX9mFHdJorTORjKJqukF/MC3bcT3Gf0rXB04Q/pJRbF91kmYkyIuiKK8VKMOqjmEbC73WTJKgexvO+sGGVUU9NqLQ6B2eNkjuiSakeJwkZks0a5ESIGOR5VpjtCRiGI3lYKqw2SiKxoJtt3c5DY4Tz7cMgbWrvZGv/CY5E/SocrO56jgx96FOQymDMsVE9A3L2edS0kW7O2/eVOW7bWFbD6bpxhZ0M+RohsdxVKICfM2NXh2gLQ89xWkaV6c38jTGcJwRMUcsU0liTPCpI5GB/zWN7wfyA050PWXC5bsNyO2Iyv16uVwOdu4feTiwHjvfZBklidugUuLb1eZRf+YWgo3AgWT4iH4wmbeH8egnvb3Hjf1zEqwTOyqSuAAXuRnb4AkPTQAAAABJRU5ErkJggg==" />`);
    }

    $row.attr('data-label', doc.name);
    $row.prop('data-data', doc);
    $row.attr('value', doc.id);
    this.groups[group].after($row);

    return $row;

  }


  addCerfaTemplate(doc) {

    let group = doc.favorite ? "favorites" : "other";

    if (!this.groups[group]) {
      let name = doc.favorite ?
        trans(COMMON_WORDS_FAVORITE, {}, 'common') :
        trans(COMMON_WORDS_OTHER, {}, 'common');
      this.groups[group] = this.groups[group] ?? $(`<span class="autocompleter-group">${name}</span>`);
      this.drawer.append(this.groups[group]);
      this.groupsItems[group] = [];
    }

    let subtitle = [
        doc.cerfa.name,
      ], $row;

    if (current_user && (parseId(doc.owner['@id'], true) !== current_user.id)) {
      switch (doc.ownerType) {
        case MEDICAL_RESOURCE.OWNER_TYPE.INSTITUTION:
          subtitle.push(`Instamed`);
          break;
        case MEDICAL_RESOURCE.OWNER_TYPE.MEDICAL_TEAM:
        case MEDICAL_RESOURCE.OWNER_TYPE.MEDICS_MEDICAL_TEAM:
        case MEDICAL_RESOURCE.OWNER_TYPE.NURSES_MEDICAL_TEAM:
          subtitle.push(trans(DOCUMENTS_SHARED_BY, { '$name': doc.owner.fullName }, 'documents'));
          break;

      }
    }

    $row = $(`<span class="autocompleter-value grouped multi-line">
                            <em class="v bold">${doc.name}</em>
                            <em class="sub-v">${subtitle.join(' - ')}</em>
                        </span>`);


    $row.attr('data-label', doc.name);
    $row.prop('data-data', doc);
    $row.attr('value', doc.id);

    // get last item of the group
    const item = this.groupsItems[group][this.groupsItems[group].length - 1];

    if(item) {
      item.after($row);
    } else {
      this.groups[group].after($row);
    }
    this.groupsItems[group].push($row);

    return $row;

  }


  /**
     *
     * @param data
     */
  addv2pdfTemplates(data) {
    let $row = $(`<span class="autocompleter-value multi-line drugs-data">
<em class="v bold">${data.name}</em>
<em class="sub-v" >${data.type.name}</em></span>`);
    $row.attr('value', data.id);
    $row.attr('data-label', data.name);
    $row.prop('data-data', data);
    return $row;

  }

  /**
     * @param data
     */
  addHealthProfile(data) {

    let formatSpecialties = '';
    if (data.specialties) {
      formatSpecialties = data.specialties.map(elem => elem.name);
      formatSpecialties.join(', ');
    }

    let $row = $(`<span class="autocompleter-value with-photo grouped multi-line">
                            <img alt="${data.user.fullName}">
                            <em class="v bold">${data.user.fullName}</em>
                            <em class="sub-v" >${formatSpecialties}</em>
                        </span>`);
    $row.attr('data-label', data.user.fullName);
    $row.prop('data-data', data);
    $row.attr('value', data.id);
    this.drawer.append($row);

    v2DisplayImage(
      data.user.thumbnails.profilePicture['50x50'],
      $row.find('img'),
      null,
      data.user
    );

    return $row;

  }


  /**
     * @param data
     */
  addHealthProfileWithCompany(data) {

    let $row = $(`<span class="autocompleter-value with-photo grouped multi-line">
                            <img alt="${data.user.fullName}">
                            <em class="v bold">${data.user.fullName}</em>
                            <em class="sub-v" >${data.establishmentName ?? ''}</em>
                        </span>`);
    $row.attr('data-label', data.user.fullName);
    $row.prop('data-data', data);
    $row.attr('value', data.id);
    this.drawer.append($row);

    v2DisplayImage(
      data.user.thumbnails.profilePicture['50x50'],
      $row.find('img'),
      null,
      data.user
    );

    return $row;

  }

  /**
     *
     * @param data
     */
  addPatient(data) {

    let tagLine = "";
    if (data.user.dateOfBirth) {
      tagLine = new Date(data.user.dateOfBirth).toLocaleDateString();
    }

    let $row = $(`<span class="autocompleter-value with-photo multi-line">
                            <img alt="${data.user.fullName}">
                            <em class="v bold">${data.user.fullName}</em><em class="sub-v" >${tagLine}</em>
                        </span>`);
    $row.attr('data-label', data.user.fullName);
    $row.prop('data-data', data);
    $row.attr('value', data.id);
    this.drawer.append($row);

    v2DisplayImage(
      data.user.thumbnails.profilePicture['50x50'],
      $row.find('img'),
      null,
      data.user
    );

    return $row;

  }

  /**
     *
     * @param data
     */
  addInstitution(data) {

    let $row = $(`<span class="autocompleter-value with-photo grouped">
                            <img alt="${data.name}">
                            <em class="v bold">${data.name}</em>
                        </span>`);
    $row.attr('data-label', data.name);
    $row.prop('data-data', data);
    $row.attr('value', data.id);
    this.drawer.append($row);

    v2DisplayImage(data.thumbnails.logo['30x30'], $row.find('img'), '/build/images/v2/logo-mini.svg');

    return $row;

  }


  /**
     *
     * @param data
     */
  addServices(data) {
    let $row = $(`<span class="autocompleter-value with-photo grouped multi-line">
                            <img alt="${data.name}">
                            <em class="v bold">${data.name}</em><em class="sub-v" >${data.institution.name}</em>
                        </span>`);

    $row.attr('data-label', data.name);
    $row.prop('data-data', data);
    $row.attr('value', data.id);
    this.drawer.append($row);

    v2DisplayImage(data.institution.thumbnails.logo['30x30'], $row.find('img'), '/build/images/v2/logo-mini.svg');

    return $row;

  }

  /**
     *
     * @param data
     */
  addQuestionnaire(data) {

    let subtitle = ts('questionnaire.type.' + data.type);

    let $row = $(`<span class="autocompleter-value multi-line">
                            <em class="v bold">${data.name}</em><em class="sub-v" >${subtitle}</em></span>
                        </span>`);
    $row.attr('data-label', data.name);
    $row.prop('data-data', data);
    $row.attr('value', data.id);
    return $row;
  }

  /**
     *
     * @deprecated
     *
     * @param data
     */
  addSpecialties(data) {
    return this.addNamedEntity(data);
  }

  /**
     *
     * @param data
     */
  addNamedEntity(data) {

    let $row = $(`<span class="autocompleter-value multi-line">
                            <em class="v bold">${data.name}</em>
                        </span>`);
    $row.attr('data-label', data.name);
    $row.prop('data-data', data);
    $row.attr('value', data.id);
    return $row;
  }

  /**
     *
     * @param data
     */
  addDocumentType(data) {
    let parent = "-";
    if (data.parent) {
      parent = data.parent.name;
    }

    let $row = $(`<span class="autocompleter-value multi-line">
                            <em class="v bold">${data.name}</em><em class="sub-v" >${parent}</em></span>
                        </span>`);
    $row.attr('data-label', data.name);
    $row.prop('data-data', data);
    $row.attr('value', data.id);
    return $row;
  }


  /**
     *
     * @param data
     */
  addDiscussion(data) {

    const participants = data
      .participants.filter((user) => user.id !== current_user.id)
      .map((user) => {
        return user.fullName;
      }).join(', ');

    let $row = $(`<span class="autocompleter-value multi-line">
                            <em class="v bold">${data.title}</em><em class="sub-v" >${participants}</em></span>
                        </span>`);
    $row.attr('data-label', data.title);
    $row.prop('data-data', data);
    $row.attr('value', data.id);
    return $row;
  }

  /**
     *
     * @param data
     */
  addLetterHead(data) {

    let $row = $(`<span class="autocompleter-value multi-line">
                            <img class="no-radius" alt="${data.name}">
                            <em class="v bold">${data.name}</em><em class="sub-v" >${data.owner.fullName}</em></span>
                        </span>`);
    $row.attr('data-label', data.name);
    $row.prop('data-data', data);
    $row.attr('value', data.id);

    v2DisplayImage(
      data.thumbnails.template['50x50'],
      $row.find('img'),
      '/build/images/document-thumb.jpeg',
    );

    return $row;
  }

  /**
     *
     * @param data
     */
  addEventType(data) {

    let duration = secondsToHms(data.meanDuration);

    let $row = $(`<span class="autocompleter-value multi-line">
                            <em class="v bold">${data.name}</em><em class="sub-v" >${duration}</em></span>
                        </span>`);
    $row.attr('data-label', data.name);
    $row.prop('data-data', data);
    $row.attr('value', data.id);
    return $row;

  }

  /**
     *
     * @param data
     */
  addDocumentTypeWithCanonical(data) {

    let $row = $(`<span class="autocompleter-value multi-line">
                            <em class="v bold">${data.name}</em><em class="sub-v" >${data.canonical}</em></span>
                        </span>`);
    $row.attr('data-label', data.name);
    $row.prop('data-data', data);
    $row.attr('value', data.id);
    return $row;

  }

  /**
     *
     * @param val
     */
  addFreeInput(val) {
    if (val) {
      let $row = $('<span class="autocompleter-value"><em class="v bold">' + val + '</em></span>');
      $row.attr('data-label', val);
      this.drawer.append($row);
    }
  }

  /**
     *
     * @param data
     */
  userParser(data) {

    data['hydra:member'].sort(function(a, b) {
      return a.role - b.role;
    }
    );

    this.defaultParser(data);

  }

  /**
     *
     * @param data
     */
  cim11Parser(data) {

    data['hydra:member'].sort(function(a, b) {
      // Sort by hierarchy level
      if (a.hierarchyLevel !== b.hierarchyLevel) {
        return a.hierarchyLevel - b.hierarchyLevel;
      }

      // Place 'Autres' at the end within the same hierarchy level
      if (a.name.startsWith(trans(COMMON_WORDS_OTHER, {}, 'common')) &&
        !b.name.startsWith(trans(COMMON_WORDS_OTHER, {}, 'common'))) {
        return 1;
      } else if (!a.name.startsWith(trans(COMMON_WORDS_OTHER, {}, 'common')) &&
        b.name.startsWith(trans(COMMON_WORDS_OTHER, {}, 'common'))) {
        return -1;
      }

      // Ensure names containing other names come first
      if (a.name.includes(b.name) && a.name !== b.name) {
        return 1;
      } else if (b.name.includes(a.name) && a.name !== b.name) {
        return -1;
      }

      // Final tie-breaker: alphabetical order
      return a.name.localeCompare(b.name);
    });

    this.defaultParser(data);

  }

  addUser(data) {

    if (!this.groups[data.role]) {
      this.groups[data.role] = this.groups[data.role] ??
                $('<span class="autocompleter-group">' + ts(data.role + ".plural") + '</span>');
      this.drawer.append(this.groups[data.role]);
    }

    let tagline = '';

    let services = [];
    if (data.services) {
      data.services.forEach((service) => {
        services.push(service.name);
      });
    }

    if (!isPatient(current_user)) {
      tagline += " " + services.join(", ");
    }

    if (data.dateOfBirth && isPatient(data)) {
      tagline = new Date(data.dateOfBirth).toLocaleDateString();
    }

    let $row = $(`<span class="autocompleter-value with-photo grouped multi-line v2_user_autocomplete">
                        <img alt="${data.fullName}">
                        <span>
                            <em class="v bold">${data.fullName}</em>
                            <em class="sub-v" >${tagline}</em>
                        </span>
                     </span>`);
    $row.attr('data-label', data.fullName);
    $row.prop('data-data', data);
    $row.attr('value', data.id);
    this.groups[data.role].after($row);

    v2DisplayImage(
      data.thumbnails.profilePicture['50x50'],
      $row.find('img'),
      null,
      data
    );

    return $row;

  }


  addUserWithEmail(data) {

    let $row = $(`<span class="autocompleter-value with-photo grouped multi-line v2_user_autocomplete">
                        <img alt="${data.fullName}">
                        <span>
                            <em class="v bold">${data.fullName}</em>
                            <em class="sub-v" >${data.email} - ${ts(data.role)}</em>
                        </span>
                     </span>`);
    $row.attr('data-label', data.fullName);
    $row.prop('data-data', data);
    $row.attr('value', data.id);

    v2DisplayImage(
      data.thumbnails.profilePicture['50x50'],
      $row.find('img'),
      null,
      data
    );

    return $row;

  }


  addNotFoundPanel() {
    let message;

    if (this.notFoundText) {
      message = this.notFoundText;
    } else {
      switch (this._parser) {
        case "rpps":
          message = trans(USER_ERROR_NOT_FOUND_DOCTOR, {}, 'user');
          break;
        case "cerfaTemplate":
          message = trans(USER_ERROR_NOT_FOUND_TEMPLATE, {}, 'user');
          break;
        case "user":
        case "healthProfile":
        case "healthProfileWithCompany":
        case "patient":
          message = trans(USER_ERROR_NOT_FOUND_USER, {}, 'user');
          break;
        case "drugs":
          message = trans(USER_ERROR_NOT_FOUND_DRUGS, {}, 'user');
          break;
        case "letterHead":
          message = trans(USER_ERROR_NOT_FOUND_LETTERHEAD, {}, 'user');
          break;
        case "questionnaire":
          message = trans(USER_ERROR_NOT_FOUND_QUESTIONNAIRE, {}, 'user');
          break;
        case "specialties":
          message = trans(USER_ERROR_NOT_FOUND_SPECIALITY, {}, 'user');
          break;
        case "institution":
          message = trans(USER_ERROR_NOT_FOUND_INSTITUTION, {}, 'user');
          break;
        case "variableType":
          message = trans(USER_ERROR_NOT_FOUND_VARIABLE, {}, 'user');
          break;
        case "pdfTemplates":
          message = trans(USER_ERROR_NOT_FOUND_DOC_TEMPLATE, {}, 'user');
          break;
        case "allergens":
          message = trans(USER_ERROR_NOT_FOUND_ALLERGEN, {}, 'user');
          break;
        case "cim11":
          if (!this.internalInput.val()) {
            message = trans(PATIENT_DISEASE_SEARCH, {}, 'patient');
          } else {
            message = trans(USER_ERROR_NOT_FOUND_DISEASE, {}, 'user');
          }
          break;
        case "documentType":
        case "documentTypeWithCanonical":
          message = trans(USER_ERROR_NOT_FOUND_DOC_TYPE, {}, 'user');
          break;
      }
    }

    if (message) {
      this.drawer.append('<span class="autocomplete-error" >' + message + '</span>');
    }

  }


  initValueFromQuery() {

    let s = new URLSearchParams(document.location.search);
    this.addValueFromString(s.get(this.input.attr('name')));
  }

  addValueFromString(val) {
    if (val) {
      val = val.split(',');
      val.forEach((v) => {
        this.addTag(v, v);
      });
    }
  }


  removeError() {
    this.wrapper.removeClass('with-error');
    this.input.removeClass('with-error');
    this.wrapper.find('.form-error').remove();
  }

  addError(error) {
    this.wrapper.addClass('with-error');
    this.input.addClass('with-error');
    this.input.after(`<span class="form-error" >${error}</span>`);
  }


  /**
     * Clears the input
     */
  clear() {
    this.input.tagsinput('removeAll');
    this.removeError();
  }


  getChoices() {
    if (Array.isArray(this.choices)) {
      return this.choices;
    }

    return Object.keys(this.choices).map((key) => {
      return {
        value: key,
        label: this.choices[key]
      };
    });

  }


}
