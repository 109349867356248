import intlTelInput from 'intl-tel-input';
import Cleave from 'cleave.js';
import $ from 'jquery';

import {
  createDatePicker,
  DELETE,
  POST,
  setToken,
  createRoundedAvatar, destroyImpersonationId
} from "./common";
import tinymce from "tinymce";
import moment from "moment";
import DocumentUploader from "./documents/document-uploader";
import { PROFILE } from "./utils";
import {
  COMMON_ACTIONS_CLOSE,
  COMMON_ACTIONS_CLOSE_WITHOUT_EDIT,
  COMMON_ACTIONS_ERASE, COMMON_ACTIONS_RESET,
  COMMON_TIME_DAYS_FRI,
  COMMON_TIME_DAYS_MIN_FRI,
  COMMON_TIME_DAYS_MIN_MON,
  COMMON_TIME_DAYS_MIN_SAT,
  COMMON_TIME_DAYS_MIN_SUN,
  COMMON_TIME_DAYS_MIN_THURS,
  COMMON_TIME_DAYS_MIN_TUES,
  COMMON_TIME_DAYS_MIN_WED,
  COMMON_TIME_DAYS_MON,
  COMMON_TIME_DAYS_SAT,
  COMMON_TIME_DAYS_SHORT_FRI,
  COMMON_TIME_DAYS_SHORT_MON,
  COMMON_TIME_DAYS_SHORT_SAT,
  COMMON_TIME_DAYS_SHORT_SUN,
  COMMON_TIME_DAYS_SHORT_THURS,
  COMMON_TIME_DAYS_SHORT_TUES,
  COMMON_TIME_DAYS_SHORT_WED,
  COMMON_TIME_DAYS_SUN,
  COMMON_TIME_DAYS_THURS,
  COMMON_TIME_DAYS_TUES,
  COMMON_TIME_DAYS_WED,
  COMMON_TIME_JS_FIRST_WEEK_DAY,
  COMMON_TIME_JS_SELECT,
  COMMON_TIME_MONTHS_APRIL,
  COMMON_TIME_MONTHS_AUG,
  COMMON_TIME_MONTHS_DEC,
  COMMON_TIME_MONTHS_FEB,
  COMMON_TIME_MONTHS_JAN,
  COMMON_TIME_MONTHS_JULY,
  COMMON_TIME_MONTHS_JUNE,
  COMMON_TIME_MONTHS_MARS,
  COMMON_TIME_MONTHS_MAY,
  COMMON_TIME_MONTHS_NOV,
  COMMON_TIME_MONTHS_OCT,
  COMMON_TIME_MONTHS_SEPT,
  COMMON_TIME_MONTHS_SHORT_APRIL,
  COMMON_TIME_MONTHS_SHORT_AUG,
  COMMON_TIME_MONTHS_SHORT_DEC,
  COMMON_TIME_MONTHS_SHORT_FEB,
  COMMON_TIME_MONTHS_SHORT_JAN,
  COMMON_TIME_MONTHS_SHORT_JULY,
  COMMON_TIME_MONTHS_SHORT_JUNE,
  COMMON_TIME_MONTHS_SHORT_MARS,
  COMMON_TIME_MONTHS_SHORT_MAY,
  COMMON_TIME_MONTHS_SHORT_NOV,
  COMMON_TIME_MONTHS_SHORT_OCT,
  COMMON_TIME_MONTHS_SHORT_SEPT,
  COMMON_TIME_MONTHS_SHOW_NEXT,
  COMMON_TIME_MONTHS_SHOW_OTHER,
  COMMON_TIME_MONTHS_SHOW_PREV,
  COMMON_TIME_SELECT_DATE,
  COMMON_TIME_SHOW_OTHER_YEAR,
  COMMON_TIME_TODAY,
  COMMON_WORDS_NEXT,
  COMMON_WORDS_NEXT_SHORT,
  COMMON_WORDS_PREV,
  COMMON_WORDS_PREV_SHORT,
  DOCUMENTS_HELPER_ERROR_IMAGE_SIZE,
  DOCUMENTS_HELPER_ERROR_IMAGE_TYPE, DOCUMENTS_HELPER_REMOVE_VALUE, PATIENT_BLOOD_TEST_DELETE_MARK,
  PATIENT_BLOOD_TEST_MARK_AS, USER_FIELDS_INVALID
} from "../../../var/translations";
import { trans } from "../../translator";

$('.delete-img').on('click', function() {
  let img = $(this).attr('data-target');
  let imgElement = $('#' + img);
  imgElement.remove();
  $(this).hide();
});

if ($.datepicker) {
  $.datepicker.regional['fr'] = {
    clearText: trans(COMMON_ACTIONS_ERASE, {}, 'common'),
    clearStatus: '',
    closeStatus: trans(COMMON_ACTIONS_CLOSE_WITHOUT_EDIT, {}, 'common'),
    prevText: trans(COMMON_WORDS_PREV_SHORT, {}, 'common'),
    prevStatus: trans(COMMON_TIME_MONTHS_SHOW_PREV, {}, 'common'),
    nextText: trans(COMMON_WORDS_NEXT_SHORT, {}, 'common'),
    nextStatus: trans(COMMON_TIME_MONTHS_SHOW_NEXT, {}, 'common'),
    monthStatus: trans(COMMON_TIME_MONTHS_SHOW_OTHER, {}, 'common'),
    yearStatus: trans(COMMON_TIME_SHOW_OTHER_YEAR, {}, 'common'),
    weekHeader: 'Sm', weekStatus: '',
    closeText: trans(COMMON_ACTIONS_CLOSE, {}, 'common'),
    currentText: trans(COMMON_TIME_TODAY, {}, 'common'),
    monthNames: [
      trans(COMMON_TIME_MONTHS_JAN, {}, 'common'),
      trans(COMMON_TIME_MONTHS_FEB, {}, 'common'),
      trans(COMMON_TIME_MONTHS_MARS, {}, 'common'),
      trans(COMMON_TIME_MONTHS_APRIL, {}, 'common'),
      trans(COMMON_TIME_MONTHS_MAY, {}, 'common'),
      trans(COMMON_TIME_MONTHS_JUNE, {}, 'common'),
      trans(COMMON_TIME_MONTHS_JULY, {}, 'common'),
      trans(COMMON_TIME_MONTHS_AUG, {}, 'common'),
      trans(COMMON_TIME_MONTHS_SEPT, {}, 'common'),
      trans(COMMON_TIME_MONTHS_OCT, {}, 'common'),
      trans(COMMON_TIME_MONTHS_NOV, {}, 'common'),
      trans(COMMON_TIME_MONTHS_DEC, {}, 'common'),
    ],
    monthNamesShort: [
      trans(COMMON_TIME_MONTHS_SHORT_JAN, {}, 'common'),
      trans(COMMON_TIME_MONTHS_SHORT_FEB, {}, 'common'),
      trans(COMMON_TIME_MONTHS_SHORT_MARS, {}, 'common'),
      trans(COMMON_TIME_MONTHS_SHORT_APRIL, {}, 'common'),
      trans(COMMON_TIME_MONTHS_SHORT_MAY, {}, 'common'),
      trans(COMMON_TIME_MONTHS_SHORT_JUNE, {}, 'common'),
      trans(COMMON_TIME_MONTHS_SHORT_JULY, {}, 'common'),
      trans(COMMON_TIME_MONTHS_SHORT_AUG, {}, 'common'),
      trans(COMMON_TIME_MONTHS_SHORT_SEPT, {}, 'common'),
      trans(COMMON_TIME_MONTHS_SHORT_OCT, {}, 'common'),
      trans(COMMON_TIME_MONTHS_SHORT_NOV, {}, 'common'),
      trans(COMMON_TIME_MONTHS_SHORT_DEC, {}, 'common'),
    ],
    dayNames: [
      trans(COMMON_TIME_DAYS_MON, {}, 'common'),
      trans(COMMON_TIME_DAYS_TUES, {}, 'common'),
      trans(COMMON_TIME_DAYS_WED, {}, 'common'),
      trans(COMMON_TIME_DAYS_THURS, {}, 'common'),
      trans(COMMON_TIME_DAYS_FRI, {}, 'common'),
      trans(COMMON_TIME_DAYS_SAT, {}, 'common'),
      trans(COMMON_TIME_DAYS_SUN, {}, 'common')
    ],
    dayNamesShort: [
      trans(COMMON_TIME_DAYS_SHORT_MON, {}, 'common'),
      trans(COMMON_TIME_DAYS_SHORT_TUES, {}, 'common'),
      trans(COMMON_TIME_DAYS_SHORT_WED, {}, 'common'),
      trans(COMMON_TIME_DAYS_SHORT_THURS, {}, 'common'),
      trans(COMMON_TIME_DAYS_SHORT_FRI, {}, 'common'),
      trans(COMMON_TIME_DAYS_SHORT_SAT, {}, 'common'),
      trans(COMMON_TIME_DAYS_SHORT_SUN, {}, 'common')
    ],
    dayNamesMin: [
      trans(COMMON_TIME_DAYS_MIN_MON, {}, 'common'),
      trans(COMMON_TIME_DAYS_MIN_TUES, {}, 'common'),
      trans(COMMON_TIME_DAYS_MIN_WED, {}, 'common'),
      trans(COMMON_TIME_DAYS_MIN_THURS, {}, 'common'),
      trans(COMMON_TIME_DAYS_MIN_FRI, {}, 'common'),
      trans(COMMON_TIME_DAYS_MIN_SAT, {}, 'common'),
      trans(COMMON_TIME_DAYS_MIN_SUN, {}, 'common')
    ],
    dayStatus: trans(COMMON_TIME_JS_FIRST_WEEK_DAY, {}, 'common'),
    dateStatus: trans(COMMON_TIME_JS_SELECT, {}, 'common'),
    dateFormat: 'dd/mm/yy', firstDay: 0,
    initStatus: trans(COMMON_TIME_SELECT_DATE, {}, 'common'),
    isRTL: false
  };
  $.datepicker.setDefaults($.datepicker.regional['fr']);
}
$(document).ready(function() {

  // eslint-disable-next-line no-undef
  if (!isImpersonating) {
    destroyImpersonationId();
  }

  $(document).on('dragenter', '#dropfile', function() {
    $(this).css('border', '3px dashed red');
    return false;
  });


  $('.v5-tinymce').each((index, elem) => {
    handleTinyMCE($(elem));

  });

  $('.small-tab').each(function() {

    let display = $(this).css('display');

    $(this).css('display', 'block');

    $(this).find('label[data-help]').each(function() {

      const helpValue = $(this).attr('data-help');

      if (!helpValue) {
        $(this).removeAttr("data-help");
        return;
      }
      let $helper = $(`<span class="help" >${helpValue}</span>`);
      $(this).append($helper);

      $helper.css('position', 'relative');
    });

    $(this).css('display', display);


  });


  $(document).on('dragover', '#dropfile', function(e) {
    e.preventDefault();
    e.stopPropagation();
    $(this).css('border', '3px dashed red');

    return false;
  });

  $(document).on('dragleave', '#dropfile', function(e) {

    e.preventDefault();
    e.stopPropagation();
    $(this).css('border', '2px dashed #BBBBBB');

    return false;
  });

  $(document).on('drop', '#dropfile', function(e) {

    if (e.originalEvent.dataTransfer) {
      if (e.originalEvent.dataTransfer.files.length) {
        // Stop the propagation of the event
        e.preventDefault();
        e.stopPropagation();
        $(this).css('border', '2px dashed green');
        // Main function to upload

        upload(e.originalEvent.dataTransfer.files[0]);
      }
    } else {
      $(this).css('border', '2px dashed #BBBBBB');
    }

    return false;
  });

  function upload(f) {

    if (!f) {
      return;
    }

    // Only process image files.
    if (!f.type.match('image/jpeg') && !f.type.match('image/png')) {
      alert(trans(DOCUMENTS_HELPER_ERROR_IMAGE_TYPE, {}, 'documents'));

      return false;
    }

    // max weight.
    if (f.size > 3000000) {
      alert(trans(DOCUMENTS_HELPER_ERROR_IMAGE_SIZE, {}, 'documents'));

      return false;
    }

    var reader = new FileReader();

    reader.onload = function() {
      var dataURL = reader.result;

      var html = '<img src="' + dataURL + '" title="Drop image here" />';
      html += '<input type="hidden" name="imageName" value="' + f.name + '" /> ';
      html += '<input type="hidden" name="imageType" value="' + f.type + '" /> ';
      html += '<input type="hidden" name="imageBase64Src" value="' + dataURL + '" /> ';

      $('#dropfile').html(html);
    };

    // Read in the image file as a data URL.
    reader.readAsDataURL(f);
  }

  $('#dropfile').click(function(e) {
    e.preventDefault();
    e.stopPropagation();
    $('#fileMechanisme').click();
  });

  $('#fileMechanisme').change(function(event) {
    var input = event.target;
    upload(input.files[0]);
  });


  if ($.datepicker) {
    $(".dateOfBirth").datepicker({
      closeText: trans(COMMON_ACTIONS_CLOSE, {}, 'common'),
      prevText: trans(COMMON_WORDS_NEXT, {}, 'common'),
      nextText: trans(COMMON_WORDS_PREV, {}, 'common'),
      currentText: trans(COMMON_TIME_TODAY, {}, 'common'),
      monthNames: [
        trans(COMMON_TIME_MONTHS_JAN, {}, 'common'),
        trans(COMMON_TIME_MONTHS_FEB, {}, 'common'),
        trans(COMMON_TIME_MONTHS_MARS, {}, 'common'),
        trans(COMMON_TIME_MONTHS_APRIL, {}, 'common'),
        trans(COMMON_TIME_MONTHS_MAY, {}, 'common'),
        trans(COMMON_TIME_MONTHS_JUNE, {}, 'common'),
        trans(COMMON_TIME_MONTHS_JULY, {}, 'common'),
        trans(COMMON_TIME_MONTHS_AUG, {}, 'common'),
        trans(COMMON_TIME_MONTHS_SEPT, {}, 'common'),
        trans(COMMON_TIME_MONTHS_OCT, {}, 'common'),
        trans(COMMON_TIME_MONTHS_NOV, {}, 'common'),
        trans(COMMON_TIME_MONTHS_DEC, {}, 'common'),
      ],
      monthNamesShort: [
        trans(COMMON_TIME_MONTHS_SHORT_JAN, {}, 'common'),
        trans(COMMON_TIME_MONTHS_SHORT_FEB, {}, 'common'),
        trans(COMMON_TIME_MONTHS_SHORT_MARS, {}, 'common'),
        trans(COMMON_TIME_MONTHS_SHORT_APRIL, {}, 'common'),
        trans(COMMON_TIME_MONTHS_SHORT_MAY, {}, 'common'),
        trans(COMMON_TIME_MONTHS_SHORT_JUNE, {}, 'common'),
        trans(COMMON_TIME_MONTHS_SHORT_JULY, {}, 'common'),
        trans(COMMON_TIME_MONTHS_SHORT_AUG, {}, 'common'),
        trans(COMMON_TIME_MONTHS_SHORT_SEPT, {}, 'common'),
        trans(COMMON_TIME_MONTHS_SHORT_OCT, {}, 'common'),
        trans(COMMON_TIME_MONTHS_SHORT_NOV, {}, 'common'),
        trans(COMMON_TIME_MONTHS_SHORT_DEC, {}, 'common'),
      ],
      dayNames: [
        trans(COMMON_TIME_DAYS_MON, {}, 'common'),
        trans(COMMON_TIME_DAYS_TUES, {}, 'common'),
        trans(COMMON_TIME_DAYS_WED, {}, 'common'),
        trans(COMMON_TIME_DAYS_THURS, {}, 'common'),
        trans(COMMON_TIME_DAYS_FRI, {}, 'common'),
        trans(COMMON_TIME_DAYS_SAT, {}, 'common'),
        trans(COMMON_TIME_DAYS_SUN, {}, 'common')
      ],
      dayNamesShort: [
        trans(COMMON_TIME_DAYS_SHORT_MON, {}, 'common'),
        trans(COMMON_TIME_DAYS_SHORT_TUES, {}, 'common'),
        trans(COMMON_TIME_DAYS_SHORT_WED, {}, 'common'),
        trans(COMMON_TIME_DAYS_SHORT_THURS, {}, 'common'),
        trans(COMMON_TIME_DAYS_SHORT_FRI, {}, 'common'),
        trans(COMMON_TIME_DAYS_SHORT_SAT, {}, 'common'),
        trans(COMMON_TIME_DAYS_SHORT_SUN, {}, 'common')
      ],
      dayNamesMin: [
        trans(COMMON_TIME_DAYS_MIN_MON, {}, 'common'),
        trans(COMMON_TIME_DAYS_MIN_TUES, {}, 'common'),
        trans(COMMON_TIME_DAYS_MIN_WED, {}, 'common'),
        trans(COMMON_TIME_DAYS_MIN_THURS, {}, 'common'),
        trans(COMMON_TIME_DAYS_MIN_FRI, {}, 'common'),
        trans(COMMON_TIME_DAYS_MIN_SAT, {}, 'common'),
        trans(COMMON_TIME_DAYS_MIN_SUN, {}, 'common')
      ],
      weekHeader: 'Sem.',

      dateFormat: 'dd/mm/yy',
      firstDay: 1,
      autoSize: true,
      showWeek: false,

      changeYear: true,
      yearRange: "-100:+0"
    });

    createDatePicker($(".datepicker"));
  }


  $('#deleteNewAddress').click(function() {
    $('.newAddressRequired').removeAttr('required');

    return true;
  });


  $('#addDocument').click(function(event) {
    event.preventDefault();
    event.stopPropagation();
    $('#documentMechanisme').click();
  });

  $('#documentMechanisme').change(function() {
    $(this).parent().submit();
  });

  $('table.documents span.update').click(function() {
    $('#update-action input[name="newName"]').val($(this).data('name'));
    $('#update-action input[name="documentId"]').val($(this).data('id'));
    $('#update-action select[name="type"]').val($(this).data('type'));

    $('#delete-action').hide();
    $('#update-action').show();
    $('#popin-action').show();
  });


  $('table.documents span.blood-test').click(function() {

    if ($(this).hasClass('enabled')) {
      $(this).removeClass('enabled');
      $(this).attr('title', trans(PATIENT_BLOOD_TEST_MARK_AS, {}, 'patient'));
    } else {
      $(this).addClass('enabled');
      $(this).attr('title', trans(PATIENT_BLOOD_TEST_DELETE_MARK, {}, 'patient'));
    }

    $.ajax($(this).data('url'));
  });

  $('#popin-action .popin-header i').click(function() {
    $('#popin-action').hide();
  });


  $('[suffix]').each(function() {

    let suffix = $(this).attr('suffix');
    $(this).after('<span class="suffix" >' + suffix + '</span>');

  });

  $('[only_if]').each(function() {

    let only_if = JSON.parse($(this).attr('only_if'));

    let $this = $(this);
    let input = $('[name*="[' + only_if[0] + ']"]');
    let suffix = $(this).parent().find('.suffix');

    input.on('change', function() {

      let $_this = $(this);

      let val = false;

      if ($_this.is(':checkbox')) {
        val = $_this.is(':checked');
      }
      if ($_this.is(':radio')) {

        if ($_this.is(':checked')) {
          val = $_this.val() == only_if[1];
        }
      } else {
        val = val == only_if[1];
      }


      if (val) {
        suffix.show();
        $this.show();
      } else {
        suffix.hide();
        $this.hide();
      }
    });

    input.trigger('change');

  });


  $('[norms]').on('keyup', handleNorms);
  $('[norms]').on('change', handleNorms);

  function handleNorms() {
    let norms = JSON.parse($(this).attr('norms'));

    let val = parseFloat($(this).val());

    if (!val) {
      $(this).removeClass('on-error');
    }

    if (val > norms[0] && val < norms[1]) {
      $(this).removeClass('on-error');
    } else {
      $(this).addClass('on-error');
    }
  }


  $('.icon-info').on('click', function() {
    let popin = $('#popin-' + $(this).attr('target'));

    popin.fadeIn(200);

  });


  createMonthYearPicker($('.month-year-date'));

});

$(document).ready(() => {

  let $pwd = $('input[type="password"]');

  $pwd.each((key, input) => {

    let $icon = $('<i class="fa fa-eye pwd-icon" ></i>');
    let $input = $(input);
    $input.after($icon);

    $icon.on('click', () => {
      let makeVisible = $icon.hasClass('fa-eye');

      $icon.toggleClass('fa-eye', !makeVisible);
      $icon.toggleClass('fa-eye-slash', makeVisible);

      $input.attr('type', makeVisible ? "text" : "password");

    });


  });


});


export const initInputFormat = ($elem) => {

  let format = $elem.attr('format');

  let numericOnly = !$elem.attr('allow_txt');

  if (!format) {
    return;
  }

  let blocks = format.split(' ');

  blocks.forEach((block, key) => {
    blocks[key] = block.length;
  });


  new Cleave($elem.get(0), {
    numericOnly: numericOnly,
    blocks: blocks
  });

};


$(document).ready(function() {

  let tels = $('input[format]');

  tels.each((key, elem) => {

    const $elem = $(elem);

    initInputFormat($elem);

  });


  $('input[type="submit"]').on('click', function() {
    $('.form-error').remove();
  });

  $('form input, form textarea, form select').on('invalid', function(e) {

    let element = $(e.target);

    let container = element.closest('[class^="col-sm"]');

    let error = '<span class="form-error error" >' + trans(USER_FIELDS_INVALID, {}, 'user') + '</span>';

    container.append(error);

  });

  $('canvas.signature').each(function(k, canvas) {

    var signaturePad = new window.SignaturePad(canvas);

    let url = $(canvas).attr('img');

    if (url) {
      signaturePad.fromDataURL(url);
    }

    signaturePad.onEnd = () => {
      signaturePad.blured = true;

    };

    $(canvas).data('pad', signaturePad);

    const clear = $(`
                <button type="button" class="reinit btn btn-light">
                  <span class="material-icons">refresh</span>
                  <span class="text">` + trans(COMMON_ACTIONS_RESET, {}, 'common') + `</span>
                </button>
              `);

    $(canvas).after(clear);

    clear.on('click', function() {
      signaturePad.clear();
      signaturePad.blured = false;
    });

  });

  $('form').on('submit', function() {

    $('canvas.signature').each(function() {

      let pad = $(this).data('pad');

      if (pad.blured) {
        let name = $(this).attr('name');

        $(this).after('<input type="hidden" name="' + name + '" value="' + pad.toDataURL() + '" >');
      }
    });
  });


  $('select[multiple]').each((k, e) => {

    let $e = $(e);

    let help = trans(DOCUMENTS_HELPER_REMOVE_VALUE, {}, 'documents');

    if (help) {
      $e.after('<span class="help" >' + help + '</span>');
    }


  });

  $('input.v2__input_subText').each((k, e) => {

    let $e = $(e);
    let help = $(e).attr('subText');

    if (help) {
      $e.after('<span class="help" >' + help + '</span>');
    }


  });


});


export const initTelInput = ($elem) => {

  if ($elem.prop('intlTelInput')) {
    return;
  }
  let tel = intlTelInput($elem.get(0), {
    initialCountry: "FR",
    utilsScript: "/build/libs/utils.min.js",
  });

  $elem.prop('intlTelInput', tel);

};

$(document).ready(function() {

  $('span.help').each((k, v) => {
    let $elem = $(v);
    let label = $elem.closest('label');
    label.css('padding-bottom', $elem.height());
  });

  $('select + span.help').each((k, v) => {
    let $elem = $(v);
    let $select = $elem.prev();
    $select.css('margin-bottom', $elem.height());
  });

  $('input[type="tel"]').each((k, elem) => {

    let $elem = $(elem);

    initTelInput($elem);

  });


  $('form').on('submit', (e) => {

    let $form = $(e.target);

    $form.find('input[type="tel"]').each((k, elem) => {

      let $elem = $(elem);

      try {
        const intlTelInput = $elem.prop('intlTelInput');
        let number = intlTelInput.getNumber(intlTelInputUtils.numberFormat.E164);
        $elem.val(number);
      } catch (e) {
        console.error('Error while loading tel input');
      }


    });

  });


});


export function fillField(input, value,options = {}) {
  if (input.prop('autocompletor')) {
    input.prop('autocompletor').value = value;
    return;
  }

  if (input.closest('.bootstrap-tagsinput').length) {
    // Do nothing
    return;
  }

  if (input.attr('type') === 'checkbox') {

    if (input.length === 1) {
      input.prop('checked', value);
    } else {

      if(value.includes(';') && options?.splitSemicolon) {
        value = value.split(';');
      }

      if (!Array.isArray(value)) {
        value = [value];
      }

      input.each((index, elem) => {
        let $elem = $(elem);
        if (value.includes($elem.val())) {
          $elem.prop('checked', true);
        }

      });
    }
    return;
  }

  if (input.attr('type') === 'radio') {

    input.each((index, elem) => {
      let $elem = $(elem);

      if ($elem.val() === value?.toString()) {
        $elem.prop('checked', true);
      }
    });
    return;
  }

  if (input.attr('type') === 'date') {
    if (value) {
      input.val(formatDate(value));
    } else {
      input.val('');
    }
    return;
  }

  if (input.hasClass('hasDatepicker')) {
    if (value) {
      let date = new Date(value);
      input.datepicker("setDate", date);
      return;
    }

  }

  if (input.hasClass('datetimepicker')) {
    if (value) {
      let date = new Date(value);
      input.val(date.toLocaleString());
      input.datetimepicker('setDate', date);
      input.datetimepicker('update');
      input.data('date', value);
      return;
    }

  }

  input.val(value);
}


/**
 * @param input
 * @param  options {undefined|string|{getCheckboxValue : undefined|boolean,dateFormat : undefined|string}}
 * @return {*|string|string|Element|*[]|null|number}
 */
export function getFieldValue(input, options = {}) {

  // Backward compatibility
  if (typeof options === "string") {
    options = {
      "dateFormat": options,
    };
  }

  if (!options) {
    options = {};
  }

  if (input.prop('autocompletor')) {


    if(options.autocompletorGetId) {
      const v = input.prop('autocompletor').value;

      if(typeof v === "string" || !v) {
        return v;
      }

      if(v?.id) {
        return v?.id;
      }

      if(Array.isArray(v)) {
        return v.map((item) => {

          if(item?.id) {
            return item?.id;
          }

          return item;

        });
      }

      return v;

    } else {
      return input.prop('autocompletor').value;
    }
  }


  if (input.prop('field')) {
    return input.prop('field').fieldValue;
  }


  if (input.prop('intlTelInput')) {
    try {
      let intlTelInput = input.prop('intlTelInput');
      return intlTelInput.getNumber(intlTelInputUtils.numberFormat.E164);
    } catch (e) {
      console.error(e);
      return input.val();
    }
  }

  if (input.attr('type') === 'checkbox') {
    if (input.length === 1) {
      if (input.is(':checked')) {
        return options.getCheckboxValue ? input.val() : true;
      }
      return options.getCheckboxValue ? "" : false;
    } else {
      let v = [];

      input.each((index, elem) => {
        let $elem = $(elem);

        if ($elem.is(':checked')) {
          v.push($elem.val());
        }
      });

      return v;

    }
  }

  if (input.attr('type') === 'radio') {

    let v = null;

    let $inputs = input.closest('form').find(`input[name="${input.attr('name')}"]`);
    if (!$inputs.length) {
      $inputs = input;
    }

    $inputs.each((index, elem) => {
      let $elem = $(elem);
      if ($elem.is(':checked')) {

        v = $elem.val();

        if (v === "true") {
          v = true;
        }

        if (v === "false") {
          v = false;
        }
      }
    });

    return v;

  }

  if (input.attr('type') === 'date') {
    let val = input.val();

    if (!val) {
      return null;
    }
  }

  if (input.hasClass('hasDatepicker')) {

    if (input.data('date')) {
      return input.data('date');
    }

    if (!options.dateFormat) {
      return input.datepicker("getDate");
    } else {

      let date = input.datepicker("getDate");

      if (!date) {
        return date;
      }

      return $.datepicker.formatDate(options.dateFormat, new Date(date));
    }

  }

  if (input.hasClass('datetimepicker')) {

    if (input.data('date')) {
      return input.data('date');
    }
  }

  if (input.attr('type') === 'number') {
    const val = parseFloat(input.val());

    return isNaN(val) ? null : val;

  }

  if (input.attr('type') === "file") {
    return input[0].files;
  }

  return input.val();
}


/**
 *
 * @param date
 * @return {string|null}
 */
function formatDate(date) {

  if (!date) {
    return null;
  }

  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) {
    month = '0' + month;
  }
  if (day.length < 2) {
    day = '0' + day;
  }

  return [year, month, day].join('-');
}


/**
 * @param input
 * @param date
 */
export function createMonthYearPicker(input, date) {

  if (!date) {
    date = input.val();
  }

  if ($.datepicker) {
    input.datepicker({
      changeMonth: true,
      changeYear: true,
      showButtonPanel: true,
      dateFormat: 'mm/yy',
      maxDate: "+1y",
      yearRange: "c-100:c+1",
      regional: 'fr',
      beforeShow: (input, inst) => {
        $(inst.dpDiv).addClass('month-year-datepicker');
      },
      onClose: (dateText, inst) => {

        let date;

        // User input something in the input
        // lastVal is for initialisation
        if (((inst.currentYear === inst.selectedYear &&
                        inst.selectedMonth === inst.currentMonth)
                    || !inst.lastVal) && dateText) {
          let d = dateText.split(/[./-]/);
          date = new Date(d[1], parseInt(d[0]) - 1, 1);

        } else {
          date = new Date(inst.selectedYear, inst.selectedMonth, 1);
        }

        input.data('date', date);
        $(input).datepicker('setDate', date);
      }
    });
  }
  fillField(input, date);
}


export function initUpgradePopins() {
  $('a[href="#upgrade-popin"],a[href="#upgrade-patient-billing-popin"]').off('click').on('click', (e) => {
    e.preventDefault();

    $($(e.currentTarget).attr('href')).show();
  });
}


$(document).ready(() => {

  initUpgradePopins();

  $('#logout-btn').on('click', async (e) => {

    e.preventDefault();

    let $elem = $(e.currentTarget);
    $elem.find('.logout-btn').hide();
    $elem.find('.logout-loader').show();

    // Add a timeout in order to redirect no matter what
    setTimeout(() => {
      document.location.href = $elem.attr('href');
    }, 3000);

    try {
      let promises = [];
      promises.push(DELETE(Routing.generate('api_logout')));


      await Promise.all(promises);
    } catch (e) {
      console.warn(e);
    }
    document.location.href = $elem.attr('href');

  });

  $('.switch-institution').on('click', async (e) => {

    e.preventDefault();

    let $elem = $(e.currentTarget);

    let $loader = $elem.closest('.dropdown-institutions').find('.loader');

    $loader.show();

    let subdomain = $elem.attr('institution');

    if (!subdomain) {
      subdomain = null;
    }

    try {
      let data = await POST(Routing.generate('api_institution_switch'), {
        institution: subdomain,
      });

      setToken(data.token);

      if (data.environmentType === "domain") {

        // By default, if null we redirect the user to the main subdomain
        if (!subdomain) {
          subdomain = main_domain.split('.')[0];
        }

        document.location.href = `${data.fullDomain}${document.location.pathname}`;
      } else {
        document.location.reload();
      }
    } catch (e) {
      console.warn(e);
      $loader.hide();
    }
  });

  // User Avatar
  const usersAvatar = $('.v2_user_avatar');
  usersAvatar.each((ind, img) => {
    const $img = $(img);
    if (!$img.attr('src')) {
      createRoundedAvatar(
        $img,
        {
          id: $img.attr('data-roundedAvatar-user-id'),
          firstName: $img.attr('data-roundedAvatar-user-firstname'),
          lastName: $img.attr('data-roundedAvatar-user-lastname'),
          aliasName: $img.attr('data-roundedAvatar-user-aliasname'),
          fullLastName: $img.attr('data-roundedAvatar-user-fullLastName')
        });
    }

  });
});

/**
 *
 * @param form
 */
export async function handleTinyMCE(form) {

  if (!form.hasClass('v5-tinymce')) {
    form = form.find('.v5-tinymce');
  }

  let id = form.attr('id');

  if (!id) {
    return;
  }

  let config = getTinyMceConfig(form);

  form.removeAttr('required');

  let editor = await tinymce.init(config);

  form.prop('tinymce', editor[0]);


}

/**
 *
 * @param elem
 * @return {*}
 */
export function getTinyMceConfig(elem) {

  const imgKeys = {};

  let theme = elem.data('theme') ? elem.data('theme') : 'minimal';

  const themes = {
    minimal: {
      statusbar: false,
      menubar: false,
      plugins: 'lists link',
      // eslint-disable-next-line max-len
      toolbar: 'styleselect | bullist numlist | fontselect | fontsizeselect | alignleft aligncenter alignright alignjustify | bold underline italic | link'
    },
    full: {
      plugins: 'lists link emoticons hr image code table',
      file_picker_types: 'image',
      // eslint-disable-next-line max-len
      toolbar: 'undo redo | image | table | styleselect | bullist  numlist | hr | fontselect | fontsizeselect | forecolor backcolor | alignleft aligncenter alignright alignjustify | bold  underline  italic | link | emoticons | code',
      images_upload_handler: async (blobInfo, success, failure) => {

        try {
          const fileInfo = await DocumentUploader.uploadFromBlob(blobInfo.blob(), blobInfo.filename());

          imgKeys[fileInfo.url] = fileInfo.key;

          success(fileInfo.url);

        } catch (e) {
          failure(e.responseJSON['hydra:description']);
        }
      },
      init_instance_callback: function(editor) {
        editor.on('NodeChange', function(e) {

          if (e.element.tagName.toLowerCase() !== "img") {
            return;
          }

          if (e.element.hasAttribute('data-url')) {
            return;
          }

          const url = e.element.getAttribute('src');

          if (imgKeys[url]) {
            e.element.setAttribute('data-url', imgKeys[url]);
          }
        });
      }
    }
  };

  let config = themes[theme];


  const id = elem.attr('id');

  config.selector = `#${id}`;
  config.branding = false;
  config.language = 'fr_FR';

  return config;

}

/**
 *
 * @param $input
 */
export function initDateRange($input) {

  let start = moment().subtract(1, 'month');

  moment.locale("fr");

  if(!$input.daterangepicker) {
    console.warn("$input.daterangepicker could not be instantiated");
    return;
  }

  $input.daterangepicker({
    locale: {
      format: 'DD-MM-YYYY',
    },
    "autoApply": true,
    "autoUpdateInput": false,
    "startDate": start,
    "endDate": moment(),
    "maxDate": moment(),
  });

  $input.attr('start', start.format('DD-MM-YYYY'));
  $input.attr('end', moment().format('DD-MM-YYYY'));

  $input.on('apply.daterangepicker', (ev, picker) => {
    $input.attr('start', picker.startDate.format('DD-MM-YYYY'));
    $input.attr('end', picker.endDate.format('DD-MM-YYYY'));
    $input.val(picker.startDate.format('MM/DD/YYYY') + ' - ' + picker.endDate.format('MM/DD/YYYY'));
  });

  $input.on('cancel.daterangepicker', () => {
    $input.val("");
  });


  $input.getBefore = () => {
    return $input.attr('end') + " 23:59:59";
  };
  $input.getAfter = () => {
    return $input.attr('start') + " 00:00:00";
  };

  return $input;

}


export function capitalizeAddress(address, excluded) {

  excluded = excluded || ['de', 'la', 'th', 'du'];

  // Split the address into an array of words
  const words = address.toLowerCase().split(' ');

  // Capitalize the first letter of each word
  const capitalizedWords = words.map((word) => {
    // Skip capitalization for certain words
    if (excluded.includes(word)) {
      return word;
    }
    // Capitalize the first letter of other words
    return word.charAt(0).toUpperCase() + word.slice(1);
  });

  // Join the capitalized words with spaces
  return capitalizedWords.join(' ').trim();
}


/**
 *
 * @param sex int
 * @param content string | undefined
 * @return {*}
 */
export function parseGenderedData(sex, content) {

  if (!content || typeof content.match !== "function") {
    return content;
  }

  // Thx GPT
  let matches = content.match(/\[[^\]]+\/[^\]]*\]|(?:\[[^\]]+\/[^\]]+\/[^\]]+\])|\[\/[^\]]+\]/gi);

  if (!matches) {
    return content;
  }

  matches.forEach((match) => {
    const options = match.replace(/(\[|\])/g, '').split('/');

    if (sex) {
      let option = sex === PROFILE.SEX.FEMALE ? options[1] : options[0];
      content = content.replace(match, option);
    } else {

      if (options[2]) {
        content = content.replace(match, options[2]);
        return;
      }

      throw new Error("sex should be defined if no neutral option is provided");

    }
  });


  return content;
}


export const allowUnCheckRadio = ($element) => {

  $element.find('input[type="radio"]').each((key, elem) => {

    const $elem = $(elem);

    const label = $elem.closest('label');

    label.off('click.uncheck').on('click.uncheck', (e) => {
      $elem.prop('checked', !$elem.prop('checked'));
      $elem.trigger('change');
      e.preventDefault();
    });


  });

};