import { buildId, displaySuccess, GET, handleViolations, POST, PUT, viewport } from "../common";
import TagsAutocompleter from "../TagsAutocompleter";
import { DOCUMENT, HTTP } from "../utils";
import Cropper from "./cropper";
import DragDroper from "./drag-droper";
import {
  DOCUMENTS_HELPER_MAX_FILES, DOCUMENTS_HELPER_POPUP_BLOCKED,
  DOCUMENTS_LIA_ANALYSIS_ONGOING,
  trans,
  USER_ACTIONS_SHOW_SUBSCRIPTION
} from "../../../translator";


export default class DocumentUploader {


  modal = $('.popin-document');
  parseWithIaInput = this.modal.find('input[name="parse_with_ia"]');
  titleInput = this.modal.find('#app_document_title');
  typeInput = this.modal.find('#app_document_type');
  createdDateInput = this.modal.find('#app_document_createdDate');
  idInput = this.modal.find('input[name="document-id"]');
  accessorsInput = this.modal.find('#app_document_accessors');
  visibilityInput = this.modal.find('#app_document_visibility');

  loader = this.modal.find('.loader');

  dayInput = this.modal.find('select[id$="day"]');
  monthInput = this.modal.find('select[id$="month"]');
  yearInput = this.modal.find('select[id$="year"]');

  form = $('#upload-doc');

  fileInput = this.form.find('input[type="file"]');

  updateLink = $('.update-document');

  accessorsContainer = this.modal.find('.accessors');

  maxFiles = null;

  user = {};

  cropper;


  onUpload = async () => {
    await this.createDocument();

  };

  constructor() {
    this.init();
  }


  /**
     *
     */
  init() {
    this.accesorsAutocompletor = new TagsAutocompleter(this.accessorsInput);
    this.accesorsAutocompletor.init();
    this.cropper = new Cropper();
    this.registerEvents();

  }

  /**
     *
     * @param files
     */
  async openModal(files) {

    // By default, when you open the modal, always resets the file
    this.files = null;

    this.cropper.destroy();

    if (typeof files === "undefined") {
      files = await this.getFiles();
    } else {
      this.files = files;
    }

    this.loading = false;

    if (files.length === 0) {
      this.modal.fadeOut(200);
      return;
    }

    let file = files[0];

    this.modal.find('.imgs').html('');
    this.modal.find('.imgs').show();
    this.modal.find('.controls').show();

    Object.keys(files).forEach(async (k) => {
      let file = files[k];
      if (file.type.match(/^image/)) {
        const reader = new FileReader();

        reader.onload = (e) => {

          if (files.length !== 1) {
            this.modal.find('.imgs').append(`<img class="mozaic img img-responsive" src="${e.target.result}" />`);
          } else {
            this.modal.find('.imgs').append(`<img class="img img-responsive" src="${e.target.result}" />`);
            this.cropper.init(this.modal.find('.imgs img', file.type));
          }


          this.modal.find('.imgs').show();

          if (files.length <= 1) {
            this.modal.find('.controls').show();
          }
        };
        reader.readAsDataURL(file);

        this.modal.find('.doc').hide();


      } else if (file.type === "application/pdf") {

        /**
                 let $canvas = $('<canvas></canvas>')

                 const reader = new FileReader();

                 reader.onload = async (e) => {
                 let typedArray = new Uint8Array(e.target.result);
                 let doc = await PDFDocument.load(typedArray);

                 let page = await doc.getPage(0);
                 console.log(page);
                 let viewport = page.getViewport(1);
                 await page.render({
                 canvasContext: $canvas.get(0).getContext('2d'),
                 viewport: viewport
                 });

                 this.modal.find('.imgs').append($canvas);

                 };

                 reader.readAsArrayBuffer(file);
                 **/
      }
    });


    this.modal.find('.nb_files').html(files.length);
    this.modal.find('.single').toggle(files.length === 1);
    this.modal.find('.update-title').hide();
    this.modal.find('.nb_files').parent().toggle(files.length > 1);
    this.modal.find('.multiple').toggle(files.length > 1);
    this.titleInput.closest('.field-group').toggle(files.length === 1);
    this.createdDateInput.closest('.field-group').toggle(files.length === 1);
    this.parseWithIaInput.closest('.field-group').toggle(files.length !== 1);

    this.visibilityInput.prop('required', true);
    this.typeInput.prop('required', true);
    this.titleInput.prop('required', true);
    this.parseWithIaInput.prop('checked', false);

    this.idInput.val("");
    this.titleInput.val(file.name);
    this.typeInput.val('');
    this.visibilityInput.val('');
    this.visibilityInput.trigger('change');
    this.accesorsAutocompletor.clear();
    this.modal.find('.patient').hide();
    this.modal.fadeIn(200);

    if (this.modal.find('.imgs img').length === 0) {
      this.modal.find('.imgs').hide();
      this.modal.find('.controls').hide();
    }

  }


  /**
     *
     * @param dropzone
     * @param location
     */
  enableDragAndDrop(dropzone, location) {

    let drag = new DragDroper(dropzone, location);
    drag.onFileUpload = (files) => {
      this.openModal(files);
    };
    drag.init();

    return drag;
  }


  /**
     *
     * @param document
     */
  openUpdateModal(document) {

    this.cropper.destroy();

    this.modal.find('.doc').attr('href', Routing.generate('app_document_view',
      { document: document.id })).removeAttr('hidden');

    this.accesorsAutocompletor.clear();

    if (document.accessors) {
      document.accessors.forEach((a) => {
        this.accesorsAutocompletor.addValue({ id: a.id, text: a.fullName, data: a });
      });
    }

    if (DocumentUploader.getType(document.fileName, document.mimeType) === 'image') {

      this.modal.find('.lia').hide();
      this.modal.find('.imgs').html('');
      let $img = $(`<img class="img img-responsive" crossorigin="anonymous" src="${document.fileUrl}" />`);
      this.modal.find('.imgs').append($img);
      this.modal.find('.imgs').show();
      this.modal.find('.controls').show();
      this.cropper.init($img, document.mimeType);

    } else {
      this.modal.find('.imgs').hide();
      this.modal.find('.controls').hide();
    }

    this.modal.find('.nb_files').parent().hide();

    this.modal.find('.single,.multiple').hide();
    this.modal.find('.update-title').show();

    this.titleInput.val(document.title);

    if (document.type && document.type.id) {
      this.typeInput.val(document.type.id);
    } else {
      this.typeInput.val(document.type.id);
    }

    this.idInput.val(document.id);

    if (document.user && document.user.fullName) {
      this.modal.find('.patient-name').html(document.user.fullName);
      this.modal.find('.patient').show();
    } else {
      this.modal.find('.patient').hide();
    }

    let date = new Date(document.createdDate);

    this.dayInput.val(date.getDate());
    this.monthInput.val(date.getMonth() + 1);
    this.yearInput.val(date.getFullYear());

    this.visibilityInput.val(document.visibility);
    this.visibilityInput.trigger('change');

    this.modal.fadeIn(200);

    this.loader.hide();

  }


  /**
     * Start the upload process
     */
  set loading(loading) {
    if (loading) {
      this.modal.find('button,input[type="submit"]').prop('disabled', 'disabled');
      this.loader.show();
    } else {
      this.modal.find('button,input[type="submit"]').prop('disabled', false);
      this.loader.hide();
    }
  }

  /**
     *
     * Returns the form data in order
     *
     * @return {Promise<null|{visibility: number, fileUrl: *, mimeType: *, title: *, type: string, user: string}>}
     */
  async getData(files, defaultDoc) {

    let doc = {
      ...{
        title: this.modal.find('#app_document_title').val(),
        visibility: parseInt(this.modal.find('#app_document_visibility').val()),
        type: buildId("document_types", this.modal.find('#app_document_type').val()),
      }, ...defaultDoc
    };

    if (!doc.user && this.user && this.user.id) {
      doc.user = buildId("users", this.user.id);
    }

    if (!doc.visibility) {
      doc.visibility = DOCUMENT.VISIBILITY.PUBLIC;
    }

    let date = new Date();
    date.setFullYear(
      this.modal.find('#app_document_createdDate_year').val(),
      (this.modal.find('#app_document_createdDate_month').val() - 1),
      this.modal.find('#app_document_createdDate_day').val(),
    );

    doc.createdDate = date;

    if (this.accesorsAutocompletor && this.accesorsAutocompletor.value) {
      doc.accessors = [];
      this.accesorsAutocompletor.value.forEach((value) => {
        doc.accessors.push(value.data['@id']);
      });
    }


    if (this.idInput.val()) {
      doc.id = this.idInput.val();
    } else {

      if (files.length === 0) {
        return null;
      }

      let document = await this.apiUpload(files);

      if (files.length === 1) {
        doc.fileUrl = document.key;
        doc.mimeType = document.mimeType;
        doc.previewUrl = document.previewUrl;
        return doc;
      } else {
        let docs = [];
        document.forEach((d) => {
          docs.push({
            ...doc, ...{
              previewUrl: d.previewUrl,
              fileUrl: d.key,
              mimeType: d.mimeType,
              title: d.name
            }
          });
        });

        return docs;

      }


    }


    return doc;

  }


  /**
     *
     * @param file
     * @param name
     * @return {Promise<any>}
     */
  static async base64Upload(file, name) {
    return POST(Routing.generate('api_upload_file_base_64'), {
      file: file,
      name: name,
    });
  }


  /**
     *
     * @param files
     * @return {Promise<void>}
     */
  async apiUpload(files) {
    try {
      return await DocumentUploader.upload(files);
    } catch (e) {

      if (e && e.responseJSON && e.responseJSON.violations) {

        e.responseJSON.violations.forEach((violation) => {
          if (violation.propertyPath === "file") {
            this.modal.find('.loader')
              .after('<span class="form-error">' + violation.message + '</span>');
          }
        });
      }

      throw e;
    }
  }


  /**
     *
     * @param files
     * @return {Promise<void>}
     */
  static async upload(files) {

    let data = new FormData();

    if (files instanceof FileList || typeof files.forEach === "function") {
      if (files.length > 1) {
        Object.keys(files).forEach((key) => {
          let file = files[key];
          data.append('file[]', file);
          data.append('name[]', file.name);
        });
      } else {
        data.append('file', files[0]);
        data.append('name', files[0].name);
      }
    } else {
      data.append('file', files);
      data.append('name', files.name);
    }


    return await POST(Routing.generate('api_upload_file'), data);

  }


  /**
     *
     * @return {jQuery}
     */
  async createDocument(defaultDoc) {

    this.loading = true;
    let document = await this.getData(await this.getFiles(), defaultDoc);

    const parseWithIa = this.parseWithIaInput.prop('checked');

    try {
      let val;
      if (document.id) {

        let id = document.id;
        delete document.id;

        let file = await this.cropper.getImage();

        if (file) {
          let f = await DocumentUploader.uploadFromBlob(file);
          document.fileUrl = f.key;
        }


        val = await PUT(Routing.generate('api_documents_put_item', { id: id }), document);
      } else {
        if (document.length) {
          if (parseWithIa) {
            try {

              const iaData = {
                user: document[0].user,
                files: document.map((d) => d.fileUrl)
              };

              await POST(Routing.generate('api_documents_parse_with_ia'), iaData, this.modal);

              displaySuccess(trans(DOCUMENTS_LIA_ANALYSIS_ONGOING, {}, 'documents'));
              this.modal.hide();
              return [];

            } catch (e) {
              console.log(e);
              return [];
            }

          } else {
            val = await POST(Routing.generate('api_documents_bulk_post_collection'), document,this.modal,{},null,{
              displayPaymentRequiredModal: false,
            });
          }
        } else {
          val = await POST(Routing.generate('api_documents_post_collection'), document, this.modal, {}, null, {
            handleViolations: true,
            displayPaymentRequiredModal: false,
            violationPrefix: 'app_document'
          });
        }
      }
      this.loading = false;
      this.fileInput.val(null);

      if (val.length) {
        val.forEach((v) => {
          this.addDocumentToPatientProfile(v);
        });
      } else {
        this.addDocumentToPatientProfile(val);
      }

      this.modal.hide();
      return val;
    } catch (e) {
      this.loading = false;
      handleViolations(e, 'app_document', this.modal);

      if(e.responseJSON?.code === HTTP.STATUS_CODE.PAYMENT_REQUIRED) {
        this.modal.find('.alert-error')
          .append(
            `<a class="block mg-top-5 small" target="_blank" href="${e.responseJSON?.resolveUrl}" >` +
            trans(USER_ACTIONS_SHOW_SUBSCRIPTION, {}, 'user') + `</a>`);
      }

      throw e;
    }
  }


  static async uploadFromBlob(blob, filename) {

    let data = new FormData();
    data.append("file", blob);

    if (filename) {
      data.append('name', filename);
    }

    data.append('getUrl', "true");

    return await POST(Routing.generate('api_upload_file'), data);
  }

  /**
     * @param defaultDoc
     * @return {Promise<[]>}
     */
  async getDocuments(defaultDoc) {
    let docs = await this.getData(await this.getFiles(), defaultDoc);

    if (docs.length) {
      return docs;
    }

    return [docs];

  }


  /**
     *
     * @param document
     */
  addDocumentToPatientProfile(document) {

    let container = $('.patient-documents');

    if (container.length === 0) {
      return;
    }

    let date = new Date(document.createdDate).toLocaleDateString();

    let $link = DocumentUploader.fillTemplate(document);

    $link.find('.title').html(document.title);
    $link.find('.createdDate').html(date);

    container.find('.docs').prepend($link);

    $link.on('click', (e) => {

      e.preventDefault();
      DocumentUploader.openDocument(document);
    });

  }

  /**
     *
     * @param doc
     * @return {*}
     */
  static fillTemplate(doc) {
    let $template = $($('#doc-template').html());

    $template.find('.subtitle').html((doc.type?.name));
    $template.find('.createdDate').html(new Date(doc.createdDate).toLocaleDateString());
    $template.find('.title').html(doc.title).attr('title', doc.title);
    $template.attr('href', doc.fileUrl);
    $template.find('.title').attr('href', doc.fileUrl);

    return $template;

  }


  registerEvents() {

    this.accessorsContainer.hide();

    this.visibilityInput.on('change', () => {
      if (parseInt(this.visibilityInput.val()) === DOCUMENT.VISIBILITY.SHARED_WITH_ACCESSORS) {
        this.accessorsContainer.show();
      } else {
        this.accessorsContainer.hide();
      }

    });

    this.parseWithIaInput.on('change', (e) => {

      const checked = e.target.checked;

      this.visibilityInput.prop('required', !checked);
      this.visibilityInput.closest('.field-group').toggle(!checked);
      this.typeInput.prop('required', !checked).toggle(!checked);
      this.typeInput.closest('.field-group').toggle(!checked);

    });

    this.typeInput.on('change', async (e) => {

      if (this.visibilityInput.length) {
        let val = $(e.target).val();

        if (!this.visibilityInput.val()) {

          this.loading = true;

          let type = await GET(Routing.generate('api_document_types_get_item', { id: val }));

          // Use option value checked so if the value doesn't exist, nothing happens
          let $option = this.visibilityInput.find('option[value="' + type.defaultVisibility + '"]');

          if ($option.length) {
            this.visibilityInput.val(type.defaultVisibility);
          }

          if (this.visibilityInput.attr('type') === "hidden") {
            this.visibilityInput.val(type.defaultVisibility);
          }

          this.loading = false;

        }
      }
    });


    this.updateLink.on('click', (e) => {

      let document = $(e.target).data('document');

      this.openUpdateModal(document);

      return false;
    });


    this.fileInput.on('change', async () => {

      let files = await this.getFiles();

      if (this.maxFiles && files.length > this.maxFiles) {
        alert(trans(DOCUMENTS_HELPER_MAX_FILES, {}, 'documents'));
        return;
      }

      this.openModal();
    });

    // Réinit the modal in case Luv try to upload the same file another time
    this.modal.find('.close-box').on('click', () => {
      this.fileInput.val('');
    });


    /**
         * Creates the document
         */
    this.form.on('submit', (e) => {
      e.preventDefault();
      this.onUpload(e);
    });

    if (this.modal.get(0)) {
      this.modal.get(0).onClose = () => {
        this.files = null;
      };
    }

  }


  /**
     *
     * @return {*}
     */
  async getFiles() {

    if (this.files && this.files.length) {
      return this.files;
    }

    if (this.fileInput[0].files.length !== 1) {
      return this.fileInput[0].files;
    }

    let file = await this.cropper.getImage();

    if (!file) {
      return this.fileInput[0].files;
    }

    return [
      file
    ];

  }


  /**
     *
     * @param filePath string
     * @param mimeType string
     * @param hasPreview boolean
     * @return {string}
     */
  static getType(filePath, mimeType, hasPreview) {


    // Mime type define in the url
    if (filePath.indexOf('mime=image/') !== -1) {
      return 'image';
    }

    // Regex for extention
    /** @url https://stackoverflow.com/questions/680929/how-to-extract-extension-from-filename-string-in-javascript */
    var re = /(?:\.([^.]+))?$/;

    let ext = re.exec(filePath)[1];

    if (mimeType) {
      switch (mimeType) {
        case "application/dicom+pdf":
          return "pdf";
        case "application/dicom+mp4" :
          return "video";
      }
    }

    if (!ext) {
      return 'other';
    }

    ext = ext.toLowerCase();

    if (['png', 'jpg', 'jpeg', 'bmp', 'gif', 'tiff', 'heic', 'heif'].indexOf(ext) !== -1) {
      return 'image';
    } else if (['pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'odt', 'ods', 'rtf'].indexOf(ext) !== -1) {
      return hasPreview ? 'pdf' : ext;
    } else if (['mp4'].indexOf(ext) !== -1) {
      return 'video';
    } else {
      return 'other';
    }
  }


  /**
     * @param document
     * @param forceDownload
     * @return {boolean}
     */
  static async openDocument(document, forceDownload,$container) {

    let url, srcdoc;

    if ("string" === typeof document) {
      url = document;
    }

    if (document.id) {
      if (forceDownload) {
        url = document.downloadUrl;
      } else {
        try {
          srcdoc = await GET(Routing.generate('api_preview_document', { id: document.id }));
        } catch (e) {
          if (e.responseText) {
            srcdoc = e.responseText;
          } else {
            throw e;
          }
        }
      }
    }

    if (forceDownload) {
      return DocumentUploader.download(url, document);
    }


    let $modal = $container ?? $('.popin-document-view');


    $modal.find('.upload-date').html('');
    let date = new Date();
    if (document.uploadedDate) {
      date = new Date(document.uploadedDate);
      $modal.find('.upload-date').html(date.toLocaleDateString());
    }

    let $iframe = $modal.find('iframe');

    $modal.removeClass('with-iframe');
    $iframe.removeAttr('srcdoc');
    $iframe.attr('src', 'about:blank');
    $iframe.attr('width', '');
    $iframe.attr('height', '');

    $modal.css('opacity', 0);

    $iframe.addClass('iframe-document');
    $iframe.css('visibility', 'hidden');
    if (srcdoc) {
      $iframe.attr('srcdoc', srcdoc);
    } else {
      $iframe.attr('src', url);
    }
    $modal.addClass('with-iframe');
    $modal.fadeIn(200);

    $iframe.on('load', () => {
      DocumentUploader.resizeIFrameToFitContent($modal, $iframe.get(0));
    });

    $(window).on('document-loaded', () => {
      DocumentUploader.resizeIFrameToFitContent($modal, $iframe.get(0));
    });

    return true;

  }


  static displayDownloadModal() {
    $('#popin-download-document').show();
  }


  /**
     *
     * @param url
     * @param document
     */
  static download(url) {
    try {
      let popup_window = window.open(url, '_blank');
      popup_window.focus();
    } catch (e) {
      console.warn(e);
      alert(trans(DOCUMENTS_HELPER_POPUP_BLOCKED, {}, 'documents'));
    }
  }


  /**
     *
     * @param template
     * @param doc
     */
  static addPreview(template, doc) {
    // Remove previous preview
    template.parent().find('.doc-preview').remove();

    if (!doc) {
      return;
    }

    if (doc && doc.thumbnails && doc.thumbnails.fileUrl && doc.thumbnails.fileUrl['300x*']) {
      let $preview = $(`<img style="display: none;" 
class="doc-preview" alt="${doc.title}" src="${doc.thumbnails.fileUrl['300x*']}" />`);

      let timeout;
      let inPreview;
      template.on('mouseenter', () => {
        const ahref = $preview.prev('a');
        ahref.attr('bkp-title', ahref.attr('title')).attr('title', null);
        timeout = setTimeout(() => {
          $preview.fadeIn(200);
        }, 500);
      });
      template.on('mouseleave', () => {
        setTimeout(() => {
          const ahref = $preview.prev('a');
          ahref.attr('title', ahref.attr('bkp-title')).attr('bkp-title', null);
          if (!inPreview) {
            clearTimeout(timeout);
            $preview.fadeOut();
          }
        }, 50);
      });
      $preview.on('mouseenter', () => {
        $preview.show();
        inPreview = true;
      });
      $preview.on('mouseleave', () => {
        $preview.hide();
        inPreview = false;
      });


      template.after($preview);
      $preview.on('click', () => {
        DocumentUploader.openDocument(doc);
      });

      $preview.on('error', () => {
        $preview.off('mouseenter');
        template.off('mouseenter');
      });

    }
  }


  /**
     * @param $modal
     * @param iFrame
     */
  static resizeIFrameToFitContent($modal, iFrame) {
    $modal.removeClass('pdfviewver');

    iFrame.width = viewport().width;
    iFrame.height = viewport().height;

    setTimeout(() => {
      const elem = iFrame.contentWindow.document.querySelector('.element');
      if ($(elem).hasClass('pdfviewver')) {
        $modal.addClass('pdfviewver');
      }

      if (elem && elem.offsetWidth) {
        iFrame.width = elem.offsetWidth;
        iFrame.height = elem.offsetHeight;

        iFrame.style.visibility = "visible";
        $modal.fadeTo(200, 1);
      } else {
        $modal.hide();
      }
    }, 10);
  }

}
