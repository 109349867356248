export default class Cropper {

  canvas;
  img;
  contentType;

  async init(photo, contentType) {

    this.img = photo;
    this.contentType = contentType;

    this.registerEvents();

  }


  registerEvents() {
    $('.rotate-270').off('click').on('click', async () => {
      this.rotate(270);
    });
    $('.rotate-90').off('click').on('click', async () => {
      this.rotate(90);
    });
  }


  rotate(deg) {

    let current = this.getRotation();
    deg = (deg + current) % 360;

    this.img.attr('deg', deg);

    this.img.css('transform', `rotate(${deg}deg)`);

  }


  getImage() {
    let prop = this.getRotation();

    // Image has not been updated
    if (!prop) {
      return null;
    }

    return this.createImg();

  }


  getRotation() {
    if (!this.img) {
      return null;
    }

    let r = parseInt(this.img.attr('deg'));

    return typeof r === "number" && !isNaN(r) ? r : 0;
  }


  /**
     * eslint-disable-next-line max-len
     * https://stackoverflow.com/questions/35336833/jquery-rotate-an-image-and-save-them-with-same-name-file-overwrite
     */
  async createImg() {

    let q = $.Deferred();

    let $canvas = $('<canvas></canvas>');

    $('body').append($canvas);

    let canvas = $canvas.get(0);

    let img = this.img.get(0);

    var cContext = canvas.getContext('2d');
    var cw = img.naturalWidth, ch = img.naturalHeight, cx = 0, cy = 0;

    let degree = this.getRotation();

    //   Calculate new canvas size and x/y coorditates for image
    switch (degree) {
      case 90:
        cw = img.naturalHeight;
        ch = img.naturalWidth;
        cy = img.naturalHeight * (-1);
        break;
      case 180:
        cx = img.naturalWidth * (-1);
        cy = img.naturalHeight * (-1);
        break;
      case 270:
        cw = img.naturalHeight;
        ch = img.naturalWidth;
        cx = img.naturalWidth * (-1);
        break;
    }

    //  Rotate image
    canvas.setAttribute('width', cw);
    canvas.setAttribute('height', ch);
    cContext.rotate(degree * Math.PI / 180);
    cContext.drawImage(img, cx, cy);

    canvas.toBlob((blob) => {

      $canvas.remove();

      q.resolve(blob);
    }, this.contentType
    );

    return q.promise();

  }


  destroy() {
    this.img = null;
    this.contentType = null;
  }

}
