export default class LocalStorage {


  static setExpiryItem(key, value, expiry) {

    expiry = typeof expiry === 'number' ?
    // Do a check to make sure the expiry value is a relative date and not a full timestamp
      expiry > 1700000000000 ? expiry : (new Date().getTime() + expiry)
      : expiry.getTime();

    LocalStorage.setJSON(key, {
      value,
      expiry
    });

  }


  static getExpiryItem(key) {

    const value = LocalStorage.getJson(key);

    if (!value) {
      return null;
    }

    if (value.expiry < new Date().getTime()) {
      LocalStorage.removeItem(key);
      return null;
    }

    return (value.value) ?? null;

  }


  static getJWT(key) {
    const token = LocalStorage.getExpiryItem(key);

    if(isJWTExpired(token)) {
      return null;
    }

    return token;

  }


  /**
     *
     * @param key
     * @param def
     * @return {string|*}
     */
  static getItem(key, def) {

    let t = localStorage.getItem(key);

    if (typeof t === "undefined") {
      return def;
    }

    return t;

  }


  /**
     *
     * @param key
     * @param value
     */
  static setItem(key, value) {

    localStorage.setItem(key, value);

  }


  /**
     *
     * @param key
     */
  static removeItem(key) {
    localStorage.removeItem(key);
  }


  /**
     *
     * @param key
     * @param value
     */
  static setJSON(key, value) {
    LocalStorage.setItem(key, JSON.stringify(value));

  }

  /**
     *
     * @param key
     * @param def
     * @return {any}
     */
  static getJson(key, def) {
    let value = LocalStorage.getItem(key);

    if (typeof value === "undefined" || null === value) {
      return def;
    }

    try {
      return JSON.parse(value);
    } catch (e) {
      console.warn(e);

      return def;

    }
  }


  /**
     *
     * @param key
     * @param def
     * @return {*}
     */
  static getBoolean(key, def) {
    let value = LocalStorage.getJson(key, def);

    if (typeof value === "string") {
      value = JSON.parse(value.toLowerCase());
    }

    return value;

  }

  /**
     *
     * @param key
     * @param value
     */
  static setBoolean(key, value) {
    return LocalStorage.setJSON(key, value);
  }


}


function isJWTExpired(token) {

  try {
    token = parseJwt(token);

    return token.exp > Date.now();

  } catch (e) {

    console.error(e);

    return true;
  }
}


function parseJwt(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}


export function getJwtExpiry(jwt, defaultValue) {

  const decodedPayload = parseJwt(jwt);

  // Extract the exp claim
  const exp = decodedPayload.exp;
  if (!exp) {
    return defaultValue;
  }

  // Convert the exp to a Date object
  return new Date(exp * 1000);
}
