import { uniqid } from "./src/common";

export default class Portal {

  element;
  parent;
  id;

  options = {};

  scrollTimeoutHandler;
  previousScroll = [];

  constructor(element, options = {}) {

    this.options = options;
    this.element = element;
    this.parent = this.element.parent();

    this.id = uniqid();

    this.element.prop('portal', this);


  }


  show() {
    $(document).on(`scroll.${this.id}_portal`, this._onContainerScroll.bind(this));

    this.element.parents().on(`scroll.${this.id}_portal`, this._onContainerScroll.bind(this));

    this._display();

  }

  hide() {

    this.element.attr('style', "");
    this.element.hide();
    this.element.removeClass('is-portal');

    $(document).off(`scroll.${this.id}_portal`);

    if (!this.options.stickToParent) {
      this.element.appendTo(this.parent);
    }

    this.element.parents().off(`scroll.${this.id}_portal`);
  }


  /** @private **/
  _onContainerScroll(event) {

    const scrollTop = event.currentTarget.scrollTop;

    // Avoid flickering
    if(scrollTop &&
        this.previousScroll.length === 2 &&
        (JSON.stringify(this.previousScroll) === JSON.stringify([scrollTop,this.previousScroll[1]]))) {
      return;
    }

    if(scrollTop) {
      this.previousScroll.push(scrollTop);

      // Keep the last 2 scroll positions
      this.previousScroll = this.previousScroll.slice(-2);
    }

    this.reload(50);
  }


  reload(timeout = 15) {
    this.element.attr('style', "");

    if (!this.options.stickToParent) {
      this.element.appendTo(this.parent);
    }
    clearTimeout(this.scrollTimeoutHandler);
    this.scrollTimeoutHandler = setTimeout(() => {
      this._display();
    }, timeout);

  }


  /** @private **/
  _display() {
    this.element.show();

    const rect = this.element.get(0).getBoundingClientRect();

    this.element.css({
      "position": "fixed",
      "top": (rect.top) + "px",
      "left": (rect.left) + "px",
      "width": rect.width + "px",
      "height": rect.height + "px",
      "z-index": 9999
    });

    this.element.addClass('is-portal');

    if (!this.options.stickToParent) {
      this.element.appendTo($('body'));
    }
  }

}
